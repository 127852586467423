import {
  Store as AdvertsIcon,
  Archive,
  LocalOffer,
  Assignment as RequestsIcon,
  SwapHoriz,
  Gavel as TendersIcon,
} from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import HighQualityOutlinedIcon from '@mui/icons-material/HighQualityOutlined'
import { Chip, Stack, Tooltip, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { groupBy, sumBy } from 'lodash'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import { alertActions } from '../../../../../../_actions/alertActions'
import {
  categorizedProductLookup,
  countryLookup,
  deliveryTypeLookup,
  productLookup,
  productStateLookup,
  requestStateLookup,
  requestTypeLookup,
} from '../../../../../../_constants/lookupConstants'
import { authorize } from '../../../../../../_helpers/authorization'
import { partnerCommissionGroupsService } from '../../../../../../_services/partnerCommissionGroupsService'
import { requestsService } from '../../../../../../_services/requestsService'
import AgreementDialog from '../../../../../AgreementDialog'
import { PositionsList } from '../../../../../PositionsList'
import RequestDetails from '../../../../../RequestDetails'
import RequestPositionsOfferComparison from '../../../../../RequestPositionsOfferComparison'
import ResourceList from '../../../../../ResourceList/_components/ResourceList'
import RequestPartnerSwitchDialog from '../../../Request/_components/RequestPartnerSwitchDialog'

const RequestsOverview = (props) => {
  const { t } = props
  const [
    showAgreementDialogDeleteRequest,
    setShowShowAgreementDialogDeleteRequest,
  ] = useState(false)

  const [selectedRequest, setSelectedRequest] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)
  const [partnerCommissionGroups, setPartnerCommissionGroups] = useState([])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [showRequestPositionComparison, setShowRequestPositionComparison] =
    useState(false)
  const [showPartnerSwitchDialog, setShowPartnerSwitchDialog] = useState(false)

  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)
  let defaultValueRequestId = params.get('id')

  useEffect(() => {
    if (authorize('administrator')) {
      partnerCommissionGroupsService
        .getPartnerCommissionGroups()
        .then((partnerCommissioNGroups) => {
          setPartnerCommissionGroups(partnerCommissioNGroups)
        })
    }
  }, [])

  return (
    <Fragment>
      {
        <Fragment>
          <ResourceList
            fetchMethod={(filterValues, pagination) => {
              return requestsService.getRequests(filterValues, pagination)
            }}
            filterId={'REQUEST_OVERVIEW'}
            forceUpdateCount={forceUpdateCount}
            filters={[
              {
                key: 'id.startsWith',
                label: t('MARKETPLACE.REQUEST_ID'),
                variant: 'textfield',
                defaultValue: defaultValueRequestId,
              },
              {
                key: 'state',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(requestStateLookup),
                lookup: requestStateLookup,
                label: t('REQUEST_OVERVIEW.REQUEST_STATE'),
              },
              {
                key: 'type',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(requestTypeLookup),
                lookup: requestTypeLookup,
                label: t('REQUEST_OVERVIEW.REQUEST_TYPE'),
              },
              {
                key: '~IGNORE~calendarWeek',
                label: t('MARKETPLACE.DELIVERY_CALENDAR_WEEK'),
                variant: 'calendarWeekPicker',
              },
              {
                key: 'positions.location.place.startsWith',
                label: t('REQUEST_OVERVIEW.PARTNER_ID'),
                variant: 'textfield',
              },
              {
                key: 'positions.productId.eq',
                variant: 'categorizedCheckboxList',
                values: categorizedProductLookup.map((category) => {
                  return {
                    label: category.name,
                    items: category.products.map((product) => product.id),
                  }
                }),
                lookup: productLookup,
                label: t('MARKETPLACE.PRODUCT'),
                shortcut: true,
              },
              {
                key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(productStateLookup),
                lookup: productStateLookup,
                label: t('MARKETPLACE.PRODUCT_STATE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(deliveryTypeLookup),
                lookup: deliveryTypeLookup,
                label: t('MARKETPLACE.DELIVERY_TYPE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=1&positions.positionProperties.value',
                label: t('MARKETPLACE.PRODUCT_LENGTH'),
                variant: 'textfield',
              },
              {
                key: 'positions.positionProperties.productPropertyId=2&positions.positionProperties.value',
                label: t('MARKETPLACE.PRODUCT_WIDTH'),
                variant: 'textfield',
              },
              {
                key: 'positions.location.countryId.eq',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(countryLookup),
                lookup: countryLookup,
                label: t('MARKETPLACE.COUNTRY'),
              },
              {
                key: 'positions.location.zipcode.startsWith',
                label: t('MARKETPLACE.ZIPCODE'),
                variant: 'textfield',
              },
              {
                key: 'positions.location.place.startsWith',
                label: t('MARKETPLACE.PLACE'),
                variant: 'textfield',
              },
              authorize('administrator')
                ? {
                    key: 'partner.partnerCommissionGroups.id.eq',
                    label: t('PARTNER_OVERVIEW.ACCOUNT_MANAGER'),
                    variant: 'autocomplete',
                    options: partnerCommissionGroups.map(
                      (partnerCommissionGroup) => partnerCommissionGroup.id,
                    ),
                    lookup: Object.fromEntries(
                      partnerCommissionGroups.map((partnerCommissionGroup) => [
                        partnerCommissionGroup.id,
                        `${partnerCommissionGroup.name}`,
                      ]),
                    ),
                  }
                : null,
            ].filter((filter) => filter !== null)}
            sorting={[
              {
                key: 'id',
                default: true,
                label: t('MARKETPLACE.NEWEST_FIRST'),
              },
            ]}
            resourceHeaderRenderFunction={(request) =>
              `${
                request.type === 'REQUEST'
                  ? t('MARKETPLACE.REQUEST')
                  : request.type === 'ADVERT'
                  ? t('ADVERTS_EXCHANGE.ADVERT')
                  : t('MARKETPLACE.TENDER')
              }: ${request.id}`
            }
            listHeaderRenderFunction={(request) => (
              <Fragment>
                <div>
                  <Tooltip
                    color={'primary'}
                    title={requestTypeLookup[request.type]}
                    style={{ marginRight: 5 }}
                  >
                    {request.type === 'REQUEST' ? (
                      <RequestsIcon />
                    ) : request.type === 'ADVERT' ? (
                      <AdvertsIcon />
                    ) : (
                      <TendersIcon />
                    )}
                  </Tooltip>
                  {`${
                    request.type === 'REQUEST'
                      ? t('MARKETPLACE.REQUEST')
                      : request.type === 'ADVERT'
                      ? t('ADVERTS_EXCHANGE.ADVERT')
                      : t('MARKETPLACE.TENDER')
                  }
                  : ${request.id} | ${moment(request.publishedAt)
                    .local()
                    .format('DD.MM.YYYY')}`}
                </div>
                <div>
                  {authorize('administrator')
                    ? `${request.user.firstname} ${request.user.lastname} | ${
                        authorize('administrator')
                          ? `${request.partner.name} (${request.partner.id})`
                          : ''
                      }`
                    : ''}
                </div>

                {`${request.location.zipcode} | ${request.location.place} | ${
                  countryLookup[request.location.countryId]
                }`}
                <div>
                  {authorize('administrator')
                    ? `Angebote: ${request.offerAmountAll} | Aufträge: ${
                        request.orderAmountAll + request.tenderOrderAmountAll
                      }`
                    : null}
                </div>
              </Fragment>
            )}
            headerActionRenderFunction={(request) => (
              <Fragment>
                <Stack direction={'column'} spacing={1}>
                  <Stack
                    direction={isMobile ? 'row-reverse' : 'row'}
                    spacing={1}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '4px',
                      }}
                    >
                      {request.qualityCategory === 'HIGH' && (
                        <Tooltip title={t('MARKETPLACE.HIGH_QUALITY')}>
                          <HighQualityOutlinedIcon
                            style={{ marginRight: '5px', fontSize: '1.7rem' }}
                            color="secondary"
                          />
                        </Tooltip>
                      )}
                      {request.community && (
                        <Tooltip title={request.partner.name}>
                          <GroupsIcon
                            color="secondary"
                            style={{ marginRight: '4px', fontSize: '1.7rem' }}
                          />
                        </Tooltip>
                      )}
                    </div>
                  </Stack>
                  <Chip
                    size="small"
                    color={
                      request.state === 'DRAFT' ||
                      request.state === 'ARCHIVED' ||
                      request.state === 'EXPIRED'
                        ? 'default'
                        : 'info'
                    }
                    label={requestStateLookup[request.state]}
                  />
                </Stack>
              </Fragment>
            )}
            contentRenderFunction={(request) => (
              <PositionsList
                positions={
                  request.type === 'TENDER'
                    ? Object.values(groupBy(request.positions, 'id')).map(
                        (pos) => ({
                          ...pos[0],
                          amount: sumBy(pos, 'amount'),
                        }),
                      )
                    : request.positions
                }
              />
            )}
            resourceRenderFunction={(request) => {
              return <RequestDetails requestId={request.id} />
            }}
            actionsRenderFunction={(request) => {
              return [
                {
                  icon: <SwapHoriz />,
                  name: t('REQUESTS.SWITCH_PARTNERS'),
                  primary: false,
                  onClick: () => {
                    setSelectedRequest(request)
                    setShowPartnerSwitchDialog(true)
                  },
                },
                {
                  icon: <Archive />,
                  name:
                    request.state === 'ARCHIVED'
                      ? t('REQUESTS.ALREADY_ARCHIVED')
                      : t('GENERAL.ARCHIVE'),
                  hidden: request.state === 'ARCHIVED',
                  primary: false,
                  onClick: () => {
                    setSelectedRequest(request)
                    setShowShowAgreementDialogDeleteRequest(true)
                  },
                },
                {
                  icon: <LocalOffer />,
                  name: t('REQUESTS.COMPARE_OFFERS'),
                  primary: true,
                  onClick: () => {
                    setSelectedRequest(request)
                    setShowRequestPositionComparison(true)
                  },
                },
              ]
            }}
            dataIdentifier={'id'}
          />
        </Fragment>
      }
      <AgreementDialog
        open={showAgreementDialogDeleteRequest}
        handleClose={() => {
          setShowShowAgreementDialogDeleteRequest(false)
        }}
        agree={async () => {
          await requestsService.deleteRequest(selectedRequest.id)
          alertActions.info(t('REQUESTS.DELETE_SUCCESSFUL'))
          setShowShowAgreementDialogDeleteRequest(false)
          forceUpdate(forceUpdateCount + 1)

          setTimeout(() => {
            alertActions.clear()
          }, alertActions.alertTimeout)
        }}
      />
      {showPartnerSwitchDialog && (
        <RequestPartnerSwitchDialog
          show={showPartnerSwitchDialog}
          hide={() => setShowPartnerSwitchDialog(false)}
          request={selectedRequest}
          forceUpdateCount={forceUpdateCount}
          forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
        />
      )}
      {showRequestPositionComparison && (
        <RequestPositionsOfferComparison
          hide={() => setShowRequestPositionComparison(false)}
          requestId={selectedRequest.id}
        />
      )}
    </Fragment>
  )
}

export default withTranslation()(RequestsOverview)
