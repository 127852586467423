import { Handshake } from '@mui/icons-material'
import { Grid, Tooltip, Typography } from '@mui/material'
import numeral from 'numeral'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { authorize } from '../../_helpers/authorization'
import { translateProductUnit } from '../../_helpers/little'
import { formatToPrice } from '../../_helpers/number-formatting'

const FormattedPrice = ({ t, pos, differences }) => {
  let productUnit = pos.product.productUnits.find(
    (productUnit) => productUnit.id === pos.productUnitId,
  )

  if (productUnit.deliveryProductUnitId) {
    productUnit = pos.product.productUnits.find(
      (pu) => pu.id === productUnit.deliveryProductUnitId,
    )
  }

  const pricePrefix = `${t('OFFER.PRICE_EACH')} ${translateProductUnit(
    productUnit,
  )}: `

  const diffPrice = differences?.positions.find(
    (position) =>
      parseInt(position.id) === parseInt(pos.id) &&
      parseInt(position.locationId) === parseInt(pos.locationId),
  )?.price

  return (
    <Fragment>
      {authorize('administrator') && pos.purchasePrice && pos.salesPrice ? (
        <Typography>
          {`${numeral(parseFloat(pos.purchasePrice)).format(
            '0,0.00 $',
          )}/${numeral(parseFloat(pos.salesPrice)).format('0,0.00 $')}`}
        </Typography>
      ) : (
        <Fragment>
          <Grid container alignItems="center">
            <Grid item>
              <Typography>
                {parseFloat(pos.amount) !== 0
                  ? `${pricePrefix} ${formatToPrice(pos.price)}`
                  : 'N/A'}
              </Typography>
            </Grid>

            {diffPrice && (
              <Grid item>
                <Tooltip
                  title={`${t(
                    'OFFER_DETAILS_POSITIONS.PREVIOUS_PRICE',
                  )} ${formatToPrice(diffPrice)}`}
                >
                  <Handshake color="primary" style={{ marginLeft: '5px' }} />
                </Tooltip>
              </Grid>
            )}
          </Grid>

          <Typography sx={{ fontWeight: 100, fontSize: '0.65rem' }}>
            {pos.purchasePrice &&
              parseFloat(pos.amount) !== 0 &&
              `${t('OFFER_DETAILS_POSITIONS.SUPPLIER_PRICE')}: ${formatToPrice(
                pos.purchasePrice,
              )}`}
            {pos.salesPrice &&
              parseFloat(pos.amount) !== 0 &&
              `${t('OFFER_DETAILS_POSITIONS.PARTNER_PRICE')}: ${formatToPrice(
                pos.salesPrice,
              )}`}
          </Typography>
        </Fragment>
      )}
    </Fragment>
  )
}

export default withTranslation()(FormattedPrice)
