import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import OfferStepper from '../..'
import CustomDialogTitle from '../../../CustomDialogTitle'
import OfferActionsDialogs from './_components/OfferActionsDialogs'

const OfferStepperDialog = (props) => {
  const {
    showOffer,
    setShowOffer,
    selectedOfferPosition,
    setSelectedOfferPosition,
    forceUpdate,
    requestPosition,
    t,
  } = props
  const [mode, setMode] = React.useState('VIEW')
  const [showDeclineOfferDialog, setShowDeclineOfferDialog] = useState(false)
  const [showAcceptOfferDialog, setShowAcceptOfferDialog] = useState(false)
  const [showSendOfferDialog, setShowSendOfferDialog] = useState(false)
  const userObject = JSON.parse(localStorage.getItem('user'))
  const [latestOfferInDialog, setLatestOfferInDialog] = useState(null)
  const handleCloseOfferDialog = () => {
    setShowOffer(false)
    setSelectedOfferPosition(null)
  }
  const handleLatestOfferChange = (latestOffer) => {
    setLatestOfferInDialog(latestOffer)
  }
  const dialogControlProps = {
    showAcceptOfferDialog,
    setShowAcceptOfferDialog: () => setShowAcceptOfferDialog(false),
    showSendOfferDialog,
    setShowSendOfferDialog: () => setShowSendOfferDialog(false),
    showDeclineOfferDialog,
    setShowDeclineOfferDialog: () => setShowDeclineOfferDialog(false),
    selectedOfferPosition,
    handleLatestOfferChange,
    requestPosition,
    forceUpdate,
  }

  return (
    <Fragment>
      <Dialog open={showOffer} fullWidth={true} maxWidth="lg">
        <CustomDialogTitle
          title={t('OFFERS.OFFER')}
          onClose={handleCloseOfferDialog}
        />
        <DialogContent>
          {selectedOfferPosition && (
            <OfferStepper
              rootOfferId={
                selectedOfferPosition.offer.hierarchyLevel === 1
                  ? selectedOfferPosition.offer.id
                  : selectedOfferPosition.rootOfferId
              }
              forceUpdate={() => {
                forceUpdate()
                handleCloseOfferDialog()
              }}
              cancelOffering={() => {
                setMode('VIEW')
                handleCloseOfferDialog()
              }}
              mode={mode}
              onLatestOfferChange={handleLatestOfferChange}
            />
          )}
        </DialogContent>
        {latestOfferInDialog && (
          <DialogActions>
            {requestPosition.request.state !== 'ARCHIVED' &&
              (latestOfferInDialog.partnerId !== userObject.partner.id ||
                latestOfferInDialog.state === 'ARCHIVED' ||
                latestOfferInDialog.state === 'EXPIRED' ||
                latestOfferInDialog.state === 'CANCLED') && (
                <Button
                  onClick={() => {
                    setMode('COUNTER')
                  }}
                  variant="outlined"
                  color={'secondary'}
                >
                  {t('OFFERS.CREATE_COUNTEROFFER')}
                </Button>
              )}
            {requestPosition.request.state !== 'ARCHIVED' &&
              latestOfferInDialog.state === 'ACTIVE' && (
                <Button
                  onClick={() => {
                    setShowDeclineOfferDialog(true)
                  }}
                  variant="outlined"
                  color={'secondary'}
                >
                  {latestOfferInDialog.partnerId === userObject.partner.id
                    ? t('OFFERS.CANCEL_OFFER')
                    : t('OFFERS.DECLINE_OFFER')}
                </Button>
              )}

            {requestPosition.request.state !== 'ARCHIVED' &&
              latestOfferInDialog.state === 'ACTIVE' && (
                <Fragment>
                  {latestOfferInDialog.partnerId === userObject.partner.id ? (
                    <Button
                      onClick={() => {
                        setMode('EDIT')
                      }}
                      variant={'contained'}
                      color={'secondary'}
                      hidden={
                        requestPosition.request.state === 'ARCHIVED' ||
                        latestOfferInDialog.state !== 'ACTIVE' ||
                        latestOfferInDialog.partnerId !== userObject.partner.id
                      }
                    >
                      {t('OFFERS.EDIT_OFFER')}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        if (requestPosition.request.type === 'ADVERT') {
                          if (
                            parseInt(userObject.partner.id) ===
                            parseInt(requestPosition.request.partnerId)
                          ) {
                            setShowSendOfferDialog(true)
                          } else {
                            setShowAcceptOfferDialog(true)
                          }
                        } else {
                          if (
                            parseInt(userObject.partner.id) ===
                            parseInt(requestPosition.request.partnerId)
                          ) {
                            setShowAcceptOfferDialog(true)
                          } else {
                            setShowSendOfferDialog(true)
                          }
                        }
                      }}
                      variant={'contained'}
                      color={'secondary'}
                      hidden={
                        requestPosition.request.state === 'ARCHIVED' ||
                        latestOfferInDialog.state !== 'ACTIVE' ||
                        latestOfferInDialog.partnerId === userObject.partner.id
                      }
                    >
                      {t('OFFERS.ACCEPT_OFFER')}
                    </Button>
                  )}
                </Fragment>
              )}
          </DialogActions>
        )}
      </Dialog>
      <OfferActionsDialogs dialogControlProps={dialogControlProps} />
    </Fragment>
  )
}

export default withTranslation()(OfferStepperDialog)
