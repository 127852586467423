import {
  Cancel,
  Check,
  Edit,
  Groups,
  Send,
  SwapHoriz,
} from '@mui/icons-material'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import moment from 'moment'
import Numeral from 'numeral'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import {
  categorizedProductLookup,
  countryLookup,
  deliveryTypeLookup,
  offerStateLookup,
  productLookup,
  productStateLookup,
  requestTypeLookup,
} from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import { offersService } from '../../../../_services/offersService'
import { usersService } from '../../../../_services/usersService'
import AcceptCounterOfferDialog from '../../../AcceptCounterOfferDialog'
import AcceptOfferDialog from '../../../AcceptOfferDialog'
import AgreementDialog from '../../../AgreementDialog'
import { CustomRating } from '../../../CustomRating'
import { InfoTooltip } from '../../../InfoTooltip'
import { Loading } from '../../../Loading'
import OfferStepper from '../../../OfferStepper'
import { PositionsList } from '../../../PositionsList'
import ResourceList from '../../../ResourceList/_components/ResourceList'
import ViewDescription from '../../../ViewDescription'

const OffersAdverts = (props) => {
  const userObject = JSON.parse(localStorage.getItem('user'))

  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)
  let id = null
  let requestId = null

  const { t } = props
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)
  const [offerType, setOfferType] = useState(
    params.get('offerType') ? params.get('offerType') : null,
  )
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [usersOfPartner, setUsersOfPartner] = useState([])
  const [showDeclineOfferDialog, setShowDeclineOfferDialog] = useState(false)
  const [showAcceptOfferDialog, setShowAcceptOfferDialog] = useState(false)
  const [showSendOfferDialog, setShowSendOfferDialog] = useState(false)

  const [mode, setMode] = React.useState('VIEW')

  id = params.get('id')
  requestId = params.get('requestId')
  window.history.pushState({}, document.title, window.location.pathname)

  useEffect(() => {
    usersService.getUsersOfPartner(userObject.partner.id).then((users) => {
      setUsersOfPartner(users)
    })
  }, [userObject.partner.id])

  return (
    <Fragment>
      <ViewDescription description={t('OFFERS.VIEW_DESCRPTION')} />
      {isLoading && <Loading variant={'centered'} />}
      <Fragment>
        {
          <ResourceList
            fetchMethod={
              offerType
                ? (filterValues, pagination) =>
                    offersService.getOffersOfPartner(
                      userObject.partner.id,
                      'ADVERT',
                      offerType,
                      filterValues,
                      pagination,
                      userObject.user.id,
                    )
                : null
            }
            filterId={'OFFERS_ADVERTS'}
            forceUpdateCount={forceUpdateCount}
            filters={[
              {
                key: 'rootOfferId',
                label: t('OFFERS.OFFER_ID'),
                variant: 'textfield',
                defaultValue: id,
              },
              {
                key: 'requestId.startsWith',
                label: t('OFFERS.REQUEST_ID'),
                variant: 'textfield',
                defaultValue: requestId,
              },
              {
                key: 'state',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(offerStateLookup),
                lookup: offerStateLookup,
                label: t('OFFERS.STATE'),
              },
              {
                key: 'request.type',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(requestTypeLookup),
                lookup: requestTypeLookup,
                label: t('REQUESTS.REQUEST_TYPE'),
              },
              {
                key: 'positions.requestPosition.productId.eq',
                variant: 'categorizedCheckboxList',
                values: categorizedProductLookup.map((category) => {
                  return {
                    label: category.name,
                    items: category.products.map((product) => product.id),
                  }
                }),
                lookup: productLookup,
                label: t('REQUESTS.PRODUCT'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(productStateLookup),
                lookup: productStateLookup,
                label: t('MARKETPLACE.PRODUCT_STATE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(deliveryTypeLookup),
                lookup: deliveryTypeLookup,
                label: t('MARKETPLACE.DELIVERY_TYPE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=1&positions.positionProperties.value',
                label: t('MARKETPLACE.PRODUCT_LENGTH'),
                variant: 'textfield',
              },
              {
                key: 'positions.positionProperties.productPropertyId=2&positions.positionProperties.value',
                label: t('MARKETPLACE.PRODUCT_WIDTH'),
                variant: 'textfield',
              },
              {
                key: 'positions.location.countryId.eq',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(countryLookup),
                lookup: countryLookup,
                label: t('OFFERS.COUNTRY'),
              },
              {
                key: 'positions.location.zipcode.startsWith',
                label: t('REQUESTS.ZIPCODE'),
                variant: 'textfield',
              },
              {
                key: 'positions.location.place.startsWith',
                label: t('REQUESTS.PLACE'),
                variant: 'textfield',
              },
              {
                key: 'userIds',
                variant: 'checkboxList',
                values: usersOfPartner.map((user) => user.id),
                lookup: Object.fromEntries(
                  usersOfPartner.map((user) => [
                    user.id,
                    `${user.firstname} ${user.lastname}`,
                  ]),
                ),
                label: t('GENERAL.USER'),
              },
            ]}
            hint={t('OFFERS.VIEW_DESCRPTION')}
            selectionText={t('OFFERS.SELECT_AN_OFFER')}
            sorting={[
              {
                key: 'updatedAt',
                default: true,
                label: t('OFFERS.LAST_ACTIVITY'),
              },
            ]}
            toolbar={
              offerType && (
                <Chip
                  color={'primary'}
                  size={'small'}
                  style={{ maxWidth: '50%', marginTop: 5, marginBottom: 5 }}
                  label={
                    <Fragment>
                      <strong>
                        {`${t('REQUESTS.OFFERS')}: ${
                          offerType === 'SENT'
                            ? t('OFFERS.SENT')
                            : t('OFFERS.RECEIVED')
                        }
                    `}
                      </strong>
                    </Fragment>
                  }
                  onDelete={() => {
                    setOfferType(null)
                  }}
                />
              )
            }
            resourceHeaderRenderFunction={(offer) =>
              `${t('OFFERS.OFFER')}: ${offer.rootOfferId}`
            }
            listHeaderRenderFunction={(offer) => (
              <Fragment>
                <div>
                  {`${t('OFFERS.OFFER')}: ${offer.rootOfferId}`}
                  {offerType === 'SENT'
                    ? ` | ${countryLookup[offer.manufacturerCountryId]}`
                    : null}
                </div>
                <div>
                  {`${t('OFFERS.LAST_ACTIVITY')} ${moment(
                    offer.updatedAt,
                  ).fromNow()}`}
                </div>
                <Fragment>
                  {offer.customerUser && (
                    <div>{`${offer.customerUser.firstname} ${offer.customerUser.lastname}`}</div>
                  )}
                  {offer.manufacturerUser && (
                    <div>{`${offer.manufacturerUser.firstname} ${offer.manufacturerUser.lastname}`}</div>
                  )}
                </Fragment>
                {authorize('administrator') ? (
                  <Fragment>
                    <br />
                    <div>{`${t('ORDERS.CUSTOMER')}: ${
                      offer.customerUser.firstname
                    } ${offer.customerUser.lastname} | ${offer.customer} (${
                      offer.customerId
                    })`}</div>
                    <div>{`${t('ORDERS.MANUFACTURER')}: ${
                      offer.manufacturerUser.firstname
                    } ${offer.manufacturerUser.lastname} | ${
                      offer.manufacturer
                    } (${offer.manufacturerId})`}</div>
                  </Fragment>
                ) : offer.customer ? (
                  <Fragment>
                    <br />
                    <div>{`${t('OFFERS.PARTNER')}: ${offer.customer}`}</div>
                  </Fragment>
                ) : null}
              </Fragment>
            )}
            headerActionRenderFunction={(offer) => {
              let averageRating =
                (parseFloat(offer.manufacturerAverageQuality) +
                  parseFloat(offer.manufacturerAverageReliability)) /
                2

              return (
                <Fragment>
                  <Stack
                    direction={isMobile ? 'row-reverse' : 'row'}
                    spacing={1}
                    alignItems={isMobile ? 'left' : 'right'}
                    sx={{
                      display: 'flex',
                      flexDirection: isMobile ? 'row-reverse' : 'row',
                    }}
                  >
                    <Grid
                      container
                      spacing={1}
                      xs={12}
                      md={12}
                      justifyContent={isMobile ? 'flex-start' : 'flex-end'}
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: isMobile ? 'flex-start' : 'flex-end',
                        }}
                      >
                        <Fragment>
                          {offer.community && (
                            <Tooltip title={offer.community.name}>
                              <Groups
                                color={'secondary'}
                                sx={{ marginRight: 1 }}
                              />
                            </Tooltip>
                          )}

                          <Chip
                            size={'small'}
                            color={
                              offer.state === 'EXPIRED' ||
                              offer.state === 'ARCHIVED' ||
                              offer.state === 'CANCLED'
                                ? 'default'
                                : 'info'
                            }
                            label={offerStateLookup[offer.state]}
                            sx={{ marginLeft: 1 }}
                          />
                        </Fragment>
                      </Grid>
                    </Grid>
                  </Stack>
                  {offerType === 'SENT' && (
                    <Box padding={1}>
                      {offer.manufacturerAverageQuality ? (
                        <InfoTooltip
                          title={
                            <table>
                              <thead>
                                <th
                                  style={{
                                    borderRight: 'solid 15px transparent',
                                  }}
                                >
                                  {t('OFFERS.PARTNER_RATING')}
                                </th>
                                <th>
                                  {Numeral(
                                    (parseFloat(
                                      offer.manufacturerAverageQuality,
                                    ) +
                                      parseFloat(
                                        offer.manufacturerAverageReliability,
                                      )) /
                                      2,
                                  ).format('0,0.00')}
                                </th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {t('OFFERS.RATING_QUALITY')}
                                  </td>
                                  <td>
                                    {Numeral(
                                      parseFloat(
                                        offer.manufacturerAverageQuality,
                                      ),
                                    ).format('0,0.00')}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {t('OFFERS.RATING_RELIABILITY')}
                                  </td>
                                  <td>
                                    {Numeral(
                                      parseFloat(
                                        offer.manufacturerAverageReliability,
                                      ),
                                    ).format('0,0.00')}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {t('OFFERS.TOTAL_RECEIVED_RATINGS')}
                                  </td>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {offer.manufacturerRatingAmount}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'right',
                              alignItems: isMobile ? 'left' : 'center',
                            }}
                          >
                            <CustomRating
                              size={'small'}
                              name="read-only"
                              value={averageRating}
                              readOnly
                              precision={0.5}
                            >
                              {`(${offer.manufacturerRatingAmount})`}
                            </CustomRating>
                          </div>
                        </InfoTooltip>
                      ) : (
                        <div
                          style={{
                            textAlign: isMobile ? 'left' : 'right',
                          }}
                        >
                          <Typography variant="caption">
                            {t('OFFERS.NOT_ENOUGH_RATINGS_YET')}
                          </Typography>
                        </div>
                      )}
                    </Box>
                  )}
                </Fragment>
              )
            }}
            contentRenderFunction={(offer) => (
              <Fragment>
                <div> {`${t('OFFERS.ADVERT_ID')}: ${offer.requestId}`}</div>
                <div>
                  {`${
                    offer.locations[0].company
                      ? offer.locations[0].company + ' | '
                      : ''
                  }${offer.locations[0].zipcode} | ${
                    offer.locations[0].place
                  } | ${countryLookup[offer.locations[0].countryId]}`}
                </div>
                <PositionsList positions={offer.positions} />
              </Fragment>
            )}
            resourceRenderFunction={(offer) => {
              return (
                <OfferStepper
                  rootOfferId={offer.rootOfferId}
                  forceUpdate={(seed) => {
                    forceUpdate(seed)
                  }}
                  cancelOffering={() => {
                    setMode('VIEW')
                  }}
                  mode={mode}
                />
              )
            }}
            actionsRenderFunction={(offer) => {
              return [
                {
                  icon: <SwapHoriz />,
                  name: t('OFFERS.CREATE_COUNTEROFFER'),
                  hidden:
                    offer.requestState === 'ARCHIVED' ||
                    (offer.partnerId === userObject.partner.id &&
                      offer.state !== 'ARCHIVED' &&
                      offer.state !== 'EXPIRED' &&
                      offer.state !== 'CANCLED'),
                  primary: false,
                  onClick: () => {
                    setSelectedOffer(offer)
                    setMode('COUNTER')
                  },
                },
                {
                  icon: <Cancel />,
                  name:
                    offer.partnerId === userObject.partner.id
                      ? t('OFFERS.CANCEL_OFFER')
                      : t('OFFERS.DECLINE_OFFER'),
                  hidden:
                    offer.requestState === 'ARCHIVED' ||
                    offer.state !== 'ACTIVE',
                  primary: false,
                  onClick: () => {
                    setSelectedOffer(offer)
                    setShowDeclineOfferDialog(true)
                  },
                },
                {
                  icon: <Edit />,
                  name:
                    offer.revision > 1 &&
                    moment.utc(offer.updatedAt).add(90, 'minutes') >
                      moment.utc()
                      ? `${t('OFFERS.EDIT_OFFER')} (${Math.abs(
                          Math.floor(
                            moment
                              .duration(
                                moment
                                  .utc()
                                  .diff(
                                    moment.utc(
                                      moment
                                        .utc(offer.updatedAt)
                                        .add(90, 'minutes'),
                                    ),
                                  ),
                              )
                              .asMinutes(),
                          ),
                        )} ${t('OFFERS.MINUTES')})`
                      : t('OFFERS.EDIT_OFFER'),
                  disabled:
                    offer.revision > 1 &&
                    moment.utc(offer.updatedAt).add(90, 'minutes') >
                      moment.utc(),
                  hidden:
                    offer.requestState === 'ARCHIVED' ||
                    offer.state !== 'ACTIVE' ||
                    offer.partnerId !== userObject.partner.id,
                  primary: true,
                  onClick: () => {
                    setSelectedOffer(offer)
                    setMode('EDIT')
                  },
                },
                {
                  icon: <Check />,
                  name: t('OFFERS.ACCEPT_OFFER'),
                  hidden:
                    offer.requestState === 'ARCHIVED' ||
                    offer.state !== 'ACTIVE' ||
                    offer.partnerId === userObject.partner.id,
                  primary: true,
                  onClick: () => {
                    setSelectedOffer(offer)
                    if (
                      parseInt(userObject.partner.id) ===
                      parseInt(offer.requestPartnerId)
                    ) {
                      setShowSendOfferDialog(true)
                    } else {
                      setShowAcceptOfferDialog(true)
                    }
                  },
                },
              ]
            }}
            dataIdentifier={'rootOfferId'}
          />
        }
      </Fragment>

      <Dialog open={!offerType} fullWidth={true} maxWidth="sm">
        <DialogContent>
          <Grid
            container
            spacing={3}
            alignItems="stretch"
            justifyContent={'center'}
          >
            <Grid item xs={12} sm={6}>
              <Card
                variant={'outlined'}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <CardActionArea
                  onClick={() => {
                    setOfferType('SENT')
                    forceUpdate(forceUpdateCount + 1)
                  }}
                  sx={{
                    height: '100%',
                    flexDirection: 'column',
                  }}
                >
                  <CardHeader avatar={<Send />} title={t('OFFERS.SENT')} />
                  <CardContent>
                    {t('OFFERS_ADVERTS.SENT_DESCRIPTION')}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card
                variant={'outlined'}
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <CardActionArea
                  sx={{
                    height: '100%',
                    flexDirection: 'column',
                  }}
                  onClick={() => {
                    setOfferType('RECEIVED')
                    forceUpdate(forceUpdateCount + 1)
                  }}
                >
                  <CardHeader
                    avatar={
                      <Send
                        sx={{
                          transform: 'scaleX(-1)',
                        }}
                      />
                    }
                    title={t('OFFERS.RECEIVED')}
                  />
                  <CardContent>
                    {t('OFFERS_ADVERTS.RECEIVED_DESCRIPTION')}
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {selectedOffer && (
        <Fragment>
          {showAcceptOfferDialog && (
            <AcceptOfferDialog
              selectedOffer={selectedOffer}
              hide={() => setShowAcceptOfferDialog(false)}
              forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
            />
          )}
          {showSendOfferDialog && (
            <AcceptCounterOfferDialog
              selectedOffer={selectedOffer}
              hide={() => setShowSendOfferDialog(false)}
              forceUpdate={() => {
                forceUpdate(forceUpdateCount + 1)
              }}
            />
          )}
          <AgreementDialog
            open={showDeclineOfferDialog}
            handleClose={() => {
              setShowDeclineOfferDialog(false)
            }}
            agree={() => {
              setIsLoading(true)
              offersService
                .declineOffer(selectedOffer.id)
                .catch(function (error) {
                  console.log(error)
                })
                .finally(() => {
                  setIsLoading(false)
                  forceUpdate(forceUpdateCount + 1)
                  setShowDeclineOfferDialog(false)
                })
            }}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default withTranslation()(OffersAdverts)
