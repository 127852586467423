import { Close as CloseIcon, Sort } from '@mui/icons-material'
import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  TablePagination,
  Toolbar,
  Tooltip,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { GoogleMap } from '@react-google-maps/api'
import PropTypes from 'prop-types'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Loading } from '../Loading'
import ResourceListCardList from '../ResourceListCardList'
import Filter from '../filter'
import ResourceMapGoogleMapChildren from './_components/ResourceMapGoogleMapChildren'

const PREFIX = 'index'

const classes = {
  selectedCard: `${PREFIX}-selectedCard`,
  customAlert: `${PREFIX}-customAlert`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.selectedCard}`]: {
    backgroundColor: '#e0e0e0',
  },

  [`& .${classes.customAlert}`]: {
    backgroundColor: '#f8f9fa',
    '& .MuiAlert-icon': {
      color: '#6C6C6C',
      alignItems: 'center',
    },
  },
}))

const ResourceMap = (props) => {
  const {
    t,
    listHeaderRenderFunction,
    headerActionRenderFunction,
    contentRenderFunction,
    resourceHeaderRenderFunction,
    resourceRenderFunction,
    actionsRenderFunction,
    toolbar,
    toolbarSecondary,
    dataIdentifier,
    alert,
    sorting,
    fetchMethod,
    filters,
    filterId,
    forceUpdateCount,
    autoSelectFirstElement,
    geoCoordinatePath,
    mouseOverFunction,
    iconFunction,
    googleMapChildrenFunction,
    isPaginationEnabled,
  } = props

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState([])
  const [filterValues, setFilterValues] = useState(null)

  const [selectedItem, setSelectedItem] = useState(null)
  const [actions, setActions] = useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedSort, setSelectedSort] = useState(
    sorting ? sorting.find((sortObj) => sortObj.default).key : null,
  )
  const [showSort, setShowSort] = useState(false)
  //Force update on resize: https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
  const [size, setSize] = useState([0, 0])

  const [map, setMap] = useState(null)

  const [origin, setOrigin] = useState(null)
  const [radius, setRadius] = useState(250 * 1000)
  const [hoveredItem, setHoveredItem] = useState(null)
  const [hoveredItemCircleRadius, setHoveredItemCircleRadius] = useState(250000)

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const gridContainerRef = useRef(null)
  const scrollableListRef = useRef(null)

  const theme = useTheme()

  const isMediumOrHigher = useMediaQuery(theme.breakpoints.up('lg'))

  useEffect(() => {
    if (!filterValues) {
      //Filter not initialized
      return
    }
    setIsLoading(true)

    let mappedFilterValues = Object.fromEntries(
      Object.entries(filterValues).filter(([key, value]) => {
        return (
          (!Array.isArray(value) && value) ||
          (Array.isArray(value) && value.length > 0)
        )
      }),
    )
    if (fetchMethod) {
      fetchMethod(
        filterValues && Object.keys(filterValues).length > 0
          ? mappedFilterValues
          : undefined,
        {
          sortBy: selectedSort,
        },
      )
        .then((result) => {
          setData(result.data)
          // Überprüfe Abstände und füge Offset hinzu
          result.data.forEach((entity1, index1) => {
            result.data.forEach((entitity2, index2) => {
              if (index1 !== index2) {
                const distanceLat = Math.abs(
                  (geoCoordinatePath
                    ? entity1[geoCoordinatePath].latitude
                    : entity1.latitude) -
                    (geoCoordinatePath
                      ? entitity2[geoCoordinatePath].latitude
                      : entitity2.latitude),
                )

                const distanceLng = Math.abs(
                  (geoCoordinatePath
                    ? entity1[geoCoordinatePath].longitude
                    : entity1.longitude) -
                    (geoCoordinatePath
                      ? entitity2[geoCoordinatePath].longitude
                      : entitity2.longitude),
                )

                if (distanceLat < threshold && distanceLng < threshold) {
                  if (geoCoordinatePath) {
                    entity1[geoCoordinatePath].latitude = addRandomOffset(
                      entity1[geoCoordinatePath].latitude,
                    )
                    entity1[geoCoordinatePath].longitude = addRandomOffset(
                      entity1[geoCoordinatePath].longitude,
                    )
                  } else {
                    entity1.latitude = addRandomOffset(entity1.latitude)
                    entity1.longitude = addRandomOffset(entity1.longitude)
                  }
                }
              }
            })
          })

          if (autoSelectFirstElement && result.count > 0) {
            setSelectedItem(result.data[0])
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [filterValues, forceUpdateCount, selectedSort])

  useEffect(() => {
    if (selectedItem) {
      let item = data.find(
        (item) => item[dataIdentifier] === selectedItem[dataIdentifier],
      )
      setActions(actionsRenderFunction(item))
    }
  }, [selectedItem, data, actionsRenderFunction])

  useEffect(() => {
    let growing = true
    const growCircle = () => {
      if (hoveredItemCircleRadius > 25000) {
        growing = false
      } else if (hoveredItemCircleRadius < 10000) {
        growing = true
      }

      setHoveredItemCircleRadius(hoveredItemCircleRadius + (growing ? 50 : -50))
    }

    const interval = setInterval(growCircle, 100)

    return () => clearInterval(interval) // Aufräumen, wenn die Komponente unmounted wird
  }, [])

  const threshold = 0.0001

  const addRandomOffset = (coordinate) => {
    const offset = (Math.random() - 0.5) / 300
    return coordinate + offset
  }

  const onLoad = useCallback(function callback(map) {
    const center = new window.google.maps.LatLng(51.1657, 10.4515)
    map.setCenter(center)
    map.setZoom(6.8)

    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    scrollableListRef.current.scrollTo(0, 0)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  if (selectedItem) {
    var doesSelectedItemExists = data.find(
      (item) => item[dataIdentifier] === selectedItem[dataIdentifier],
    )
    if (!doesSelectedItemExists) {
      setSelectedItem(null)
      return null
    }
  }

  let containerHeight = 0
  if (gridContainerRef.current) {
    containerHeight =
      window.innerHeight -
      gridContainerRef.current.offsetTop -
      73 -
      (alert.hasOwnProperty('type') ? 44 : 0)
  }

  return selectedItem && !doesSelectedItemExists ? null : (
    <StyledGrid container ref={gridContainerRef}>
      <Grid item xs={isMediumOrHigher ? 4 : 12}>
        <Paper
          elevation={8}
          sx={{
            marginRight: 1,
            height: containerHeight,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              /* for Firefox */
              minHeight: 0,
            }}
          >
            {toolbarSecondary && (
              <Box margin={2} marginBottom={0.5}>
                {toolbarSecondary}
              </Box>
            )}

            <Box
              margin={2}
              marginBottom={0.5}
              display="flex"
              flexDirection="column"
            >
              <Grid item xs={12} sm={6}>
                {sorting && (
                  <Button
                    sx={{
                      marginRight: theme.spacing(2),
                    }}
                    size={'small'}
                    variant={'text'}
                    color={'secondary'}
                    startIcon={<Sort />}
                    onClick={(e) => {
                      setAnchorEl(e.currentTarget)
                      setShowSort(true)
                    }}
                  >
                    {t('RESOURCE_LIST.SORT_BY')}
                  </Button>
                )}

                {data.length === 0 &&
                  filterValues &&
                  filters
                    .filter((filter) => filter.nothingFoundMessage)
                    .map((filter) =>
                      (Array.isArray(filterValues[filter.key]) &&
                        filterValues[filter.key].length > 0) ||
                      (!Array.isArray(filterValues[filter.key]) &&
                        filterValues[filter.key]) ? (
                        <Alert severity="info" sx={{ margin: 1 }}>
                          {filter.nothingFoundMessage}
                        </Alert>
                      ) : null,
                    )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Filter
                  id={filterId}
                  filterValues={filterValues}
                  saveFilterValues={true}
                  setFilterValues={setFilterValues}
                  filters={[
                    {
                      key: 'geo',
                      label: t('MARKETPLACE.STARTING_POINT'),
                      variant: 'geo',
                      shortcut: true,
                    },
                  ].concat(filters)}
                  map={map}
                  setGeoData={(origin, radius) => {
                    setOrigin(origin)
                    setRadius(radius)
                  }}
                />
              </Grid>
              <Grid item>{toolbar}</Grid>
            </Box>
            {sorting && (
              <Popover
                onClose={() => {
                  setShowSort(false)
                }}
                anchorEl={anchorEl}
                open={showSort}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Container sx={{ padding: theme.spacing(2) }}>
                  <RadioGroup
                    value={selectedSort}
                    onChange={(e) => {
                      setSelectedSort(e.target.value)
                    }}
                  >
                    {sorting.map((sortObj) => (
                      <FormControlLabel
                        value={sortObj.key}
                        control={<Radio />}
                        label={sortObj.label}
                      />
                    ))}
                  </RadioGroup>
                </Container>
              </Popover>
            )}

            <Divider />
            <ResourceListCardList
              setHoveredItem={setHoveredItem}
              data={
                isPaginationEnabled
                  ? data.slice(
                      page * rowsPerPage,
                      rowsPerPage + page * rowsPerPage,
                    )
                  : data
              }
              scrollableListRef={scrollableListRef}
              isLoading={isLoading}
              listHeaderRenderFunction={listHeaderRenderFunction}
              headerActionRenderFunction={headerActionRenderFunction}
              contentRenderFunction={contentRenderFunction}
              dataIdentifier={dataIdentifier}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
            <AppBar
              position={'relative'}
              color={'default'}
              sx={{ top: 'auto', bottom: 0 }}
            >
              <Toolbar>
                <Box sx={{ flexGrow: 1 }}>
                  {isPaginationEnabled && (
                    <TablePagination
                      rowsPerPageOptions={[25]}
                      component="div"
                      showFirstButton
                      showLastButton
                      count={data.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </Box>
              </Toolbar>
            </AppBar>
          </div>
        </Paper>
      </Grid>

      <Grid item xs={8}>
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '100%',
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={{
            styles: [
              {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
              },
              {
                featureType: 'poi.business',
                stylers: [{ visibility: 'off' }],
              },
            ],
            streetViewControl: false,
            fullscreenControl: false,
          }}
        >
          {isLoading ? (
            <Loading variant={'centered'} />
          ) : (
            <ResourceMapGoogleMapChildren
              data={data}
              setSelectedItem={setSelectedItem}
              geoCoordinatePath={geoCoordinatePath}
              mouseOverFunction={mouseOverFunction}
              iconFunction={iconFunction}
              origin={origin}
              radius={radius}
              googleMapChildrenFunction={googleMapChildrenFunction}
              hoveredItem={hoveredItem}
            />
          )}
        </GoogleMap>
      </Grid>
      {selectedItem && !isLoading && (
        <Dialog
          fullWidth={true}
          maxWidth={'xl'}
          open={true}
          onClose={() => {
            setSelectedItem(null)
            setActions(null)
          }}
        >
          <DialogTitle>
            {resourceHeaderRenderFunction
              ? resourceHeaderRenderFunction(
                  data.find(
                    (item) =>
                      item[dataIdentifier] === selectedItem[dataIdentifier],
                  ),
                )
              : null}
            <IconButton
              aria-label="close"
              onClick={() => {
                setSelectedItem(null)
                setActions(null)
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              {resourceRenderFunction
                ? resourceRenderFunction(
                    data.find(
                      (item) =>
                        item[dataIdentifier] === selectedItem[dataIdentifier],
                    ),
                  )
                : null}
            </Box>
          </DialogContent>
          <DialogActions>
            {selectedItem &&
            actions &&
            actions.filter((action) => !action.hidden).length > 1 ? (
              <Fragment>
                {actions
                  .filter((action) => !action.primary)
                  .map((action) => (
                    <Tooltip title={action.tooltip ? action.tooltip : ''}>
                      <div>
                        {!action.hidden && (
                          <Button
                            startIcon={action.icon}
                            size={'small'}
                            variant={'outlined'}
                            color={'secondary'}
                            disabled={action.disabled}
                            onClick={() => {
                              action.onClick()
                            }}
                          >
                            {action.name}
                          </Button>
                        )}
                      </div>
                    </Tooltip>
                  ))}
                <Divider />
                {actions
                  .filter((action) => action.primary)
                  .map((action) => (
                    <Tooltip title={action.tooltip ? action.tooltip : ''}>
                      <div>
                        {!action.hidden && (
                          <Button
                            startIcon={action.icon}
                            size={'small'}
                            variant={'contained'}
                            color={'secondary'}
                            disabled={action.disabled}
                            onClick={() => {
                              action.onClick()
                            }}
                          >
                            {action.name}
                          </Button>
                        )}
                      </div>
                    </Tooltip>
                  ))}
              </Fragment>
            ) : selectedItem &&
              actions &&
              actions.filter((action) => !action.hidden).length === 1 ? (
              actions
                .filter((action) => !action.hidden)
                .map((action) => (
                  <Tooltip title={action.tooltip ? action.tooltip : ''}>
                    <div>
                      {!action.hidden && (
                        <Button
                          startIcon={action.icon}
                          size={'small'}
                          variant={'contained'}
                          color={'secondary'}
                          disabled={action.disabled}
                          onClick={() => {
                            action.onClick()
                          }}
                        >
                          {action.name}
                        </Button>
                      )}
                    </div>
                  </Tooltip>
                ))
            ) : null}
          </DialogActions>
        </Dialog>
      )}
    </StyledGrid>
  )
}

ResourceMap.propTypes = {
  dataIdentifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  filter: PropTypes.func,
}

function mapStateToProps(state) {
  const { alert } = state
  return {
    alert,
  }
}

export default withTranslation()(connect(mapStateToProps)(ResourceMap))
