import {
  Euro,
  EventAvailable as GotoOrdersIcon,
  LocalOffer as OfferIcon,
} from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import moment from 'moment'
import Numeral from 'numeral'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import {
  advertsExchangeRequestStateLookup,
  categorizedProductLookup,
  countryLookup,
  deliveryTypeLookup,
  marketplaceRequestStateLookup,
  productLookup,
  productStateLookup,
} from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import { requestsService } from '../../../../_services/requestsService'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { CustomRating } from '../../../CustomRating'
import { InfoTooltip } from '../../../InfoTooltip'
import Offer from '../../../Offer'
import { PositionsList } from '../../../PositionsList'
import RequestDetails from '../../../RequestDetails'
import ResourceMap from '../../../ResourceMap'
import ViewDescription from '../../../ViewDescription'

const AdvertsExchange = (props) => {
  const { t } = props

  const [requests, setRequests] = React.useState([])
  const [showOfferModal, setShowOfferModal] = React.useState(false)
  const [selectedRequest, setSelectedRequest] = React.useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLessThanLg = useMediaQuery(theme.breakpoints.down('lg'))
  const userObject = JSON.parse(localStorage.getItem('user'))

  const closeOfferDialog = (offer) => {
    setShowOfferModal(false)
    setRequests(
      requests.map((request) => {
        if (request.id === offer.requestId) {
          request.offerAmount = 1
        }
        return {
          ...request,
        }
      }),
    )
  }

  const navigate = useNavigate()
  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)
  let defaultValueRequestId = params.get('id')

  return (
    <Fragment>
      <ViewDescription description={t('ADVERTS_EXCHANGE.VIEW_DESCRPTION')} />
      {
        <Fragment>
          <ResourceMap
            geoCoordinatePath={'location'}
            fetchMethod={(filterValues, pagination) =>
              requestsService.getPublishedRequests(
                ['ADVERT'],
                userObject.partner.id,
                filterValues,
                pagination,
              )
            }
            iconFunction={(request) => {
              return {
                url: '/icons/map/Pacurion_Iconset_Map_Orange_Inserate.svg',
                scaledSize: new window.google.maps.Size(90, 90),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(45, 45),
              }
            }}
            mouseOverFunction={(request) => (
              <Stack spacing={1}>
                <Typography variant="h6">
                  {`${t('MARKETPLACE.ADVERT_ID')} ${request.id}`}
                </Typography>
                <Chip
                  size={'medium'}
                  color={
                    request.offerState === 'SENT'
                      ? 'info'
                      : request.offerState === 'SUCCESSFUL'
                      ? 'success'
                      : 'default'
                  }
                  label={marketplaceRequestStateLookup[request.offerState]}
                />
                <Fragment>
                  <div>
                    {`${moment(request.publishedAt)
                      .local()
                      .format('DD.MM.YYYY')}`}
                  </div>
                  <div>
                    {authorize('administrator')
                      ? `${request.user.firstname} ${request.user.lastname} | ${
                          authorize('administrator')
                            ? `${request.partner.name} (${request.partner.id})`
                            : ''
                        }`
                      : ''}
                  </div>
                  {`${request.location.zipcode} | ${request.location.place} | ${
                    countryLookup[request.location.countryId]
                  }`}
                  <div>
                    {authorize('administrator')
                      ? `Angebote: ${request.offerAmountAll} | Aufträge: ${
                          request.orderAmountAll + request.tenderOrderAmountAll
                        }`
                      : null}
                  </div>
                </Fragment>
                <PositionsList positions={request.positions} />
              </Stack>
            )}
            filterId={'AdvertsExchange'}
            forceUpdateCount={forceUpdateCount}
            filters={[
              {
                key: 'id.startsWith',
                label: t('MARKETPLACE.ADVERT_ID'),
                variant: 'textfield',
                defaultValue: defaultValueRequestId,
              },
              {
                key: 'positions.productId.eq',
                variant: 'categorizedCheckboxList',
                values: categorizedProductLookup.map((category) => {
                  return {
                    label: category.name,
                    items: category.products.map((product) => product.id),
                  }
                }),
                lookup: productLookup,
                label: t('MARKETPLACE.PRODUCT'),
                shortcut: true,
              },
              {
                key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(productStateLookup),
                lookup: productStateLookup,
                label: t('MARKETPLACE.PRODUCT_STATE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(deliveryTypeLookup),
                lookup: deliveryTypeLookup,
                label: t('MARKETPLACE.DELIVERY_TYPE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=1&positions.positionProperties.value',
                label: t('MARKETPLACE.PRODUCT_LENGTH'),
                variant: 'textfield',
              },
              {
                key: 'positions.positionProperties.productPropertyId=2&positions.positionProperties.value',
                label: t('MARKETPLACE.PRODUCT_WIDTH'),
                variant: 'textfield',
              },
              {
                key: 'positions.location.countryId.eq',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(countryLookup),
                lookup: countryLookup,
                label: t('MARKETPLACE.COUNTRY'),
              },
              {
                key: 'positions.location.zipcode.startsWith',
                label: t('MARKETPLACE.ZIPCODE'),
                variant: 'textfield',
              },
              {
                key: 'positions.location.place.startsWith',
                label: t('MARKETPLACE.PLACE'),
                variant: 'textfield',
              },
            ]}
            hint={t('ADVERTS_EXCHANGE.VIEW_DESCRPTION')}
            selectionText={t('ADVERTS_EXCHANGE.SELECT_AN_ADVERT')}
            sorting={[
              {
                key: 'id',
                default: true,
                label: t('MARKETPLACE.NEWEST_FIRST'),
              },
            ]}
            resourceHeaderRenderFunction={(request) =>
              `${t('ADVERTS_EXCHANGE.ADVERT')}: ${request.id}`
            }
            listHeaderRenderFunction={(request) => (
              <Fragment>
                <div>
                  {`${t('MARKETPLACE.ADVERT')}
                  : ${request.id}  | ${moment(request.publishedAt)
                    .local()
                    .format('DD.MM.YYYY')}`}
                </div>
                <div>
                  {authorize('administrator')
                    ? `${request.user.firstname} ${request.user.lastname} | ${request.partner.name} (${request.partner.id})`
                    : ''}
                </div>
                {`${request.location.zipcode} | ${request.location.place} | ${
                  countryLookup[request.location.countryId]
                }`}
              </Fragment>
            )}
            headerActionRenderFunction={(request) => {
              let averageRating =
                (parseFloat(request.averageQuality) +
                  parseFloat(request.averageReliability)) /
                2

              return (
                <Fragment>
                  <Stack direction={'column'}>
                    <Stack
                      direction={isMobile ? 'row-reverse' : 'row'}
                      spacing={1}
                    >
                      {request.community && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: isMobile ? 'flex-start' : 'center',
                            marginRight: '10px',
                          }}
                        >
                          <InfoTooltip title={request.partner.name}>
                            <GroupsIcon color={'secondary'} />
                          </InfoTooltip>
                        </div>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'right',
                          alignItems: 'center',
                        }}
                      >
                        <Chip
                          size={'small'}
                          sx={{
                            padding: 1,
                          }}
                          color={
                            request.offerState === 'SENT'
                              ? 'info'
                              : request.offerState === 'SUCCESSFUL'
                              ? 'success'
                              : 'default'
                          }
                          label={
                            advertsExchangeRequestStateLookup[
                              request.offerState
                            ]
                          }
                        />
                      </div>
                    </Stack>
                    <Box padding={1}>
                      {request.averageQuality ? (
                        <InfoTooltip
                          title={
                            <table>
                              <thead>
                                <th
                                  style={{
                                    borderRight: 'solid 15px transparent',
                                  }}
                                >
                                  {t('OFFERS.PARTNER_RATING')}
                                </th>
                                <th>
                                  {Numeral(
                                    (parseFloat(request.averageQuality) +
                                      parseFloat(request.averageReliability)) /
                                      2,
                                  ).format('0,0.00')}
                                </th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {t('OFFERS.RATING_QUALITY')}
                                  </td>
                                  <td>
                                    {Numeral(
                                      parseFloat(request.averageQuality),
                                    ).format('0,0.00')}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {t('OFFERS.RATING_RELIABILITY')}
                                  </td>
                                  <td>
                                    {Numeral(
                                      parseFloat(request.averageReliability),
                                    ).format('0,0.00')}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {t('OFFERS.TOTAL_RECEIVED_RATINGS')}
                                  </td>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {request.ratingAmount}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: isMobile ? 'left' : 'right',
                              alignItems: { xs: 'flex-start', sm: 'center' },
                            }}
                          >
                            <CustomRating
                              size={'small'}
                              name="read-only"
                              value={averageRating}
                              readOnly
                              precision={0.5}
                            >
                              {`(${request.ratingAmount})`}
                            </CustomRating>
                          </div>
                        </InfoTooltip>
                      ) : (
                        <div
                          style={{
                            textAlign: isMobile ? 'left' : 'right',
                          }}
                        >
                          <Typography variant="caption">
                            {t('OFFERS.NOT_ENOUGH_RATINGS_YET')}
                          </Typography>
                        </div>
                      )}
                    </Box>
                  </Stack>
                </Fragment>
              )
            }}
            contentRenderFunction={(request) => (
              <PositionsList positions={request.positions} />
            )}
            resourceRenderFunction={(request) => {
              return <RequestDetails requestId={request.id} />
            }}
            actionsRenderFunction={(request) => {
              return [
                {
                  icon: <GotoOrdersIcon />,
                  name:
                    request.offerState !== 'SUCCESSFUL'
                      ? t('MARKETPLACE.NO_ORDERS_YET')
                      : t('MARKETPLACE.GOTO_ORDERS'),
                  hidden: request.offerState !== 'SUCCESSFUL',
                  primary: false,
                  onClick: () =>
                    navigate({
                      pathname: '/deliveries-customer',
                      search: '?requestId=' + request.id,
                    }),
                },
                {
                  icon: <OfferIcon />,
                  name:
                    request.offerAmount === 0
                      ? t('MARKETPLACE.NO_OFFER_YET')
                      : t('MARKETPLACE.GOTO_OFFER'),
                  hidden: request.offerAmount === 0,
                  primary: false,
                  onClick: () =>
                    navigate({
                      pathname: '/offers-adverts',
                      search: `requestId=${request.id}&offerType=SENT`,
                    }),
                },
                {
                  icon: <Euro />,
                  name:
                    request.offerAmount > 0
                      ? t('ADVERTS_EXCHANGE.INTEREST_ALREADY_SHOWN')
                      : t('MARKETPLACE.SHOW_INTEREST'),
                  hidden: request.offerAmount > 0,
                  primary: true,
                  onClick: () =>
                    requestsService.getRequest(request.id).then((data) => {
                      setSelectedRequest(data)
                      setShowOfferModal(true)
                    }),
                },
              ]
            }}
            data={requests}
            dataIdentifier={'id'}
          />
          <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={showOfferModal}
            fullScreen={isLessThanLg}
          >
            <CustomDialogTitle
              title={t('MARKETPLACE.CREATE_OFFER')}
              onClose={() => setShowOfferModal(false)}
            />
            <DialogContent style={{ minHeight: '80vh' }}>
              <Offer
                closeOfferDialog={(offer) => closeOfferDialog(offer)}
                requestId={selectedRequest ? selectedRequest.id : null}
                key={selectedRequest ? selectedRequest.id : null}
                requestType={'ADVERTS'}
                forceUpdate={() => {
                  forceUpdate(forceUpdateCount + 1)
                }}
              />
            </DialogContent>
          </Dialog>
        </Fragment>
      }
    </Fragment>
  )
}

export default withTranslation()(AdvertsExchange)
