import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryLookup,
  orderStateLookup,
} from '../../../../../../../../_constants/lookupConstants'
import {
  formatToNumber,
  formatToPrice,
} from '../../../../../../../../_helpers/number-formatting'
import { generalService } from '../../../../../../../../_services/generalService'
import { orderPositionsService } from '../../../../../../../../_services/orderPositionsService'
import Filter from '../../../../../../../filter'

const OrdersOfAutomaticOfferPositionDialog = (props) => {
  const { t, automaticOfferPositionId } = props
  const [filterValues, setFilterValues] = useState(null)
  const [orderPositions, setOrderPositions] = useState([])
  const [offset, setOffSet] = useState(0)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [isLoading, setIsLoading] = useState(false)

  const handleChangePage = (event, newPage) => {
    setOffSet(newPage)
  }

  useEffect(() => {
    if (!filterValues) {
      //Filter not initialized
      return
    }
    let mappedFilterValues = Object.fromEntries(
      Object.entries(filterValues).filter(([key, value]) => {
        return (
          (!Array.isArray(value) && value) ||
          (Array.isArray(value) && value.length > 0)
        )
      }),
    )
    setIsLoading(true)

    orderPositionsService
      .getOrderPositionsOfAutomaticOfferPosition(
        automaticOfferPositionId,
        mappedFilterValues,
        offset * 10,
        limit,
      )
      .then((data) => {
        setOrderPositions(data.orderPositions)
        setCount(data.count)
        setIsLoading(false)
      })
  }, [automaticOfferPositionId, filterValues, offset, limit])

  const filters = [
    {
      key: 'orderId',
      variant: 'textfield',
      label: t('ORDERS.ORDER_ID'),
    },
    {
      key: 'order.state',
      variant: 'checkboxList',
      values: Object.getOwnPropertyNames(orderStateLookup),
      lookup: orderStateLookup,
      label: t('ORDERS.STATE'),
      shortcut: true,
    },
    {
      key: 'order.countryId.eq',
      variant: 'checkboxList',
      values: Object.getOwnPropertyNames(countryLookup),
      lookup: countryLookup,
      label: t('ORDERS.COUNTRY'),
    },
    {
      key: 'order.zipcode.startsWith',
      label: t('ORDERS.ZIPCODE'),
      variant: 'textfield',
    },
    {
      key: 'order.place.startsWith',
      label: t('ORDERS.PLACE'),
      variant: 'textfield',
    },
  ]

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box margin={1}>
            <Filter
              id={'AUTOMATIC_OFFERPOSITION_ORDERS'}
              filterValues={filterValues}
              saveFilterValues={true}
              setFilterValues={(fValues) => {
                setFilterValues(fValues)
              }}
              filters={filters}
            />
          </Box>
        </Grid>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('AUTOMATIC_OFFERS.ORDER_ID')}</TableCell>
                <TableCell>{t('AUTOMATIC_OFFERS.DELIVERY_PLACE')}</TableCell>
                <TableCell>{t('AUTOMATIC_OFFERS.AMOUNT')}</TableCell>
                <TableCell>{t('AUTOMATIC_OFFERS.DELIVERY_DATES')}</TableCell>
                <TableCell>{t('AUTOMATIC_OFFERS.PRICE')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orderPositions.length > 0 ? (
                orderPositions.map((orderPosition) => (
                  <TableRow
                    key={orderPosition.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {orderPosition.offerId}
                    </TableCell>
                    <TableCell>{`${orderPosition.company}, ${
                      orderPosition.zipcode
                    } ${orderPosition.place}, ${
                      countryLookup[orderPosition.countryId]
                    }`}</TableCell>
                    <TableCell>
                      {formatToNumber(orderPosition.amount)}
                    </TableCell>
                    <TableCell>
                      {orderPosition.deliveryType === 'date'
                        ? orderPosition.deliveryValue
                        : generalService.convertDateToCalendarWeek(
                            orderPosition.deliveryValue,
                          )}
                    </TableCell>
                    <TableCell>{formatToPrice(orderPosition.price)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow style={{ height: '200px' }}>
                  <TableCell
                    colSpan={5}
                    align="center"
                    style={{ paddingTop: '40px', paddingBottom: '40px' }}
                  >
                    {t('AUTOMATIC_OFFERS.NO_DATA_YET')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            showFirstButton={true}
            showLastButton={true}
            count={count?.count?.length}
            page={offset}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
          />
        </TableContainer>
      </Grid>
    </Fragment>
  )
}

export default withTranslation()(OrdersOfAutomaticOfferPositionDialog)
