import { Close } from '@mui/icons-material'
import { DialogTitle, Grid, IconButton } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'

const CustomDialogTitle = (props) => {
  const { title, onClose } = props
  return (
    <DialogTitle>
      <Grid container style={{ lineHeight: '33px' }}>
        <Grid item xs>
          {title}
        </Grid>
        <Grid item>
          <IconButton
            onClick={(e) => {
              onClose()
            }}
            style={{ padding: '6px' }}
            size="large"
          >
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  )
}

export default withTranslation()(CustomDialogTitle)
