import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import useOCIPunchout from '../../../../../../_hooks/useOCIPunchout'
import CustomDialogTitle from '../../../../../CustomDialogTitle'

const RetroactivePunchoutDialog = (props) => {
  const { order, hide, show, t } = props

  const { punchout } = useOCIPunchout()

  let hookURL = sessionStorage.getItem('HOOK_URL')

  return (
    <Dialog fullWidth={true} maxWidth="lg" open={show}>
      <CustomDialogTitle
        title={t('ORDERS.RETRO_ACTIVE_PUNCHOUT')}
        onClose={() => hide()}
      />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Alert severity="info">
              {t('ORDERS.RETRO_ACTIVE_PUNCHOUT_INFO')}
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {t('ORDERS.RETRO_ACTIVE_PUNCHOUT_DESCRIPTION')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          style={{ margin: '0 10px 0px 10px' }}
          onClick={() => {
            hide()
          }}
        >
          {t('GENERAL.BACK')}
        </Button>
        <Button
          onClick={() =>
            punchout(
              hookURL,
              order.positions,
              order.stackHeight,
              order.stackHeightUnit,
              order.request ? order.request.id : undefined,
              [order.id],
            )
          }
          type="submit"
          variant="contained"
          color="secondary"
        >
          {t('ORDERS.PUNCHOUT')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(RetroactivePunchoutDialog)
