import { Button, Grid } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import { mailSettingsService } from '../../../../_services/mailSettingsService'
import { userMailSettingsService } from '../../../../_services/userMailSettingsService'
import { Loading } from '../../../Loading'

const Unsubscribe = (props) => {
  const { t } = props

  const [isLoading, setIsLoading] = useState(false)
  const [unSubscribe, setUnSubscribe] = useState(null)
  const [subscribe, setSubscribe] = useState(null)

  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)
  let userId = params.get('id')
  let token = params.get('token')

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true)
      const mailSettings = await mailSettingsService.getMailSettings()

      let mailSettingJobId = mailSettings.find(
        (mailSetting) => mailSetting.name === 'JOB',
      ).id
      const userMailSettings =
        await userMailSettingsService.getUserMailSettingsOfUser(userId)

      let userMailSettingsId = userMailSettings.find(
        (userMailSetting) => userMailSetting.mailSettingId === mailSettingJobId,
      ).id
      userMailSettingsService
        .updateUserMailSetting(userMailSettingsId, false, token)
        .then((unSubscribe) => {
          setUnSubscribe(unSubscribe)
        })
        .finally(() => setIsLoading(false))
    }

    if (userId) {
      fetchData()
    }
  }, [userId])

  return isLoading ? (
    <Loading variant={'center'} />
  ) : (
    <Fragment>
      {unSubscribe && (
        <Fragment>
          {unSubscribe.type === 'Success' ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {t('UNSUBSCRIBE.SUCCESS')}
              </Grid>

              <Grid item xs={12}>
                {t('UNSUBSCRIBE.ASK_FOR_RESUBSCRIPTION')}
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant={'contained'}
                  color={'secondary'}
                  onClick={async () => {
                    setIsLoading(true)
                    const mailSettings =
                      await mailSettingsService.getMailSettings()

                    let mailSettingJobId = mailSettings.find(
                      (mailSetting) => mailSetting.name === 'JOB',
                    ).id
                    const userMailSettings =
                      await userMailSettingsService.getUserMailSettingsOfUser(
                        userId,
                      )

                    let userMailSettingsId = userMailSettings.find(
                      (userMailSetting) =>
                        userMailSetting.mailSettingId === mailSettingJobId,
                    ).id
                    userMailSettingsService
                      .updateUserMailSetting(userMailSettingsId, true, token)
                      .then((response) => {
                        setSubscribe(response)
                        setUnSubscribe(false)
                      })
                      .finally(() => setIsLoading(false))
                  }}
                >
                  {t('UNSUBSCRIBE.SUBSCRIBE')}
                </Button>
              </Grid>
            </Grid>
          ) : (
            t('UNSUBSCRIBE.FAILED')
          )}
        </Fragment>
      )}
      {subscribe && (
        <Fragment>
          {subscribe.type === 'Success' ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {t('UNSUBSCRIBE.RESUBSCRIBE_SUCCES')}
              </Grid>
            </Grid>
          ) : (
            t('UNSUBSCRIBE.RESUBSCIRBE_FAILED')
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default withTranslation()(connect()(Unsubscribe))
