import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { _applicationService } from '../../../../_services/_applicationService'
import { Loading } from '../../../Loading'

const PartnerChecker = (props) => {
  const { t } = props

  const [results, setResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        searchValue: '',
      }}
      //validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        setIsLoading(true)
        setSubmitting = true

        try {
          let data = await _applicationService.searchPartners(values)

          setResults(data)
        } catch (err) {
          alert(err)
        } finally {
          setIsLoading(false)
          setSubmitting = false
        }
      }}
    >
      {({ values, setFieldValue, errors, submitForm }) => {
        return (
          <Form>
            <Grid container spacing={1} direction="row" alignItems="center">
              <Grid item>
                <Field
                  component={TextField}
                  label={t('PARTNER_CHECKER.NAME')}
                  name={'searchValue'}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={() => submitForm()}
                  variant="contained"
                  color={'secondary'}
                  disabled={values.searchValue.length < 2}
                >
                  {t('GENERAL.SUBMIT')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                {(isLoading && <Loading variant={'centered'} />) || (
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={2}>
                            <Typography variant={'h5'}>
                              {t('PARTNER_CHECKER.RESULTS')}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('PARTNER_CHECKER.NAME')}</TableCell>
                          <TableCell>{t('PARTNER_CHECKER.OWNER')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {results.length > 0 ? (
                          <Fragment>
                            {results.sort().map((result, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {result.name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {result.owner ? result.owner.name : ''}
                                </TableCell>
                              </TableRow>
                            ))}
                          </Fragment>
                        ) : (
                          <TableRow style={{ height: '200px' }}>
                            <TableCell
                              colSpan={5}
                              align="center"
                              style={{
                                paddingTop: '40px',
                                paddingBottom: '40px',
                              }}
                            >
                              {t('AUTOMATIC_OFFERS.NO_DATA_YET')}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default withTranslation()(PartnerChecker)
