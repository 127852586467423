import MaterialTable from '@material-table/core'
import LogoutIcon from '@mui/icons-material/Logout'
import { Box, Tab, Tabs, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { tableIcons, tableLocalization } from '../../../../_helpers/muiTable'
import { communitiesService } from '../../../../_services/communitiesService'
import { communityPartnersService } from '../../../../_services/communityPartnersService'
import AgreementDialog from '../../../AgreementDialog'
import { Loading } from '../../../Loading'
import ViewDescription from '../../../ViewDescription'
import CommunityInvitations from './CommunityInvitations'
import MyCommunities from './MyCommunities'

const CommunitiesOverview = ({ t }) => {
  const [isLoading, setIsLoading] = useState(false)

  const [communities, setCommunities] = useState(null)
  const [selectedCommunity, setSelectedCommunity] = useState(null)
  const [forceUpdateCount, forceUpdate] = React.useState(0)
  const userObject = JSON.parse(localStorage.getItem('user'))
  const partnerId = userObject.partner.id
  const [showExitCommunityDialog, setShowExitCommunityDialog] = useState(false)
  const [value, setValue] = React.useState(0)
  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return value === index ? (
      <Typography
        component="div"
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    ) : null
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  useEffect(() => {
    setIsLoading(true)
    communitiesService
      .getCommunities(partnerId)
      .then((data) => {
        const filteredCommunities = data.filter(
          (community) => community.ownerPartnerId !== partnerId,
        )
        setCommunities(filteredCommunities)
      })
      .catch((error) => {
        console.error('Error fetching communities:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [partnerId, forceUpdateCount])

  const tableActions = [
    {
      icon: () => <LogoutIcon color={'secondary'} />,
      tooltip: t('COMMUNITY.EXIT'),
      onClick: (event, rowData) => {
        let selectedCommunity = rowData
        setShowExitCommunityDialog(true)
        setSelectedCommunity(selectedCommunity)
      },
    },
  ]
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Fragment>
      {isLoading ? (
        <Loading variant={'centered'} />
      ) : (
        <Fragment>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label={t('COMMUNITY.OVERVIEW')} {...a11yProps(0)} />
            <Tab label={t('COMMUNITY.MY_COMMUNITIES')} {...a11yProps(1)} />
            <Tab label={t('COMMUNITY.INVITATIONS')} {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <ViewDescription
              description={t('COMMUNITY.OVERVIEW_DESCRIPTION')}
            />
            <div style={{ marginBottom: 10 }}></div>
            {communities && communities.length ? (
              <MaterialTable
                icons={tableIcons}
                localization={tableLocalization()}
                columns={[
                  { title: '#', field: 'id', defaultSort: 'desc' },
                  { title: t('COMMUNITY.NAME'), field: 'name' },
                ]}
                data={communities}
                actions={tableActions}
                options={{
                  toolbarButtonAlignment: 'left',
                  toolbar: false,
                  showTitle: false,
                  search: false,
                  paging: true,
                  pageSize: 10,
                  padding: 'dense',
                  actionsColumnIndex: -1,
                  filterCellStyle: {
                    maxWidth: 0,
                  },
                  headerStyle: {
                    fontWeight: 'bold',
                  },
                }}
                style={{ boxShadow: 'none' }}
              />
            ) : (
              <p>{t('COMMUNITY.NO_COMMUNITES')}</p>
            )}
            <AgreementDialog
              open={showExitCommunityDialog}
              message={t('COMMUNITY.EXIT_MESSAGE')}
              acceptButtonText={t('COMMUNITY.EXIT')}
              handleClose={() => {
                setShowExitCommunityDialog(false)
              }}
              agree={() => {
                setIsLoading(true)
                setShowExitCommunityDialog(false)
                communityPartnersService
                  .deleteCommunityPartner(
                    selectedCommunity.partners[0].CommunityPartner.id,
                  )
                  .then(() => {
                    forceUpdate(forceUpdateCount + 1)
                    setIsLoading(false)
                  })
                  .catch((error) => {
                    console.error('Error deleting community partner:', error)
                  })
              }}
            />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Fragment>
              <MyCommunities partnerId={partnerId} />
            </Fragment>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Fragment>
              <CommunityInvitations
                partnerId={partnerId}
                forceUpdate={forceUpdate}
              />
            </Fragment>
          </TabPanel>
        </Fragment>
      )}
    </Fragment>
  )
}

export default withTranslation()(CommunitiesOverview)
