import config from '../_config/config'
import { authHeader } from '../_helpers/auth-header'

export const claimAttachmentsService = {
  getClaimAttachment,
  deleteClaimAttachment,
}

function getClaimAttachment(claimAttachmentId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/claim-attachments/${claimAttachmentId}`,
    requestOptions,
  )
}

function deleteClaimAttachment(claimAttachmentId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/claim-attachments/${claimAttachmentId}`,
    requestOptions,
  )
}

export * from './claimAttachmentsService'
