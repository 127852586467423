import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const jobOfferPositionsService = {
  setSalesPrice,
}
function setSalesPrice(jobOfferPositionId, price) {
  var requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      price: price,
    }),
  }

  return fetch(
    `${config.apiUrlBase}/job-offer-positions/${jobOfferPositionId}/set-sales-price`,
    requestOptions,
  ).then(handleResponse)
}

export * from './jobOfferPositionsService'
