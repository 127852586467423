import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const productsService = {
  getProduct,
  getProducts,
  getProductsOfProductCategory,
  createProduct,
  updateProduct,
  deleteProduct,
}

function getProduct(productId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/products/${productId}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function getProducts() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/products`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function getProductsOfProductCategory(productCategoryId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/product-categories/${productCategoryId}/products`,
    requestOptions,
  )
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function createProduct(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(`${config.apiUrlBase}/products`, requestOptions).then(
    handleResponse,
  )
}

function updateProduct(productId, payload) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(
    `${config.apiUrlBase}/products/${productId}`,
    requestOptions,
  ).then(handleResponse)
}

function deleteProduct(productId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/products/${productId}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './productsService'
