import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { badgesLookup } from '../../../../../../_constants/lookupConstants'
import { partnerBadgesService } from '../../../../../../_services/partnerBadgesService'
import { InfoTooltip } from '../../../../../InfoTooltip'

const Badges = ({ t, partnerId }) => {
  const [badges, setBadges] = useState([])

  useEffect(() => {
    const fetchBadges = async () => {
      try {
        const fetchedBadges = await partnerBadgesService.getBadgesOfPartner(
          partnerId,
        )
        setBadges(fetchedBadges)
      } catch (error) {
        console.error('Error loading partner badges:', error)
        setBadges([])
      }
    }

    if (partnerId) {
      fetchBadges()
    }
  }, [partnerId])

  if (!badges || !badges.length > 0) {
    return null
  }
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {badges.map((badge, index) => (
        <div key={badge.name} style={{ marginRight: '5px' }}>
          <InfoTooltip title={badgesLookup[badge.name.toUpperCase()]}>
            <img
              src={badge.imageLink}
              alt={badge.name}
              style={{
                width: '30px',
                height: '30px',
                transition: 'transform 0.3s ease',
                transform: 'scale(2)',
              }}
            />
          </InfoTooltip>
        </div>
      ))}
    </div>
  )
}

export default withTranslation()(Badges)
