import { Button, Grid, MenuItem, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import moment from 'moment'
import Numeral from 'numeral'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { countryLookup } from '../../../../../../_constants/lookupConstants'
import { tenderOrderRequestsService } from '../../../../../../_services/tenderOrderRequestsService'
import AgreementDialog from '../../../../../AgreementDialog'
import Deliveries from '../../../../../Deliveries'
import InvoiceAddress from '../../../../../InvoiceAddress'
import { Loading } from '../../../../../Loading'
import PositionCard from '../../../../../PositionCard'
import { Section } from '../../../../../Section'

const TenderOrderRequestForm = (props) => {
  const { t, tenderOrder } = props

  const [isLoading, setIsLoading] = useState(false)

  const [
    showDeleteTenderOrderRequestDialog,
    setShowDeleteTenderOrderRequestDialog,
  ] = useState(false)

  const userObject = JSON.parse(localStorage.getItem('user'))
  const valSchema = Yup.object().shape({
    orderNumber: Yup.string().nullable(),
    locationId: Yup.number().required(t('GENERAL.REQUIRED')),
    partnerInvoiceLocationId: Yup.number().required(t('GENERAL.REQUIRED')),
    deliveries: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string().required(t('GENERAL.REQUIRED')),
          value: Yup.string(),
          positions: Yup.array()
            .of(
              Yup.object().shape({
                amount: Yup.number(),
              }),
            )
            .compact(function (v) {
              return v.amount === ''
            })
            .min(1, t('GENERAL.MIN_1')),
        }),
      )
      .min(1, t('GENERAL.MIN_1')),
  })

  return tenderOrder
    ? (isLoading && <Loading variant={'centered'} />) || (
        <Fragment>
          <Formik
            enableReinitialize={true}
            initialValues={{
              orderNumber: null,
              locationId: null,
              deliveries: [],
            }}
            validationSchema={valSchema}
            //validateOnBlur={false}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting = true
              setIsLoading(true)
              var payLoad = values

              payLoad.deliveries = payLoad.deliveries.map((delivery) => {
                let deliveryValue = delivery.value
                deliveryValue = moment(deliveryValue)

                deliveryValue = deliveryValue.format('YYYY-MM-DD')

                return {
                  ...delivery,
                  value: deliveryValue,
                  deliveryPositions: delivery.positions.map((dPos) => {
                    return {
                      amount: dPos.amount,
                      positionId: dPos.positionId,
                    }
                  }),
                }
              })

              tenderOrderRequestsService
                .createTenderOrderRequest(payLoad, tenderOrder.id)
                .then(() => {
                  setSubmitting = false
                  window.location.reload()
                })
                .catch(function (error) {
                  setSubmitting = false
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                })
            }}
          >
            {({
              isSubmitting,
              isValidating,
              isValid,
              values,
              setFieldValue,
              setFieldError,
              setFieldTouched,
              errors,
              handleBlur,
              submitForm,
            }) => (
              <Form>
                <Fragment>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="orderNumber"
                        label={`${t('ORDERS.INTERNAL_ORDERNUMBER')}(${t(
                          'GENERAL.OPTIONAL',
                        )})`}
                        component={TextField}
                        variant="outlined"
                        type="text"
                        fullWidth={true}
                        margin="normal"
                      />
                      <Field
                        component={TextField}
                        name="locationId"
                        select
                        fullWidth
                        label={t('TENDER_ORDERS.CHOOSE_LOCATION')}
                        variant="outlined"
                        margin="dense"
                      >
                        {tenderOrder.generalInformation.deliveryDetails.locations.map(
                          (location) => (
                            <MenuItem value={location.id} key={location.id}>
                              {location.company +
                                ' / ' +
                                location.address +
                                ' / ' +
                                location.zipcode +
                                ' ' +
                                location.place +
                                ' / ' +
                                countryLookup[location.countryId]}
                            </MenuItem>
                          ),
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InvoiceAddress
                        partnerId={userObject.partner.id}
                        data={values}
                        setFieldValue={setFieldValue}
                        userId={userObject.user.id}
                      />
                    </Grid>
                    <Grid item xs={12}></Grid>
                    <Grid item xs={12}>
                      {values.locationId ? (
                        <Section
                          header={t(
                            'TENDER_ORDERS.CONTRACT_POSITIONS_OVERVIEW',
                          )}
                        >
                          <Grid container spacing={1}>
                            {tenderOrder.generalInformation.deliveryDetails.locations
                              .find((location) => {
                                return location.id === values.locationId
                              })
                              .positions.map((tenderOrderPosition) => (
                                <Grid item xs={6}>
                                  <PositionCard
                                    data={tenderOrderPosition}
                                    readOnly={true}
                                    showProductDetails={true}
                                    showImage={true}
                                    imageSize={'small'}
                                  >
                                    {
                                      <Typography>
                                        {`${t(
                                          'TENDER_ORDERS.ORDERED_AMOUNT',
                                        )}: ${Numeral(
                                          tenderOrderPosition.orderedAmount,
                                        ).format('0,0')}`}
                                      </Typography>
                                    }
                                    {
                                      <Typography>
                                        {`${t(
                                          'TENDER_ORDERS.REQUESTED_AMOUNT',
                                        )}: ${Numeral(
                                          tenderOrderPosition.requestedAmount,
                                        ).format('0,0')}`}
                                      </Typography>
                                    }
                                    {
                                      <Typography>
                                        {`${t(
                                          'TENDER_ORDERS.TOTAL_AMOUNT',
                                        )}: ${Numeral(
                                          tenderOrderPosition.amount,
                                        ).format('0,0')}`}
                                      </Typography>
                                    }
                                  </PositionCard>
                                </Grid>
                              ))}
                          </Grid>
                        </Section>
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      {values.locationId ? (
                        <Deliveries
                          data={{
                            ...values,
                            positions:
                              tenderOrder.generalInformation.deliveryDetails.locations.find(
                                (location) => {
                                  return location.id === values.locationId
                                },
                              ).positions,
                          }}
                          readOnly={false}
                          setFieldValue={setFieldValue}
                          positions={
                            tenderOrder.generalInformation.deliveryDetails.locations.find(
                              (location) => {
                                return location.id === values.locationId
                              },
                            ).positions
                          }
                          errros={errors}
                          type={'TENDER_ORDER'}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </Fragment>

                <Button
                  disabled={!isValid || isSubmitting}
                  type="button"
                  onClick={() => {
                    submitForm()
                  }}
                  variant="contained"
                  color="secondary"
                >
                  {t('TENDER_ORDERS.REQUEST')}
                </Button>

                <AgreementDialog
                  open={showDeleteTenderOrderRequestDialog}
                  handleClose={() => {
                    setShowDeleteTenderOrderRequestDialog(false)
                  }}
                  agree={() => {
                    setShowDeleteTenderOrderRequestDialog(false)
                    tenderOrderRequestsService
                      .deleteTenderOrderRequest(tenderOrder.id)
                      .then(() => window.location.reload())
                  }}
                />
              </Form>
            )}
          </Formik>
        </Fragment>
      )
    : null
}

export default withTranslation()(TenderOrderRequestForm)
