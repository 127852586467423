import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const tenderOrderRequestsService = {
  createTenderOrderRequest,
  confirmTenderOrderRequest,
  renegotiateTenderOrderRequest,
  deleteTenderOrderRequest,
}

function confirmTenderOrderRequest(tenderOrderRequestId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/tender-order-requests/` +
      tenderOrderRequestId +
      '/confirm',
    requestOptions,
  ).then(handleResponse)
}

function deleteTenderOrderRequest(tenderOrderRequestId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/tender-order-requests/` + tenderOrderRequestId,
    requestOptions,
  ).then(handleResponse)
}

function renegotiateTenderOrderRequest(
  payLoad,
  partnerId,
  tenderOrderRequestId,
) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payLoad),
  }

  return fetch(
    `${config.apiUrlBase}/tender-order-requests/ ${tenderOrderRequestId}
      /renegotiate?partnerId=${partnerId}`,
    requestOptions,
  ).then(handleResponse)
}

function createTenderOrderRequest(payload, tenderOrderId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(
    `${config.apiUrlBase}/tender-orders/${tenderOrderId}/tender-order-requests`,
    requestOptions,
  ).then(handleResponse)
}

export * from './tenderOrderRequestsService'
