import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header.js'

function predictAmountWithinTruck(input) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(input),
  }
  return fetch(
    `${config.apiUrlBase}/proxies-predict-amount-within-truck`,
    requestOptions,
  ).then(handleResponse)
}

function getRangeOfIndex(identifier, dateFrom, dateTo, partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${
      config.apiUrlBase
    }/proxies-indexes/${identifier}/range?start=${dateFrom.format(
      'YYYY-MM-DDTHH:mm:ss',
    )}&end=${dateTo.format('YYYY-MM-DDTHH:mm:ss')}&partnerId=${partnerId}`,
    requestOptions,
  ).then(handleResponse)
}

function getLatestOfIndex(identifier) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/proxies-indexes/${identifier}/latest`,
    requestOptions,
  ).then(handleResponse)
}

export const _proxiesService = {
  predictAmountWithinTruck,
  getRangeOfIndex,
  getLatestOfIndex,
}
