import { Box, Chip, Paper, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { formatToNumber } from '../../../../../../_helpers/number-formatting'

const CustomBarChart = ({
  data,
  xAxisDataKey,
  xAxisLabel,
  bars,
  yAxisLabel,
  formatterFunction,
  tooltipDataKeyMapping = {},
  hasLegend,
  lookup,
  title,
  showSums,
  sumOperation,
  isExporting,
  ...restProps
}) => {
  const countDistinctStackIdsForDataPoint = (barsArray, dataKey) => {
    const distinctStackIds = new Set(
      barsArray
        .filter((bar) => bar.dataKey === dataKey)
        .map((bar) => bar.stackId),
    )
    return distinctStackIds.size
  }

  const hasStackIdInBars = (barsArray) => {
    return barsArray.some((barConfig) => 'stackId' in barConfig)
  }

  const isLastBarOfItsStackId = (barsArray, currentBarConfig) => {
    const barsWithSameStackId = barsArray.filter(
      (bar) => bar.stackId === currentBarConfig.stackId,
    )
    const lastBar = barsWithSameStackId[barsWithSameStackId.length - 1]
    return lastBar.dataKey === currentBarConfig.dataKey
  }

  const calculateSums = (data, keys, operation = 'sum') => {
    let sums = {}
    let counts = {}

    data.forEach((item) => {
      keys.forEach((key) => {
        const keyParts = key.split('.')
        const mainKey = keyParts[0]
        const subKey = keyParts[1]

        if (!sums[key]) {
          sums[key] = 0
          counts[key] = 0
        }

        if (subKey) {
          // Verschachtelte Struktur
          if (item[mainKey] && item[mainKey][subKey] !== undefined) {
            sums[key] += item[mainKey][subKey]
            counts[key] += 1
          }
        } else {
          // Flache Struktur
          if (item[mainKey] !== undefined) {
            sums[key] += item[mainKey]
            counts[key] += 1
          }
        }
      })
    })

    if (operation === 'average') {
      Object.keys(sums).forEach((key) => {
        sums[key] = sums[key] / counts[key]
      })
    }

    // Umwandlung des sums-Objekts in ein Array von [key, value]-Paaren
    const sumsArray = Object.entries(sums)

    // Sortieren des Arrays absteigend nach dem Wert (sum)
    sumsArray.sort((a, b) => b[1] - a[1])

    // Konvertierung des sortierten Arrays zurück in ein Objekt
    const sortedSums = {}
    sumsArray.forEach(([key, value]) => {
      sortedSums[key] = value
    })

    return sortedSums
  }

  // Use the new function to calculate sums if showSums is true
  let sums
  if (showSums) {
    sums = calculateSums(
      data,
      bars.map((bar) => bar.dataKey),
      sumOperation,
    )
  }

  return (
    <Paper
      elevation={4}
      sx={{
        padding: '25px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className="chartContainer">
        <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {title}
          </Typography>
          {/* Display total sums in a flex container */}
          {showSums && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: '10px',
                marginBottom: '20px',
              }}
            >
              {Object.entries(sums).map(([key, value]) => (
                <Chip
                  key={key}
                  label={`${formatterFunction(sums[key], key, lookup)}`}
                  variant="outlined"
                />
              ))}
            </Box>
          )}
        </div>
        <ResponsiveContainer
          width="100%"
          height={bars.length > 7 && isExporting ? 750 : 450}
          style={{ flexGrow: 1 }}
        >
          <BarChart
            animationDuration={500}
            width={600}
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
            {...restProps}
          >
            <CartesianGrid stroke="#E5E5E5" strokeDasharray="3 3" />
            <XAxis
              dataKey={xAxisDataKey}
              label={{
                value: xAxisLabel,
                position: 'insideBottomRight',
                offset: -5,
              }}
            />
            <YAxis
              label={{
                value: yAxisLabel,
                angle: -90,
                position: 'insideLeft',
                offset: -15,
                dy: 50,
              }}
            />
            <Tooltip
              wrapperStyle={{ zIndex: 1000 }}
              formatter={(value, dataKey) =>
                formatterFunction(value, dataKey, lookup)
              }
              labelFormatter={(name) => `Monat: ${name}`}
              itemStyle={{ color: '#8884d8' }}
              cursor={{ fill: '#F0F0F0' }}
            />
            {hasLegend && (
              <Legend
                formatter={(value) => {
                  let formattedValue = formatterFunction(undefined, value)[1]

                  return formattedValue
                }}
              />
            )}
            {bars.map((barConfig, index) => {
              let hasSingleStackIdForDataPoint =
                countDistinctStackIdsForDataPoint(bars, barConfig.dataKey) === 1

              const isLast = isLastBarOfItsStackId(bars, barConfig)

              const shouldDisplayLabel =
                bars.length === 1 ||
                (hasStackIdInBars(bars) &&
                  hasSingleStackIdForDataPoint &&
                  isLast)

              return (
                <Bar
                  {...barConfig}
                  radius={[5, 5, 0, 0]}
                  label={
                    shouldDisplayLabel
                      ? ({ x, y, width, value }) => (
                          <text
                            x={x + width / 2}
                            y={y}
                            fill="#666"
                            textAnchor="middle"
                            dy={-6}
                          >
                            {formatToNumber(value)}
                          </text>
                        )
                      : undefined
                  }
                />
              )
            })}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  )
}

export default withTranslation()(CustomBarChart)
