import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import JobOfferPositionsView from '../JobOfferPositionsView'
import { Section } from '../Section'
import JobOfferDeliveryDetailsView from './_components/JobOfferDeliveryDetailsView'
import JobOfferPaymentDetailsView from './_components/JobOfferPaymentDetailsView'

const JobOfferView = (props) => {
  const { data, forceUpdate, t } = props

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Section>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <JobOfferDeliveryDetailsView data={data} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <JobOfferPaymentDetailsView data={data} />
              <Section header={t('REQUEST.OTHER_DETAILS')} hideBorder={true}>
                <Grid item container xs={12}>
                  <Grid item xs={12} style={{ alignSelf: 'center' }}>
                    <Typography variant={'subtitle1'}>
                      {t('REQUEST.VALID_UNTIL') + ':'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ alignSelf: 'center' }}>
                    <Typography variant={'subtitle2'} color={'text.secondary'}>
                      {moment(data.validUntil).local().format('DD.MM.YYYY')}
                    </Typography>
                  </Grid>
                </Grid>
              </Section>
            </Grid>
          </Grid>
        </Section>
      </Grid>
      <Grid item xs={12}>
        <JobOfferPositionsView data={data} forceUpdate={forceUpdate} />
      </Grid>
    </Grid>
  )
}

export default withTranslation()(connect()(JobOfferView))
