import { AddBox } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField as MUITextField,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { alertActions } from '../../../../../../_actions/alertActions'
import {
  countryList,
  countryLookup,
} from '../../../../../../_constants/lookupConstants'
import { authorize } from '../../../../../../_helpers/authorization'
import NumberFormatCustom from '../../../../../../_helpers/numberFormatCustom'
import { partnerInvoiceLocationsService } from '../../../../../../_services/partnerInvoiceLocationsService'
import { partnersService } from '../../../../../../_services/partnersService'
import DeliveryTimes from '../../../../../DeliveryTimes'
import GoogleAutocomplete from '../../../../../GoogleAutocomplete'
import { Loading } from '../../../../../Loading'
import { Section } from '../../../../../Section'

const Addresses = (props) => {
  const { t, dispatch } = props

  const [isLoading, setIsLoading] = useState(false)
  const [isAddingLocation, setIsAddingLocation] = useState(false)
  const [isAddingInvoiceLocation, setIsAddingInvoiceLocation] = useState(false)
  const [initialValues, setInitialValues] = useState({
    partnerLocations: [],
    partnerInvoiceLocations: [],
  })
  const [showDebtorDialog, setShowDebtorDialog] = useState(false)
  const [dialogExternalDebtorId, setDialogExternalDebtorId] = useState('')
  const [forceUpdateCount, forceUpdate] = useState(0)
  const [validationErrors, setValidationErrors] = useState([])
  var userObject = JSON.parse(localStorage.getItem('user'))
  let partnerId = parseInt(userObject.partner.id)

  useEffect(() => {
    setIsLoading(true)

    partnersService.getPartner(partnerId).then((partnerData) => {
      partnerData.size = partnerData.size ? partnerData.size : 0
      partnerData.founded = partnerData.founded ? partnerData.founded : 0
      partnerData.capacity = partnerData.capacity ? partnerData.capacity : 0
      partnerData.selectedLocationId = partnerData.partnerLocations.find(
        (partnerLocation) => partnerLocation.primary,
      ).id

      partnerData.selectedInvoiceLocationId =
        partnerData.partnerInvoiceLocations.find(
          (partnerInvoiceLocation) => partnerInvoiceLocation.primary,
        ).id

      setInitialValues({
        ...partnerData,
        partnerLocations: partnerData.partnerLocations
          .filter((location) => location.active)
          .map((location) => {
            return {
              ...location,
              deliveryInformations: location.deliveryInformations
                ? location.deliveryInformations
                : '',
              stackHeight: location.stackHeight ? location.stackHeight : '',
              deliveryTimes: location.deliveryTimes,
              despatchAdviceInstructions: location.despatchAdviceInstructions
                ? location.despatchAdviceInstructions
                : '',
            }
          }),
        partnerInvoiceLocations: partnerData.partnerInvoiceLocations
          .filter((location) => location.active)
          .map((location) => {
            return {
              ...location,
              contactPerson: location.contactPerson
                ? location.contactPerson
                : '',
              additionalAddressLine: location.additionalAddressLine
                ? location.additionalAddressLine
                : '',
              reference: location.reference ? location.reference : '',
              additionalInvoiceText: location.additionalInvoiceText
                ? location.additionalInvoiceText
                : '',
              email: location.email ? location.email : '',
            }
          }),
      })
      setIsLoading(false)
    })
  }, [partnerId, forceUpdateCount])

  const taxIdValidation = {
    14: '^(AT)?U[0-9]{8}$',
    21: '^(BE)?0[0-9]{9}$',
    33: '^(BG)?[0-9]{9,10}$',
    54: '^(CY)?[0-9]{8}L$',
    55: '^(CZ)?[0-9]{8,10}$',
    80: '^(DE)?[0-9]{9}$',
    56: '^(DK)?[0-9]{8}$',
    66: '^(EE)?[0-9]{9}$',
    84: '^(EL|GR)?[0-9]{9}$',
    203: '^(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]$',
    71: '^(FI)?[0-9]{8}$',
    72: '^(FR)?[0-9A-Z]{2}[0-9]{9}$',
    230: '^(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$',
    97: '^(HU)?[0-9]{8}$',
    104: '^(IE)?[0-9]S[0-9]{5}L$',
    106: '^(IT)?[0-9]{11}$',
    127: '^(LT)?([0-9]{9}|[0-9]{12})$',
    128: '^(LU)?[0-9]{8}$',
    121: '^(LV)?[0-9]{11}$',
    136: '^(MT)?[0-9]{8}$',
    155: '^(NL)?[0-9]{9}B[0-9]{2}$',
    176: '^(PL)?[0-9]{10}$',
    177: '^(PT)?[0-9]{9}$',
    181: '^(RO)?[0-9]{2,10}$',
    211: '^(SE)?[0-9]{12}$',
    197: '^(SI)?[0-9]{8}$',
    196: '^(SK)?[0-9]{10}$',
  }
  const taxIdPlaceholders = {
    14: 'ATU12345678',
    21: 'BE0123456789',
    33: 'BG1234567890',
    54: 'CY12345678L',
    55: 'CZ1234567890',
    80: 'DE123456789',
    56: 'DK12345678',
    66: 'EE123456789',
    84: 'EL123456789',
    203: 'ESX1234567X',
    71: 'FI12345678',
    72: 'FRXX123456789',
    230: 'GB123456789',
    97: 'HU12345678',
    104: 'IE1S12345L',
    106: 'IT12345678901',
    127: 'LT123456789',
    128: 'LU12345678',
    121: 'LV12345678901',
    136: 'MT12345678',
    155: 'NL123456789B12',
    176: 'PL1234567890',
    177: 'PT123456789',
    181: 'RO1234567890',
    211: 'SE123456789012',
    197: 'SI12345678',
    196: 'SK1234567890',
  }
  const valSchema = Yup.object().shape({
    partnerLocations: Yup.array().of(
      Yup.object().shape({
        company: Yup.string().required(t('GENERAL.REQUIRED')),
        address: Yup.string().required(t('GENERAL.REQUIRED')),
        zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
        place: Yup.string().required(t('GENERAL.REQUIRED')),
        countryId: Yup.number().required(t('GENERAL.REQUIRED')),
        deliveryInformations: Yup.string().nullable(),
        unload: Yup.string().required(),
        stackHeight: Yup.number().nullable(),
        stackHeightUnit: Yup.string().required(),
        isDespatchAdviceRequired: Yup.boolean().required(),
        despatchAdviceInstructions: Yup.string().nullable(),
      }),
    ),
    partnerInvoiceLocations: Yup.array().of(
      Yup.object().shape({
        company: Yup.string().required(t('GENERAL.REQUIRED')),
        contactPerson: Yup.string().nullable(),
        address: Yup.string().required(t('GENERAL.REQUIRED')),
        additionalAddressLine: Yup.string().nullable(),
        zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
        place: Yup.string().required(t('GENERAL.REQUIRED')),
        countryId: Yup.number().required(t('GENERAL.REQUIRED')),
        taxId: Yup.string()
          .test(
            'tax validation',
            t('GENERAL.INVALID_TAX_ID'),
            function (value) {
              const { countryId } = this.parent
              if (!countryId) return true
              if (!value) return false
              return value.match(taxIdValidation[countryId])
            },
          )
          .required(t('GENERAL.REQUIRED')),
        email: Yup.string()
          .matches(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))((,\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*)$/,
            t('GENERAL.INVALID_EMAIL'),
          )
          .nullable(),
        reference: Yup.string().nullable(),
      }),
    ),
  })

  const handleGenerateDebtorClick = async (id) => {
    await partnerInvoiceLocationsService.createDebtorOfPartnerInvoiceLocation(
      id,
    )
    forceUpdate(forceUpdateCount + 1)
    setShowDebtorDialog(false)
  }

  const handleSetDebtorClick = async (id) => {
    await partnerInvoiceLocationsService.updatePartnerInvoiceLocation(id, {
      externalDebtorId: dialogExternalDebtorId,
    })
    forceUpdate(forceUpdateCount + 1)
    setShowDebtorDialog(false)
  }

  const validateFields = (values) => {
    let errors = []

    // Validation for partnerLocations
    values.partnerLocations.forEach((location) => {
      let locationErrors = {}
      if (!location.company) locationErrors.company = t('GENERAL.REQUIRED')
      if (!location.address) locationErrors.address = t('GENERAL.REQUIRED')
      if (!location.zipcode) locationErrors.zipcode = t('GENERAL.REQUIRED')
      if (!location.place) locationErrors.place = t('GENERAL.REQUIRED')

      if (Object.keys(locationErrors).length > 0) {
        errors.push({
          type: 'Lieferadresse',
          ...locationErrors,
          company: location.company || t('GENERAL.UNKNOWN'),
          address: location.address || t('GENERAL.UNKNOWN'),
          zipcode: location.zipcode || t('GENERAL.UNKNOWN'),
          place: location.place || t('GENERAL.UNKNOWN'),
        })
      }
    })

    // Validation for partnerInvoiceLocations
    values.partnerInvoiceLocations.forEach((location) => {
      let locationErrors = {}
      if (!location.company) locationErrors.company = t('GENERAL.REQUIRED')
      if (!location.address) locationErrors.address = t('GENERAL.REQUIRED')
      if (!location.zipcode) locationErrors.zipcode = t('GENERAL.REQUIRED')
      if (!location.place) locationErrors.place = t('GENERAL.REQUIRED')
      if (!location.taxId) locationErrors.taxId = t('GENERAL.REQUIRED')
      else if (!location.taxId.match(taxIdValidation[location.countryId])) {
        locationErrors.taxId = t('GENERAL.INVALID_TAX_ID')
      }

      if (Object.keys(locationErrors).length > 0) {
        errors.push({
          type: 'Rechnungsadresse',
          ...locationErrors,
          company: location.company || t('GENERAL.UNKNOWN'),
          address: location.address || t('GENERAL.UNKNOWN'),
          zipcode: location.zipcode || t('GENERAL.UNKNOWN'),
          place: location.place || t('GENERAL.UNKNOWN'),
        })
      }
    })

    return errors
  }

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={valSchema}
        validate={(values) => {
          const errors = validateFields(values)
          setValidationErrors(errors)
          return {}
        }}
        onSubmit={(values, { setSubmitting }) => {
          let requestBody = {
            partnerLocations: values.partnerLocations.map((location) => {
              return {
                id: location.isNew ? null : location.id,
                company: location.company,
                countryId: location.countryId,
                address: location.address,
                zipcode: location.zipcode,
                place: location.place,
                deliveryInformations: location.deliveryInformations,
                active: location.active,
                primary: location.primary,
                unload: location.unload,
                stackHeight: Number(location.stackHeight)
                  ? location.stackHeight
                  : null,
                stackHeightUnit: location.stackHeightUnit,
                deliveryTimes: location.deliveryTimes,
                isDespatchAdviceRequired: location.isDespatchAdviceRequired,
                despatchAdviceInstructions: location.isDespatchAdviceRequired
                  ? location.despatchAdviceInstructions
                  : null,
              }
            }),
            partnerInvoiceLocations: values.partnerInvoiceLocations.map(
              (location) => {
                return {
                  id: location.isNew ? null : location.id,
                  company: location.company,
                  countryId: location.countryId,
                  address: location.address,
                  zipcode: location.zipcode,
                  place: location.place,
                  contactPerson: location.contactPerson,
                  additionalAddressLine: location.additionalAddressLine,
                  taxId: location.taxId,
                  email: location.email,
                  reference: location.reference,
                  additionalInvoiceText: location.additionalInvoiceText,
                  active: location.active,
                  primary: location.primary,
                  taxFree: location.taxFree,
                  creditLimit: location.creditLimit,
                  creditLimitTaxMode: location.creditLimitTaxMode,
                }
              },
            ),
          }

          partnersService
            .updatePartner(partnerId, requestBody)
            .then(() => {
              partnersService.getPartner(partnerId).then((partnerData) => {
                setInitialValues({
                  ...partnerData,
                  selectedLocationId: partnerData.partnerLocations.find(
                    (location) => location.primary,
                  ).id,
                  selectedInvoiceLocationId:
                    partnerData.partnerInvoiceLocations.find(
                      (location) => location.primary,
                    ).id,
                  partnerLocations: partnerData.partnerLocations
                    .filter((location) => location.active)
                    .map((location) => {
                      return {
                        ...location,
                        stackHeight: location.stackHeight
                          ? location.stackHeight
                          : '',
                        deliveryInformations: location.deliveryInformations
                          ? location.deliveryInformations
                          : '',
                        despatchAdviceInstructions:
                          location.despatchAdviceInstructions
                            ? location.despatchAdviceInstructions
                            : '',
                      }
                    }),
                  partnerInvoiceLocations: partnerData.partnerInvoiceLocations
                    .filter((location) => location.active)
                    .map((location) => {
                      return {
                        ...location,
                        contactPerson: location.contactPerson
                          ? location.contactPerson
                          : '',
                        additionalAddressLine: location.additionalAddressLine
                          ? location.additionalAddressLine
                          : '',
                        reference: location.reference ? location.reference : '',
                        additionalInvoiceText: location.additionalInvoiceText
                          ? location.additionalInvoiceText
                          : '',
                        email: location.email ? location.email : '',
                      }
                    }),
                })
                setIsLoading(false)
              })
              dispatch(
                alertActions.info(t('PARTNER_SETTINGS.SAVING_SUCCESSFUL')),
              )
              setTimeout(() => {
                dispatch(alertActions.clear())
              }, alertActions.alertTimeout)
              setSubmitting(false)
            })
            .catch(function (error) {
              dispatch(alertActions.error(error))
              setTimeout(() => {
                dispatch(alertActions.clear())
              }, alertActions.alertTimeout)
              setSubmitting(false)
            })
        }}
      >
        {({ isSubmitting, values, setFieldValue, submitForm, isValid }) => {
          let indexPartnerLocation = values.partnerLocations.findIndex(
            (location) => location.id === values.selectedLocationId,
          )

          let indexPartnerInvoiceLocation =
            values.partnerInvoiceLocations.findIndex(
              (location) => location.id === values.selectedInvoiceLocationId,
            )
          const taxIdPlaceholder = values.partnerInvoiceLocations[
            indexPartnerInvoiceLocation
          ]
            ? taxIdPlaceholders[
                values.partnerInvoiceLocations[indexPartnerInvoiceLocation]
                  .countryId
              ]
            : ''
          return (
            <Form>
              {validationErrors.length > 0 && (
                <Grid container spacing={2} style={{ marginBottom: '20px' }}>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="error">
                      {t('PARTNER_SETTINGS.VALIDATION_ERRORS')}
                    </Typography>
                  </Grid>
                  {validationErrors.map((error, index) => (
                    <Grid item xs={12} key={index}>
                      <Typography variant="body2">
                        <strong>{`${error.type}: ${error.company}, ${error.address}, ${error.zipcode}, ${error.place}`}</strong>
                      </Typography>
                      <Typography variant="body2" color="error">
                        {Object.entries(error)
                          .filter(
                            ([key, value]) =>
                              key !== 'type' &&
                              key !== 'company' &&
                              key !== 'address' &&
                              key !== 'zipcode' &&
                              key !== 'place',
                          )
                          .map(([field, message]) => {
                            let translatedField = ''
                            switch (field) {
                              case 'company':
                                translatedField = t(
                                  'REQUEST.DELIVERY_DETAILS.COMPANY',
                                )
                                break
                              case 'address':
                                translatedField = t(
                                  'REQUEST.DELIVERY_DETAILS.ADDRESS',
                                )
                                break
                              case 'zipcode':
                                translatedField = t(
                                  'REQUEST.DELIVERY_DETAILS.ZIP_CODE',
                                )
                                break
                              case 'place':
                                translatedField = t(
                                  'REQUEST.DELIVERY_DETAILS.PLACE',
                                )
                                break
                              case 'taxId':
                                translatedField = t('PARTNER_SETTINGS.TAX_ID')
                                break
                              default:
                                translatedField = field
                            }
                            return `${translatedField}: ${message}`
                          })
                          .join(' | ')}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              )}
              <Grid container spacing={1}>
                <Grid item xs={12} lg={6}>
                  <Section header={t('PARTNER_SETTING.DELIVERY_ADDRESSES')}>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sm={authorize('addPartnerLocation') ? 10 : 12}
                      >
                        <Field
                          disabled={isAddingLocation}
                          component={TextField}
                          name="selectedLocationId"
                          select
                          fullWidth
                          label={t('TENDER_ORDERS.CHOOSE_LOCATION')}
                          variant="outlined"
                          margin="dense"
                        >
                          {initialValues.partnerLocations.map((location) => (
                            <MenuItem value={location.id} key={location.id}>
                              {location.address +
                                ' | ' +
                                location.zipcode +
                                ' | ' +
                                location.place +
                                ' | ' +
                                countryLookup[location.countryId]}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      {authorize('addPartnerLocation') && (
                        <Grid item xs={12} sm={2}>
                          <Tooltip title={t('PARTNER_SETTING.ADD_LOCATION')}>
                            <IconButton
                              color="default"
                              onClick={() => {
                                setIsAddingLocation(true)

                                //IE8 Support
                                if (!Date.now) {
                                  Date.now = function () {
                                    return new Date().getTime()
                                  }
                                }

                                let tempId = Date.now()

                                let newLocation = {
                                  id: tempId, //Required to find the indexPartnerLocation within this component
                                  isNew: true,
                                  company: '',
                                  countryId: 80,
                                  address: '',
                                  zipcode: '',
                                  place: '',
                                  deliveryInformations: '',
                                  active: true,
                                  primary: false,
                                  unload: 'ANY',
                                  stackHeight: null,
                                  stackHeightUnit: 'QUANTITY',
                                  isDespatchAdviceRequired: false,
                                  despatchAdviceInstructions: '',
                                  deliveryTimes: [
                                    {
                                      isActive: true,
                                      start: 8,
                                      end: 16,
                                    },
                                    {
                                      isActive: true,
                                      start: 8,
                                      end: 16,
                                    },
                                    {
                                      isActive: true,
                                      start: 8,
                                      end: 16,
                                    },
                                    {
                                      isActive: true,
                                      start: 8,
                                      end: 16,
                                    },
                                    {
                                      isActive: true,
                                      start: 8,
                                      end: 16,
                                    },
                                    {
                                      isActive: false,
                                      start: null,
                                      end: null,
                                    },
                                    {
                                      isActive: false,
                                      start: null,
                                      end: null,
                                    },
                                  ],
                                }

                                setFieldValue(
                                  'partnerLocations',
                                  values.partnerLocations.concat(newLocation),
                                )

                                setFieldValue('selectedLocationId', tempId)
                              }}
                              size="large"
                            >
                              <AddBox />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <Field
                          disabled={true}
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={
                            'partnerLocations[' +
                            indexPartnerLocation +
                            '].active'
                          }
                          Label={{
                            label: t('PARTNER_SETTINGS.ACTIVE'),
                            hidden: isAddingLocation,
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          disabled={true}
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={
                            'partnerLocations[' +
                            indexPartnerLocation +
                            '].primary'
                          }
                          Label={{
                            label: t('PARTNER_SETTINGS.PRIMARY_LOCATION'),
                            hidden: isAddingLocation,
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          label={t('REQUEST.DELIVERY_DETAILS.COMPANY')}
                          name={
                            'partnerLocations[' +
                            indexPartnerLocation +
                            '].company'
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          disabled={!authorize('updatePartnerLocation')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          label={t('REQUEST.DELIVERY_DETAILS.COUNTRY')}
                          name={
                            'partnerLocations[' +
                            indexPartnerLocation +
                            '].countryId'
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          select
                          disabled={!authorize('updatePartnerLocation')}
                        >
                          {Object.entries(countryList).map((country) => (
                            <MenuItem value={country[1]} key={country[0]}>
                              {country[0]}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <GoogleAutocomplete
                          setFieldValue={setFieldValue}
                          formikName={`partnerLocations[${indexPartnerLocation}].address`}
                          formikPath={`partnerLocations[${indexPartnerLocation}]`}
                          readOnly={!authorize('updatePartnerLocation')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          label={t('REQUEST.DELIVERY_DETAILS.ZIP_CODE')}
                          name={
                            'partnerLocations[' +
                            indexPartnerLocation +
                            '].zipcode'
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          disabled={!authorize('updatePartnerLocation')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          label={t('REQUEST.DELIVERY_DETAILS.PLACE')}
                          name={
                            'partnerLocations[' +
                            indexPartnerLocation +
                            '].place'
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          disabled={!authorize('updatePartnerLocation')}
                        />
                      </Grid>
                      {values.partnerLocations[indexPartnerLocation] && (
                        <Grid item xs={12}>
                          <Typography variant="caption">
                            {t('DELIVERY_DETAILS.POSSIBLE_LOADING')}
                          </Typography>
                          <ButtonGroup
                            color={'secondary'}
                            sx={{
                              height: '36.7px',
                              marginTop: '8px',
                            }}
                            fullWidth
                            disabled={!authorize('updatePartnerLocation')}
                          >
                            <Button
                              variant={
                                values.partnerLocations[indexPartnerLocation]
                                  .unload === 'ANY'
                                  ? 'contained'
                                  : 'outlined'
                              }
                              onClick={() =>
                                setFieldValue(
                                  'partnerLocations[' +
                                    indexPartnerLocation +
                                    '].unload',
                                  'ANY',
                                )
                              }
                            >
                              {t('DELIVERY_DETAILS.ANY_UNLOAD')}
                            </Button>
                            <Button
                              variant={
                                values.partnerLocations[indexPartnerLocation]
                                  .unload === 'RAMP'
                                  ? 'contained'
                                  : 'outlined'
                              }
                              onClick={() =>
                                setFieldValue(
                                  'partnerLocations[' +
                                    indexPartnerLocation +
                                    '].unload',
                                  'RAMP',
                                )
                              }
                            >
                              {t('DELIVERY_DETAILS.RAMP')}
                            </Button>
                            <Button
                              variant={
                                values.partnerLocations[indexPartnerLocation]
                                  .unload === 'SIDE'
                                  ? 'contained'
                                  : 'outlined'
                              }
                              onClick={() =>
                                setFieldValue(
                                  'partnerLocations[' +
                                    indexPartnerLocation +
                                    '].unload',
                                  'SIDE',
                                )
                              }
                            >
                              {t('DELIVERY_DETAILS.SIDE')}
                            </Button>
                          </ButtonGroup>
                        </Grid>
                      )}
                      {values.partnerLocations[indexPartnerLocation] && (
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <Field
                                fullWidth={true}
                                component={TextField}
                                margin="dense"
                                size={'small'}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                inputProps={{
                                  decimalScale: 0,
                                  allowNegative: false,
                                }}
                                label={`${t(
                                  'DELIVERY_DETAILS.STACK_HEIGHT',
                                )} (${t('GENERAL.OPTIONAL')})`}
                                variant="outlined"
                                name={
                                  'partnerLocations[' +
                                  indexPartnerLocation +
                                  '].stackHeight'
                                }
                                disabled={!authorize('updatePartnerLocation')}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <ButtonGroup
                                color={'secondary'}
                                sx={{
                                  height: '36.7px',
                                  marginTop: '8px',
                                }}
                                fullWidth
                                disabled={!authorize('updatePartnerLocation')}
                              >
                                <Button
                                  variant={
                                    values.partnerLocations[
                                      indexPartnerLocation
                                    ].stackHeightUnit === 'QUANTITY'
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  onClick={() =>
                                    setFieldValue(
                                      'partnerLocations[' +
                                        indexPartnerLocation +
                                        '].stackHeightUnit',
                                      'QUANTITY',
                                    )
                                  }
                                >
                                  {t('DELIVERY_DETAILS.PIECES')}
                                </Button>
                                <Button
                                  variant={
                                    values.partnerLocations[
                                      indexPartnerLocation
                                    ].stackHeightUnit === 'CM'
                                      ? 'contained'
                                      : 'outlined'
                                  }
                                  onClick={() =>
                                    setFieldValue(
                                      'partnerLocations[' +
                                        indexPartnerLocation +
                                        '].stackHeightUnit',
                                      'CM',
                                    )
                                  }
                                >
                                  {t('DELIVERY_DETAILS.CENTIMETER')}
                                </Button>
                              </ButtonGroup>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} spacing={1}>
                            <Typography variant="caption">
                              {t('DELIVERY_TIMES.LOADING_TIMES')}
                            </Typography>
                            <DeliveryTimes
                              data={
                                values.partnerLocations[indexPartnerLocation]
                                  .deliveryTimes
                              }
                              formikName={
                                'partnerLocations[' +
                                indexPartnerLocation +
                                '].deliveryTimes'
                              }
                              setFieldValue={setFieldValue}
                              readOnly={!authorize('updatePartnerLocation')}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <Field
                              component={CheckboxWithLabel}
                              type={'checkbox'}
                              color="default"
                              name={
                                'partnerLocations[' +
                                indexPartnerLocation +
                                '].isDespatchAdviceRequired'
                              }
                              Label={{
                                label: t(
                                  'DELIVERY_DETAILS.IS_DESPATCH_ADVICE_REQUIRED',
                                ),
                              }}
                              style={{ marginLeft: '15px' }}
                              disabled={!authorize('updatePartnerLocation')}
                            />
                          </Grid>
                          {values.partnerLocations[indexPartnerLocation] && (
                            <Grid item xs={12}>
                              <Field
                                disabled={
                                  !authorize('updatePartnerLocation') ||
                                  !values.partnerLocations[indexPartnerLocation]
                                    .isDespatchAdviceRequired
                                }
                                component={TextField}
                                name={
                                  'partnerLocations[' +
                                  indexPartnerLocation +
                                  '].despatchAdviceInstructions'
                                }
                                fullWidth
                                margin="dense"
                                label={t(
                                  'REQUEST.DELIVERY_DETAILS.DESPATCH_ADVICE_INSTRUCTIONS',
                                )}
                                variant="outlined"
                                multiline
                                rows="4"
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name={
                            'partnerLocations[' +
                            indexPartnerLocation +
                            '].deliveryInformations'
                          }
                          fullWidth
                          margin="dense"
                          label={t(
                            'REQUEST.DELIVERY_DETAILS.ADDITIONAL_DELIVERY_INFORMATION',
                          )}
                          variant="outlined"
                          multiline
                          rows="4"
                          disabled={!authorize('updatePartnerLocation')}
                        />
                      </Grid>
                      {authorize('updatePartnerLocation') &&
                      values.selectedLocationId &&
                      !isAddingLocation ? (
                        <Fragment>
                          {values.partnerLocations.find(
                            (location) =>
                              location.id === values.selectedLocationId,
                          ).active ? (
                            <Grid item xs={12} sm={6}>
                              <Button
                                disabled={
                                  values.partnerLocations.find(
                                    (location) =>
                                      location.id === values.selectedLocationId,
                                  ).primary
                                }
                                onClick={() => {
                                  setFieldValue(
                                    `partnerLocations[${indexPartnerLocation}].active`,
                                    false,
                                  )
                                }}
                                variant="contained"
                                color="secondary"
                              >
                                {t('PARTNER_SETTINGS.ARCHIVE')}
                              </Button>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6}>
                              <Button
                                onClick={() => {
                                  let indexPartnerLocation =
                                    values.partnerLocations.findIndex(
                                      (location) =>
                                        location.id ===
                                        values.selectedLocationId,
                                    )

                                  setFieldValue(
                                    `partnerLocations[${indexPartnerLocation}].active`,
                                    true,
                                  )
                                }}
                                variant="contained"
                                color="secondary"
                              >
                                {t('PARTNER_SETTINGS.ACTIVATE')}
                              </Button>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6}>
                            <Button
                              onClick={() => {
                                values.partnerLocations.forEach(
                                  (location, indexPartnerLocation) => {
                                    if (
                                      location.id === values.selectedLocationId
                                    ) {
                                      setFieldValue(
                                        `partnerLocations[${indexPartnerLocation}].primary`,
                                        true,
                                      )
                                    } else {
                                      setFieldValue(
                                        `partnerLocations[${indexPartnerLocation}].primary`,
                                        false,
                                      )
                                    }
                                  },
                                )
                              }}
                              disabled={
                                values.partnerLocations.find(
                                  (location) =>
                                    location.id === values.selectedLocationId,
                                ).primary ||
                                !values.partnerLocations.find(
                                  (location) =>
                                    location.id === values.selectedLocationId,
                                ).active
                              }
                              variant="contained"
                              color="secondary"
                            >
                              {t('PARTNER_SETTINGS.SET_PRIMARY_LOCATION')}
                            </Button>
                          </Grid>
                        </Fragment>
                      ) : null}
                      {isAddingLocation ? (
                        <Fragment>
                          <Grid item xs={12} sm={6}>
                            <Button
                              onClick={() => {
                                if (isValid) {
                                  submitForm()
                                  setIsAddingLocation(false)
                                }
                              }}
                              variant="contained"
                              color="secondary"
                            >
                              {t('PARTNER_SETTINGS.ADD')}
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              onClick={() => {
                                let tempLocationId = values.selectedLocationId

                                setFieldValue(
                                  'selectedLocationId',
                                  values.partnerLocations.find(
                                    (location) => location.primary,
                                  ).id,
                                )

                                setFieldValue(
                                  'partnerLocations',
                                  values.partnerLocations.filter(
                                    (location) =>
                                      location.id !== tempLocationId,
                                  ),
                                )

                                setIsAddingLocation(false)
                              }}
                              variant="outlined"
                              color={'secondary'}
                            >
                              {t('PARTNER_SETTINGS.CANCEL')}
                            </Button>
                          </Grid>
                        </Fragment>
                      ) : null}
                    </Grid>
                  </Section>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Section header={t('PARTNER_SETTING.INVOICE_ADDRESSES')}>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sm={authorize('addPartnerInvoiceLocation') ? 10 : 12}
                      >
                        <Field
                          disabled={isAddingInvoiceLocation}
                          component={TextField}
                          name="selectedInvoiceLocationId"
                          select
                          fullWidth
                          label={t('TENDER_ORDERS.CHOOSE_LOCATION')}
                          variant="outlined"
                          margin="dense"
                        >
                          {initialValues.partnerInvoiceLocations.map(
                            (location) => (
                              <MenuItem value={location.id} key={location.id}>
                                {location.address +
                                  ' | ' +
                                  location.zipcode +
                                  ' | ' +
                                  location.place +
                                  ' | ' +
                                  countryLookup[location.countryId]}
                              </MenuItem>
                            ),
                          )}
                        </Field>
                      </Grid>
                      {authorize('addPartnerInvoiceLocation') && (
                        <Grid item xs={12} sm={2}>
                          <Tooltip title={t('PARTNER_SETTING.ADD_LOCATION')}>
                            <IconButton
                              color="default"
                              onClick={() => {
                                setIsAddingInvoiceLocation(true)

                                //IE8 Support
                                if (!Date.now) {
                                  Date.now = function () {
                                    return new Date().getTime()
                                  }
                                }

                                let tempId = Date.now()

                                let newLocation = {
                                  id: tempId, //Required to find the indexPartnerLocation within this component
                                  isNew: true,
                                  company: '',
                                  countryId: 80,
                                  address: '',
                                  zipcode: '',
                                  place: '',
                                  active: true,
                                  primary: false,
                                  contactPerson: '',
                                  additionalAddressLine: '',
                                  taxId: '',
                                  email: '',
                                  reference: '',
                                  additionalInvoiceText: '',
                                  taxFree: false,
                                  creditLimit: '',
                                  creditLimitTaxMode: '',
                                }

                                setFieldValue(
                                  'partnerInvoiceLocations',
                                  values.partnerInvoiceLocations.concat(
                                    newLocation,
                                  ),
                                )

                                setFieldValue(
                                  'selectedInvoiceLocationId',
                                  tempId,
                                )
                              }}
                              size="large"
                            >
                              <AddBox />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <Field
                          disabled={true}
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={
                            'partnerInvoiceLocations[' +
                            indexPartnerInvoiceLocation +
                            '].active'
                          }
                          Label={{
                            label: t('PARTNER_SETTINGS.ACTIVE'),
                            hidden: isAddingInvoiceLocation,
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          disabled={true}
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={
                            'partnerInvoiceLocations[' +
                            indexPartnerInvoiceLocation +
                            '].primary'
                          }
                          Label={{
                            label: t('PARTNER_SETTINGS.PRIMARY_LOCATION'),
                            hidden: isAddingInvoiceLocation,
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                      </Grid>
                      {authorize('administrator') && (
                        <Grid item xs={12}>
                          <Typography>
                            {`${t('PARTNER_SETTINGS.DEBTOR_ID')}: ${
                              values.partnerInvoiceLocations[
                                indexPartnerInvoiceLocation
                              ]?.externalDebtorId
                                ? values.partnerInvoiceLocations[
                                    indexPartnerInvoiceLocation
                                  ].externalDebtorId
                                : t('PARTNER_SETTINGS.NOT_ASSIGNED')
                            }`}{' '}
                            <Button
                              variant={'contained'}
                              color={'secondary'}
                              onClick={() => {
                                setDialogExternalDebtorId(
                                  values.partnerInvoiceLocations[
                                    indexPartnerInvoiceLocation
                                  ].externalDebtorId,
                                )
                                setShowDebtorDialog(true)
                              }}
                            >
                              {t('PARTNER_SETTINGS.SET_DEBTOR')}
                            </Button>
                          </Typography>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          label={t('REQUEST.DELIVERY_DETAILS.COMPANY')}
                          name={
                            'partnerInvoiceLocations[' +
                            indexPartnerInvoiceLocation +
                            '].company'
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          disabled={!authorize('updatePartnerInvoiceLocation')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          label={t('REQUEST.DELIVERY_DETAILS.COUNTRY')}
                          name={
                            'partnerInvoiceLocations[' +
                            indexPartnerInvoiceLocation +
                            '].countryId'
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          select
                          disabled={!authorize('updatePartnerInvoiceLocation')}
                        >
                          {Object.entries(countryList).map((country) => (
                            <MenuItem value={country[1]} key={country[0]}>
                              {country[0]}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={
                            t('PARTNER_SETTINGS.TAX_ID') +
                            '(' +
                            taxIdPlaceholder +
                            ')'
                          }
                          variant="outlined"
                          plaxeholder={taxIdPlaceholder}
                          name={
                            'partnerInvoiceLocations[' +
                            indexPartnerInvoiceLocation +
                            '].taxId'
                          }
                          disabled={!authorize('updatePartnerInvoiceLocation')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          label={t('REQUEST.DELIVERY_DETAILS.CONTACT_PERSON')}
                          name={
                            'partnerInvoiceLocations[' +
                            indexPartnerInvoiceLocation +
                            '].contactPerson'
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          disabled={!authorize('updatePartnerInvoiceLocation')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <GoogleAutocomplete
                          setFieldValue={setFieldValue}
                          formikName={`partnerInvoiceLocations[${indexPartnerInvoiceLocation}].address`}
                          formikPath={`partnerInvoiceLocations[${indexPartnerInvoiceLocation}]`}
                          readOnly={!authorize('updatePartnerInvoiceLocation')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          label={t(
                            'REQUEST.DELIVERY_DETAILS.ADDITIONAL_ADDRESS_LINE',
                          )}
                          name={
                            'partnerInvoiceLocations[' +
                            indexPartnerInvoiceLocation +
                            '].additionalAddressLine'
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          disabled={!authorize('updatePartnerInvoiceLocation')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          label={t('REQUEST.DELIVERY_DETAILS.ZIP_CODE')}
                          name={
                            'partnerInvoiceLocations[' +
                            indexPartnerInvoiceLocation +
                            '].zipcode'
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          disabled={!authorize('updatePartnerInvoiceLocation')}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          label={t('REQUEST.DELIVERY_DETAILS.PLACE')}
                          name={
                            'partnerInvoiceLocations[' +
                            indexPartnerInvoiceLocation +
                            '].place'
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          disabled={!authorize('updatePartnerInvoiceLocation')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t('PARTNER_SETTINGS.INVOICE_EMAIL')}
                          variant="outlined"
                          name={
                            'partnerInvoiceLocations[' +
                            indexPartnerInvoiceLocation +
                            '].email'
                          }
                          disabled={!authorize('updatePartnerInvoiceLocation')}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t('PARTNER_SETTINGS.INVOICE_REFERENCE')}
                          variant="outlined"
                          name={
                            'partnerInvoiceLocations[' +
                            indexPartnerInvoiceLocation +
                            '].reference'
                          }
                          disabled={!authorize('updatePartnerInvoiceLocation')}
                        />
                      </Grid>
                      {authorize('administrator') && (
                        <Fragment>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t(
                                'REQUEST.DELIVERY_DETAILS.ADDITIONAL_INVOICE_TEXT',
                              )}
                              variant="outlined"
                              multiline
                              rows="4"
                              name={
                                'partnerInvoiceLocations[' +
                                indexPartnerInvoiceLocation +
                                '].additionalInvoiceText'
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              component={CheckboxWithLabel}
                              type={'checkbox'}
                              color="default"
                              name={
                                'partnerInvoiceLocations[' +
                                indexPartnerInvoiceLocation +
                                '].taxFree'
                              }
                              Label={{
                                label: t('PARTNER_SETTINGS.TAX_FREE'),
                              }}
                              style={{ marginLeft: '15px' }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              label={t(
                                'PARTNER_SETTINGS.CREDIT_LIMIT_TAX_MODE',
                              )}
                              name={
                                'partnerInvoiceLocations[' +
                                indexPartnerInvoiceLocation +
                                '].creditLimitTaxMode'
                              }
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              select
                            >
                              <MenuItem value={'NET'} key={'NET'}>
                                Netto
                              </MenuItem>
                              <MenuItem value={'GROSS'} key={'GROSS'}>
                                Brutto
                              </MenuItem>
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t('PARTNER_SETTINGS.CREDIT_LIMIT')}
                              variant="outlined"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                inputComponent: NumberFormatCustom,
                                decimalScale: 0,
                                allowNegative: false,
                                suffix: ' \u20AC',
                                fixedDecimalScale: true,
                              }}
                              name={
                                'partnerInvoiceLocations[' +
                                indexPartnerInvoiceLocation +
                                '].creditLimit'
                              }
                            />
                          </Grid>
                        </Fragment>
                      )}
                      {authorize('updatePartnerInvoiceLocation') &&
                      values.selectedInvoiceLocationId &&
                      !isAddingInvoiceLocation ? (
                        <Fragment>
                          {values.partnerInvoiceLocations.find(
                            (location) =>
                              location.id === values.selectedInvoiceLocationId,
                          ).active ? (
                            <Grid item xs={12} sm={6}>
                              <Button
                                disabled={
                                  values.partnerInvoiceLocations.find(
                                    (location) =>
                                      location.id ===
                                      values.selectedInvoiceLocationId,
                                  ).primary
                                }
                                onClick={() => {
                                  setFieldValue(
                                    `partnerInvoiceLocations[${indexPartnerInvoiceLocation}].active`,
                                    false,
                                  )
                                }}
                                variant="contained"
                                color="secondary"
                              >
                                {t('PARTNER_SETTINGS.ARCHIVE')}
                              </Button>
                            </Grid>
                          ) : (
                            <Grid item xs={12} sm={6}>
                              <Button
                                onClick={() => {
                                  let indexPartnerLocation =
                                    values.partnerInvoiceLocations.findIndex(
                                      (location) =>
                                        location.id ===
                                        values.selectedInvoiceLocationId,
                                    )

                                  setFieldValue(
                                    `partnerInvoiceLocations[${indexPartnerLocation}].active`,
                                    true,
                                  )
                                }}
                                variant="contained"
                                color="secondary"
                              >
                                {t('PARTNER_SETTINGS.ACTIVATE')}
                              </Button>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6}>
                            <Button
                              onClick={() => {
                                values.partnerInvoiceLocations.forEach(
                                  (location, indexPartnerLocation) => {
                                    if (
                                      location.id ===
                                      values.selectedInvoiceLocationId
                                    ) {
                                      setFieldValue(
                                        `partnerInvoiceLocations[${indexPartnerLocation}].primary`,
                                        true,
                                      )
                                    } else {
                                      setFieldValue(
                                        `partnerInvoiceLocations[${indexPartnerLocation}].primary`,
                                        false,
                                      )
                                    }
                                  },
                                )
                              }}
                              disabled={
                                values.partnerInvoiceLocations.find(
                                  (location) =>
                                    location.id ===
                                    values.selectedInvoiceLocationId,
                                ).primary ||
                                !values.partnerInvoiceLocations.find(
                                  (location) =>
                                    location.id ===
                                    values.selectedInvoiceLocationId,
                                ).active
                              }
                              variant="contained"
                              color="secondary"
                            >
                              {t('PARTNER_SETTINGS.SET_PRIMARY_LOCATION')}
                            </Button>
                          </Grid>
                        </Fragment>
                      ) : null}
                      {isAddingInvoiceLocation ? (
                        <Fragment>
                          <Grid item xs={12} sm={6}>
                            <Button
                              onClick={() => {
                                if (isValid) {
                                  submitForm()
                                  setIsAddingInvoiceLocation(false)
                                }
                              }}
                              variant="contained"
                              color="secondary"
                            >
                              {t('PARTNER_SETTINGS.ADD')}
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Button
                              onClick={() => {
                                let tempLocationId =
                                  values.selectedInvoiceLocationId

                                setFieldValue(
                                  'selectedInvoiceLocationId',
                                  values.partnerInvoiceLocations.find(
                                    (location) => location.primary,
                                  ).id,
                                )

                                setFieldValue(
                                  'partnerInvoiceLocations',
                                  values.partnerInvoiceLocations.filter(
                                    (location) =>
                                      location.id !== tempLocationId,
                                  ),
                                )

                                setIsAddingInvoiceLocation(false)
                              }}
                              variant="outlined"
                              color={'secondary'}
                            >
                              {t('PARTNER_SETTINGS.CANCEL')}
                            </Button>
                          </Grid>
                        </Fragment>
                      ) : null}
                    </Grid>
                  </Section>
                  <Dialog
                    open={showDebtorDialog}
                    onClose={() => setShowDebtorDialog(false)}
                  >
                    <DialogContent>
                      <MUITextField
                        fullWidth
                        label={'PARTNER_SETTINGS.DEBTOR_ID'}
                        variant="outlined"
                        value={dialogExternalDebtorId}
                        onChange={(e) => {
                          setDialogExternalDebtorId(e.target.value)
                        }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setShowDebtorDialog(false)}
                        color="secondary"
                        variant={'outlined'}
                      >
                        {t('GENERAL.CANCEL')}
                      </Button>
                      {!dialogExternalDebtorId ? (
                        <Button
                          variant={'contained'}
                          onClick={async () => {
                            setIsLoading(true)
                            await handleGenerateDebtorClick(
                              values.partnerInvoiceLocations[
                                indexPartnerInvoiceLocation
                              ].id,
                            )
                            setIsLoading(false)
                          }}
                          color="secondary"
                        >
                          {t('PARTNER_SETTINGS.GENERATE_DEBTOR')}
                        </Button>
                      ) : (
                        <Button
                          variant={'contained'}
                          disabled={isLoading}
                          onClick={async () => {
                            setIsLoading(true)
                            await handleSetDebtorClick(
                              values.partnerInvoiceLocations[
                                indexPartnerInvoiceLocation
                              ].id,
                            )
                            setIsLoading(false)
                          }}
                          color="secondary"
                        >
                          {t('PARTNER_SETTINGS.SET_DEBTOR')}
                        </Button>
                      )}
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    style={{ margin: '0 0 15px 15px' }}
                    type="submit"
                  >
                    {t('PARTNER_SETTINGS.SAVE')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    )
  )
}

export default withTranslation()(connect()(Addresses))
