import moment from 'moment'
import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'
import { generateFilterValuesQueryString } from '../_helpers/little.js'

export const ordersService = {
  createOrders,
  deleteOrder,
  updateOrder,
  getOrder,
  getOrdersOfPartner,
  getOrders,
  createRequestOfOrder,
  getUnallocatedOrdersOfPartner,
  claimOrder,
  getOrdersWithClaims,
  mapOrders,
}

function createOrders(orders) {
  var payload = mapOrders(orders)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(`${config.apiUrlBase}/orders`, requestOptions).then(
    handleResponse,
  )
}

function deleteOrder(orderId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(`${config.apiUrlBase}/orders/${orderId}`, requestOptions).then(
    handleResponse,
  )
}

function updateOrder(payload, orderId) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }

  return fetch(`${config.apiUrlBase}/orders/${orderId}`, requestOptions).then(
    handleResponse,
  )
}

function createRequestOfOrder(values, orderId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(values),
  }

  return fetch(
    `${config.apiUrlBase}/orders/${orderId}/request`,
    requestOptions,
  ).then(handleResponse)
}

function getOrder(orderId, partnerId = undefined) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    partnerId
      ? `${config.apiUrlBase}/orders/${orderId}?partnerId=${partnerId}`
      : `${config.apiUrlBase}/orders/${orderId}`,
    requestOptions,
  ).then(handleResponse)
}

function getOrders(filterValues = undefined, pagination) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  filterValues = filterValues.calendarWeek
    ? {
        ...filterValues,
        calendarWeek: moment(filterValues.calendarWeek[0]).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      }
    : filterValues

  let queryString = generateFilterValuesQueryString(filterValues, pagination)
  return fetch(
    `${config.apiUrlBase}/orders${queryString}`,
    requestOptions,
  ).then(handleResponse)
}

function getOrdersWithClaims(filterValues = undefined, pagination) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  filterValues = filterValues.calendarWeek
    ? {
        ...filterValues,
        calendarWeek: moment(filterValues.calendarWeek[0]).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      }
    : filterValues

  let queryString = generateFilterValuesQueryString(filterValues, pagination)
  return fetch(
    `${config.apiUrlBase}/orders-with-claims${queryString}`,
    requestOptions,
  ).then(handleResponse)
}

function getOrdersOfPartner(
  partnerId,
  mode,
  filterValues = undefined,
  pagination,
  partnerLocationIds,
) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  filterValues = filterValues.calendarWeek
    ? {
        ...filterValues,
        calendarWeek: moment(filterValues.calendarWeek[0]).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      }
    : filterValues

  let queryString = generateFilterValuesQueryString(filterValues, pagination)

  return fetch(
    partnerLocationIds.length === 0
      ? `${config.apiUrlBase}/partners/${partnerId}/orders${queryString}&mode=${mode}&partnerLocationIds=null`
      : `${
          config.apiUrlBase
        }/partners/${partnerId}/orders${queryString}&mode=${mode}&${partnerLocationIds
          .map((partnerLocationId) => 'partnerLocationIds=' + partnerLocationId)
          .join('&')}`,
    requestOptions,
  ).then(handleResponse)
}

function getUnallocatedOrdersOfPartner(
  partnerId,
  filterValues = undefined,
  pagination,
) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  filterValues = filterValues.calendarWeek
    ? {
        ...filterValues,
        calendarWeek: moment(filterValues.calendarWeek[0]).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      }
    : filterValues

  let queryString = generateFilterValuesQueryString(filterValues, pagination)

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/unallocated-orders${queryString}`,
    requestOptions,
  ).then(handleResponse)
}

function mapOrders(orders) {
  return orders.deliveries.map((delivery) => ({
    incoTerm: orders.generalInformation.deliveryDetails.incoTerm,
    requestId: orders.requestId,
    deliveryType: delivery.type,
    deliveryValue: moment(delivery.value).format('YYYY-MM-DD'),
    orderNumber: orders.orderNumber,
    type: orders.type,
    state: orders.state,
    company: orders.generalInformation.deliveryDetails.locations[0].company,
    zipcode: orders.generalInformation.deliveryDetails.locations[0].zipcode,
    place: orders.generalInformation.deliveryDetails.locations[0].place,
    address: orders.generalInformation.deliveryDetails.locations[0].address,
    unload: orders.generalInformation.deliveryDetails.locations[0].unload,
    stackHeight:
      orders.generalInformation.deliveryDetails.locations[0].stackHeight,
    stackHeightUnit:
      orders.generalInformation.deliveryDetails.locations[0].stackHeightUnit,
    deliveryTimes:
      orders.generalInformation.deliveryDetails.locations[0].deliveryTimes,
    deliveryInformations:
      orders.generalInformation.deliveryDetails.locations[0]
        .deliveryInformations,
    isDespatchAdviceRequired:
      orders.generalInformation.deliveryDetails.locations[0]
        .isDespatchAdviceRequired,
    despatchAdviceInstructions:
      orders.generalInformation.deliveryDetails.locations[0]
        .despatchAdviceInstructions,
    countryId: orders.generalInformation.deliveryDetails.locations[0].countryId,
    customerId: orders.customerId,
    customerUserId: orders.customerUserId,
    manufacturerId: orders.manufacturerId ? orders.manufacturerId : null,
    manufacturerUserId: orders.manufacturerUserId
      ? orders.manufacturerUserId
      : null,
    tenderOrderId: orders.tenderOrderId ? orders.tenderOrderId : null,
    skonto: orders.generalInformation.paymentDetails.skonto,
    skontoPeriod: orders.generalInformation.paymentDetails.skontoPeriod,
    paymentTerms: orders.generalInformation.paymentDetails.paymentTerm,
    internalNote: orders.internalNote,
    latitude: orders.latitude,
    longitude: orders.longitude,
    partnerInvoiceLocationId: orders.partnerInvoiceLocationId,
    positions: delivery.positions
      .filter((dPos) => parseFloat(dPos.amount) > 0)
      .map((dPos) => {
        const pos =
          orders.generalInformation.deliveryDetails.locations[0].positions.find(
            (pos) => parseInt(pos.id) === parseInt(dPos.positionId),
          )
        return {
          id: pos.id,
          productId: pos.productId,
          productUnitId: pos.productUnitId,
          amount: dPos.amount,
          tenderOrderPositionId: orders.tenderOrderId
            ? pos.tenderOrderPositionId
              ? pos.tenderOrderPositionId
              : pos.id
            : null,
          locationId: orders.tenderOrderId ? pos.locationId : null,
          purchasePrice: pos.purchasePrice,
          salesPrice: pos.salesPrice,
          positionProperties: pos.positionProperties.map((positionProperty) => {
            return {
              ...positionProperty,
              productPropertyId: positionProperty.productProperty.id,
            }
          }),
        }
      }),
  }))
}

function claimOrder(orderId, partnerId, userId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ partnerId: partnerId, userId: userId }),
  }

  return fetch(
    `${config.apiUrlBase}/orders/${orderId}/claim-order`,
    requestOptions,
  ).then(handleResponse)
}

export * from './ordersService'
