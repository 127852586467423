import CssBaseline from '@mui/material/CssBaseline'
import { csCZ, deDE, enUS, plPL } from '@mui/material/locale'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import moment from 'moment'
import 'moment/locale/de'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import 'typeface-comfortaa'
import App from './App'
import { Loading } from './_components/Loading'
import config from './_config/config.js'
import { store } from './_helpers/store'
import i18n from './i18n'
import keycloak from './keycloak'
import * as serviceWorker from './serviceWorker'

const languageLookup = {
  de: deDE,
  en: enUS,
  pl: plPL,
  cs: csCZ,
}

const theme = createTheme(
  {
    typography: {
      fontFamily: 'Comfortaa',
      fontSize: 12,
      body1: {
        color: '#333333',
      },
    },
    palette: {
      primary: {
        main:
          window.location.host === 'logcoop.packlogx.com' ||
          window.location.host === 'logcoop-test.packlogx.com' ||
          window.location.host === 'logcoop-demo.packlogx.com'
            ? '#e30421'
            : window.location.host === 'portal.packlogx.com' ||
              window.location.host === 'portal-test.packlogx.com' ||
              window.location.host === 'portal-demo.packlogx.com'
            ? '#94c11f'
            : '#7EC07E',
      },
      secondary: {
        main:
          window.location.host === 'logcoop.packlogx.com' ||
          window.location.host === 'logcoop-test.packlogx.com' ||
          window.location.host === 'logcoop-demo.packlogx.com'
            ? '#565555'
            : window.location.host === 'portal.packlogx.com' ||
              window.location.host === 'portal-test.packlogx.com' ||
              window.location.host === 'portal-demo.packlogx.com'
            ? '#3267C2'
            : '#577a7d',
      },
      info: {
        light: '#00f3f3',
        main: '#B5DBDE',
        dark: '#85a9ac',
      },
      success: {
        light: '#aff3ae',
        main: '#7EC07E',
        dark: '#4f8f51',
      },
      accent1: '#fb5315',
      accent2: '#0b6467',
    },
    components: {
      MuiTablePagination: {
        styleOverrides: {
          displayedRows: {
            marginBottom: 0,
          },
          selectLabel: {
            marginBottom: 0,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            position: 'relative',
            marginLeft: '-22px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: '#6C6C6C',
              backgroundColor: '#f8f9fa',
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            '*::-webkit-scrollbar': {
              backgroundColor: '#ffffff',
              width: '16px',
              background: 'transparent',
            },
            '*::-webkit-scrollbar-track': {
              backgroundColor: '#fff',
            },
            '*::-webkit-scrollbar-track:hover': {
              backgroundColor: '#f4f4f4',
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: '#babac0',
              borderRadius: '16px',
              border: '5px solid #fff',
            },
            '*::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#a0a0a5',
              border: '5px solid #f4f4f4',
            },
          },
        },
      },
    },
  },
  languageLookup[localStorage.getItem('i18nextLng')],
)

if (config.apiUrlBase !== 'http://localhost:7777') {
  console.log = function () {}
}

const root = createRoot(document.getElementById('root'))
root.render(
  <ReactKeycloakProvider authClient={keycloak} LoadingComponent={<Loading />}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          locale={moment.locale(i18n.language.substring(0, 2))}
        >
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  </ReactKeycloakProvider>,
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
