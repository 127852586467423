import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { countryLookup } from '../../../../../../../../_constants/lookupConstants'
import { partnerLocationsService } from '../../../../../../../../_services/partnerLocationsService'
import { userPartnerLocationsService } from '../../../../../../../../_services/userPartnerLocationsService'
import { Loading } from '../../../../../../../Loading'

function not(a, b) {
  return a.filter((partnerLocation) => b.indexOf(partnerLocation) === -1)
}

function intersection(a, b) {
  return a.filter((partnerLocation) => b.indexOf(partnerLocation) !== -1)
}

const UserPartnerLocations = (props) => {
  const { t, user, forceUpdate } = props

  const [isLoading, setIsLoading] = useState(false)

  const [checked, setChecked] = useState([])
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (partnerLocation) => () => {
    const currentIndex = checked.indexOf(partnerLocation)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(partnerLocation)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = async () => {
    const selectedPartnerLocations = right.concat(left)

    setRight(selectedPartnerLocations)
    setLeft([])

    updateUserPartnerLocations(selectedPartnerLocations)
  }

  const handleCheckedRight = () => {
    const selectedPartnerLocations = right.concat(leftChecked)

    setRight(selectedPartnerLocations)
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))

    updateUserPartnerLocations(selectedPartnerLocations)
  }

  const handleCheckedLeft = () => {
    const selectedPartnerLocations = not(right, rightChecked)

    setLeft(left.concat(rightChecked))
    setRight(selectedPartnerLocations)
    setChecked(not(checked, rightChecked))

    updateUserPartnerLocations(selectedPartnerLocations)
  }

  const handleAllLeft = async () => {
    const selectedPartnerLocations = []
    setLeft(left.concat(right))
    setRight(selectedPartnerLocations)

    updateUserPartnerLocations(selectedPartnerLocations)
  }

  const updateUserPartnerLocations = async (partnerLocations) => {
    setIsLoading(true)
    try {
      await userPartnerLocationsService.updateUserPartnerLocations(
        user.id,
        partnerLocations.map((partnerLocation) => partnerLocation.id),
      )
      forceUpdate()
    } finally {
      setIsLoading(false)
    }
  }

  const userObject = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (!user) {
      return
    }

    let unassignedPartnerLocations = []
    let assignedPartnerLocations = []

    const fetchPartnerLocations = async () => {
      try {
        setIsLoading(true)
        const partnerLocations =
          await partnerLocationsService.getPartnerLocationsOfPartner(
            userObject.partner.id,
          )

        partnerLocations.forEach((partnerLocation) => {
          const matchingPartnerLocation = user.partnerLocations.find(
            (partnerLocationOfUser) =>
              partnerLocationOfUser.id === partnerLocation.id,
          )

          if (matchingPartnerLocation) {
            assignedPartnerLocations.push(partnerLocation)
          } else {
            unassignedPartnerLocations.push(partnerLocation)
          }
        })

        setLeft(unassignedPartnerLocations)
        setRight(assignedPartnerLocations)
      } catch (e) {
        console.log(e)
      } finally {
        setIsLoading(false)
      }
    }

    fetchPartnerLocations()
  }, [user])

  const customList = (items, header) => (
    <Paper sx={{ minHeight: 250, overflow: 'auto' }}>
      <List
        dense
        component="div"
        role="list"
        subheader={<ListSubheader>{header}</ListSubheader>}
      >
        {items.map((partnerLocation) => {
          const labelId = `transfer-list-item-${partnerLocation}-label`

          return (
            <ListItem
              key={partnerLocation}
              role="listitem"
              button
              onClick={handleToggle(partnerLocation)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(partnerLocation) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${partnerLocation.company}, ${
                  partnerLocation.address
                }, ${partnerLocation.zipcode} ${partnerLocation.place} ${
                  countryLookup[partnerLocation.countryId]
                }`}
              />
            </ListItem>
          )
        })}
      </List>
    </Paper>
  )

  return (
    (isLoading && <Loading variant={'centered'} />) ||
    (user.roles.some((role) => role.id === 4) ? (
      <Typography
        sx={{
          margin: 2,
        }}
        variant={'h6'}
        align={'center'}
      >
        {t('USERS.IS_LOCATION_MANAGER_MESSAGE')}
      </Typography>
    ) : (
      <Grid container spacing={2} justifyContent="center" alignItems="stretch">
        <Grid item xs={12} md={5}>
          {customList(left, t('USERS.UNASSIGNED_LOCATIONS'))}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          {customList(right, t('USERS.ASSIGNED_LOCATIONS'))}
        </Grid>
      </Grid>
    ))
  )
}

export default withTranslation()(UserPartnerLocations)
