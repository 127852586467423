import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material'
import moment from 'moment'
import Papa from 'papaparse'
import { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { countryLookup } from '../../../../../../_constants/lookupConstants'
import {
  calculateDistance,
  calculatePriceByKm,
} from '../../../../../../_helpers/little'
import {
  formatToNumber,
  formatToPrice,
} from '../../../../../../_helpers/number-formatting'
import { automaticOfferPositionsService } from '../../../../../../_services/automaticOfferPositionsService'
import { Loading } from '../../../../../Loading'
import PositionCard from '../../../../../PositionCard'
import OffersOfAutomaticOfferPositionDialog from './_components/OffersOfAutomaticOfferPositionDialog'
import OrdersOfAutomaticOfferPositionDialog from './_components/OrdersOfAutomaticOfferPositionDialog'

const AutomaticOfferPosition = (props) => {
  const { t, automaticOfferPositionId } = props
  const [tabValue, setTabValue] = useState(0)
  const [automaticOfferPosition, setAutomaticOfferPosition] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [forceUpdateCount, forceUpdate] = useState(0)

  const [zipcodeCoords, setZipcodeCoords] = useState({})

  useEffect(() => {
    if (!automaticOfferPosition) return

    setIsLoading(true)
    const checkFileExists = async (csvFileUrl) => {
      try {
        const response = await fetch(csvFileUrl, { method: 'HEAD' })
        return response.ok // Gibt true zurück, wenn die Datei existiert
      } catch (error) {
        console.error('Fehler beim Überprüfen der CSV-Datei:', error)
        return false // Bei einem Fehler gehen wir davon aus, dass die Datei nicht existiert
      }
    }

    const parseCSV = async (csvFileUrl) => {
      return new Promise((resolve, reject) => {
        Papa.parse(csvFileUrl, {
          download: true,
          header: true,
          complete: (result) => {
            resolve(result.data)
          },
          error: (error) => {
            reject(error)
          },
        })
      })
    }

    const init = async () => {
      let combinedData = {}

      for (
        let index = 0;
        index < automaticOfferPosition.countries.length;
        index++
      ) {
        const countryId = automaticOfferPosition.countries[index]

        const csvFileUrl = `other/zipcode-geocoord-${countryId}.csv`
        const fileExists = await checkFileExists(csvFileUrl)
        if (fileExists) {
          const data = await parseCSV(csvFileUrl)
          combinedData[countryId] = data
        } else {
          combinedData[countryId] = []
        }
      }

      setZipcodeCoords(combinedData)
      setIsLoading(false)
    }

    init()
  }, [automaticOfferPosition])

  useEffect(() => {
    automaticOfferPositionsService
      .getAutomaticOfferPosition(automaticOfferPositionId)
      .then((data) => {
        setAutomaticOfferPosition(data)
      })
  }, [automaticOfferPositionId, forceUpdateCount])

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue)
  }
  return isLoading || !automaticOfferPosition ? (
    <Loading variant={'centered'} />
  ) : (
    <Grid container spacing={1} justifyContent="center" alignItems="flex-start">
      <Grid item xs={6}>
        <Box margin={1}>
          <PositionCard
            data={automaticOfferPosition}
            showProductDetails={true}
            readOnly={true}
            showImage={true}
            imageSize={'small'}
          >
            <List
              dense={true}
              sx={{ width: '100%', bgcolor: 'background.paper' }}
            >
              {(automaticOfferPosition.type === 'DISTANCE_BASED' ||
                Object.keys(automaticOfferPosition.zipcodeAreas).length ===
                  0) && (
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      automaticOfferPosition.type === 'DISTANCE_BASED'
                        ? t('AUTOMATIC_OFFERS.EXW_PRICE_EACH')
                        : t('AUTOMATIC_OFFERS.PRICE_EACH')
                    }
                    secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {formatToPrice(automaticOfferPosition.price)}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
              <Divider />
              {automaticOfferPosition.type === 'DISTANCE_BASED' &&
                Object.keys(automaticOfferPosition.zipcodeAreas).length ===
                  0 && (
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('AUTOMATIC_OFFERS.PRICE_EACH_KM')}
                      secondary={
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {formatToPrice(automaticOfferPosition.priceEachKm)}
                        </Typography>
                      }
                    />
                  </ListItem>
                )}
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={t('AUTOMATIC_OFFERS.VALID_UNTIL')}
                  secondary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {moment(automaticOfferPosition.validUntil).format(
                        'DD.MM.YYYY',
                      )}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
              {automaticOfferPosition.earliestDeliveryDate && (
                <>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('AUTOMATIC_OFFERS.EARLIEST_DELIVERY_DATE')}
                      secondary={
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {moment(
                            automaticOfferPosition.earliestDeliveryDate,
                          ).format('DD.MM.YYYY')}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <Divider />
                </>
              )}
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={t('AUTOMATIC_OFFERS.MAX_PAYMENT_TERM')}
                  secondary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {automaticOfferPosition.maxPaymentTerm}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={t('AUTOMATIC_OFFERS.OFFER_VALIDITY_IN_DAYS')}
                  secondary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {automaticOfferPosition.offerValidityInDays}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={t('AUTOMATIC_OFFERS.LEAD_TIME_IN_DAYS')}
                  secondary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {automaticOfferPosition.leadTimeInDays}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider />
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={t('AUTOMATIC_OFFERS.MAX_AMOUNT_EACH_CALENDAR_WEEK')}
                  secondary={
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {automaticOfferPosition.maxAmountEachCalendarWeek}
                    </Typography>
                  }
                />
              </ListItem>
              {automaticOfferPosition.maxDistanceInKm && (
                <Fragment>
                  <Divider />
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      primary={t('AUTOMATIC_OFFERS.MAX_DISTANCE_IN_KM')}
                      secondary={
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          {formatToNumber(
                            automaticOfferPosition.maxDistanceInKm,
                          )}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Fragment>
              )}
            </List>
          </PositionCard>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box margin={1}>
          {automaticOfferPosition.countries.map((countryId) => (
            <TableContainer component={Paper}>
              {automaticOfferPosition.zipcodeAreas[countryId] && (
                <Table sx={{ maxWidth: '100%' }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6">
                          {countryLookup[countryId]}
                        </Typography>
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        <Typography variant="h6">
                          {automaticOfferPosition.type === 'DISTANCE_BASED'
                            ? t('AUTOMATIC_OFFERS.PRICE_EACH_KM')
                            : t('AUTOMATIC_OFFERS.PRICE_EACH')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell>PLZ</TableCell>
                      {Object.keys(
                        automaticOfferPosition.stackHeightLookup,
                      ).map((key) => (
                        <TableCell align="right">
                          {key === 'null' ? t('AUTOMATIC_OFFERS.MAXIMUM') : key}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(automaticOfferPosition.zipcodeAreas[countryId])
                      .sort()
                      .map((zipcode) => (
                        <TableRow
                          key={zipcode}
                          sx={{
                            '&:last-child td, &:last-child th': {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {zipcode}
                          </TableCell>
                          {Object.keys(
                            automaticOfferPosition.stackHeightLookup,
                          ).map((key) => {
                            const areaCoords = zipcodeCoords[countryId]?.find(
                              (zipcodeCoord) =>
                                zipcodeCoord.zipcode.startsWith(zipcode),
                            )

                            let distance = 0
                            if (areaCoords) {
                              distance = calculateDistance(
                                automaticOfferPosition.partnerLocation.latitude,
                                automaticOfferPosition.partnerLocation
                                  .longitude,
                                parseFloat(areaCoords.lat),
                                parseFloat(areaCoords.lng),
                              )
                            }

                            return (
                              <TableCell
                                align="right"
                                component="th"
                                scope="row"
                              >
                                {automaticOfferPosition.type ===
                                'DISTANCE_BASED'
                                  ? formatToPrice(
                                      calculatePriceByKm(
                                        distance,
                                        key,
                                        automaticOfferPosition.zipcodeAreas[
                                          countryId
                                        ][zipcode].price,
                                        automaticOfferPosition,
                                      ),
                                    )
                                  : formatToPrice(
                                      parseFloat(
                                        automaticOfferPosition.zipcodeAreas[
                                          countryId
                                        ][zipcode].price,
                                      ) +
                                        parseFloat(
                                          automaticOfferPosition
                                            .stackHeightLookup[key],
                                        ),
                                    )}
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Tabs value={tabValue} onChange={handleChangeTab} centered>
          <Tab label={t('AUTOMATIC_OFFERS.OFFERS')} />
          <Tab label={t('AUTOMATIC_OFFERS.ORDERS')} />
        </Tabs>
      </Grid>
      {tabValue === 0 && (
        <Grid item xs={12}>
          <OffersOfAutomaticOfferPositionDialog
            automaticOfferPositionId={automaticOfferPositionId}
            forceUpdate={() => {
              forceUpdate(forceUpdateCount + 1)
            }}
          />
        </Grid>
      )}
      {tabValue === 1 && (
        <Grid item xs={12}>
          <OrdersOfAutomaticOfferPositionDialog
            automaticOfferPositionId={automaticOfferPositionId}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default withTranslation()(AutomaticOfferPosition)
