import { TextField as MuiTextField } from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import { Autocomplete } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { countriesService } from '../../_services/countriesService'
import i18n from '../../i18n'

const GoogleAutocomplete = (props) => {
  const { t, setFieldValue, formikName, formikPath, readOnly } = props

  const [placePredictions, setPlacePredictions] = useState([])
  const [countries, setCountries] = useState([])
  const [autoCompleteSessionToken, setAutoCompleteSessionToken] = useState(
    window.google
      ? new window.google.maps.places.AutocompleteSessionToken()
      : undefined,
  )

  useEffect(() => {
    const getCountries = async () => {
      let data = await countriesService.getCountries()
      setCountries(data)
    }
    getCountries()
  }, [])

  return (
    <Fragment>
      {
        //Dummy map element is needed to access placesService
        <div id={'map-canvas'}></div>
      }
      <Field
        name={formikName}
        component={Autocomplete}
        options={placePredictions}
        getOptionLabel={(placePrediction) =>
          placePrediction.description
            ? placePrediction.description
            : placePrediction
        }
        onChange={async (e, value) => {
          if (!value || !value.place_id) {
            return
          }

          try {
            var map = new window.google.maps.Map(
              document.getElementById('map-canvas'),
            )

            let service = await new window.google.maps.places.PlacesService(map)
            await service.getDetails(
              {
                placeId: value.place_id,
                fields: ['address_components'],
                language: i18n.language.substring(0, 2),
                sessionToken: autoCompleteSessionToken,
              },
              async (place) => {
                let street = place.address_components.find((addressComponent) =>
                  addressComponent.types.some((type) => type === 'route'),
                )

                let streetNumber = place.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.some(
                      (type) => type === 'street_number',
                    ),
                )

                let zipcode = place.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.some(
                      (type) => type === 'postal_code',
                    ),
                )

                let locality = place.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.some((type) => type === 'locality'),
                )

                let placeCountry = place.address_components.find(
                  (addressComponent) =>
                    addressComponent.types.some((type) => type === 'country'),
                )

                let country = countries.find(
                  (country) =>
                    country.code.toLowerCase() ===
                    placeCountry.short_name.toLowerCase(),
                )

                if (streetNumber) {
                  setFieldValue(
                    formikName,
                    `${street.long_name} ${streetNumber.long_name}`,
                  )
                } else {
                  setFieldValue(formikName, `${street.long_name}`)
                }

                if (zipcode) {
                  setFieldValue(
                    formikPath ? `${formikPath}.zipcode` : 'zipcode',
                    zipcode.long_name,
                  )
                }

                if (locality) {
                  setFieldValue(
                    formikPath ? `${formikPath}.place` : 'place',
                    locality.long_name,
                  )
                }

                if (country) {
                  setFieldValue(
                    formikPath ? `${formikPath}.countryId` : 'countryId',
                    country.id,
                  )
                }

                setAutoCompleteSessionToken(
                  new window.google.maps.places.AutocompleteSessionToken(),
                )
              },
            )
          } catch (e) {
            console.error(e)
            console.error('Autocompletion failed')
          }
        }}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            label={t('REQUEST.DELIVERY_DETAILS.ADDRESS')}
            variant="outlined"
            margin={'dense'}
            freeSolo={true}
            onChange={async (e) => {
              setFieldValue(formikName, e.target.value)

              if (e.target.value.length > 2) {
                try {
                  let service =
                    new window.google.maps.places.AutocompleteService()

                  let result = await service.getPlacePredictions({
                    input: e.target.value,
                    language: i18n.language.substring(0, 2),
                    types: ['route'],
                    sessionToken: autoCompleteSessionToken,
                  })
                  setPlacePredictions(result.predictions)
                } catch (e) {
                  console.error('Autocompletion failed')
                }
              }
            }}
          />
        )}
        disabled={readOnly}
      />
      <ErrorMessage
        name={formikName}
        render={(msg) => <div className="help-block">{msg}</div>}
      />
    </Fragment>
  )
}

export default withTranslation()(connect()(GoogleAutocomplete))
