import config from '../_config/config'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const communitiesService = {
  getCommunity,
  updateCommunity,
  getCommunities,
  createCommunityOfPartner,
  getCommunitiesOfPartner,
}

function getCommunity(communityId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${config.apiUrlBase}/communities/${communityId}`,
    requestOptions,
  ).then(handleResponse)
}

function updateCommunity(communityId, requestBody) {
  var requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }

  return fetch(
    `${config.apiUrlBase}/communities/${communityId}`,
    requestOptions,
  ).then(handleResponse)
}
function getCommunities(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/communities?partnerId=${partnerId}`,
    requestOptions,
  )
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function getCommunitiesOfPartner(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/communities`,
    requestOptions,
  ).then(handleResponse)
}

function createCommunityOfPartner(requestBody) {
  let partnerId = requestBody.ownerPartnerId
  var requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/communities`,
    requestOptions,
  ).then(handleResponse)
}
export * from './communitiesService'
