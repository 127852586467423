import { Add, Delete, Edit } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { generateField } from '../../../../_helpers/productPropertyFieldGenerator'
import { productPropertiesService } from '../../../../_services/productPropertiesService'
import { productPropertyCategoriesService } from '../../../../_services/productPropertyCategoriesService'
import AgreementDialog from '../../../AgreementDialog'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { Loading } from '../../../Loading'
import { Section } from '../../../Section'

const ProductPropertyEditor = (props) => {
  const { t } = props

  const [isLoading, setIsLoading] = useState(true)
  const [productProperties, setProductProperties] = useState([])
  const [productPropertyCategories, setProductPropertyCategories] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)

  const valSchema = Yup.object().shape({
    name: Yup.string()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    type: Yup.string()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    variant: Yup.string().when('type', ([type], schema) => {
      return type === 'ENUM'
        ? Yup.string()
            .required(t('GENERAL.REQUIRED'))
            .typeError(t('GENERAL.REQUIRED'))
        : Yup.mixed().nullable()
    }),
    width: Yup.number()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED'))
      .min(1)
      .max(12),
    renderPosition: Yup.number()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED'))
      .min(0),
    unit: Yup.string().nullable(),
    isPrimary: Yup.bool()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    isRequired: Yup.bool()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    isHighlight: Yup.bool()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    productPropertyCategoryId: Yup.number()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
  })

  useEffect(() => {
    setIsLoading(true)
    productPropertyCategoriesService
      .getProductPropertyCategories()
      .then((data) => {
        setProductPropertyCategories(data)
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    productPropertiesService.getProductProperties().then((data) => {
      setProductProperties(data)
      setIsLoading(false)
    })
  }, [forceUpdateCount])
  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Fragment>
        <Button
          size="medium"
          color={'secondary'}
          variant={'contained'}
          startIcon={<Add />}
          onClick={() => {
            setSelectedItem(null)
            setShowCreateDialog(true)
          }}
        >
          {t('PRODUCT_EDITOR.ADD_PRODUCT_PROPERTY_CATEGORY')}
        </Button>
        <List sx={{ width: '40%' }}>
          {productProperties.map((productProperty) => (
            <ListItem
              secondaryAction={
                <Fragment>
                  <IconButton
                    onClick={() => {
                      setSelectedItem(productProperty)
                      setShowCreateDialog(true)
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSelectedItem(productProperty)
                      setShowDeleteDialog(true)
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Fragment>
              }
            >
              <ListItemText primary={productProperty.name}></ListItemText>
            </ListItem>
          ))}
        </List>
        <AgreementDialog
          open={showDeleteDialog}
          message={t('PRODUCT_EDITOR.DELETE_MESSAGE_PRODUCT_PROPERTY')}
          acceptButtonText={t('GENERAL.CONFIRM')}
          handleClose={() => {
            setSelectedItem(null)
            setShowDeleteDialog(false)
          }}
          agree={() => {
            setIsLoading(true)
            productPropertiesService
              .deleteProductProperty(selectedItem.id)
              .then(() => {
                forceUpdate(forceUpdateCount + 1)
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                setIsLoading(false)
                setSelectedItem(null)
                setShowDeleteDialog(false)
              })
          }}
        />
        <Formik
          enableReinitialize={true}
          initialValues={
            selectedItem
              ? {
                  name: selectedItem.name,
                  type: selectedItem.type,
                  width: selectedItem.width,
                  renderPosition: selectedItem.renderPosition,
                  unit: selectedItem.unit,
                  productPropertyCategoryId:
                    selectedItem.productPropertyCategoryId,
                  variant: selectedItem.variant,
                  isPrimary: selectedItem.isPrimary,
                  isRequired: selectedItem.isRequired,
                  isHighlight: selectedItem.isHighlight,
                  productPropertyArray: selectedItem.productPropertyArray
                    ? selectedItem.productPropertyArray
                    : undefined,
                  productPropertyEnumValues:
                    selectedItem.productPropertyEnumValues
                      ? selectedItem.productPropertyEnumValues
                      : undefined,
                  conditions: selectedItem.conditions,
                  disableConditions: selectedItem.disableConditions,
                  triggers: selectedItem.triggers ? selectedItem.triggers : [],
                  primaryRenderVariant: selectedItem.primaryRenderVariant,
                  productPropertyLocalizations:
                    selectedItem.productPropertyLocalizations,
                }
              : {
                  name: '',
                  type: 'INTEGER',
                  width: 12,
                  renderPosition: 0,
                  unit: null,
                  productPropertyCategoryId: null,
                  variant: undefined,
                  isPrimary: false,
                  isRequired: false,
                  isHighlight: false,
                  productPropertyEnumValues: undefined,
                  productPropertyArray: undefined,
                  conditions: null,
                  disableConditions: null,
                  triggers: [],
                  primaryRenderVariant: 'KEY/VALUE',
                  productPropertyLocalizations: [
                    { language: 'de', value: '' },
                    { language: 'en', value: '' },
                    { language: 'pl', value: '' },
                    { language: 'cs', value: '' },
                    { language: 'bg', value: '' },
                    { language: 'da', value: '' },
                    { language: 'et', value: '' },
                    { language: 'fi', value: '' },
                    { language: 'fr', value: '' },
                    { language: 'el', value: '' },
                    { language: 'it', value: '' },
                    { language: 'lt', value: '' },
                    { language: 'lv', value: '' },
                    { language: 'nl', value: '' },
                    { language: 'pt-PT', value: '' },
                    { language: 'ro', value: '' },
                    { language: 'sv', value: '' },
                    { language: 'sk', value: '' },
                    { language: 'sl', value: '' },
                    { language: 'es', value: '' },
                    { language: 'uk', value: '' },
                    { language: 'hu', value: '' },
                  ],
                }
          }
          validationSchema={valSchema}
          //validateOnBlur={false}
          onSubmit={(values, { setSubmitting }) => {
            if (selectedItem) {
              productPropertiesService
                .updateProductProperty(selectedItem.id, values)
                .then((data) => {
                  forceUpdate(forceUpdateCount + 1)
                  setShowCreateDialog(false)
                })
                .catch(function (error) {
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                })
            } else {
              productPropertiesService
                .createProductProperty(values)
                .then((data) => {
                  forceUpdate(forceUpdateCount + 1)
                  setShowCreateDialog(false)
                })
                .catch(function (error) {
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                })
            }
          }}
        >
          {({ values, isSubmitting, submitForm, isValid, setFieldValue }) => {
            function generateConditionFields(
              conditions,
              values,
              hierarchyLevel,
              formikNameSuffix = '',
              formikPrefix,
            ) {
              let operator = conditions[0]

              return (
                <Fragment>
                  <Grid container spacing={3} justifyContent={'flex-start'}>
                    <Grid item xs={4}>
                      <Field
                        component={TextField}
                        select
                        name={`${formikPrefix}${formikNameSuffix}[0]`}
                        label={t('PRODUCT_EDITOR.OPERATOR')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        onChange={(e) => {
                          setFieldValue(
                            `${formikPrefix}${formikNameSuffix}[0]`,
                            e.target.value,
                          )
                          if (
                            e.target.value === 'AND' ||
                            e.target.value === 'OR'
                          ) {
                            setFieldValue(
                              `${formikPrefix}${formikNameSuffix}[1]`,
                              ['EQ', null, null],
                            )
                            setFieldValue(
                              `${formikPrefix}${formikNameSuffix}[2]`,
                              ['EQ', null, null],
                            )
                          } else {
                            setFieldValue(
                              `${formikPrefix}${formikNameSuffix}[1]`,
                              null,
                            )
                            setFieldValue(
                              `${formikPrefix}${formikNameSuffix}[2]`,
                              null,
                            )
                          }
                        }}
                      >
                        <MenuItem key={'AND'} value={'AND'}>
                          {'AND'}
                        </MenuItem>
                        <MenuItem key={'OR'} value={'OR'}>
                          {'OR'}
                        </MenuItem>
                        <MenuItem key={'EQ'} value={'EQ'}>
                          {'EQ'}
                        </MenuItem>
                        <MenuItem key={'NE'} value={'NE'}>
                          {'NE'}
                        </MenuItem>
                      </Field>
                    </Grid>
                  </Grid>
                  {(operator === 'AND' || operator === 'OR') && (
                    <Box marginLeft={10}>
                      {generateConditionFields(
                        conditions[1],
                        values,
                        hierarchyLevel + 1,
                        `${formikNameSuffix}[1]`,
                        formikPrefix,
                      )}
                      {generateConditionFields(
                        conditions[2],
                        values,
                        hierarchyLevel + 1,
                        `${formikNameSuffix}[2]`,
                        formikPrefix,
                      )}
                    </Box>
                  )}
                  {(operator === 'EQ' || operator === 'NE') && (
                    <Grid container spacing={3} justifyContent={'flex-end'}>
                      <Grid item xs={4}>
                        <Field
                          component={TextField}
                          select
                          name={`${formikPrefix}${formikNameSuffix}[1]`}
                          label={t('PRODUCT_EDITOR.PRODUCT_PROPERTY')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        >
                          {productProperties.map((productProperty) => (
                            <MenuItem
                              key={productProperty.id}
                              value={productProperty.id}
                            >
                              {productProperty.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={4}>
                        {productProperties.some(
                          (productProperty) =>
                            productProperty.id === conditions[1],
                        )
                          ? generateField(
                              productProperties.find(
                                (productProperty) =>
                                  productProperty.id === conditions[1],
                              ),
                              `${formikPrefix}${formikNameSuffix}[2]`,
                              _.get(
                                values,
                                `${formikPrefix}${formikNameSuffix}[2]`,
                              ),
                            )
                          : null}
                      </Grid>
                    </Grid>
                  )}
                </Fragment>
              )
            }
            function generateTriggerFields(
              trigger,
              values,
              hierarchyLevel,
              formikNameSuffix = '',
              index,
            ) {
              let operator = trigger[0]

              return (
                <Fragment>
                  {hierarchyLevel === 1 && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Button
                          size="medium"
                          color={'secondary'}
                          variant={'contained'}
                          startIcon={<Delete />}
                          sx={{ margin: 1 }}
                          onClick={() => {
                            let newTriggers = [...values.triggers]
                            const targetIndex = newTriggers.indexOf(trigger)
                            if (targetIndex > -1) {
                              newTriggers.splice(targetIndex, 1)
                            }
                            setFieldValue(`triggers`, newTriggers)
                          }}
                        >
                          {t('PRODUCT_EDITOR.REMOVE_TRIGGER')}
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Field
                          component={TextField}
                          select
                          name={`triggers[${index}]${formikNameSuffix}[3]`}
                          label={t('PRODUCT_EDITOR.PRODUCT_PROPERTY')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          onChange={(e) => {
                            setFieldValue(
                              `triggers[${index}]${formikNameSuffix}[3]`,
                              e.target.value,
                            )

                            let targetProductProperty = productProperties.find(
                              (productProperty) =>
                                productProperty.id === e.target.value,
                            )

                            if (targetProductProperty.type === 'BOOLEAN') {
                              setFieldValue(
                                `triggers[${index}]${formikNameSuffix}[4]`,
                                false,
                              )
                            }
                          }}
                        >
                          {productProperties.map((productProperty) => (
                            <MenuItem
                              key={productProperty.id}
                              value={productProperty.id}
                            >
                              {productProperty.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={3}>
                        {productProperties.some(
                          (productProperty) =>
                            productProperty.id === trigger[3],
                        )
                          ? generateField(
                              productProperties.find(
                                (productProperty) =>
                                  productProperty.id === trigger[3],
                              ),
                              `triggers[${index}]${formikNameSuffix}[4]`,
                              _.get(
                                values,
                                `triggers[${index}]${formikNameSuffix}[4]`,
                              ),
                            )
                          : null}
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={3} justifyContent={'flex-start'}>
                    <Grid item xs={4}>
                      <Field
                        component={TextField}
                        select
                        name={`triggers[${index}]${formikNameSuffix}[0]`}
                        label={t('PRODUCT_EDITOR.OPERATOR')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        onChange={(e) => {
                          setFieldValue(
                            `triggers[${index}]${formikNameSuffix}[0]`,
                            e.target.value,
                          )
                          if (
                            e.target.value === 'AND' ||
                            e.target.value === 'OR'
                          ) {
                            setFieldValue(
                              `triggers[${index}]${formikNameSuffix}[1]`,
                              ['EQ', null, null],
                            )
                            setFieldValue(
                              `triggers[${index}]${formikNameSuffix}[2]`,
                              ['EQ', null, null],
                            )
                          } else {
                            setFieldValue(
                              `triggers[${index}]${formikNameSuffix}[1]`,
                              null,
                            )
                            setFieldValue(
                              `triggers[${index}]${formikNameSuffix}[2]`,
                              null,
                            )
                          }
                        }}
                      >
                        <MenuItem key={'AND'} value={'AND'}>
                          {'AND'}
                        </MenuItem>
                        <MenuItem key={'OR'} value={'OR'}>
                          {'OR'}
                        </MenuItem>
                        <MenuItem key={'EQ'} value={'EQ'}>
                          {'EQ'}
                        </MenuItem>
                        <MenuItem key={'NE'} value={'NE'}>
                          {'NE'}
                        </MenuItem>
                      </Field>
                    </Grid>
                    {(operator === 'EQ' || operator === 'NE') && (
                      <Grid item xs={4}>
                        {productProperties.some(
                          (productProperty) =>
                            productProperty.id === selectedItem.id,
                        )
                          ? generateField(
                              productProperties.find(
                                (productProperty) =>
                                  productProperty.id === selectedItem.id,
                              ),
                              `triggers[${index}]${formikNameSuffix}[1]`,
                              _.get(
                                values,
                                `triggers[${index}]${formikNameSuffix}[1]`,
                              ),
                            )
                          : null}
                      </Grid>
                    )}
                  </Grid>
                  {(operator === 'AND' || operator === 'OR') && (
                    <Box marginLeft={10}>
                      {generateTriggerFields(
                        trigger[1],
                        values,
                        hierarchyLevel + 1,
                        `${formikNameSuffix}[1]`,
                        index,
                      )}
                      {generateTriggerFields(
                        trigger[2],
                        values,
                        hierarchyLevel + 1,
                        `${formikNameSuffix}[2]`,
                        index,
                      )}
                    </Box>
                  )}
                </Fragment>
              )
            }
            return (
              <Dialog fullWidth={true} maxWidth="md" open={showCreateDialog}>
                <CustomDialogTitle
                  title={t('PRODUCT_EDITOR.CREATE_PRODUCT_PROPERTY')}
                  onClose={() => {
                    setSelectedItem(null)
                    setShowCreateDialog(false)
                  }}
                />
                <DialogContent>
                  <Form>
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PRODUCT_EDITOR.PRODUCT_PROPERTY_CATEGORY_NAME')}
                      variant="outlined"
                      name="name"
                    />
                    <Field
                      component={TextField}
                      select
                      name="type"
                      label={t('PRODUCT_EDITOR.TYPE')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                      onChange={(e) => {
                        if (
                          e.target.value === 'ARRAY' ||
                          e.target.value === '2D_ARRAY'
                        ) {
                          setFieldValue(
                            'productPropertyArray',
                            values.productPropertyArray
                              ? values.productPropertyArray
                              : {
                                  id: undefined,
                                  length: 1,
                                  productPropertyArrayElements: [
                                    {
                                      id: undefined,
                                      productPropertyId: null,
                                      renderPosition: 0,
                                    },
                                  ],
                                },
                          )
                        }

                        if (e.target.value === 'ENUM') {
                          setFieldValue(
                            'productPropertyEnumValues',
                            values.productPropertyEnumValues
                              ? values.productPropertyEnumValues
                              : [
                                  {
                                    id: undefined,
                                    value: '',
                                    renderPosition: 0,
                                    productPropertyEnumValueLocalizations: [
                                      { language: 'de', value: '' },
                                      { language: 'en', value: '' },
                                      { language: 'pl', value: '' },
                                      { language: 'cs', value: '' },
                                      { language: 'bg', value: '' },
                                      { language: 'da', value: '' },
                                      { language: 'et', value: '' },
                                      { language: 'fi', value: '' },
                                      { language: 'fr', value: '' },
                                      { language: 'el', value: '' },
                                      { language: 'it', value: '' },
                                      { language: 'lt', value: '' },
                                      { language: 'lv', value: '' },
                                      { language: 'nl', value: '' },
                                      { language: 'pt-PT', value: '' },
                                      { language: 'ro', value: '' },
                                      { language: 'sv', value: '' },
                                      { language: 'sk', value: '' },
                                      { language: 'sl', value: '' },
                                      { language: 'es', value: '' },
                                      { language: 'uk', value: '' },
                                      { language: 'hu', value: '' },
                                    ],
                                  },
                                ],
                          )

                          setFieldValue('variant', 'TOGGLE_BUTTON_GROUP')
                        }

                        setFieldValue('type', e.target.value)
                      }}
                    >
                      <MenuItem key={'BOOLEAN'} value={'BOOLEAN'}>
                        {'Boolean'}
                      </MenuItem>
                      <MenuItem key={'ENUM'} value={'ENUM'}>
                        {'Enum'}
                      </MenuItem>
                      <MenuItem key={'INTEGER'} value={'INTEGER'}>
                        {'Integer'}
                      </MenuItem>
                      <MenuItem key={'FLOAT'} value={'FLOAT'}>
                        {'Float'}
                      </MenuItem>
                      <MenuItem key={'TEXT'} value={'TEXT'}>
                        {'Text'}
                      </MenuItem>
                      <MenuItem key={'ARRAY'} value={'ARRAY'}>
                        {'Array'}
                      </MenuItem>
                      <MenuItem key={'2D_ARRAY'} value={'2D_ARRAY'}>
                        {'2D-Array'}
                      </MenuItem>
                    </Field>
                    {(values.type === 'ARRAY' ||
                      values.type === '2D_ARRAY') && (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                          <Button
                            size="medium"
                            color={'secondary'}
                            variant={'contained'}
                            startIcon={<Add />}
                            onClick={() => {
                              setFieldValue('productPropertyArray', {
                                length:
                                  values.productPropertyArray
                                    .productPropertyArrayElements.length + 1,
                                productPropertyArrayElements:
                                  values.productPropertyArray.productPropertyArrayElements.concat(
                                    {
                                      id: undefined,
                                      productPropertyId: null,
                                      renderPosition: 0,
                                    },
                                  ),
                              })
                            }}
                          >
                            {t('PRODUCT_EDITOR.ADD_ARRAY_VALUE')}
                          </Button>
                        </Grid>
                        {values.productPropertyArray &&
                          values.productPropertyArray.productPropertyArrayElements.map(
                            (enumValue, index) => (
                              <Fragment>
                                <Grid item xs={5}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                      <Field
                                        component={TextField}
                                        select
                                        name={`productPropertyArray.productPropertyArrayElements[${index}].productPropertyId`}
                                        label={t(
                                          'PRODUCT_EDITOR.PRODUCT_PROPERTY',
                                        )}
                                        variant="outlined"
                                        fullWidth={true}
                                        margin="dense"
                                      >
                                        {productProperties.map(
                                          (productProperty) => (
                                            <MenuItem
                                              key={productProperty.id}
                                              value={productProperty.id}
                                            >
                                              {productProperty.name}
                                            </MenuItem>
                                          ),
                                        )}
                                      </Field>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Field
                                        component={TextField}
                                        fullWidth
                                        margin="dense"
                                        label={t(
                                          'PRODUCT_EDITOR.RENDER_POSITION',
                                        )}
                                        variant="outlined"
                                        name={`productPropertyArray.productPropertyArrayElements[${index}].renderPosition`}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={1}>
                                  <IconButton
                                    onClick={() =>
                                      setFieldValue(
                                        'productPropertyArray.productPropertyArrayElements',
                                        values.productPropertyArray.productPropertyArrayElements
                                          .filter(
                                            (value, index2) => index !== index2,
                                          )
                                          .map((value) => {
                                            return {
                                              ...value,
                                            }
                                          }),
                                      )
                                    }
                                  >
                                    <Delete />
                                  </IconButton>
                                </Grid>
                              </Fragment>
                            ),
                          )}
                      </Grid>
                    )}
                    {values.type === 'ENUM' && (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={12}>
                          <Button
                            size="medium"
                            color={'secondary'}
                            variant={'contained'}
                            startIcon={<Add />}
                            onClick={() => {
                              setFieldValue(
                                'productPropertyEnumValues',
                                values.productPropertyEnumValues.concat({
                                  id: undefined,
                                  value: '',
                                  renderPosition: 0,
                                  productPropertyEnumValueLocalizations: [
                                    { language: 'de', value: '' },
                                    { language: 'en', value: '' },
                                    { language: 'pl', value: '' },
                                    { language: 'cs', value: '' },
                                    { language: 'bg', value: '' },
                                    { language: 'da', value: '' },
                                    { language: 'et', value: '' },
                                    { language: 'fi', value: '' },
                                    { language: 'fr', value: '' },
                                    { language: 'el', value: '' },
                                    { language: 'it', value: '' },
                                    { language: 'lt', value: '' },
                                    { language: 'lv', value: '' },
                                    { language: 'nl', value: '' },
                                    { language: 'pt-PT', value: '' },
                                    { language: 'ro', value: '' },
                                    { language: 'sv', value: '' },
                                    { language: 'sk', value: '' },
                                    { language: 'sl', value: '' },
                                    { language: 'es', value: '' },
                                    { language: 'uk', value: '' },
                                    { language: 'hu', value: '' },
                                  ],
                                }),
                              )
                            }}
                          >
                            {t('PRODUCT_EDITOR.ADD_ENUM_VALUE')}
                          </Button>
                        </Grid>
                        {values.productPropertyEnumValues.map(
                          (enumValue, index) => (
                            <Fragment>
                              <Grid item xs={3}>
                                <Grid container spacing={1}>
                                  <Grid item xs={8}>
                                    <Field
                                      component={TextField}
                                      fullWidth
                                      margin="dense"
                                      label={t('PRODUCT_EDITOR.ENUM_VALUE')}
                                      variant="outlined"
                                      name={`productPropertyEnumValues[${index}].value`}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Field
                                      component={TextField}
                                      fullWidth
                                      margin="dense"
                                      label={t(
                                        'PRODUCT_EDITOR.RENDER_POSITION',
                                      )}
                                      variant="outlined"
                                      name={`productPropertyEnumValues[${index}].renderPosition`}
                                    />
                                  </Grid>
                                </Grid>
                                <Divider />
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <Typography>
                                      {t('PRODUCT_EDITOR.TRANSLATIONS')}
                                    </Typography>
                                  </Grid>
                                  {enumValue.productPropertyEnumValueLocalizations.map(
                                    (localizations, indexLocalization) => (
                                      <Grid item xs={12}>
                                        <Field
                                          component={TextField}
                                          fullWidth
                                          margin="dense"
                                          label={localizations.language}
                                          variant="outlined"
                                          name={`productPropertyEnumValues[${index}].productPropertyEnumValueLocalizations[${indexLocalization}].value`}
                                        />
                                      </Grid>
                                    ),
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  onClick={() =>
                                    setFieldValue(
                                      'productPropertyEnumValues',
                                      values.productPropertyEnumValues.filter(
                                        (value, index2) => index !== index2,
                                      ),
                                    )
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </Grid>
                            </Fragment>
                          ),
                        )}
                      </Grid>
                    )}
                    {values.type === 'ENUM' && (
                      <Field
                        component={TextField}
                        select
                        name="variant"
                        label={t('PRODUCT_EDITOR.VARIANT')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      >
                        <MenuItem
                          key={'TOGGLE_BUTTON_GROUP'}
                          value={'TOGGLE_BUTTON_GROUP'}
                        >
                          {'TOGGLE_BUTTON_GROUP'}
                        </MenuItem>
                        <MenuItem key={'SELECT'} value={'SELECT'}>
                          {'SELECT'}
                        </MenuItem>
                      </Field>
                    )}
                    <Field
                      component={TextField}
                      select
                      name="primaryRenderVariant"
                      label={t('PRODUCT_EDITOR.PRIMARY_VARIANT')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    >
                      <MenuItem key={'KEY/VALUE'} value={'KEY/VALUE'}>
                        {'KEY/VALUE'}
                      </MenuItem>
                      <MenuItem key={'VALUE'} value={'VALUE'}>
                        {'VALUE'}
                      </MenuItem>
                    </Field>
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PRODUCT_EDITOR.WIDTH')}
                      variant="outlined"
                      name="width"
                      inputProps={{
                        decimalScale: 0,
                        allowNegative: false,
                      }}
                    />
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PRODUCT_EDITOR.RENDER_POSITION')}
                      variant="outlined"
                      name="renderPosition"
                      inputProps={{
                        decimalScale: 0,
                        allowNegative: false,
                      }}
                    />
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PRODUCT_EDITOR.UNIT')}
                      variant="outlined"
                      name="unit"
                    />
                    <Field
                      component={TextField}
                      select
                      name="productPropertyCategoryId"
                      label={t('PRODUCT_EDITOR.PRODUCT_PROPERTY_CATEGORY')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    >
                      {productPropertyCategories.map(
                        (productPropertyCategory) => (
                          <MenuItem
                            key={productPropertyCategory.id}
                            value={productPropertyCategory.id}
                          >
                            {productPropertyCategory.name}
                          </MenuItem>
                        ),
                      )}
                    </Field>
                    <Field
                      component={CheckboxWithLabel}
                      type={'checkbox'}
                      checked={values.isPrimary}
                      name={'isPrimary'}
                      margin="dense"
                      Label={{
                        label: t('PRODUCT_EDITOR.PRIMARY'),
                      }}
                    />
                    <Field
                      component={CheckboxWithLabel}
                      type={'checkbox'}
                      checked={values.isRequired}
                      name={'isRequired'}
                      margin="dense"
                      Label={{
                        label: t('PRODUCT_EDITOR.REQUIRED'),
                      }}
                    />
                    <Field
                      component={CheckboxWithLabel}
                      type={'checkbox'}
                      checked={values.isHighlight}
                      name={'isHighlight'}
                      margin="dense"
                      Label={{
                        label: t('PRODUCT_EDITOR.HIGHLIGHT'),
                      }}
                    />
                    <Divider />

                    {selectedItem && (
                      <Fragment>
                        <Section header={t('PRODUCT_EDITOR.RENDER_CONDITIONS')}>
                          {!values.conditions ? (
                            <Button
                              size="medium"
                              color={'secondary'}
                              variant={'contained'}
                              startIcon={<Add />}
                              sx={{ margin: 1 }}
                              onClick={() => {
                                setFieldValue('conditions', ['EQ', null, null])
                              }}
                            >
                              {t('PRODUCT_EDITOR.ADD_CONDITION')}
                            </Button>
                          ) : (
                            <Fragment>
                              <Button
                                size="medium"
                                color={'secondary'}
                                variant={'contained'}
                                startIcon={<Delete />}
                                sx={{ margin: 1 }}
                                onClick={() => {
                                  setFieldValue('conditions', null)
                                }}
                              >
                                {t('PRODUCT_EDITOR.REMOVE_CONDITION')}
                              </Button>
                              {generateConditionFields(
                                values.conditions,
                                values,
                                1,
                                '',
                                'conditions',
                              )}
                            </Fragment>
                          )}
                        </Section>

                        <Section header={t('PRODUCT_EDITOR.ENABLE_CONDITIONS')}>
                          {!values.disableConditions ? (
                            <Button
                              size="medium"
                              color={'secondary'}
                              variant={'contained'}
                              startIcon={<Add />}
                              sx={{ margin: 1 }}
                              onClick={() => {
                                setFieldValue('disableConditions', [
                                  'EQ',
                                  null,
                                  null,
                                ])
                              }}
                            >
                              {t('PRODUCT_EDITOR.ADD_CONDITION')}
                            </Button>
                          ) : (
                            <Fragment>
                              <Button
                                size="medium"
                                color={'secondary'}
                                variant={'contained'}
                                startIcon={<Delete />}
                                sx={{ margin: 1 }}
                                onClick={() => {
                                  setFieldValue('disableConditions', null)
                                }}
                              >
                                {t('PRODUCT_EDITOR.REMOVE_CONDITION')}
                              </Button>
                              {generateConditionFields(
                                values.disableConditions,
                                values,
                                1,
                                '',
                                'disableConditions',
                              )}
                            </Fragment>
                          )}
                        </Section>

                        <Section header={t('PRODUCT_EDITOR.TRIGGERS')}>
                          <Button
                            size="medium"
                            color={'secondary'}
                            variant={'contained'}
                            startIcon={<Add />}
                            sx={{ margin: 1 }}
                            onClick={() => {
                              let newTriggers = [...values.triggers]

                              newTriggers.push(['EQ', null, null])

                              setFieldValue('triggers', newTriggers)
                            }}
                          >
                            {t('PRODUCT_EDITOR.ADD_TRIGGER')}
                          </Button>

                          {values.triggers.map((trigger, index) => {
                            return generateTriggerFields(
                              trigger,
                              values,
                              1,
                              '',
                              index,
                            )
                          })}
                        </Section>
                      </Fragment>
                    )}

                    <Section header={t('PRODUCT_EDITOR.TRANSLATIONS')}>
                      <Grid container spacing={1}>
                        {values.productPropertyLocalizations.map(
                          (localizations, index) => (
                            <Grid item xs={3}>
                              <Field
                                component={TextField}
                                fullWidth
                                margin="dense"
                                label={localizations.language}
                                variant="outlined"
                                name={`productPropertyLocalizations[${index}].value`}
                              />
                            </Grid>
                          ),
                        )}
                      </Grid>
                    </Section>
                  </Form>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setSelectedItem(null)
                      setShowCreateDialog(false)
                    }}
                    variant="outlined"
                    color={'secondary'}
                  >
                    {t('GENERAL.CANCEL')}
                  </Button>
                  <Button
                    onClick={() => submitForm()}
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    {t('GENERAL.CONFIRM')}
                  </Button>
                </DialogActions>
              </Dialog>
            )
          }}
        </Formik>
      </Fragment>
    )
  )
}

export default withTranslation()(ProductPropertyEditor)
