import MaterialTable from '@material-table/core'
import { Dialog, DialogContent } from '@mui/material'
import React, { Fragment, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryLookup,
  tenderOrderRequestStateLookup,
} from '../../../../../../_constants/lookupConstants'
import {
  tableIcons,
  tableLocalization,
} from '../../../../../../_helpers/muiTable'
import CustomDialogTitle from '../../../../../CustomDialogTitle'
import TenderOrderRequestHistory from './_components/TenderOrderRequestHistory'

const TenderOrderRequestsDialog = (props) => {
  const { t, tenderOrder, hide, show, removeTenderOrderRequest } = props

  const [columns] = React.useState([
    {
      title: t('TENDER_ORDERS.TENDER_ORDER_REQUESTS.REQUEST_ID'),
      field: 'id',
    },
    {
      title: t('TENDER_ORDERS.TENDER_ORDER_REQUESTS.ORDER_NUMBER'),
      field: 'orderNumber',
    },
    {
      title: t('TENDER_ORDERS.TENDER_ORDER_REQUESTS.STATE'),
      field: 'descendents[0].state',
      lookup: tenderOrderRequestStateLookup,
    },
    {
      title: t('TENDER_ORDERS.TENDER_ORDER_REQUESTS.COMPANY'),
      field: 'location.company',
    },
    {
      title: t('TENDER_ORDERS.TENDER_ORDER_REQUESTS.ADDRESS'),
      field: 'location.address',
    },
    {
      title: t('TENDER_ORDERS.TENDER_ORDER_REQUESTS.ZIPCODE'),
      field: 'location.zipcode',
    },
    {
      title: t('TENDER_ORDERS.TENDER_ORDER_REQUESTS.PLACE'),
      field: 'location.place',
    },
    {
      title: t('TENDER_ORDERS.TENDER_ORDER_REQUESTS.COUNTRY'),
      field: 'location.countryId',
      lookup: countryLookup,
    },
  ])

  useEffect(() => {}, [])

  return (
    <Dialog onClose={() => hide()} maxWidth="xl" open={show}>
      <CustomDialogTitle
        title={t('TENDER_ORDERS.TENDER_ORDER_REQUESTS_DIALOG_TITLE')}
        onClose={() => hide()}
      />
      <DialogContent>
        <Fragment>
          <MaterialTable
            icons={tableIcons}
            localization={tableLocalization()}
            columns={columns}
            data={tenderOrder.tenderOrderRequests}
            style={{
              boxShadow: 'none',
              //marginLeft: "60px"
            }}
            options={{
              toolbarButtonAlignment: 'left',
              showTitle: false,
              search: false,
              paging: true,
              filtering: false,
              pageSize: 10,
              emptyRowsWhenPaging: false,
              padding: 'dense',
              actionsColumnIndex: -1,
              filterCellStyle: {
                maxWidth: 0,
              },
              headerStyle: {
                fontWeight: 'bold',
              },
            }}
            detailPanel={(data) => {
              return (
                <TenderOrderRequestHistory
                  removeTenderOrderRequest={removeTenderOrderRequest}
                  tenderOrder={tenderOrder}
                  tenderOrderRequest={data.rowData}
                />
              )
            }}
          />
        </Fragment>
      </DialogContent>
    </Dialog>
  )
}

export default withTranslation()(TenderOrderRequestsDialog)
