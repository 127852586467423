import { InlineIcon } from '@iconify/react'
import MaterialTable from '@material-table/core'
import {
  AddBox,
  LockPerson,
  Payment,
  Public,
  ShoppingCart,
} from '@mui/icons-material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField as MUITextField,
  Paper,
  Slider,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryLookup,
  salutationLookup,
} from '../../../../_constants/lookupConstants'
import { tableIcons, tableLocalization } from '../../../../_helpers/muiTable'
import { partnersService } from '../../../../_services/partnersService'
import AgreementDialog from '../../../AgreementDialog'
import { Loading } from '../../../Loading'
import RegisterPartnerDialog from '../../../RegisterPartnerDialog'
import PartnerBadges from './_components/PartnerBadges'
import PartnerProductsAllowed from './_components/PartnerProductsAllowed'
import PartnerRestrictions from './_components/PartnerRestrictions'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return value === index ? (
    <Typography
      component="div"
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  ) : null
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const AdminUserOverview = (props) => {
  const { t } = props

  const [partners, setPartners] = React.useState([])
  const [selectedPartnerId, setSelectedPartnerId] = React.useState(null)
  const [stats, setStats] = React.useState({})
  const [registerPartnerOpen, setRegisterPartnerOpen] = React.useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedPartnerInputValue, setSelectedPartnerInputValue] = useState('')

  const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 10,
      label: '10',
    },
  ]
  const handlePaymentChange = async (e) => {
    try {
      setIsLoading(true)
      const newData = {
        ...stats,
        allPaymentTermsAllowed: e.target.checked,
      }
      const updateData = {
        allPaymentTermsAllowed: e.target.checked,
        defaultPaymentTerms: !e.target.checked ? 0 : 14,
        defaultSkonto: 0,
        defaultSkontoPeriod: null,
      }
      await partnersService.updatePartner(selectedPartnerId, updateData)
      setStats(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const handlePublicAccessChange = async () => {
    try {
      setIsLoading(true)
      const newData = {
        ...stats,
        hasPublicAccess: !stats.hasPublicAccess,
      }
      await partnersService.updatePartner(selectedPartnerId, {
        hasPublicAccess: newData.hasPublicAccess,
      })
      setStats(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const handlePublicAccessToMarketplaceChange = async () => {
    try {
      setIsLoading(true)
      const newData = {
        ...stats,
        hasPublicAccessToMarketplace: !stats.hasPublicAccessToMarketplace,
      }
      await partnersService.updatePartner(selectedPartnerId, {
        hasPublicAccessToMarketplace: newData.hasPublicAccessToMarketplace,
      })
      setStats(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleAccessToSpotChange = async () => {
    try {
      setIsLoading(true)
      const newData = {
        ...stats,
        hasAccessToSpotMarketplace: !stats.hasAccessToSpotMarketplace,
      }
      await partnersService.updatePartner(selectedPartnerId, {
        hasAccessToSpotMarketplace: newData.hasAccessToSpotMarketplace,
      })
      setStats(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleAccessToIndexChange = async () => {
    try {
      setIsLoading(true)
      const newData = {
        ...stats,
        hasAccessToIndex: !stats.hasAccessToIndex,
      }
      await partnersService.updatePartner(selectedPartnerId, {
        hasAccessToIndex: newData.hasAccessToIndex,
      })
      setStats(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleActiveChange = async () => {
    try {
      setIsLoading(true)
      const newData = {
        ...stats,
        active: !stats.active,
      }
      await partnersService.activatePartner(selectedPartnerId, {
        active: newData.active,
      })
      setStats(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleBuyNowAllowedChange = async () => {
    try {
      setIsLoading(true)
      const newData = {
        ...stats,
        hasAccessToBuyNow: !stats.hasAccessToBuyNow,
      }
      await partnersService.updatePartner(selectedPartnerId, {
        hasAccessToBuyNow: newData.hasAccessToBuyNow,
      })
      setStats(newData)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    partnersService.getPartners().then((partners) => {
      partners = _.orderBy(partners, ['id'], ['desc'])
      setPartners(partners)
    })
  }, [registerPartnerOpen])

  useEffect(() => {
    const updatePartner = async () => {
      if (!selectedPartnerId) {
        return
      }
      setIsLoading(true)
      try {
        await partnersService.updatePartner(selectedPartnerId, {
          sellQualityLevel: stats.sellQualityLevel,
          buyQualityLevel: stats.buyQualityLevel,
        })
      } finally {
        setIsLoading(false)
      }
    }
    updatePartner()
  }, [stats.sellQualityLevel, stats.buyQualityLevel])
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    partners.length > 0 && (
      <Fragment>
        {isLoading ? (
          <Loading variant={'centered'} />
        ) : (
          <Grid container spacing={1}>
            <Grid item>
              <Autocomplete
                value={selectedPartnerId}
                onChange={(e, newValue) => {
                  setSelectedPartnerId(newValue)
                  if (newValue) {
                    partnersService.getPartner(newValue).then((partner) => {
                      setStats(partner)
                    })
                  }
                }}
                inputValue={selectedPartnerInputValue}
                onInputChange={(event, newInputValue) => {
                  setSelectedPartnerInputValue(newInputValue)
                }}
                size={'small'}
                disablePortal
                options={partners.map((customer) => customer.id)}
                getOptionLabel={(option) => {
                  if (!option) {
                    return ''
                  }

                  let partner = partners.find(
                    (customer) => customer.id === option,
                  )

                  return `${
                    !partner.active ? `[${t('ADMINPANEL.INACTIVE')}] ` : ''
                  }${partner.name} (${partner.id}) - ${partner.place}`
                }}
                // filterOptions={(options, { inputValue }) => {
                //   let inputLength = inputValue.length
                //   return options.filter((option) => {
                //     let partner = partners.find(
                //       (customer) => customer.id === option,
                //     )

                //     let partnerIdAsString = partner.id.toString()
                //     let matchesId = partnerIdAsString === inputValue
                //     let matchesName =
                //       inputLength >= 3 &&
                //       partner.name
                //         .toLowerCase()
                //         .startsWith(inputValue.toLowerCase())

                //     return true || matchesId || matchesName
                //   })
                // }}
                sx={{ width: 600 }}
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    label={t('GENERAL.SELECTED_PARTNER')}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <IconButton
                onClick={(e) => {
                  setRegisterPartnerOpen(true)
                }}
                size="large"
              >
                <AddBox />
              </IconButton>
            </Grid>
          </Grid>
        )}

        <RegisterPartnerDialog
          open={registerPartnerOpen}
          close={() => setRegisterPartnerOpen(false)}
        />
        <Divider />
        {selectedPartnerId ? (
          <Fragment>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label={t('ADMINPANEL.BASE_DATA')} {...a11yProps(0)} />
              <Tab label={t('ADMINPANEL.PARTNER_PRODUCTS')} {...a11yProps(1)} />
              <Tab
                label={t('ADMINPANEL.PARTNER_RESTRICTIONS')}
                {...a11yProps(2)}
              />
              <Tab label={t('ADMINPANEL.PARTNER_BADGES')} {...a11yProps(3)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              {stats ? (
                <div>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <h6 className="header">{t('ADMINPANEL.COMPANY_INFO')}</h6>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                          <div>
                            <strong>{t('ADMINPANEL.ADDRESS')}</strong>
                          </div>
                          <div>{stats.address}</div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div>
                            <strong>{t('ADMINPANEL.ZIPCODE')}</strong>
                          </div>
                          <div>{stats.zipcode}</div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div>
                            <strong>{t('ADMINPANEL.PLACE')}</strong>
                          </div>
                          <div>{stats.place}</div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div>
                            <strong>{t('ADMINPANEL.COUNTRY')}</strong>
                          </div>
                          <div>{countryLookup[stats.countryId]}</div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div>
                            <strong>{t('ADMINPANEL.TAX_ID')}</strong>
                          </div>
                          <div>{stats.taxId}</div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div>
                            <strong>{t('ADMINPANEL.TYPE')}</strong>
                          </div>
                          <div>{stats.type}</div>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <div>
                            <strong>{t('ADMINPANEL.FOUNDED')}</strong>
                          </div>
                          <div>{stats.founded}</div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div>
                            <strong>{t('ADMINPANEL.SIZE')}</strong>
                          </div>
                          <div>{stats.size}</div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div>
                            <strong>{t('ADMINPANEL.CAPACITY')}</strong>
                          </div>
                          <div>{stats.capacity}</div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <h6 className="header">{t('ADMINPANEL.ACTIONS')}</h6>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Paper
                            style={{
                              padding: '8px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="body1">Off</Typography>
                            <Switch
                              checked={stats.active}
                              variant={stats.active ? 'outlined' : 'contained'}
                              color="secondary"
                              onChange={handleActiveChange}
                            />
                            <Typography variant="body1">On</Typography>
                            <div
                              style={{
                                flexGrow: 1,
                                textAlign: 'center',
                                margin: '0 8px',
                              }}
                            >
                              <Typography variant="body1">
                                {stats.active
                                  ? t('ADMINPANEL.ACTIVATE')
                                  : t('ADMINPANEL.BAN')}
                              </Typography>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                              <LockPerson color="primary" />
                            </div>
                          </Paper>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Paper
                            style={{
                              padding: '8px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="body1">Off</Typography>
                            <Switch
                              checked={stats.hasAccessToSpotMarketplace}
                              variant={
                                stats.hasAccessToSpotMarketplace
                                  ? 'outlined'
                                  : 'contained'
                              }
                              color="secondary"
                              onChange={handleAccessToSpotChange}
                            />
                            <Typography variant="body1">On</Typography>
                            <div
                              style={{
                                flexGrow: 1,
                                textAlign: 'center',
                                margin: '0 8px',
                              }}
                            >
                              <Typography variant="body1">
                                {stats.hasAccessToSpotMarketplace
                                  ? t('ADMINPANEL.SPOT_ALLOWED')
                                  : t('ADMINPANEL.SPOT_NOT_ALLOWED')}
                              </Typography>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                              <LockPerson color="primary" />
                            </div>
                          </Paper>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Paper
                            style={{
                              padding: '8px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="body1">Off</Typography>
                            <Switch
                              checked={stats.hasAccessToIndex}
                              variant={
                                stats.hasAccessToIndex
                                  ? 'outlined'
                                  : 'contained'
                              }
                              color="secondary"
                              onChange={handleAccessToIndexChange}
                            />
                            <Typography variant="body1">On</Typography>
                            <div
                              style={{
                                flexGrow: 1,
                                textAlign: 'center',
                                margin: '0 8px',
                              }}
                            >
                              <Typography variant="body1">
                                {stats.hasAccessToIndex
                                  ? t('ADMINPANEL.INDEX_ACCESS')
                                  : t('ADMINPANEL.NO_INDEX_ACCESS')}
                              </Typography>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                              <LockPerson color="primary" />
                            </div>
                          </Paper>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Paper
                            style={{
                              padding: '8px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="body1">Off</Typography>
                            <Switch
                              checked={stats.allPaymentTermsAllowed}
                              variant={
                                stats.allPaymentTermsAllowed
                                  ? 'outlined'
                                  : 'contained'
                              }
                              color="secondary"
                              onChange={handlePaymentChange}
                            />

                            <Typography variant="body1">On</Typography>
                            <div
                              style={{
                                flexGrow: 1,
                                textAlign: 'center',
                                margin: '0 8px',
                              }}
                            >
                              <Typography variant="body1">
                                {stats.allPaymentTermsAllowed
                                  ? t('ADMINPANEL.ALLOW_ALL_PAYMENT_TERMS')
                                  : t('ADMINPANEL.ONLY_IN_ADVANCE')}
                              </Typography>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                              <Payment color="primary" />
                            </div>
                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Paper
                            style={{
                              padding: '8px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Typography variant="body1">Off</Typography>
                            <Switch
                              checked={stats.hasPublicAccess}
                              variant={
                                stats.hasPublicAccess ? 'outlined' : 'contained'
                              }
                              color="secondary"
                              onChange={handlePublicAccessChange}
                            />
                            <Typography variant="body1">On</Typography>
                            <div
                              style={{
                                flexGrow: 1,
                                textAlign: 'center',
                                margin: '0 8px',
                              }}
                            >
                              <Typography variant="body1">
                                {stats.hasPublicAccess
                                  ? t('ADMINPANEL.ENABLE_PUBLIC_ACCESS')
                                  : t('ADMINPANEL.DISABLE_PUBLIC_ACCESS')}
                              </Typography>
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                              <Public color="primary" />
                            </div>
                          </Paper>
                        </Grid>
                        {stats.type === 'manufacturer' ? (
                          <Grid item xs={12} sm={6}>
                            <Paper
                              style={{
                                padding: '8px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="body1">Off</Typography>
                              <Switch
                                checked={stats.hasPublicAccessToMarketplace}
                                variant={
                                  stats.hasPublicAccessToMarketplace
                                    ? 'outlined'
                                    : 'contained'
                                }
                                color="secondary"
                                onChange={handlePublicAccessToMarketplaceChange}
                              />
                              <Typography variant="body1">On</Typography>
                              <div
                                style={{
                                  flexGrow: 1,
                                  textAlign: 'center',
                                  margin: '0 8px',
                                }}
                              >
                                <Typography variant="body1">
                                  {stats.hasPublicAccessToMarketplace
                                    ? t(
                                        'ADMINPANEL.ENABLE_PUBLIC_ACCESS_MARKETPLACE',
                                      )
                                    : t(
                                        'ADMINPANEL.DISABLE_PUBLIC_ACCESS_MARKETPLACE',
                                      )}
                                </Typography>
                              </div>
                              <div style={{ marginLeft: 'auto' }}>
                                <ShoppingCart color="primary" />
                              </div>
                            </Paper>
                          </Grid>
                        ) : null}
                        {
                          <Grid item xs={12} sm={6}>
                            <Paper
                              style={{
                                padding: '8px',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="body1">Off</Typography>
                              <Switch
                                checked={stats.hasAccessToBuyNow}
                                variant={
                                  stats.hasAccessToBuyNow
                                    ? 'outlined'
                                    : 'contained'
                                }
                                color="secondary"
                                onChange={(e) =>
                                  handleBuyNowAllowedChange(
                                    stats.hasAccessToBuyNow,
                                    e.target.checked,
                                  )
                                }
                              />
                              <Typography variant="body1">On</Typography>
                              <div
                                style={{
                                  flexGrow: 1,
                                  textAlign: 'center',
                                  margin: '0 8px',
                                }}
                              >
                                <Typography variant="body1">
                                  {stats.hasAccessToBuyNow
                                    ? t('ADMINPANEL.ENABLE_BUYNOW_ALLOWED')
                                    : t('ADMINPANEL.DISABLE_BUYNOW_ALLOWED')}
                                </Typography>
                              </div>
                              <div style={{ marginLeft: 'auto' }}>
                                <InlineIcon
                                  style={{ width: '20px', height: '20px' }}
                                  icon={ShoppingCartIcon}
                                  color={'#32CD32'}
                                />
                              </div>
                            </Paper>
                          </Grid>
                        }
                      </Grid>

                      {stats.buyQualityLevel && (
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: '10px', marginBottom: '10px' }}
                        >
                          <Grid item xs={12} sm={6}>
                            <Typography gutterBottom>
                              {t('ADMINPANEL.BUY_QUALITY_LEVEL')}
                            </Typography>
                            <Slider
                              color="secondary"
                              getAriaLabel={() => 'Minimum distance'}
                              step={1}
                              min={1}
                              max={10}
                              marks={marks}
                              value={stats.buyQualityLevel}
                              onChange={(e) => {
                                setStats({
                                  ...stats,
                                  buyQualityLevel: e.target.value,
                                })
                              }}
                              valueLabelDisplay="auto"
                              disableSwap
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Typography gutterBottom>
                              {t('ADMINPANEL.SELL_QUALITY_LEVEL')}
                            </Typography>
                            <Slider
                              color="secondary"
                              getAriaLabel={() => 'Minimum distance'}
                              step={1}
                              min={1}
                              max={10}
                              marks={marks}
                              value={stats.sellQualityLevel}
                              onChange={(e) => {
                                setStats({
                                  ...stats,
                                  sellQualityLevel: e.target.value,
                                })
                              }}
                              valueLabelDisplay="auto"
                              disableSwap
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={3}>
                          <Button
                            variant={'contained'}
                            color={'error'}
                            onClick={(e) => {
                              setIsLoading(true)
                              setShowDeleteDialog(true)
                            }}
                          >
                            {t('GENERAL.DELETE')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <MaterialTable
                        icons={tableIcons}
                        localization={tableLocalization()}
                        columns={[
                          {
                            title: t('ADMINPANEL.USER_ID'),
                            field: 'id',
                          },
                          {
                            title: t('ADMINPANEL.SALUTATION'),
                            field: 'salutation',
                            lookup: salutationLookup,
                          },
                          {
                            title: t('ADMINPANEL.FIRSTNAME'),
                            field: 'firstname',
                          },
                          {
                            title: t('ADMINPANEL.LASTNAME'),
                            field: 'lastname',
                          },
                          {
                            title: t('ADMINPANEL.EMAIL'),
                            field: 'email',
                          },
                          {
                            title: t('ADMINPANEL.FAX'),
                            field: 'fax',
                          },
                          {
                            title: t('ADMINPANEL.PHONE'),
                            field: 'phone',
                          },
                          {
                            title: t('ADMINPANEL.MOBILE'),
                            field: 'mobile',
                          },
                        ]}
                        data={stats.users}
                        style={{
                          boxShadow: 'none',
                          //marginLeft: "60px"
                        }}
                        options={{
                          toolbar: false,
                          showTitle: false,
                          search: false,
                          paging: true,
                          filtering: false,
                          pageSize: 10,
                          emptyRowsWhenPaging: false,
                          padding: 'dense',
                          actionsColumnIndex: -1,
                          headerStyle: {
                            fontWeight: 'bold',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </div>
              ) : null}
              <AgreementDialog
                open={showDeleteDialog}
                message={t('ADMIN.ARE_YOU_SURE')}
                acceptButtonText={t('GENERAL.YES')}
                handleClose={() => {
                  setShowDeleteDialog(false)
                  setIsLoading(false)
                }}
                agree={() => {
                  setIsLoading(true)
                  setShowDeleteDialog(false)
                  partnersService
                    .deletePartner(selectedPartnerId)
                    .then((data) => {
                      alert(data.message)
                      window.location.reload(false)
                    })
                    .catch((e) => {
                      alert(e)
                    })
                    .finally(() => setIsLoading(false))
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PartnerProductsAllowed
                selectedPartnerId={selectedPartnerId}
              ></PartnerProductsAllowed>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PartnerRestrictions
                partnerId={selectedPartnerId}
              ></PartnerRestrictions>
            </TabPanel>
            <TabPanel value={value} index={3}>
              {stats.type === 'manufacturer' ? (
                <PartnerBadges
                  selectedPartnerId={selectedPartnerId}
                ></PartnerBadges>
              ) : (
                <Typography>{t('ADMINPANEL.NOT_MANUFACTURER')}</Typography>
              )}
            </TabPanel>
          </Fragment>
        ) : null}
      </Fragment>
    )
  )
}

export default withTranslation()(AdminUserOverview)
