import { Box, CircularProgress } from '@mui/material'
import React from 'react'

export const Loading = (props) => {
  const { variant } = props

  return variant === 'centered' ? (
    <CircularProgress className={'centered'} />
  ) : (
    <Box
      display="flex"
      justifyContent={'center'}
      alignItems="center"
      marginTop={10}
    >
      <CircularProgress />
    </Box>
  )
}
