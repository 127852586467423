import { Business } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'
import { Field } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryList,
  countryLookup,
  stackHeightUnitLookup,
} from '../../_constants/lookupConstants'
import { authorize } from '../../_helpers/authorization'
import NumberFormatCustom from '../../_helpers/numberFormatCustom'
import { partnerLocationsService } from '../../_services/partnerLocationsService'
import CustomDialogTitle from '../CustomDialogTitle'
import DeliveryTimes from '../DeliveryTimes'
import GoogleAutocomplete from '../GoogleAutocomplete'
import PartnerLocationDialog from '../PartnerLocationDialog'
import { Section } from '../Section'

const DeliveryDetails = (props) => {
  const {
    t,
    readOnly,
    setFieldValue,
    data,
    isOrderRegistration,
    mode,
    userId,
  } = props

  const [showSelectPartnerLocation, setShowSelectPartnerLocation] =
    useState(false)

  const [showCreatePartnerLocation, setShowCreatePartnerLocation] =
    useState(false)

  const [partnerLocations, setPartnerLocations] = useState([])

  var userObject = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    partnerLocationsService
      .getPartnerLocationsOfUser(userId ? userId : userObject.user.id)
      .then((partnerLocations) => {
        setPartnerLocations(
          partnerLocations.filter((location) => location.active),
        )

        if (
          mode === 'create' &&
          !data.generalInformation.deliveryDetails.locations[0].company
        ) {
          let primaryLocation = partnerLocations.find(
            (location) => location.primary,
          )

          if (!primaryLocation) {
            primaryLocation = partnerLocations[0]
          }
          setFieldValue('generalInformation.deliveryDetails.locations[0]', {
            ...data.generalInformation.deliveryDetails.locations[0],
            company: primaryLocation.company,
            countryId: primaryLocation.countryId,
            address: primaryLocation.address,
            zipcode: primaryLocation.zipcode,
            place: primaryLocation.place,
            unload: primaryLocation.unload,
            deliveryTimes: primaryLocation.deliveryTimes,
            stackHeight: primaryLocation.stackHeight
              ? primaryLocation.stackHeight
              : '',
            stackHeightUnit: primaryLocation.stackHeightUnit,
            deliveryInformations: primaryLocation.deliveryInformations
              ? primaryLocation.deliveryInformations
              : '',
            isDespatchAdviceRequired: primaryLocation.isDespatchAdviceRequired,
            despatchAdviceInstructions:
              primaryLocation.despatchAdviceInstructions
                ? primaryLocation.despatchAdviceInstructions
                : '',
            partnerLocationId: primaryLocation.id,
          })
        }
      })
  }, [showCreatePartnerLocation, userObject.user.id, userId])

  return (
    <Fragment>
      <Section header={t('REQUEST.DELIVERY_DETAILS.HEADING')}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item xs={12} sm={12}>
            {isOrderRegistration && (
              <Field
                component={TextField}
                label={t('REQUEST.DELIVERY_DETAILS.INCOTERM')}
                name={'generalInformation.deliveryDetails.incoTerm'}
                variant="outlined"
                margin="dense"
                fullWidth
                select
              >
                <MenuItem value={'DDP'} key={'DDP'}>
                  {t('REQUEST.DELIVERY_DETAILS.DELIVERED_DUTY_PAID')}
                </MenuItem>

                <MenuItem value={'EXW'} key={'EXW'}>
                  {t('REQUEST.DELIVERY_DETAILS.EX_WORKS')}
                </MenuItem>
              </Field>
            )}
          </Grid>
          {!readOnly && (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    size={'small'}
                    color={'secondary'}
                    onClick={() => setShowSelectPartnerLocation(true)}
                    variant={'contained'}
                  >
                    {t('REQUEST.DELIVERY_DETAILS.CHOOSE_DELIVERY_ADDRESS')}
                  </Button>
                </Grid>
                {authorize('addPartnerLocation') && (
                  <Grid item>
                    <Button
                      size={'small'}
                      color={'secondary'}
                      onClick={() => setShowCreatePartnerLocation(true)}
                      variant={'outlined'}
                    >
                      {t('REQUEST.ADD_LOCATION_TO_MASTER_DATA')}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              label={t('REQUEST.DELIVERY_DETAILS.COMPANY')}
              disabled={
                readOnly || userObject.user.partnerLocationIds.length > 0
              }
              name={'generalInformation.deliveryDetails.locations[0].company'}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              label={t('REQUEST.DELIVERY_DETAILS.COUNTRY')}
              disabled={
                readOnly || userObject.user.partnerLocationIds.length > 0
              }
              name={'generalInformation.deliveryDetails.locations[0].countryId'}
              variant="outlined"
              margin="dense"
              fullWidth
              select
            >
              {Object.entries(countryList).map((country) => (
                <MenuItem value={country[1]} key={country[0]}>
                  {country[0]}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <GoogleAutocomplete
              setFieldValue={setFieldValue}
              formikName={
                'generalInformation.deliveryDetails.locations[0].address'
              }
              formikPath={'generalInformation.deliveryDetails.locations[0]'}
              readOnly={
                readOnly || userObject.user.partnerLocationIds.length > 0
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              label={t('REQUEST.DELIVERY_DETAILS.ZIP_CODE')}
              disabled={
                readOnly || userObject.user.partnerLocationIds.length > 0
              }
              name={'generalInformation.deliveryDetails.locations[0].zipcode'}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              label={t('REQUEST.DELIVERY_DETAILS.PLACE')}
              disabled={
                readOnly || userObject.user.partnerLocationIds.length > 0
              }
              name={'generalInformation.deliveryDetails.locations[0].place'}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              {t('DELIVERY_DETAILS.POSSIBLE_LOADING')}
            </Typography>
            <ButtonGroup
              disabled={readOnly}
              color={'secondary'}
              sx={{
                height: '36.7px',
                marginTop: '8px',
              }}
              fullWidth
            >
              <Button
                variant={
                  data.generalInformation.deliveryDetails.locations[0]
                    .unload === 'ANY'
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() =>
                  setFieldValue(
                    `generalInformation.deliveryDetails.locations[0].unload`,
                    'ANY',
                  )
                }
              >
                {t('DELIVERY_DETAILS.ANY_UNLOAD')}
              </Button>
              <Button
                variant={
                  data.generalInformation.deliveryDetails.locations[0]
                    .unload === 'RAMP'
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() =>
                  setFieldValue(
                    `generalInformation.deliveryDetails.locations[0].unload`,
                    'RAMP',
                  )
                }
              >
                {t('DELIVERY_DETAILS.RAMP')}
              </Button>
              <Button
                variant={
                  data.generalInformation.deliveryDetails.locations[0]
                    .unload === 'SIDE'
                    ? 'contained'
                    : 'outlined'
                }
                onClick={() =>
                  setFieldValue(
                    `generalInformation.deliveryDetails.locations[0].unload`,
                    'SIDE',
                  )
                }
              >
                {t('DELIVERY_DETAILS.SIDE')}
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field
                  disabled={readOnly}
                  fullWidth={true}
                  component={TextField}
                  margin="dense"
                  size={'small'}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: (
                      <InputAdornment position="end">
                        {
                          stackHeightUnitLookup[
                            data.generalInformation.deliveryDetails.locations[0]
                              .stackHeightUnit
                          ]
                        }
                      </InputAdornment>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    decimalScale: 0,
                    allowNegative: false,
                  }}
                  label={`${t('DELIVERY_DETAILS.STACK_HEIGHT')} (${t(
                    'GENERAL.OPTIONAL',
                  )})`}
                  variant="outlined"
                  name={`generalInformation.deliveryDetails.locations[0].stackHeight`}
                />
              </Grid>
              <Grid item xs={6}>
                <ButtonGroup
                  disabled={readOnly}
                  color={'secondary'}
                  sx={{
                    height: '36.7px',
                    marginTop: '8px',
                  }}
                  fullWidth
                >
                  <Button
                    variant={
                      data.generalInformation.deliveryDetails.locations[0]
                        .stackHeightUnit === 'QUANTITY'
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() =>
                      setFieldValue(
                        `generalInformation.deliveryDetails.locations[0].stackHeightUnit`,
                        'QUANTITY',
                      )
                    }
                  >
                    {t('DELIVERY_DETAILS.PIECES')}
                  </Button>
                  <Button
                    variant={
                      data.generalInformation.deliveryDetails.locations[0]
                        .stackHeightUnit === 'CM'
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() =>
                      setFieldValue(
                        `generalInformation.deliveryDetails.locations[0].stackHeightUnit`,
                        'CM',
                      )
                    }
                  >
                    {t('DELIVERY_DETAILS.CENTIMETER')}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} spacing={1}>
            <Typography variant="caption">
              {t('DELIVERY_TIMES.LOADING_TIMES')}
            </Typography>
            <DeliveryTimes
              data={
                data.generalInformation.deliveryDetails.locations[0]
                  .deliveryTimes
              }
              formikName={
                'generalInformation.deliveryDetails.locations[0].deliveryTimes'
              }
              setFieldValue={setFieldValue}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              {t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  type={'checkbox'}
                  color="default"
                  name={
                    'generalInformation.deliveryDetails.locations[0].isDespatchAdviceRequired'
                  }
                  Label={{
                    label: t('DELIVERY_DETAILS.IS_DESPATCH_ADVICE_REQUIRED'),
                  }}
                  style={{ marginLeft: '15px' }}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  disabled={
                    readOnly ||
                    !data.generalInformation.deliveryDetails.locations[0]
                      .isDespatchAdviceRequired
                  }
                  name={
                    'generalInformation.deliveryDetails.locations[0].despatchAdviceInstructions'
                  }
                  fullWidth
                  margin="dense"
                  label={t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
                  variant="outlined"
                  multiline
                  rows="4"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption">
              {t('DELIVERY_DETAILS.OTHER_INFORMATIONS')}
            </Typography>
            <Field
              component={TextField}
              disabled={readOnly}
              name={
                'generalInformation.deliveryDetails.locations[0].deliveryInformations'
              }
              fullWidth
              margin="dense"
              label={t(
                'REQUEST.DELIVERY_DETAILS.ADDITIONAL_DELIVERY_INFORMATION',
              )}
              variant="outlined"
              multiline
              rows="4"
            />
          </Grid>
        </Grid>
      </Section>
      {data ? (
        <PartnerLocationDialog
          hide={() => {
            setShowCreatePartnerLocation(false)
          }}
          open={showCreatePartnerLocation}
        />
      ) : null}
      <Dialog open={showSelectPartnerLocation}>
        <CustomDialogTitle
          title={t('REQUEST.DELIVERY_DETAILS.CHOOSE_DELIVERY_ADDRESS')}
          onClose={() => setShowSelectPartnerLocation(false)}
        />
        <DialogContent>
          <List>
            {partnerLocations.map((location) => (
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    let selectedPartnerLocation = partnerLocations.find(
                      (loc) => loc.id === location.id,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].company',
                      selectedPartnerLocation.company,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].countryId',
                      selectedPartnerLocation.countryId,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].address',
                      selectedPartnerLocation.address,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].zipcode',
                      selectedPartnerLocation.zipcode,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].place',
                      selectedPartnerLocation.place,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].unload',
                      selectedPartnerLocation.unload,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].deliveryTimes',
                      selectedPartnerLocation.deliveryTimes,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].stackHeight',
                      selectedPartnerLocation.stackHeight
                        ? selectedPartnerLocation.stackHeight
                        : '',
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].stackHeightUnit',
                      selectedPartnerLocation.stackHeightUnit,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].deliveryInformations',
                      selectedPartnerLocation.deliveryInformations
                        ? selectedPartnerLocation.deliveryInformations
                        : '',
                    )

                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].isDespatchAdviceRequired',
                      selectedPartnerLocation.isDespatchAdviceRequired,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].despatchAdviceInstructions',
                      selectedPartnerLocation.despatchAdviceInstructions
                        ? selectedPartnerLocation.despatchAdviceInstructions
                        : '',
                    )

                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].partnerLocationId',
                      selectedPartnerLocation.id,
                    )

                    setShowSelectPartnerLocation(false)
                  }}
                >
                  <ListItemIcon>
                    <Business />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${location.company}, ${location.address}, ${
                      location.zipcode
                    } ${location.place}, ${countryLookup[location.countryId]}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'contained'}
            color={'secondary'}
            onClick={() => {
              setShowSelectPartnerLocation(false)
            }}
          >
            {t('GENERAL.CANCEL')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default withTranslation()(DeliveryDetails)
