import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header.js'

export const partnerProductsService = {
  getPartnerProducts,
  updatePartnerProduct,
}

function getPartnerProducts(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/partnerProducts`,
    requestOptions,
  )
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function updatePartnerProduct(partnerProductId, payload) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(
    `${config.apiUrlBase}/partnerProducts/${partnerProductId}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './partnerProductsService.js'
