import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Chip, List, ListItem, Typography } from '@mui/material'
import numeral from 'numeral'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import {
  generatePositionPropertyValue,
  translateProduct,
  translateProductUnit,
} from '../../_helpers/little'
import { InfoTooltip } from '../InfoTooltip'

export const PositionsList = (props) => {
  const { positions, maxLength } = props

  return (
    positions &&
    (positions.length > maxLength ? (
      <InfoTooltip
        title={
          <Fragment>
            {positions.map((pos) => (
              <ListItem sx={{ padding: 0 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 800 }}>
                  {numeral(pos.amount).format('0,0.[00]')}
                  {` ${translateProductUnit(pos.productUnit)} x `}
                  {translateProduct(pos.product)}
                </Typography>
                {pos.positionProperties
                  .filter(
                    (positionProperty) =>
                      positionProperty.value &&
                      positionProperty.productProperty.isHighlight,
                  )
                  .map((positionProperty) =>
                    positionProperty.productProperty.type !== 'BOOLEAN' ||
                    (positionProperty.productProperty.type === 'BOOLEAN' &&
                      (positionProperty.value === 'true' ||
                        positionProperty.value === true)) ? (
                      <Chip
                        sx={{ margin: 1 }}
                        size={'small'}
                        label={generatePositionPropertyValue(positionProperty)}
                      />
                    ) : null,
                  )}
              </ListItem>
            ))}
          </Fragment>
        }
      >
        <List>
          {positions.slice(0, maxLength).map((pos) => (
            <ListItem sx={{ padding: 0 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 800 }}>
                {numeral(pos.amount).format('0,0.[00]')}
                {` ${translateProductUnit(pos.productUnit)} x `}
                {translateProduct(pos.product)}
              </Typography>
              {pos.positionProperties
                .filter(
                  (positionProperty) =>
                    positionProperty.productProperty.isHighlight,
                )
                .map((positionProperty) => (
                  <Chip
                    sx={{ margin: 1 }}
                    size={'small'}
                    label={generatePositionPropertyValue(positionProperty)}
                  />
                ))}
            </ListItem>
          ))}
          <MoreHorizIcon />
        </List>
      </InfoTooltip>
    ) : (
      <List>
        {positions.map((pos) => (
          <ListItem sx={{ padding: 0 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 800 }}>
              {numeral(pos.amount).format('0,0.[00]')}
              {` ${`${translateProductUnit(pos.productUnit)}`} x `}
              {translateProduct(pos.product)}
            </Typography>
            {pos.positionProperties
              .filter(
                (positionProperty) =>
                  positionProperty.productProperty.isHighlight,
              )
              .map((positionProperty) =>
                positionProperty.productProperty.type !== 'BOOLEAN' ||
                (positionProperty.productProperty.type === 'BOOLEAN' &&
                  (positionProperty.value === 'true' ||
                    positionProperty.value === true)) ? (
                  <Chip
                    sx={{ margin: 1 }}
                    size={'small'}
                    label={generatePositionPropertyValue(positionProperty)}
                  />
                ) : null,
              )}
          </ListItem>
        ))}
      </List>
    ))
  )
}

PositionsList.propTypes = {
  positions: PropTypes.array,
  maxLength: PropTypes.number,
}

PositionsList.defaultProps = {
  positions: [],
  maxLength: 3,
}
