import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  List,
  Typography,
} from '@mui/material'
import React, { useMemo } from 'react'
import { Loading } from '../Loading'

const ResourceMapGoogleMapChildren = (props) => {
  const {
    setHoveredItem,
    data,
    scrollableListRef,
    isLoading,
    listHeaderRenderFunction,
    headerActionRenderFunction,
    contentRenderFunction,
    dataIdentifier,
    selectedItem,
    setSelectedItem,
  } = props

  const listMemo = useMemo(() => {
    return (
      <List
        ref={scrollableListRef}
        dense={true}
        sx={{
          padding: 0,
          flexGrow: 1,
          overflow: 'auto',
          /* for Firefox */
          minHeight: 0,
        }}
        onMouseLeave={() => (setHoveredItem ? setHoveredItem(null) : null)}
      >
        {isLoading ? (
          <Loading />
        ) : (
          data.map((item, index) => {
            return (
              <Card
                onMouseEnter={() =>
                  setHoveredItem ? setHoveredItem(item) : null
                }
                variant="outlined"
                sx={{
                  margin: '5px',
                  marginRight: '10px',
                  backgroundColor:
                    selectedItem &&
                    selectedItem[dataIdentifier] === item[dataIdentifier]
                      ? '#e0e0e0'
                      : '#ffffff',
                }}
              >
                <CardActionArea
                  onClick={() => setSelectedItem(item)}
                  focusRipple={true}
                >
                  <CardContent sx={{ paddingBottom: 0 }}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'flex-start',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ order: { xs: 2, sm: 1 } }}
                      >
                        <Typography variant={'caption'}>
                          {listHeaderRenderFunction
                            ? listHeaderRenderFunction(item)
                            : null}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{
                          order: { xs: 1, sm: 2 },
                          marginLeft: { xs: 0, sm: 'auto' },
                        }}
                      >
                        <Grid container direction="row-reverse">
                          <Grid item>
                            <Box>
                              {headerActionRenderFunction
                                ? headerActionRenderFunction(item)
                                : null}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardContent sx={{ paddingTop: 1, paddingBottom: 1 }}>
                    {contentRenderFunction
                      ? contentRenderFunction(item, index)
                      : null}
                  </CardContent>
                </CardActionArea>
              </Card>
            )
          })
        )}
      </List>
    )
  }, [data, selectedItem, isLoading])

  return listMemo
}
export default ResourceMapGoogleMapChildren
