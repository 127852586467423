import i18n from '../i18n'
import keycloak from './../keycloak'
import { authorize } from './authorization'

export function authHeader() {
  let lng = i18n.language.substring(0, 2)

  if (lng === 'pt') {
    lng = 'pt-PT'
  }
  // return authorization header with jwt token
  if (keycloak && keycloak.authenticated) {
    return {
      Authorization: 'Bearer ' + keycloak.token,
      Pragma: 'no-cache',
      lng: lng,
      impersonatedUserId: authorize('administrator')
        ? JSON.parse(localStorage.getItem('user')).user.id
        : undefined,
    }
  } else {
    return {
      // return no token but the header, as it is required by the keycloak implementation in the backend
      Authorization: 'Bearer no-token',
      Pragma: 'no-cache',
      lng: lng,
    }
  }
}
