import config from '../_config/config'
import { authHeader } from '../_helpers/auth-header'

export const communityPartnersService = {
  deleteCommunityPartner,
}

function deleteCommunityPartner(communityPartnerId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${config.apiUrlBase}/community-partners/${communityPartnerId}`,
    requestOptions,
  )
}

export * from './communityPartnersService'
