import { combineReducers } from 'redux'

import { alert } from './alertReducer'
import { authentication } from './authenticationReducer'
import { registration } from './registrationReducer'
import { users } from './usersReducer'

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
})

export default rootReducer
