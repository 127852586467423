export function authorize(permission, user) {
  if (!user && localStorage.getItem('user')) {
    user = JSON.parse(localStorage.getItem('user'))
  }
  // if called without permission return authorized
  if (!permission) {
    return true
  }
  if (!user || !user.permissions) {
    return false
  }
  return user.permissions.find((perm) => permission === perm)
}
