import { Delete, HelpOutline, RestartAlt, Save } from '@mui/icons-material'
import { Card, Grid, IconButton, Tooltip } from '@mui/material'
import React, { Fragment } from 'react'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions/alertActions'
import { offerAttachmentsService } from '../../_services/offerAttachmentsService'
import { InfoTooltip } from '../InfoTooltip'
import { Section } from '../Section'
const dropzoneStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: '2px',
  borderRadius: '2px',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in -out',
  margin: '15px',
}

const minFileSize = 1
const maxFileSize = 10000000

const OfferAttachments = (props) => {
  const { t, dispatch, data, setFieldValue, mode } = props

  return (
    <Section
      header={
        <Fragment>
          {t('REQUEST.OFFER_ATTACHMENTS')}
          <InfoTooltip
            disableTouchListener
            disableFocusListener
            title={t('REQUEST.ATTACHMENTS_TOOLTIP')}
          >
            <IconButton
              color="default"
              style={{
                padding: 0,
                margin: '0 8px',
              }}
              size="large"
            >
              <HelpOutline />
            </IconButton>
          </InfoTooltip>
        </Fragment>
      }
    >
      {data.attachments && data.attachments.length > 0 ? (
        <Grid container spacing={1} style={{ padding: '15px' }}>
          {(mode === 'EDIT'
            ? data.attachments
            : data.attachments.filter((attachment) => attachment.isNew)
          ).map((attachment) => (
            <Grid item xs={12} lg={3} sm={6}>
              <Card
                key={attachment.name}
                elevation={0}
                style={{ border: '1px solid #ddd' }}
              >
                <Grid container>
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="flex-end"
                    wrap="nowrap"
                    style={{ lineHeight: '27px' }}
                  >
                    <Grid
                      item
                      xs
                      zeroMinWidth
                      style={{
                        padding: '0 5px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {attachment.isDeleted ? (
                        <s>
                          <b>{attachment.name}</b>
                        </s>
                      ) : (
                        <b>{attachment.name}</b>
                      )}
                    </Grid>
                    {attachment.isDeleted ? (
                      <Grid
                        item
                        xs={3}
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#ddd',
                        }}
                      >
                        <Tooltip title={t('OFFER.OFFER_ATTACHMENTS.RECOVER')}>
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => {
                              let newAttachments = [...data.attachments]
                              newAttachments = newAttachments.map(
                                (newAttachment) => {
                                  if (newAttachment.id === attachment.id) {
                                    newAttachment.isDeleted = false
                                    return newAttachment
                                  } else {
                                    return newAttachment
                                  }
                                },
                              )
                              setFieldValue('attachments', newAttachments)
                            }}
                          >
                            <RestartAlt />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    ) : (
                      <Grid
                        item
                        xs={3}
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#ddd',
                        }}
                      >
                        <Tooltip title={t('GENERAL.DOWNLOAD')}>
                          <IconButton
                            color="default"
                            size="small"
                            disabled={attachment.isNew}
                            onClick={() => {
                              offerAttachmentsService
                                .getOfferAttachment(attachment)
                                .then((response) => response.blob())
                                .then((blob) => {
                                  // 2. Create blob link to download
                                  const url = window.URL.createObjectURL(
                                    new Blob([blob]),
                                  )
                                  const link = document.createElement('a')
                                  link.href = url
                                  link.setAttribute('download', attachment.name)
                                  // 3. Append to html page
                                  document.body.appendChild(link)
                                  // 4. Force download
                                  link.click()
                                  // 5. Clean up and remove the link
                                  link.parentNode.removeChild(link)
                                })
                            }}
                          >
                            <Save />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                  {!attachment.isDeleted && (
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="flex-end"
                      wrap="nowrap"
                      style={{ lineHeight: '27px' }}
                    >
                      <Grid
                        item
                        xs={3}
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#ddd',
                        }}
                      >
                        <Tooltip title={t('GENERAL.DELETE')}>
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => {
                              if (attachment.isNew) {
                                const index =
                                  data.attachments.indexOf(attachment)
                                let newAttachments = [...data.attachments]
                                newAttachments.splice(index, 1)
                                setFieldValue('attachments', newAttachments)
                              } else {
                                let newAttachments = [...data.attachments]
                                newAttachments = newAttachments.map(
                                  (newAttachment) => {
                                    if (newAttachment.id === attachment.id) {
                                      newAttachment.isDeleted = true
                                      return newAttachment
                                    } else {
                                      return newAttachment
                                    }
                                  },
                                )
                                setFieldValue('attachments', newAttachments)
                              }
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div style={{ textAlign: 'center', padding: '10px' }}>
          {t('REQUEST.NO_ATTACHMENTS')}
        </div>
      )}
      <Dropzone
        minSize={minFileSize}
        maxSize={maxFileSize}
        accept="image/*,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.pdf,.zip,.txt"
        onDropAccepted={(files) => {
          files.forEach((file) => {
            file.isNew = true
            file.isDeleted = false
          })
          setFieldValue('attachments', data.attachments.concat(files))
        }}
        onDropRejected={(files) => {
          files.some((file) => {
            if (file.size < minFileSize || file.size > maxFileSize) {
              dispatch(
                alertActions.error(
                  file.name + ' --> ' + t('GENERAL.ATTACHMENT_SIZE_ERROR'),
                ),
              )
              return true
            }
            dispatch(
              alertActions.error(
                file.name + ' --> ' + t('GENERAL.ATTACHMENT_TYPE_ERROR'),
              ),
            )
            return true
          })
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div
            style={dropzoneStyle}
            {...getRootProps({ className: 'dropzone' })}
          >
            <input {...getInputProps()} />
            <p>{t('REQUEST.CLICK_HERE_OR_DRAG_FILES_HERE')}</p>
          </div>
        )}
      </Dropzone>
    </Section>
  )
}

export default connect()(withTranslation()(OfferAttachments))
