import { de } from 'date-fns/locale'
import moment from 'moment'
import React from 'react'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'

export default class CalendarWeekPicker extends React.Component {
  state = {
    hoverRange: undefined,
  }

  render() {
    const { hoverRange } = this.state

    const { selectedDays, setSelectedDays, enablePast } = this.props

    const daysAreSelected = selectedDays.length > 0

    const today = new Date()

    const modifiers = {
      hoverRange,
      selectedRange: daysAreSelected && {
        from: selectedDays[0],
        to: selectedDays[6],
      },
      hoverRangeStart: hoverRange && hoverRange.from,
      hoverRangeEnd: hoverRange && hoverRange.to,
      selectedRangeStart: daysAreSelected && selectedDays[0],
      selectedRangeEnd: daysAreSelected && selectedDays[6],
    }

    return (
      <DayPicker
        locale={de}
        disabled={enablePast ? {} : { before: today }}
        firstDayOfWeek={1}
        selected={selectedDays}
        showWeekNumber
        showOutsideDays
        modifiers={modifiers}
        onDayClick={(date) => {
          var days = getWeekDays(getWeekRange(date).from)
          if (!enablePast && days[6] < new Date()) {
            return
          }
          setSelectedDays(days)
        }}
        onDayMouseEnter={(date) => {
          var days = getWeekDays(getWeekRange(date).from)
          if (!enablePast && days[6] < new Date()) {
            return
          }
          this.setState({ hoverRange: days })
        }}
        onDayMouseLeave={() => this.setState({ hoverRange: undefined })}
        onWeekClick={(weekNumber, days) => {
          if (!enablePast && days[6] < new Date()) {
            return
          }
          setSelectedDays(days)
        }}
      />
    )
  }
}

function getWeekDays(weekStart) {
  const days = [weekStart]
  for (let i = 1; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, 'days').toDate())
  }
  return days
}

function getWeekRange(date) {
  return {
    from: moment(date).startOf('isoWeek').toDate(),
    to: moment(date).endOf('isoWeek').toDate(),
  }
}
