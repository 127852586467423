import { Delete, Info } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField as MUITextField,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { partnerRestrictionsService } from '../../../../../../_services/partnerRestrictionsService'
import { partnersService } from '../../../../../../_services/partnersService'

const PartnerRestrictions = (props) => {
  const { t, partnerId } = props
  const [restrictionType, setRestrictionType] = useState('')
  const [partnerRestrictions, setPartnerRestrictions] = useState([])
  const [targetPartnerId, setTargetPartnerId] = React.useState(null)
  const [selectedPartnerInputValue, setSelectedPartnerInputValue] = useState('')
  const [partners, setPartners] = React.useState([])
  const [openChangeRestrictionTypeDialog, setOpenChangeRestrictionTypeDialog] =
    useState(false)
  const [openDialogAlreadyRestricted, setOpenAlreadyRestrictedDialog] =
    useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)

  useEffect(() => {
    partnersService.getPartner(partnerId).then((data) => {
      setRestrictionType(data.restrictionType)
    })
  }, [partnerId])

  useEffect(() => {
    loadPartnerRestrictions()
  }, [forceUpdateCount])

  const loadPartnerRestrictions = () => {
    partnerRestrictionsService
      .getPartnerRestrictionsOfPartner(partnerId)
      .then((data) => {
        setPartnerRestrictions(data)
      })
  }
  const createPartnerRestriction = (partnerId, targetPartnerId) => {
    const requestBody = {
      sourcePartnerId: partnerId,
      targetPartnerId: targetPartnerId,
    }
    partnerRestrictionsService
      .createPartnerRestriction(requestBody)
      .then(() => {
        forceUpdate(forceUpdateCount + 1)
      })
      .catch((error) => {
        console.error('Error creating partner restriction: ', error)
      })
  }
  const isPartnerAlreadyRestricted = (targetPartnerId) => {
    return partnerRestrictions.some(
      (restriction) => restriction.targetPartner.id === targetPartnerId,
    )
  }
  const handleDelete = (restrictionId) => {
    partnerRestrictionsService
      .deletePartnerRestriction(restrictionId)
      .then(() => {
        forceUpdate(forceUpdateCount + 1)
      })
      .catch((error) => {
        console.error('Error creating partner restriction: ', error)
      })
  }
  const handleChangeOfRestrictionType = (event) => {
    setRestrictionType(event.target.value)
    setOpenChangeRestrictionTypeDialog(true)
  }

  const handleConfirmOfRestrictionType = () => {
    partnersService
      .updatePartner(partnerId, { restrictionType: restrictionType })
      .then(() => {
        setOpenChangeRestrictionTypeDialog(false)
        forceUpdate(forceUpdateCount + 1)
      })
  }
  useEffect(() => {
    partnersService.getPartners().then((partners) => {
      partners = _.orderBy(partners, ['id'], ['desc'])
      setPartners(partners)
    })
  }, [])
  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography>{t('ADMINPANEL.SELECT_RESTRICTION_TYPE')}</Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              value={restrictionType}
              onChange={handleChangeOfRestrictionType}
            >
              <MenuItem value="BLACKLIST">{t('ADMINPANEL.BLACKLIST')}</MenuItem>
              <MenuItem value="WHITELIST">{t('ADMINPANEL.WHITELIST')}</MenuItem>
            </Select>
            <Tooltip title={t('ADMINPANEL.TOOLTIP_LISTS')}>
              <IconButton>
                <Info />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>

        <Grid item xs={12}>
          <Typography>{t('ADMINPANEL.SELECT_PARTNER_RESTRICTION')}</Typography>
          <Autocomplete
            value={targetPartnerId}
            onChange={(e, newValue) => {
              setTargetPartnerId(newValue)
              if (isPartnerAlreadyRestricted(newValue)) {
                setOpenAlreadyRestrictedDialog(true)
              } else {
                createPartnerRestriction(partnerId, newValue)
              }
            }}
            inputValue={selectedPartnerInputValue}
            onInputChange={(event, newInputValue) => {
              setSelectedPartnerInputValue(newInputValue)
            }}
            size={'small'}
            disablePortal
            options={partners.map((customer) => customer.id)}
            getOptionLabel={(option) => {
              if (!option) {
                return ''
              }

              let partner = partners.find((customer) => customer.id === option)

              return `${
                !partner.active ? `[${t('ADMINPANEL.INACTIVE')}] ` : ''
              }${partner.name} (${partner.id}) - ${partner.place}`
            }}
            sx={{ width: 600 }}
            renderInput={(params) => (
              <MUITextField {...params} label={t('GENERAL.SELECTED_PARTNER')} />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="einfache Tabelle">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">ID</TableCell>
                  <TableCell align="right">Löschen</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {partnerRestrictions.map((restriction) => (
                  <TableRow
                    key={restriction.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {restriction.targetPartner.name}
                    </TableCell>
                    <TableCell align="right">
                      {restriction.targetPartner.id}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => handleDelete(restriction.id)}>
                        <Delete />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Dialog
        open={openChangeRestrictionTypeDialog}
        onClose={() => setOpenChangeRestrictionTypeDialog(false)}
      >
        <DialogContent>
          <Typography variant="body1">
            {t('ADMINPANEL.RESTRICTION_TYPE_CHANGE')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenChangeRestrictionTypeDialog(false)}
            variant={'outlined'}
            color={'secondary'}
          >
            {t('GENERAL.CANCEL')}
          </Button>
          <Button
            onClick={handleConfirmOfRestrictionType}
            variant={'contained'}
            color={'secondary'}
          >
            {t('GENERAL.CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogAlreadyRestricted}
        onClose={() => setOpenAlreadyRestrictedDialog(false)}
      >
        <DialogContent>
          <Typography variant="body1">
            {t('ADMINPANEL.PARTNER_ALREADY_RESTRICTED')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenAlreadyRestrictedDialog(false)}
            variant={'contained'}
            color={'secondary'}
          >
            {t('GENERAL.SUBMIT')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default withTranslation()(PartnerRestrictions)
