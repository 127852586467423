import moment from 'moment'
import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'
import { generateFilterValuesQueryString } from '../_helpers/little.js'
import { attachmentsService } from './attachmentsService'

export const requestsService = {
  createRequestOfPartner,
  updateRequest,
  getRequest,
  getRequests,
  getPublishedRequests,
  getRequestsOfPartner,
  deleteRequest,
  getRequestPositionsOfRequest,
}

function getRequest(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  const userObject = JSON.parse(localStorage.getItem('user'))

  return fetch(
    `${config.apiUrlBase}/requests/${id}?partnerId=${userObject.partner.id}`,
    requestOptions,
  ).then(handleResponse)
}

function getPublishedRequests(
  types,
  partnerId,
  filterValues = undefined,
  pagination = undefined,
) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  filterValues = filterValues['~IGNORE~calendarWeek']
    ? {
        ...filterValues,
        '~IGNORE~calendarWeek': moment(
          filterValues['~IGNORE~calendarWeek'][0],
        ).format('YYYY-MM-DD HH:mm:ss'),
      }
    : filterValues

  let queryString = generateFilterValuesQueryString(filterValues, pagination)

  let requestTypes = types.map((type) => type).join(',')

  let url = `${config.apiUrlBase}/published-requests${queryString}&partnerId.ne=${partnerId}&type=${requestTypes}`

  if (filterValues?.geo?.origin) {
    url += `&latitude=${filterValues.geo.origin.lat}&longitude=${
      filterValues.geo.origin.lng
    }&radius=${filterValues.geo.radius / 1000}`
  }

  return fetch(url, requestOptions).then(handleResponse)
}
function getRequests(filterValues = undefined, pagination = undefined) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  filterValues = filterValues['~IGNORE~calendarWeek']
    ? {
        ...filterValues,
        '~IGNORE~calendarWeek': moment(
          filterValues['~IGNORE~calendarWeek'][0],
        ).format('YYYY-MM-DD HH:mm:ss'),
      }
    : filterValues

  let queryString = generateFilterValuesQueryString(filterValues, pagination)
  let url = `${config.apiUrlBase}/requests${queryString}`

  return fetch(url, requestOptions).then(handleResponse)
}

function getRequestsOfPartner(
  partnerId,
  type,
  filterValues = undefined,
  pagination,
  partnerLocationIds,
) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  let queryString = generateFilterValuesQueryString(filterValues, pagination)

  let url =
    partnerLocationIds.length === 0
      ? `${config.apiUrlBase}/partners/${partnerId}/requests${queryString}&type=${type}&partnerLocationIds=null`
      : `${
          config.apiUrlBase
        }/partners/${partnerId}/requests${queryString}&type=${type}&${partnerLocationIds
          .map((partnerLocationId) => 'partnerLocationIds=' + partnerLocationId)
          .join('&')}`

  return fetch(url, requestOptions).then(handleResponse)
}

function createRequestOfPartner(partnerId, userId, request) {
  return new Promise(function (resolve, reject) {
    var payLoad = mapRequest(request)
    var requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...payLoad, userId: userId }),
    }

    const partnerLocationIds = payLoad.locations.map(
      (location) => location.partnerLocationId,
    )

    fetch(
      `${config.apiUrlBase}/partners/${partnerId}/requests?${partnerLocationIds
        .map((partnerLocationId) => 'partnerLocationIds=' + partnerLocationId)
        .join('&')}`,
      requestOptions,
    )
      .then(handleResponse)
      .then((data) => {
        var createdRequestId = data.requestId

        let fileUploadPromiseList = []
        request.attachments.forEach(function (attachment) {
          if (!attachment.new) {
            return
          }

          var requestBody = new FormData()

          requestBody.append('file', attachment)

          requestOptions = {
            method: 'POST',
            headers: { ...authHeader() },
            body: requestBody,
          }

          var attachmentPromiseList = new Promise(function (resolve, reject) {
            attachmentsService
              .createAttachmentOfRequest(createdRequestId, requestBody)
              .then(function (response) {
                if (response.ok) {
                  resolve()
                } else {
                  reject()
                }
              })
          })

          fileUploadPromiseList.push(attachmentPromiseList)
        })

        Promise.all(fileUploadPromiseList).then(function (values) {
          resolve(data.requestId)
        })
      })
  })
}

function updateRequest(
  values,
  requestId,
  useRequestMapping = false,
  partnerLocationIds,
) {
  var payLoad = useRequestMapping ? mapRequest(values) : values
  var requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payLoad),
  }

  return fetch(
    `${config.apiUrlBase}/requests/${requestId}?${partnerLocationIds
      .map((partnerLocationId) => 'partnerLocationIds=' + partnerLocationId)
      .join('&')}`,
    requestOptions,
  ).then(handleResponse)
}

function deleteRequest(requestId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${config.apiUrlBase}/requests/` + requestId,
    requestOptions,
  ).then(handleResponse)
}

function getRequestPositionsOfRequest(requestId, filterValues, pagination) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  let queryString = generateFilterValuesQueryString(filterValues, pagination)

  let url = `${config.apiUrlBase}/requests/${requestId}/request-positions${queryString}`

  return fetch(url, requestOptions).then(handleResponse)
}

function mapRequest(request) {
  return {
    requestId: request.id,
    incoTerm: request.generalInformation.deliveryDetails.incoTerm
      ? request.generalInformation.deliveryDetails.incoTerm
      : 'DDP',
    type: request.type,
    state: request.state,
    communityId: request.generalInformation.otherDetails.communityId
      ? request.generalInformation.otherDetails.communityId
      : null,
    paymentTerms: request.generalInformation.paymentDetails.paymentTerm,
    skonto: request.generalInformation.paymentDetails.skonto,
    skontoPeriod: request.generalInformation.paymentDetails.skontoPeriod,
    title: request.generalInformation.otherDetails.title,
    start:
      request.generalInformation.otherDetails.start &&
      request.generalInformation.otherDetails.start !== ''
        ? moment(request.generalInformation.otherDetails.start).format(
            'YYYY-MM-DD',
          )
        : null,
    end:
      request.generalInformation.otherDetails.end &&
      request.generalInformation.otherDetails.end !== ''
        ? moment(request.generalInformation.otherDetails.end).format(
            'YYYY-MM-DD',
          )
        : null,
    validUntil: moment(
      request.generalInformation.otherDetails.validUntil,
    ).format('YYYY-MM-DD'),
    locations: request.generalInformation.deliveryDetails.locations.map(
      (location) => ({
        id: location.id,
        company: location.company,
        address: location.address,
        zipcode: location.zipcode,
        place: location.place,
        countryId: location.countryId,
        deliveryInformations: location.deliveryInformations,
        unload: location.unload,
        stackHeight: Number(location.stackHeight) ? location.stackHeight : null,
        stackHeightUnit: location.stackHeightUnit,
        isDespatchAdviceRequired: location.isDespatchAdviceRequired,
        despatchAdviceInstructions: location.despatchAdviceInstructions,
        geoData: location.geoData,
        deliveryTimes: location.deliveryTimes,
        positions: location.positions.map((pos) => ({
          id: pos.id,
          productId: pos.productId,
          productUnitId:
            request.type === 'TENDER'
              ? request.positions.find((rPos) => rPos.id === pos.id)
                  .productUnitId
              : pos.productUnitId,
          amount: pos.amount,
          targetPrice: pos.targetPrice,
          positionProperties: pos.positionProperties.map((positionProperty) => {
            if (
              positionProperty.productProperty.type === 'TEXT' &&
              positionProperty.value === ''
            ) {
              positionProperty.value = null
            }
            return {
              value: positionProperty.value,
              productPropertyId: positionProperty.productProperty.id,
            }
          }),
          index: pos.index,
          price: pos.price ? pos.price : null,
        })),
        partnerLocationId: location.partnerLocationId,
      }),
    ),
    attachments: request.attachments,
    deliveries:
      request.type === 'REQUEST' ||
      (request.type === 'ADVERT' && request.deliveries.length > 0)
        ? request.deliveries.map((delivery) => {
            return {
              id: delivery.id,
              type: delivery.type,
              value: moment(delivery.value).format('YYYY-MM-DD'),
              obligatory: delivery.obligatory,
              deliveryPositions: delivery.positions
                .filter((dPos) => dPos.amount > 0)
                .map((dPos) => {
                  return {
                    id: dPos.positionId,
                    positionId: dPos.positionId,
                    amount: dPos.amount,
                  }
                }),
            }
          })
        : undefined,
  }
}

export * from './requestsService'
