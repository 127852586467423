import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-mui'
import Papa from 'papaparse'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import NumberFormatCustom from '../../../../../../../../../_helpers/numberFormatCustom'

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const ProductPriceLimitZipCodeAreaDialog = (props) => {
  const { t, countryId, data, hide, submit } = props

  const [zipcodeCoords, setZipcodeCoords] = useState([])
  const [tabsValue, setTabsValue] = React.useState(null)
  const [initialValues, setInitialValues] = useState({})

  const handleTabChange = (event, newValue) => {
    setTabsValue(newValue)
  }

  useEffect(() => {
    const checkFileExists = async (csvFileUrl) => {
      try {
        const response = await fetch(csvFileUrl, { method: 'HEAD' })
        return response.ok // Gibt true zurück, wenn die Datei existiert
      } catch (error) {
        console.error('Fehler beim Überprüfen der CSV-Datei:', error)
        return false // Bei einem Fehler gehen wir davon aus, dass die Datei nicht existiert
      }
    }

    const parseCSV = (csvFileUrl) => {
      Papa.parse(csvFileUrl, {
        download: true,
        header: true,
        complete: (result) => {
          setZipcodeCoords(result.data)
        },
      })
    }

    const csvFileUrl = `other/zipcode-geocoord-${countryId}.csv`

    const init = async () => {
      const fileExists = await checkFileExists(csvFileUrl)
      if (fileExists) {
        parseCSV(csvFileUrl)
      } else {
        setZipcodeCoords([])
      }
    }

    init()
  }, [countryId])

  useEffect(() => {
    setInitialValues(
      data.zipcodeAreas[countryId] ? data.zipcodeAreas[countryId] : {},
    )
  }, [countryId])

  return (
    <Dialog fullWidth={true} maxWidth="lg" open={true}>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          submit(values)
        }}
      >
        {({ values, setFieldValue, submitForm }) => {
          const firstDigit = tabsValue

          return (
            <Fragment>
              <DialogContent>
                <Grid container spacing={2}>
                  <Tabs
                    value={tabsValue}
                    onChange={handleTabChange}
                    aria-label="zipcode-area-tabs"
                  >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                      .filter(
                        (area) =>
                          zipcodeCoords.length === 0 ||
                          zipcodeCoords.some((zipcodeCoord) =>
                            zipcodeCoord.zipcode.startsWith(area),
                          ),
                      )
                      .map((area) => (
                        <Tab label={area} value={area} />
                      ))}
                  </Tabs>

                  {tabsValue !== null ? (
                    <Grid item xs={12} key={tabsValue}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item xs={12}>
                          <Stack direction="row" spacing={2}>
                            <Field
                              fullWidth={true}
                              component={TextField}
                              margin="dense"
                              size={'small'}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                inputComponent: NumberFormatCustom,
                                decimalScale: 2,
                                allowNegative: false,
                                suffix: ' \u20AC',
                                fixedDecimalScale: true,
                              }}
                              label={`${t(
                                'AUTOMATIC_OFFERS.ZIPCODE_AREA',
                              )} ${firstDigit} - ${t(
                                'PRODUCT_PRICE_LIMITS.BACKUP_PURCHASE_PRICE',
                              )}`}
                              variant="outlined"
                              name={`${firstDigit}.backupPurchasePrice`}
                            />
                            <Field
                              fullWidth={true}
                              component={TextField}
                              margin="dense"
                              size={'small'}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                inputComponent: NumberFormatCustom,
                                decimalScale: 2,
                                allowNegative: false,
                                suffix: ' \u20AC',
                                fixedDecimalScale: true,
                              }}
                              label={`${t(
                                'AUTOMATIC_OFFERS.ZIPCODE_AREA',
                              )} ${firstDigit} - ${t(
                                'PRODUCT_PRICE_LIMITS.MIN_PRICE',
                              )}`}
                              variant="outlined"
                              name={`${firstDigit}.minPrice`}
                            />
                            <Field
                              fullWidth={true}
                              component={TextField}
                              margin="dense"
                              size={'small'}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                inputComponent: NumberFormatCustom,
                                decimalScale: 2,
                                allowNegative: false,
                                suffix: ' \u20AC',
                                fixedDecimalScale: true,
                              }}
                              label={`${t(
                                'AUTOMATIC_OFFERS.ZIPCODE_AREA',
                              )} ${firstDigit} - ${t(
                                'PRODUCT_PRICE_LIMITS.MAX_PRICE',
                              )}`}
                              variant="outlined"
                              name={`${firstDigit}.maxPrice`}
                            />
                          </Stack>
                        </Grid>
                        <Fragment>
                          <Grid item xs={1}></Grid>
                          <Grid item xs={11}>
                            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                              .filter(
                                (area) =>
                                  zipcodeCoords.length === 0 ||
                                  zipcodeCoords.some((zipcodeCoord) =>
                                    zipcodeCoord.zipcode.startsWith(
                                      `${firstDigit}${area}`,
                                    ),
                                  ),
                              )
                              .map((secondDigit) => {
                                const zipcodeArea = `${firstDigit}${secondDigit}`

                                return (
                                  <Stack direction="row" spacing={2}>
                                    <Field
                                      fullWidth={true}
                                      component={TextField}
                                      margin="dense"
                                      size={'small'}
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                      // eslint-disable-next-line react/jsx-no-duplicate-props
                                      inputProps={{
                                        inputComponent: NumberFormatCustom,
                                        decimalScale: 2,
                                        allowNegative: false,
                                        suffix: ' \u20AC',
                                        fixedDecimalScale: true,
                                      }}
                                      label={`${t(
                                        'AUTOMATIC_OFFERS.ZIPCODE_AREA',
                                      )} ${zipcodeArea} - ${t(
                                        'PRODUCT_PRICE_LIMITS.BACKUP_PURCHASE_PRICE',
                                      )}`}
                                      variant="outlined"
                                      name={`${zipcodeArea}.backupPurchasePrice`}
                                    />
                                    <Field
                                      fullWidth={true}
                                      component={TextField}
                                      margin="dense"
                                      size={'small'}
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                      // eslint-disable-next-line react/jsx-no-duplicate-props
                                      inputProps={{
                                        inputComponent: NumberFormatCustom,
                                        decimalScale: 2,
                                        allowNegative: false,
                                        suffix: ' \u20AC',
                                        fixedDecimalScale: true,
                                      }}
                                      label={`${t(
                                        'AUTOMATIC_OFFERS.ZIPCODE_AREA',
                                      )} ${zipcodeArea} - ${t(
                                        'PRODUCT_PRICE_LIMITS.MIN_PRICE',
                                      )}`}
                                      variant="outlined"
                                      name={`${zipcodeArea}.minPrice`}
                                    />
                                    <Field
                                      fullWidth={true}
                                      component={TextField}
                                      margin="dense"
                                      size={'small'}
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                      // eslint-disable-next-line react/jsx-no-duplicate-props
                                      inputProps={{
                                        inputComponent: NumberFormatCustom,
                                        decimalScale: 2,
                                        allowNegative: false,
                                        suffix: ' \u20AC',
                                        fixedDecimalScale: true,
                                      }}
                                      label={`${t(
                                        'AUTOMATIC_OFFERS.ZIPCODE_AREA',
                                      )} ${zipcodeArea} - ${t(
                                        'PRODUCT_PRICE_LIMITS.MAX_PRICE',
                                      )}`}
                                      variant="outlined"
                                      name={`${zipcodeArea}.maxPrice`}
                                    />
                                  </Stack>
                                )
                              })}
                          </Grid>
                        </Fragment>
                      </Grid>
                      <Divider
                        sx={{
                          marginTop: 1,
                        }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Typography variant={'h5'}>
                        {t('AUTOMATIC_OFFERS.SELECT_AN_AREA')}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => hide()}
                  variant={'outlined'}
                  color={'secondary'}
                >
                  {t('GENERAL.CANCEL')}
                </Button>
                <Button
                  onClick={() => submitForm()}
                  variant={'contained'}
                  color={'secondary'}
                >
                  {t('GENERAL.SUBMIT')}
                </Button>
              </DialogActions>
            </Fragment>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default withTranslation()(ProductPriceLimitZipCodeAreaDialog)
