import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material'
import { Field, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'
import { countryList } from '../../_constants/lookupConstants'
import { authorize } from '../../_helpers/authorization'
import { isOfferDeliveryInvalid } from '../../_helpers/little'
import useOCIPunchout from '../../_hooks/useOCIPunchout'
import { _googleService } from '../../_services/_googleService'
import { offersService } from '../../_services/offersService'
import DeliveryTimes from '../DeliveryTimes'
import InvoiceAddress from '../InvoiceAddress'
import { Loading } from '../Loading'
import { Section } from '../Section'

const AcceptOfferDialog = (props) => {
  const { t, selectedOffer, hide, forceUpdate } = props

  const userObject = JSON.parse(localStorage.getItem('user'))

  const [recentOffer, setRecentOffer] = useState(null)

  const [isLoading, setIsLoading] = useState(true)

  const [error, setError] = useState('')

  const [initialValues, setInitialValues] = useState({
    archiveRequest: true,
    orderNumber: '',
    customInvoiceAddress: false,
    countryId: '',
    deliveryTimes: [
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: false,
        start: null,
        end: null,
      },
      {
        isActive: false,
        start: null,
        end: null,
      },
    ],
  })

  let navigate = useNavigate()

  const { punchout } = useOCIPunchout()

  useEffect(() => {
    offersService
      .getOffer(selectedOffer.id, userObject.partner.id)
      .then((offer) => {
        setRecentOffer(offer)
        if (offer.request.type !== 'TENDER') {
          setInitialValues({
            ...initialValues,
            address:
              offer.generalInformation.deliveryDetails.locations[0].address,
            company:
              offer.generalInformation.deliveryDetails.locations[0].company,
            zipcode:
              offer.generalInformation.deliveryDetails.locations[0].zipcode,
            place: offer.generalInformation.deliveryDetails.locations[0].place,
            countryId:
              offer.generalInformation.deliveryDetails.locations[0].countryId,
            deliveryTimes:
              offer.generalInformation.deliveryDetails.locations[0]
                .deliveryTimes,
            isDespatchAdviceRequired:
              offer.generalInformation.deliveryDetails.locations[0]
                .isDespatchAdviceRequired,
            despatchAdviceInstructions: offer.generalInformation.deliveryDetails
              .locations[0].despatchAdviceInstructions
              ? offer.generalInformation.deliveryDetails.locations[0]
                  .despatchAdviceInstructions
              : '',
          })
        }

        setIsLoading(false)
      })
  }, [selectedOffer])

  if (!recentOffer) {
    return null
  }

  const handleSubmit = async (archiveRequest, formikProps) => {
    const { submitForm, setFieldValue } = formikProps

    await setFieldValue('archiveRequest', archiveRequest)
    await submitForm()
  }

  let hookURL = sessionStorage.getItem('HOOK_URL')

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object()
        .shape(
          selectedOffer.requestType === 'TENDER'
            ? undefined
            : {
                orderNumber: Yup.string().nullable(),
                address: Yup.mixed().when('incoTerm', ([incoTerm], schema) => {
                  return recentOffer.generalInformation.deliveryDetails
                    .incoTerm === 'EXW'
                    ? Yup.mixed().nullable()
                    : Yup.string().required(t('GENERAL.REQUIRED'))
                }),
                company: Yup.mixed().when('incoTerm', ([incoTerm], schema) => {
                  return recentOffer.generalInformation.deliveryDetails
                    .incoTerm === 'EXW'
                    ? Yup.mixed().nullable()
                    : Yup.string().required(t('GENERAL.REQUIRED'))
                }),
                partnerInvoiceLocationId: Yup.number().required(
                  t('GENERAL.REQUIRED'),
                ),
              },
        )
        .test({
          test: async function (orderDetails, context) {
            if (
              !orderDetails.address ||
              selectedOffer.incoTerm === 'EXW' ||
              selectedOffer.requestType === 'TENDER' ||
              initialValues.countryId !== 80
            ) {
              return true
            }
            let validationResult =
              await _googleService.validateAddressOfLocation({
                address: orderDetails.address,
                zipcode: initialValues.zipcode,
                place: initialValues.place,
              })
            if (validationResult.address.missingComponentTypes) {
              for (
                let index = 0;
                index < validationResult.address.missingComponentTypes.length;
                index++
              ) {
                const missingComponentType =
                  validationResult.address.missingComponentTypes[index]

                switch (missingComponentType) {
                  case 'route':
                    return this.createError({
                      message: t('LITTLE.ADDRESS_MISSING'),
                      path: `address`,
                    })
                  case 'street_number':
                    return this.createError({
                      message: t('LITTLE.STREET_NUMBER_MISSING'),
                      path: `address`,
                    })
                  default:
                    break
                }
              }
            }
          },
        })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting = true
        setIsLoading(true)
        offersService
          .acceptOffer(recentOffer.id, {
            revision: recentOffer.revision,
            orderNumber: values.orderNumber,
            archiveRequest: values.archiveRequest,
            address: values.address,
            company: values.company,
            deliveryTimes: values.deliveryTimes,
            isDespatchAdviceRequired: values.isDespatchAdviceRequired,
            despatchAdviceInstructions: values.isDespatchAdviceRequired
              ? values.despatchAdviceInstructions
              : undefined,
            partnerInvoiceLocationId: values.partnerInvoiceLocationId,
          })
          .then((data) => {
            if (hookURL && !authorize('administrator')) {
              punchout(
                hookURL,
                recentOffer.positions,
                recentOffer.stackHeight,
                recentOffer.stackHeightUnit,
                recentOffer.request.id,
                data.orderIds,
              )
            }

            navigate(
              selectedOffer.requestType !== 'TENDER'
                ? '/deliveries-customer'
                : '/contracts',
            )
          })
          .catch((err) => {
            if (err === 'Revision mismatch') {
              setError(t('OFFER.REVISION_MISMATCH'))
            } else if (err === 'Offer not active') {
              setError(t('OFFER.OFFER_NO_LONGER_ACTIVE'))
            } else {
              setError(err)
            }
          })
          .finally(() => {
            setSubmitting = false
            setIsLoading(false)
          })
      }}
    >
      {({ values, isValid, submitForm, setFieldValue, isSubmitting }) => {
        let isDeliveryInvalid =
          (selectedOffer.requestType === 'REQUEST' ||
            selectedOffer.requestType === 'ADVERT') &&
          isOfferDeliveryInvalid(recentOffer, selectedOffer.requestType)
        return (
          <Fragment>
            <Dialog open={true} fullWidth={true} maxWidth={'md'}>
              <DialogTitle>{t('OFFER.ACCEPT_OFFER_DIALOG_TITLE')}</DialogTitle>

              {isLoading ? (
                <DialogContent>
                  <Loading variant={'centered'} />
                </DialogContent>
              ) : error ? (
                <Fragment>
                  <DialogContent>
                    <Alert severity="error">{error}</Alert>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      onClick={() => {
                        forceUpdate()
                        hide()
                      }}
                    >
                      {t('GENERAL.BACK')}
                    </Button>
                  </DialogActions>
                </Fragment>
              ) : (
                <Fragment>
                  {isDeliveryInvalid ? (
                    <DialogContent>{t('OFFER.DELIVERY_INVALID')}</DialogContent>
                  ) : (
                    <DialogContent>
                      {selectedOffer.requestType !== 'TENDER' && (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Field
                              name="orderNumber"
                              label={t('OFFER.INTERNAL_ORDERNUMBER')}
                              component={TextField}
                              type="text"
                              fullWidth={true}
                              margin="normal"
                            />
                          </Grid>
                        </Grid>
                      )}

                      {selectedOffer.requestType !== 'TENDER' && (
                        <Grid container spacing={1}>
                          {recentOffer.generalInformation.deliveryDetails
                            .incoTerm === 'DDP' && (
                            <Section header={t('OFFER.DELIVERY_ADDRESS')}>
                              <Grid item xs={12}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={6}>
                                    <Field
                                      component={TextField}
                                      label={t(
                                        'REQUEST.DELIVERY_DETAILS.COMPANY',
                                      )}
                                      name={'company'}
                                      variant="outlined"
                                      margin="dense"
                                      fullWidth
                                      disabled={
                                        userObject.user.partnerLocationIds
                                          .length > 0
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Field
                                      component={TextField}
                                      label={t(
                                        'REQUEST.DELIVERY_DETAILS.COUNTRY',
                                      )}
                                      disabled={true}
                                      name={'countryId'}
                                      variant="outlined"
                                      margin="dense"
                                      fullWidth
                                      select
                                    >
                                      {Object.entries(countryList).map(
                                        (country) => (
                                          <MenuItem
                                            value={country[1]}
                                            key={country[0]}
                                          >
                                            {country[0]}
                                          </MenuItem>
                                        ),
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Field
                                      component={TextField}
                                      label={t(
                                        'REQUEST.DELIVERY_DETAILS.ADDRESS',
                                      )}
                                      name={'address'}
                                      variant="outlined"
                                      margin="dense"
                                      fullWidth
                                      disabled={
                                        userObject.user.partnerLocationIds
                                          .length > 0
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Field
                                      component={TextField}
                                      label={t(
                                        'REQUEST.DELIVERY_DETAILS.ZIP_CODE',
                                      )}
                                      disabled={true}
                                      name={'zipcode'}
                                      variant="outlined"
                                      margin="dense"
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <Field
                                      component={TextField}
                                      label={t(
                                        'REQUEST.DELIVERY_DETAILS.PLACE',
                                      )}
                                      disabled={true}
                                      name={'place'}
                                      variant="outlined"
                                      margin="dense"
                                      fullWidth
                                    />
                                  </Grid>
                                  <Grid item xs={12} spacing={1}>
                                    <Typography variant="caption">
                                      {t('DELIVERY_TIMES.LOADING_TIMES')}
                                    </Typography>
                                    <DeliveryTimes
                                      data={values.deliveryTimes}
                                      formikName={'deliveryTimes'}
                                      setFieldValue={setFieldValue}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="caption">
                                      {t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
                                    </Typography>
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Field
                                          component={CheckboxWithLabel}
                                          type={'checkbox'}
                                          color="default"
                                          name={'isDespatchAdviceRequired'}
                                          Label={{
                                            label: t(
                                              'DELIVERY_DETAILS.IS_DESPATCH_ADVICE_REQUIRED',
                                            ),
                                          }}
                                          style={{ marginLeft: '15px' }}
                                          disabled={
                                            userObject.user.partnerLocationIds
                                              .length > 0
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Field
                                          component={TextField}
                                          disabled={
                                            !values.isDespatchAdviceRequired ||
                                            userObject.user.partnerLocationIds
                                              .length > 0
                                          }
                                          name={'despatchAdviceInstructions'}
                                          fullWidth
                                          margin="dense"
                                          label={t(
                                            'DELIVERY_DETAILS.DESPATCH_ADVICE',
                                          )}
                                          variant="outlined"
                                          multiline
                                          rows="4"
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Section>
                          )}
                          <Grid item xs={12}>
                            <InvoiceAddress
                              partnerId={userObject.partner.id}
                              data={values}
                              setFieldValue={setFieldValue}
                              userId={userObject.user.id}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Alert severity="info">{t('OFFER.ARCHIVE_INFO')}</Alert>
                    </DialogContent>
                  )}
                  <DialogActions>
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      onClick={() => {
                        hide()
                      }}
                    >
                      {t('GENERAL.CANCEL')}
                    </Button>
                    {!isDeliveryInvalid && (
                      <Button
                        variant={
                          selectedOffer.requestType === 'ADVERT'
                            ? 'contained'
                            : 'outlined'
                        }
                        color="secondary"
                        disabled={isSubmitting}
                        onClick={() => {
                          handleSubmit(false, {
                            submitForm,
                            setFieldValue,
                          })
                        }}
                      >
                        {hookURL
                          ? t('OFFERS.PUNCHOUT')
                          : t('OFFERS.ACCEPT_BINDING_OFFER')}
                      </Button>
                    )}
                    {selectedOffer.requestType !== 'ADVERT' &&
                      !isDeliveryInvalid && (
                        <Button
                          variant="contained"
                          color="secondary"
                          disabled={isSubmitting}
                          onClick={() => {
                            handleSubmit(true, {
                              submitForm,
                              setFieldValue,
                            })
                          }}
                        >
                          {hookURL
                            ? t('OFFERS.PUNCHOUT_AND_ARCHIVE_REQUEST')
                            : t('OFFERS.ACCEPT_AND_ARCHIVE_REQUEST')}
                        </Button>
                      )}
                  </DialogActions>
                </Fragment>
              )}
            </Dialog>
          </Fragment>
        )
      }}
    </Formik>
  )
}

export default withTranslation()(AcceptOfferDialog)
