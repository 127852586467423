import { Delete } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import React, { Fragment, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { invoicingTypeLookup } from '../../../../_constants/lookupConstants'
import NumberFormatCustom from '../../../../_helpers/numberFormatCustom'
import { invoicesService } from '../../../../_services/invoicesService'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { FieldErrorWrapper } from '../../../FieldErrorWrapper'
import { Loading } from '../../../Loading'
import OrderPaymentDetailsView from '../../../OrderPaymentDetailsView'

const InvoicingCreateDialog = (props) => {
  const { t, order, deliveryNote, show, hide, forceUpdate } = props

  useEffect(() => {}, [])

  const [isLoading, setIsLoading] = React.useState(false)

  const valSchema = Yup.object().shape({
    invoices: Yup.array().of(
      Yup.object().shape({
        invoiceNumber: Yup.string().nullable(),
        invoiceDate: Yup.date()
          .typeError(t('GENERAL.INVALID_DATE'))
          .required(t('GENERAL.REQUIRED')),
        description: Yup.mixed().when(
          ['direction', 'type'],
          ([direction, type], schema) => {
            return direction === 'OUTBOUND' && type === 'OTHER'
              ? Yup.string()
                  .typeError(t('GENERAL.REQUIRED'))
                  .required(t('GENERAL.REQUIRED'))
              : Yup.mixed().nullable(true)
          },
        ),
        direction: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        type: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        price: Yup.number()
          .required(t('GENERAL.REQUIRED'))
          .typeError(t('GENERAL.REQUIRED')),
        comment: Yup.string().nullable(),
      }),
    ),
  })

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Formik
        enableReinitialize={true}
        initialValues={{
          invoices: [
            {
              invoiceNumber: '',
              price: '',
              type: 'PROVISION',
              direction: 'INBOUND',
              comment: '',
              invoiceDate: moment(),
            },
          ],
        }}
        validationSchema={valSchema}
        //validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting = true

          invoicesService
            .createInvoices(
              values.invoices.map((invoice) => {
                let invoiceDate = moment(invoice.invoiceDate).format(
                  'YYYY-MM-DD',
                )

                return {
                  ...invoice,
                  deliveryNoteId: deliveryNote ? deliveryNote.id : undefined,
                  orderId: order.id,
                  date: invoiceDate,
                }
              }),
            )
            .then((data) => {
              hide()
              forceUpdate()
            })
            .catch(function (error) {
              console.log(error)
            })
            .finally(() => {
              setSubmitting = false
              setIsLoading(false)
            })
        }}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          submitForm,
          isValid,
          errors,
          touched,
        }) => (
          <Dialog fullWidth={true} maxWidth="xl" open={show}>
            <CustomDialogTitle
              title={'ORDERS.INVOICING.INVOICING'}
              onClose={() => hide()}
            />
            <DialogContent>
              <Form>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <OrderPaymentDetailsView data={order} />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant={'contained'}
                      color={'secondary'}
                      onClick={() =>
                        setFieldValue(
                          'invoices',
                          values.invoices.concat([
                            {
                              invoiceNumber: '',
                              price: '',
                              type: 'PROVISION',
                              direction: 'INBOUND',
                              comment: '',
                            },
                          ]),
                        )
                      }
                    >
                      {t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.ADD_INVOICE')}
                    </Button>
                  </Grid>
                  {values.invoices.map((invoice, index) => (
                    <Fragment>
                      <Grid item xs={1.5}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t(
                            'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.INVOICE_NUMBER',
                          )}
                          variant="outlined"
                          name={`invoices[${index}].invoiceNumber`}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <FieldErrorWrapper
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              label: t('INVOICE_DRAFT.INVOICE_DATE'),
                              margin: 'dense',
                            },
                          }}
                          component={DatePicker}
                          name={`invoices[${index}].invoiceDate`}
                          inputFormat="DD.MM.YYYY"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Field
                          fullWidth={true}
                          component={TextField}
                          margin="dense"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{
                            inputComponent: NumberFormatCustom,
                            decimalScale: 2,
                            allowNegative: true,
                            suffix: ' \u20AC',
                            fixedDecimalScale: true,
                          }}
                          label={t(
                            'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.PRICE',
                          )}
                          variant="outlined"
                          name={`invoices[${index}].price`}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Field
                          component={TextField}
                          select
                          fullWidth
                          margin="dense"
                          label={t(
                            'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.DIRECTION',
                          )}
                          variant="outlined"
                          name={`invoices[${index}].direction`}
                          onChange={(e) => {
                            setFieldValue(
                              `invoices[${index}].direction`,
                              e.target.value,
                            )
                          }}
                        >
                          <MenuItem key="INBOUND" value="INBOUND">
                            {t(
                              'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.INBOUND',
                            )}
                          </MenuItem>
                          <MenuItem key="OUTBOUND" value="OUTBOUND">
                            {t(
                              'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.OUTBOUND',
                            )}
                          </MenuItem>
                        </Field>
                      </Grid>
                      <Grid item xs={2}>
                        <Field
                          component={TextField}
                          select
                          fullWidth
                          margin="dense"
                          label={t(
                            'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.TYPE',
                          )}
                          variant="outlined"
                          name={`invoices[${index}].type`}
                        >
                          {Object.entries(invoicingTypeLookup)
                            .filter((invoiceType) =>
                              deliveryNote
                                ? true
                                : invoiceType[0] !== 'POSITION',
                            )
                            .map((invoiceType) => (
                              <MenuItem
                                key={invoiceType[0]}
                                value={invoiceType[0]}
                              >
                                {invoiceType[1]}
                              </MenuItem>
                            ))}
                        </Field>
                      </Grid>
                      {values.invoices[index].type === 'OTHER' &&
                        values.invoices[index].direction === 'OUTBOUND' && (
                          <Grid item xs={2}>
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t(
                                'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.DESCRIPTION',
                              )}
                              variant="outlined"
                              name={`invoices[${index}].description`}
                            />
                          </Grid>
                        )}
                      <Grid
                        item
                        xs={
                          values.invoices[index].type === 'OTHER' &&
                          values.invoices[index].direction === 'OUTBOUND'
                            ? 1
                            : 3
                        }
                      >
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t(
                            'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.COMMENT',
                          )}
                          variant="outlined"
                          name={`invoices[${index}].comment`}
                        />
                      </Grid>
                      <Grid item xs={0.5}>
                        <IconButton
                          onClick={() => {
                            let newValues = [...values.invoices]
                            newValues.splice(index, 1)
                            setFieldValue('invoices', newValues)
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => hide()}
                variant="outlined"
                color={'secondary'}
              >
                {t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.CANCEL')}
              </Button>
              <Button
                onClick={() => submitForm()}
                disabled={!isValid || isSubmitting}
                type="submit"
                variant="contained"
                color="secondary"
              >
                {t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.CONFIRM')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    )
  )
}

export default withTranslation()(InvoicingCreateDialog)
