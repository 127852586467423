import Rating from '@mui/material/Rating'
import { styled } from '@mui/material/styles'
import React from 'react'

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.palette.accent1,
  },
  '& .MuiRating-iconHover': {
    color: theme.palette.accent1,
  },
}))

export const CustomRating = (props) => {
  return <StyledRating {...props} />
}
