import React from 'react'
import CalendarWeekPicker from '../../CalendarWeekPicker'

const FilterCalendarWeekPicker = (props) => {
  const { filterHandler, filter, setFilterHandler } = props
  let index = filterHandler.findIndex((handler) => handler.key === filter.key)

  return (
    <CalendarWeekPicker
      enablePast={true}
      margin={'dense'}
      selectedDays={filterHandler[index].value}
      setSelectedDays={(days) => {
        let newFilterHandler = [...filterHandler]
        newFilterHandler[index].value = days
        setFilterHandler(newFilterHandler)
      }}
    />
  )
}

export default FilterCalendarWeekPicker
