import MaterialTable from '@material-table/core'
import { Add, Download, Edit, Remove } from '@mui/icons-material'
import { Button, Dialog, DialogContent } from '@mui/material'
import moment from 'moment'
import Numeral from 'numeral'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  invoicingDirectionLookup,
  invoicingTypeLookup,
} from '../../_constants/lookupConstants'
import { authorize } from '../../_helpers/authorization'
import { tableIcons, tableLocalization } from '../../_helpers/muiTable'
import { invoicesService } from '../../_services/invoicesService'
import CustomDialogTitle from '../CustomDialogTitle'
import { Loading } from '../Loading'
import EditInvoiceDialog from './_components/EditInvoiceDialog'
import InvoicingCreateDialog from './_components/InvoicingCreateDialog'
import InvoicingCreateInitialDialog from './_components/InvoicingCreateInitalDialog'

const InvoicingDialog = (props) => {
  const { t, order, deliveryNote, show, hide } = props

  const [isLoading, setIsLoading] = useState(true)

  const [invoices, setInvoices] = useState([])

  const [showCreateInvoiceDialog, setShowCreateInvoiceDialog] = useState(false)

  const [
    showInvoicingCreateInitialDialog,
    setShowInvoicingCreateInitialDialog,
  ] = useState(false)

  const [
    invoicingCreateInitialDialogMode,
    setInvoicingCreateInitialDialogMode,
  ] = useState()

  const [forceUpdateCount, forceUpdate] = useState(0)

  const userObject = JSON.parse(localStorage.getItem('user'))

  const [showEditInvoiceDialog, setShowEditInvoiceDialog] = useState(false)
  const [selectedInvoice, setSeletedInvoice] = useState({})

  useEffect(() => {
    setIsLoading(true)
    if (deliveryNote) {
      invoicesService
        .getInvoicesOfDeliveryNote(deliveryNote.id)
        .then((data) => {
          setInvoices(data)
          setIsLoading(false)
        })
    } else {
      invoicesService.getInvoicesOfOrder(order.id).then((data) => {
        setInvoices(data)
        setIsLoading(false)
      })
    }
  }, [show, forceUpdateCount, deliveryNote, order?.id])
  return isLoading ? (
    <Loading variant={'centered'} />
  ) : (
    <Fragment>
      {!deliveryNote || invoices.length > 0 ? (
        <Dialog fullWidth={true} maxWidth="xl" open={show}>
          <CustomDialogTitle
            title={t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.TITLE')}
            onClose={() => hide()}
          />
          {
            <DialogContent>
              {authorize('administrator') && (
                <div style={{ marginBottom: 10 }}>
                  <Button
                    size="medium"
                    color={'secondary'}
                    variant={'contained'}
                    startIcon={<Add />}
                    onClick={() => {
                      setShowCreateInvoiceDialog(true)
                    }}
                  >
                    {t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.NEW_INVOICING')}
                  </Button>
                </div>
              )}
              {order ? (
                <Fragment>
                  <MaterialTable
                    icons={tableIcons}
                    localization={tableLocalization()}
                    columns={[
                      {
                        title: '#',
                        field: 'id',
                        defaultSort: 'asc',
                        hidden: !authorize('administrator'),
                      },
                      {
                        title: t(
                          'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.INVOICE_NUMBER',
                        ),
                        field: 'invoiceNumber',
                      },
                      {
                        title: t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.DATE'),
                        field: 'date',
                        render: (invoice) =>
                          invoice.date
                            ? moment(invoice.date)
                                .set({ hour: 12, minute: 0 })
                                .format('DD.MM.YYYY')
                            : 'N/A',
                      },
                      {
                        title: t(
                          'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.DIRECTION',
                        ),
                        field: 'direction',
                        lookup: invoicingDirectionLookup,
                        hidden: !authorize('administrator'),
                      },
                      {
                        title: t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.TYPE'),
                        field: 'type',
                        lookup: invoicingTypeLookup,
                        hidden: !authorize('administrator'),
                      },
                      {
                        title: t(
                          'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.PRICE',
                        ),
                        field: 'price',
                        render: (invoice) =>
                          Numeral(parseFloat(invoice.price)).format('0,0.00 $'),
                      },
                      {
                        title: t(
                          'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.DESCRIPTION',
                        ),
                        field: 'description',
                      },
                      {
                        title: t(
                          'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.COMMENT',
                        ),
                        field: 'comment',
                      },
                    ]}
                    data={invoices}
                    options={{
                      toolbarButtonAlignment: 'left',
                      toolbar: order.customerId !== userObject.partner.id,
                      showTitle: false,
                      search: false,
                      paging: true,
                      pageSize: 10,
                      padding: 'dense',
                      actionsColumnIndex: -1,
                      filterCellStyle: {
                        maxWidth: 0,
                      },
                      headerStyle: {
                        fontWeight: 'bold',
                      },
                    }}
                    style={{ boxShadow: 'none' }}
                    actions={[
                      (rowData) => ({
                        icon: () => (
                          <Download
                            color={
                              rowData.invoiceNumber ? 'secondary' : 'disabled'
                            }
                          />
                        ),
                        tooltip: rowData.invoiceNumber
                          ? t(
                              'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.DOWNLOAD_INVOICE',
                            )
                          : t('ORDERS.DELIVERY_NOTES.NOT_AVAILABLE_YET'),
                        onClick: (event, rowData) => {
                          invoicesService
                            .getInvoice(rowData.id)
                            .then((response) => response.blob())
                            .then((blob) => {
                              // 2. Create blob link to download
                              const url = window.URL.createObjectURL(
                                new Blob([blob]),
                              )
                              const link = document.createElement('a')
                              link.href = url
                              link.setAttribute(
                                'download',
                                'invoice-' + rowData.invoiceNumber + '.pdf',
                              )
                              link.setAttribute('target', '_blank')
                              // 3. Append to html page
                              document.body.appendChild(link)
                              // 4. Force download
                              link.click()
                              // 5. Clean up and remove the link
                              link.parentNode.removeChild(link)
                            })
                        },
                        disabled: !rowData.invoiceNumber,
                        hidden:
                          rowData.direction === 'INBOUND' &&
                          !authorize('administrator'),
                      }),
                      (rowData) => ({
                        icon: () => (
                          <Edit
                            color={
                              rowData.invoiceNumber ? 'secondary' : 'disabled'
                            }
                          />
                        ),
                        tooltip: rowData.invoiceNumber
                          ? t(
                              'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.UPDATE_INVOICE',
                            )
                          : t('ORDERS.DELIVERY_NOTES.NOT_AVAILABLE_YET'),
                        onClick: async (event, rowData) => {
                          setSeletedInvoice(rowData)
                          setShowEditInvoiceDialog(true)
                        },
                        disabled: !rowData.invoiceNumber,
                        hidden: !authorize('administrator'),
                      }),
                      (rowData) => ({
                        icon: () => (
                          <Add
                            color={
                              rowData.invoiceNumber &&
                              rowData.direction === 'OUTBOUND' &&
                              deliveryNote
                                ? 'secondary'
                                : 'disabled'
                            }
                          />
                        ),
                        tooltip:
                          rowData.invoiceNumber &&
                          rowData.direction === 'OUTBOUND' &&
                          deliveryNote
                            ? t(
                                'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.ADD_INVOICE',
                              )
                            : t('ORDERS.DELIVERY_NOTES.NOT_AVAILABLE_YET'),
                        onClick: async (event, rowData) => {
                          setSeletedInvoice(rowData)
                          setInvoicingCreateInitialDialogMode('invoice')
                          setShowInvoicingCreateInitialDialog(true)
                        },
                        disabled:
                          !rowData.invoiceNumber ||
                          rowData.direction !== 'OUTBOUND' ||
                          !deliveryNote,
                        hidden: !authorize('administrator'),
                      }),
                      (rowData) => ({
                        icon: () => (
                          <Remove
                            color={
                              rowData.invoiceNumber &&
                              rowData.direction === 'OUTBOUND' &&
                              deliveryNote
                                ? 'secondary'
                                : 'disabled'
                            }
                          />
                        ),
                        tooltip:
                          rowData.invoiceNumber &&
                          rowData.direction === 'OUTBOUND' &&
                          deliveryNote
                            ? t(
                                'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.ADD_INVOICE_CORRECTION',
                              )
                            : t('ORDERS.DELIVERY_NOTES.NOT_AVAILABLE_YET'),
                        onClick: async (event, rowData) => {
                          setSeletedInvoice(rowData)
                          setInvoicingCreateInitialDialogMode(
                            'invoice_correction',
                          )
                          setShowInvoicingCreateInitialDialog(true)
                        },
                        disabled:
                          !rowData.invoiceNumber ||
                          rowData.direction !== 'OUTBOUND' ||
                          !deliveryNote,
                        hidden: !authorize('administrator'),
                      }),
                    ]}
                  />
                </Fragment>
              ) : null}
            </DialogContent>
          }
        </Dialog>
      ) : (
        <InvoicingCreateInitialDialog
          deliveryNote={deliveryNote}
          mode={'initial'}
          show={show}
          hide={hide}
          key={deliveryNote.id}
        />
      )}
      {deliveryNote?.id && (
        <InvoicingCreateInitialDialog
          deliveryNote={deliveryNote}
          refInvoiceNumber={selectedInvoice.invoiceNumber}
          refInvoiceDate={moment(selectedInvoice.date)
            .set({ hour: 12, minute: 0 })
            .format('DD.MM.YYYY')}
          show={showInvoicingCreateInitialDialog}
          mode={invoicingCreateInitialDialogMode}
          hide={() => setShowInvoicingCreateInitialDialog(false)}
          key={deliveryNote.id + 1}
          forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
        />
      )}
      <InvoicingCreateDialog
        order={order}
        deliveryNote={deliveryNote}
        show={showCreateInvoiceDialog}
        hide={() => setShowCreateInvoiceDialog(false)}
        forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
      />
      {selectedInvoice && (
        <EditInvoiceDialog
          key={selectedInvoice.id}
          invoice={selectedInvoice}
          show={showEditInvoiceDialog}
          hide={() => setShowEditInvoiceDialog(false)}
          forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
        />
      )}
    </Fragment>
  )
}

export default withTranslation()(InvoicingDialog)
