import {
  ArrowDropDown,
  Close,
  Delete,
  ExpandMore,
  FilterAlt,
  Save,
} from '@mui/icons-material'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Popover,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { debounce } from 'lodash'
import moment from 'moment'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import isEqual from 'react-fast-compare'
import { withTranslation } from 'react-i18next'
import { filtersService } from '../../_services/filtersService'
import AgreementDialog from '../AgreementDialog'
import { InfoTooltip } from '../InfoTooltip'
import CategorizedFilterCheckBoxList from './_components/CategorizedCheckBoxList'
import FilterAutocomplete from './_components/FilterAutoComplete'
import FilterCalendarWeekPicker from './_components/FilterCalendarWeekPicker'
import FilterCheckBoxList from './_components/FilterCheckBoxList'
import FilterGeo from './_components/FilterGeo'
import SaveFilterDialog from './_components/SaveFilterDialog'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const Filter = (props) => {
  const { t, filters, setFilterValues, saveFilterValues, id, map, setGeoData } =
    props

  const [filterHandler, setFilterHandler] = React.useState(() => {
    let atLeastOneDefaultFilterExists = filters.some(
      (filter) => filter.defaultValue,
    )
    let filterStorage = JSON.parse(localStorage.getItem(`filter-${id}`))

    if (filterStorage && filterStorage.geo) {
      setGeoData(filterStorage.geo.origin, filterStorage.geo.radius)
    }

    return filters.map((filter) => {
      let value = null
      if (atLeastOneDefaultFilterExists && filter.defaultValue) {
        value = filter.defaultValue
      } else if (
        !atLeastOneDefaultFilterExists &&
        saveFilterValues &&
        filterStorage &&
        filterStorage[filter.key]
      ) {
        value =
          filter.variant === 'calendarWeekPicker'
            ? filterStorage[filter.key].map((date) => new Date(date))
            : filterStorage[filter.key]
      } else {
        value =
          filter.variant === 'calendarWeekPicker' ||
          filter.variant === 'checkboxList' ||
          filter.variant === 'categorizedCheckboxList'
            ? []
            : null
      }

      return {
        key: filter.key,
        value: value,
      }
    })
  })

  const [showFilterDrawer, setShowFilterDrawer] = useState(false)

  const [expanded, setExpanded] = React.useState(false)

  const [showSaveFilterDialog, setShowSaveFilterDialog] = useState(false)

  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null)

  const [savedFilterAnchorEl, setSavedFilterAnchorEl] = React.useState(null)

  const [savedFilters, setSavedFilters] = React.useState([])

  const [showDeleteFilterDialog, setShowDeleteFilteDialog] =
    React.useState(false)

  const [selectedFilter, setSelectedFilter] = React.useState(null)

  const filterValues = useMemo(() => {
    if (!props.filterValues || Object.keys(props.filterValues).length === 0) {
      // setFilterValues({})
      return
    }

    let differenceExists = false
    let tempFilterHandler = filterHandler.map((handler) => {
      if (
        props.filterValues[handler.key] &&
        !isEqual(handler.value, props.filterValues[handler.key])
      ) {
        differenceExists = true
        return { ...handler, value: props.filterValues[handler.key] }
      } else {
        return handler
      }
    })
    if (differenceExists) {
      setFilterHandler(tempFilterHandler)

      let geoValue = tempFilterHandler.find(
        (handler) => handler.key === 'geo',
      )?.value

      if (geoValue) {
        setGeoData(geoValue.origin, geoValue.radius)
      }
    }

    return
  }, [props.filterValues])

  const handleChange = (index) => (event, isExpanded) => {
    setExpanded(isExpanded ? index : false)
  }

  const clearAllFilters = () => {
    let newFilterHandler = [...filterHandler]

    newFilterHandler = newFilterHandler.map((handler, index) => {
      let matchingFilter = filters[index]

      return {
        ...handler,
        value:
          matchingFilter.variant === 'calendarWeekPicker' ||
          matchingFilter.variant === 'checkboxList' ||
          matchingFilter.variant === 'categorizedCheckboxList'
            ? []
            : '',
      }
    })
    setFilterHandler(newFilterHandler)
    if (setGeoData) {
      setGeoData(null, null)
    }
  }

  const clearFilter = (key) => {
    let newFilterHandler = [...filterHandler]

    let index = newFilterHandler.findIndex((handler) => handler.key === key)
    let matchingFilter = filters[index]

    newFilterHandler[index].value =
      matchingFilter.variant === 'calendarWeekPicker' ||
      matchingFilter.variant === 'checkboxList' ||
      matchingFilter.variant === 'categorizedCheckboxList'
        ? []
        : ''
    setFilterHandler(newFilterHandler)

    if (matchingFilter.variant === 'geo') {
      setGeoData(null, null)
    }
  }

  const handleClickSavedFilters = (event) => {
    setSavedFilterAnchorEl(event.currentTarget)
  }

  const userObject = JSON.parse(localStorage.getItem('user'))

  const theme = useTheme()

  useEffect(() => {
    filtersService.getFiltersOfUser(userObject.user.id).then((filters) => {
      setSavedFilters(filters.filter((filter) => filter.filterId === id))
    })
  }, [])

  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setFilterValues(nextValue)
    }, 700),
    [],
  )

  useEffect(() => {
    let tempFilterValues = {}
    filters.forEach((filter) => {
      let index = filterHandler.findIndex(
        (handler) => filter.key === handler.key,
      )

      tempFilterValues[filter.key] = filterHandler[index].value
    })
    if (saveFilterValues) {
      localStorage.setItem(`filter-${id}`, JSON.stringify(tempFilterValues))
    }

    if (!isEqual(tempFilterValues, filterValues)) {
      debouncedSave(tempFilterValues)
    }
  }, [filterHandler])

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Fragment>
      <ButtonGroup>
        {filters
          .filter((filter) => !isMobile && filter.shortcut)
          .map((filter) => {
            let index = filterHandler.findIndex(
              (handler) => handler.key === filter.key,
            )

            return (
              <Button
                variant={'text'}
                color={'secondary'}
                size={'small'}
                key={`filter-button-${index}`}
                onClick={(e) => {
                  setFilterAnchorEl({
                    element: e.currentTarget,
                    key: filter.key,
                  })
                }}
                endIcon={<ArrowDropDown />}
              >
                {
                  <span sx={{ overflowWrap: 'break-word' }}>
                    {filter.label}
                  </span>
                }
              </Button>
            )
          })}
        <Button
          startIcon={<FilterAlt />}
          variant={'outlined'}
          color={'secondary'}
          size={'small'}
          onClick={() => setShowFilterDrawer(true)}
        >
          {t('FILTER.FILTER')}
        </Button>
        <Button
          variant={'outlined'}
          color={'secondary'}
          size={'small'}
          onClick={handleClickSavedFilters}
        >
          <ArrowDropDown />
        </Button>
        {filterHandler.some((handler) => {
          let matchingFilter = filters.find(
            (filter) => filter.key === handler.key,
          )
          return (
            ((matchingFilter.variant === 'calendarWeekPicker' ||
              matchingFilter.variant === 'checkboxList' ||
              matchingFilter.variant === 'categorizedCheckboxList') &&
              handler.value.length > 0) ||
            ((matchingFilter.variant === 'geo' ||
              matchingFilter.variant === 'textfield' ||
              matchingFilter.variant === 'autocomplete') &&
              handler.value)
          )
        }) ? (
          <Button
            variant={'contained'}
            color={'secondary'}
            size={'small'}
            onClick={() => {
              clearAllFilters()
            }}
          >
            {t('FILTER.CLEAR_ALL_FILTERS_SHORT')}
          </Button>
        ) : null}
      </ButtonGroup>

      <Popover
        open={Boolean(savedFilterAnchorEl)}
        anchorEl={savedFilterAnchorEl}
        onClose={() => setSavedFilterAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
              startIcon={<Save />}
              color="secondary"
              size="small"
              variant="contained"
              disabled={
                !filterHandler.some((handler) => {
                  let matchingFilter = filters.find(
                    (filter) => filter.key === handler.key,
                  )
                  return (
                    ((matchingFilter.variant === 'calendarWeekPicker' ||
                      matchingFilter.variant === 'checkboxList' ||
                      matchingFilter.variant === 'categorizedCheckboxList') &&
                      handler.value.length > 0) ||
                    ((matchingFilter.variant === 'geo' ||
                      matchingFilter.variant === 'textfield' ||
                      matchingFilter.variant === 'autocomplete') &&
                      handler.value)
                  )
                })
              }
              onClick={() => {
                setShowSaveFilterDialog(true)
                setSavedFilterAnchorEl(null)
              }}
            >
              {t('FILTER.SAVE')}
            </Button>
          </Grid>

          {savedFilters.length === 0 ? (
            <Typography sx={{ padding: 2 }}>
              {t('FILTER.NO_FILTER_SAVED_YET')}
            </Typography>
          ) : (
            <Fragment>
              {savedFilters
                .filter((filter) => filter.userId === userObject.user.id)
                .map((filter) => (
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardHeader
                        title={filter.name}
                        action={
                          <IconButton
                            onClick={() => {
                              setSelectedFilter(filter)
                              setShowDeleteFilteDialog(true)
                            }}
                          >
                            <Delete />
                          </IconButton>
                        }
                      />
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item>
                            <FormControl component="fieldset">
                              <FormControlLabel
                                value="top"
                                control={
                                  <Switch
                                    edge="end"
                                    onChange={() => {
                                      filtersService
                                        .updateFilter(filter.id, {
                                          isShared: !filter.isShared,
                                        })
                                        .then(() => {
                                          setSavedFilters(
                                            savedFilters.map((savedFilter) => {
                                              if (
                                                savedFilter.id === filter.id
                                              ) {
                                                savedFilter.isShared =
                                                  !savedFilter.isShared
                                              }
                                              return savedFilter
                                            }),
                                          )
                                        })
                                        .catch(function (error) {
                                          console.log(error)
                                        })
                                    }}
                                    checked={filter.isShared}
                                    inputProps={{
                                      'aria-labelledby':
                                        'switch-list-label-wifi',
                                    }}
                                  />
                                }
                                label={
                                  filter.isShared
                                    ? t('FILTER.SHARED')
                                    : t('FILTER.NOT_SHARED')
                                }
                                labelPlacement="top"
                              />
                            </FormControl>
                          </Grid>
                          {(id.includes('Marketplace') ||
                            id.includes('AdvertsExchange')) && (
                            <Grid item>
                              <Tooltip title={t('FILTER.NOTIFY_EXPLAINATION')}>
                                <FormControl component="fieldset">
                                  <FormControlLabel
                                    value="top"
                                    control={
                                      <Switch
                                        edge="end"
                                        onChange={() => {
                                          filtersService
                                            .updateFilter(filter.id, {
                                              hasEnabledNotfications:
                                                !filter.hasEnabledNotfications,
                                            })
                                            .then(() => {
                                              setSavedFilters(
                                                savedFilters.map(
                                                  (savedFilter) => {
                                                    if (
                                                      savedFilter.id ===
                                                      filter.id
                                                    ) {
                                                      savedFilter.hasEnabledNotfications =
                                                        !savedFilter.hasEnabledNotfications
                                                    }
                                                    return savedFilter
                                                  },
                                                ),
                                              )
                                            })
                                            .catch(function (error) {
                                              console.log(error)
                                            })
                                        }}
                                        checked={filter.hasEnabledNotfications}
                                        inputProps={{
                                          'aria-labelledby':
                                            'switch-list-label-wifi',
                                        }}
                                      />
                                    }
                                    label={
                                      filter.hasEnabledNotfications
                                        ? t('FILTER.NOTIFY_WHEN_FULLFILLED')
                                        : t(
                                            'FILTER.DONT_NOTIFY_WHEN_FULLFILLED',
                                          )
                                    }
                                    labelPlacement="top"
                                  />
                                </FormControl>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant={'outlined'}
                          color={'secondary'}
                          onClick={() => {
                            setFilterValues(JSON.parse(filter.values))
                            setSavedFilterAnchorEl(null)
                          }}
                        >
                          {t('GENERAL.APPLY')}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              {savedFilters.filter(
                (filter) => filter.userId !== userObject.user.id,
              ).length > 0 && (
                <Fragment>
                  <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                      {`${t('FILTER.FITERS_SHARED_WITH_YOU')}:`}
                    </Typography>
                  </Grid>
                  {savedFilters
                    .filter((filter) => filter.userId !== userObject.user.id)
                    .map((filter) => (
                      <Grid item xs={12}>
                        <Card variant="outlined">
                          <CardHeader title={filter.name} />
                          <CardActions>
                            <Button
                              variant={'outlined'}
                              color={'secondary'}
                              onClick={() => {
                                setFilterValues(JSON.parse(filter.values))
                                setSavedFilterAnchorEl(null)
                              }}
                            >
                              {t('GENERAL.APPLY')}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    ))}
                </Fragment>
              )}
            </Fragment>
          )}
        </Grid>
      </Popover>

      <AgreementDialog
        title={t('FILTER.DELETE_FILTER')}
        message={t('FILTER.DELETE_FILTER_MESSAGE')}
        acceptButtonText={t('FILTER.DELETE_FILTER')}
        open={showDeleteFilterDialog}
        handleClose={() => {
          setShowDeleteFilteDialog(false)
        }}
        agree={() => {
          filtersService.deleteFilter(selectedFilter.id).then(() => {
            setShowDeleteFilteDialog(false)
            setSavedFilters(
              savedFilters.filter((filter) => filter.id !== selectedFilter.id),
            )
          })
        }}
      />
      {filters
        .filter((filter) => filter.shortcut)
        .map((filter) => {
          let index = filterHandler.findIndex(
            (handler) => handler.key === filter.key,
          )

          return (
            <Popover
              onClose={(e) => {
                setFilterAnchorEl(null)
              }}
              anchorEl={filterAnchorEl ? filterAnchorEl.element : null}
              open={filterAnchorEl && filter.key === filterAnchorEl.key}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Container sx={{ padding: theme.spacing(2) }}>
                {filter.variant === 'checkboxList' ? (
                  <FilterCheckBoxList
                    filterHandler={filterHandler}
                    filter={filter}
                    setFilterHandler={setFilterHandler}
                    lookup={filter.lookup}
                  />
                ) : filter.variant === 'calendarWeekPicker' ? (
                  <FilterCalendarWeekPicker
                    filterHandler={filterHandler}
                    filter={filter}
                    setFilterHandler={setFilterHandler}
                  />
                ) : filter.variant === 'autocomplete' ? (
                  <FilterAutocomplete
                    filterHandler={filterHandler}
                    filter={filter}
                    setFilterHandler={setFilterHandler}
                    lookup={filter.lookup}
                  />
                ) : filter.variant === 'geo' ? (
                  <FilterGeo
                    filterHandler={filterHandler}
                    filter={filter}
                    setFilterHandler={setFilterHandler}
                    map={map}
                    setGeoData={setGeoData}
                  />
                ) : filter.variant === 'radio' ? (
                  <RadioGroup
                    sx={{ margin: theme.spacing(1) }}
                    value={filterHandler[index].value}
                    onChange={(e) => {
                      let newFilterHandler = [...filterHandler]
                      newFilterHandler[index].value = e.target.value
                      setFilterHandler(newFilterHandler)
                    }}
                  >
                    {filter.values.map((value) => (
                      <FormControlLabel
                        value={value}
                        control={<Radio />}
                        label={filter.lookup[value]}
                      />
                    ))}
                  </RadioGroup>
                ) : filter.variant === 'categorizedCheckboxList' ? (
                  <CategorizedFilterCheckBoxList
                    filterHandler={filterHandler}
                    filter={filter}
                    setFilterHandler={setFilterHandler}
                    lookup={filter.lookup}
                  />
                ) : (
                  <TextField
                    fullWidth={true}
                    label={filter.label}
                    size={'small'}
                    margin={'dense'}
                    autoFocus
                    variant="outlined"
                    value={filterHandler[index].value}
                    onChange={(e) => {
                      let newFilterHandler = [...filterHandler]
                      newFilterHandler[index].value = e.target.value
                      setFilterHandler(newFilterHandler)
                    }}
                  />
                )}
              </Container>

              <Box display="flex" flexDirection="column">
                <Button
                  color={'secondary'}
                  variant={'text'}
                  onClick={() => clearFilter(filter.key)}
                >
                  {t('FILTER.CLEAR')}
                </Button>
              </Box>
            </Popover>
          )
        })}
      <Drawer
        variant={'temporary'}
        anchor={'right'}
        open={showFilterDrawer}
        onClose={() => setShowFilterDrawer(false)}
        sx={{ zIndex: 1301 }} //Dialogs have a default z-Index of 1300
      >
        <AppBar position={'relative'} color={'default'}>
          <IconButton
            onClick={(e) => {
              setShowFilterDrawer(false)
            }}
            sx={{
              paddingLeft: '18px',
              paddingBottom: '-0px',
              marginLeft: 'auto',
            }}
            size="large"
          >
            <Close />
          </IconButton>
          <Typography
            variant={'h6'}
            sx={{ alignSelf: 'center', margin: theme.spacing(1) }}
          >
            {t('FILTER.FILTER')}
          </Typography>
        </AppBar>

        {filters.map((filter, index) => (
          <Accordion
            expanded={expanded === index}
            onChange={handleChange(index)}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              {filter.label}
            </AccordionSummary>
            <AccordionDetails>
              {filter.variant === 'checkboxList' ? (
                <FilterCheckBoxList
                  filterHandler={filterHandler}
                  filter={filter}
                  setFilterHandler={setFilterHandler}
                  lookup={filter.lookup}
                />
              ) : filter.variant === 'calendarWeekPicker' ? (
                <FilterCalendarWeekPicker
                  filterHandler={filterHandler}
                  filter={filter}
                  setFilterHandler={setFilterHandler}
                />
              ) : filter.variant === 'autocomplete' ? (
                <FilterAutocomplete
                  filterHandler={filterHandler}
                  filter={filter}
                  setFilterHandler={setFilterHandler}
                  lookup={filter.lookup}
                />
              ) : filter.variant === 'radio' ? (
                <RadioGroup
                  value={filterHandler[index].value}
                  onChange={(e) => {
                    let newFilterHandler = [...filterHandler]
                    newFilterHandler[index].value = e.target.value
                    setFilterHandler(newFilterHandler)
                  }}
                >
                  {filter.values.map((value) => (
                    <FormControlLabel
                      value={value}
                      control={<Radio />}
                      label={filter.lookup[value]}
                    />
                  ))}
                </RadioGroup>
              ) : filter.variant === 'categorizedCheckboxList' ? (
                <CategorizedFilterCheckBoxList
                  filterHandler={filterHandler}
                  filter={filter}
                  setFilterHandler={setFilterHandler}
                  lookup={filter.lookup}
                />
              ) : filter.variant === 'geo' ? (
                <FilterGeo
                  filterHandler={filterHandler}
                  filter={filter}
                  setFilterHandler={setFilterHandler}
                  map={map}
                  setGeoData={setGeoData}
                />
              ) : (
                <TextField
                  fullWidth={true}
                  label={filter.label}
                  size={'small'}
                  margin={'dense'}
                  variant="outlined"
                  value={filterHandler[index].value}
                  onChange={(e) => {
                    let newFilterHandler = [...filterHandler]
                    newFilterHandler[index].value = e.target.value
                    setFilterHandler(newFilterHandler)
                  }}
                />
              )}
            </AccordionDetails>
            <Box display="flex" flexDirection="column">
              <Button
                color={'secondary'}
                variant={'text'}
                onClick={() => clearFilter(filter.key)}
              >
                {t('FILTER.CLEAR')}
              </Button>
            </Box>
          </Accordion>
        ))}

        <AppBar
          sx={{
            marginTop: `auto`,
          }}
          position={'relative'}
          color={'default'}
        >
          <Toolbar sx={{ alignItem: 'center', justifyContent: 'center' }}>
            <ButtonGroup>
              <Button
                disabled={
                  !filterHandler.some((handler) => {
                    let matchingFilter = filters.find(
                      (filter) => filter.key === handler.key,
                    )
                    return (
                      ((matchingFilter.variant === 'calendarWeekPicker' ||
                        matchingFilter.variant === 'checkboxList' ||
                        matchingFilter.variant === 'categorizedCheckboxList') &&
                        handler.value.length > 0) ||
                      ((matchingFilter.variant === 'geo' ||
                        matchingFilter.variant === 'textfield' ||
                        matchingFilter.variant === 'autocomplete') &&
                        handler.value)
                    )
                  })
                }
                variant={'outlined'}
                color={'secondary'}
                onClick={() => {
                  clearAllFilters()
                }}
              >
                {t('FILTER.CLEAR_ALL_FILTERS')}
              </Button>
              <Button
                startIcon={<Save />}
                disabled={
                  !filterHandler.some((handler) => {
                    let matchingFilter = filters.find(
                      (filter) => filter.key === handler.key,
                    )
                    return (
                      ((matchingFilter.variant === 'calendarWeekPicker' ||
                        matchingFilter.variant === 'checkboxList' ||
                        matchingFilter.variant === 'categorizedCheckboxList') &&
                        handler.value.length > 0) ||
                      ((matchingFilter.variant === 'geo' ||
                        matchingFilter.variant === 'textfield' ||
                        matchingFilter.variant === 'autocomplete') &&
                        handler.value)
                    )
                  })
                }
                variant={'outlined'}
                color={'secondary'}
                onClick={() => {
                  setShowSaveFilterDialog(true)
                }}
              >
                {t('FILTER.SAVE')}
              </Button>
              <Button
                variant={'contained'}
                color={'secondary'}
                onClick={() => {
                  setShowFilterDrawer(false)
                }}
              >
                {t('FILTER.APPLY')}
              </Button>
            </ButtonGroup>
          </Toolbar>
        </AppBar>
      </Drawer>
      <Box style={{ marginTop: 10, marginBottom: 10 }}>
        {filterHandler.map((handler, index) => {
          let matchingFilter = filters[index]

          return matchingFilter.variant === 'calendarWeekPicker' ? (
            filterHandler[index].value.length > 0 ? (
              <Chip
                size={'small'}
                style={{ maxWidth: '50%', marginTop: 5 }}
                label={
                  <Fragment>
                    <strong>{matchingFilter.label + ': '}</strong>
                    {`${moment(filterHandler[index].value[3]).week()}/${moment(
                      filterHandler[index].value[3],
                    ).year()}`}
                  </Fragment>
                }
                onDelete={() => {
                  let newFilterHandler = [...filterHandler]
                  newFilterHandler[index].value = []
                  setFilterHandler(newFilterHandler)
                }}
              />
            ) : null
          ) : matchingFilter.variant === 'geo' ? (
            filterHandler[index].value ? (
              <Chip
                size={'small'}
                style={{ maxWidth: '50%', marginTop: 5 }}
                label={
                  <strong>{`${handler.value.inputValue} (${
                    handler.value.radius / 1000
                  }km)`}</strong>
                }
                onDelete={() => {
                  let newFilterHandler = [...filterHandler]
                  newFilterHandler[index].value = null
                  setFilterHandler(newFilterHandler)
                  if (setGeoData) {
                    setGeoData(null, null)
                  }
                }}
              />
            ) : null
          ) : matchingFilter.variant === 'checkboxList' ||
            matchingFilter.variant === 'categorizedCheckboxList' ? (
            filterHandler[index].value.length > 0 ? (
              <InfoTooltip
                disableTouchListener
                disableFocusListener
                title={filterHandler[index].value.map((item, index) => {
                  return (
                    <li>
                      {matchingFilter.lookup
                        ? matchingFilter.lookup[item]
                        : item}
                    </li>
                  )
                })}
              >
                <Chip
                  size={'small'}
                  style={{ maxWidth: '50%', marginTop: 5 }}
                  label={
                    <Fragment>
                      <strong>{matchingFilter.label + ': '}</strong>
                      <strong>
                        {filterHandler[index].value.map((item, index) =>
                          index === 0
                            ? matchingFilter.lookup
                              ? matchingFilter.lookup[item]
                              : item
                            : `, ${
                                matchingFilter.lookup
                                  ? matchingFilter.lookup[item]
                                  : item
                              }`,
                        )}
                      </strong>
                    </Fragment>
                  }
                  onDelete={() => {
                    let newFilterHandler = [...filterHandler]
                    newFilterHandler[index].value = []
                    setFilterHandler(newFilterHandler)
                  }}
                />
              </InfoTooltip>
            ) : null
          ) : handler.value ? (
            <Chip
              size={'small'}
              style={{ maxWidth: '50%', marginTop: 5 }}
              label={
                <strong>{`${matchingFilter.label}: ${
                  matchingFilter.lookup
                    ? matchingFilter.lookup[handler.value]
                    : handler.value
                }`}</strong>
              }
              onDelete={() => {
                let newFilterHandler = [...filterHandler]
                newFilterHandler[index].value = null
                setFilterHandler(newFilterHandler)
              }}
            />
          ) : null
        })}
      </Box>

      <SaveFilterDialog
        savedFilters={savedFilters}
        setSavedFilters={setSavedFilters}
        show={showSaveFilterDialog}
        hide={() => {
          setShowSaveFilterDialog(false)
        }}
        getFilterValues={() => {
          let filterValues = {}

          filters.forEach((filter, index) => {
            filterValues[filter.key] = filterHandler[index].value
          })

          return filterValues
        }}
        filterId={id}
      />
    </Fragment>
  )
}
export default withTranslation()(Filter)
