import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import isEqual from 'react-fast-compare'
import { withTranslation } from 'react-i18next'
import i18n from '../../../i18n'

const FilterGeo = (props) => {
  const { filterHandler, filter, setFilterHandler, t, map, setGeoData } = props
  let index = filterHandler.findIndex((handler) => handler.key === filter.key)

  const [origin, setOrigin] = useState(
    filterHandler[index].value ? filterHandler[index].value.origin : null,
  )
  const [radius, setRadius] = useState(
    filterHandler[index].value ? filterHandler[index].value.radius : 250 * 1000,
  )
  const [placePredictions, setPlacePredictions] = useState([])
  const [autoCompleteSessionToken, setAutoCompleteSessionToken] = useState(
    window.google
      ? new window.google.maps.places.AutocompleteSessionToken()
      : undefined,
  )

  const [inputValue, setInputValue] = React.useState(
    filterHandler[index].value ? filterHandler[index].value.inputValue : '',
  )

  useEffect(() => {
    if (
      (!filterHandler[index].value && !origin) ||
      isEqual(filterHandler[index].value, {
        origin: origin,
        radius: radius,
        inputValue: inputValue,
      })
    ) {
      return
    }
    let newFilterHandler = [...filterHandler]
    newFilterHandler[index].value = origin
      ? {
          origin: origin,
          radius: radius,
          inputValue: inputValue,
        }
      : null

    setGeoData(origin, radius)
    setFilterHandler(newFilterHandler)
  }, [radius, origin])

  return (
    <Box display="flex" alignItems="center">
      <Autocomplete
        value={
          filterHandler[index].value
            ? filterHandler[index].value.inputValue
            : ''
        }
        size="small"
        margin="dense"
        inputValue={inputValue}
        disablePortal
        id="combo-box-demo"
        options={placePredictions}
        getOptionLabel={(placePrediction) =>
          placePrediction.description
            ? placePrediction.description
            : placePrediction
        }
        sx={{ width: '20vh', paddingRight: '5px' }}
        onChange={async (e, value) => {
          if (!value || !value.place_id) {
            return
          }

          try {
            let service = await new window.google.maps.places.PlacesService(map)
            await service.getDetails(
              {
                placeId: value.place_id,
                fields: ['geometry.location'],
                language: i18n.language.substring(0, 2),
                sessionToken: autoCompleteSessionToken,
              },
              async (place) => {
                setOrigin({
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                })
                setAutoCompleteSessionToken(
                  new window.google.maps.places.AutocompleteSessionToken(),
                )
              },
            )
          } catch (e) {
            console.error('Autocompletion failed')
          }
        }}
        onInputChange={(event, value, reason) => {
          setInputValue(value)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('MARKETPLACE.STARTING_POINT')}
            onChange={async (event) => {
              if (event.target.value.length > 2) {
                try {
                  let service =
                    new window.google.maps.places.AutocompleteService()

                  let result = await service.getPlacePredictions({
                    input: event.target.value,
                    language: i18n.language.substring(0, 2),
                    types: ['route', 'locality', 'postal_code', 'sublocality'],
                    sessionToken: autoCompleteSessionToken,
                  })
                  setPlacePredictions(result.predictions)
                } catch (e) {
                  console.error('Autocompletion failed')
                }
              }
            }}
          />
        )}
      />

      <FormControl
        variant="outlined"
        style={{ margin: '8px 0', minWidth: 120 }}
        size="small"
        margin="dense"
      >
        <InputLabel id="radius-label">{t('MARKETPLACE.RADIUS')}</InputLabel>
        <Select
          MenuProps={{
            style: { zIndex: 1302 },
          }}
          labelId="radius-label"
          value={radius}
          onChange={(e) => {
            setRadius(e.target.value)
          }}
          label={t('MARKETPLACE.RADIUS')}
        >
          {[...Array(30).keys()]
            .map((i) => (i + 1) * 50)
            .map((km) => (
              <MenuItem key={km} value={km * 1000}>
                {km} km
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default withTranslation()(FilterGeo)
