import React, { Fragment, useState } from 'react'
import { offersService } from '../../../../../../_services/offersService'
import AcceptCounterOfferDialog from '../../../../../AcceptCounterOfferDialog'
import AcceptOfferDialog from '../../../../../AcceptOfferDialog'
import AgreementDialog from '../../../../../AgreementDialog'

const OfferActionsDialogs = ({ dialogControlProps }) => {
  const {
    showAcceptOfferDialog,
    setShowAcceptOfferDialog,
    showSendOfferDialog,
    setShowSendOfferDialog,
    showDeclineOfferDialog,
    setShowDeclineOfferDialog,
    selectedOfferPosition,
    requestPosition,
    forceUpdate,
  } = dialogControlProps
  const userObject = JSON.parse(localStorage.getItem('user'))
  const [isLoading, setIsLoading] = useState(true)

  return (
    <Fragment>
      {showAcceptOfferDialog && (
        <AcceptOfferDialog
          selectedOffer={{
            ...selectedOfferPosition.offer,
            requestType: requestPosition.request.type,
          }}
          hide={() => setShowAcceptOfferDialog(false)}
          forceUpdate={forceUpdate}
        />
      )}
      {showSendOfferDialog && (
        <AcceptCounterOfferDialog
          selectedOffer={{
            ...selectedOfferPosition.offer,
            requestPartnerId: requestPosition.request.partnerId,
          }}
          hide={() => setShowSendOfferDialog(false)}
          forceUpdate={forceUpdate}
        />
      )}

      {showDeclineOfferDialog && (
        <AgreementDialog
          open={showDeclineOfferDialog}
          handleClose={() => setShowDeclineOfferDialog(false)}
          agree={() => {
            setIsLoading(true)
            offersService
              .declineOffer(
                selectedOfferPosition.offer.id,
                userObject.partner.id,
              )
              .then(() => {
                forceUpdate()
                setShowDeclineOfferDialog(false)
              })
              .catch((error) => console.log(error))
              .finally(() => {
                setIsLoading(false)
              })
          }}
        />
      )}
    </Fragment>
  )
}

export default OfferActionsDialogs
