import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { Field, Form, Formik, yupToFormErrors } from 'formik'
import { TextField } from 'formik-mui'

import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import * as Yup from 'yup'
import { alertActions } from '../../../../_actions/alertActions'
import { getTouchedObj, validateLocation } from '../../../../_helpers/little'
import { communitiesService } from '../../../../_services/communitiesService'
import { jobOffersService } from '../../../../_services/jobOffersService'
import { requestsService } from '../../../../_services/requestsService'
import AcceptJobOfferDialog from '../../../AcceptJobOfferDialog'
import AgreementDialog from '../../../AgreementDialog'
import Attachments from '../../../Attachments'
import CustomDialogTitle from '../../../CustomDialogTitle'
import Deliveries from '../../../Deliveries'
import DeliveryDetails from '../../../DeliveryDetails'
import DeliveryDetailsReplacedComponentsDialog from '../../../DeliveryDetailsReplacedComponentsDialog'
import { FieldErrorWrapper } from '../../../FieldErrorWrapper'
import JobOfferPositionsView from '../../../JobOfferPositionsView'
import { Loading } from '../../../Loading'
import PaymentDetails from '../../../PaymentDetails'
import RequestDetails from '../../../RequestDetails'
import ScrollableFullHeightContainer from '../../../ScrollableFullHeightContainer'
import { Section } from '../../../Section'
import RequestPositions from './_components/RequestPositions'

const Request = (props) => {
  const { t, dispatch } = props

  const [isLoading, setIsLoading] = useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [isInitialValid, setIsInitialValid] = useState(false)
  const [initialValues, setInitialValues] = useState({
    id: undefined,
    selectedPartnerLocationId: '',
    type: 'REQUEST',
    state: 'DRAFT',
    generalInformation: {
      deliveryDetails: {
        incoTerm: 'DDP',
        locations: [
          {
            id: 1,
            company: '',
            address: '',
            zipcode: '',
            place: '',
            countryId: 80,
            deliveryInformations: '',
            positions: [],
            unload: 'ANY',
            stackHeight: '',
            stackHeightUnit: 'QUANTITY',
            isDespatchAdviceRequired: false,
            despatchAdviceInstructions: '',
            deliveryTimes: [
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
            ],
          },
        ],
      },
      paymentDetails: {
        paymentTerm: JSON.parse(localStorage.getItem('user')).partner
          .allPaymentTermsAllowed
          ? '14'
          : '0',
        skonto: '0',
        skontoPeriod: '',
        additionalPaymentInformation: '',
      },
      otherDetails: {
        title: '',
        validUntil: null,
        communityId: '',
      },
    },
    positions: [],
    deliveries: [],
    attachments: [],
  })

  const [hasAccessedPriceFinder, setHasAccessedPriceFinder] = useState(false)

  const [showHasReplacedComponentsDialog, setShowHasReplacedComponentsDialog] =
    useState(false)
  const [externalAdressValidationResult, setExternalAdressValidationResult] =
    useState(false)

  const [priceFinderJobOffer, setPriceFinderJobOffer] = useState(false)
  const [showAcceptOfferDialog, setShowAcceptOfferDialog] = useState(false)

  const [geoData, setGeoData] = useState(null)
  const [communities, setCommunities] = useState([])
  const userObject = JSON.parse(localStorage.getItem('user'))
  const partnerId = userObject.partner.id

  useEffect(() => {
    setIsLoading(true)
    communitiesService
      .getCommunitiesOfPartner(partnerId)
      .then((data) => {
        setCommunities(data)
      })
      .catch((error) => {
        console.error('Error fetching communities:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [partnerId])

  const location = useLocation()

  useEffect(() => {
    const search = location.search
    const params = new URLSearchParams(search)
    const id = params.get('id')
    const mode = params.get('mode') || 'create'

    if (id) {
      setIsLoading(true)
      requestsService.getRequest(id).then((data) => {
        data.deliveries = data.deliveries.map((delivery) => {
          return {
            ...delivery,
            value:
              delivery.type === 'date'
                ? moment(delivery.value)
                : delivery.value,
          }
        })
        if (mode === 'copy') {
          data.id = undefined
          data.state = 'DRAFT'
          data.generalInformation.otherDetails.validUntil = null
          if (data.community) {
            data.generalInformation.otherDetails.communityId = data.community.id
          }
          data.generalInformation.deliveryDetails.locations[0].stackHeight =
            data.generalInformation.deliveryDetails.locations[0].stackHeight
              ? data.generalInformation.deliveryDetails.locations[0].stackHeight
              : ''
          data.attachments = []
        } else {
          data.generalInformation.otherDetails.validUntil = moment(
            data.generalInformation.otherDetails.validUntil,
          )
        }
        setIsInitialValid(true)
        setInitialValues(data)
        setIsLoading(false)
      })
    }
  }, [location.search, t])

  const valSchemas = [
    Yup.object().shape({
      id: Yup.number(),
      generalInformation: Yup.object().shape({
        deliveryDetails: Yup.object().shape({
          locations: Yup.array()
            .of(
              Yup.object()
                .shape({
                  company: Yup.string().required(t('GENERAL.REQUIRED')),
                  address: Yup.string().required(t('GENERAL.REQUIRED')),
                  zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
                  place: Yup.string().required(t('GENERAL.REQUIRED')),
                  countryId: Yup.number().required(t('GENERAL.REQUIRED')),
                  deliveryInformations: Yup.string().nullable(),
                  unload: Yup.string().required(),
                  stackHeight: Yup.number()
                    .nullable(true)
                    .transform((_, val) => (val === Number(val) ? val : null)), //NaN to Null
                  stackHeightUnit: Yup.string().required(),
                })
                .test({
                  test: async function (location, context) {
                    return userObject.user.partnerLocationIds.length > 0
                      ? true
                      : await validateLocation(
                          location,
                          context,
                          `generalInformation.deliveryDetails.locations[${context.parent.findIndex(
                            (locationInValues) =>
                              locationInValues.id === location.id,
                          )}]`,
                          setExternalAdressValidationResult,
                          setShowHasReplacedComponentsDialog,
                          (validationResult) => {
                            setGeoData(validationResult.geocode.location)
                          },
                          this,
                          ['route', 'street_number'],
                        )
                  },
                }),
            )
            .min(1, t('GENERAL.MIN_1')),
        }),
        paymentDetails: Yup.object().shape({
          paymentTerm: Yup.string().required(t('GENERAL.REQUIRED')),
          skonto: Yup.string().required(t('GENERAL.REQUIRED')),
          skontoPeriod: Yup.string()
            .nullable()
            .test('required', t('GENERAL.REQUIRED'), function (skontoPeriod) {
              return (
                parseInt(this.parent.skonto) === 0 ||
                (skontoPeriod !== null && skontoPeriod !== '')
              )
            })
            .test(
              'paymentTerm',
              t('REQUEST.PAYMENT_DETAILS.INVALID_REF_PAYMENT_TERM'),
              function (skontoPeriod) {
                return (
                  skontoPeriod === null ||
                  skontoPeriod === '' ||
                  parseInt(skontoPeriod) <= parseInt(this.parent.paymentTerm)
                )
              },
            ),
        }),
        otherDetails: Yup.object().shape({
          validUntil: Yup.date()
            .typeError(t('GENERAL.INVALID_DATE'))
            .required(t('GENERAL.REQUIRED'))
            .test('start', t('GENERAL.INVALID_DATE_PAST'), (start) => {
              if (start) {
                return moment().diff(moment(start), 'days') <= 0
              } else {
                return true
              }
            }),
          communityId: Yup.number()
            .nullable()
            .transform((_, val) => (val === Number(val) ? val : null)), //NaN to Null,
        }),
      }),
    }),
    Yup.object().shape({
      generalInformation: Yup.object().shape({
        deliveryDetails: Yup.object().shape({
          locations: Yup.array().of(
            Yup.object().shape({
              positions: Yup.array()
                .of(
                  Yup.object().shape({
                    id: Yup.number().required(t('GENERAL.REQUIRED')),
                    productId: Yup.string().required(t('GENERAL.REQUIRED')),
                    productUnitId: Yup.number().required(t('GENERAL.REQUIRED')),
                    targetPrice: Yup.number().min(
                      0.01,
                      t('GENERAL.GREATER_THAN_0'),
                    ),
                    amount: Yup.number()
                      .required(t('GENERAL.REQUIRED'))
                      .typeError(t('GENERAL.REQUIRED'))
                      .min(0.01, t('GENERAL.GREATER_THAN_0'))
                      .test({
                        message: t('GENERAL.TOO_MUCH'),
                        test: function (v) {
                          if (
                            this.parent.productUnitId === 3 &&
                            parseFloat(v) > 200
                          ) {
                            return false
                          } else {
                            return true
                          }
                        },
                      }),
                  }),
                )
                .min(1, t('GENERAL.MIN_1')),
            }),
          ),
        }),
      }),
    }),
    Yup.object().shape({
      deliveries: Yup.array().of(
        Yup.object()
          .shape({
            type: Yup.string().required(t('GENERAL.REQUIRED')),
            value: Yup.string(),
            obligatory: Yup.boolean(),
            positions: Yup.array()
              .of(
                Yup.object().shape({
                  amount: Yup.number(),
                }),
              )
              .compact(function (v) {
                return v.amount === ''
              })
              .min(1, t('GENERAL.MIN_1')),
          })
          .test({
            message: t('REQUEST.ADD_DELIVERY_WIZARD.POS_AMOUNT_EXCEEDED'),
            test: function (delivery) {
              let result = true
              delivery.positions.forEach((dPos) => {
                const { context } = this.options
                var positionsAmount = parseFloat(
                  context.generalInformation.deliveryDetails.locations[0].positions.find(
                    (pos) => {
                      return pos.id === dPos.positionId
                    },
                  ).amount,
                )
                var otherDeliveriesAmount = 0
                context.deliveries.forEach((otherDelivery) => {
                  if (delivery.id === otherDelivery.id) {
                    return
                  }
                  otherDelivery.positions.forEach((dPos2) => {
                    if (dPos2.positionId === dPos.positionId) {
                      otherDeliveriesAmount += dPos2.amount
                        ? parseFloat(dPos2.amount)
                        : 0
                    }
                  })
                })
                result =
                  result &&
                  dPos.amount <=
                    positionsAmount - parseFloat(otherDeliveriesAmount)
              })

              return result
            },
          }),
      ),
    }),
  ]

  const handleSubmit = async (state, formikProps) => {
    const { submitForm, setFieldValue, isValid } = formikProps

    if (isValid) {
      await setFieldValue('state', state)
    }
    await submitForm()
  }

  let navigate = useNavigate()

  const getFirstErrorPath = (errors) => {
    let path = []
    const findFirstErrorPath = (obj, currentPath) => {
      for (let key in obj) {
        path.push(key)
        if (typeof obj[key] === 'string') {
          return
        } else {
          findFirstErrorPath(obj[key], path)
        }
      }
    }
    findFirstErrorPath(errors, path)
    return path.join('.')
  }

  return (
    <Fragment>
      {
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          isInitialValid={isInitialValid}
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            let requestBody = {
              ...values,
              generalInformation: {
                ...values.generalInformation,
                deliveryDetails: {
                  ...values.generalInformation.deliveryDetails,
                  locations:
                    values.generalInformation.deliveryDetails.locations.map(
                      (location) => {
                        return {
                          ...location,
                          geoData: geoData,
                        }
                      },
                    ),
                },
              },
            }
            setIsLoading(true)
            if (!values.id) {
              let partnerId = userObject.partner.id
              let userId = userObject.user.id

              if (!hasAccessedPriceFinder) {
                //TODO: Nur wenn Mengen in Anlieferung = Menge in Anfrage

                let priceFinderData = {
                  jobOfferType: 'REQUEST',
                  incoTerm:
                    requestBody.generalInformation.deliveryDetails.incoTerm,
                  stackHeight:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .stackHeight,
                  stackHeightUnit:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .stackHeightUnit,
                  company:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .company,
                  zipcode:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .zipcode,
                  place:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .place,
                  address:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .address,
                  deliveryInformations:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .deliveryInformations,
                  countryId:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .countryId,
                  unload:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .unload,
                  deliveryTimes:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .deliveryTimes,
                  isDespatchAdviceRequired:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .isDespatchAdviceRequired,
                  despatchAdviceInstructions:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .despatchAdviceInstructions,
                  geoData:
                    requestBody.generalInformation.deliveryDetails.locations[0]
                      .geoData,
                  jobOfferValidity: 1,
                  skonto: requestBody.generalInformation.paymentDetails.skonto,
                  paymentTerm:
                    requestBody.generalInformation.paymentDetails.paymentTerm,
                  skontoPeriod:
                    requestBody.generalInformation.paymentDetails.skontoPeriod,
                  partnerId: partnerId,
                  jobId: null,
                  signatureUserId: 563,
                  positions:
                    requestBody.generalInformation.deliveryDetails.locations[0].positions.map(
                      (pos) => {
                        return {
                          ...pos,
                          localId: pos.id,
                          positionProperties: pos.positionProperties.map(
                            (positionProperty) => {
                              return {
                                ...positionProperty,
                                productPropertyId:
                                  positionProperty.productProperty.id,
                              }
                            },
                          ),
                        }
                      },
                    ),
                  deliveries: requestBody.deliveries.map((delivery) => {
                    return {
                      ...delivery,
                      localId: delivery.id,
                      deliveryPositions: delivery.positions.map((dPos) => {
                        return {
                          ...dPos,
                          localId: dPos.positionId,
                        }
                      }),
                    }
                  }),
                  users: [
                    {
                      id: userId,
                    },
                  ],
                }

                let priceFinder = await jobOffersService.createJobOffer(
                  priceFinderData,
                )

                if (priceFinder.jobOfferId) {
                  setHasAccessedPriceFinder(true)

                  let jobOffer = await jobOffersService.getJobOffer(
                    priceFinder.jobOfferId,
                    undefined,
                  )

                  setPriceFinderJobOffer(jobOffer)
                  setIsLoading(false)
                  return
                }
              }

              requestsService
                .createRequestOfPartner(partnerId, userId, requestBody)
                .then((requestId) => {
                  const searchParams = new URLSearchParams({
                    advertsCheck: requestId,
                  })
                  navigate(`/requests?${searchParams.toString()}`)

                  dispatch(alertActions.info(t('REQUEST.PUBLISH_SUCCESSFUL')))
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .catch(function (error) {
                  setSubmitting = false
                  dispatch(alertActions.error(error))
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .finally(() => {
                  setIsLoading(false)
                })
            } else {
              requestsService
                .updateRequest(
                  requestBody,
                  values.id,
                  true,
                  values.generalInformation.deliveryDetails.locations.map(
                    (location) => location.partnerLocationId,
                  ),
                )
                .then((data) => {
                  setSubmitting = false
                  navigate('/requests')
                  dispatch(alertActions.info(t('REQUEST.UPDATE_SUCCESSFUL')))
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .catch(function (error) {
                  setSubmitting = false
                  console.log(error)
                  dispatch(alertActions.error(error))
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .finally(() => {
                  setIsLoading(false)
                })
            }
          }}
        >
          {({
            isSubmitting,
            values,
            setFieldValue,
            errors,
            handleBlur,
            touched,
            submitForm,
            isValid,
            setErrors,
            setTouched,
          }) =>
            (isLoading && <Loading variant={'centered'} />) || (
              <Fragment>
                <Form
                  onKeyDown={(keyEvent) => {
                    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                      keyEvent.preventDefault()
                    }
                  }}
                >
                  <Stepper activeStep={activeStep} sx={{ margin: 2 }}>
                    <Step>
                      <StepLabel>{t('REQUEST.GENERAL_INFORMATION')}</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t('REQUEST.PRODUCTS')}</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t('REQUEST.DATE_OF_DELIVERY')}</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t('REQUEST.ATTACHMENTS')}</StepLabel>
                    </Step>
                    <Step>
                      <StepLabel>{t('REQUEST.SUMMARY')}</StepLabel>
                    </Step>
                  </Stepper>
                  <ScrollableFullHeightContainer
                    toolbar={
                      <Fragment>
                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{ margin: '0 10px 0px 10px' }}
                          onClick={() => {
                            if (activeStep === 0) {
                              navigate('/requests')
                            } else {
                              setActiveStep(
                                (prevActiveStep) => prevActiveStep - 1,
                              )
                            }
                          }}
                        >
                          {t('GENERAL.BACK')}
                        </Button>
                        {activeStep === 4 ? (
                          <Fragment>
                            {values.state !== 'ARCHIVED' ? (
                              <Tooltip
                                title={
                                  values.state !== 'DRAFT'
                                    ? t('REQUEST.SAVE_PUBLISHED_DESCRIPTION')
                                    : t(
                                        'REQUEST.SAVE_NOT_PUBLISHED_DESCRIPTION',
                                      )
                                }
                              >
                                <Button
                                  variant={values.id ? 'contained' : 'outlined'}
                                  color={'secondary'}
                                  disabled={isSubmitting}
                                  onClick={async () =>
                                    handleSubmit('DRAFT', {
                                      submitForm,
                                      setFieldValue,
                                      isValid,
                                    })
                                  }
                                >
                                  {t('GENERAL.SAVE')}
                                </Button>
                              </Tooltip>
                            ) : null}

                            {values.state === 'DRAFT' ? (
                              <Button
                                variant="contained"
                                color="secondary"
                                style={{ margin: '0 10px 0px 10px' }}
                                disabled={isSubmitting}
                                onClick={async () =>
                                  handleSubmit('PUBLISHED', {
                                    submitForm,
                                    setFieldValue,
                                    isValid,
                                  })
                                }
                              >
                                {t('REQUEST.PUBLISH')}
                              </Button>
                            ) : null}
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                if (valSchemas[activeStep]) {
                                  valSchemas[activeStep]
                                    .validate(values, {
                                      abortEarly: false,
                                      context: values,
                                    })
                                    .then(function (value) {
                                      setActiveStep(
                                        (prevActiveStep) => prevActiveStep + 1,
                                      )
                                    })
                                    .catch((err) => {
                                      let yupErrors = yupToFormErrors(err)
                                      setErrors(yupErrors)
                                      setTouched(
                                        getTouchedObj(yupErrors),
                                        false,
                                      ) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503

                                      const firstErrorPath =
                                        getFirstErrorPath(yupErrors)

                                      let errorElement =
                                        document.getElementById(firstErrorPath)

                                      if (errorElement) {
                                        errorElement.scrollIntoView({
                                          behavior: 'smooth',
                                          block: 'center',
                                        })
                                      }
                                    })
                                } else {
                                  setActiveStep(
                                    (prevActiveStep) => prevActiveStep + 1,
                                  )
                                }
                              }}
                            >
                              {t('REQUEST.CONTINUE')}
                            </Button>
                          </Fragment>
                        )}
                      </Fragment>
                    }
                  >
                    {activeStep === 0 && (
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <DeliveryDetails
                            readOnly={
                              values.state !== 'DRAFT' &&
                              values.state !== 'PUBLISHED'
                            }
                            setFieldValue={setFieldValue}
                            data={values}
                            mode={
                              new URLSearchParams(location.search).get(
                                'mode',
                              ) || 'create'
                            }
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <PaymentDetails
                            data={values}
                            setFieldValue={setFieldValue}
                            readOnly={
                              values.state !== 'DRAFT' &&
                              values.state !== 'PUBLISHED'
                            }
                          />
                          <Section header={t('REQUEST.OTHER_DETAILS')}>
                            <div id="testy"></div>
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t('REQUEST.TITLE')}
                              variant="outlined"
                              name="generalInformation.otherDetails.title"
                            />

                            <FieldErrorWrapper
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  label: t('REQUEST.VALID_UNTIL'),
                                  margin: 'dense',
                                },
                              }}
                              name="generalInformation.otherDetails.validUntil"
                              component={DatePicker}
                              inputFormat="DD/MM/YYYY"
                              minDate={moment()}
                            />
                            {communities !== null && communities.length > 0 && (
                              <Field
                                component={TextField}
                                name="generalInformation.otherDetails.communityId"
                                select
                                fullWidth
                                label={t('REQUEST.COMMUNITY')}
                                variant="outlined"
                                margin="dense"
                              >
                                <MenuItem value={null}>
                                  {t('COMMUNITY.SELECT')}
                                </MenuItem>
                                {communities
                                  .filter(
                                    (community) => community.state === 'ACTIVE',
                                  )
                                  .map((community) => (
                                    <MenuItem
                                      value={community.id}
                                      key={community.id}
                                    >
                                      {community.name}
                                    </MenuItem>
                                  ))}
                              </Field>
                            )}
                          </Section>
                        </Grid>
                      </Grid>
                    )}

                    {activeStep === 1 && (
                      <RequestPositions
                        data={values}
                        readOnly={
                          values.state !== 'DRAFT' &&
                          values.state !== 'PUBLISHED'
                        }
                        errors={errors}
                        setFieldValue={setFieldValue}
                        valSchema={valSchemas[activeStep]}
                      />
                    )}

                    {activeStep === 2 && (
                      <Deliveries
                        data={values}
                        readOnly={
                          values.state !== 'DRAFT' &&
                          values.state !== 'PUBLISHED'
                        }
                        setFieldValue={setFieldValue}
                        type={'REQUEST'}
                        errors={errors}
                        positions={
                          values.generalInformation.deliveryDetails.locations[0]
                            .positions
                        }
                      />
                    )}

                    {activeStep === 3 && (
                      <Attachments
                        data={values}
                        readOnly={
                          values.state !== 'DRAFT' &&
                          values.state !== 'PUBLISHED'
                        }
                        setFieldValue={setFieldValue}
                        type={'REQUEST'}
                      />
                    )}
                    {activeStep === 4 && (
                      <RequestDetails communities={communities} data={values} />
                    )}
                  </ScrollableFullHeightContainer>
                  {priceFinderJobOffer && (
                    <Dialog open={true} fullWidth={true} maxWidth={'md'}>
                      <CustomDialogTitle
                        title={t('REQUEST.PRICE_FINDER_OFFER_TITLE')}
                        onClose={() => {
                          setPriceFinderJobOffer(null)
                          setHasAccessedPriceFinder(false)
                          setFieldValue('state', 'DRAFT')
                        }}
                      />
                      <DialogContent>
                        <Typography>
                          {t('REQUEST.PRICE_FINDER_OFFER_DESCRIPTION')}
                        </Typography>
                        {values.generalInformation.otherDetails.communityId && (
                          <Alert severity="info">
                            {t('OFFER.NO_COMMUNITY_POSSIBLE')}
                          </Alert>
                        )}
                      </DialogContent>
                      <DialogContent>
                        <JobOfferPositionsView data={priceFinderJobOffer} />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant={'outlined'}
                          color="secondary"
                          style={{ margin: '0 10px 0px 10px' }}
                          onClick={async () => {
                            await jobOffersService.declineJobOffer(
                              priceFinderJobOffer.id,
                            )
                            setPriceFinderJobOffer(null)
                            await handleSubmit('DRAFT', {
                              submitForm,
                              setFieldValue,
                              isValid,
                            })
                          }}
                        >
                          {t('REQUEST.PRICE_FINDER_OFFER_DECLINE_SAVE')}
                        </Button>
                        <Button
                          variant={'outlined'}
                          color="secondary"
                          style={{ margin: '0 10px 0px 10px' }}
                          onClick={async () => {
                            await jobOffersService.declineJobOffer(
                              priceFinderJobOffer.id,
                            )
                            setPriceFinderJobOffer(null)

                            await handleSubmit('PUBLISHED', {
                              submitForm,
                              setFieldValue,
                              isValid,
                            })
                          }}
                        >
                          {t('REQUEST.PRICE_FINDER_OFFER_DECLINE')}
                        </Button>

                        <Button
                          variant={'contained'}
                          color="secondary"
                          style={{ margin: '0 10px 0px 10px' }}
                          onClick={async () => {
                            setShowAcceptOfferDialog(true)
                          }}
                        >
                          {t('REQUEST.PRICE_FINDER_OFFER_ACCEPT')}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  )}
                  {showAcceptOfferDialog && (
                    <AcceptJobOfferDialog
                      jobOffer={priceFinderJobOffer}
                      hide={() => setShowAcceptOfferDialog(false)}
                    />
                  )}
                </Form>
                <AgreementDialog
                  open={showDeleteDialog}
                  handleClose={() => {
                    setShowDeleteDialog(!showDeleteDialog)
                  }}
                  agree={() => {
                    requestsService.deleteRequest(values.id)
                    props.navigate('/requests')
                    dispatch(alertActions.info(t('REQUEST.DELETE_SUCCESSFUL')))
                    setTimeout(() => {
                      dispatch(alertActions.clear())
                    }, alertActions.alertTimeout)
                  }}
                />
                {showHasReplacedComponentsDialog && (
                  <DeliveryDetailsReplacedComponentsDialog
                    setAddress={() => {
                      let replacedAddressComponents =
                        externalAdressValidationResult.address.addressComponents.filter(
                          (addressComponent) => addressComponent.replaced,
                        )

                      replacedAddressComponents.forEach((addressComponent) => {
                        switch (addressComponent.componentType) {
                          case 'route':
                          case 'street_number':
                            let adress =
                              externalAdressValidationResult.address.addressComponents.find(
                                (addressComponent) =>
                                  addressComponent.componentType === 'route',
                              )
                            let streetNumber =
                              externalAdressValidationResult.address.addressComponents.find(
                                (addressComponent) =>
                                  addressComponent.componentType ===
                                  'streetNumber',
                              )
                            setFieldValue(
                              `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                                (location) =>
                                  location.id ===
                                  externalAdressValidationResult.locationId,
                              )}].address`,
                              `${adress} ${streetNumber}`,
                            )
                            break
                          case 'postal_code':
                            setFieldValue(
                              `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                                (location) =>
                                  location.id ===
                                  externalAdressValidationResult.locationId,
                              )}].zipcode`,
                              addressComponent.componentName.text,
                            )
                            break
                          case 'locality':
                            setFieldValue(
                              `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                                (location) =>
                                  location.id ===
                                  externalAdressValidationResult.locationId,
                              )}].place`,
                              addressComponent.componentName.text,
                            )
                            break
                          // case 'country':
                          //   break
                          default:
                            break
                        }
                        setFieldValue()
                      })
                    }}
                    addressValidationResult={externalAdressValidationResult}
                    hide={() => setShowHasReplacedComponentsDialog(false)}
                  />
                )}
              </Fragment>
            )
          }
        </Formik>
      }
    </Fragment>
  )
}
export default withTranslation()(connect()(Request))
