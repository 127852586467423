import config from '../_config/config.js'
import { authHeader } from '../_helpers/auth-header.js'

export const orderPurchaseConfirmationsService = {
  getOrderPurchaseConfirmation,
}

function getOrderPurchaseConfirmation(orderId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/orders/` + orderId + '/order-purchase-confirmation',
    requestOptions,
  )
}

export * from './orderPurchaseConfirmationsService.js'
