import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const unitTranslationsService = {
  getUnitTranslations,
  updateUnitTranslations,
  translateUnitWithModel,
}

function getUnitTranslations() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/unittranslations`, requestOptions)
}

function updateUnitTranslations(formData) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: formData,
  }

  return fetch(`${config.apiUrlBase}/unittranslations`, requestOptions)
}

function translateUnitWithModel(positions) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(positions),
  }

  return fetch(`${config.apiUrlBase}/unit-translations`, requestOptions).then(
    handleResponse,
  )
}

export * from './unitTranslationsService'
