import config from '../_config/config'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const communityInvitationsService = {
  deleteCommunityInvitation,
  acceptCommunityInvitation,
  createCommunityInvitationOfCommunity,
  getCommunityInvitationsOfPartner,
}

function deleteCommunityInvitation(communityInvitationId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${config.apiUrlBase}/community-invitations/${communityInvitationId}`,
    requestOptions,
  )
}
function acceptCommunityInvitation(communityInvitationId, requestBody) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }

  return fetch(
    `${config.apiUrlBase}/community-invitations/${communityInvitationId}`,
    requestOptions,
  ).then(handleResponse)
}
function createCommunityInvitationOfCommunity(communityId, requestBody) {
  var requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }

  return fetch(
    `${config.apiUrlBase}/communities/${communityId}/community-invitations`,
    requestOptions,
  ).then(handleResponse)
}

function getCommunityInvitationsOfPartner(partnerId) {
  var requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/community-invitations`,
    requestOptions,
  ).then(handleResponse)
}

export * from './communityInvitationsService'
