import { Build, Delete, Info, WarningRounded } from '@mui/icons-material'
import { Grid } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { default as Numeral, default as numeral } from 'numeral'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { unitLookup } from '../../_constants/lookupConstants'
import AgreementDialog from '../AgreementDialog'
import DifferenceWrapper from '../DifferenceWrapper'
import { InfoTooltip } from '../InfoTooltip'
import PositionDetailSummary from '../PositionDetailSummary'
import ProductConfigurator from '../ProductConfigurator'

const Position = (props) => {
  const {
    t,
    data,
    differences,
    diffData,
    diffObjectType,
    showDifference,
    readOnly,
    showProductDetails,
    updatePositionFunction,
    deletePositionFunction,
    parentType,
    hideInfoButton,
    showAmount,
    amount,
  } = props

  const [showProductConfigurator, setShowProductConfigurator] = useState(false)

  const [showDeletePositionDialog, setShowDeletePositionDialog] =
    useState(false)

  return (
    <nobr>
      <Grid container spacing={1} wrap="nowrap">
        {showDifference ? (
          <Grid
            item
            xs="auto"
            style={{ alignSelf: 'center', minWidth: '32px' }}
          >
            {differences &&
            differences.details.filter((detail) => detail.value !== false)
              .length > 0 ? (
              <InfoTooltip
                title={
                  (diffObjectType === 'REQUEST'
                    ? t('OFFER.DIFFERENCE_ORIGIN')
                    : t('OFFER.DIFFERENCE_COMPARE_OFFER')) +
                  ' ' +
                  differences.details.filter((detail) => detail.value !== false)
                    .length +
                  ' ' +
                  t('OFFER.DIFFERENCE_DETAILS')
                }
              >
                <WarningRounded style={{ color: '#ffe57f' }} />
              </InfoTooltip>
            ) : null}
          </Grid>
        ) : null}
        <Grid
          item
          xs
          sx={{
            alignSelf: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          <DifferenceWrapper
            showDifference={
              showAmount &&
              differences &&
              (differences.amount || differences.unit)
            }
            difference={
              differences ? differences.amount || differences.unit : false
            }
            differenceDisplay={
              differences
                ? `${numeral(
                    differences.amount ? differences.amount : data.amount,
                  ).format('0,0.[00]')} ${
                    unitLookup[differences.unit ? differences.unit : data.unit]
                  }`
                : null
            }
            diffObjectType={diffObjectType}
          >
            <Grid container alignItems="center">
              <Grid item>
                {showAmount
                  ? amount !== undefined
                    ? Numeral(amount).format('0,0.[00]')
                    : Numeral(data.amount).format('0,0.[00]')
                  : null}
                {showAmount && ` ${unitLookup[data.unit]} x `}
              </Grid>
              <Grid item>{data.product.name}</Grid>
              <Grid item>
                {!hideInfoButton && (
                  <Tooltip title={t('POSITION.DETAILS')}>
                    <IconButton
                      color="default"
                      style={{ margin: '0px', padding: '8px' }}
                      onClick={() => {
                        setShowProductConfigurator(true)
                      }}
                      size="large"
                    >
                      {readOnly ? <Info /> : <Build />}
                    </IconButton>
                  </Tooltip>
                )}
                {!readOnly && deletePositionFunction ? (
                  <Tooltip title={t('POSITION.DELETE_POSITION')}>
                    <IconButton
                      color="default"
                      style={{ margin: '0px', padding: '8px' }}
                      onClick={() => {
                        setShowDeletePositionDialog(true)
                      }}
                      size="large"
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Grid>
            </Grid>
          </DifferenceWrapper>
        </Grid>
        {showProductDetails ? (
          <Grid item xs>
            {<PositionDetailSummary data={data} />}
          </Grid>
        ) : null}
      </Grid>
      <ProductConfigurator
        key={data ? 'ProductConfigurator' + data.id : 'ProductConfigurator'}
        readOnly={readOnly}
        show={showProductConfigurator}
        product={data.productId}
        data={data.details}
        diffData={diffData ? diffData.details : undefined}
        diffObjectType={diffObjectType}
        close={(e) => {
          setShowProductConfigurator(false)
        }}
        updatePositionFunction={(productId, details) => {
          setShowProductConfigurator(false)
          updatePositionFunction(productId, details)
        }}
        parentType={parentType}
      />
      <AgreementDialog
        open={showDeletePositionDialog}
        handleClose={() => {
          setShowDeletePositionDialog(false)
        }}
        agree={() => {
          deletePositionFunction()
        }}
      />
    </nobr>
  )
}
export default withTranslation()(Position)
