import { WarningRounded } from '@mui/icons-material'
import { Grid } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { InfoTooltip } from '../InfoTooltip'

const DifferenceWrapper = (props) => {
  const {
    t,
    difference,
    diffObjectType,
    showDifference,
    differenceDisplay,
    differenceDisplayOverride,
  } = props

  return (
    <Grid container wrap="nowrap">
      {showDifference ? (
        <Grid item xs="auto" style={{ alignSelf: 'center', minWidth: '32px' }}>
          {difference ? (
            <InfoTooltip
              title={
                differenceDisplayOverride
                  ? differenceDisplayOverride
                  : `${
                      diffObjectType === 'REQUEST'
                        ? t('OFFER.DIFFERENCE_ORIGIN')
                        : t('OFFER.DIFFERENCE_COMPARE_OFFER')
                    } ${differenceDisplay}`
              }
            >
              <WarningRounded style={{ color: '#ffe57f' }} />
            </InfoTooltip>
          ) : null}
        </Grid>
      ) : null}
      <Grid item xs style={{ alignSelf: 'center', whiteSpace: 'nowrap' }}>
        {props.children}
      </Grid>
    </Grid>
  )
}

export default withTranslation()(DifferenceWrapper)
