import { Add, Business, DeleteOutline } from '@mui/icons-material'
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryList,
  countryLookup,
  stackHeightUnitLookup,
} from '../../_constants/lookupConstants'
import { reorgArray } from '../../_helpers/little'
import NumberFormatCustom from '../../_helpers/numberFormatCustom'
import { partnerLocationsService } from '../../_services/partnerLocationsService'
import CustomDialogTitle from '../CustomDialogTitle'
import DeliveryTimes from '../DeliveryTimes'
import GoogleAutocomplete from '../GoogleAutocomplete'
import { Section } from '../Section'
const DeliveryDetailsMultipleLocations = (props) => {
  const { t, data, errors, setFieldValue, readOnly, mode, selectedPartnerId } =
    props

  const [selectedPartnerLocation, setSelectedPartnerLocation] = useState(null)

  const [partnerLocations, setPartnerLocations] = useState([])
  var userObject = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    partnerLocationsService
      .getPartnerLocationsOfUser(userObject.user.id)
      .then((partnerLocations) => {
        setPartnerLocations(
          partnerLocations.filter((location) => location.active),
        )

        if (
          mode === 'create' &&
          !data.generalInformation.deliveryDetails.locations[0].company
        ) {
          let primaryLocation = partnerLocations.find(
            (location) => location.primary,
          )

          if (!primaryLocation) {
            primaryLocation = partnerLocations[0]
          }

          setFieldValue('generalInformation.deliveryDetails.locations[0]', {
            ...data.generalInformation.deliveryDetails.locations[0],
            id: 1,
            company: primaryLocation.company,
            countryId: primaryLocation.countryId,
            address: primaryLocation.address,
            zipcode: primaryLocation.zipcode,
            place: primaryLocation.place,
            deliveryInformations: primaryLocation.deliveryInformations
              ? primaryLocation.deliveryInformations
              : '',
            unload: primaryLocation.unload,
            stackHeight: primaryLocation.stackHeight,
            stackHeightUnit: primaryLocation.stackHeightUnit,
            deliveryTimes: primaryLocation.deliveryTimes,
            isDespatchAdviceRequired: primaryLocation.isDespatchAdviceRequired,
            despatchAdviceInstructions:
              primaryLocation.despatchAdviceInstructions,
            partnerLocationId: primaryLocation.id,
          })
        }
      })
  }, [selectedPartnerId])
  const theme = useTheme()

  return (
    <Fragment>
      <Tooltip title={t('REQUEST.DELIVERY_DETAILS.ADD_LOCATION')}>
        <Box style={{ margin: theme.spacing(1), marginTop: theme.spacing(3) }}>
          {!readOnly && (
            <Button
              size="medium"
              color={'secondary'}
              variant={'contained'}
              startIcon={<Add />}
              onClick={async () => {
                let primaryLocation = partnerLocations.find(
                  (location) => location.primary,
                )

                if (!primaryLocation) {
                  primaryLocation = partnerLocations[0]
                }

                var locations =
                  data.generalInformation.deliveryDetails.locations
                locations.push({
                  id: locations.length + 1,
                  company: primaryLocation.company,
                  address: primaryLocation.address,
                  zipcode: primaryLocation.zipcode,
                  place: primaryLocation.place,
                  countryId: primaryLocation.countryId,
                  deliveryInformations: primaryLocation.deliveryInformations
                    ? primaryLocation.deliveryInformations
                    : '',
                  unload: primaryLocation.unload,
                  stackHeight: primaryLocation.stackHeight,
                  stackHeightUnit: primaryLocation.stackHeightUnit,
                  deliveryTimes: primaryLocation.deliveryTimes,
                  isDespatchAdviceRequired:
                    primaryLocation.isDespatchAdviceRequired,
                  despatchAdviceInstructions:
                    primaryLocation.despatchAdviceInstructions,
                  positions: data.positions.map((pos) => {
                    return {
                      ...pos,
                      positionId: pos.id,
                      amount: 0,
                      checked: true,
                    }
                  }),
                  partnerLocationId: primaryLocation.id,
                })

                await setFieldValue(
                  'selectedPartnerLocations',
                  data.selectedPartnerLocations.concat(''),
                )

                await setFieldValue(
                  'generalInformation.deliveryDetails.locations',
                  locations,
                )
              }}
            >
              {t('REQUEST.DELIVERY_DETAILS.ADD_LOCATION')}
            </Button>
          )}
        </Box>
      </Tooltip>
      {_.some(errors.generalInformation) &&
      _.some(errors.generalInformation.deliveryDetails) &&
      typeof errors.generalInformation.deliveryDetails.locations ===
        'string' ? (
        <ErrorMessage
          name={'generalInformation.deliveryDetails.locations'}
          render={(msg) => <div className="help-block">{msg}</div>}
        />
      ) : null}
      {data.generalInformation.deliveryDetails.locations.map(
        (location, index) => (
          <Section
            header={
              <Grid container>
                <Grid item xs style={{ lineHeight: '21px' }}>
                  {t('REQUEST.DELIVERY_DETAILS.LOCATION') + location.id}
                </Grid>
                <Grid item>
                  <Tooltip
                    title={t('REQUEST.DELIVERY_DETAILS.DELETE_LOCATION')}
                  >
                    <IconButton
                      disabled={readOnly}
                      style={{ padding: 0 }}
                      color="default"
                      onClick={() => {
                        var locations =
                          data.generalInformation.deliveryDetails.locations
                        var i = locations.indexOf(location)
                        locations.splice(i, 1)
                        locations = reorgArray(locations, 'id', 1)

                        setFieldValue(
                          'selectedPartnerLocations[' + i + ']',
                          data.selectedPartnerLocations.splice(i, 1),
                        )
                        setFieldValue(
                          'generalInformation.deliveryDetails.locations',
                          locations,
                        )
                      }}
                      size="large"
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            }
            size={'sm'}
          >
            <Grid container spacing={1}>
              {!readOnly && (
                <Grid item xs={12}>
                  <Button
                    onClick={() => setSelectedPartnerLocation(location)}
                    variant={'contained'}
                    color={'secondary'}
                    size={'small'}
                  >
                    {t('REQUEST.DELIVERY_DETAILS.CHOOSE_DELIVERY_ADDRESS')}
                  </Button>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <Field
                  disabled={readOnly}
                  component={TextField}
                  label={t('REQUEST.DELIVERY_DETAILS.COMPANY')}
                  name={
                    'generalInformation.deliveryDetails.locations[' +
                    index +
                    '].company'
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  disabled={readOnly}
                  component={TextField}
                  label={t('REQUEST.DELIVERY_DETAILS.COUNTRY')}
                  name={
                    'generalInformation.deliveryDetails.locations[' +
                    index +
                    '].countryId'
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  select
                >
                  {Object.entries(countryList).map((country) => (
                    <MenuItem value={country[1]} key={country[0]}>
                      {country[0]}
                    </MenuItem>
                  ))}{' '}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <GoogleAutocomplete
                  setFieldValue={setFieldValue}
                  formikName={`generalInformation.deliveryDetails.locations[${index}].address`}
                  formikPath={`generalInformation.deliveryDetails.locations[${index}]`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  disabled={readOnly}
                  component={TextField}
                  label={t('REQUEST.DELIVERY_DETAILS.ZIP_CODE')}
                  name={
                    'generalInformation.deliveryDetails.locations[' +
                    index +
                    '].zipcode'
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  disabled={readOnly}
                  component={TextField}
                  label={t('REQUEST.DELIVERY_DETAILS.PLACE')}
                  name={
                    'generalInformation.deliveryDetails.locations[' +
                    index +
                    '].place'
                  }
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">
                  {t('DELIVERY_DETAILS.POSSIBLE_LOADING')}
                </Typography>
                <ButtonGroup
                  disabled={readOnly}
                  color={'secondary'}
                  sx={{
                    height: '36.7px',
                    marginTop: '8px',
                  }}
                  fullWidth
                >
                  <Button
                    variant={
                      data.generalInformation.deliveryDetails.locations[index]
                        .unload === 'ANY'
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() =>
                      setFieldValue(
                        'generalInformation.deliveryDetails.locations[' +
                          index +
                          '].unload',
                        'ANY',
                      )
                    }
                  >
                    {t('DELIVERY_DETAILS.ANY_UNLOAD')}
                  </Button>
                  <Button
                    variant={
                      data.generalInformation.deliveryDetails.locations[index]
                        .unload === 'RAMP'
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() =>
                      setFieldValue(
                        'generalInformation.deliveryDetails.locations[' +
                          index +
                          '].unload',
                        'RAMP',
                      )
                    }
                  >
                    {t('DELIVERY_DETAILS.RAMP')}
                  </Button>
                  <Button
                    variant={
                      data.generalInformation.deliveryDetails.locations[index]
                        .unload === 'SIDE'
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() =>
                      setFieldValue(
                        'generalInformation.deliveryDetails.locations[' +
                          index +
                          '].unload',
                        'SIDE',
                      )
                    }
                  >
                    {t('DELIVERY_DETAILS.SIDE')}
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Field
                      disabled={readOnly}
                      fullWidth={true}
                      component={TextField}
                      margin="dense"
                      size={'small'}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        endAdornment: (
                          <InputAdornment position="end">
                            {
                              stackHeightUnitLookup[
                                data.generalInformation.deliveryDetails
                                  .locations[index].stackHeightUnit
                              ]
                            }
                          </InputAdornment>
                        ),
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        decimalScale: 0,
                        allowNegative: false,
                      }}
                      label={`${t('DELIVERY_DETAILS.STACK_HEIGHT')} (${t(
                        'GENERAL.OPTIONAL',
                      )})`}
                      variant="outlined"
                      name={
                        'generalInformation.deliveryDetails.locations[' +
                        index +
                        '].stackHeight'
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonGroup
                      disabled={readOnly}
                      color={'secondary'}
                      sx={{
                        height: '36.7px',
                        marginTop: '8px',
                      }}
                      fullWidth
                    >
                      <Button
                        variant={
                          data.generalInformation.deliveryDetails.locations[
                            index
                          ].stackHeightUnit === 'QUANTITY'
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() =>
                          setFieldValue(
                            'generalInformation.deliveryDetails.locations[' +
                              index +
                              '].stackHeightUnit',
                            'QUANTITY',
                          )
                        }
                      >
                        {t('DELIVERY_DETAILS.PIECES')}
                      </Button>
                      <Button
                        variant={
                          data.generalInformation.deliveryDetails.locations[
                            index
                          ].stackHeightUnit === 'CM'
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() =>
                          setFieldValue(
                            'generalInformation.deliveryDetails.locations[' +
                              index +
                              '].stackHeightUnit',
                            'CM',
                          )
                        }
                      >
                        {t('DELIVERY_DETAILS.CENTIMETER')}
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} spacing={1}>
                <Typography variant="caption">
                  {t('DELIVERY_TIMES.LOADING_TIMES')}
                </Typography>
                <DeliveryTimes
                  disabled={readOnly}
                  data={
                    data.generalInformation.deliveryDetails.locations[index]
                      .deliveryTimes
                  }
                  formikName={
                    'generalInformation.deliveryDetails.locations[' +
                    index +
                    '].deliveryTimes'
                  }
                  setFieldValue={setFieldValue}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">
                  {t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Field
                      component={CheckboxWithLabel}
                      type={'checkbox'}
                      color="default"
                      name={`generalInformation.deliveryDetails.locations[${index}].isDespatchAdviceRequired`}
                      Label={{
                        label: t(
                          'DELIVERY_DETAILS.IS_DESPATCH_ADVICE_REQUIRED',
                        ),
                      }}
                      style={{ marginLeft: '15px' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      disabled={
                        readOnly ||
                        !data.generalInformation.deliveryDetails.locations[
                          index
                        ].isDespatchAdviceRequired
                      }
                      name={`generalInformation.deliveryDetails.locations[${index}].despatchAdviceInstructions`}
                      fullWidth
                      margin="dense"
                      label={t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
                      variant="outlined"
                      multiline
                      rows="4"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">
                  {t('DELIVERY_DETAILS.OTHER_INFORMATIONS')}
                </Typography>
                <Field
                  disabled={readOnly}
                  component={TextField}
                  name={
                    'generalInformation.deliveryDetails.locations[' +
                    index +
                    '].deliveryInformations'
                  }
                  fullWidth
                  margin="dense"
                  label={t(
                    'REQUEST.DELIVERY_DETAILS.ADDITIONAL_DELIVERY_INFORMATION',
                  )}
                  variant="outlined"
                  multiline
                  rows="4"
                />
              </Grid>
            </Grid>
          </Section>
        ),
      )}
      <Dialog open={selectedPartnerLocation}>
        <CustomDialogTitle
          title={t('REQUEST.DELIVERY_DETAILS.CHOOSE_DELIVERY_ADDRESS')}
          onClose={() => setSelectedPartnerLocation(null)}
        />
        <DialogContent>
          <List>
            {partnerLocations.map((location) => (
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    let index =
                      data.generalInformation.deliveryDetails.locations.findIndex(
                        (loc) => {
                          return loc.id === selectedPartnerLocation.id
                        },
                      )

                    setFieldValue(
                      `generalInformation.deliveryDetails.locations[${index}].company`,
                      location.company,
                    )
                    setFieldValue(
                      `generalInformation.deliveryDetails.locations[${index}].countryId`,
                      location.countryId,
                    )
                    setFieldValue(
                      `generalInformation.deliveryDetails.locations[${index}].address`,
                      location.address,
                    )
                    setFieldValue(
                      `generalInformation.deliveryDetails.locations[${index}].zipcode`,
                      location.zipcode,
                    )
                    setFieldValue(
                      `generalInformation.deliveryDetails.locations[${index}].place`,
                      location.place,
                    )
                    setFieldValue(
                      `generalInformation.deliveryDetails.locations[${index}].unload`,
                      location.unload,
                    )
                    setFieldValue(
                      `generalInformation.deliveryDetails.locations[${index}].stackHeight`,
                      location.stackHeight,
                    )
                    setFieldValue(
                      `generalInformation.deliveryDetails.locations[${index}].stackHeightUnit`,
                      location.stackHeightUnit,
                    )
                    setFieldValue(
                      `generalInformation.deliveryDetails.locations[${index}].deliveryInformations`,
                      location.deliveryInformations
                        ? location.deliveryInformations
                        : '',
                    )
                    setFieldValue(
                      `generalInformation.deliveryDetails.locations[${index}].isDespatchAdviceRequired`,
                      location.isDespatchAdviceRequired,
                    )
                    setFieldValue(
                      `generalInformation.deliveryDetails.locations[${index}].despatchAdviceInstructions`,
                      location.despatchAdviceInstructions
                        ? location.despatchAdviceInstructions
                        : '',
                    )

                    setFieldValue(
                      `generalInformation.deliveryDetails.locations[${index}].partnerLocationId`,
                      location.id,
                    )

                    setSelectedPartnerLocation(null)
                  }}
                >
                  <ListItemIcon>
                    <Business />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${location.company}, ${location.address}, ${
                      location.zipcode
                    } ${location.place}, ${countryLookup[location.countryId]}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'contained'}
            color={'secondary'}
            onClick={() => {
              setSelectedPartnerLocation(null)
            }}
          >
            {t('GENERAL.CANCEL')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default withTranslation()(DeliveryDetailsMultipleLocations)
