import {
  Chip,
  Dialog,
  DialogContent,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material'
import { Circle } from '@react-google-maps/api'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  automaticOfferPositionStateLookup,
  categorizedProductLookup,
  deliveryTypeLookup,
  productLookup,
  productStateLookup,
} from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import { _applicationService } from '../../../../_services/_applicationService'
import { partnerCommissionGroupsService } from '../../../../_services/partnerCommissionGroupsService'
import CustomDialogTitle from '../../../CustomDialogTitle'
import ResourceMap from '../../../ResourceMap'
import AutomaticOfferPosition from '../AutomaticOffers/_components/AutomaticOfferPosition'

const AutomaticOfferPositionsMap = (props) => {
  const { t } = props

  const [partnerCommissionGroups, setPartnerCommissionGroups] = useState([])
  const [showInactive, setShowInactive] = useState(false)
  const [selectedAutomaticOfferPosition, setSelectedAutomaticOfferPosition] =
    useState(null)

  useEffect(() => {
    if (authorize('administrator')) {
      partnerCommissionGroupsService
        .getPartnerCommissionGroups()
        .then((partnerCommissioNGroups) => {
          setPartnerCommissionGroups(partnerCommissioNGroups)
        })
    }
  }, [])

  const handleShowAutomaticOfferPosition = (automaticOfferPosition) => {
    setSelectedAutomaticOfferPosition(automaticOfferPosition)
  }

  const handleChange = (event) => {
    setShowInactive(event.target.checked)
  }

  return (
    <Fragment>
      {
        <Fragment>
          <ResourceMap
            toolbar={
              <FormControlLabel
                control={
                  <Switch
                    checked={showInactive}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={t('AUTOMATIC_OFFER_POSITIONS_MAP.SHOW_INACTIVE')}
              />
            }
            fetchMethod={(filterValues, pagination) => {
              return _applicationService.getAutomaticOfferPositionsMapData(
                filterValues,
              )
            }}
            iconFunction={(partnerLocation) => {
              let iconUrl = undefined

              if (
                partnerLocation.automaticOfferPositions.some(
                  (automaticOfferPosition) =>
                    automaticOfferPosition.state === 'ACTIVE',
                )
              ) {
                iconUrl = '/icons/map/Pacurion_Iconset_Map_Grün_Erledigt.svg'
              } else {
                iconUrl = '/icons/map/Pacurion_Iconset_Map_Orange_Anfrage.svg'
              }

              return {
                url: iconUrl,
                scaledSize: new window.google.maps.Size(90, 90),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(45, 45),
              }
            }}
            mouseOverFunction={(partnerLocation) => (
              <List
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                }}
              >
                {
                  <Typography>{`${partnerLocation.company} (${partnerLocation.partnerId})`}</Typography>
                }
                {partnerLocation.automaticOfferPositions.map(
                  (automaticOfferPosition) => (
                    <ListItem
                      alignItems="flex-start"
                      secondaryAction={
                        <Chip
                          color={
                            automaticOfferPosition.state === 'ACTIVE'
                              ? 'success'
                              : 'default'
                          }
                          label={
                            automaticOfferPositionStateLookup[
                              automaticOfferPosition.state
                            ]
                          }
                        />
                      }
                    >
                      <ListItemButton
                        onClick={() =>
                          handleShowAutomaticOfferPosition(
                            automaticOfferPosition,
                          )
                        }
                        dense
                      >
                        <ListItemText
                          primary={`${t(
                            'AUTOMATIC_OFFER_POSITIONS_MAP.OFFER_SERVICE_ID',
                          )}: ${automaticOfferPosition.id}`}
                          secondary={`${
                            productLookup[automaticOfferPosition.productId]
                          } ${
                            automaticOfferPosition.positionProperties[0]
                              ? productStateLookup[
                                  automaticOfferPosition.positionProperties[0]
                                    .value
                                ]
                              : ''
                          } (${automaticOfferPosition.maxDistanceInKm} km)`}
                        />
                      </ListItemButton>
                    </ListItem>
                  ),
                )}
              </List>
            )}
            filterId={'AUTOMATIC_OFFER_POSITIONS_MAP'}
            filters={[
              {
                key: 'partnerId.startsWith',
                label: t('AUTOMATIC_OFFER_POSITIONS_MAP.PARTNER_ID'),
                variant: 'textfield',
              },
              {
                key: 'automaticOfferPositions.id.startsWith',
                label: t('AUTOMATIC_OFFER_POSITIONS_MAP.OFFER_SERVICE_ID'),
                variant: 'textfield',
              },
              {
                key: 'automaticOfferPositions.state.eq',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(
                  automaticOfferPositionStateLookup,
                ),
                lookup: automaticOfferPositionStateLookup,
                label: t('AUTOMATIC_OFFER_POSITIONS.STATE'),
              },
              {
                key: 'automaticOfferPositions.productId.eq',
                variant: 'categorizedCheckboxList',
                values: categorizedProductLookup.map((category) => {
                  return {
                    label: category.name,
                    items: category.products.map((product) => product.id),
                  }
                }),
                lookup: productLookup,
                label: t('MARKETPLACE.PRODUCT'),
                shortcut: true,
              },
              {
                key: 'automaticOfferPositions.positionProperties.productPropertyId=21,44,45&automaticOfferPositions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(productStateLookup),
                lookup: productStateLookup,
                label: t('MARKETPLACE.PRODUCT_STATE'),
              },
              {
                key: 'automaticOfferPositions.positionProperties.productPropertyId=22,47,69&automaticOfferPositions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(deliveryTypeLookup),
                lookup: deliveryTypeLookup,
                label: t('MARKETPLACE.DELIVERY_TYPE'),
              },
              {
                key: 'partner.partnerCommissionGroups.id.eq',
                label: t('PARTNER_OVERVIEW.ACCOUNT_MANAGER'),
                variant: 'autocomplete',
                options: partnerCommissionGroups.map(
                  (partnerCommissionGroup) => partnerCommissionGroup.id,
                ),
                lookup: Object.fromEntries(
                  partnerCommissionGroups.map((partnerCommissionGroup) => [
                    partnerCommissionGroup.id,
                    `${partnerCommissionGroup.name}`,
                  ]),
                ),
              },
            ].filter((filter) => filter !== null)}
            sorting={[
              {
                key: 'id',
                default: true,
                label: t('MARKETPLACE.NEWEST_FIRST'),
              },
            ]}
            resourceHeaderRenderFunction={(partnerLocation) =>
              `${partnerLocation.company} (${partnerLocation.partnerId})`
            }
            listHeaderRenderFunction={(partnerLocation) =>
              `${partnerLocation.company} | ${partnerLocation.place}`
            }
            headerActionRenderFunction={(partnerLocation) =>
              partnerLocation.partnerId
            }
            contentRenderFunction={(partnerLocation) => (
              <List
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                }}
                dense={true}
              >
                {partnerLocation.automaticOfferPositions.map(
                  (automaticOfferPosition) => (
                    <ListItem alignItems="flex-start">
                      <ListItemText
                        primary={`${
                          productLookup[automaticOfferPosition.productId]
                        } ${
                          automaticOfferPosition.positionProperties[0]
                            ? productStateLookup[
                                automaticOfferPosition.positionProperties[0]
                                  .value
                              ]
                            : ''
                        } `}
                        secondary={`${automaticOfferPosition.maxDistanceInKm} km`}
                      />
                    </ListItem>
                  ),
                )}
              </List>
            )}
            resourceRenderFunction={(partnerLocation) => (
              <List
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                }}
              >
                {partnerLocation.automaticOfferPositions.map(
                  (automaticOfferPosition) => (
                    <ListItem
                      alignItems="flex-start"
                      secondaryAction={
                        <Chip
                          color={
                            automaticOfferPosition.state === 'ACTIVE'
                              ? 'success'
                              : 'default'
                          }
                          label={
                            automaticOfferPositionStateLookup[
                              automaticOfferPosition.state
                            ]
                          }
                        />
                      }
                    >
                      <ListItemButton
                        onClick={() =>
                          handleShowAutomaticOfferPosition(
                            automaticOfferPosition,
                          )
                        }
                        dense
                      >
                        <ListItemText
                          primary={`${t(
                            'AUTOMATIC_OFFER_POSITIONS_MAP.OFFER_SERVICE_ID',
                          )}: ${automaticOfferPosition.id}`}
                          secondary={`${
                            productLookup[automaticOfferPosition.productId]
                          } ${
                            automaticOfferPosition.positionProperties[0]
                              ? productStateLookup[
                                  automaticOfferPosition.positionProperties[0]
                                    .value
                                ]
                              : ''
                          } (${automaticOfferPosition.maxDistanceInKm} km)`}
                        />
                      </ListItemButton>
                    </ListItem>
                  ),
                )}
              </List>
            )}
            actionsRenderFunction={(partnerLocation) => {
              return []
            }}
            dataIdentifier={'id'}
            googleMapChildrenFunction={(partnerLocation) => {
              let circleArray = []

              partnerLocation.automaticOfferPositions
                .filter(
                  (automaticOfferPosition) =>
                    showInactive || automaticOfferPosition.state === 'ACTIVE',
                )
                .forEach((automaticOfferPosition) => {
                  circleArray.push(
                    <Circle
                      center={{
                        lat: partnerLocation.latitude,
                        lng: partnerLocation.longitude,
                      }}
                      radius={automaticOfferPosition.maxDistanceInKm * 1000}
                      options={{
                        strokeColor:
                          automaticOfferPosition.state === 'ACTIVE'
                            ? '#0b6467'
                            : '#fb5315',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor:
                          automaticOfferPosition.state === 'ACTIVE'
                            ? '#0b6467'
                            : '#fb5315',
                        fillOpacity: 0.15,
                      }}
                    />,
                  )
                })
              return circleArray.map((circle) => circle)
            }}
          />
        </Fragment>
      }
      {selectedAutomaticOfferPosition && (
        <Dialog
          onClose={() => handleShowAutomaticOfferPosition(null)}
          open={true}
          maxWidth={'xl'}
          fullWidth={true}
        >
          <CustomDialogTitle
            title={`${t('AUTOMATIC_OFFER_POSITIONS_MAP.OFFER_SERVICE_ID')} ${
              selectedAutomaticOfferPosition.id
            }`}
            onClose={() => handleShowAutomaticOfferPosition(null)}
          />
          <DialogContent>
            <AutomaticOfferPosition
              automaticOfferPositionId={selectedAutomaticOfferPosition.id}
            />
          </DialogContent>
        </Dialog>
      )}
    </Fragment>
  )
}

export default withTranslation()(AutomaticOfferPositionsMap)
