import { Button, Grid, MenuItem, Step, StepLabel, Stepper } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { Field, Form, Formik, yupToFormErrors } from 'formik'
import { TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import * as Yup from 'yup'
import { alertActions } from '../../../../_actions/alertActions'
import { getTouchedObj, validateLocation } from '../../../../_helpers/little'
import { communitiesService } from '../../../../_services/communitiesService'
import { requestsService } from '../../../../_services/requestsService'
import AgreementDialog from '../../../AgreementDialog'
import Attachments from '../../../Attachments'
import DeliveryDetailsMultipleLocations from '../../../DeliveryDetailsMultipleLocations'
import DeliveryDetailsReplacedComponentsDialog from '../../../DeliveryDetailsReplacedComponentsDialog'
import { FieldErrorWrapper } from '../../../FieldErrorWrapper'
import { Loading } from '../../../Loading'
import PaymentDetails from '../../../PaymentDetails'
import RequestDetails from '../../../RequestDetails'
import ScrollableFullHeightContainer from '../../../ScrollableFullHeightContainer'
import { Section } from '../../../Section'
import TenderPositions from './_components/TenderPositions'

const Tender = (props) => {
  const { t, dispatch } = props

  const [isLoading, setIsLoading] = useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const [agreeDialogOpen, setAgreeDialogOpen] = useState(false)
  const [isInitialValid, setIsInitialValid] = useState(false)
  const [initialValues, setInitialValues] = useState({
    id: undefined,
    type: 'TENDER',
    state: 'DRAFT',
    selectedPartnerLocations: [''],
    generalInformation: {
      deliveryDetails: {
        locations: [
          {
            id: 1,
            company: '',
            address: '',
            zipcode: '',
            place: '',
            countryId: 80,
            deliveryInformations: '',
            positions: [],
            unload: 'ANY',
            stackHeightUnit: 'QUANTITY',
            deliveryTimes: [
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
            ],
            isDespatchAdviceRequired: false,
            despatchAdviceInstructions: '',
          },
        ],
      },
      paymentDetails: {
        paymentTerm: JSON.parse(localStorage.getItem('user')).partner
          .allPaymentTermsAllowed
          ? '14'
          : '0',
        skonto: '0',
        skontoPeriod: '',
        additionalPaymentInformation: '',
      },
      otherDetails: {
        validUntil: null,
        start: null,
        end: null,
        communityId: '',
      },
    },
    positions: [],
    attachments: [],
  })
  const [communities, setCommunities] = useState([])
  const userObject = JSON.parse(localStorage.getItem('user'))
  const partnerId = userObject.partner.id

  useEffect(() => {
    setIsLoading(true)
    communitiesService
      .getCommunitiesOfPartner(partnerId)
      .then((data) => {
        setCommunities(data)
      })
      .catch((error) => {
        console.error('Error fetching communities:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [partnerId])
  const [showHasReplacedComponentsDialog, setShowHasReplacedComponentsDialog] =
    useState(false)
  const [externalAdressValidationResult, setExternalAdressValidationResult] =
    useState(false)
  const [geoData, setGeoData] = useState({})

  const location = useLocation()

  useEffect(() => {
    const search = location.search
    const params = new URLSearchParams(search)
    const id = params.get('id')
    const mode = params.get('mode') || 'create'

    if (id) {
      setIsLoading(true)
      requestsService.getRequest(id).then((data) => {
        if (mode === 'copy') {
          data.id = undefined
          data.state = 'DRAFT'
          data.generalInformation.otherDetails.validUntil = null
          data.generalInformation.otherDetails.start = null
          data.generalInformation.otherDetails.end = null

          if (data.community) {
            data.generalInformation.otherDetails.communityId = data.community.id
          }

          setIsInitialValid(false)
        } else {
          data.generalInformation.otherDetails.validUntil = moment(
            data.generalInformation.otherDetails.validUntil,
          )
          data.generalInformation.otherDetails.start = moment(
            data.generalInformation.otherDetails.start,
          )
          data.generalInformation.otherDetails.end = moment(
            data.generalInformation.otherDetails.end,
          )

          setIsInitialValid(true)
        }
        let selectedPartnerLocations =
          data.generalInformation.deliveryDetails.locations.map(() => {
            return ''
          })

        setInitialValues({
          ...data,
          selectedPartnerLocations: selectedPartnerLocations,
        })
        setIsLoading(false)
      })
    }
  }, [location.search])

  const valSchemas = [
    Yup.object().shape({
      id: Yup.number(),
      generalInformation: Yup.object().shape({
        deliveryDetails: Yup.object().shape({
          locations: Yup.array()
            .of(
              Yup.object()
                .shape({
                  company: Yup.string().required(t('GENERAL.REQUIRED')),
                  address: Yup.string().required(t('GENERAL.REQUIRED')),
                  zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
                  place: Yup.string().required(t('GENERAL.REQUIRED')),
                  countryId: Yup.number().required(t('GENERAL.REQUIRED')),
                  deliveryInformations: Yup.string().nullable(),
                  unload: Yup.string().required(),
                  stackHeight: Yup.number()
                    .nullable(true)
                    .transform((_, val) => (val === Number(val) ? val : null)), //NaN to Null
                  stackHeightUnit: Yup.string().required(),
                  positions: Yup.array().of(
                    Yup.object().shape({
                      targetPrice: Yup.number().min(
                        0.01,
                        t('GENERAL.GREATER_THAN_0'),
                      ),
                      amount: Yup.string()
                        .required(t('GENERAL.REQUIRED'))
                        .typeError(t('GENERAL.REQUIRED'))
                        .min(0.01, t('GENERAL.GREATER_THAN_0'))
                        .test({
                          message: t('GENERAL.TOO_MUCH'),
                          test: function (v) {
                            if (
                              this.parent.unit === 'truck' &&
                              parseFloat(v) > 200
                            ) {
                              return false
                            } else {
                              return true
                            }
                          },
                        }),
                    }),
                  ),
                })
                .test({
                  test: async function (location, context) {
                    let newGeoData = { ...geoData }
                    return userObject.user.partnerLocationIds.length > 0
                      ? true
                      : await validateLocation(
                          location,
                          context,
                          `generalInformation.deliveryDetails.locations[${context.parent.findIndex(
                            (locationInValues) =>
                              locationInValues.id === location.id,
                          )}]`,
                          setExternalAdressValidationResult,
                          setShowHasReplacedComponentsDialog,
                          (validationResult) => {
                            newGeoData[location.id] =
                              validationResult.geocode.location
                            setGeoData(newGeoData)
                          },
                          this,
                          ['route', 'street_number'],
                        )
                  },
                }),
            )
            .min(1, t('GENERAL.MIN_1')),
        }),
        paymentDetails: Yup.object().shape({
          paymentTerm: Yup.string().required(t('GENERAL.REQUIRED')),
          skonto: Yup.string().required(t('GENERAL.REQUIRED')),
          skontoPeriod: Yup.string()
            .nullable()
            .test('required', t('GENERAL.REQUIRED'), function (skontoPeriod) {
              return (
                parseInt(this.parent.skonto) === 0 ||
                (skontoPeriod !== null && skontoPeriod !== '')
              )
            })
            .test(
              'paymentTerm',
              t('REQUEST.PAYMENT_DETAILS.INVALID_REF_PAYMENT_TERM'),
              function (skontoPeriod) {
                return (
                  skontoPeriod === null ||
                  skontoPeriod === '' ||
                  parseInt(skontoPeriod) <= parseInt(this.parent.paymentTerm)
                )
              },
            ),
        }),
        otherDetails: Yup.object().shape({
          start: Yup.date()
            .typeError(t('GENERAL.INVALID_DATE'))
            .required(t('GENERAL.REQUIRED'))
            .test(
              'start',
              t('GENERAL.INVALID_DATE_PAST'),
              (start, testContext) => {
                let state = testContext.options.context.state

                if (start && state === 'DRAFT' && state === 'PUBLISHED') {
                  return moment().diff(moment(start), 'days') <= 0
                } else {
                  return true
                }
              },
            ),
          end: Yup.date()
            .typeError(t('GENERAL.INVALID_DATE'))
            .required(t('GENERAL.REQUIRED'))
            .test('end', t('GENERAL.INVALID_DATE_PAST'), (end, testContext) => {
              let state = testContext.options.context.state

              if (end && state === 'DRAFT' && state === 'PUBLISHED') {
                return moment().diff(moment(end), 'days') <= 0
              } else {
                return true
              }
            })
            .test('end', t('TENDER.INVALID_DATE_REF_START'), function (end) {
              if (this.resolve(Yup.ref('start')) && end) {
                return (
                  moment(this.resolve(Yup.ref('start'))).diff(
                    moment(end),
                    'days',
                  ) <= 0
                )
              } else {
                return true
              }
            }),
          validUntil: Yup.date()
            .typeError(t('GENERAL.INVALID_DATE'))
            .required(t('GENERAL.REQUIRED'))
            .test('start', t('GENERAL.INVALID_DATE_PAST'), (start) => {
              if (start) {
                return moment().diff(moment(start), 'days') <= 0
              } else {
                return true
              }
            }),
          communityId: Yup.number()
            .nullable()
            .transform((_, val) => (val === Number(val) ? val : null)), //NaN to Null,
        }),
      }),
    }),
    Yup.object().shape({
      generalInformation: Yup.object().shape({
        deliveryDetails: Yup.object().shape({
          locations: Yup.array().of(
            Yup.object().shape({
              positions: Yup.array()
                .of(
                  Yup.object().shape({
                    id: Yup.number().required(t('GENERAL.REQUIRED')),
                    productId: Yup.string().required(t('GENERAL.REQUIRED')),
                    productUnitId: Yup.number().required(t('GENERAL.REQUIRED')),
                    amount: Yup.number()
                      .required(t('GENERAL.REQUIRED'))
                      .typeError(t('GENERAL.REQUIRED'))
                      .test({
                        message: t('GENERAL.TOO_MUCH'),
                        test: function (v) {
                          if (
                            this.parent.productUnitId === 3 &&
                            parseFloat(v) > 200
                          ) {
                            return false
                          } else {
                            return true
                          }
                        },
                      }),
                  }),
                )
                .min(1, t('GENERAL.MIN_1')),
            }),
          ),
        }),
      }),
      positions: Yup.array().test({
        message: t('REQUEST.ATLEAST_ONE_OF_EACH_PRODUCT'),
        test: function (positions, context) {
          if (activeStep !== 1) {
            return true
          }

          let locations =
            context.parent.generalInformation.deliveryDetails.locations

          let positionAmountLookup = locations[0].positions.map((pos) => {
            return {
              id: pos.id,
              amount: 0,
            }
          })
          locations.forEach((location) => {
            location.positions.forEach((pos) => {
              positionAmountLookup[
                positionAmountLookup.findIndex(
                  (pLookup) => pLookup.id === pos.id,
                )
              ].amount += pos.amount ? parseFloat(pos.amount) : 0
            })
          })
          return !positionAmountLookup.some((pLookup) => pLookup.amount === 0)
        },
      }),
    }),
  ]

  const handleSubmit = async (state, formikProps) => {
    const { submitForm, setFieldValue, isValid } = formikProps

    if (isValid) {
      await setFieldValue('state', state)
    }
    await submitForm()
  }

  let navigate = useNavigate()

  return (
    <Fragment>
      {(isLoading && <Loading />) || (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          isInitialValid={isInitialValid}
          //validateOnBlur={false}
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            setIsLoading(true)

            let requestBody = {
              ...values,
              generalInformation: {
                ...values.generalInformation,
                deliveryDetails: {
                  ...values.generalInformation.deliveryDetails,
                  locations:
                    values.generalInformation.deliveryDetails.locations.map(
                      (location) => {
                        return {
                          ...location,
                          geoData: geoData[location.id],
                        }
                      },
                    ),
                },
              },
            }

            if (!values.id) {
              let partnerId = userObject.partner.id
              let userId = userObject.user.id
              requestsService
                .createRequestOfPartner(partnerId, userId, requestBody)
                .then((data) => {
                  navigate('/tenders')
                  dispatch(
                    alertActions.info(t('TENDER.PUBLISH_SUCCESSFUL_TENDER')),
                  )
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .catch(function (error) {
                  setSubmitting = false
                  console.log(error)
                  dispatch(alertActions.error(error))
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .finally(() => {
                  setIsLoading(false)
                })
            } else {
              requestsService
                .updateRequest(
                  requestBody,
                  values.id,
                  true,
                  values.generalInformation.deliveryDetails.locations.map(
                    (location) => location.partnerLocationId,
                  ),
                )
                .then((data) => {
                  setSubmitting = false
                  if (!props.selectedPartner) {
                    navigate('/tenders')
                  } else {
                    window.location.reload()
                  }
                  dispatch(
                    alertActions.info(t('TENDER.UPDATE_SUCCESSFUL_TENDER')),
                  )
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .catch(function (error) {
                  setSubmitting = false
                  console.log(error)
                  dispatch(alertActions.error(error))
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .finally(() => {
                  setIsLoading(false)
                })
            }
          }}
        >
          {({
            isSubmitting,
            values,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            errors,
            handleBlur,
            touched,
            submitForm,
            isValid,
            setErrors,
            setTouched,
          }) => (
            <Fragment>
              <Form
                onKeyDown={(keyEvent) => {
                  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    keyEvent.preventDefault()
                  }
                }}
              >
                <Stepper activeStep={activeStep} sx={{ margin: 2 }}>
                  <Step>
                    <StepLabel>{t('REQUEST.GENERAL_INFORMATION')}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{t('REQUEST.PRODUCTS')}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{t('REQUEST.ATTACHMENTS')}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{t('REQUEST.SUMMARY')}</StepLabel>
                  </Step>
                </Stepper>
                <ScrollableFullHeightContainer
                  toolbar={
                    <Fragment>
                      <Button
                        variant="outlined"
                        color="secondary"
                        style={{ margin: '0 10px 0px 10px' }}
                        onClick={() => {
                          if (activeStep === 0) {
                            navigate('/tenders')
                          } else {
                            setActiveStep(
                              (prevActiveStep) => prevActiveStep - 1,
                            )
                          }
                        }}
                      >
                        {t('GENERAL.BACK')}
                      </Button>
                      {activeStep === 3 ? (
                        <Fragment>
                          {values.state !== 'ARCHIVED' ? (
                            <Tooltip
                              title={
                                values.state !== 'DRAFT'
                                  ? t('REQUEST.SAVE_PUBLISHED_DESCRIPTION')
                                  : t('REQUEST.SAVE_NOT_PUBLISHED_DESCRIPTION')
                              }
                            >
                              <Button
                                variant={values.id ? 'contained' : 'outlined'}
                                color={'secondary'}
                                disabled={isSubmitting}
                                onClick={async () =>
                                  handleSubmit('DRAFT', {
                                    submitForm,
                                    setFieldValue,
                                    isValid,
                                  })
                                }
                              >
                                {t('GENERAL.SAVE')}
                              </Button>
                            </Tooltip>
                          ) : null}

                          {values.state === 'DRAFT' ? (
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ margin: '0 10px 0px 10px' }}
                              disabled={isSubmitting}
                              onClick={async () =>
                                handleSubmit('PUBLISHED', {
                                  submitForm,
                                  setFieldValue,
                                  isValid,
                                })
                              }
                            >
                              {t('REQUEST.PUBLISH')}
                            </Button>
                          ) : null}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              if (valSchemas[activeStep]) {
                                valSchemas[activeStep]
                                  .validate(values, {
                                    abortEarly: false,
                                    context: values,
                                  })
                                  .then(function (value) {
                                    setActiveStep(
                                      (prevActiveStep) => prevActiveStep + 1,
                                    )
                                  })
                                  .catch((err) => {
                                    let yupErrors = yupToFormErrors(err)
                                    setErrors(yupErrors)
                                    setTouched(getTouchedObj(yupErrors), false) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                                  })
                              } else {
                                setActiveStep(
                                  (prevActiveStep) => prevActiveStep + 1,
                                )
                              }
                            }}
                          >
                            {t('REQUEST.CONTINUE')}
                          </Button>
                        </Fragment>
                      )}
                    </Fragment>
                  }
                >
                  {activeStep === 0 && (
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <DeliveryDetailsMultipleLocations
                          readOnly={
                            values.state !== 'DRAFT' &&
                            values.state !== 'PUBLISHED'
                          }
                          data={values}
                          errors={errors}
                          setFieldValue={setFieldValue}
                          mode={
                            new URLSearchParams(location.search).get('mode') ||
                            'create'
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <PaymentDetails
                          data={values}
                          setFieldValue={setFieldValue}
                          readOnly={
                            values.state !== 'DRAFT' &&
                            values.state !== 'PUBLISHED'
                          }
                        />
                        <Section header={t('REQUEST.OTHER_DETAILS')}>
                          <Field
                            component={TextField}
                            fullWidth
                            margin="dense"
                            label={t('REQUEST.TITLE')}
                            variant="outlined"
                            name="generalInformation.otherDetails.title"
                          />
                          <FieldErrorWrapper
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                label: t('REQUEST.VALID_UNTIL'),
                                margin: 'dense',
                              },
                            }}
                            name="generalInformation.otherDetails.validUntil"
                            component={DatePicker}
                            inputFormat="DD/MM/YYYY"
                            minDate={moment()}
                          />

                          <FieldErrorWrapper
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                label: t('REQUEST.COLLOBORATION_START'),
                                margin: 'dense',
                              },
                            }}
                            disabled={
                              values.state === 'NEGOTIATION' ||
                              values.state === 'ARCHIVED'
                            }
                            name="generalInformation.otherDetails.start"
                            component={DatePicker}
                            inputFormat="DD/MM/YYYY"
                            minDate={moment()}
                          />
                          <FieldErrorWrapper
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                label: t('REQUEST.COLLOBORATION_END'),
                                margin: 'dense',
                              },
                            }}
                            disabled={
                              values.state === 'NEGOTIATION' ||
                              values.state === 'ARCHIVED'
                            }
                            name="generalInformation.otherDetails.end"
                            component={DatePicker}
                            inputFormat="DD/MM/YYYY"
                            minDate={moment()}
                          />
                          {communities !== null && communities.length > 0 && (
                            <Field
                              component={TextField}
                              name="generalInformation.otherDetails.communityId"
                              select
                              fullWidth
                              label={t('REQUEST.COMMUNITY')}
                              variant="outlined"
                              margin="dense"
                            >
                              <MenuItem value={null}>
                                {t('COMMUNITY.SELECT')}
                              </MenuItem>
                              {communities
                                .filter(
                                  (community) => community.state === 'ACTIVE',
                                )
                                .map((community) => (
                                  <MenuItem
                                    value={community.id}
                                    key={community.id}
                                  >
                                    {community.name}
                                  </MenuItem>
                                ))}
                            </Field>
                          )}
                        </Section>
                      </Grid>
                    </Grid>
                  )}

                  {activeStep === 1 && (
                    <Grid item xs={12}>
                      <TenderPositions
                        data={values}
                        readOnly={
                          values.state !== 'DRAFT' &&
                          values.state !== 'PUBLISHED'
                        }
                        errors={errors}
                        setFieldError={setFieldError}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        valSchema={valSchemas[activeStep]}
                      />
                    </Grid>
                  )}

                  {activeStep === 2 && (
                    <Grid item xs={12}>
                      <Attachments
                        data={values}
                        readOnly={
                          values.state !== 'DRAFT' &&
                          values.state !== 'PUBLISHED'
                        }
                        setFieldValue={setFieldValue}
                        type={'REQUEST'}
                      />
                    </Grid>
                  )}

                  {activeStep === 3 && (
                    <Grid item xs={12}>
                      <RequestDetails communities={communities} data={values} />
                    </Grid>
                  )}
                </ScrollableFullHeightContainer>
              </Form>
              <AgreementDialog
                open={agreeDialogOpen}
                handleClose={() => {
                  setAgreeDialogOpen(!agreeDialogOpen)
                }}
                agree={() => {
                  requestsService.deleteRequest(values.id)
                  navigate('/tenders')
                  dispatch(alertActions.info(t('REQUEST.DELETE_SUCCESSFUL')))
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                }}
              />
              {showHasReplacedComponentsDialog && (
                <DeliveryDetailsReplacedComponentsDialog
                  setAddress={() => {
                    let replacedAddressComponents =
                      externalAdressValidationResult.address.addressComponents.filter(
                        (addressComponent) => addressComponent.replaced,
                      )

                    replacedAddressComponents.forEach((addressComponent) => {
                      switch (addressComponent.componentType) {
                        case 'route':
                        case 'street_number':
                          let adress =
                            externalAdressValidationResult.address.addressComponents.find(
                              (addressComponent) =>
                                addressComponent.componentType === 'route',
                            )
                          let streetNumber =
                            externalAdressValidationResult.address.addressComponents.find(
                              (addressComponent) =>
                                addressComponent.componentType ===
                                'streetNumber',
                            )
                          setFieldValue(
                            `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                              (location) =>
                                location.id ===
                                externalAdressValidationResult.locationId,
                            )}].address`,
                            `${adress} ${streetNumber}`,
                          )
                          break
                        case 'postal_code':
                          setFieldValue(
                            `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                              (location) =>
                                location.id ===
                                externalAdressValidationResult.locationId,
                            )}].zipcode`,
                            addressComponent.componentName.text,
                          )
                          break
                        case 'locality':
                          setFieldValue(
                            `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                              (location) =>
                                location.id ===
                                externalAdressValidationResult.locationId,
                            )}].place`,
                            addressComponent.componentName.text,
                          )
                          break
                        // case 'country':
                        //   break
                        default:
                          break
                      }
                      setFieldValue()
                    })
                  }}
                  addressValidationResult={externalAdressValidationResult}
                  hide={() => setShowHasReplacedComponentsDialog(false)}
                />
              )}
            </Fragment>
          )}
        </Formik>
      )}
    </Fragment>
  )
}
export default withTranslation()(connect()(Tender))
