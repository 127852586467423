import { Delete } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@mui/material'
import { Field, Formik } from 'formik'
import { CheckboxWithLabel } from 'formik-mui'
import React from 'react'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { tenderOrderAttachmentTypeLookup } from '../../../../../../../../_constants/lookupConstants'
import { tenderOrderAttachmentsService } from '../../../../../../../../_services/tenderOrderAttachmentsService'
import CustomDialogTitle from '../../../../../../../CustomDialogTitle'
import { Loading } from '../../../../../../../Loading'

const TenderOrderAttachmentCreateDialog = (props) => {
  const { t, tenderOrderId, show, hide, forceUpdate } = props

  const [selectedAttachmentType, setSelectedAttachmentType] =
    React.useState('OTHER')
  const [isLoading, setIsLoading] = React.useState(false)
  const [activeStep, setActiveStep] = React.useState(0)

  const valSchema = Yup.object().shape({
    attachments: Yup.array().min(1, t('GENERAL.MIN_1')),
  })

  const userObject = JSON.parse(localStorage.getItem('user'))

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Formik
        enableReinitialize={true}
        initialValues={{
          attachments: [],
          isAccessibleForCustomer: true,
          isAccessibleForManufacturer: true,
        }}
        validationSchema={valSchema}
        //validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          let fileUploadPromiseList = []
          values.attachments.forEach(function (attachment) {
            var requestBody = new FormData()

            requestBody.append('file', attachment)
            requestBody.append('userId', userObject.user.id)
            requestBody.append('partnerId', userObject.partner.id)
            requestBody.append('type', selectedAttachmentType)
            requestBody.append(
              'isAccessibleForCustomer',
              values.isAccessibleForCustomer,
            )
            requestBody.append(
              'isAccessibleForManufacturer',
              values.isAccessibleForManufacturer,
            )

            var attachmentPromiseList = new Promise(function (resolve, reject) {
              tenderOrderAttachmentsService
                .createTenderOrderAttachmentOfTenderOrder(
                  tenderOrderId,
                  requestBody,
                )
                .then(function (response) {
                  if (response.ok) {
                    resolve()
                  } else {
                    reject()
                  }
                })
            })

            fileUploadPromiseList.push(attachmentPromiseList)
          })

          Promise.all(fileUploadPromiseList)
            .then((values) => {
              forceUpdate()
              hide()
            })
            .catch((err) => {
              console.error(err)
            })
            .finally(() => {
              setIsLoading(false)
            })
        }}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          submitForm,
          isValid,
          errors,
          touched,
        }) => (
          <Dialog fullWidth={true} maxWidth="xl" open={show}>
            <CustomDialogTitle
              title={'ORDERS.ORDER_ATTACHMENTS.CREATE_ATTACHMENT'}
              onClose={() => hide()}
            />
            <Stepper activeStep={activeStep} sx={{ margin: 2 }}>
              <Step>
                <StepLabel>
                  {t('ORDERS.ORDER_ATTACHMENTS.CHOOSE_TYPE')}
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  {t('ORDERS.ORDER_ATTACHMENTS.UPLOAD_FILES')}
                </StepLabel>
              </Step>
            </Stepper>
            {activeStep === 0 && (
              <DialogContent>
                <Grid container spacing={3}>
                  {['DELIVERY_DOCUMENT', 'OTHER'].map((attachmentType) => (
                    <Grid item xs={12} sm={6}>
                      <Card>
                        <CardActionArea
                          onClick={() => {
                            setSelectedAttachmentType(attachmentType)
                            setActiveStep(1)
                          }}
                        >
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="div"
                            >
                              {tenderOrderAttachmentTypeLookup[attachmentType]}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </DialogContent>
            )}
            {activeStep === 1 && (
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={CheckboxWithLabel}
                      type={'checkbox'}
                      color="default"
                      name={`isAccessibleForCustomer`}
                      Label={{
                        label: t('DELIVERY_DETAILS.IS_ACCESSIBLE_FOR_CUSTOMER'),
                      }}
                      style={{ marginLeft: '15px' }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={CheckboxWithLabel}
                      type={'checkbox'}
                      color="default"
                      name={`isAccessibleForManufacturer`}
                      Label={{
                        label: t(
                          'DELIVERY_DETAILS.IS_ACCESSIBLE_FOR_MANUFACTURER',
                        ),
                      }}
                      style={{ marginLeft: '15px' }}
                    />
                  </Grid>
                </Grid>
                {values.attachments && values.attachments.length > 0 ? (
                  <Grid container spacing={1} style={{ padding: '15px' }}>
                    {values.attachments.map((attachment) => (
                      <Grid item xs={12} lg={3} sm={6}>
                        <Card
                          key={attachment.name}
                          elevation={0}
                          style={{ border: '1px solid #ddd' }}
                        >
                          <Grid container>
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="flex-end"
                              wrap="nowrap"
                              style={{ lineHeight: '27px' }}
                            >
                              <Grid
                                item
                                xs
                                zeroMinWidth
                                style={{
                                  padding: '0 5px',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                <b>{attachment.name}</b>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              xs={12}
                              justifyContent="flex-end"
                              wrap="nowrap"
                              style={{ lineHeight: '27px' }}
                            >
                              <Grid
                                item
                                xs={3}
                                style={{
                                  textAlign: 'center',
                                  backgroundColor: '#ddd',
                                }}
                              >
                                <Tooltip title={t('GENERAL.DELETE')}>
                                  <IconButton
                                    color="default"
                                    size="small"
                                    onClick={() => {
                                      const index =
                                        values.attachments.indexOf(attachment)
                                      let newAttachments = [
                                        ...values.attachments,
                                      ]
                                      newAttachments.splice(index, 1)
                                      setFieldValue(
                                        'attachments',
                                        newAttachments,
                                      )
                                    }}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <div style={{ textAlign: 'center', padding: '10px' }}>
                    {t('REQUEST.NO_ATTACHMENTS')}
                  </div>
                )}
                <Dropzone
                  accept="image/*,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.pdf,.zip,.txt"
                  onDropAccepted={(files) => {
                    let newAttachments = [...values.attachments]
                    setFieldValue('attachments', newAttachments.concat(files))
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        padding: '20px',
                        borderWidth: '2px',
                        borderRadius: '2px',
                        borderStyle: 'dashed',
                        backgroundColor: '#fafafa',
                        color: '#bdbdbd',
                        outline: 'none',
                        transition: 'border .24s ease-in -out',
                        margin: '15px',
                      }}
                      {...getRootProps({ className: 'dropzone' })}
                    >
                      <input {...getInputProps()} />
                      <p>{t('REQUEST.CLICK_HERE_OR_DRAG_FILES_HERE')}</p>
                    </div>
                  )}
                </Dropzone>
              </DialogContent>
            )}

            {activeStep === 0 && (
              <DialogActions>
                <Button
                  onClick={() => hide()}
                  variant={'outlined'}
                  color={'secondary'}
                >
                  {t('ORDERS.ORDER_ATTACHMENTS.CANCEL')}
                </Button>
              </DialogActions>
            )}
            {activeStep === 1 && (
              <DialogActions>
                <Button
                  onClick={() => setActiveStep(0)}
                  variant={'outlined'}
                  color={'secondary'}
                >
                  {t('ORDERS.ORDER_ATTACHMENTS.BACK')}
                </Button>
                <Button
                  onClick={() => submitForm()}
                  disabled={!isValid || isSubmitting}
                  type={'submit'}
                  variant={'contained'}
                  color={'secondary'}
                >
                  {t('ORDERS.ORDER_ATTACHMENTS.CONFIRM')}
                </Button>
              </DialogActions>
            )}
          </Dialog>
        )}
      </Formik>
    )
  )
}

export default withTranslation()(TenderOrderAttachmentCreateDialog)
