import {
  Handshake,
  LocalOffer,
  SmartToy,
  Sort,
  WarningRounded,
} from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Popover,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'

import { get } from 'lodash'
import numeral from 'numeral'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryLookup,
  offerStateLookup,
} from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import { requestPositionsService } from '../../../../_services/requestPositionsService'
import AcceptJobOfferDialog from '../../../AcceptJobOfferDialog'
import CounterOfferTargetPriceDialog from '../../../CounterOfferTargetPriceDialog'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { CustomRating } from '../../../CustomRating'
import { InfoTooltip } from '../../../InfoTooltip'
import JobOfferView from '../../../JobOfferView'
import { Loading } from '../../../Loading'
import OfferStepperDialog from '../../../OfferStepper/_components/OfferStepperDialog'
import Rank from '../../../Rank'
import Badges from './_components/Badges'

const RequestPositionComparison = (props) => {
  const { t, requestPosition, forceUpdate, jobOffer } = props

  const [isLoading, setIsLoading] = useState(true)
  const [offerPositions, setOfferPositions] = useState([])
  const [selectedOfferPosition, setSelectedOfferPosition] = React.useState(null)
  const [showOffer, setShowOffer] = React.useState(null)

  const [showAcceptJobOfferDialog, setShowAcceptJobOfferDialog] =
    useState(false)

  const [showJobOffer, setShowJobOffer] = React.useState(false)
  const [targetPrice, setTargetPrice] = useState('')
  const [isTop3FilterSelected, setIsTop3FilterSelected] = useState(false)
  const [showInactiveOffers, setShowInactiveOffers] = useState(false)
  const [
    showCounterOfferTargetPriceDialog,
    setShowCounterOfferTargetPriceDialog,
  ] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [showSort, setShowSort] = useState(false)
  const [selectedSort, setSelectedSort] = useState(
    requestPosition.request.type !== 'ADVERT' ? 'rank' : 'price',
  )
  const handleOpenDialog = () => {
    setShowCounterOfferTargetPriceDialog(true)
  }
  const theme = useTheme()

  useEffect(() => {
    if (requestPosition) {
      setIsLoading(true)
      requestPositionsService
        .getOfferPositionsOfRequestPosition(requestPosition.id)
        .then((data) => {
          setOfferPositions(data)

          setTargetPrice(
            requestPosition.targetPrice
              ? requestPosition.targetPrice
              : undefined,
          )
        })
        .finally(() => setIsLoading(false))
    }
  }, [requestPosition])

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Fragment>
        <Button
          variant={'contained'}
          color={'secondary'}
          size={'small'}
          startIcon={<PriceCheckIcon />}
          onClick={handleOpenDialog}
          sx={{ marginLeft: 2, marginTop: 1, marginBottom: 1 }}
        >
          {targetPrice ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>{t('REQUEST_POSITION_COMPARISON.TARGET_PRICE_EDIT')}</div>
              <div>
                {`${t(
                  'REQUEST_POSITION_COMPARISON.TARGET_PRICE_NOW',
                )}: ${numeral(parseFloat(targetPrice)).format('0,0.00 $')}`}
              </div>
            </div>
          ) : (
            t('REQUEST_POSITION_COMPARISON.TARGET_PRICE_CREATE')
          )}
        </Button>
        {jobOffer && ['ACTIVE', 'READY'].includes(jobOffer.state) && (
          <Button
            variant={'contained'}
            color={'secondary'}
            size={'small'}
            startIcon={<LocalOffer />}
            onClick={() => {
              setShowJobOffer(true)
            }}
            sx={{ marginLeft: 2, marginTop: 1, marginBottom: 1 }}
          >
            {`${t('REQUEST_POSITION_COMPARISON.BUY_NOW')} ${numeral(
              parseFloat(jobOffer.positions[0].price),
            ).format('0,0.00 $')}`}
          </Button>
        )}
        <Toolbar>
          <Fragment>
            <Button
              style={{
                verticalAlign: 'middle',
                marginRight: theme.spacing(2),
              }}
              size={'small'}
              variant={'text'}
              color={'secondary'}
              startIcon={<Sort />}
              onClick={(e) => {
                setAnchorEl(e.currentTarget)
                setShowSort(true)
              }}
            >
              {t('RESOURCE_LIST.SORT_BY')}
            </Button>
            <Popover
              onClose={() => {
                setShowSort(false)
              }}
              anchorEl={anchorEl}
              open={showSort}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <RadioGroup
                sx={{ margin: theme.spacing(1) }}
                value={selectedSort}
                onChange={(e) => {
                  setSelectedSort(e.target.value)
                }}
              >
                {requestPosition.request.type !== 'ADVERT' ? (
                  <FormControlLabel
                    value={'rank'}
                    control={<Radio />}
                    label={t('REQUEST_POSITION_COMPARISON.RANK')}
                  />
                ) : (
                  <FormControlLabel
                    value={'price'}
                    control={<Radio />}
                    label={t('REQUEST_POSITION_COMPARISON.PRICE')}
                  />
                )}
                {requestPosition.request.type !== 'ADVERT' && (
                  <FormControlLabel
                    value={'manufacturerRating'}
                    control={<Radio />}
                    label={t('REQUEST_POSITION_COMPARISON.RATING')}
                  />
                )}
              </RadioGroup>
            </Popover>
          </Fragment>
          <FormControlLabel
            sx={{ marginBottom: 0 }}
            control={<Checkbox />}
            label={t('REQUEST_POSITION_COMPARISON.TOP_3')}
            checked={isTop3FilterSelected}
            onChange={(e) => setIsTop3FilterSelected(!isTop3FilterSelected)}
          />
          <FormControlLabel
            sx={{ marginBottom: 0 }}
            control={<Checkbox />}
            label={t('REQUEST_POSITION_COMPARISON.SHOW_INACTIVE_OFFERS')}
            checked={showInactiveOffers}
            onChange={(e) => setShowInactiveOffers(!showInactiveOffers)}
          />
        </Toolbar>
        <Grid container spacing={1}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {requestPosition.request.type !== 'ADVERT' && (
                  <TableCell style={{ verticalAlign: 'center' }}>
                    {t('REQUEST_POSITION_COMPARISON.RANK')}
                  </TableCell>
                )}
                {requestPosition.request.type !== 'ADVERT' && (
                  <InfoTooltip
                    title={t('REQUEST_POSITION_COMPARISON.BADGES_EXPLANATION')}
                  >
                    <TableCell style={{ verticalAlign: 'center' }}>
                      {t('REQUEST_POSITION_COMPARISON.BADGES')}
                    </TableCell>
                  </InfoTooltip>
                )}
                <TableCell style={{ verticalAlign: 'center' }}>
                  {t('REQUEST_POSITION_COMPARISON.PRICE')}
                </TableCell>
                {authorize('administrator') && (
                  <TableCell style={{ verticalAlign: 'center' }}>
                    {requestPosition.request.type !== 'ADVERT'
                      ? t('REQUEST_POSITION_COMPARISON.PURCHASE_PRICE')
                      : t('REQUEST_POSITION_COMPARISON.SALES_PRICE')}
                  </TableCell>
                )}
                {requestPosition.request.type !== 'ADVERT' && (
                  <TableCell style={{ verticalAlign: 'center' }}>
                    {t('REQUEST_POSITION_COMPARISON.RATING')}
                  </TableCell>
                )}
                {(authorize('administrator') ||
                  requestPosition.request.communityId) && (
                  <TableCell style={{ verticalAlign: 'center' }}>
                    {requestPosition.request.type !== 'ADVERT'
                      ? t('REQUEST_POSITION_COMPARISON.MANUFACTURER')
                      : t('REQUEST_POSITION_COMPARISON.PARTNER')}
                  </TableCell>
                )}
                {requestPosition.request.type !== 'ADVERT' && (
                  <TableCell style={{ verticalAlign: 'center' }}>
                    {t('REQUEST_POSITION_COMPARISON.MANUFACTURER_COUNTRY')}
                  </TableCell>
                )}
                <TableCell style={{ verticalAlign: 'center' }}>
                  {t('REQUEST_POSITION_COMPARISON.OFFER_ID')}
                </TableCell>
                <TableCell style={{ verticalAlign: 'center' }}>
                  {t('REQUEST_POSITION_COMPARISON.STATE')}
                </TableCell>
                <TableCell style={{ verticalAlign: 'center' }}>
                  {t('REQUEST_POSITION_COMPARISON.ACTIONS')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                cursor: 'pointer',
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              {offerPositions
                .sort((a, b) => {
                  let aValue = get(a, selectedSort, 0)
                  let bValue = get(b, selectedSort, 0)

                  if (selectedSort === 'manufacturerRating') {
                    aValue = get(a, selectedSort, 0) * -1
                    bValue = get(b, selectedSort, 0) * -1
                  }

                  if (
                    requestPosition.request.type === 'ADVERT' &&
                    selectedSort === 'price'
                  ) {
                    aValue = parseFloat(a.price)
                    bValue = parseFloat(b.price)
                    return bValue - aValue
                  }

                  if (selectedSort === 'rank') {
                    if (aValue === '3+') {
                      aValue = 4 + parseFloat(a.price)
                    }

                    if (bValue === '3+') {
                      bValue = 4 + parseFloat(b.price)
                    }

                    if (aValue === 'N/A') {
                      aValue = 5 + parseFloat(a.price)
                    }

                    if (bValue === 'N/A') {
                      bValue = 5 + parseFloat(b.price)
                    }

                    if (a.isPositionDifferent) {
                      aValue = aValue < 4 ? aValue : aValue * 10000000
                    }

                    if (b.isPositionDifferent) {
                      bValue = bValue < 4 ? bValue : bValue * 10000000
                    }
                  } else {
                    if (!aValue) {
                      aValue = 0
                    }

                    if (!bValue) {
                      bValue = 0
                    }

                    if (a.isPositionDifferent) {
                      aValue = aValue + 10000000
                    }

                    if (b.isPositionDifferent) {
                      bValue = bValue + 10000000
                    }
                  }

                  return aValue - bValue
                })
                .filter((offerPositon) =>
                  showInactiveOffers
                    ? true
                    : !['EXPIRED', 'CANCLED'].includes(
                        offerPositon.offer.state,
                      ),
                )
                .slice(0, isTop3FilterSelected ? 3 : offerPositions.length)
                .map((offerPosition) => {
                  const manufacturer = offerPosition.manufacturer

                  if (requestPosition.request.type !== 'ADVERT') {
                    var averageRating =
                      (parseFloat(manufacturer.averageQuality) +
                        parseFloat(manufacturer.averageReliability)) /
                      2
                  }
                  return (
                    <TableRow
                      hover
                      key={offerPosition.offer.id}
                      onClick={() => {
                        setSelectedOfferPosition(offerPosition)
                        setShowOffer(true)
                      }}
                    >
                      {requestPosition.request.type !== 'ADVERT' && (
                        <TableCell style={{ verticalAlign: 'center' }}>
                          <Rank rank={offerPosition.rank} />
                        </TableCell>
                      )}
                      {requestPosition.request.type !== 'ADVERT' && (
                        <TableCell style={{ verticalAlign: 'center' }}>
                          <Badges partnerId={manufacturer.id} />
                        </TableCell>
                      )}
                      <TableCell style={{ verticalAlign: 'center' }}>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item>
                            <Typography
                              sx={{ fontWeight: 700, fontSize: '0.675rem' }}
                            >
                              {numeral(parseFloat(offerPosition.price)).format(
                                '0,0.00 $',
                              )}
                            </Typography>
                          </Grid>
                          <InfoTooltip
                            title={t(
                              'REQUEST_POSITION_COMPARISON.IN_NEGOTIATION',
                            )}
                          >
                            <Grid item>
                              {offerPosition.negotiatedPrice && (
                                <Fragment>
                                  <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                  >
                                    <Grid item>
                                      <Handshake color={'primary'} />
                                    </Grid>
                                    <Grid item>
                                      <Typography
                                        sx={{
                                          fontWeight: 700,
                                          fontSize: '0.675rem',
                                        }}
                                      >
                                        {numeral(
                                          parseFloat(
                                            offerPosition.negotiatedPrice,
                                          ),
                                        ).format('0,0.00 $')}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Fragment>
                              )}
                            </Grid>
                          </InfoTooltip>
                        </Grid>
                      </TableCell>
                      {authorize('administrator') && (
                        <TableCell style={{ verticalAlign: 'center' }}>
                          {((requestPosition.request.type !== 'ADVERT' &&
                            offerPosition.purchasePrice) ||
                            (requestPosition.request.type === 'ADVERT' &&
                              offerPosition.salesPrice)) && (
                            <Grid container spacing={1} alignItems="center">
                              <Grid item>
                                <Typography
                                  sx={{ fontWeight: 100, fontSize: '0.6rem' }}
                                >
                                  {numeral(
                                    parseFloat(
                                      requestPosition.request.type !== 'ADVERT'
                                        ? offerPosition.purchasePrice
                                        : offerPosition.salesPrice,
                                    ),
                                  ).format('0,0.00 $')}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        </TableCell>
                      )}
                      {requestPosition.request.type !== 'ADVERT' &&
                        (manufacturer.averageQuality ? (
                          <InfoTooltip
                            title={
                              <table>
                                <thead>
                                  <th
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {t('OFFERS.PARTNER_RATING')}
                                  </th>
                                  <th>
                                    {numeral(
                                      (parseFloat(manufacturer.averageQuality) +
                                        parseFloat(
                                          manufacturer.averageReliability,
                                        )) /
                                        2,
                                    ).format('0,0.00')}
                                  </th>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      style={{
                                        borderRight: 'solid 15px transparent',
                                      }}
                                    >
                                      {t('OFFERS.RATING_QUALITY')}
                                    </td>
                                    <td>
                                      {numeral(
                                        parseFloat(manufacturer.averageQuality),
                                      ).format('0,0.00')}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        borderRight: 'solid 15px transparent',
                                      }}
                                    >
                                      {t('OFFERS.RATING_RELIABILITY')}
                                    </td>
                                    <td>
                                      {numeral(
                                        parseFloat(
                                          manufacturer.averageReliability,
                                        ),
                                      ).format('0,0.00')}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      style={{
                                        borderRight: 'solid 15px transparent',
                                      }}
                                    >
                                      {t('OFFERS.TOTAL_RECEIVED_RATINGS')}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: 'solid 15px transparent',
                                      }}
                                    >
                                      {manufacturer.averageRatingAmount}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            }
                          >
                            <TableCell style={{ verticalAlign: 'center' }}>
                              <CustomRating
                                size={'small'}
                                name="read-only"
                                value={averageRating}
                                readOnly
                                precision={0.5}
                              />
                            </TableCell>
                          </InfoTooltip>
                        ) : (
                          <TableCell style={{ verticalAlign: 'center' }}>
                            <Typography variant="caption">
                              {t('OFFERS.NOT_ENOUGH_RATINGS_YET')}
                            </Typography>
                          </TableCell>
                        ))}
                      {requestPosition.request.type === 'ADVERT' &&
                        (requestPosition.request.communityId ||
                          authorize('administrator')) && (
                          <TableCell
                            style={{
                              verticalAlign: 'middle',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {offerPosition.offer.partner &&
                              offerPosition.offer.partner.name && (
                                <Fragment>
                                  {offerPosition.offer.request.community && (
                                    <div
                                      style={{
                                        display: 'inline-block',
                                        width: '25%',
                                        maxWidth: '100px',
                                      }}
                                    >
                                      <InfoTooltip
                                        disableTouchListener
                                        disableFocusListener
                                        title={t(
                                          'COMMUNITY.COMMUNITY_REQUEST_COMPARISON_ICON',
                                        )}
                                      >
                                        <GroupsIcon color={'secondary'} />
                                      </InfoTooltip>
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      width: '75%',
                                      display: 'inline-block',
                                      maxWidth: '120px',
                                    }}
                                  >
                                    {`${offerPosition.offer.partner.name}`}
                                  </div>
                                </Fragment>
                              )}
                          </TableCell>
                        )}
                      {(requestPosition.request.communityId ||
                        authorize('administrator')) &&
                        requestPosition.request.type !== 'ADVERT' && (
                          <TableCell
                            style={{
                              verticalAlign: 'middle',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {offerPosition.offer.partner &&
                              offerPosition.offer.partner.name && (
                                <>
                                  <div
                                    style={{
                                      width: '25%',
                                      display: 'inline-block',
                                    }}
                                  >
                                    {offerPosition.offer.request.community && (
                                      <InfoTooltip
                                        disableTouchListener
                                        disableFocusListener
                                        title={t(
                                          'COMMUNITY.COMMUNITY_REQUEST_COMPARISON_ICON',
                                        )}
                                      >
                                        <GroupsIcon color={'secondary'} />
                                      </InfoTooltip>
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      width: '75%',
                                      display: 'inline-block',
                                    }}
                                  >
                                    {`${offerPosition.offer.partner.name} (${offerPosition.offer.partner.id})`}
                                  </div>
                                </>
                              )}
                          </TableCell>
                        )}
                      {requestPosition.request.type !== 'ADVERT' && (
                        <TableCell style={{ verticalAlign: 'center' }}>
                          {countryLookup[manufacturer.countryId]}
                        </TableCell>
                      )}
                      <TableCell style={{ verticalAlign: 'center' }}>
                        {offerPosition.rootOfferId}
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'center' }}>
                        {offerStateLookup[offerPosition.offer.state]}
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'center' }}>
                        <Button
                          variant={'contained'}
                          color={'secondary'}
                          size={'small'}
                          startIcon={<LocalOffer />}
                        >
                          {t('REQUEST_POSITION_COMPARISON.OPEN_OFFER')}
                        </Button>
                      </TableCell>
                      <TableCell style={{ verticalAlign: 'center' }}>
                        {offerPosition.isPositionDifferent && (
                          <InfoTooltip
                            title={t(
                              'REQUEST_POSITION_COMPARISON.DIFFERENCE_TO_REQUEST_POSITION',
                            )}
                          >
                            <WarningRounded style={{ color: '#ffe57f' }} />
                          </InfoTooltip>
                        )}
                        {offerPosition.automaticOfferPositionId && (
                          <Tooltip
                            title={`${t(
                              'OFFERS.CREATED_BY_OFFER_SERVICE_WITH_ID',
                            )}: ${offerPosition.automaticOfferPositionId}`}
                          >
                            <SmartToy color="primary" sx={{ marginRight: 1 }} />
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </Grid>
        <OfferStepperDialog
          showOffer={showOffer}
          setShowOffer={setShowOffer}
          selectedOfferPosition={selectedOfferPosition}
          setSelectedOfferPosition={setSelectedOfferPosition}
          forceUpdate={forceUpdate}
          requestPosition={requestPosition}
          setIsLoading={setIsLoading}
        />
        {jobOffer && (
          <Dialog open={showJobOffer} fullWidth={true} maxWidth="lg">
            <CustomDialogTitle
              title={t('JOB_OFFERS.OFFER_OVERVIEW')}
              onClose={() => setShowJobOffer(false)}
            />
            {!['READY', 'ACTIVE'].includes(jobOffer.state) ? (
              <Alert severity="error" sx={{ margin: 5 }}>
                <AlertTitle>{t('JOB_OFFER.OFFER_NO_LONGER_ACTIVE')}</AlertTitle>
                {t('JOB_OFFER.OFFER_NO_LONGER_ACTIVE_DESCRIPTION')}
              </Alert>
            ) : (
              <Fragment>
                <DialogContent>
                  <JobOfferView data={jobOffer} />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant={'outlined'}
                    color="secondary"
                    style={{ margin: '0 10px 0px 10px' }}
                    onClick={() => {
                      setShowJobOffer(false)
                    }}
                  >
                    {t('GENERAL.CANCEL')}
                  </Button>
                  <Button
                    variant={'contained'}
                    color="secondary"
                    style={{ margin: '0 10px 0px 10px' }}
                    onClick={() => {
                      setShowAcceptJobOfferDialog(true)
                    }}
                  >
                    {t('JOB_OFFERS.ACCEPT_OFFER')}
                  </Button>
                </DialogActions>
              </Fragment>
            )}
          </Dialog>
        )}

        {showAcceptJobOfferDialog && (
          <AcceptJobOfferDialog
            jobOffer={jobOffer}
            hide={() => setShowAcceptJobOfferDialog(false)}
            forceUpdate={() => forceUpdate()}
          />
        )}
        {showCounterOfferTargetPriceDialog && (
          <CounterOfferTargetPriceDialog
            requestId={requestPosition.requestId}
            show={showCounterOfferTargetPriceDialog}
            hide={() => setShowCounterOfferTargetPriceDialog(false)}
            forceUpdateCount={forceUpdate}
            forceUpdate={() => forceUpdate(forceUpdate + 1)}
          />
        )}
      </Fragment>
    )
  )
}

export default withTranslation()(RequestPositionComparison)
