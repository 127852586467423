import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const orderAttachmentsService = {
  getOrderAttachment,
  deleteOrderAttachment,
  createOrderAttachmentOfOrder,
  getOrderAttachmentOfOrder,
}

function getOrderAttachment(attachmentId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/order-attachments/${attachmentId}`,
    requestOptions,
  )
}

function deleteOrderAttachment(attachmentId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/order-attachments/${attachmentId}`,
    requestOptions,
  ).then(function (response) {
    if (response.ok) {
      return true
    } else {
      return false
    }
  })
}

function createOrderAttachmentOfOrder(orderId, requestBody) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: requestBody,
  }

  return fetch(
    `${config.apiUrlBase}/orders/` + orderId + '/order-attachments',
    requestOptions,
  )
}

function getOrderAttachmentOfOrder(orderId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/orders/${orderId}/order-attachments`,
    requestOptions,
  ).then(handleResponse)
}

export * from './orderAttachmentsService'
