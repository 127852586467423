import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const usersService = {
  getUsersOfPartner,
  createUserOfPartner,
  deleteUser,
  getUser,
  updateUser,
}

function getUser(userId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/users/${userId}`, requestOptions).then(
    handleResponse,
  )
}

function updateUser(userId, requestBody, token) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }
  return fetch(
    token
      ? `${config.apiUrlBase}/users/${userId}?token=${token}`
      : `${config.apiUrlBase}/users/${userId}`,
    requestOptions,
  ).then(handleResponse)
}

function getUsersOfPartner(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/users`,
    requestOptions,
  ).then(handleResponse)
}

function createUserOfPartner(partnerId, user) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/users`,
    requestOptions,
  ).then(handleResponse)
}

function deleteUser(userId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(`${config.apiUrlBase}/users/${userId}`, requestOptions).then(
    handleResponse,
  )
}

export * from './usersService'
