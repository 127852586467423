import { Close } from '@mui/icons-material'
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Numeral from 'numeral'
import React, { Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom'
import './App.css'
import { alertActions } from './_actions/alertActions'
import { Loading } from './_components/Loading'
import APIOverview from './_components/_app/_components/APIOverview'
import AdminSettings from './_components/_app/_components/AdminSettings'
import AdminUserOverview from './_components/_app/_components/AdminUserOverview'
import Advert from './_components/_app/_components/Advert'
import Adverts from './_components/_app/_components/Adverts'
import AdvertsExchange from './_components/_app/_components/AdvertsExchange'
import AutomaticOfferPositionsMap from './_components/_app/_components/AutomaticOfferPositionsMap'
import AutomaticOffers from './_components/_app/_components/AutomaticOffers'
import BreadcrumbsRouted from './_components/_app/_components/BreadcrumbsRouted'
import Charts from './_components/_app/_components/Charts'
import CommunitiesOverview from './_components/_app/_components/CommunitiesOverview'
import Dashboard from './_components/_app/_components/Dashboard'
import Documents from './_components/_app/_components/Documents'
import Indexes from './_components/_app/_components/Indexes'
import JobOffersOfPartner from './_components/_app/_components/JobOffersOfPartner'
import Marketplace from './_components/_app/_components/Marketplace'
import NavigationBar from './_components/_app/_components/NavigationBar'
import Offers from './_components/_app/_components/Offers'
import OffersAdverts from './_components/_app/_components/OffersAdverts'
import Orders from './_components/_app/_components/Orders'
import Overviews from './_components/_app/_components/Overviews'
import PartnerChecker from './_components/_app/_components/PartnerChecker'
import PartnerOverviewMap from './_components/_app/_components/PartnerOverviewMap'
import PartnerSettings from './_components/_app/_components/PartnerSettings'
import PriceChecker from './_components/_app/_components/PriceChecker'
import ProductCategoryEditor from './_components/_app/_components/ProductCategoryEditor'
import ProductEditor from './_components/_app/_components/ProductEditor'
import ProductPropertyCategoryEditor from './_components/_app/_components/ProductPropertyCategoryEditor'
import ProductPropertyEditor from './_components/_app/_components/ProductPropertyEditor'
import ProductUnitEditor from './_components/_app/_components/ProductUnitEditor'
import Register from './_components/_app/_components/Register'
import Registrations from './_components/_app/_components/Registrations'
import Request from './_components/_app/_components/Request'
import Requests from './_components/_app/_components/Requests'
import Sidebar from './_components/_app/_components/Sidebar'
import SpotMarketplace from './_components/_app/_components/SpotMarketplace'
import Tender from './_components/_app/_components/Tender'
import TenderOrders from './_components/_app/_components/TenderOrders'
import Unsubscribe from './_components/_app/_components/Unsubscribe'
import UserMailSettings from './_components/_app/_components/UserMailSettings'
import UserSettings from './_components/_app/_components/UserSettings'
import Users from './_components/_app/_components/Users'
import Visitors from './_components/_app/_components/Visitors'
import { authorize } from './_helpers/authorization'
import useHubspotChat from './_hooks/useHubspotChat'
import { loginService } from './_services/loginService'
import keycloak from './keycloak'

Numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : '?me'
  },
  currency: {
    symbol: '\u20AC',
  },
})

Numeral.locale('de')

const App = (props) => {
  const { dispatch, alert } = props
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [drawerOpen, setDrawerOpen] = useState(isMobile ? false : true)
  const [isOverlayVisible, setOverlayVisible] = useState(
    isMobile ? false : true,
  )
  const [loading, setLoading] = useState(true)

  const { hasLoaded, initializeChat, isChatOpen, setIsChatOpen } =
    useHubspotChat('25833552')

  useEffect(() => {
    if (
      keycloak.authenticated &&
      (!localStorage.getItem('user') ||
        JSON.parse(localStorage.getItem('user')).user.email.toLowerCase() !==
          keycloak.tokenParsed.preferred_username.toLowerCase() ||
        !JSON.parse(localStorage.getItem('user')).user.partnerLocationIds ||
        !JSON.parse(localStorage.getItem('user')).user
          .partnerInvoiceLocationIds)
    ) {
      loginService.login().then(() => {
        setLoading(false)
        initializeChat()
      })
    } else {
      setLoading(false)
      initializeChat()
    }
    const params = new URLSearchParams(window.location.search)
    let url = params.get('HOOK_URL')
    if (url) {
      sessionStorage.setItem('HOOK_URL', url)
    }
  }, [])

  const handleDrawer = () => {
    if (drawerOpen && !isMobile) {
      setDrawerOpen(false)
    } else {
      setDrawerOpen(!drawerOpen)
      if (isMobile) {
        setOverlayVisible(!isOverlayVisible)
      }
    }
  }

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1200,
    display: isOverlayVisible ? 'block' : 'none',
  }

  return loading ? (
    <Loading />
  ) : (
    <Router>
      {isMobile && isOverlayVisible && (
        <div style={overlayStyle} onClick={handleDrawer} />
      )}
      <Suspense fallback={<Loading />}>
        <NavigationBar setIsChatOpen={setIsChatOpen} />
        {keycloak.authenticated && (
          <Sidebar
            drawerOpen={drawerOpen}
            handleDrawer={handleDrawer}
            variant={isMobile ? 'temporary' : 'permanent'}
          />
        )}
        {alert.hasOwnProperty('type') && (
          <Alert
            id="alert"
            className={
              keycloak.authenticated
                ? !drawerOpen
                  ? 'drawerClosed'
                  : 'drawerOpen'
                : ''
            }
            severity={alert.type}
            onClose={() => {
              dispatch(alertActions.clear())
            }}
            icon={false}
          >
            <Box paddingLeft={'20px'}>{alert.message}</Box>
          </Alert>
        )}
        <Container
          maxWidth={false}
          id="content"
          className={
            (keycloak.authenticated
              ? !drawerOpen
                ? 'drawerClosed'
                : 'drawerOpen'
              : '') +
            ' ' +
            (alert.hasOwnProperty('type') === true ? 'alertOpen' : '')
          }
        >
          {keycloak.authenticated && <BreadcrumbsRouted />}
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/job-offers" element={<JobOffersOfPartner />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route
              path="/product-editor"
              element={
                <RequireAuth>
                  <ProductEditor />
                </RequireAuth>
              }
            />
            <Route
              path="/product-unit-editor"
              element={
                <RequireAuth>
                  <ProductUnitEditor />
                </RequireAuth>
              }
            />
            <Route
              path="/product-category-editor"
              element={
                <RequireAuth>
                  <ProductCategoryEditor />
                </RequireAuth>
              }
            />
            <Route
              path="/product-property-editor"
              element={
                <RequireAuth>
                  <ProductPropertyEditor />
                </RequireAuth>
              }
            />
            <Route
              path="/product-property-category-editor"
              element={
                <RequireAuth>
                  <ProductPropertyCategoryEditor />
                </RequireAuth>
              }
            />
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
            <Route
              path="/deliveries-customer"
              element={
                <RequireAuth>
                  <Orders key={'deliveries-customer'} />
                </RequireAuth>
              }
            />
            <Route
              path="/deliveries-manufacturer"
              element={
                <RequireAuth>
                  <Orders key={'deliveries-manufacturer'} />
                </RequireAuth>
              }
            />
            <Route
              path="/overviews"
              element={
                <RequireAuth>
                  <Overviews />
                </RequireAuth>
              }
            />
            <Route
              path="/contracts"
              element={
                <RequireAuth>
                  <TenderOrders />
                </RequireAuth>
              }
            />
            <Route
              path="/request"
              element={
                <RequireAuth>
                  <Request />
                </RequireAuth>
              }
            />
            <Route
              path="/tender"
              element={
                <RequireAuth>
                  <Tender />
                </RequireAuth>
              }
            />
            <Route
              path="/advert"
              element={
                <RequireAuth>
                  <Advert />
                </RequireAuth>
              }
            />
            <Route
              path="/marketplace"
              element={
                <RequireAuth>
                  <Marketplace />
                </RequireAuth>
              }
            />
            <Route
              path="/spot-marketplace"
              element={
                <RequireAuth>
                  <SpotMarketplace />
                </RequireAuth>
              }
            />
            <Route
              path="/adverts-exchange"
              element={
                <RequireAuth>
                  <AdvertsExchange />
                </RequireAuth>
              }
            />
            <Route
              path="/requests"
              element={
                <RequireAuth>
                  <Requests key={'requests'} />
                </RequireAuth>
              }
            />
            <Route
              path="/adverts"
              element={
                <RequireAuth>
                  <Adverts />
                </RequireAuth>
              }
            />
            <Route
              path="/tenders/:id"
              element={
                <RequireAuth>
                  <Requests />
                </RequireAuth>
              }
            />
            <Route
              path="/tenders"
              element={
                <RequireAuth>
                  <Requests key={'tenders'} />
                </RequireAuth>
              }
            />
            <Route
              path="/offers-requests/:requestId"
              element={
                <RequireAuth>
                  <Offers />
                </RequireAuth>
              }
            />
            <Route
              path="/offers-tenders/:requestId"
              element={
                <RequireAuth>
                  <Offers />
                </RequireAuth>
              }
            />
            <Route
              path="/offers-requests"
              element={
                <RequireAuth>
                  <Offers key={'offers-requests'} />
                </RequireAuth>
              }
            />
            <Route
              path="/offers-tenders"
              element={
                <RequireAuth>
                  <Offers key={'offers-tenders'} />
                </RequireAuth>
              }
            />
            <Route
              path="/offers-adverts/:requestId"
              element={
                <RequireAuth>
                  <OffersAdverts key={'offers-adverts1'} />
                </RequireAuth>
              }
            />
            <Route
              path="/offers-adverts"
              element={
                <RequireAuth>
                  <OffersAdverts key={'offers-adverts2'} />
                </RequireAuth>
              }
            />
            <Route
              path="/partner-overview"
              element={
                <RequireAuth>
                  <AdminUserOverview />
                </RequireAuth>
              }
            />
            <Route
              path="/partner-overview-map"
              element={
                <RequireAuth>
                  <PartnerOverviewMap />
                </RequireAuth>
              }
            />
            <Route
              path="/global-settings"
              element={
                <RequireAuth>
                  <AdminSettings />
                </RequireAuth>
              }
            />
            <Route
              path="/registrations"
              element={
                <RequireAuth>
                  <Registrations />
                </RequireAuth>
              }
            />
            <Route
              path="/api"
              element={
                <RequireAuth>
                  <APIOverview />
                </RequireAuth>
              }
            />
            <Route
              path="/visitors"
              element={
                <RequireAuth>
                  <Visitors />
                </RequireAuth>
              }
            />
            <Route
              path="/company-users"
              element={
                <RequireAuth>
                  <Users />
                </RequireAuth>
              }
            />
            <Route
              path="/company-documents"
              element={
                <RequireAuth>
                  <Documents />
                </RequireAuth>
              }
            />
            <Route
              path="/mail-settings"
              element={
                <RequireAuth>
                  <UserMailSettings />
                </RequireAuth>
              }
            />
            <Route
              path="/communities"
              element={
                <RequireAuth>
                  <CommunitiesOverview />
                </RequireAuth>
              }
            />
            <Route
              path="/user-settings"
              element={
                <RequireAuth>
                  <UserSettings />
                </RequireAuth>
              }
            />
            <Route
              path="/company-data"
              element={
                <RequireAuth>
                  <PartnerSettings />
                </RequireAuth>
              }
            />
            <Route
              path="/charts"
              element={
                <RequireAuth>
                  <Charts />
                </RequireAuth>
              }
            />
            <Route
              path="/automatic-offers"
              element={
                <RequireAuth>
                  <AutomaticOffers />
                </RequireAuth>
              }
            />
            <Route
              path="/automatic-offer-positions-map"
              element={
                <RequireAuth>
                  <AutomaticOfferPositionsMap />
                </RequireAuth>
              }
            />
            <Route
              path="/price-checker"
              element={
                <RequireAuth>
                  <PriceChecker />
                </RequireAuth>
              }
            />
            <Route
              path="/partner-checker"
              element={
                <RequireAuth>
                  <PartnerChecker />
                </RequireAuth>
              }
            />
            <Route
              path="/indexes"
              element={
                <RequireAuth>
                  <Indexes />
                </RequireAuth>
              }
            />
          </Routes>
        </Container>
        {hasLoaded && isChatOpen && (
          <Box id="hubspot-conversations-inline-parent">
            <Card raised={true}>
              <CardHeader
                sx={{ backgroundColor: '#B5DBDE' }}
                action={
                  <IconButton
                    aria-label="close-chat"
                    onClick={() => setIsChatOpen(false)}
                  >
                    <Close />
                  </IconButton>
                }
              ></CardHeader>
              <CardContent sx={{ padding: 0, marginTop: '-24px' }}>
                <iframe title="Chat" id="hubspot-conversations-inline-iframe" />
              </CardContent>
            </Card>
          </Box>
        )}
      </Suspense>
    </Router>
  )
}

function RequireAuth({ children, redirectTo, permission }) {
  let isAuthenticated = keycloak.authenticated && authorize(permission)

  if (!isAuthenticated) {
    keycloak.login()
  }

  return redirectTo ? <Navigate to={redirectTo} /> : children
}

function mapStateToProps(state) {
  const { alert } = state
  return {
    alert,
  }
}

export default connect(mapStateToProps)(App)
