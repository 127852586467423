import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import React from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import CustomDialogTitle from '../../CustomDialogTitle'

import { filtersService } from '../../../_services/filtersService'

const SaveFilterDialog = (props) => {
  const {
    hide,
    show,
    t,
    getFilterValues,
    filterId,
    savedFilters,
    setSavedFilters,
  } = props

  const [mode, setMode] = React.useState('new')

  var userObject = JSON.parse(localStorage.getItem('user'))

  const valSchema =
    mode === 'new'
      ? Yup.object().shape({
          name: Yup.string().required(t('GENERAL.REQUIRED')),
          isShared: Yup.boolean().required(t('GENERAL.REQUIRED')),
          hasEnabledNotfications: Yup.boolean().nullable(),
        })
      : Yup.object().shape({
          isShared: Yup.boolean().required(t('GENERAL.REQUIRED')),
          hasEnabledNotfications: Yup.boolean().nullable(),
        })

  const handleChange = (event) => {
    setMode(event.target.value)
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: '',
        isShared: false,
        hasEnabledNotfications: false,
      }}
      validationSchema={valSchema}
      //validateOnBlur={false}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting = true

        if (mode === 'overwrite') {
          let payload = {
            isShared: values.isShared,
            hasEnabledNotfications: values.hasEnabledNotfications,
            values: getFilterValues(),
          }

          filtersService
            .updateFilter(values.id, payload)
            .then((updatedFilter) => {
              setSubmitting = false
              let newSavedFilters = savedFilters.map((savedFilter) => {
                return savedFilter.id === updatedFilter.id
                  ? updatedFilter
                  : savedFilter
              })
              setSavedFilters(newSavedFilters)
              hide()
              resetForm()
            })
            .catch(function (error) {
              setSubmitting = false
              console.log(error)
            })
        } else {
          let payload = {
            name: values.name,
            isShared: values.isShared,
            hasEnabledNotfications: values.hasEnabledNotfications,
            values: getFilterValues(),
            filterId: filterId,
          }

          filtersService
            .createFilterOfUser(userObject.user.id, payload)
            .then((createdFilter) => {
              setSubmitting = false
              let newSavedFilters = [...savedFilters]
              newSavedFilters.push(createdFilter)
              setSavedFilters(newSavedFilters)
              hide()
              resetForm()
            })
            .catch(function (error) {
              setSubmitting = false
              console.log(error)
            })
        }
      }}
    >
      {({ isSubmitting, submitForm, resetForm }) => (
        <Form>
          <Dialog fullWidth={true} maxWidth="sm" open={show}>
            <CustomDialogTitle
              onClose={() => {
                hide()
                resetForm()
              }}
              title={t('FILTER.SAVE_FILTER')}
            ></CustomDialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel>{t('FILTER.NEW_OR_OVERWRITE')}</FormLabel>
                    <RadioGroup row value={mode} onChange={handleChange}>
                      <FormControlLabel
                        value="new"
                        control={<Radio />}
                        label={t('FILTER.NEW')}
                      />
                      <FormControlLabel
                        value="overwrite"
                        control={<Radio />}
                        label={t('FILTER.OVERWRITE')}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  {mode === 'new' ? (
                    <Field
                      name="name"
                      label={t('FILTER.NAME')}
                      component={TextField}
                      variant="outlined"
                      type="text"
                      fullWidth={true}
                      margin="normal"
                    />
                  ) : (
                    <Field
                      component={TextField}
                      name="id"
                      select
                      fullWidth
                      label={t('FILTER.SELECT_FILTER')}
                      variant="outlined"
                      margin="normal"
                    >
                      {savedFilters
                        .filter(
                          (filter) => filter.userId === userObject.user.id,
                        )
                        .map((savedFilter) => (
                          <MenuItem value={savedFilter.id} key={savedFilter.id}>
                            {savedFilter.name}
                          </MenuItem>
                        ))}
                    </Field>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={CheckboxWithLabel}
                    type={'checkbox'}
                    color="default"
                    name={'isShared'}
                    Label={{
                      label: t('FILTER.SHARE_FILTER'),
                    }}
                    style={{ marginLeft: '15px' }}
                  />
                </Grid>
                {filterId.includes('Marketplace') ||
                  (filterId.includes('AdvertsExchange') && (
                    <Grid item xs={12}>
                      <Field
                        component={CheckboxWithLabel}
                        type={'checkbox'}
                        color="default"
                        name={'hasEnabledNotfications'}
                        Label={{
                          label: t('FILTER.NOTIFY_WHEN_FULLFILLED'),
                        }}
                        style={{ marginLeft: '15px' }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color={'secondary'}
                onClick={(e) => {
                  hide()
                  resetForm()
                }}
              >
                {t('GENERAL.BACK')}
              </Button>
              <Button
                onClick={() => submitForm()}
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="secondary"
              >
                {t('FILTER.SAVE')}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  )
}

export default withTranslation()(SaveFilterDialog)
