import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const ratingsService = {
  createRatingOfOrder,
}

function createRatingOfOrder(ratings) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(ratings),
  }
  return fetch(
    `${config.apiUrlBase}/orders/${ratings.orderId}/ratings`,
    requestOptions,
  ).then(handleResponse)
}

export * from './ratingsService'
