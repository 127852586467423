import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  MenuItem,
  Typography,
} from '@mui/material'
import { Field, Formik, yupToFormErrors } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'
import { countryList } from '../../_constants/lookupConstants'
import { getTouchedObj } from '../../_helpers/little'
import { _googleService } from '../../_services/_googleService'
import { jobOffersService } from '../../_services/jobOffersService'
import DeliveryTimes from '../DeliveryTimes'
import { Section } from '../Section'

const AcceptJobOfferNewUserDialog = (props) => {
  const { t, hide, forceUpdate, jobOffer, token } = props

  const userObject = JSON.parse(localStorage.getItem('user'))

  const [error, setError] = useState('')

  const [initialValues, setInitialValues] = useState({
    orderNumber: '',
    customInvoiceAddress: false,
    countryId: '',
    invoiceCompany: '',
    invoiceZipcode: '',
    invoicePlace: '',
    invoiceAddress: '',
    invoiceCountryId: 80,
    invoiceContactPerson: '',
    invoiceAdditionalAddressLine: '',
    AGBAccept: false,
    deliveryTimes: [
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: false,
        start: null,
        end: null,
      },
      {
        isActive: false,
        start: null,
        end: null,
      },
    ],
    taxId: '',
    firstname: '',
    lastname: '',
    phone: '',
  })

  const [showAdditionalDetailsDialog, setShowAdditionalDetailsDialog] =
    useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      address: jobOffer.address,
      company: jobOffer.company,
      zipcode: jobOffer.zipcode,
      place: jobOffer.place,
      countryId: jobOffer.countryId,
      deliveryTimes: jobOffer.deliveryTimes,
      isDespatchAdviceRequired: jobOffer.isDespatchAdviceRequired,
      despatchAdviceInstructions: jobOffer.despatchAdviceInstructions
        ? jobOffer.despatchAdviceInstructions
        : '',
    })
  }, [jobOffer])
  const phoneRegExp = /^[\d+]+$/

  let navigate = useNavigate()

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object()
        .shape({
          orderNumber: Yup.string().nullable(),
          address: Yup.string().required(t('GENERAL.REQUIRED')),
          company: Yup.string().required(t('GENERAL.REQUIRED')),
          firstname: Yup.string().required(t('GENERAL.REQUIRED')),
          lastname: Yup.string().required(t('GENERAL.REQUIRED')),
          phone: Yup.string()
            .matches(phoneRegExp, t('GENERAL.INVALID_PHONE'))
            .required(t('GENERAL.REQUIRED')),
          taxId: Yup.string().required(t('GENERAL.REQUIRED')),
          AGBAccept: Yup.boolean()
            .oneOf([true], t('GENERAL.REQUIRED'))
            .required(t('GENERAL.REQUIRED')),
        })
        .test({
          test: async function (orderDetails, context) {
            if (!orderDetails.address || initialValues.countryId !== 80) {
              return true
            }
            let validationResult =
              await _googleService.validateAddressOfLocation({
                address: orderDetails.address,
                zipcode: initialValues.zipcode,
                place: initialValues.place,
              })
            if (validationResult.address.missingComponentTypes) {
              for (
                let index = 0;
                index < validationResult.address.missingComponentTypes.length;
                index++
              ) {
                const missingComponentType =
                  validationResult.address.missingComponentTypes[index]

                switch (missingComponentType) {
                  case 'route':
                    return this.createError({
                      message: t('LITTLE.ADDRESS_MISSING'),
                      path: `address`,
                    })
                  case 'street_number':
                    return this.createError({
                      message: t('LITTLE.STREET_NUMBER_MISSING'),
                      path: `address`,
                    })
                  default:
                    break
                }
              }
            }
          },
        })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting = true
        jobOffersService
          .confirmJobOffer(
            jobOffer.id,
            {
              orderNumber: values.orderNumber,
              address: values.address,
              company: values.company,
              deliveryTimes: values.deliveryTimes,
              isDespatchAdviceRequired: values.isDespatchAdviceRequired,
              despatchAdviceInstructions: values.isDespatchAdviceRequired
                ? values.despatchAdviceInstructions
                : undefined,
              userId: userObject ? userObject.user.id : undefined,
              firstname: values.firstname,
              lastname: values.lastname,
              taxId: values.taxId,
              phone: values.phone,
            },
            token,
          )
          .then(() => setShowSuccessMessage(true))
          .catch((err) => {
            if (err === 'Offer is no longer active') {
              setError(t('JOB_OFFERS.NO_LONGER_ACTIVE'))
            } else {
              setError(err)
            }
          })
          .finally(() => {
            setSubmitting = false
          })
      }}
    >
      {({
        values,
        submitForm,
        setFieldValue,
        isSubmitting,
        setErrors,
        setTouched,
      }) => {
        return showSuccessMessage ? (
          <Dialog open={true}>
            <DialogTitle>{t('JOB_OFFERS.OFFER_ACCEPTED')}</DialogTitle>
            <DialogContent>
              {t('JOB_OFFERS.OFFER_ACCEPTED_DESCRIPTION')}
            </DialogContent>
            {jobOffer.type === 'JOB' && (
              <DialogContent>
                {t('JOB_OFFERS.OFFER_ACCEPTED_DESCRIPTION_2')}
              </DialogContent>
            )}
            <DialogActions>
              <Button
                variant={'contained'}
                color="secondary"
                onClick={() => {
                  navigate('deliveries-customer')
                }}
              >
                {t('JOB_OFFERS.GOTO_ORDER')}
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Fragment>
            <Dialog open={true} fullWidth={true} maxWidth={'md'}>
              <DialogTitle>{t('OFFER.ACCEPT_OFFER_DIALOG_TITLE')}</DialogTitle>
              <Fragment>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Field
                        name="orderNumber"
                        label={t('OFFER.INTERNAL_ORDERNUMBER')}
                        component={TextField}
                        type="text"
                        fullWidth={true}
                        margin="normal"
                      />
                    </Grid>
                    <Section header={t('OFFER.DELIVERY_ADDRESS')}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={TextField}
                              label={t('REQUEST.DELIVERY_DETAILS.COMPANY')}
                              name={'company'}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={TextField}
                              label={t('REQUEST.DELIVERY_DETAILS.COUNTRY')}
                              disabled={true}
                              name={'countryId'}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              select
                            >
                              {Object.entries(countryList).map((country) => (
                                <MenuItem value={country[1]} key={country[0]}>
                                  {country[0]}
                                </MenuItem>
                              ))}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              label={t('REQUEST.DELIVERY_DETAILS.ADDRESS')}
                              name={'address'}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={TextField}
                              label={t('REQUEST.DELIVERY_DETAILS.ZIP_CODE')}
                              disabled={true}
                              name={'zipcode'}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={TextField}
                              label={t('REQUEST.DELIVERY_DETAILS.PLACE')}
                              disabled={true}
                              name={'place'}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} spacing={1}>
                            <Typography variant="caption">
                              {t('DELIVERY_TIMES.LOADING_TIMES')}
                            </Typography>
                            <DeliveryTimes
                              data={values.deliveryTimes}
                              formikName={'deliveryTimes'}
                              setFieldValue={setFieldValue}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="caption">
                              {t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
                            </Typography>
                            <Grid container>
                              <Grid item xs={12}>
                                <Field
                                  component={CheckboxWithLabel}
                                  type={'checkbox'}
                                  color="default"
                                  name={'isDespatchAdviceRequired'}
                                  Label={{
                                    label: t(
                                      'DELIVERY_DETAILS.IS_DESPATCH_ADVICE_REQUIRED',
                                    ),
                                  }}
                                  style={{ marginLeft: '15px' }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  component={TextField}
                                  disabled={!values.isDespatchAdviceRequired}
                                  name={'despatchAdviceInstructions'}
                                  fullWidth
                                  margin="dense"
                                  label={t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
                                  variant="outlined"
                                  multiline
                                  rows="4"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Section>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => {
                      hide()
                    }}
                  >
                    {t('GENERAL.CANCEL')}
                  </Button>
                  <Button
                    variant={'contained'}
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={async () => {
                      let valSchema = Yup.object()
                        .shape({
                          address: Yup.string().required(t('GENERAL.REQUIRED')),
                          company: Yup.string().required(t('GENERAL.REQUIRED')),
                        })
                        .test({
                          test: async function (orderDetails, context) {
                            if (
                              !orderDetails.address ||
                              initialValues.countryId !== 80
                            ) {
                              return true
                            }
                            let validationResult =
                              await _googleService.validateAddressOfLocation({
                                address: orderDetails.address,
                                zipcode: initialValues.zipcode,
                                place: initialValues.place,
                              })

                            if (
                              validationResult.address.missingComponentTypes
                            ) {
                              for (
                                let index = 0;
                                index <
                                validationResult.address.missingComponentTypes
                                  .length;
                                index++
                              ) {
                                const missingComponentType =
                                  validationResult.address
                                    .missingComponentTypes[index]

                                switch (missingComponentType) {
                                  case 'route':
                                    return this.createError({
                                      message: t('LITTLE.ADDRESS_MISSING'),
                                      path: `address`,
                                    })
                                  case 'street_number':
                                    return this.createError({
                                      message: t(
                                        'LITTLE.STREET_NUMBER_MISSING',
                                      ),
                                      path: `address`,
                                    })
                                  default:
                                    break
                                }
                              }
                            } else {
                              return true
                            }
                          },
                        })

                      valSchema
                        .validate(values, {
                          abortEarly: false,
                          context: values,
                        })
                        .then(function (value) {
                          setShowAdditionalDetailsDialog(true)
                        })
                        .catch((err) => {
                          let yupErrors = yupToFormErrors(err)
                          setErrors(yupErrors)
                          setTouched(getTouchedObj(yupErrors), false) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                        })
                    }}
                  >
                    {t('OFFERS.ACCEPT_BINDING_OFFER')}
                  </Button>
                </DialogActions>
              </Fragment>
            </Dialog>
            <Dialog
              open={showAdditionalDetailsDialog}
              fullWidth={true}
              maxWidth={'sm'}
            >
              <DialogTitle>{t('OFFER.WE_NEED_ADDITONAL_DETAILS')}</DialogTitle>
              {error ? (
                <Fragment>
                  <DialogContent>
                    <Alert severity="error">{error}</Alert>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      onClick={() => {
                        if (forceUpdate) {
                          forceUpdate()
                        }
                        hide()
                      }}
                    >
                      {t('GENERAL.BACK')}
                    </Button>
                  </DialogActions>
                </Fragment>
              ) : (
                <Fragment>
                  <DialogContent>
                    <Typography>
                      {t('JOB_OFFERS.WE_NEED_ADDITONAL_DETAILS_DESCRIPTION')}
                    </Typography>
                  </DialogContent>
                  <DialogContent>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Field
                          name="firstname"
                          label={t('JOB_OFFERS.FIRSTNAME')}
                          component={TextField}
                          type="text"
                          fullWidth={true}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="lastname"
                          label={t('JOB_OFFERS.LASTNAME')}
                          component={TextField}
                          type="text"
                          fullWidth={true}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="phone"
                          label={t('JOB_OFFERS.PHONE')}
                          component={TextField}
                          type="text"
                          fullWidth={true}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="taxId"
                          label={t('JOB_OFFERS.TAX_ID')}
                          component={TextField}
                          type="text"
                          fullWidth={true}
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name="AGBAccept"
                          Label={{
                            label: (
                              <span>
                                {t('JOB_OFFERS.AGB_ACCEPT_0') + ' '}
                                {t('REGISTER.AGB_ACCEPT_0') + ' '}
                                <Link
                                  onClick={() =>
                                    window.open('https://www.pacurion.com/agb/')
                                  }
                                >
                                  {t('REGISTER.AGB_ACCEPT_1')}
                                  {' ' + t('REGISTER.AGB_ACCEPT_2')}
                                </Link>
                              </span>
                            ),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      onClick={() => {
                        setShowAdditionalDetailsDialog(false)
                      }}
                    >
                      {t('GENERAL.CANCEL')}
                    </Button>
                    <Button
                      variant={'contained'}
                      color="secondary"
                      disabled={isSubmitting || !values.AGBAccept}
                      onClick={() => {
                        submitForm()
                      }}
                    >
                      {t('OFFERS.ACCEPT_BINDING_OFFER')}
                    </Button>
                  </DialogActions>
                </Fragment>
              )}
            </Dialog>
          </Fragment>
        )
      }}
    </Formik>
  )
}

export default withTranslation()(AcceptJobOfferNewUserDialog)
