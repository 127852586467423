import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { requestsService } from '../../_services/requestsService'
import Attachments from '../Attachments'
import DeliveryDetailsMultipleLocationsView from '../DeliveryDetailsMultipleLocationsView'
import DeliveryDetailsView from '../DeliveryDetailsView'
import { Loading } from '../Loading'
import OtherDetailsView from '../OtherDetailsView'
import PaymentDetailsView from '../PaymentDetailsView'
import { Section } from '../Section'
import TenderOrderPositions from '../TenderOrderPositions'
import AdvertPositionsView from './_components/AdvertsPositionsView'
import RequestPositionsView from './_components/RequestPositionsView'
import TenderPositionsView from './_components/TenderPositionsView'

const RequestDetails = (props) => {
  const { requestId, data } = props
  const [request, setRequest] = useState(data)

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (requestId) {
      setIsLoading(true)
      requestsService.getRequest(requestId).then((data) => {
        setRequest(data)
        setIsLoading(false)
      })
    }
  }, [requestId])

  if (!request) {
    return null
  }

  return (
    (isLoading && <Loading />) || (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Section>
            <Grid container>
              <Grid item xs={12} sm={6}>
                {request.type === 'REQUEST' || request.type === 'ADVERT' ? (
                  <DeliveryDetailsView data={request} parentType={'REQUEST'} />
                ) : (
                  <DeliveryDetailsMultipleLocationsView data={request} />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <PaymentDetailsView
                  data={request}
                  differences={{}}
                  parentType={'REQUEST'}
                />
                <OtherDetailsView
                  data={request.generalInformation.otherDetails}
                  requestType={request.type}
                />
              </Grid>
            </Grid>
          </Section>
        </Grid>
        <Grid item xs={12}>
          {request.type === 'TENDER' ? (
            <TenderPositionsView data={request} />
          ) : null}
          {request.type === 'REQUEST' ? (
            <RequestPositionsView data={request} />
          ) : null}

          {request.type === 'ADVERT' ? (
            <AdvertPositionsView data={request} />
          ) : null}

          {request.type === 'TENDERORDER' ? (
            <TenderOrderPositions data={request} />
          ) : null}
        </Grid>
        {request.type !== 'TENDERORDER' ? (
          <Grid item xs={12}>
            <Attachments data={request} readOnly={true} />
          </Grid>
        ) : null}
      </Grid>
    )
  )
}
export default withTranslation()(RequestDetails)
