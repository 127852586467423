import MaterialTable from '@material-table/core'
import { HelpOutline } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Numeral from 'numeral'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  countryLookup,
  deliveryTypeLookup,
  dryingLookup,
  productLookup,
  productStateLookup,
} from '../../../../../../../../_constants/lookupConstants'
import {
  tableIcons,
  tableLocalization,
} from '../../../../../../../../_helpers/muiTable'
import { InfoTooltip } from '../../../../../../../InfoTooltip'
import Position from '../../../../../../../Position'
import { Section } from '../../../../../../../Section'

const OrderRegistrationPositionsTender = (props) => {
  const { t, data } = props

  return (
    <Fragment>
      <Section header={t('REQUEST.REQUEST_POSITIONS')}>
        <MaterialTable
          icons={tableIcons}
          localization={tableLocalization()}
          columns={[
            {
              title: '#',
              field: 'id',
              editable: 'never',
              defaultSort: 'asc',
            },
            {
              title: t('REQUEST.POSITION_PRODUCT'),
              field: 'productId',
              lookup: { productLookup },
              render: (rowData) => <Position data={rowData} readOnly={true} />,
            },
            {
              title: t('REQUEST.PRODUCT_STATE'),
              field: 'state',
              lookup: productStateLookup,
            },
            {
              title: (
                <Fragment>
                  {t('REQUEST.DELIVERYTYPE')}
                  <InfoTooltip
                    disableTouchListener
                    disableFocusListener
                    title={t('REQUEST.DELIVERYTYPE_TOOLTIP')}
                  >
                    <IconButton
                      color="default"
                      style={{
                        padding: 0,
                        margin: '0 8px',
                      }}
                      size="large"
                    >
                      <HelpOutline />
                    </IconButton>
                  </InfoTooltip>
                </Fragment>
              ),
              field: 'deliveryType',
              lookup: deliveryTypeLookup,
            },
            {
              title: t('REQUEST.IPPC'),
              field: 'ippc',
              type: 'boolean',
            },
            {
              title: t('REQUEST.DRYING'),
              field: 'drying',
              lookup: dryingLookup,
            },
            {
              title: t('TENDER_ORDERS.ORDERED_AMOUNT'),
              render: (tenderOrderPosition) => (
                <span>
                  {Numeral(tenderOrderPosition.orderedAmount).format('0,0')}
                </span>
              ),
            },
            {
              title: t('TENDER_ORDERS.REQUESTED_AMOUNT'),
              render: (tenderOrderPosition) => (
                <span>
                  {Numeral(tenderOrderPosition.requestedAmount).format('0,0')}
                </span>
              ),
            },
            {
              title: t('REQUEST.TOTAL_AMOUNT'),
              editable: 'never',
              render: (rowData) => {
                return Numeral(rowData.amount).format('0,0.[00]')
              },
            },
            {
              title: t('TENDER_ORDERS.TOTAL_POSITION_PURCHASE_PRICE'),
              render: (rowData) => (
                <span>
                  {Numeral(rowData.totalPurchasePrice).format('0,0.00 $')}
                </span>
              ),
            },
            {
              title: t('TENDER_ORDERS.TOTAL_POSITION_SALES_PRICE'),
              render: (rowData) => (
                <span>
                  {Numeral(rowData.totalSalesPrice).format('0,0.00 $')}
                </span>
              ),
            },
          ]}
          data={data.positions}
          options={{
            toolbar: false,
            toolbarButtonAlignment: 'left',
            showTitle: false,
            search: false,
            paging: false,
            padding: 'dense',
            actionsColumnIndex: -1,
            filterCellStyle: {
              maxWidth: 0,
            },
            headerStyle: {
              fontWeight: 'bold',
            },
            sorting: false,
          }}
          style={{ boxShadow: 'none' }}
        />
      </Section>
      {data.generalInformation.deliveryDetails.locations.map(
        (location, index) => (
          <Section
            header={
              <Fragment>
                {' '}
                {t('REQUEST.LOCATION')}
                {' ' + (index + 1)}
                {location.company ? ` | ${location.company}` : null}
                {location.countryId
                  ? ` | ${countryLookup[location.countryId]}`
                  : null}
                {location.address ? ` | ${location.address}` : null}
                {location.zipcode ? ` | ${location.zipcode}` : null}
                {location.place ? ` | ${location.place}` : null}
              </Fragment>
            }
          >
            <MaterialTable
              icons={tableIcons}
              localization={tableLocalization}
              columns={[
                {
                  title: '#',
                  field: 'id',
                  defaultSort: 'asc',
                },
                {
                  title: t('REQUEST.POSITION_PRODUCT'),
                  field: 'productId',
                  lookup: { productLookup },
                  render: (rowData) => {
                    return <Position data={rowData} readOnly={true} />
                  },
                },
                {
                  title: t('REQUEST.PRODUCT_STATE'),
                  field: 'state',
                  lookup: productStateLookup,
                },
                {
                  title: t('REQUEST.DELIVERYTYPE'),
                  field: 'deliveryType',
                  lookup: deliveryTypeLookup,
                },
                {
                  title: t('REQUEST.IPPC'),
                  field: 'ippc',
                  type: 'boolean',
                },
                {
                  title: t('REQUEST.DRYING'),
                  field: 'drying',
                  lookup: dryingLookup,
                },
                {
                  title: t('TENDER_ORDERS.ORDERED_AMOUNT'),
                  render: (tenderOrderPosition) =>
                    Numeral(tenderOrderPosition.orderedAmount).format('0,0'),
                },
                {
                  title: t('TENDER_ORDERS.REQUESTED_AMOUNT'),
                  render: (tenderOrderPosition) =>
                    Numeral(tenderOrderPosition.requestedAmount).format('0,0'),
                },
                {
                  title: t('REQUEST.POSITION_AMOUNT'),
                  field: 'amount',
                  render: (rowData) => Numeral(rowData.amount).format('0,0'),
                },
                {
                  title: t('ORDER_REGISTRATION.PURCHASE_PRICE'),
                  render: (rowData) =>
                    Numeral(parseFloat(rowData.purchasePrice)).format(
                      '0,0.00 $',
                    ),
                },
                {
                  title: t('ORDER_REGISTRATION.SALES_PRICE'),
                  render: (rowData) =>
                    Numeral(parseFloat(rowData.salesPrice)).format('0,0.00 $'),
                },
              ]}
              data={location.positions}
              options={{
                toolbar: false,
                toolbarButtonAlignment: 'left',
                showTitle: false,
                search: false,
                paging: false,
                padding: 'dense',
                actionsColumnIndex: -1,
                filterCellStyle: {
                  maxWidth: 0,
                },
                headerStyle: {
                  fontWeight: 'bold',
                },
                sorting: false,
              }}
              style={{ boxShadow: 'none' }}
            />
          </Section>
        ),
      )}
    </Fragment>
  )
}

export default withTranslation()(connect()(OrderRegistrationPositionsTender))
