import { Grid, InputAdornment, MenuItem } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { generalService } from '../../_services/generalService'
import DifferenceWrapper from '../DifferenceWrapper'
import { Section } from '../Section'

const OfferPaymentDetails = (props) => {
  const {
    t,
    differences,
    diffObjectType,
    allPaymentTermsAllowed,
    data,
    setFieldValue,
  } = props
  const [paymentTerms, setPaymentTerms] = useState([])
  const [skontos, setSkontos] = useState([])

  useEffect(() => {
    setPaymentTerms(generalService.getPaymentTerms(allPaymentTermsAllowed))
    setSkontos(generalService.getSkontos())
  }, [])

  return (
    <Section header={t('REQUEST.PAYMENT_DETAILS.HEADING')}>
      <Grid container>
        <Grid container item xs={12}>
          <DifferenceWrapper
            showDifference={
              differences.paymentTerm ||
              differences.skonto ||
              differences.skontoPeriod
            }
            difference={differences.paymentTerm}
            differenceDisplay={
              parseInt(differences.paymentTerm) === -1
                ? 'N/A'
                : differences.paymentTerm
            }
            diffObjectType={diffObjectType}
          >
            <Field
              variant={'outlined'}
              component={TextField}
              name="generalInformation.paymentDetails.paymentTerm"
              select
              fullWidth
              label={t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM')}
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM_UNIT')}
                  </InputAdornment>
                ),
                onChange: (e) => {
                  if (parseInt(e.target.value) === 0) {
                    setFieldValue(
                      'generalInformation.paymentDetails.skontoPeriod',
                      null,
                    )
                    setFieldValue('generalInformation.paymentDetails.skonto', 0)
                  }
                  setFieldValue(
                    'generalInformation.paymentDetails.paymentTerm',
                    e.target.value,
                  )
                },
              }}
            >
              {paymentTerms.map((paymentTerm) => (
                <MenuItem value={paymentTerm} key={paymentTerm}>
                  {paymentTerm !== 0 ? paymentTerm : t('GENERAL.PREPAYMENT')}
                </MenuItem>
              ))}
            </Field>
          </DifferenceWrapper>
        </Grid>
        <Grid container item xs={12}>
          <DifferenceWrapper
            showDifference={
              differences.paymentTerm ||
              differences.skonto ||
              differences.skontoPeriod
            }
            difference={differences.skonto}
            differenceDisplay={
              parseInt(differences.skonto) === -1 ? 'N/A' : differences.skonto
            }
            diffObjectType={diffObjectType}
          >
            {allPaymentTermsAllowed &&
              ((data.request.type === 'ADVERT' &&
                data.request.partnerId !==
                  JSON.parse(localStorage.getItem('user')).partner.id) ||
                (data.request.type !== 'ADVERT' &&
                  data.request.partnerId ===
                    JSON.parse(localStorage.getItem('user')).partner.id)) && (
                <Field
                  variant={'outlined'}
                  component={TextField}
                  name="generalInformation.paymentDetails.skonto"
                  select
                  fullWidth
                  disabled={
                    parseInt(
                      data.generalInformation.paymentDetails.paymentTerm,
                    ) === 0
                  }
                  label={t('REQUEST.PAYMENT_DETAILS.SKONTO')}
                  margin="dense"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ paddingRight: '20px' }}
                      >
                        {t('REQUEST.PAYMENT_DETAILS.SKONTO_UNIT')}
                      </InputAdornment>
                    ),
                    onChange: (e) => {
                      if (parseInt(e.target.value) === 0) {
                        setFieldValue(
                          'generalInformation.paymentDetails.skontoPeriod',
                          null,
                        )
                      }
                      setFieldValue(
                        'generalInformation.paymentDetails.skonto',
                        e.target.value,
                      )
                    },
                  }}
                >
                  {skontos.map((skonto) => (
                    <MenuItem value={skonto} key={skonto}>
                      {skonto}
                    </MenuItem>
                  ))}
                </Field>
              )}
          </DifferenceWrapper>
        </Grid>
        <Grid container item xs={12}>
          <DifferenceWrapper
            showDifference={
              differences.paymentTerm ||
              differences.skonto ||
              differences.skontoPeriod
            }
            difference={differences.skontoPeriod}
            differenceDisplay={differences.skontoPeriod}
            diffObjectType={diffObjectType}
          >
            {allPaymentTermsAllowed &&
              ((data.request.type === 'ADVERT' &&
                data.request.partnerId !==
                  JSON.parse(localStorage.getItem('user')).partner.id) ||
                (data.request.type !== 'ADVERT' &&
                  data.request.partnerId ===
                    JSON.parse(localStorage.getItem('user')).partner.id)) && (
                <Field
                  component={TextField}
                  name="generalInformation.paymentDetails.skontoPeriod"
                  select
                  fullWidth
                  disabled={
                    parseInt(data.generalInformation.paymentDetails.skonto) ===
                    0
                  }
                  label={t('REQUEST.PAYMENT_DETAILS.SKONTO_PERIOD')}
                  variant="outlined"
                  margin="dense"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM_UNIT')}
                      </InputAdornment>
                    ),
                  }}
                >
                  {paymentTerms
                    .filter(
                      (paymentTerm) =>
                        paymentTerm <=
                        data.generalInformation.paymentDetails.paymentTerm,
                    )
                    .map((paymentTerm) => (
                      <MenuItem value={paymentTerm} key={paymentTerm}>
                        {paymentTerm}
                      </MenuItem>
                    ))}
                </Field>
              )}
          </DifferenceWrapper>
        </Grid>
      </Grid>
    </Section>
  )
}

export default withTranslation()(OfferPaymentDetails)
