import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'

import { authHeader } from '../_helpers/auth-header'

export const surveysService = {
  createSurvey,
  deleteSurvey,
  updateSurvey,
  getSurvey,
  getSurveys,
  completeSurvey,
  getSurveysOfPartner,
  getSurveyAnswersOfPartner,
}

function createSurvey(survey) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(survey),
  }

  return fetch(`${config.apiUrlBase}/surveys`, requestOptions).then(
    handleResponse,
  )
}

function deleteSurvey(surveyId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(`${config.apiUrlBase}/surveys/${surveyId}`, requestOptions).then(
    handleResponse,
  )
}

function updateSurvey(surveyId, payload) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }

  return fetch(`${config.apiUrlBase}/surveys/${surveyId}`, requestOptions).then(
    handleResponse,
  )
}

function getSurvey(surveyId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(`${config.apiUrlBase}/surveys/${surveyId}`, requestOptions).then(
    handleResponse,
  )
}

function getSurveys() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/surveys`, requestOptions).then(
    handleResponse,
  )
}

function completeSurvey(surveyId, answers) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(answers),
  }

  return fetch(
    `${config.apiUrlBase}/surveys/${surveyId}/complete`,
    requestOptions,
  ).then(handleResponse)
}

function getSurveysOfPartner(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/surveys`,
    requestOptions,
  ).then(handleResponse)
}

function getSurveyAnswersOfPartner(partnerId, surveyId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/surveys/${surveyId}/answers`,
    requestOptions,
  ).then(handleResponse)
}

export * from './surveysService'
