import { WarningRounded } from '@mui/icons-material'
import { Chip, Grid } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { generatePositionPropertyValue } from '../../_helpers/little'
import { InfoTooltip } from '../InfoTooltip'

const PositionDetailSummary = (props) => {
  const { t, data, differences, showDifference, diffObjectType } = props

  return (
    <Grid spacing={1} container>
      {data.positionProperties
        .sort(
          (a, b) =>
            a.productProperty.renderPosition - b.productProperty.renderPosition,
        )
        .filter(
          (positionProperty) => positionProperty.productProperty.isPrimary,
        )
        .map((positionProperty) => {
          let isDifferent =
            showDifference &&
            differences &&
            differences.positionProperties.some(
              (diffPositionProperty) =>
                positionProperty.productProperty.id ===
                diffPositionProperty.productProperty.id,
            ) &&
            differences.positionProperties.find(
              (diffPositionProperty) =>
                positionProperty.productProperty.id ===
                diffPositionProperty.productProperty.id,
            ).value !== false

          return (
            <Grid item>
              {positionProperty.productProperty.type === 'BOOLEAN' ? (
                isDifferent ? (
                  <Chip
                    icon={isDifferent ? <WarningRounded /> : null}
                    color={isDifferent ? 'warning' : 'default'}
                    size={'small'}
                    label={
                      differences.positionProperties
                        .find(
                          (diffPositionProperty) =>
                            positionProperty.productProperty.id ===
                            diffPositionProperty.productProperty.id,
                        )
                        .value.toString() === 'true' &&
                      positionProperty.productProperty.primaryRenderVariant ===
                        'VALUE' ? (
                        <s>{generatePositionPropertyValue(positionProperty)}</s>
                      ) : (
                        generatePositionPropertyValue(positionProperty)
                      )
                    }
                  />
                ) : positionProperty.productProperty.primaryRenderVariant ===
                    'KEY/VALUE' ||
                  positionProperty.value === 'true' ||
                  positionProperty.value === true ? (
                  <Chip
                    size={'small'}
                    label={generatePositionPropertyValue(positionProperty)}
                  />
                ) : null
              ) : (
                <InfoTooltip
                  title={
                    isDifferent
                      ? (diffObjectType === 'REQUEST'
                          ? t('OFFER.DIFFERENCE_ORIGIN')
                          : t('OFFER.DIFFERENCE_COMPARE_OFFER')) +
                        ' ' +
                        generatePositionPropertyValue(
                          differences.positionProperties.find(
                            (diffPositionProperty) =>
                              positionProperty.productProperty.id ===
                              diffPositionProperty.productProperty.id,
                          ),
                        )
                      : ''
                  }
                >
                  <Chip
                    icon={isDifferent ? <WarningRounded /> : null}
                    color={isDifferent ? 'warning' : 'default'}
                    size={'small'}
                    label={generatePositionPropertyValue(positionProperty)}
                  />
                </InfoTooltip>
              )}
            </Grid>
          )
        })}
    </Grid>
  )
}

export default withTranslation()(PositionDetailSummary)
