import MaterialTable from '@material-table/core'
import { Add, Delete, Download } from '@mui/icons-material'
import { Button, Dialog, DialogContent } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { orderAttachmentTypeLookup } from '../../_constants/lookupConstants'
import { tableIcons, tableLocalization } from '../../_helpers/muiTable'
import { orderAttachmentsService } from '../../_services/orderAttachmentsService'
import AgreementDialog from '../AgreementDialog'
import CustomDialogTitle from '../CustomDialogTitle'
import { Loading } from '../Loading'
import OrderAttachmentCreateDialog from './_components/OrderAttachmentCreateDialog'

const OrderAttachmentDialog = (props) => {
  const { t, orderId, show, hide, forceUpdate, forceUpdateCount } = props

  const [isLoading, setIsLoading] = useState(true)

  const [orderAttachments, setOrderAttachments] = useState([])

  const [selectedAttachment, setSelectedAttachment] = useState(null)

  const [showCreateAttachmentDialog, setShowCreateAttachmentDialog] =
    useState(false)

  const [showDeleteAttachmentDialog, setShowDeleteAttachmentDialog] =
    useState(false)

  useEffect(() => {
    setIsLoading(true)
    orderAttachmentsService.getOrderAttachmentOfOrder(orderId).then((data) => {
      setOrderAttachments(data)
      setIsLoading(false)
    })
  }, [show, forceUpdateCount])
  return isLoading ? (
    <Loading variant={'centered'} />
  ) : (
    <Fragment>
      <Dialog fullWidth={true} maxWidth="xl" open={show}>
        <CustomDialogTitle
          title={t('ORDERS.ORDER_ATTACHMENTS.ORDER_ATTACHMENTS')}
          onClose={() => hide()}
        />
        {
          <DialogContent>
            <div style={{ marginBottom: 10 }}>
              <Button
                size="medium"
                color={'secondary'}
                variant={'contained'}
                startIcon={<Add />}
                onClick={() => {
                  setShowCreateAttachmentDialog(true)
                }}
              >
                {t('ORDERS.ORDER_ATTACHMENTS.CREATE_ATTACHMENT')}
              </Button>
            </div>
            <Fragment>
              <MaterialTable
                icons={tableIcons}
                localization={tableLocalization()}
                columns={[
                  {
                    title: t('ORDERS.ORDER_ATTACHMENTS.NAME'),
                    field: 'name',
                  },
                  {
                    title: t('ORDERS.ORDER_ATTACHMENTS.TYPE'),
                    field: 'type',
                    lookup: orderAttachmentTypeLookup,
                  },
                  {
                    title: t(
                      'ORDERS.ORDER_ATTACHMENTS.IS_ACCESSIBLE_FOR_CUSTOMER',
                    ),
                    field: 'isAccessibleForCustomer',
                    lookup: {
                      true: t('GENERAL.YES'),
                      false: t('GENERAL.NO'),
                    },
                  },
                  {
                    title: t(
                      'ORDERS.ORDER_ATTACHMENTS.IS_ACCESSIBLE_FOR_MANUFACTURER',
                    ),
                    field: 'isAccessibleForManufacturer',
                    lookup: {
                      true: t('GENERAL.YES'),
                      false: t('GENERAL.NO'),
                    },
                  },
                ]}
                data={orderAttachments}
                options={{
                  toolbarButtonAlignment: 'left',
                  toolbar: true,
                  showTitle: false,
                  search: false,
                  paging: true,
                  pageSize: 10,
                  padding: 'dense',
                  actionsColumnIndex: -1,
                  filterCellStyle: {
                    maxWidth: 0,
                  },
                  headerStyle: {
                    fontWeight: 'bold',
                  },
                }}
                style={{ boxShadow: 'none' }}
                actions={[
                  (rowData) => ({
                    icon: () => <Download color={'secondary'} />,
                    tooltip: t('ORDERS.ORDER_ATTACHMENTS.DOWNLOAD'),
                    onClick: (event, attachment) => {
                      orderAttachmentsService
                        .getOrderAttachment(attachment.id)
                        .then((response) => response.blob())
                        .then((blob) => {
                          // 2. Create blob link to download
                          const url = window.URL.createObjectURL(
                            new Blob([blob]),
                          )
                          const link = document.createElement('a')
                          link.href = url
                          link.setAttribute('download', attachment.name)
                          link.setAttribute('target', '_blank')
                          // 3. Append to html page
                          document.body.appendChild(link)
                          // 4. Force download
                          link.click()
                          // 5. Clean up and remove the link
                          link.parentNode.removeChild(link)
                        })
                    },
                  }),
                  (rowData) => ({
                    icon: () => <Delete color={'secondary'} />,
                    tooltip: t('ORDERS.ORDER_ATTACHMENTS.DELETE_ATTACHMENT'),
                    onClick: (event, attachment) => {
                      setSelectedAttachment(attachment)
                      setShowDeleteAttachmentDialog(true)
                    },
                  }),
                ]}
              />
              <AgreementDialog
                open={showDeleteAttachmentDialog}
                message={t('ORDERS.ORDER_ATTACHMENTS.DELETE_ATTACHMENT')}
                acceptButtonText={t('GENERAL.YES')}
                handleClose={() => {
                  setShowDeleteAttachmentDialog(false)
                }}
                agree={() => {
                  setIsLoading(true)
                  setShowDeleteAttachmentDialog(false)
                  orderAttachmentsService
                    .deleteOrderAttachment(selectedAttachment.id)
                    .then(() => {
                      forceUpdate()
                    })
                    .catch(function (error) {
                      console.log(error)
                    })
                    .finally(() => {
                      setIsLoading(false)
                    })
                }}
              />
            </Fragment>
          </DialogContent>
        }
      </Dialog>
      <OrderAttachmentCreateDialog
        orderId={orderId}
        show={showCreateAttachmentDialog}
        hide={() => setShowCreateAttachmentDialog(false)}
        forceUpdate={forceUpdate}
      />
    </Fragment>
  )
}

export default withTranslation()(OrderAttachmentDialog)
