import moment from 'moment'
import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'
import { generateFilterValuesQueryString } from '../_helpers/little.js'

export const deliveryNotesService = {
  updateDeliveryNote,
  getDeliveryNote,
  createDeliveryNote,
  getDeliveryNotesOfPartner,
  getDeliveryNotes,
  deleteDeliveryNote,
  getDeliveryNotesOfOrder,
}

function getDeliveryNotes(filterValues = undefined, pagination) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  filterValues = filterValues.calendarWeek
    ? {
        ...filterValues,
        calendarWeek: moment(filterValues.calendarWeek[0]).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      }
    : filterValues

  let queryString = generateFilterValuesQueryString(filterValues, pagination)

  return fetch(
    `${config.apiUrlBase}/delivery-notes${queryString}`,
    requestOptions,
  ).then(handleResponse)
}

function getDeliveryNote(deliveryNoteId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/delivery-notes/` + deliveryNoteId,
    requestOptions,
  )
}

function getDeliveryNotesOfPartner(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/delivery-notes`,
    requestOptions,
  ).then(handleResponse)
}

function getDeliveryNotesOfOrder(orderId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/orders/${orderId}/delivery-notes`,
    requestOptions,
  ).then(handleResponse)
}

function updateDeliveryNote(deliveryNoteId, payload) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(
    `${config.apiUrlBase}/delivery-notes/${deliveryNoteId}`,
    requestOptions,
  ).then(handleResponse)
}

function createDeliveryNote(deliveryNote) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(deliveryNote),
  }
  return fetch(`${config.apiUrlBase}/delivery-notes`, requestOptions).then(
    handleResponse,
  )
}

function deleteDeliveryNote(deliveryNoteId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/delivery-notes/${deliveryNoteId}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './deliveryNotesService'
