import { Button, Grid, MenuItem, Step, StepLabel, Stepper } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { Field, Form, Formik, yupToFormErrors } from 'formik'
import { TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import * as Yup from 'yup'
import { alertActions } from '../../../../_actions/alertActions'
import { getTouchedObj, validateLocation } from '../../../../_helpers/little'
import { communitiesService } from '../../../../_services/communitiesService'
import { requestsService } from '../../../../_services/requestsService'
import AgreementDialog from '../../../AgreementDialog'
import Attachments from '../../../Attachments'
import Deliveries from '../../../Deliveries'
import DeliveryDetails from '../../../DeliveryDetails'
import DeliveryDetailsReplacedComponentsDialog from '../../../DeliveryDetailsReplacedComponentsDialog'
import { FieldErrorWrapper } from '../../../FieldErrorWrapper'
import { Loading } from '../../../Loading'
import PaymentDetails from '../../../PaymentDetails'
import RequestDetails from '../../../RequestDetails'
import ScrollableFullHeightContainer from '../../../ScrollableFullHeightContainer'
import { Section } from '../../../Section'
import AdvertPositions from './_components/AdvertsPosition'

const Advert = (props) => {
  const { t, dispatch } = props

  const [isLoading, setIsLoading] = useState(false)
  const [activeStep, setActiveStep] = React.useState(0)
  const [agreeDialogOpen, setAgreeDialogOpen] = useState(false)
  const [isInitialValid, setIsInitialValid] = useState(false)
  const [initialValues, setInitialValues] = useState({
    id: undefined,
    selectedPartnerLocationId: '',
    type: 'ADVERT',
    state: 'DRAFT',
    generalInformation: {
      deliveryDetails: {
        incoTerm: 'EXW',
        locations: [
          {
            id: 1,
            company: '',
            address: '',
            zipcode: '',
            place: '',
            countryId: 80,
            deliveryInformations: '',
            positions: [],
            unload: 'ANY',
            stackHeightUnit: 'QUANTITY',
            deliveryTimes: [
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
            ],
            isDespatchAdviceRequired: false,
            despatchAdviceInstructions: '',
          },
        ],
      },
      paymentDetails: {
        paymentTerm: JSON.parse(localStorage.getItem('user')).partner
          .allPaymentTermsAllowed
          ? '14'
          : '0',
        skonto: '0',
        skontoPeriod: '',
        additionalPaymentInformation: '',
      },
      otherDetails: {
        title: '',
        validUntil: null,
        communityId: '',
      },
    },
    positions: [],
    deliveries: [],
    attachments: [],
  })

  const [showHasReplacedComponentsDialog, setShowHasReplacedComponentsDialog] =
    useState(false)
  const [externalAdressValidationResult, setExternalAdressValidationResult] =
    useState(false)

  const [geoData, setGeoData] = useState(null)

  const userObject = JSON.parse(localStorage.getItem('user'))
  const [communities, setCommunities] = useState([])
  const partnerId = userObject.partner.id

  let navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setIsLoading(true)
    communitiesService
      .getCommunitiesOfPartner(partnerId)
      .then((data) => {
        setCommunities(data)
      })
      .catch((error) => {
        console.error('Error fetching communities:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [partnerId])
  useEffect(() => {
    const search = location.search
    const params = new URLSearchParams(search)
    const id = params.get('id')
    const mode = params.get('mode') || 'create'

    if (id) {
      setIsLoading(true)
      requestsService.getRequest(id).then((data) => {
        data.deliveries = data.deliveries.map((delivery) => {
          return {
            ...delivery,
            value:
              delivery.type === 'date'
                ? moment(delivery.value)
                : delivery.value,
          }
        })
        if (mode === 'copy') {
          data.id = undefined
          data.state = 'DRAFT'
          data.generalInformation.otherDetails.validUntil = null
          if (data.community) {
            data.generalInformation.otherDetails.communityId = data.community.id
          }

          data.attachments = []
        } else {
          data.generalInformation.otherDetails.validUntil = moment(
            data.generalInformation.otherDetails.validUntil,
          )
        }
        setIsInitialValid(true)
        setInitialValues(data)
        setIsLoading(false)
      })
    }
  }, [location.search, t])

  const valSchemas = [
    Yup.object().shape({
      id: Yup.number(),
      generalInformation: Yup.object().shape({
        deliveryDetails: Yup.object().shape({
          locations: Yup.array()
            .of(
              Yup.object()
                .shape({
                  company: Yup.string().required(t('GENERAL.REQUIRED')),
                  address: Yup.string().required(t('GENERAL.REQUIRED')),
                  zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
                  place: Yup.string().required(t('GENERAL.REQUIRED')),
                  countryId: Yup.number().required(t('GENERAL.REQUIRED')),
                  deliveryInformations: Yup.string().nullable(),
                  unload: Yup.string().required(),
                  stackHeight: Yup.number()
                    .nullable(true)
                    .transform((_, val) => (val === Number(val) ? val : null)), //NaN to Null
                  stackHeightUnit: Yup.string().required(),
                })
                .test({
                  test: async function (location, context) {
                    return userObject.user.partnerLocationIds.length > 0
                      ? true
                      : await validateLocation(
                          location,
                          context,
                          `generalInformation.deliveryDetails.locations[${context.parent.findIndex(
                            (locationInValues) =>
                              locationInValues.id === location.id,
                          )}]`,
                          setExternalAdressValidationResult,
                          setShowHasReplacedComponentsDialog,
                          (validationResult) => {
                            setGeoData(validationResult.geocode.location)
                          },
                          this,
                          ['route', 'street_number'],
                        )
                  },
                }),
            )
            .min(1, t('GENERAL.MIN_1')),
        }),
        paymentDetails: Yup.object().shape({
          paymentTerm: Yup.string().required(t('GENERAL.REQUIRED')),
          skonto: Yup.string().required(t('GENERAL.REQUIRED')),
          skontoPeriod: Yup.string()
            .nullable()
            .test('required', t('GENERAL.REQUIRED'), function (skontoPeriod) {
              return (
                parseInt(this.parent.skonto) === 0 ||
                (skontoPeriod !== null && skontoPeriod !== '')
              )
            })
            .test(
              'paymentTerm',
              t('REQUEST.PAYMENT_DETAILS.INVALID_REF_PAYMENT_TERM'),
              function (skontoPeriod) {
                return (
                  skontoPeriod === null ||
                  skontoPeriod === '' ||
                  parseInt(skontoPeriod) <= parseInt(this.parent.paymentTerm)
                )
              },
            ),
        }),
        otherDetails: Yup.object().shape({
          validUntil: Yup.date()
            .typeError(t('GENERAL.INVALID_DATE'))
            .required(t('GENERAL.REQUIRED'))
            .test('start', t('GENERAL.INVALID_DATE_PAST'), (start) => {
              if (start) {
                return moment().diff(moment(start), 'days') <= 0
              } else {
                return true
              }
            }),
          communityId: Yup.number()
            .nullable()
            .transform((_, val) => (val === Number(val) ? val : null)), //NaN to Null,
        }),
      }),
    }),
    Yup.object().shape({
      generalInformation: Yup.object().shape({
        deliveryDetails: Yup.object().shape({
          locations: Yup.array().of(
            Yup.object().shape({
              positions: Yup.array()
                .of(
                  Yup.object().shape({
                    id: Yup.number().required(t('GENERAL.REQUIRED')),
                    price: Yup.number()
                      .transform((value, originalValue) =>
                        originalValue === '' ? null : value,
                      )
                      .nullable(true)
                      .min(0.1, t('GENERAL.GREATER_THAN_0')),
                    productId: Yup.string().required(t('GENERAL.REQUIRED')),
                    productUnitId: Yup.number().required(t('GENERAL.REQUIRED')),
                    amount: Yup.number()
                      .required(t('GENERAL.REQUIRED'))
                      .typeError(t('GENERAL.REQUIRED'))
                      .min(0.01, t('GENERAL.GREATER_THAN_0'))
                      .test({
                        message: t('GENERAL.TOO_MUCH'),
                        test: function (v) {
                          if (
                            this.parent.productUnitId === 3 &&
                            parseFloat(v) > 200
                          ) {
                            return false
                          } else {
                            return true
                          }
                        },
                      }),
                  }),
                )
                .min(1, t('GENERAL.MIN_1')),
            }),
          ),
        }),
      }),
    }),
    Yup.object().shape({
      deliveries: Yup.array()
        .nullable()
        .of(
          Yup.object()
            .shape({
              type: Yup.string().required(t('GENERAL.REQUIRED')),
              value: Yup.string(),
              obligatory: Yup.boolean(),
              positions: Yup.array()
                .of(
                  Yup.object().shape({
                    amount: Yup.number(),
                  }),
                )
                .compact(function (v) {
                  return v.amount === ''
                })
                .min(1, t('GENERAL.MIN_1')),
            })
            .test({
              message: t('REQUEST.ADD_DELIVERY_WIZARD.POS_AMOUNT_EXCEEDED'),
              test: function (delivery) {
                let result = true
                delivery.positions.forEach((dPos) => {
                  const { context } = this.options
                  var positionsAmount = parseFloat(
                    context.generalInformation.deliveryDetails.locations[0].positions.find(
                      (pos) => {
                        return pos.id === dPos.positionId
                      },
                    ).amount,
                  )
                  var otherDeliveriesAmount = 0
                  context.deliveries.forEach((otherDelivery) => {
                    if (delivery.id === otherDelivery.id) {
                      return
                    }
                    otherDelivery.positions.forEach((dPos2) => {
                      if (dPos2.positionId === dPos.positionId) {
                        otherDeliveriesAmount += dPos2.amount
                          ? parseFloat(dPos2.amount)
                          : 0
                      }
                    })
                  })
                  result =
                    result &&
                    dPos.amount <=
                      positionsAmount - parseFloat(otherDeliveriesAmount)
                })

                return result
              },
            }),
        ),
    }),
  ]

  const handleSubmit = async (state, formikProps) => {
    const { submitForm, setFieldValue, isValid } = formikProps

    if (isValid) {
      await setFieldValue('state', state)
    }
    await submitForm()
  }

  return (
    <Fragment>
      {(isLoading && <Loading variant={'centered'} />) || (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          isInitialValid={isInitialValid}
          onSubmit={async (values, { setSubmitting, setFieldValue }) => {
            let requestBody = {
              ...values,
              generalInformation: {
                ...values.generalInformation,
                deliveryDetails: {
                  ...values.generalInformation.deliveryDetails,
                  locations:
                    values.generalInformation.deliveryDetails.locations.map(
                      (location) => {
                        return {
                          ...location,
                          geoData: geoData,
                        }
                      },
                    ),
                },
              },
            }

            setIsLoading(true)
            if (!values.id) {
              let partnerId = userObject.partner.id
              let userId = userObject.user.id
              requestsService
                .createRequestOfPartner(partnerId, userId, requestBody)
                .then((data) => {
                  navigate('/adverts')
                  dispatch(
                    alertActions.info(t('REQUEST.PUBLISH_SUCCESSFUL_ADVERT')),
                  )
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .catch(function (error) {
                  setSubmitting = false
                  console.log(error)
                  dispatch(alertActions.error(error))
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .finally(() => {
                  setIsLoading(false)
                })
            } else {
              requestsService
                .updateRequest(
                  requestBody,
                  values.id,
                  true,
                  values.generalInformation.deliveryDetails.locations.map(
                    (location) => location.partnerLocationId,
                  ),
                )
                .then((data) => {
                  setSubmitting = false
                  navigate('/adverts')
                  dispatch(
                    alertActions.info(t('REQUEST.UPDATE_SUCCESSFUL_ADVERT')),
                  )
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .catch(function (error) {
                  setSubmitting = false
                  console.log(error)
                  dispatch(alertActions.error(error))
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .finally(() => {
                  setIsLoading(false)
                })
            }
          }}
        >
          {({
            isSubmitting,
            values,
            setFieldValue,
            errors,
            handleBlur,
            touched,
            submitForm,
            isValid,
            setErrors,
            setTouched,
          }) => (
            <div>
              <Form
                onKeyDown={(keyEvent) => {
                  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    keyEvent.preventDefault()
                  }
                }}
              >
                <Stepper activeStep={activeStep} sx={{ margin: 2 }}>
                  <Step>
                    <StepLabel>{t('REQUEST.GENERAL_INFORMATION')}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{t('REQUEST.PRODUCTS')}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{t('REQUEST.DATE_OF_DELIVERY')}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{t('REQUEST.ATTACHMENTS')}</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>{t('REQUEST.SUMMARY')}</StepLabel>
                  </Step>
                </Stepper>
                <ScrollableFullHeightContainer
                  toolbar={
                    <Fragment>
                      {' '}
                      <Button
                        variant="outlined"
                        color="secondary"
                        style={{ margin: '0 10px 0px 10px' }}
                        onClick={() => {
                          if (activeStep === 0) {
                            navigate('/adverts')
                          } else {
                            setActiveStep(
                              (prevActiveStep) => prevActiveStep - 1,
                            )
                          }
                        }}
                      >
                        {t('GENERAL.BACK')}
                      </Button>
                      {activeStep === 4 ? (
                        <Fragment>
                          {values.state !== 'ARCHIVED' ? (
                            <Tooltip
                              title={
                                values.state !== 'DRAFT'
                                  ? t('REQUEST.SAVE_PUBLISHED_DESCRIPTION')
                                  : t('REQUEST.SAVE_NOT_PUBLISHED_DESCRIPTION')
                              }
                            >
                              <Button
                                variant={values.id ? 'contained' : 'outlined'}
                                color={'secondary'}
                                disabled={isSubmitting}
                                onClick={async () =>
                                  handleSubmit('DRAFT', {
                                    submitForm,
                                    setFieldValue,
                                    isValid,
                                  })
                                }
                              >
                                {t('GENERAL.SAVE')}
                              </Button>
                            </Tooltip>
                          ) : null}

                          {values.state === 'DRAFT' ? (
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{ margin: '0 10px 0px 10px' }}
                              disabled={isSubmitting}
                              onClick={async () =>
                                handleSubmit('PUBLISHED', {
                                  submitForm,
                                  setFieldValue,
                                  isValid,
                                })
                              }
                            >
                              {t('REQUEST.PUBLISH')}
                            </Button>
                          ) : null}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                              if (valSchemas[activeStep]) {
                                valSchemas[activeStep]
                                  .validate(values, {
                                    abortEarly: false,
                                    context: values,
                                  })
                                  .then(function (value) {
                                    setActiveStep(
                                      (prevActiveStep) => prevActiveStep + 1,
                                    )
                                  })
                                  .catch((err) => {
                                    let yupErrors = yupToFormErrors(err)
                                    setErrors(yupErrors)
                                    setTouched(getTouchedObj(yupErrors), false) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                                  })
                              } else {
                                setActiveStep(
                                  (prevActiveStep) => prevActiveStep + 1,
                                )
                              }
                            }}
                          >
                            {t('REQUEST.CONTINUE')}
                          </Button>
                        </Fragment>
                      )}
                    </Fragment>
                  }
                >
                  {activeStep === 0 && (
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <DeliveryDetails
                          readOnly={
                            values.state !== 'DRAFT' &&
                            values.state !== 'PUBLISHED'
                          }
                          setFieldValue={setFieldValue}
                          data={values}
                          mode={
                            new URLSearchParams(location.search).get('mode') ||
                            'create'
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <PaymentDetails
                          data={values}
                          setFieldValue={setFieldValue}
                          readOnly={
                            values.state !== 'DRAFT' &&
                            values.state !== 'PUBLISHED'
                          }
                        />

                        <Section header={t('REQUEST.OTHER_DETAILS')}>
                          <Field
                            component={TextField}
                            fullWidth
                            margin="dense"
                            label={t('REQUEST.TITLE')}
                            variant="outlined"
                            name="generalInformation.otherDetails.title"
                          />
                          <FieldErrorWrapper
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                label: t('REQUEST.VALID_UNTIL'),
                                margin: 'dense',
                              },
                            }}
                            name="generalInformation.otherDetails.validUntil"
                            fullWidth
                            component={DatePicker}
                            inputFormat="DD/MM/YYYY"
                            minDate={moment()}
                          />
                          {communities !== null && communities.length > 0 && (
                            <Field
                              component={TextField}
                              name="generalInformation.otherDetails.communityId"
                              select
                              fullWidth
                              label={t('REQUEST.COMMUNITY')}
                              variant="outlined"
                              margin="dense"
                            >
                              <MenuItem value={null}>
                                {t('COMMUNITY.SELECT')}
                              </MenuItem>
                              {communities
                                .filter(
                                  (community) => community.state === 'ACTIVE',
                                )
                                .map((community) => (
                                  <MenuItem
                                    value={community.id}
                                    key={community.id}
                                  >
                                    {community.name}
                                  </MenuItem>
                                ))}
                            </Field>
                          )}
                        </Section>
                      </Grid>
                    </Grid>
                  )}

                  {activeStep === 1 && (
                    <AdvertPositions
                      data={values}
                      readOnly={
                        values.state !== 'DRAFT' && values.state !== 'PUBLISHED'
                      }
                      errors={errors}
                      setFieldValue={setFieldValue}
                      valSchema={valSchemas[activeStep]}
                    />
                  )}
                  {activeStep === 2 && (
                    <Deliveries
                      data={values}
                      readOnly={
                        values.state !== 'DRAFT' && values.state !== 'PUBLISHED'
                      }
                      setFieldValue={setFieldValue}
                      type={'ADVERT'}
                      errors={errors}
                      positions={
                        values.generalInformation.deliveryDetails.locations[0]
                          .positions
                      }
                    />
                  )}
                  {activeStep === 3 && (
                    <Attachments
                      data={values}
                      readOnly={
                        values.state !== 'DRAFT' && values.state !== 'PUBLISHED'
                      }
                      setFieldValue={setFieldValue}
                      type={'REQUEST'}
                    />
                  )}

                  {activeStep === 4 && (
                    <RequestDetails communities={communities} data={values} />
                  )}
                </ScrollableFullHeightContainer>
              </Form>
              <AgreementDialog
                open={agreeDialogOpen}
                handleClose={() => {
                  setAgreeDialogOpen(!agreeDialogOpen)
                }}
                agree={() => {
                  requestsService.deleteRequest(values.id)
                  navigate('/adverts')
                  dispatch(alertActions.info(t('REQUEST.DELETE_SUCCESSFUL')))
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                }}
              />
              {showHasReplacedComponentsDialog && (
                <DeliveryDetailsReplacedComponentsDialog
                  setAddress={() => {
                    let replacedAddressComponents =
                      externalAdressValidationResult.address.addressComponents.filter(
                        (addressComponent) => addressComponent.replaced,
                      )

                    replacedAddressComponents.forEach((addressComponent) => {
                      switch (addressComponent.componentType) {
                        case 'route':
                        case 'street_number':
                          let adress =
                            externalAdressValidationResult.address.addressComponents.find(
                              (addressComponent) =>
                                addressComponent.componentType === 'route',
                            )
                          let streetNumber =
                            externalAdressValidationResult.address.addressComponents.find(
                              (addressComponent) =>
                                addressComponent.componentType ===
                                'streetNumber',
                            )
                          setFieldValue(
                            `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                              (location) =>
                                location.id ===
                                externalAdressValidationResult.locationId,
                            )}].address`,
                            `${adress} ${streetNumber}`,
                          )
                          break
                        case 'postal_code':
                          setFieldValue(
                            `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                              (location) =>
                                location.id ===
                                externalAdressValidationResult.locationId,
                            )}].zipcode`,
                            addressComponent.componentName.text,
                          )
                          break
                        case 'locality':
                          setFieldValue(
                            `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                              (location) =>
                                location.id ===
                                externalAdressValidationResult.locationId,
                            )}].place`,
                            addressComponent.componentName.text,
                          )
                          break
                        // case 'country':
                        //   break
                        default:
                          break
                      }
                      setFieldValue()
                    })
                  }}
                  addressValidationResult={externalAdressValidationResult}
                  hide={() => setShowHasReplacedComponentsDialog(false)}
                />
              )}
            </div>
          )}
        </Formik>
      )}
    </Fragment>
  )
}
export default withTranslation()(connect()(Advert))
