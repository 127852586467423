import { Grid, Paper, Switch, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { badgesService } from '../../../../../../_services/badgesService'
import { partnerBadgesService } from '../../../../../../_services/partnerBadgesService'

const PartnerBadges = (props) => {
  const { t, selectedPartnerId } = props
  const [badges, setBadges] = useState([])
  // Initialisieren als leeres Array, da es ein Array von Objekten sein wird
  const [activeBadges, setActiveBadges] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const allBadges = await badgesService.getBadges()
      setBadges(allBadges)

      const partnerActiveBadges = await partnerBadgesService.getBadgesOfPartner(
        selectedPartnerId,
      )

      // Stellen Sie sicher, dass partnerActiveBadges als Array behandelt wird
      if (Array.isArray(partnerActiveBadges)) {
        setActiveBadges(partnerActiveBadges)
      } else {
        console.error(
          'partnerActiveBadges ist kein Array:',
          partnerActiveBadges,
        )
      }
    }
    fetchData()
  }, [selectedPartnerId])

  const handleBadgeToggle = async (badgeId) => {
    try {
      await partnerBadgesService.updateBadgeOfPartner(
        selectedPartnerId,
        badgeId,
      )
      const updatedActiveBadges = await partnerBadgesService.getBadgesOfPartner(
        selectedPartnerId,
      )
      setActiveBadges(updatedActiveBadges)
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Badge-Status:', error)
    }
  }

  return (
    <Grid container spacing={2}>
      {badges.map((badge) => (
        <Grid item xs={12} sm={6} key={badge.id}>
          <Paper
            style={{ padding: '8px', display: 'flex', alignItems: 'center' }}
          >
            <Typography variant="body1">Off</Typography>
            <Switch
              checked={
                activeBadges && activeBadges.some((ab) => ab.id === badge.id)
              }
              onChange={() => handleBadgeToggle(badge.id)}
              color="primary"
            />
            <Typography variant="body1">On</Typography>
            <div style={{ flexGrow: 1, textAlign: 'center', margin: '0 8px' }}>
              <Typography variant="body1">{badge.name}</Typography>
            </div>
            <img
              src={badge.imageLink}
              alt={badge.name}
              style={{ width: '50px', height: '50px', marginRight: '10px' }}
            />
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

export default withTranslation()(PartnerBadges)
