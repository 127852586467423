import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const partnerRestrictionsService = {
  createPartnerRestriction,
  getPartnerRestrictionsOfPartner,
  deletePartnerRestriction,
}

function getPartnerRestrictionsOfPartner(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/partner-restrictions`,
    requestOptions,
  )
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function createPartnerRestriction(requestBody) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }

  return fetch(
    `${config.apiUrlBase}/partner-restrictions`,
    requestOptions,
  ).then(handleResponse)
}

function deletePartnerRestriction(partnerRestrictionId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/partner-restrictions/${partnerRestrictionId}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './partnerRestrictionsService'
