import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { externalAddressComponentTypeLookup } from '../../_constants/lookupConstants'

const DeliveryDetailsReplacedComponentsDialog = (props) => {
  const { t, hide, setAddress, addressValidationResult } = props

  return (
    <Dialog open={true} fullWidth={true} maxWidth={'md'}>
      <DialogTitle>
        {t(
          'DELIVERY_DETAILS_REPLACED_COMPONENTS_DIALOG.REPLACE_DELIVERY_ADDRESS',
        )}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {t(
            'DELIVERY_DETAILS_REPLACED_COMPONENTS_DIALOG.REPLACE_DELIVERY_ADDRESS_EXPLAINATION',
          )}
        </Typography>
        {addressValidationResult.address.addressComponents
          .filter((addressComponent) => addressComponent.replaced)
          .map((addressComponent) => (
            <Typography>
              {`${
                externalAddressComponentTypeLookup[
                  addressComponent.componentType
                ]
              } ${addressComponent.componentName.text}`}
            </Typography>
          ))}
      </DialogContent>

      <DialogActions>
        <Button
          variant={'outlined'}
          color={'secondary'}
          onClick={() => {
            hide()
          }}
        >
          {t('GENERAL.CANCEL')}
        </Button>
        <Button
          variant={'contained'}
          color="secondary"
          onClick={() => {
            setAddress()
            hide()
          }}
        >
          {t('DELIVERY_DETAILS_REPLACED_COMPONENTS_DIALOG.ACCEPT')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(DeliveryDetailsReplacedComponentsDialog)
