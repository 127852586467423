import { Add } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import moment from 'moment'
import numeral from 'numeral'
import { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  countryList,
  stackHeightUnitLookup,
} from '../../../../_constants/lookupConstants'
import {
  formatToNumber,
  formatToPrice,
} from '../../../../_helpers/number-formatting'
import NumberFormatCustom from '../../../../_helpers/numberFormatCustom'
import { _applicationService } from '../../../../_services/_applicationService'
import { generalService } from '../../../../_services/generalService'
import Deliveries from '../../../Deliveries'
import GoogleAutocomplete from '../../../GoogleAutocomplete'
import PositionCard from '../../../PositionCard'
import ProductConfigurator from '../../../ProductConfigurator'

const PriceChecker = (props) => {
  const { t } = props

  const [showProductConfigurator, setShowProductConfigurator] = useState(true)
  const [results, setResults] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        address: '',
        zipcode: '',
        place: '',
        deliveries: [],
        stackHeightUnit: 'QUANTITY',
        position: {
          id: 1,
          productId: null,
          amount: 0,
        },
      }}
      validationSchema={Yup.object().shape({
        position: Yup.object().shape({
          productId: Yup.number().required(t('GENERAL.REQUIRED')),
          amount: Yup.number().required(t('GENERAL.REQUIRED')),
        }),
        address: Yup.string().required(t('GENERAL.REQUIRED')),
        zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
        place: Yup.string().required(t('GENERAL.REQUIRED')),
        deliveries: Yup.array().of(
          Yup.object()
            .shape({
              type: Yup.string().required(t('GENERAL.REQUIRED')),
              value: Yup.string().required(t('GENERAL.REQUIRED')),
              obligatory: Yup.boolean(),
              positions: Yup.array()
                .of(
                  Yup.object().shape({
                    amount: Yup.number(),
                  }),
                )
                .compact(function (v) {
                  return v.amount === ''
                })
                .min(1, t('GENERAL.MIN_1')),
            })
            .test({
              message: t('REQUEST.ADD_DELIVERY_WIZARD.POS_AMOUNT_EXCEEDED'),
              test: function (delivery) {
                let result = true
                delivery.positions.forEach((dPos) => {
                  const { context } = this.options

                  // if order is placed against tender order skip this validation
                  if (context.tenderOrderId) {
                    return true
                  }

                  var positionsAmount = parseFloat(context.position.amount)
                  var otherDeliveriesAmount = 0
                  context.deliveries.forEach((otherDelivery) => {
                    if (delivery.id === otherDelivery.id) {
                      return
                    }
                    otherDelivery.positions.forEach((dPos2) => {
                      if (dPos2.positionId === dPos.positionId) {
                        otherDeliveriesAmount += parseFloat(dPos2.amount)
                      }
                    })
                  })
                  result =
                    result &&
                    dPos.amount <=
                      positionsAmount - parseFloat(otherDeliveriesAmount)
                })

                return result
              },
            }),
        ),
      })}
      //validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        setIsLoading(true)
        setSubmitting = true

        try {
          const payload = {
            ...values,
            stackHeight: values.stackHeight ? values.stackHeight : null,
            position: {
              ...values.position,
              localId: values.position.id,
              positionProperties: values.position.positionProperties.map(
                (positionProperty) => {
                  return {
                    ...positionProperty,
                    productPropertyId: positionProperty.productProperty.id,
                  }
                },
              ),
            },
            deliveries: values.deliveries.map((delivery) => {
              return {
                ...delivery,
                localId: delivery.id,
                positions: undefined,
                deliveryPositions: delivery.positions.map((dPos) => {
                  return {
                    ...dPos,
                    localId: dPos.positionId,
                  }
                }),
              }
            }),
          }

          let data = await _applicationService.checkPrice(payload)

          setResults(data)
        } catch (err) {
          alert(err)
        } finally {
          setIsLoading(false)
          setSubmitting = false
        }
      }}
    >
      {({ values, setFieldValue, errors, submitForm }) => {
        return (
          <Form>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Field
                      fullWidth={true}
                      component={TextField}
                      margin="dense"
                      size={'small'}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        endAdornment: (
                          <InputAdornment position="end">
                            {stackHeightUnitLookup[values.stackHeightUnit]}
                          </InputAdornment>
                        ),
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        decimalScale: 0,
                        allowNegative: false,
                      }}
                      label={`${t('DELIVERY_DETAILS.STACK_HEIGHT')} (${t(
                        'GENERAL.OPTIONAL',
                      )})`}
                      variant="outlined"
                      name={`stackHeight`}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonGroup
                      color={'secondary'}
                      sx={{
                        height: '36.7px',
                        marginTop: '8px',
                      }}
                      fullWidth
                    >
                      <Button
                        variant={
                          values.stackHeightUnit === 'QUANTITY'
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() =>
                          setFieldValue(`stackHeightUnit`, 'QUANTITY')
                        }
                      >
                        {t('DELIVERY_DETAILS.PIECES')}
                      </Button>
                      <Button
                        variant={
                          values.stackHeightUnit === 'CM'
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() => setFieldValue(`stackHeightUnit`, 'CM')}
                      >
                        {t('DELIVERY_DETAILS.CENTIMETER')}
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  label={t('REQUEST.DELIVERY_DETAILS.COUNTRY')}
                  name={'countryId'}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  select
                >
                  {Object.entries(countryList).map((country) => (
                    <MenuItem value={country[1]} key={country[0]}>
                      {country[0]}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} sm={6}>
                <GoogleAutocomplete
                  setFieldValue={setFieldValue}
                  formikName={'address'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  label={t('REQUEST.DELIVERY_DETAILS.ZIP_CODE')}
                  name={'zipcode'}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  label={t('REQUEST.DELIVERY_DETAILS.PLACE')}
                  name={'place'}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                {values.position.productId ? (
                  <PositionCard
                    data={values.position}
                    showProductDetails={true}
                    updatePositionFunction={(product, positionProperties) => {
                      if (product) {
                        setFieldValue('position.productId', product.id)
                        setFieldValue(
                          'position.positionProperties',
                          positionProperties,
                        )
                      }
                    }}
                    deletePositionFunction={() => {
                      setFieldValue('position.productId', null)
                      setFieldValue('position.positionProperties', [])
                    }}
                    readOnly={false}
                    showImage={true}
                    imageSize={'small'}
                  >
                    <Field
                      fullWidth={true}
                      component={TextField}
                      margin="dense"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        decimalScale: 0,
                        allowNegative: false,
                      }}
                      label={t('REQUEST.AMOUNT')}
                      variant="outlined"
                      name={`position.amount`}
                    />
                  </PositionCard>
                ) : (
                  <Fragment>
                    <Button
                      startIcon={<Add />}
                      variant="contained"
                      color="secondary"
                      size={'medium'}
                      style={{ marginBottom: 20, marginTop: 10 }}
                      onClick={() => setShowProductConfigurator(true)}
                    >
                      {t('REQUEST.ADD_POSITION')}
                    </Button>
                    <ErrorMessage
                      name={'position.productId'}
                      render={(msg) => <div className="help-block">{msg}</div>}
                    />
                  </Fragment>
                )}
              </Grid>
              <Grid item xs={12}>
                <Deliveries
                  data={values}
                  setFieldValue={setFieldValue}
                  type={'ORDER_REGISTRATION'}
                  errors={errors}
                  positions={[values.position]}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => submitForm()}
                  variant="contained"
                  color={'secondary'}
                >
                  {t('GENERAL.SUBMIT')}
                </Button>
              </Grid>
            </Grid>
            <ProductConfigurator
              readOnly={false}
              show={showProductConfigurator}
              close={(e) => {
                setShowProductConfigurator(false)
              }}
              createPositionFunction={(product, posProperties) => {
                setShowProductConfigurator(false)
                setFieldValue('position.productId', product.id)
                setFieldValue(
                  'position.productUnitId',
                  product.productUnits.find((productUnit) =>
                    [3, 5, 7].includes(productUnit.id),
                  ).id,
                )
                setFieldValue('position.positionProperties', posProperties)
              }}
            />
            {results && (
              <Dialog
                maxWidth={'lg'}
                fullWidth={true}
                open={true}
                onClose={() => setResults(null)}
              >
                <DialogContent>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'h5'}>
                              {t(
                                'REQUEST_POSITION_COMPARISON.JOB_OFFER_AND_AUTOMATIC_OFFER_POSITIONS_SERVICES',
                              )}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {t('REQUEST_POSITION_COMPARISON.SALES_PRICE')}
                          </TableCell>
                          <TableCell>{t('PRICE_CHECKER.SOURCE')}</TableCell>
                          <TableCell>{t('PRICE_CHECKER.DELIVERIES')}</TableCell>
                          <TableCell>{t('PRICE_CHECKER.PARTNER')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {results.length > 0 ? (
                          <Fragment>
                            {results
                              .filter((result) => result.source !== 'ADVERT')
                              .sort((a, b) => a.price - b.price)
                              .map((result, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {formatToPrice(result.price)}
                                  </TableCell>
                                  <TableCell>{result.source}</TableCell>
                                  <TableCell>
                                    {result.deliveries.map((delivery) => {
                                      let dPos = delivery.deliveryPositions[0]

                                      return (
                                        <Typography>{`${t(
                                          'REQUEST.DELIVERY',
                                        )} ${
                                          delivery.type === 'date'
                                            ? moment(delivery.value).format(
                                                'DD.MM.YYYY',
                                              )
                                            : `${t(
                                                'ORDERS.CW',
                                              )} ${generalService.convertDateToCalendarWeek(
                                                delivery.value,
                                              )}`
                                        }: ${numeral(
                                          parseFloat(dPos.amount),
                                        ).format('0,0.[00]')}`}</Typography>
                                      )
                                    })}
                                  </TableCell>
                                  <TableCell>{result.partner}</TableCell>
                                </TableRow>
                              ))}
                          </Fragment>
                        ) : (
                          <TableRow style={{ height: '200px' }}>
                            <TableCell
                              colSpan={5}
                              align="center"
                              style={{
                                paddingTop: '40px',
                                paddingBottom: '40px',
                              }}
                            >
                              {t('AUTOMATIC_OFFERS.NO_DATA_YET')}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DialogContent>
                <DialogContent>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant={'h5'}>
                              {t('REQUEST_POSITION_COMPARISON.ADVERTS')}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {t('REQUEST_POSITION_COMPARISON.EXW_SALES_PRICE')}
                          </TableCell>
                          <TableCell>{t('PRICE_CHECKER.DISTANCE')}</TableCell>
                          <TableCell>{t('PRICE_CHECKER.PARTNER')}</TableCell>
                          <TableCell>{t('PRICE_CHECKER.ADVERT_ID')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {results.length > 0 ? (
                          <Fragment>
                            {results
                              .filter((result) => result.source === 'ADVERT')
                              .sort((a, b) => {
                                const priceA =
                                  a.price === null ? Number.MAX_VALUE : a.price
                                const priceB =
                                  b.price === null ? Number.MAX_VALUE : b.price
                                return priceA - priceB
                              })
                              .map((result, index) => (
                                <TableRow
                                  key={index}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {result.price === null
                                      ? t('ADVERT.NO_PRICE')
                                      : formatToPrice(result.price)}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {`${formatToNumber(result.distance)} KM`}
                                  </TableCell>
                                  <TableCell>{result.partner}</TableCell>
                                  <TableCell>{result.requestId}</TableCell>
                                </TableRow>
                              ))}
                          </Fragment>
                        ) : (
                          <TableRow style={{ height: '200px' }}>
                            <TableCell
                              colSpan={5}
                              align="center"
                              style={{
                                paddingTop: '40px',
                                paddingBottom: '40px',
                              }}
                            >
                              {t('AUTOMATIC_OFFERS.NO_DATA_YET')}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setResults(null)}
                    variant="outlined"
                    color={'secondary'}
                  >
                    {t('GENERAL.CANCEL')}
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default withTranslation()(PriceChecker)
