import {
  Add,
  Archive,
  Edit,
  EventAvailable,
  FileCopy,
  LocalOffer,
  Publish,
} from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import {
  Button,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { alertActions } from '../../../../_actions/alertActions'
import {
  categorizedProductLookup,
  countryLookup,
  deliveryTypeLookup,
  productLookup,
  productStateLookup,
  requestStateLookup,
} from '../../../../_constants/lookupConstants'
import { requestsService } from '../../../../_services/requestsService'
import { usersService } from '../../../../_services/usersService'
import AgreementDialog from '../../../AgreementDialog'
import { InfoTooltip } from '../../../InfoTooltip'
import { PositionsList } from '../../../PositionsList'
import RequestDetails from '../../../RequestDetails'
import RequestPositionsOfferComparison from '../../../RequestPositionsOfferComparison'
import ResourceList from '../../../ResourceList/_components/ResourceList'
import ViewDescription from '../../../ViewDescription'

const Adverts = (props) => {
  const { t, dispatch } = props

  const [selectedRequest, setSelectedRequest] = useState(null)
  const [
    showAgreementDialogDeleteRequest,
    setShowShowAgreementDialogDeleteRequest,
  ] = useState(false)
  const [showRequestPositionComparison, setShowRequestPositionComparison] =
    useState(false)
  const [usersOfPartner, setUsersOfPartner] = useState([])
  const [forceUpdateCount, forceUpdate] = useState(0)

  const userObject = JSON.parse(localStorage.getItem('user'))

  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const search = location.search
  const params = new URLSearchParams(search)
  let defaultValueRequestId = params.get('id')

  let offerComparisonRequestId = params.get('offerComparisonRequestId')
  window.history.pushState({}, document.title, window.location.pathname)

  let navigate = useNavigate()

  useEffect(() => {
    usersService.getUsersOfPartner(userObject.partner.id).then((users) => {
      setUsersOfPartner(users)
    })
  }, [userObject.partner.id])

  useEffect(() => {
    if (offerComparisonRequestId) {
      requestsService.getRequest(offerComparisonRequestId).then((request) => {
        setSelectedRequest(request)
        setShowRequestPositionComparison(true)
      })
    }
  }, [])

  return (
    <Fragment>
      <ViewDescription description={t('ADVERTS.VIEW_DESCRPTION')} />
      <Fragment>
        <ResourceList
          fetchMethod={(filterValues, pagination) =>
            requestsService.getRequestsOfPartner(
              userObject.partner.id,
              'ADVERT',
              filterValues,
              pagination,
              userObject.user.partnerLocationIds,
            )
          }
          forceUpdateCount={forceUpdateCount}
          filterId={'ADVERTS_V2'}
          filters={[
            {
              key: 'requestId.startsWith',
              label: t('ADVERTS.REQUEST_ID'),
              variant: 'textfield',
              defaultValue: defaultValueRequestId,
            },
            {
              key: 'state',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(requestStateLookup),
              lookup: requestStateLookup,
              label: t('REQUESTS.STATE'),
            },
            {
              key: 'positions.productId.eq',
              variant: 'categorizedCheckboxList',
              values: categorizedProductLookup.map((category) => {
                return {
                  label: category.name,
                  items: category.products.map((product) => product.id),
                }
              }),
              lookup: productLookup,
              label: t('ADVERTS.PRODUCT'),
            },
            {
              key: 'productState',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(productStateLookup),
              lookup: productStateLookup,
              label: t('ADVERTS.PRODUCT_STATE'),
            },
            {
              key: 'deliveryType',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(deliveryTypeLookup),
              lookup: deliveryTypeLookup,
              label: t('ADVERTS.DELIVERY_TYPE'),
            },
            {
              key: 'countries.eq',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(countryLookup),
              lookup: countryLookup,
              label: t('ADVERTS.COUNTRY'),
            },
            {
              key: 'zipcode.startsWith',
              label: t('ADVERTS.ZIPCODE'),
              variant: 'textfield',
            },
            {
              key: 'place.startsWith',
              label: t('ADVERTS.PLACE'),
              variant: 'textfield',
            },
            {
              key: 'userId.eq',
              variant: 'checkboxList',
              values: usersOfPartner.map((user) => user.id),
              lookup: Object.fromEntries(
                usersOfPartner.map((user) => [
                  user.id,
                  `${user.firstname} ${user.lastname}`,
                ]),
              ),
              label: t('GENERAL.USER'),
            },
            {
              key: 'title.like',
              label: t('REQUESTS.TITLE'),
              variant: 'textfield',
            },
          ]}
          hint={t('ADVERTS.VIEW_DESCRPTION')}
          selectionText={t('ADVERTS.SELECT_AN_ADVERT')}
          sorting={[
            {
              key: 'id',
              default: true,
              label: t('ADVERTS.REQUEST_ID'),
            },
          ]}
          toolbarSecondary={
            <Button
              size="medium"
              color={'secondary'}
              variant={'contained'}
              startIcon={<Add />}
              onClick={() => {
                navigate({
                  pathname: '/advert',
                })
              }}
            >
              {t('REQUESTS.NEW_ADVERT')}
            </Button>
          }
          resourceHeaderRenderFunction={(request) =>
            `${t('MARKETPLACE.ADVERT')}: ${request.id}`
          }
          listHeaderRenderFunction={(request) => (
            <Fragment>
              <div>
                {`${t('MARKETPLACE.ADVERT')}
                  : ${request.id}${
                  request.publishedAt
                    ? ` | ${moment(request.publishedAt)
                        .local()
                        .format('DD.MM.YYYY')}`
                    : ''
                }`}
                {request.generalInformation.otherDetails.title
                  ? ` | ${request.generalInformation.otherDetails.title}`
                  : ''}
              </div>
              <div>{`${request.user.firstname} ${request.user.lastname}`}</div>
              {`${
                request.generalInformation.deliveryDetails.locations[0].company
              } |  ${
                request.generalInformation.deliveryDetails.locations[0].zipcode
              } | ${
                request.generalInformation.deliveryDetails.locations[0].place
              } | ${
                countryLookup[
                  request.generalInformation.deliveryDetails.locations[0]
                    .countryId
                ]
              }`}
            </Fragment>
          )}
          headerActionRenderFunction={(request) => (
            <Fragment>
              <Stack direction={'column'}>
                <Stack direction={isMobile ? 'row-reverse' : 'row'} spacing={1}>
                  {request.generalInformation.otherDetails.communityName && (
                    <InfoTooltip
                      disableTouchListener
                      disableFocusListener
                      title={
                        request.generalInformation.otherDetails.communityName
                      }
                    >
                      <GroupsIcon color={'secondary'} />
                    </InfoTooltip>
                  )}

                  <Chip
                    size={'small'}
                    color={
                      request.state === 'DRAFT' ||
                      request.state === 'ARCHIVED' ||
                      request.state === 'EXPIRED'
                        ? 'default'
                        : 'info'
                    }
                    label={requestStateLookup[request.state]}
                  />
                </Stack>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: isMobile ? 'left' : 'right',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant={'caption'}>
                    {`${t('REQUESTS.OFFERS')}: ${request.offerAmount}`}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: isMobile ? 'left' : 'right',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant={'caption'}>
                    {`${t('REQUESTS.ORDERS')}: ${request.orderAmount}`}
                  </Typography>
                </div>
              </Stack>
            </Fragment>
          )}
          contentRenderFunction={(request) => (
            <PositionsList positions={request.positions} />
          )}
          resourceRenderFunction={(request) => (
            <RequestDetails requestId={request.id} />
          )}
          actionsRenderFunction={(request) => {
            return [
              {
                icon: <EventAvailable />,
                hidden: request.offerState !== 'SUCCESSFUL',
                name:
                  request.offerState !== 'SUCCESSFUL'
                    ? t('REQUESTS.NO_ORDERS')
                    : t('REQUESTS.GOTO_ORDERS'),
                primary: false,
                onClick: () =>
                  navigate({
                    pathname: '/deliveries-manufacturer',
                    search: 'requestId=' + request.id,
                  }),
              },
              {
                icon: <Publish />,
                hidden: request.state !== 'DRAFT',
                name:
                  request.state !== 'DRAFT'
                    ? t('REQUESTS.ALREADY_PUBLISHED')
                    : t('GENERAL.PUBLISH'),
                primary: false,
                onClick: () => {
                  requestsService
                    .updateRequest(
                      { state: 'PUBLISHED' },
                      request.id,
                      false,
                      request.generalInformation.deliveryDetails.locations.map(
                        (location) => location.partnerLocationId,
                      ),
                    )
                    .then(() => {
                      forceUpdate(forceUpdateCount + 1)
                      dispatch(alertActions.info(t('REQUESTS.PUBLISHED')))
                      setTimeout(() => {
                        dispatch(alertActions.clear())
                      }, alertActions.alertTimeout)
                    })
                    .catch((error) => {
                      dispatch(alertActions.error(error))
                      setTimeout(() => {
                        dispatch(alertActions.clear())
                      }, alertActions.alertTimeout)
                    })
                },
              },
              {
                icon: <FileCopy />,
                name: t('REQUESTS.COPY'),
                primary: false,
                onClick: () =>
                  navigate({
                    pathname: '/advert',
                    search: '?mode=copy&id=' + request.id,
                  }),
              },
              {
                icon: <Archive />,
                name:
                  request.state === 'ARCHIVED'
                    ? t('REQUESTS.ALREADY_ARCHIVED')
                    : t('GENERAL.ARCHIVE'),
                hidden: request.state === 'ARCHIVED',
                primary: false,
                onClick: () => {
                  setSelectedRequest(request)
                  setShowShowAgreementDialogDeleteRequest(true)
                },
              },
              {
                icon: <Edit />,
                name: t('REQUESTS.EDIT'),
                primary: false,
                hidden: request.state === 'ARCHIVED',
                onClick: () => {
                  if (
                    request.state === 'NEGOTIATION' ||
                    request.state === 'ARCHIVED'
                  ) {
                    dispatch(
                      alertActions.info(
                        t('REQUESTS.EDIT_NO_LONGER_POSSIBLE_CONTENT'),
                      ),
                    )
                    setTimeout(() => {
                      dispatch(alertActions.clear())
                    }, alertActions.alertTimeout)
                  }
                  navigate({
                    pathname: '/advert',
                    search: '?mode=edit&id=' + request.id,
                  })
                },
              },
              {
                icon: <LocalOffer />,
                hidden: request.offerAmount === 0,
                name: t('REQUESTS.COMPARE_OFFERS'),
                primary: true,
                onClick: () => {
                  setSelectedRequest(request)
                  setShowRequestPositionComparison(true)
                },
              },
            ]
          }}
          dataIdentifier={'id'}
        />
        <AgreementDialog
          open={showAgreementDialogDeleteRequest}
          handleClose={() => {
            setShowShowAgreementDialogDeleteRequest(false)
          }}
          agree={() => {
            requestsService.deleteRequest(selectedRequest.id)
            dispatch(alertActions.info(t('REQUESTS.DELETE_SUCCESSFUL')))
            setShowShowAgreementDialogDeleteRequest(false)
            forceUpdate(forceUpdateCount + 1)

            setTimeout(() => {
              dispatch(alertActions.clear())
            }, alertActions.alertTimeout)
          }}
        />
        {showRequestPositionComparison && (
          <RequestPositionsOfferComparison
            hide={() => setShowRequestPositionComparison(false)}
            requestId={selectedRequest.id}
          />
        )}
      </Fragment>
    </Fragment>
  )
}

export default withTranslation()(connect()(Adverts))
