import { AddCircle, Delete } from '@mui/icons-material'
import {
  FormControlLabel,
  Grid,
  IconButton,
  Checkbox as MUICheckbox,
  TextField as MUITextField,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import _ from 'lodash'
import { Fragment } from 'react'
import * as Yup from 'yup'
import DifferenceWrapper from '../_components/DifferenceWrapper'
import { InfoTooltip } from '../_components/InfoTooltip'
import i18n from '../i18n'
import {
  generatePositionPropertyValue,
  translateProductProperty,
  translateProductPropertyEnumValue,
} from './little'
import NumberFormatCustom from './numberFormatCustom'
export function generateField(productProperty, formikName, value) {
  let field = null

  switch (productProperty.type) {
    case 'BOOLEAN':
      field = (
        <Field
          component={CheckboxWithLabel}
          type={'checkbox'}
          checked={value}
          name={formikName}
          margin="dense"
          Label={{
            label: 'YES/NO',
          }}
        />
      )
      break
    case 'ENUM':
      field = (
        <Field
          component={TextField}
          select
          name={formikName}
          label={productProperty.name}
          variant="outlined"
          fullWidth={true}
          margin="dense"
        >
          {productProperty.productPropertyEnumValues
            .sort((a, b) => a.renderPosition - b.renderPosition)
            .map((enumValue) => (
              <MenuItem key={enumValue.value} value={enumValue.value}>
                {enumValue.value}
              </MenuItem>
            ))}
        </Field>
      )
      break
    case 'INTEGER':
      field = (
        <Field
          component={TextField}
          fullWidth
          margin="dense"
          label={productProperty.name}
          variant="outlined"
          name={formikName}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            decimalScale: 0,
            allowNegative: false,
          }}
        />
      )
      break
    case 'FLOAT':
      field = (
        <Field
          component={TextField}
          fullWidth
          margin="dense"
          label={productProperty.name}
          variant="outlined"
          name={formikName}
          InputProps={{
            inputComponent: NumberFormatCustom,
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            decimalScale: 2,
            allowNegative: false,
          }}
        />
      )
      break
    case 'TEXT':
      field = (
        <Field
          component={TextField}
          name={formikName}
          fullWidth
          margin="dense"
          label={productProperty.name}
          variant="outlined"
          multiline
          rows="4"
        />
      )
      break
    default:
      break
  }

  return field
}

export function generateProductConfiguratorField(
  productProperty,
  formikName,
  value,
  setFieldValue,
  readOnly,
  showDifference,
  diffObjectType,
  differences,
  values,
  isMobile,
) {
  let field = null

  let differenceValue = false

  try {
    differenceValue = _.get(differences, `positionProperties${formikName}`)
  } catch (e) {
    differenceValue = false
  }

  let isDifferent =
    showDifference &&
    !productProperty.readonly &&
    differences &&
    differenceValue !== false

  switch (productProperty.type) {
    case 'BOOLEAN':
      field =
        readOnly || productProperty.readonly ? (
          <FormControlLabel
            disabled
            control={<MUICheckbox />}
            label={translateProductProperty(productProperty)}
            checked={value === 'true' || value === true}
          />
        ) : (
          <Field
            component={CheckboxWithLabel}
            type={'checkbox'}
            checked={value === 'true' || value === true}
            name={formikName}
            margin="dense"
            Label={{
              label: translateProductProperty(productProperty),
            }}
            disabled={readOnly || productProperty.readonly}
            onChange={(e) => {
              setFieldValue(formikName, !value)
              handleTriggers(
                productProperty,
                values,
                e.target.value,
                setFieldValue,
              )
            }}
          />
        )
      break
    case 'ENUM':
      field =
        productProperty.variant === 'SELECT' || isMobile ? (
          (field =
            readOnly || productProperty.readonly ? (
              <MUITextField
                fullWidth
                margin="dense"
                label={translateProductProperty(productProperty)}
                variant="outlined"
                disabled={true}
                value={translateProductPropertyEnumValue(
                  productProperty.productPropertyEnumValues.find(
                    (enumValue) =>
                      enumValue.value.toString() === value.toString(),
                  ),
                )}
                InputProps={{
                  endAdornment: productProperty.unit
                    ? productProperty.unit
                    : null,
                }}
              />
            ) : (
              <Field
                component={TextField}
                select
                name={formikName}
                label={translateProductProperty(productProperty)}
                variant="outlined"
                fullWidth={true}
                margin="dense"
                disabled={readOnly || productProperty.readonly}
                onChange={(e) => {
                  setFieldValue(formikName, e.target.value)
                  handleTriggers(
                    productProperty,
                    values,
                    e.target.value,
                    setFieldValue,
                  )
                }}
              >
                {productProperty.productPropertyEnumValues
                  .sort((a, b) => a.renderPosition - b.renderPosition)
                  .map((enumValue) => (
                    <MenuItem key={enumValue.value} value={enumValue.value}>
                      {translateProductPropertyEnumValue(enumValue)}
                    </MenuItem>
                  ))}
              </Field>
            ))
        ) : (
          <Fragment>
            <ToggleButtonGroup
              fullWidth
              value={value ? value.toString() : value}
              disabled={readOnly || productProperty.readonly}
              sx={{ alignSelf: 'center' }}
              size={'medium'}
            >
              {productProperty.productPropertyEnumValues
                .sort((a, b) => a.renderPosition - b.renderPosition)
                .map((enumValue) => {
                  return (
                    <ToggleButton
                      value={enumValue.value}
                      onClick={() => {
                        setFieldValue(formikName, enumValue.value)

                        handleTriggers(
                          productProperty,
                          values,
                          enumValue.value,
                          setFieldValue,
                        )
                      }}
                    >
                      {translateProductPropertyEnumValue(enumValue)}
                    </ToggleButton>
                  )
                })}
            </ToggleButtonGroup>
            <ErrorMessage
              name={formikName}
              render={(msg) => <div className="help-block">{msg}</div>}
            />
          </Fragment>
        )
      break
    case 'INTEGER':
      field =
        readOnly || productProperty.readonly ? (
          <MUITextField
            fullWidth
            margin="dense"
            label={translateProductProperty(productProperty)}
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: productProperty.unit ? productProperty.unit : null,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              decimalScale: 0,
              allowNegative: false,
            }}
            disabled={true}
            value={value}
          />
        ) : (
          <Field
            component={TextField}
            fullWidth
            margin="dense"
            label={translateProductProperty(productProperty)}
            variant="outlined"
            name={formikName}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: productProperty.unit ? productProperty.unit : null,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              decimalScale: 0,
              allowNegative: false,
            }}
            onChange={(e) => {
              setFieldValue(formikName, e.target.value)
              handleTriggers(
                productProperty,
                values,
                e.target.value,
                setFieldValue,
              )
            }}
          />
        )
      break
    case 'FLOAT':
      field =
        readOnly || productProperty.readonly ? (
          <MUITextField
            fullWidth
            margin="dense"
            label={translateProductProperty(productProperty)}
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: productProperty.unit ? productProperty.unit : null,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              decimalScale: 2,
              allowNegative: false,
            }}
            disabled={true}
            value={value}
          />
        ) : (
          <Field
            component={TextField}
            fullWidth
            margin="dense"
            label={translateProductProperty(productProperty)}
            variant="outlined"
            name={formikName}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: productProperty.unit ? productProperty.unit : null,
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              decimalScale: 2,
              allowNegative: false,
            }}
            onChange={(e) => {
              setFieldValue(formikName, e.target.value)
              handleTriggers(
                productProperty,
                values,
                e.target.value,
                setFieldValue,
              )
            }}
          />
        )
      break
    case 'TEXT':
      field =
        readOnly || productProperty.readonly ? (
          <MUITextField
            fullWidth
            margin="dense"
            label={translateProductProperty(productProperty)}
            variant="outlined"
            multiline
            rows="4"
            disabled={true}
            value={value}
          />
        ) : (
          <Field
            component={TextField}
            name={formikName}
            fullWidth
            margin="dense"
            label={translateProductProperty(productProperty)}
            variant="outlined"
            multiline
            rows="4"
            onChange={(e) => {
              setFieldValue(formikName, e.target.value)
              handleTriggers(
                productProperty,
                values,
                e.target.value,
                setFieldValue,
              )
            }}
          />
        )
      break
    case 'ARRAY':
      field = (
        <Grid container spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography>{translateProductProperty(productProperty)}</Typography>
          </Grid>
          {productProperty.productPropertyArray.productPropertyArrayElements.map(
            (arrayElement, index) => {
              return (
                <Grid item sm={arrayElement.productProperty.width} xs={12}>
                  {generateProductConfiguratorField(
                    arrayElement.productProperty,
                    `${formikName}[${index}].value`,
                    value[index].value,
                    setFieldValue,
                    readOnly,
                    showDifference,
                    diffObjectType,
                    differences,
                    undefined,
                    isMobile,
                  )}
                </Grid>
              )
            },
          )}
        </Grid>
      )
      break
    case '2D_ARRAY':
      field =
        readOnly && value.length === 0 ? null : (
          <Grid container spacing={1} alignItems="center">
            <Grid item sm={2} xs={12}>
              <DifferenceWrapper
                showDifference={isDifferent && !Array.isArray(differenceValue)}
                difference={isDifferent ? differenceValue : null}
                differenceDisplay={isDifferent ? differenceValue : null}
                diffObjectType={diffObjectType}
              >
                <Typography>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={8}>
                      {translateProductProperty(productProperty)}
                    </Grid>
                    <Grid item xs={4}>
                      {!readOnly && !productProperty.readonly && (
                        <InfoTooltip title={i18n.t('PRODUCT_CONFIGURATOR.ADD')}>
                          <IconButton
                            color="default"
                            onClick={() => {
                              let newValue =
                                productProperty.productPropertyArray.productPropertyArrayElements.map(
                                  (arrayElement) =>
                                    productProperty.defaultValue.some(
                                      (dValue) =>
                                        dValue.productPropertyId ===
                                        arrayElement.productProperty.id,
                                    )
                                      ? {
                                          value:
                                            productProperty.defaultValue[
                                              productProperty.defaultValue.findIndex(
                                                (dValue) =>
                                                  dValue.productPropertyId ===
                                                  arrayElement.productProperty
                                                    .id,
                                              )
                                            ].value,
                                          productPropertyId:
                                            arrayElement.productProperty.id,
                                        }
                                      : arrayElement.productProperty.type ===
                                        'BOOLEAN'
                                      ? {
                                          value: false,
                                          productPropertyId:
                                            arrayElement.productProperty.id,
                                        }
                                      : arrayElement.productProperty.type ===
                                        'ENUM'
                                      ? {
                                          value: '',
                                          productPropertyId:
                                            arrayElement.productProperty.id,
                                        }
                                      : null,
                                )
                              if (!value) {
                                value = []
                              }
                              value.push(newValue)
                              setFieldValue(formikName, value)
                            }}
                            size="large"
                          >
                            <AddCircle />
                          </IconButton>
                        </InfoTooltip>
                      )}
                    </Grid>
                  </Grid>
                </Typography>
              </DifferenceWrapper>
            </Grid>
            <Grid item xs={10}>
              {!value
                ? []
                : value.map((array2D, index2D) => {
                    return (
                      <Grid container spacing={1} alignItems="center">
                        <Grid item sm={0.5} xs={3}>
                          {!readOnly && !productProperty.readonly && (
                            <IconButton
                              color="default"
                              onClick={() => {
                                value.splice(index2D, 1)
                                setFieldValue(formikName, value)
                              }}
                              size="large"
                            >
                              <Delete />
                            </IconButton>
                          )}
                        </Grid>
                        <Grid item sm={11.5} xs={9}>
                          <Grid container spacing={1} alignItems="center">
                            {array2D
                              .sort((arrayElementA, arrayElementB) => {
                                let renderPositionA =
                                  productProperty.productPropertyArray.productPropertyArrayElements.find(
                                    (arrayElement) =>
                                      arrayElement.productPropertyId ===
                                      arrayElementA.productPropertyId,
                                  ).renderPosition

                                let renderPositionB =
                                  productProperty.productPropertyArray.productPropertyArrayElements.find(
                                    (arrayElement) =>
                                      arrayElement.productPropertyId ===
                                      arrayElementB.productPropertyId,
                                  ).renderPosition

                                return renderPositionA - renderPositionB
                              })
                              .map((array, index) => {
                                let arrayElement =
                                  productProperty.productPropertyArray
                                    .productPropertyArrayElements[
                                    productProperty.productPropertyArray.productPropertyArrayElements.findIndex(
                                      (element) =>
                                        element.productPropertyId ===
                                        array.productPropertyId,
                                    )
                                  ]

                                return (
                                  <Grid
                                    item
                                    sm={arrayElement.productProperty.width}
                                    xs={12}
                                  >
                                    {generateProductConfiguratorField(
                                      arrayElement.productProperty,
                                      `${formikName}[${index2D}][${index}].value`,
                                      value[index2D][index].value,
                                      setFieldValue,
                                      readOnly,
                                      showDifference,
                                      diffObjectType,
                                      differences,
                                      undefined,
                                      isMobile,
                                    )}
                                  </Grid>
                                )
                              })}
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })}
            </Grid>
          </Grid>
        )
      break
    default:
      break
  }
  return productProperty.type === 'ARRAY' ||
    productProperty.type === '2D_ARRAY' ? (
    field
  ) : (
    <DifferenceWrapper
      showDifference={isDifferent}
      difference={isDifferent ? differenceValue : null}
      differenceDisplay={
        isDifferent
          ? productProperty.type === 'BOOLEAN' &&
            (differenceValue === false || differenceValue === 'false')
            ? `${i18n.t('GENERAL.NOT')} ${generatePositionPropertyValue({
                value: differenceValue,
                productProperty: productProperty,
              })}`
            : generatePositionPropertyValue({
                value: differenceValue,
                productProperty: productProperty,
              })
          : null
      }
      diffObjectType={diffObjectType}
    >
      {field}
    </DifferenceWrapper>
  )
}

export function generateValidationSchemaOfProduct(product) {
  let schemas = []
  product.productProperties.forEach((productProperty) => {
    if (productProperty.readonly) {
      schemas.push(
        Yup.object().shape({
          value: Yup.mixed().nullable(),
        }),
      )
    } else {
      schemas.push(
        generateValidationSchemaOfProductProperty(
          productProperty,
          !productProperty.conditions && !productProperty.disableConditions
            ? productProperty.isRequired
            : false,
        ),
      )
    }
  })

  let schema = Yup.tuple(schemas).test({
    message: i18n.t('GENERAL.REQUIRED'),
    test: async function (value, context) {
      let positionPropertiesWithConditions = value.filter(
        (positionProperty) => positionProperty.productProperty.conditions,
      )
      for (
        let index = 0;
        index < positionPropertiesWithConditions.length;
        index++
      ) {
        const positionProperty = positionPropertiesWithConditions[index]

        if (
          isProductPropertyConditionFullfilled(
            positionProperty.productProperty.conditions,
            value,
          )
        ) {
          let subSchema = generateValidationSchemaOfProductProperty(
            positionProperty.productProperty,
            positionProperty.productProperty.isRequired,
          )

          try {
            await subSchema.validate(positionProperty, {
              abortEarly: false,
            })
          } catch (err) {
            let error = {
              ...err,
            }

            error.inner[0].path = product.productProperties.findIndex(
              (productProperty) =>
                productProperty.id === positionProperty.productProperty.id,
            )

            let errorValue = { value: error.errors[0] }
            error.inner[0].errors[0] = errorValue
            error.inner[0].message = errorValue

            return error
          }
        }
      }

      let positionPropertiesWithDisableConditions = value.filter(
        (positionProperty) =>
          positionProperty.productProperty.disableConditions,
      )
      for (
        let index = 0;
        index < positionPropertiesWithDisableConditions.length;
        index++
      ) {
        const positionProperty = positionPropertiesWithDisableConditions[index]

        if (
          isProductPropertyConditionFullfilled(
            positionProperty.productProperty.disableConditions,
            value,
          )
        ) {
          let subSchema = generateValidationSchemaOfProductProperty(
            positionProperty.productProperty,
            positionProperty.productProperty.isRequired,
          )

          try {
            await subSchema.validate(positionProperty, {
              abortEarly: false,
            })
          } catch (err) {
            let error = {
              ...err,
            }

            error.inner[0].path = product.productProperties.findIndex(
              (productProperty) =>
                productProperty.id === positionProperty.productProperty.id,
            )

            let errorValue = { value: error.errors[0] }
            error.inner[0].errors[0] = errorValue
            error.inner[0].message = errorValue

            return error
          }
        }
      }
      return true
    },
  })
  return schema
}

export function generateValidationSchemaOfProductProperty(
  productProperty,
  isRequired,
) {
  var schema
  let schemas = []
  switch (productProperty.type) {
    case 'BOOLEAN':
      if (isRequired) {
        schema = Yup.object().shape({
          value: Yup.boolean()
            .typeError(i18n.t('GENERAL.REQUIRED'))
            .required(i18n.t('GENERAL.REQUIRED')),
        })
      } else {
        schema = Yup.object().shape({
          value: Yup.boolean().nullable(),
        })
      }
      break
    case 'ENUM':
      if (isRequired) {
        schema = Yup.object().shape({
          value: Yup.string()
            .typeError(i18n.t('GENERAL.REQUIRED'))
            .required(i18n.t('GENERAL.REQUIRED')),
        })
      } else {
        schema = Yup.object().shape({
          value: Yup.string().nullable(),
        })
      }
      break
    case 'INTEGER':
      if (isRequired) {
        schema = Yup.object().shape({
          value: Yup.number()
            .typeError(i18n.t('GENERAL.REQUIRED'))
            .required(i18n.t('GENERAL.REQUIRED')),
        })
      } else {
        schema = Yup.object().shape({
          value: Yup.number().nullable(),
        })
      }
      break
    case 'FLOAT':
      if (isRequired) {
        schema = Yup.object().shape({
          value: (schema = Yup.number()
            .typeError(i18n.t('GENERAL.REQUIRED'))
            .required(i18n.t('GENERAL.REQUIRED'))),
        })
      } else {
        schema = Yup.object().shape({
          value: Yup.number().nullable(),
        })
      }
      break
    case 'TEXT':
      if (isRequired) {
        schema = Yup.object().shape({
          value: Yup.string()
            .typeError(i18n.t('GENERAL.REQUIRED'))
            .required(i18n.t('GENERAL.REQUIRED')),
        })
      } else {
        schema = Yup.object().shape({
          value: Yup.string().nullable(),
        })
      }
      break
    case 'ARRAY':
      productProperty.productPropertyArray.productPropertyArrayElements.forEach(
        (productPropertyArrayElement) => {
          schemas.push(
            generateValidationSchemaOfProductProperty(
              productPropertyArrayElement.productProperty,
              productPropertyArrayElement.productProperty.isRequired,
            ),
          )
        },
      )

      schema = Yup.object().shape({
        value: Yup.tuple(schemas),
      })
      break
    case '2D_ARRAY':
      productProperty.productPropertyArray.productPropertyArrayElements.forEach(
        (productPropertyArrayElement) => {
          schemas.push(
            generateValidationSchemaOfProductProperty(
              productPropertyArrayElement.productProperty,
              productPropertyArrayElement.productProperty.isRequired,
            ),
          )
        },
      )

      schema = Yup.object().shape({
        value: Yup.array(Yup.tuple(schemas)),
      })

      break
    default:
      break
  }
  return schema
}

export function isProductPropertyConditionFullfilled(conditions, values) {
  if (!conditions) {
    return true
  }

  let operator = conditions[0]

  if (operator === 'AND') {
    return (
      isProductPropertyConditionFullfilled(conditions[1], values) &&
      isProductPropertyConditionFullfilled(conditions[2], values)
    )
  } else if (operator === 'OR') {
    return (
      isProductPropertyConditionFullfilled(conditions[1], values) ||
      isProductPropertyConditionFullfilled(conditions[2], values)
    )
  } else if (operator === 'EQ') {
    return (
      values[
        values.findIndex((value) => value.productProperty.id === conditions[1])
      ].value === conditions[2]
    )
  } else if (operator === 'NE') {
    return (
      values[
        values.findIndex((value) => value.productProperty.id === conditions[1])
      ].value !== conditions[2]
    )
  }
}

export function isProductPropertyTriggerConditionFullfilled(
  conditions,
  value,
  productPropertyId,
) {
  if (!conditions) {
    return true
  }

  let operator = conditions[0]

  if (operator === 'AND') {
    return (
      isProductPropertyTriggerConditionFullfilled(conditions[1], value) &&
      isProductPropertyTriggerConditionFullfilled(conditions[2], value)
    )
  } else if (operator === 'OR') {
    return (
      isProductPropertyTriggerConditionFullfilled(conditions[1], value) ||
      isProductPropertyTriggerConditionFullfilled(conditions[2], value)
    )
  } else if (operator === 'EQ') {
    return value === conditions[1]
  } else if (operator === 'NE') {
    return value !== conditions[1]
  }
}

function handleTriggers(productProperty, values, changedValue, setFieldValue) {
  if (productProperty.triggers) {
    productProperty.triggers
      .filter((trigger) =>
        isProductPropertyTriggerConditionFullfilled(
          trigger,
          changedValue,
          productProperty.id,
        ),
      )
      .forEach((trigger) => {
        let target = values.find(
          (value) => value.productProperty.id === trigger[3],
        )

        let targetPropertyIndex = values.findIndex(
          (value) => value.productProperty.id === trigger[3],
        )

        setFieldValue(`[${targetPropertyIndex}]`, {
          ...target,
          value: trigger[4],
        })
      })
  }
}
