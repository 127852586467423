import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'
import { authorize } from '../_helpers/authorization.js'

export const loginService = {
  login,
}

function login() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details in local storage
      if (
        !authorize('administrator', user) ||
        !JSON.parse(localStorage.getItem('user'))
      ) {
        localStorage.setItem('user', JSON.stringify(user))
        const params = new URLSearchParams(window.location.search)
        let url = params.get('HOOK_URL')
        if (url) {
          sessionStorage.setItem('HOOK_URL', url)
        }

        return
      }

      return user
    })
}

export * from './loginService'
