import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel } from 'formik-mui'
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { deliveryNotesService } from '../../../../_services/deliveryNotesService'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { Loading } from '../../../Loading'
import PositionCard from '../../../PositionCard'

const GenerateDeliveryNoteDialog = (props) => {
  const { t, order, show, hide, forceUpdate } = props

  const [isLoading, setIsLoading] = React.useState(false)

  useEffect(() => {}, [])

  const valSchema = Yup.object().shape({
    positions: Yup.array().min(1, t('GENERAL.MIN_1')),
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        positions: order.positions.map((opos) => {
          return {
            ...opos,
            checked: true,
          }
        }),
      }}
      validationSchema={valSchema}
      //validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting = true
        setIsLoading(true)
        var payLoad = {
          orderId: order.id,
          positions: values.positions
            .filter((pos) => {
              return pos.checked
            })
            .map((deliveryPosition) => {
              return {
                orderPositionId: deliveryPosition.id,
              }
            }),
        }
        deliveryNotesService
          .createDeliveryNote(payLoad)
          .then((data) => {
            setSubmitting = false
            forceUpdate()
            hide()
          })
          .catch(function (error) {
            setSubmitting = false
            console.log(error)
          })
          .finally(() => {
            setSubmitting = false
            setIsLoading(false)
          })
      }}
    >
      {({ values, setFieldValue, isSubmitting, isValid, submitForm }) => (
        <Dialog
          onClose={() => hide()}
          fullWidth={true}
          maxWidth="md"
          open={show}
        >
          <CustomDialogTitle
            title={t('ORDERS.DELIVERY_NOTES.GENERATE_DELIVERY_NOTE')}
            onClose={() => hide()}
          />
          <DialogContent>
            {(isLoading && <Loading variant={'centered'} />) || (
              <Form>
                <Grid container spacing={1}>
                  {order.positions.map((opos) => (
                    <Grid item xs={6}>
                      <PositionCard
                        data={opos}
                        readOnly={true}
                        showProductDetails={true}
                        showImage={true}
                        imageSize={'small'}
                      >
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          checked={
                            values.positions[
                              values.positions.findIndex(
                                (position) => position.id === opos.id,
                              )
                            ].checked
                          }
                          name={
                            'positions[' +
                            values.positions.findIndex(
                              (position) => position.id === opos.id,
                            ) +
                            '].checked'
                          }
                          margin="dense"
                          Label={{
                            label: t(
                              'ORDERS.DELIVERY_NOTES.PRINT_ON_DELIVERY_NOTE',
                            ),
                          }}
                        />
                      </PositionCard>
                    </Grid>
                  ))}
                </Grid>
              </Form>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => hide()} variant="outlined" color="secondary">
              {t('ORDERS.DELIVERY_NOTES.CANCEL')}
            </Button>
            <Button
              onClick={() => submitForm()}
              disabled={
                values.positions.filter((pos) => {
                  return pos.checked
                }).length === 0 || isSubmitting
              }
              type="submit"
              variant="contained"
              color="secondary"
            >
              {t('ORDERS.DELIVERY_NOTES.GENERATE')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

export default withTranslation()(GenerateDeliveryNoteDialog)
