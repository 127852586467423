import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header.js'

export const userPartnerInvoiceLocationsService = {
  updateUserPartnerInvoiceLocations,
}

function updateUserPartnerInvoiceLocations(userId, partnerInvoiceLocationIds) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      partnerInvoiceLocationIds: partnerInvoiceLocationIds,
    }),
  }

  return fetch(
    `${config.apiUrlBase}/users/${userId}/user-partner-invoice-locations`,
    requestOptions,
  ).then(handleResponse)
}

export * from './userPartnerInvoiceLocationsService.js'
