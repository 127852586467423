import { Grid } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { authorize } from '../../../../_helpers/authorization'
import { FieldErrorWrapper } from '../../../FieldErrorWrapper'
import { Section } from '../../../Section'
const OfferOtherDetails = (props) => {
  const { t, data } = props

  return (
    <Section header={t('OFFER.OTHER_DETAILS')}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FieldErrorWrapper
            slotProps={{
              textField: {
                fullWidth: true,
                label: t('REQUEST.VALID_UNTIL'),
                margin: 'dense',
              },
            }}
            name="generalInformation.otherDetails.validUntil"
            component={DatePicker}
            inputFormat="DD/MM/YYYY"
            minDate={moment()}
          />
        </Grid>
        {data.request.type === 'TENDER' ? (
          <Grid container spacing={1} style={{ marginTop: '5px' }}>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={6}>
                <strong>{t('REQUEST.COLLABORATION_START') + ':'}</strong>
              </Grid>
              <Grid item xs={12} sm={6}>
                {data.request.generalInformation.otherDetails.start === '' ||
                data.request.generalInformation.otherDetails.start === null ||
                data.request.generalInformation.otherDetails.start === undefined
                  ? null
                  : moment(data.request.generalInformation.otherDetails.start)
                      .local()
                      .format('DD.MM.YYYY')}
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={12} sm={6}>
                <strong>{t('REQUEST.COLLABORATION_END') + ':'}</strong>
              </Grid>
              <Grid item xs={12} sm={6}>
                {data.request.generalInformation.otherDetails.end === '' ||
                data.request.generalInformation.otherDetails.end === null ||
                data.request.generalInformation.otherDetails.end === undefined
                  ? null
                  : moment(data.request.generalInformation.otherDetails.end)
                      .local()
                      .format('DD.MM.YYYY')}
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {authorize('administrator') && (
          <Grid item xs={12}>
            <Field
              name="generalInformation.otherDetails.internalNote"
              fullWidth
              component={TextField}
              label={t('OFFER.INTERNAL_NOTE')}
              variant="outlined"
              multiline
              rows="4"
            />
          </Grid>
        )}
      </Grid>
    </Section>
  )
}

export default withTranslation()(OfferOtherDetails)
