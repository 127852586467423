import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header.js'

export const validAddressService = {
  validateAddress,
}

function validateAddress(reqBody) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(reqBody),
  }
  return fetch(`${config.apiUrlBase}/valid-addresses/`, requestOptions).then(
    handleResponse,
  )
}

export * from './validAddressService.js'
