import { CheckCircleOutline, HighlightOff } from '@mui/icons-material'
import { Grid, IconButton, Paper, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { communityInvitationsService } from '../../../../../_services/communityInvitationsService'

const CommunityInvitations = ({ t, partnerId, forceUpdate }) => {
  const [communityInvitations, setCommunityInvitations] = useState([])

  const [isLoading, setIsLoading] = React.useState(false)

  useEffect(() => {
    setIsLoading(true)
    communityInvitationsService
      .getCommunityInvitationsOfPartner(partnerId)
      .then((communityInvitations) => {
        setCommunityInvitations(communityInvitations)
        setIsLoading(false)
      })
  }, [partnerId, forceUpdate])

  const handleAcceptInvitation = async (invitationId) => {
    setIsLoading(true)
    await communityInvitationsService
      .acceptCommunityInvitation(invitationId)
      .then((communityInvitations) => {
        setCommunityInvitations(communityInvitations)
        forceUpdate(forceUpdate + 1)
        setIsLoading(false)
      })
  }

  const handleRejectInvitation = async (invitationId) => {
    setIsLoading(true)
    await communityInvitationsService
      .deleteCommunityInvitation(invitationId)
      .then((communityInvitations) => {
        setCommunityInvitations(communityInvitations)
        forceUpdate(forceUpdate + 1)
        setIsLoading(false)
      })
  }

  return (
    <Fragment>
      {communityInvitations.length > 0 ? (
        <Grid container spacing={1}>
          {communityInvitations.map((invitation) => (
            <Grid item xs={12} sm={6} key={invitation.id}>
              <Paper
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  minHeight: '40px',
                  padding: '10px',
                }}
              >
                <div>{invitation.community.ownerPartner.name}</div>
                <div style={{ display: 'flex' }}>
                  <IconButton
                    onClick={() => handleAcceptInvitation(invitation.id)}
                    color="secondary"
                    aria-label={t('COMMUNITY.ACCEPT_INVITATION')}
                  >
                    <CheckCircleOutline />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRejectInvitation(invitation.id)}
                    color="secondary"
                    aria-label={t('COMMUNITY.REJECT_INVITATION')}
                  >
                    <HighlightOff />
                  </IconButton>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid item xs={12} sm={6}>
          <Paper
            style={{
              padding: '8px',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <div
              style={{
                flexGrow: 1,
                textAlign: 'center',
                margin: '0 8px',
              }}
            >
              <Typography variant="body1">
                {t('COMMUNITY.NO_INVITATIONS')}
              </Typography>
            </div>
          </Paper>
        </Grid>
      )}
    </Fragment>
  )
}

export default withTranslation()(CommunityInvitations)
