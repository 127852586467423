import BarChartIcon from '@mui/icons-material/BarChart'
import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { formatToPrice } from '../../../../../../_helpers/number-formatting'
import { _applicationService } from '../../../../../../_services/_applicationService'
import { Loading } from '../../../../../Loading'

function OrderPieChart(props) {
  const { t, partner } = props
  const [activeTab, setActiveTab] = useState(
    partner.type === 'manufacturer' ? 1 : 0,
  )
  var userObject = JSON.parse(localStorage.getItem('user'))
  const [revenueData, setRevenueData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    _applicationService
      .getDashboardRevenueOfPartner(
        userObject.partner.id,
        activeTab === 0 ? 'PURCHASE' : 'SELL',
      )
      .then((data) => {
        const revenueDataFromBackend = [
          {
            name: t('PIECHART.REALIZED'),
            value: data.totalRevenueRealized,
            color: '#7EC07E',
          },
          {
            name: t('PIECHART.OPEN'),
            value: data.totalRevenueOpen,
            color: '#DDCEB5',
          },
        ]
        setRevenueData(revenueDataFromBackend)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [userObject.partner.id, activeTab])

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const renderLegend = (props) => {
    const { payload } = props
    return (
      <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{ display: 'flex', alignItems: 'center', marginBottom: 4 }}
          >
            <div
              style={{
                width: 20,
                height: 20,
                backgroundColor: entry.color,
                marginRight: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            ></div>
            <span style={{ color: '#000', lineHeight: '20px' }}>
              {`${entry.value} (${formatToPrice(entry.payload.value)})`}
            </span>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <Paper
      style={{ overflow: 'hidden', borderRadius: '10px', height: '40vh' }}
      elevation={1}
    >
      {(isLoading && <Loading variant={'centered'} />) || (
        <Fragment>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              style={{ backgroundColor: '#B5DBDE', width: '100%' }}
            >
              <Grid item>
                <BarChartIcon
                  color="secondary"
                  style={{
                    fontSize: '40px',
                    padding: '10px 0',
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    margin: 0,
                    color: '#333333',
                    padding: '10px 0',
                  }}
                >
                  {t('DASHBOARD.CHART')}
                </Typography>
              </Grid>
            </Grid>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              centered
              style={{
                margin: 0,
                color: '#333333',
                width: '100%',
              }}
            >
              <Tab label={t('PIECHART.REVENUE_PURCHASE')} />
              <Tab label={t('PIECHART.REVENUE_SELL')} />
            </Tabs>

            {revenueData &&
            revenueData.length > 0 &&
            (revenueData[0].value > 0 || revenueData[1].value > 0) ? (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Legend content={renderLegend} />
                  <Pie
                    data={revenueData}
                    cx={'50%'}
                    cy={'50%'}
                    labelLine={false}
                    outerRadius={'95%'}
                    dataKey="value"
                  >
                    {revenueData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            ) : (
              <Box
                sx={{
                  width: 'auto',
                  height: '50%',
                  aspectRatio: '1', // Hält das Seitenverhältnis 1:1, damit es rund bleibt
                  margin: '0 auto', // Zentriert das Element horizontal
                }}
              >
                <Skeleton
                  variant="circular"
                  width="100%"
                  height="100%"
                  animation="wave" // optional, um einen Wellen-Effekt hinzuzufügen
                />
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                padding: 1,
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                size={'large'}
                component={Link}
                to="/charts"
                endIcon={<BarChartIcon />}
              >
                {t('DASHBOARD.GO_TO_REPORTING')}
              </Button>
            </Box>
          </Box>
        </Fragment>
      )}
    </Paper>
  )
}

export default withTranslation()(OrderPieChart)
