import { Dialog, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  categorizedProductLookup,
  countryLookup,
  productLookup,
} from '../../_constants/lookupConstants'
import { jobOffersService } from '../../_services/jobOffersService'
import { requestsService } from '../../_services/requestsService'
import CustomDialogTitle from '../CustomDialogTitle'
import { PositionsList } from '../PositionsList'
import ResourceList from '../ResourceList/_components/ResourceList'
import RequestPositionComparison from './_components/RequestPositionComparison'

const RequestPositionsOfferComparison = (props) => {
  const { t, hide, requestId } = props

  const [forceUpdateCount, forceUpdate] = useState(0)
  const [jobOffer, setJobOffer] = useState(null)

  useEffect(() => {
    if (requestId) {
      jobOffersService
        .getJobOfferOfRequest(requestId)
        .then((data) => setJobOffer(data))
    }
  }, [requestId])

  return (
    <Dialog fullWidth={true} maxWidth={'xl'} open={true}>
      <CustomDialogTitle
        title={
          <>
            {t('REQUEST_POSITION_COMPARISON.REQUEST_COMPARATION')}
            <span style={{ color: 'gray' }}> ({requestId})</span>
          </>
        }
        onClose={() => hide()}
      />
      <ResourceList
        autoSelectFirstElement={true}
        fetchMethod={(filterValues, pagination) =>
          requestsService.getRequestPositionsOfRequest(
            requestId,
            filterValues,
            pagination,
          )
        }
        forceUpdateCount={forceUpdateCount}
        filterId={'REQUEST_POSITION_COMPARISON'}
        hint={t('REQUEST_POSITION_COMPARISON.VIEW_DESCRPTION')}
        selectionText={t(
          'REQUEST_POSITION_COMPARISON.SELECT_A_REQUEST_POSITION',
        )}
        sorting={[
          {
            key: 'localId',
            default: true,
            label: t('REQUESTS.REQUEST_POSITION_ID'),
          },
        ]}
        filters={[
          {
            key: 'productId.eq',
            variant: 'categorizedCheckboxList',
            values: categorizedProductLookup.map((category) => {
              return {
                label: category.name,
                items: category.products.map((product) => product.id),
              }
            }),
            lookup: productLookup,
            label: t('MARKETPLACE.PRODUCT'),
            shortcut: true,
          },
        ]}
        listHeaderRenderFunction={(requestPosition) => {
          let location = requestPosition.location
          return requestPosition.request.type === 'TENDER'
            ? `${location.zipcode} ${location.place} ${
                countryLookup[location.countryId]
              } ${location.company}`
            : null
        }}
        headerActionRenderFunction={(requestPosition) => null}
        contentRenderFunction={(requestPosition, index) => (
          <Fragment>
            <Typography>
              {`${t('REQUEST_POSITION_COMPARISON.REQUEST_POSITION')} ${
                index + 1
              }`}
            </Typography>
            <PositionsList positions={[requestPosition]} />
          </Fragment>
        )}
        resourceRenderFunction={(requestPosition) => (
          <RequestPositionComparison
            requestPosition={requestPosition}
            forceUpdate={() => {
              forceUpdate(forceUpdateCount + 1)
            }}
            jobOffer={jobOffer}
          />
        )}
        actionsRenderFunction={(requestPosition) => {
          return []
        }}
        dataIdentifier={'id'}
      />
    </Dialog>
  )
}

export default withTranslation()(RequestPositionsOfferComparison)
