import { Close as CloseIcon, DoubleArrow, Sort } from '@mui/icons-material'
import {
  Alert,
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  TablePagination,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

const PREFIX = 'ResourceListLegacy'

const classes = {
  selectedCard: `${PREFIX}-selectedCard`,
  customAlert: `${PREFIX}-customAlert`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.selectedCard}`]: {
    backgroundColor: '#e0e0e0',
  },

  [`& .${classes.customAlert}`]: {
    backgroundColor: '#f8f9fa',
    '& .MuiAlert-icon': {
      color: '#6C6C6C',
      alignItems: 'center',
    },
  },
}))

const ResourceListLegacy = (props) => {
  const {
    t,
    data,
    listHeaderRenderFunction,
    headerActionRenderFunction,
    contentRenderFunction,
    resourceHeaderRenderFunction,
    resourceRenderFunction,
    actionsRenderFunction,
    toolbar,
    toolbarSecondary,
    dataIdentifier,
    filter,
    isLoading,
    alert,
    selectionText,
    hint,
    sorting,
  } = props

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [selectedItem, setSelectedItem] = useState(null)
  const [actions, setActions] = useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedSort, setSelectedSort] = useState(
    sorting ? sorting.find((sortObj) => sortObj.default).key : null,
  )
  const [showSort, setShowSort] = useState(false)
  //Force update on resize: https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [rowsPerPage, page])
  const gridContainerRef = useRef(null)
  const scrollableListRef = useRef(null)

  const theme = useTheme()

  const isExtraLargeOrHigher = useMediaQuery(theme.breakpoints.up('xl'))

  useEffect(() => {
    if (selectedItem) {
      let item = data.find(
        (item) => item[dataIdentifier] === selectedItem[dataIdentifier],
      )
      setActions(actionsRenderFunction(item))
    }
  }, [selectedItem, data, actionsRenderFunction])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    scrollableListRef.current.scrollTo(0, 0)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  if (selectedItem) {
    var doesSelectedItemExists = data.find(
      (item) => item[dataIdentifier] === selectedItem[dataIdentifier],
    )
    if (!doesSelectedItemExists) {
      setSelectedItem(null)
      return null
    }
  }

  let containerHeight = 0
  if (gridContainerRef.current) {
    containerHeight =
      window.innerHeight -
      gridContainerRef.current.offsetTop -
      73 -
      (alert.hasOwnProperty('type') ? 44 : 0)
  }

  return selectedItem && !doesSelectedItemExists ? null : (
    <StyledGrid container ref={gridContainerRef}>
      <Grid item xs={isExtraLargeOrHigher ? 4 : 12}>
        <Paper
          elevation={8}
          sx={{
            marginRight: 1,
            height: containerHeight,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              /* for Firefox */
              minHeight: 0,
            }}
          >
            {toolbarSecondary && (
              <Box margin={2} marginBottom={0.5}>
                {toolbarSecondary}
              </Box>
            )}
            <Box margin={2} marginBottom={0.5}>
              {sorting && (
                <Button
                  style={{
                    verticalAlign: 'middle',
                    marginRight: theme.spacing(2),
                  }}
                  size={'small'}
                  variant={'text'}
                  color={'secondary'}
                  startIcon={<Sort />}
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget)
                    setShowSort(true)
                  }}
                >
                  {t('RESOURCE_LIST.SORT_BY')}
                </Button>
              )}
              <span style={{ verticalAlign: 'middle' }}>{toolbar}</span>
            </Box>
            {sorting && (
              <Popover
                onClose={() => {
                  setShowSort(false)
                }}
                anchorEl={anchorEl}
                open={showSort}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <RadioGroup
                  sx={{ margin: theme.spacing(1) }}
                  value={selectedSort}
                  onChange={(e) => {
                    setSelectedSort(e.target.value)
                  }}
                >
                  {sorting.map((sortObj) => (
                    <FormControlLabel
                      value={sortObj.key}
                      control={<Radio />}
                      label={sortObj.label}
                    />
                  ))}
                </RadioGroup>
              </Popover>
            )}

            <Divider />
            <List
              ref={scrollableListRef}
              dense={true}
              sx={{
                padding: 0,
                flexGrow: 1,
                overflow: 'auto',
                /* for Firefox */
                minHeight: 0,
              }}
            >
              {(sorting &&
              sorting.some((sortObj) => sortObj.key === selectedSort)
                ? (filter ? filter(data) : data).sort(
                    sorting.find((sortObj) => sortObj.key === selectedSort)
                      .sortFunc,
                  )
                : data
              )
                .slice(page * rowsPerPage, rowsPerPage + page * rowsPerPage)
                .map((item, index) => {
                  return (
                    <Card
                      variant="outlined"
                      className={
                        selectedItem &&
                        selectedItem[dataIdentifier] === item[dataIdentifier]
                          ? classes.selectedCard
                          : null
                      }
                      sx={{ margin: '5px', marginRight: '10px' }}
                    >
                      <CardActionArea
                        onClick={() => setSelectedItem(item)}
                        focusRipple={true}
                      >
                        <CardContent sx={{ paddingBottom: 0 }}>
                          <Grid
                            container
                            spacing={1}
                            sx={{
                              flexDirection: { xs: 'column', sm: 'row' },
                              alignItems: 'flex-start',
                              flexWrap: 'wrap',
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              sx={{ order: { xs: 2, sm: 1 } }}
                            >
                              <Typography variant={'caption'}>
                                {listHeaderRenderFunction
                                  ? listHeaderRenderFunction(item)
                                  : null}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              sx={{
                                order: { xs: 1, sm: 2 },
                                marginLeft: { xs: 0, sm: 'auto' },
                              }}
                            >
                              <Grid container direction="row-reverse">
                                <Grid item>
                                  <Box>
                                    {headerActionRenderFunction
                                      ? headerActionRenderFunction(item)
                                      : null}
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardContent sx={{ paddingTop: 1, paddingBottom: 1 }}>
                          {contentRenderFunction
                            ? contentRenderFunction(item, index)
                            : null}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  )
                })}
            </List>
            <AppBar
              position={'relative'}
              color={'default'}
              sx={{ top: 'auto', bottom: 0 }}
            >
              <Toolbar>
                <Box sx={{ flexGrow: 1 }} />
                <TablePagination
                  rowsPerPageOptions={[25]}
                  component="div"
                  showFirstButton
                  showLastButton
                  count={filter ? filter(data).length : data.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Toolbar>
            </AppBar>
          </div>
        </Paper>
      </Grid>
      {selectedItem && !isLoading && isExtraLargeOrHigher && (
        <Grid item xs={8}>
          <Paper
            elevation={8}
            sx={{
              height: containerHeight,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                /* for Firefox */
                minHeight: 0,
              }}
            >
              {resourceHeaderRenderFunction && !isExtraLargeOrHigher && (
                <Box padding={2}>
                  <Typography paddingLeft={1} variant={'h6'}>
                    {resourceHeaderRenderFunction(
                      data.find(
                        (item) =>
                          item[dataIdentifier] === selectedItem[dataIdentifier],
                      ),
                    )}
                  </Typography>
                  <Divider />
                </Box>
              )}
              <Box
                sx={{
                  flexGrow: 1,
                  overflow: 'auto',
                  /* for Firefox */
                  minHeight: 0,
                }}
              >
                {resourceRenderFunction
                  ? resourceRenderFunction(
                      data.find(
                        (item) =>
                          item[dataIdentifier] === selectedItem[dataIdentifier],
                      ),
                    )
                  : null}
              </Box>
              <AppBar position={'relative'} color={'default'}>
                <Toolbar>
                  <Box sx={{ flexGrow: 1 }} />
                  {selectedItem &&
                  actions &&
                  actions.filter((action) => !action.hidden).length > 1 ? (
                    <ButtonGroup>
                      {actions
                        .filter((action) => !action.primary && !action.hidden)
                        .map((action) => (
                          <Tooltip title={action.tooltip ? action.tooltip : ''}>
                            <div>
                              <Button
                                component={'div'}
                                startIcon={action.icon}
                                size={'small'}
                                variant={'outlined'}
                                color={'secondary'}
                                disabled={action.disabled}
                                onClick={() => {
                                  action.onClick()
                                }}
                              >
                                {action.name}
                              </Button>
                            </div>
                          </Tooltip>
                        ))}
                      {actions
                        .filter((action) => action.primary && !action.hidden)
                        .map((action) => (
                          <Tooltip title={action.tooltip ? action.tooltip : ''}>
                            <div>
                              <Button
                                component={'div'}
                                startIcon={action.icon}
                                size={'small'}
                                variant={'contained'}
                                color={'secondary'}
                                disabled={action.disabled}
                                onClick={() => {
                                  action.onClick()
                                }}
                              >
                                {action.name}
                              </Button>
                            </div>
                          </Tooltip>
                        ))}
                    </ButtonGroup>
                  ) : selectedItem &&
                    actions &&
                    actions.filter((action) => !action.hidden).length === 1 ? (
                    actions
                      .filter((action) => !action.hidden)
                      .map((action) => (
                        <Tooltip title={action.tooltip ? action.tooltip : ''}>
                          <div>
                            {!action.hidden && (
                              <Button
                                startIcon={action.icon}
                                size={'small'}
                                variant={'contained'}
                                color={'secondary'}
                                disabled={action.disabled}
                                onClick={() => {
                                  action.onClick()
                                }}
                              >
                                {action.name}
                              </Button>
                            )}
                          </div>
                        </Tooltip>
                      ))
                  ) : null}
                </Toolbar>
              </AppBar>
            </div>
          </Paper>
        </Grid>
      )}
      {!selectedItem && isExtraLargeOrHigher && (
        <Grid item xs={8}>
          <Paper
            elevation={8}
            sx={{
              height: containerHeight,
            }}
            component={Stack}
            direction="column"
            justifyContent="center"
          >
            <Typography variant={'h4'} align={'center'}>
              {selectionText
                ? selectionText
                : t('RESOURCE_LIST.PLEASE_SELECT_AN_ITEM')}
            </Typography>
            <DoubleArrow
              sx={{
                fontSize: '10rem',
                alignSelf: 'center',
                transform: 'scaleX(-1)',
              }}
            />
            {hint && (
              <Alert
                severity="info"
                align={'center'}
                className={classes.customAlert}
                style={{ maxWidth: '60vh', alignSelf: 'center' }}
              >
                {hint}
              </Alert>
            )}
          </Paper>
        </Grid>
      )}
      {selectedItem && !isLoading && !isExtraLargeOrHigher && (
        <Dialog
          fullWidth={true}
          maxWidth={'xl'}
          open={true}
          onClose={() => {
            setSelectedItem(null)
            setActions(null)
          }}
        >
          <DialogTitle>
            {resourceHeaderRenderFunction
              ? resourceHeaderRenderFunction(
                  data.find(
                    (item) =>
                      item[dataIdentifier] === selectedItem[dataIdentifier],
                  ),
                )
              : null}
            <IconButton
              aria-label="close"
              onClick={() => {
                setSelectedItem(null)
                setActions(null)
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box>
              {resourceRenderFunction
                ? resourceRenderFunction(
                    data.find(
                      (item) =>
                        item[dataIdentifier] === selectedItem[dataIdentifier],
                    ),
                  )
                : null}
            </Box>
          </DialogContent>
          <DialogActions>
            {selectedItem &&
            actions &&
            actions.filter((action) => !action.hidden).length > 1 ? (
              <Fragment>
                {actions
                  .filter((action) => !action.primary)
                  .map((action) => (
                    <Tooltip title={action.tooltip ? action.tooltip : ''}>
                      <div>
                        {!action.hidden && (
                          <Button
                            startIcon={action.icon}
                            size={'small'}
                            variant={'outlined'}
                            color={'secondary'}
                            disabled={action.disabled}
                            onClick={() => {
                              action.onClick()
                            }}
                          >
                            {action.name}
                          </Button>
                        )}
                      </div>
                    </Tooltip>
                  ))}
                <Divider />
                {actions
                  .filter((action) => action.primary)
                  .map((action) => (
                    <Tooltip title={action.tooltip ? action.tooltip : ''}>
                      <div>
                        {!action.hidden && (
                          <Button
                            startIcon={action.icon}
                            size={'small'}
                            variant={'contained'}
                            color={'secondary'}
                            disabled={action.disabled}
                            onClick={() => {
                              action.onClick()
                            }}
                          >
                            {action.name}
                          </Button>
                        )}
                      </div>
                    </Tooltip>
                  ))}
              </Fragment>
            ) : selectedItem &&
              actions &&
              actions.filter((action) => !action.hidden).length === 1 ? (
              actions
                .filter((action) => !action.hidden)
                .map((action) => (
                  <Tooltip title={action.tooltip ? action.tooltip : ''}>
                    <div>
                      {!action.hidden && (
                        <Button
                          startIcon={action.icon}
                          size={'small'}
                          variant={'contained'}
                          color={'secondary'}
                          disabled={action.disabled}
                          onClick={() => {
                            action.onClick()
                          }}
                        >
                          {action.name}
                        </Button>
                      )}
                    </div>
                  </Tooltip>
                ))
            ) : null}
          </DialogActions>
        </Dialog>
      )}
    </StyledGrid>
  )
}

ResourceListLegacy.propTypes = {
  dataIdentifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  data: PropTypes.array.isRequired,
  filter: PropTypes.func,
}

function mapStateToProps(state) {
  const { alert } = state
  return {
    alert,
  }
}

export default withTranslation()(connect(mapStateToProps)(ResourceListLegacy))
