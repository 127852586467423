import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const automaticOfferPositionsService = {
  getAutomaticOfferPositionsOfPartnerLocation,
  createAutomaticOfferPositionOfPartnerLocation,
  updateAutomaticOfferPosition,
  getAutomaticOfferPosition,
}

function getAutomaticOfferPosition(automaticOfferPositionId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/automatic-offer-positions/${automaticOfferPositionId}`,
    requestOptions,
  ).then(handleResponse)
}

function getAutomaticOfferPositionsOfPartnerLocation(partnerLocationId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/partner-locations/${partnerLocationId}/automatic-offer-positions`,
    requestOptions,
  ).then(handleResponse)
}

function createAutomaticOfferPositionOfPartnerLocation(
  payload,
  partnerLocationId,
) {
  var requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }

  return fetch(
    `${config.apiUrlBase}/partner-locations/${partnerLocationId}/automatic-offer-positions`,
    requestOptions,
  ).then(handleResponse)
}

function updateAutomaticOfferPosition(payload, automaticOfferPositionId) {
  var requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }

  return fetch(
    `${config.apiUrlBase}/automatic-offer-positions/${automaticOfferPositionId}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './automaticOfferPositionsService'
