import { SaveAltOutlined } from '@mui/icons-material'
import { Dialog, DialogContent } from '@mui/material'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  categorizedProductLookup,
  countryLookup,
  deliveryTypeLookup,
  productLookup,
  productStateLookup,
  requestTypeLookup,
} from '../../../../../../../../_constants/lookupConstants'
import { generalService } from '../../../../../../../../_services/generalService'
import { ordersService } from '../../../../../../../../_services/ordersService'
import CustomDialogTitle from '../../../../../../../CustomDialogTitle'
import { Loading } from '../../../../../../../Loading'
import { PositionsList } from '../../../../../../../PositionsList'
import ResourceList from '../../../../../../../ResourceList/_components/ResourceList'
import Order from '../../../../../Order'

const SelectOrderDialog = (props) => {
  const { t, partnerId, submit, close, open } = props

  const [isLoading, setIsLoading] = useState(false)

  var userObject = JSON.parse(localStorage.getItem('user'))

  return (
    <Fragment>
      <Dialog
        onClose={() => {
          close()
        }}
        fullWidth={true}
        maxWidth="xl"
        open={open}
      >
        <CustomDialogTitle
          title={t('ORDER.ORDERS')}
          onClose={() => {
            close()
          }}
        />
        {isLoading ? (
          <DialogContent>
            <Loading variant={'centered'} />
          </DialogContent>
        ) : (
          <DialogContent>
            <ResourceList
              fetchMethod={(filterValues, pagination) =>
                ordersService.getOrdersOfPartner(
                  parseInt(partnerId),
                  'CUSTOMER',
                  filterValues,
                  pagination,
                  userObject.user.partnerLocationIds,
                )
              }
              filterId={'SELECT_ORDER'}
              filters={[
                {
                  key: 'id.startsWith',
                  label: t('ORDERS.ORDER_ID'),
                  variant: 'textfield',
                },
                {
                  key: 'deliveryNotes.id.startsWith',
                  label: t('ORDERS.DELIVERY_NOTE_ID'),
                  variant: 'textfield',
                },
                {
                  key: 'orderNumber.like',
                  label: t('ORDER.CUSTOMER_ORDER_NUMBER'),
                  variant: 'textfield',
                },
                {
                  key: 'invoices.invoiceNumber.like',
                  label: t('ORDERS.INVOICE_NUMBER'),
                  variant: 'textfield',
                },
                {
                  key: 'requestId.startsWith',
                  label: t('ORDERS.REQUEST_ID'),
                  variant: 'textfield',
                },
                {
                  key: 'positions.productId.eq',
                  variant: 'categorizedCheckboxList',
                  values: categorizedProductLookup.map((category) => {
                    return {
                      label: category.name,
                      items: category.products.map((product) => product.id),
                    }
                  }),
                  lookup: productLookup,
                  label: t('ORDERS.PRODUCT'),
                },
                {
                  key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
                  variant: 'checkboxList',
                  values: Object.getOwnPropertyNames(productStateLookup),
                  lookup: productStateLookup,
                  label: t('ORDERS.PRODUCT_STATE'),
                },
                {
                  key: 'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value',
                  variant: 'checkboxList',
                  values: Object.getOwnPropertyNames(deliveryTypeLookup),
                  lookup: deliveryTypeLookup,
                  label: t('ORDERS.DELIVERY_TYPE'),
                },
                {
                  key: 'countryId.eq',
                  variant: 'checkboxList',
                  values: Object.getOwnPropertyNames(countryLookup),
                  lookup: countryLookup,
                  label: t('ORDERS.COUNTRY'),
                },
                {
                  key: 'zipcode.startsWith',
                  label: t('ORDERS.ZIPCODE'),
                  variant: 'textfield',
                },
                {
                  key: 'place.startsWith',
                  label: t('ORDERS.PLACE'),
                  variant: 'textfield',
                },
                {
                  key: 'address.startsWith',
                  label: t('ORDERS.ADDRESS'),
                  variant: 'textfield',
                },
                {
                  key: 'company.startsWith',
                  label: t('ORDERS.COMPANY'),
                  variant: 'textfield',
                },
              ]}
              sorting={[
                {
                  key: 'id',
                  default: true,
                  label: t('ORDERS.ORDER_ID'),
                },
              ]}
              dataIdentifier={'id'}
              resourceHeaderRenderFunction={(order) =>
                `${t('ORDERS.ORDER')}: ${order.id}`
              }
              listHeaderRenderFunction={(order) => (
                <Fragment>
                  <div>{`${t('ORDERS.ORDER')}: ${order.id}`} </div>
                  <div>
                    {order.request
                      ? `${requestTypeLookup[order.request.type]} ${t(
                          'ORDERS.NUMBER_SHORT',
                        )}: ${order.request.id}`
                      : t('ORDER.SERVICE_ORDER')}
                  </div>
                  <div>
                    {`${order.zipcode} | ${order.place} | `}
                    {`${countryLookup[order.countryId]} |  ${
                      order.deliveryType === 'date'
                        ? moment(order.deliveryValue).format('DD.MM.YYYY')
                        : `${t(
                            'ORDERS.CW',
                          )} ${generalService.convertDateToCalendarWeek(
                            order.deliveryValue,
                          )}`
                    }`}
                  </div>
                  {
                    <Fragment>
                      <div>{`${t('ORDER.CUSTOMER')}: ${order.customer.name} (${
                        order.customer.id
                      })`}</div>
                      {order.manufacturer && (
                        <div>{`${t('ORDER.MANUFACTURER')}: ${
                          order.manufacturer.name
                        } (${order.manufacturer.id})`}</div>
                      )}
                    </Fragment>
                  }
                </Fragment>
              )}
              contentRenderFunction={(order) => (
                <PositionsList positions={order.positions} />
              )}
              resourceRenderFunction={(order) => {
                return <Order orderId={order.id} />
              }}
              actionsRenderFunction={(order) => {
                return [
                  {
                    icon: <SaveAltOutlined />,
                    name: t('ORDERS.COPY_ORDER'),
                    primary: true,
                    onClick: async () => {
                      setIsLoading(true)
                      try {
                        let orderToCopy = await ordersService.getOrder(order.id)
                        submit(orderToCopy)
                      } catch (err) {
                      } finally {
                        setIsLoading(false)
                      }
                    },
                  },
                ]
              }}
            />
          </DialogContent>
        )}
      </Dialog>
    </Fragment>
  )
}

export default withTranslation()(connect()(SelectOrderDialog))
