import { Grid, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { alertActions } from '../../../../_actions/alertActions'
import { diffOfferWithOfferOrRequest } from '../../../../_helpers/little'
import { offersService } from '../../../../_services/offersService'
import AgreementDialog from '../../../AgreementDialog'
import Attachments from '../../../Attachments'
import DeliveryDetailsMultipleLocationsView from '../../../DeliveryDetailsMultipleLocationsView'
import DeliveryDetailsView from '../../../DeliveryDetailsView'
import { Loading } from '../../../Loading'
import OfferAttachmentsView from '../../../OfferAttachmentsView'
import OfferDetailsPositions from '../../../OfferDetailsPositions'
import OfferPaymentDetailsView from '../../../OfferPaymentDetailsView'
import OtherDetailsView from '../../../OtherDetailsView'
import { Section } from '../../../Section'

const OfferView = (props) => {
  const { t, dispatch, offerId, forceUpdate } = props

  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({})
  const [showSendOfferDialog, setShowSendOfferDialog] = useState(false)
  const userObject = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    setIsLoading(true)
    offersService
      .getOffer(offerId, userObject.partner.id)
      .then((offer) => {
        setData(offer)
      })
      .finally(() => setIsLoading(false))
  }, [offerId])

  const [differences, setDifferences] = useState()
  const [diffObjectType, setDiffObjectType] = useState()

  useEffect(() => {
    if (isLoading) {
      return
    }
    console.log(data)
    var rhsObj = data.request
    var rhsObjType = 'REQUEST'
    if (data.ancestor) {
      rhsObj = data.ancestor
      rhsObjType = 'OFFER'
    }
    setDiffObjectType(rhsObjType)
    setDifferences(diffOfferWithOfferOrRequest(data, rhsObj, rhsObjType))
  }, [data, isLoading])

  if (!differences || isLoading) {
    return <Loading />
  }

  return (
    <Fragment>
      {data.request.state === 'ARCHIVED' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant={'caption'} sx={{ fontWeight: 600 }}>
            {t('OFFER.ARCHIVED_INFORMATION')}
          </Typography>
        </div>
      ) : null}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Section>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                {data.request.type === 'TENDER' ? (
                  <DeliveryDetailsMultipleLocationsView data={data.request} />
                ) : null}
                {data.request.type === 'REQUEST' ||
                data.request.type === 'ADVERT' ? (
                  <DeliveryDetailsView
                    data={data}
                    parentType={'OFFER'}
                    differences={differences.generalInformation.deliveryDetails}
                  />
                ) : null}
              </Grid>
              <Grid item xs={12} sm={6}>
                <OfferPaymentDetailsView
                  data={data}
                  differences={differences.generalInformation.paymentDetails}
                  diffObjectType={diffObjectType}
                  parentType={'OFFER'}
                />
                <OtherDetailsView
                  data={{
                    // offer information
                    validUntil: data.generalInformation.otherDetails.validUntil,
                    internalNote:
                      data.generalInformation.otherDetails.internalNote,
                    // start and end comes from the request, as it can not be modified in offer
                    start: data.request.generalInformation.otherDetails.start,
                    end: data.request.generalInformation.otherDetails.end,
                  }}
                  requestType={data.request.type}
                />
              </Grid>
            </Grid>
          </Section>
        </Grid>
        <Grid item xs={12}>
          <OfferDetailsPositions
            data={data}
            differences={differences}
            diffObjectType={diffObjectType}
            diffData={data.ancestor ? data.ancestor : data.request}
          />
        </Grid>
        <Grid item xs={12}>
          <Attachments data={data.request} readOnly={true} />
        </Grid>
        <Grid item xs={12}>
          <OfferAttachmentsView data={data} />
        </Grid>
      </Grid>
      <AgreementDialog
        title={t('OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_0')}
        acceptButtonText={t('OFFERS.YES')}
        declineButtonText={t('OFFERS.NO')}
        message={
          parseInt(userObject.partner.id) ===
          parseInt(data.request.partnerId) ? (
            <Fragment>
              {t('OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_1')}
              <br />
              <br />
              {t('OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_2')}
            </Fragment>
          ) : (
            <Fragment>
              {t('OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_INSTANT_1')}
              <br />
              <br />
              {t('OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_INSTANT_2')}
            </Fragment>
          )
        }
        open={showSendOfferDialog}
        handleClose={() => {
          setShowSendOfferDialog(false)
        }}
        agree={() => {
          setShowSendOfferDialog(false)
          setIsLoading(true)
          offersService
            .counterOffer(data, data.id)
            .then((res) => {
              dispatch(alertActions.info(t('OFFER.COUNTEROFFER_CREATED')))
              setTimeout(() => {
                dispatch(alertActions.clear())
              }, alertActions.alertTimeout)
            })
            .catch(function (error) {
              console.log(error)
            })
            .finally(() => {
              setIsLoading(false)
              forceUpdate()
            })
        }}
      />
    </Fragment>
  )
}

export default withTranslation()(connect()(OfferView))
