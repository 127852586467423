import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ratingsService } from '../../_services/ratingsService'
import CustomDialogTitle from '../CustomDialogTitle'
import { CustomRating } from '../CustomRating'

const RateOrderDialog = (props) => {
  const { t, order, show, hide, forceUpdate } = props

  useEffect(() => {}, [])

  const [loading, setLoading] = React.useState(false)

  const valSchema = Yup.object().shape({
    quality: Yup.number().required(t('GENERAL.REQUIRED')).min(1).max(5),
    reliability: Yup.number().required(t('GENERAL.REQUIRED')).min(1).max(5),
  })

  return (
    (loading && <CircularProgress className="centered" />) || (
      <Formik
        enableReinitialize={true}
        initialValues={{
          quality: null,
          reliability: null,
        }}
        validationSchema={valSchema}
        //validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting = true
          setLoading(true)
          var payLoad = {
            ...values,
            orderId: order.id,
          }
          ratingsService
            .createRatingOfOrder(payLoad)
            .then(() => {
              forceUpdate()
              hide()
            })
            .catch(function (error) {
              console.log(error)
            })
            .finally(() => {
              setSubmitting = false
              setLoading(false)
            })
        }}
        render={({
          values,
          setFieldValue,
          isSubmitting,
          submitForm,
          isValid,
        }) => (
          <Dialog
            onClose={() => hide()}
            fullWidth={true}
            maxWidth="xs"
            open={show}
          >
            <CustomDialogTitle
              title={t('ORDERS.RATE_ORDER.TITLE')}
              onClose={() => hide()}
            />
            <DialogContent>
              <Form>
                <Grid container spacing={1} justifyContent="center">
                  <Grid item xs={12}>
                    <Typography component="legend">
                      {t('ORDER.RATE_ORDER.QUALITY')}
                    </Typography>
                    <CustomRating
                      name="quality"
                      value={values.quality}
                      onChange={(event, newValue) => {
                        setFieldValue('quality', newValue)
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography component="legend">
                      {t('ORDER.RATE_ORDER.RELIABILITY')}
                    </Typography>
                    <CustomRating
                      name="reliability"
                      value={values.reliability}
                      onChange={(event, newValue) => {
                        setFieldValue('reliability', newValue)
                      }}
                    />
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => hide()}
                variant="outlined"
                color={'secondary'}
              >
                {t('ORDERS.RATE_ORDER.CANCEL')}
              </Button>
              <Button
                onClick={() => submitForm()}
                disabled={!isValid || isSubmitting}
                type="submit"
                variant="contained"
                color="secondary"
              >
                {t('ORDERS.RATE_ORDER.CONFIRM')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      />
    )
  )
}

export default withTranslation()(RateOrderDialog)
