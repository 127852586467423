import MaterialTable from '@material-table/core'
import {
  Add,
  Delete,
  LocalShipping as DeliveryStartedIcon,
  Edit as EditIcon,
  Euro,
  Drafts as OrderConfirmationIcon,
} from '@mui/icons-material'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits'
import { Button, Dialog, DialogContent, Grid, Typography } from '@mui/material'
import moment from 'moment'
import Numeral from 'numeral'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { authorize } from '../../_helpers/authorization'
import { translateProductUnit } from '../../_helpers/little'
import { tableIcons, tableLocalization } from '../../_helpers/muiTable'
import { formatToNumber, formatToPrice } from '../../_helpers/number-formatting'
import { deliveryNotesService } from '../../_services/deliveryNotesService'
import AgreementDialog from '../AgreementDialog'
import ClaimsOfDeliveryNoteDialog from '../ClaimsOfDeliveryNoteDialog'
import CustomDialogTitle from '../CustomDialogTitle'
import InvoicingDialog from '../InvoicingDialog'
import { Loading } from '../Loading'
import PositionCard from '../PositionCard'
import DeliveryStartedDialog from './_components/DeliveryStartedDialog'
import GenerateDeliveryNoteDialog from './_components/GenerateDeliveryNoteDialog'
const DeliveryNotes = (props) => {
  const { t, order, show, hide } = props

  const [isLoading, setIsLoading] = useState(true)

  const [deliveryNotes, setDeliveryNotes] = useState([])

  const [selectedDeliveryNote, setSelectedDeliveryNote] = useState(null)
  const [showDeliveryStartedDialog, setShowDeliveryStartedDialog] =
    useState(false)

  const [showInvoicingDialog, setShowInvoicingDialog] = useState(false)

  const [showGenerateDeliveryNoteDialog, setShowGenerateDeliveryNoteDialog] =
    useState(false)

  const [showDeleteDeliveryNoteDialog, setShowDeleteDeliveryNoteDialog] =
    useState(false)

  const [forceUpdateCount, forceUpdate] = useState(0)
  const [showClaimsDialog, setShowClaimsDialog] = useState(false)
  const userObject = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (order) {
      setIsLoading(true)
      deliveryNotesService.getDeliveryNotesOfOrder(order.id).then((data) => {
        setDeliveryNotes(data)
        setIsLoading(false)
      })
    }
  }, [order, forceUpdateCount])

  return (
    <Dialog fullWidth={true} maxWidth="xl" open={show}>
      <CustomDialogTitle
        title={t('ORDERS.DELIVERY_NOTES.TITLE')}
        onClose={() => hide()}
      />
      {isLoading ? (
        <Loading variant={'centered'} />
      ) : (
        <DialogContent>
          {order ? (
            <Fragment>
              {(order.manufacturerId === userObject.partner.id ||
                authorize('administrator')) && (
                <div style={{ marginBottom: 10 }}>
                  <Button
                    size="medium"
                    disabled={
                      order.state === 'RECEIVED' || order.state === 'CANCELED'
                    }
                    color={'secondary'}
                    variant={'contained'}
                    startIcon={<Add />}
                    onClick={() => {
                      setShowGenerateDeliveryNoteDialog(true)
                    }}
                  >
                    {order.state === 'RECEIVED'
                      ? t('ORDERS.DELIVERY_NOTES.ORDER_ALREADY_RECEIVED')
                      : order.incoTerm === 'DDP'
                      ? t('ORDERS.DELIVERY_NOTES.GENERATE_DELIVERY_NOTE')
                      : t('ORDERS.DELIVERY_NOTES.GENERATE_PICKUP_NOTE')}
                  </Button>
                </div>
              )}
              <MaterialTable
                icons={tableIcons}
                localization={tableLocalization()}
                columns={[
                  {
                    title: '#',
                    field: 'id',
                    defaultSort: 'desc',
                  },
                  {
                    title:
                      order && order.incoTerm === 'DDP'
                        ? t('ORDERS.DELIVERY_NOTES.DELIVERY_TIME')
                        : t('ORDERS.DELIVERY_NOTES.PICKUP_TIME'),
                    field: 'delivery',
                    render: (deliveryNote) =>
                      deliveryNote.delivery ? (
                        <span>
                          {moment(deliveryNote.delivery).format('DD.MM.YYYY')}
                        </span>
                      ) : (
                        <span> {t('ORDERS.DELIVERY_NOT_STARTED_YET')} </span>
                      ),
                  },
                  {
                    title: t('ORDERS.DELIVERY_NOTES.TOTAL_COSTS'),
                    render: (deliveryNote) => {
                      var sum = 0
                      deliveryNote.positions.forEach((deliveryNotePosition) => {
                        sum +=
                          deliveryNotePosition.amount *
                          order.positions.find((orderPosition) => {
                            return (
                              orderPosition.id ===
                              deliveryNotePosition.orderPositionId
                            )
                          }).price
                      })

                      return <span>{Numeral(sum).format('0,0.00 $')}</span>
                    },
                  },
                  {
                    title: t('ORDERS.DELIVERY_NOTES.DESPATCH_ADVICE_DATE'),
                    hidden: !order.isDespatchAdviceRequired,
                    render: (deliveryNote) =>
                      order.isDespatchAdviceRequired &&
                      deliveryNote.despatchAdviceDate ? (
                        <span>
                          {moment(deliveryNote.despatchAdviceDate).format(
                            'DD.MM.YYYY h:mm:ss',
                          )}
                        </span>
                      ) : (
                        <span> {t('ORDERS.NOT_ADVICED_YET')} </span>
                      ),
                  },
                ]}
                data={deliveryNotes}
                options={{
                  toolbarButtonAlignment: 'left',
                  toolbar: order.customerId !== userObject.partner.id,
                  showTitle: false,
                  search: false,
                  paging: true,
                  pageSize: 10,
                  padding: 'dense',
                  actionsColumnIndex: -1,
                  filterCellStyle: {
                    maxWidth: 0,
                  },
                  headerStyle: {
                    fontWeight: 'bold',
                  },
                }}
                style={{ boxShadow: 'none' }}
                actions={
                  order.manufacturerId === userObject.partner.id ||
                  authorize('administrator')
                    ? [
                        (rowData) => ({
                          icon: () => (
                            <DeliveryStartedIcon
                              color={
                                rowData.delivery ? 'disabled' : 'secondary'
                              }
                            />
                          ),
                          tooltip:
                            order.incoTerm === 'EXW'
                              ? t('ORDERS.DELIVERY_NOTES.START_PICKUP')
                              : t('ORDERS.DELIVERY_NOTES.DELIVERY_STARTED'),
                          onClick: (event, rowData) => {
                            var deliveryNote = rowData
                            deliveryNote.order = order

                            setSelectedDeliveryNote(rowData)
                            setShowDeliveryStartedDialog(true)
                          },
                          disabled: rowData.delivery,
                        }),
                        (rowData) => ({
                          icon: () => (
                            <EditIcon
                              color={
                                !rowData.delivery ? 'disabled' : 'secondary'
                              }
                            />
                          ),
                          tooltip: t('ORDERS.DELIVERY_NOTES.EDIT_DELIVERY'),
                          onClick: (event, rowData) => {
                            var deliveryNote = rowData
                            deliveryNote.order = order

                            setSelectedDeliveryNote(rowData)
                            setShowDeliveryStartedDialog(true)
                          },
                          disabled: !rowData.delivery,
                        }),
                        (rowData) => ({
                          icon: () => (
                            <OrderConfirmationIcon color={'secondary'} />
                          ),
                          tooltip: t(
                            order.incoTerm === 'EXW'
                              ? t('ORDERS.DELIVERY_NOTES.DOWNLOAD_PICKUP_NOTE')
                              : t(
                                  'ORDERS.DELIVERY_NOTES.DOWNLOAD_DELIVERY_NOTE',
                                ),
                          ),
                          onClick: (event, rowData) => {
                            deliveryNotesService
                              .getDeliveryNote(rowData.id)
                              .then((response) => response.blob())
                              .then((blob) => {
                                // 2. Create blob link to download
                                const url = window.URL.createObjectURL(
                                  new Blob([blob]),
                                )
                                const link = document.createElement('a')
                                link.href = url
                                link.setAttribute(
                                  'download',
                                  'delivery-note-' + rowData.id + '.pdf',
                                )
                                link.setAttribute('target', '_blank')
                                // 3. Append to html page
                                document.body.appendChild(link)
                                // 4. Force download
                                link.click()
                                // 5. Clean up and remove the link
                                link.parentNode.removeChild(link)
                              })
                          },
                        }),
                        (rowData) => ({
                          icon: () => (
                            <Delete
                              color={
                                rowData.delivery ? 'disabled' : 'secondary'
                              }
                            />
                          ),
                          tooltip: rowData.delivery
                            ? t(
                                'ORDERS.DELIVERY_NOTES.DELIVERY_ALREADY_STARTED',
                              )
                            : t('GENERAL.DELETE'),
                          onClick: (event, rowData) => {
                            setSelectedDeliveryNote({
                              ...rowData,
                              orderId: order.id,
                            })
                            setShowDeleteDeliveryNoteDialog(true)
                          },
                          disabled: rowData.delivery,
                        }),
                        (rowData) => ({
                          icon: () => (
                            <Euro
                              color={
                                !rowData.delivery ? 'disabled' : 'secondary'
                              }
                            />
                          ),
                          tooltip: !rowData.delivery
                            ? t(
                                'ORDERS.DELIVERY_NOTES.DELIVERY_NOT_STARTED_YET',
                              )
                            : t('ORDERS.DELIVERY_NOTES.OPEN_INVOICING'),
                          onClick: (event, rowData) => {
                            setSelectedDeliveryNote({
                              ...rowData,
                              order: order,
                            })
                            setShowInvoicingDialog(true)
                          },
                          hidden: !authorize('administrator'),
                          disabled: !rowData.delivery,
                        }),

                        (rowData) => ({
                          icon: () => (
                            <ProductionQuantityLimitsIcon
                              color={
                                !rowData.delivery &&
                                order.customerId !== userObject.partner.id
                                  ? 'disabled'
                                  : 'secondary'
                              }
                            />
                          ),
                          tooltip: t('CLAIM.TOOLTIP'),
                          onClick: (event, rowData) => {
                            setSelectedDeliveryNote(rowData)
                            setShowClaimsDialog(true)
                          },
                          //TODO isCustomerPrüfung
                          hidden:
                            order.customerId !== userObject.partner.id ||
                            !authorize('administrator'),
                          disabled:
                            !rowData.delivery &&
                            order.customerId !== userObject.partner.id,
                        }),
                      ]
                    : []
                }
                detailPanel={(deliveryNote) => {
                  return (
                    <Grid container spacing={1}>
                      {deliveryNote.rowData.positions.map(
                        (deliveryNotePosition) => {
                          let oPos = order.positions.find((oPos) => {
                            return (
                              oPos.id === deliveryNotePosition.orderPositionId
                            )
                          })
                          return (
                            <Grid item xs={12} md={6} margin={1}>
                              <PositionCard
                                data={oPos}
                                readOnly={true}
                                showProductDetails={true}
                                showImage={true}
                                imageSize={'small'}
                              >
                                {deliveryNotePosition.amount > 0 && (
                                  <Fragment>
                                    <Typography>
                                      {`${formatToNumber(
                                        deliveryNotePosition.amount,
                                      )} ${translateProductUnit(
                                        oPos.productUnit.deliveryProductUnit
                                          ? oPos.productUnit.deliveryProductUnit
                                          : oPos.productUnit,
                                      )}`}
                                    </Typography>
                                    <Typography>
                                      {formatToPrice(
                                        deliveryNotePosition.amount *
                                          oPos.price,
                                      )}
                                    </Typography>
                                  </Fragment>
                                )}
                              </PositionCard>
                            </Grid>
                          )
                        },
                      )}
                    </Grid>
                  )
                }}
              />

              <AgreementDialog
                open={showDeleteDeliveryNoteDialog}
                message={t(
                  'ORDERS.DELIVERY_NOTES.DELETE_DELIVERY_NOTE_MESSAGE',
                )}
                acceptButtonText={t('ORDERS.DELETE_DELIVERY_NOTE')}
                handleClose={() => {
                  setShowDeleteDeliveryNoteDialog(false)
                }}
                agree={() => {
                  setIsLoading(true)
                  setShowDeleteDeliveryNoteDialog(false)
                  deliveryNotesService
                    .deleteDeliveryNote(selectedDeliveryNote.id)
                    .then(() => {
                      forceUpdate(forceUpdateCount + 1)
                    })
                    .catch(function (error) {
                      console.log(error)
                    })
                    .finally(() => {
                      setIsLoading(false)
                    })
                }}
              />
              <GenerateDeliveryNoteDialog
                forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
                order={order}
                show={showGenerateDeliveryNoteDialog}
                hide={() => setShowGenerateDeliveryNoteDialog(false)}
                key={showGenerateDeliveryNoteDialog}
              />
              {showDeliveryStartedDialog ? (
                <DeliveryStartedDialog
                  forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
                  deliveryNote={selectedDeliveryNote}
                  show={showDeliveryStartedDialog}
                  hide={() => setShowDeliveryStartedDialog(false)}
                  key={selectedDeliveryNote.id + selectedDeliveryNote.delivery}
                />
              ) : null}

              {showInvoicingDialog && (
                <InvoicingDialog
                  order={order}
                  deliveryNote={selectedDeliveryNote}
                  show={showInvoicingDialog}
                  hide={() => setShowInvoicingDialog(false)}
                />
              )}
              {showClaimsDialog && (
                <ClaimsOfDeliveryNoteDialog
                  forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
                  deliveryNote={selectedDeliveryNote}
                  order={order}
                  show={showClaimsDialog}
                  hide={() => setShowClaimsDialog(false)}
                />
              )}
            </Fragment>
          ) : null}
        </DialogContent>
      )}
    </Dialog>
  )
}

export default withTranslation()(DeliveryNotes)
