import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { Field, Form, Formik, yupToFormErrors } from 'formik'
import { CheckboxWithLabel, RadioGroup, Select, TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { RRule } from 'rrule'
import * as Yup from 'yup'
import {
  getTouchedObj,
  validateLocation,
} from '../../../../../../../../_helpers/little'
import NumberFormatCustom from '../../../../../../../../_helpers/numberFormatCustom'
import { jobsService } from '../../../../../../../../_services/jobsService'
import { usersService } from '../../../../../../../../_services/usersService'
import CustomDialogTitle from '../../../../../../../CustomDialogTitle'
import DeliveryDetails from '../../../../../../../DeliveryDetails'
import DeliveryDetailsReplacedComponentsDialog from '../../../../../../../DeliveryDetailsReplacedComponentsDialog'
import { FieldErrorWrapper } from '../../../../../../../FieldErrorWrapper'
import { Loading } from '../../../../../../../Loading'
import PaymentDetails from '../../../../../../../PaymentDetails'
import JobDeliveries from './_components/JobDeliveries'
import JobPositions from './_components/JobPositions'

const OfferJobs = (props) => {
  const { t, hide, jobId, forceUpdate } = props

  const [activeStep, setActiveStep] = React.useState(0)
  const [initialValues, setInitialValues] = useState({
    freq: RRule.WEEKLY,
    interval: 1,
    weekdays: Array(7).fill(false),
    month: Array(12).fill(false),
    dtstart: null,
    until: null,
    bymonthday: undefined,
    name: '',
    generalInformation: {
      deliveryDetails: {
        incoTerm: 'DDP',
        locations: [
          {
            id: 1,
            company: '',
            address: '',
            zipcode: '',
            place: '',
            countryId: 80,
            deliveryInformations: '',
            unload: 'ANY',
            stackHeight: 20,
            stackHeightUnit: 'QUANTITY',
            deliveryTimes: [
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
            ],
            isDespatchAdviceRequired: false,
            despatchAdviceInstructions: '',
          },
        ],
      },
      paymentDetails: {
        paymentTerm: JSON.parse(localStorage.getItem('user')).partner
          .allPaymentTermsAllowed
          ? '14'
          : '0',
        skonto: '0',
        skontoPeriod: '',
        additionalPaymentInformation: '',
      },
      otherDetails: {
        title: '',
        validUntil: null,
      },
    },
    positions: [],
    deliveries: [],
    users: [],
  })
  const [showHasReplacedComponentsDialog, setShowHasReplacedComponentsDialog] =
    useState(false)
  const [externalAdressValidationResult, setExternalAdressValidationResult] =
    useState(false)
  const [geoData, setGeoData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [usersOfPacurion, setUsersOfPacurion] = useState([])
  const [usersOfPartner, setUsersOfPartner] = useState([])

  const userObject = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (jobId) {
      setIsLoading(true)
      jobsService.getJob(jobId).then((job) => {
        let rule = RRule.fromString(job.rule)

        let mappedValues = {
          ...job,
          validUntil: moment(job.validUntil),
          freq: rule.options.freq,
          interval: rule.options.interval,
          weekdays: rule.options.byweekday
            ? Array(7)
                .fill(false)
                .map((day, index) =>
                  rule.options.byweekday.some((weekday) => weekday === index),
                )
            : Array(7).fill(false),
          month: rule.options.bymonth
            ? Array(12)
                .fill(false)
                .map((month, index) =>
                  rule.options.bymonth.some((month) => month === index),
                )
            : Array(12).fill(false),
          dtstart: moment(rule.options.dtstart),
          until: moment(rule.options.until),
          bymonthday: rule.options.bynmonthday
            ? rule.options.bynmonthday.join(',')
            : undefined,
        }

        setInitialValues(mappedValues)
        setIsLoading(false)
      })
    }
  }, [t])

  useEffect(() => {
    usersService.getUsersOfPartner(userObject.partner.id).then((users) => {
      setUsersOfPartner(users)
    })
  }, [userObject.partner.id])

  useEffect(() => {
    usersService.getUsersOfPartner(1).then((users) => {
      setUsersOfPacurion(users)
    })
  }, [])

  const valSchemas = [
    Yup.object().shape({
      id: Yup.number(),
      generalInformation: Yup.object().shape({
        deliveryDetails: Yup.object().shape({
          locations: Yup.array()
            .of(
              Yup.object()
                .shape({
                  company: Yup.string().required(t('GENERAL.REQUIRED')),
                  address: Yup.string().required(t('GENERAL.REQUIRED')),
                  zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
                  place: Yup.string().required(t('GENERAL.REQUIRED')),
                  countryId: Yup.number().required(t('GENERAL.REQUIRED')),
                  deliveryInformations: Yup.string().nullable(),
                  unload: Yup.string().required(),
                  stackHeight: Yup.number()
                    .typeError(t('GENERAL.REQUIRED'))
                    .required(t('GENERAL.REQUIRED')),
                  stackHeightUnit: Yup.string().required(),
                })
                .test({
                  test: async function (location, context) {
                    return await validateLocation(
                      location,
                      context,
                      `generalInformation.deliveryDetails.locations[${context.parent.findIndex(
                        (locationInValues) =>
                          locationInValues.id === location.id,
                      )}]`,
                      setExternalAdressValidationResult,
                      setShowHasReplacedComponentsDialog,
                      (validationResult) => {
                        setGeoData(validationResult.geocode.location)
                      },
                      this,
                      ['route', 'street_number'],
                    )
                  },
                }),
            )
            .min(1, t('GENERAL.MIN_1')),
        }),
        paymentDetails: Yup.object().shape({
          paymentTerm: Yup.string().required(t('GENERAL.REQUIRED')),
          skonto: Yup.string().required(t('GENERAL.REQUIRED')),
          skontoPeriod: Yup.string()
            .nullable()
            .test('required', t('GENERAL.REQUIRED'), function (skontoPeriod) {
              return (
                parseInt(this.parent.skonto) === 0 ||
                (skontoPeriod !== null && skontoPeriod !== '')
              )
            })
            .test(
              'paymentTerm',
              t('REQUEST.PAYMENT_DETAILS.INVALID_REF_PAYMENT_TERM'),
              function (skontoPeriod) {
                return (
                  skontoPeriod === null ||
                  skontoPeriod === '' ||
                  parseInt(skontoPeriod) <= parseInt(this.parent.paymentTerm)
                )
              },
            ),
        }),
      }),
    }),
    Yup.object().shape({
      interval: Yup.number().required(t('GENERAL.REQUIRED')),
      jobOfferValidity: Yup.number().required(t('GENERAL.REQUIRED')),
      dtstart: Yup.date()
        .typeError(t('GENERAL.INVALID_DATE'))
        .required(t('GENERAL.REQUIRED')),
      until: Yup.date()
        .typeError(t('GENERAL.INVALID_DATE'))
        .required(t('GENERAL.REQUIRED'))
        .test('start', t('GENERAL.INVALID_DATE_PAST'), (start) => {
          if (start) {
            return moment().diff(moment(start), 'days') <= 0
          } else {
            return true
          }
        }),
    }),
    Yup.object().shape({
      positions: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.number().required(t('GENERAL.REQUIRED')),
            productId: Yup.string().required(t('GENERAL.REQUIRED')),
            productUnitId: Yup.number().required(t('GENERAL.REQUIRED')),
            amount: Yup.number()
              .required(t('GENERAL.REQUIRED'))
              .typeError(t('GENERAL.REQUIRED'))
              .min(0.01, t('GENERAL.GREATER_THAN_0'))
              .test({
                message: t('GENERAL.TOO_MUCH'),
                test: function (v) {
                  if (this.parent.productUnitId === 3 && parseFloat(v) > 200) {
                    return false
                  } else {
                    return true
                  }
                },
              }),
          }),
        )
        .min(1, t('GENERAL.MIN_1')),
    }),
    Yup.object().shape({
      deliveries: Yup.array()
        .of(
          Yup.object()
            .shape({
              type: Yup.string().required(t('GENERAL.REQUIRED')),
              value: Yup.string(),
              obligatory: Yup.boolean(),
              positions: Yup.array()
                .of(
                  Yup.object().shape({
                    amount: Yup.number(),
                  }),
                )
                .compact(function (v) {
                  return v.amount === ''
                })
                .min(1, t('GENERAL.MIN_1')),
            })
            .test({
              message: t('REQUEST.ADD_DELIVERY_WIZARD.POS_AMOUNT_EXCEEDED'),
              test: function (delivery) {
                let result = true
                delivery.deliveryPositions.forEach((dPos) => {
                  const { context } = this.options
                  var positionsAmount = parseFloat(
                    context.positions.find((pos) => {
                      return pos.id === dPos.positionId
                    }).amount,
                  )
                  var otherDeliveriesAmount = 0
                  context.deliveries.forEach((otherDelivery) => {
                    if (delivery.id === otherDelivery.id) {
                      return
                    }
                    otherDelivery.deliveryPositions.forEach((dPos2) => {
                      if (dPos2.positionId === dPos.positionId) {
                        otherDeliveriesAmount += dPos2.amount
                          ? parseFloat(dPos2.amount)
                          : 0
                      }
                    })
                  })
                  result =
                    result &&
                    dPos.amount <=
                      positionsAmount - parseFloat(otherDeliveriesAmount)
                })

                return result
              },
            }),
        )
        .test({
          message: t('JOB_OFFERS.DELIVERY_NOT_MATCHING_POSITIONS'),
          test: function (deliveries, context) {
            let dPosAmountLookup = {}
            context.parent.positions.forEach((pos) => {
              dPosAmountLookup[pos.id] = 0
              deliveries.forEach((delivery) => {
                delivery.deliveryPositions.forEach((dPos) => {
                  if (dPos.positionId === pos.id) {
                    dPosAmountLookup[pos.id] =
                      parseFloat(dPosAmountLookup[pos.id]) +
                      (isNaN(parseFloat(dPos.amount))
                        ? 0
                        : parseFloat(dPos.amount))
                  }
                })
              })
            })

            let positionsWithoutDeliveriesExists =
              context.parent.positions.some((pos) => {
                return pos.amount - dPosAmountLookup[pos.id] !== 0
              })
            return !positionsWithoutDeliveriesExists
          },
        }),
    }),
    Yup.object().shape({
      signatureUserId: Yup.string().required(t('GENERAL.REQUIRED')),
      users: Yup.array().min(1, t('GENERAL.MIN_1')),
      name: Yup.string(),
    }),
  ]

  const generateRule = (values) => {
    let RRuleWeekDays = [
      RRule.MO,
      RRule.TU,
      RRule.WE,
      RRule.TH,
      RRule.FR,
      RRule.SA,
      RRule.SU,
    ]

    let selectedWeekDays = []

    values.weekdays.forEach((weekday, index) => {
      if (weekday) {
        selectedWeekDays.push(RRuleWeekDays[index])
      }
    })

    let RRuleMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

    let selectedMonth = []

    values.month.forEach((month, index) => {
      if (month) {
        selectedMonth.push(RRuleMonth[index])
      }
    })

    let monthDays = values.bymonthday ? values.bymonthday.split(',') : undefined

    let dtstart = new Date(values.dtstart)
    let until = new Date(values.until)

    let rule = new RRule({
      freq: parseInt(values.freq),
      interval: parseInt(values.interval),
      byweekday: selectedWeekDays.length > 0 ? selectedWeekDays : undefined,
      dtstart: dtstart,
      until: until,
      bymonthday:
        monthDays && monthDays.length > 0
          ? monthDays.map((monthday) => parseInt(monthday))
          : undefined,
      bymonth:
        selectedMonth && selectedMonth.length > 0
          ? selectedMonth.map((monthday) => parseInt(monthday))
          : undefined,
    })

    return rule
  }

  return isLoading ? (
    <Loading variant={'centered'} />
  ) : (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        let rule = generateRule(values)
        let requestBody = {
          incoTerm: 'DDP',
          name: values.name,
          validUntil: moment(rule.options.until).format('YYYY-MM-DD'),
          jobOfferValidity: values.jobOfferValidity,
          rule: rule.toString(),
          company:
            values.generalInformation.deliveryDetails.locations[0].company,
          zipcode:
            values.generalInformation.deliveryDetails.locations[0].zipcode,
          place: values.generalInformation.deliveryDetails.locations[0].place,
          address:
            values.generalInformation.deliveryDetails.locations[0].address,
          deliveryInformations:
            values.generalInformation.deliveryDetails.locations[0]
              .deliveryInformations,
          countryId:
            values.generalInformation.deliveryDetails.locations[0].countryId,
          unload: values.generalInformation.deliveryDetails.locations[0].unload,
          stackHeight:
            values.generalInformation.deliveryDetails.locations[0].stackHeight,
          stackHeightUnit:
            values.generalInformation.deliveryDetails.locations[0]
              .stackHeightUnit,
          deliveryTimes:
            values.generalInformation.deliveryDetails.locations[0]
              .deliveryTimes,
          isDespatchAdviceRequired:
            values.generalInformation.deliveryDetails.locations[0]
              .isDespatchAdviceRequired,
          despatchAdviceInstructions:
            values.generalInformation.deliveryDetails.locations[0]
              .despatchAdviceInstructions,
          paymentTerm: values.generalInformation.paymentDetails.paymentTerm,
          skonto: values.generalInformation.paymentDetails.skonto,
          skontoPeriod:
            values.generalInformation.paymentDetails.skontoPeriod === ''
              ? null
              : values.generalInformation.paymentDetails.skontoPeriod,
          longitude: geoData ? geoData.longitude : undefined,
          latitude: geoData ? geoData.latitude : undefined,
          signatureUserId: values.signatureUserId,
          positions: values.positions,
          deliveries: values.deliveries,
          users: values.users,
        }

        const userObject = JSON.parse(localStorage.getItem('user'))

        if (jobId) {
          jobsService
            .updateJob(jobId, requestBody)
            .then(() => {
              hide()
            })
            .catch(function (error) {
              console.log(error)
            })
            .finally(() => {
              setSubmitting = false
              setIsLoading(false)
              forceUpdate()
            })
        } else {
          jobsService
            .createJobOfPartner(userObject.partner.id, requestBody)
            .then(() => {
              hide()
            })
            .catch(function (error) {
              console.log(error)
            })
            .finally(() => {
              setSubmitting = false
              setIsLoading(false)
              forceUpdate()
            })
        }
      }}
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        submitForm,
        isValid,
        errors,
        setTouched,
        setErrors,
      }) => {
        let resetFields = () => {
          setFieldValue('interval', 1)
          setFieldValue('weekdays', Array(7).fill(false))
          setFieldValue('month', Array(12).fill(false))
          setFieldValue('bymonthday', undefined)
        }
        let rule = generateRule(values)
        let yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        yesterday.setHours(12, 0, 0, 0) // Setzt die Uhrzeit auf Mitternacht.

        let futureDates = rule
          .all()
          .filter((date) => date > yesterday)
          .map((date) => {
            let currentDate = new Date(date)
            currentDate.setDate(currentDate.getDate() - 1)
            currentDate.setHours(12, 0, 0, 0) // Setzt die Uhrzeit auf Mitternacht.
            return currentDate
          })

        let matchingDateTimes = futureDates.slice(0, 10)

        return (
          <Dialog fullWidth={true} maxWidth="xl" open={true}>
            <CustomDialogTitle
              title={
                jobId ? t('OFFER_JOBS.EDIT_JOB') : t('OFFER_JOBS.CREATE_JOB')
              }
              onClose={() => {
                hide()
              }}
            />
            <DialogContent>
              <Stepper activeStep={activeStep} sx={{ margin: 2 }}>
                <Step>
                  <StepLabel>{t('REQUEST.GENERAL_INFORMATION')}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{t('REQUEST.OFFER_RULES')}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{t('REQUEST.PRODUCTS')}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{t('REQUEST.DATE_OF_DELIVERY')}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{t('REQUEST.OTHER')}</StepLabel>
                </Step>
              </Stepper>
            </DialogContent>
            <DialogContent>
              <Form>
                <Grid container spacing={1} padding={3}>
                  {activeStep === 0 && (
                    <Fragment>
                      <Grid item xs={12} sm={6}>
                        <DeliveryDetails
                          readOnly={false}
                          data={values}
                          setFieldValue={setFieldValue}
                          mode={'create'}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <PaymentDetails
                          data={values}
                          setFieldValue={setFieldValue}
                          readOnly={false}
                        />
                      </Grid>
                    </Fragment>
                  )}

                  {activeStep === 1 && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Field component={RadioGroup} name={'freq'} row>
                          <FormControlLabel
                            value={RRule.MONTHLY}
                            control={<Radio disabled={isSubmitting} />}
                            label={t('OFFER_JOBS.MONTHLY')}
                            onChange={() => resetFields()}
                          />
                          <FormControlLabel
                            value={RRule.WEEKLY}
                            control={<Radio disabled={isSubmitting} />}
                            label={t('OFFER_JOBS.WEEKLY')}
                            onChange={() => resetFields()}
                          />
                          <FormControlLabel
                            value={RRule.DAILY}
                            control={<Radio disabled={isSubmitting} />}
                            label={t('OFFER_JOBS.DAILY')}
                            onChange={() => resetFields()}
                          />
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FieldErrorWrapper
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              label: t('OFFER_JOBS.START_DATE'),
                              margin: 'dense',
                            },
                          }}
                          name="dtstart"
                          component={DatePicker}
                          inputFormat="DD.MM.YYYY"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FieldErrorWrapper
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              label: t('OFFER_JOBS.UNTIL'),
                              margin: 'dense',
                            },
                          }}
                          name="until"
                          component={DatePicker}
                          inputFormat="DD.MM.YYYY"
                          minDate={moment()}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t('OFFER_JOBS.INTERVAL')}
                          variant="outlined"
                          name="interval"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{
                            decimalScale: 0,
                            allowNegative: false,
                            fixedDecimalScale: true,
                            min: 1,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t('OFFER_JOBS.OFFER_VALIDITY_IN_DAY')}
                          variant="outlined"
                          name="jobOfferValidity"
                        />
                      </Grid>

                      {RRule.MONTHLY === parseInt(values.freq) && (
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            fullWidth
                            margin="dense"
                            label={t('OFFER_JOBS.DAY_OF_THE_MONTH')}
                            variant="outlined"
                            name="bymonthday"
                          />
                        </Grid>
                      )}

                      {[RRule.MONTHLY, RRule.WEEKLY].includes(
                        parseInt(values.freq),
                      ) && (
                        <Grid item xs={12}>
                          <Field
                            component={CheckboxWithLabel}
                            type={'checkbox'}
                            color="default"
                            name={'weekdays[0]'}
                            Label={{
                              label: t('GENERAL.MONDAY'),
                            }}
                            style={{ marginLeft: '15px' }}
                          />
                          <Field
                            component={CheckboxWithLabel}
                            type={'checkbox'}
                            color="default"
                            name={'weekdays[1]'}
                            Label={{
                              label: t('GENERAL.TUESDAY'),
                            }}
                            style={{ marginLeft: '15px' }}
                          />
                          <Field
                            component={CheckboxWithLabel}
                            type={'checkbox'}
                            color="default"
                            name={'weekdays[2]'}
                            Label={{
                              label: t('GENERAL.WEDNESDAY'),
                            }}
                            style={{ marginLeft: '15px' }}
                          />
                          <Field
                            component={CheckboxWithLabel}
                            type={'checkbox'}
                            color="default"
                            name={'weekdays[3]'}
                            Label={{
                              label: t('GENERAL.THURSDAY'),
                            }}
                            style={{ marginLeft: '15px' }}
                          />
                          <Field
                            component={CheckboxWithLabel}
                            type={'checkbox'}
                            color="default"
                            name={'weekdays[4]'}
                            Label={{
                              label: t('GENERAL.FRIDAY'),
                            }}
                            style={{ marginLeft: '15px' }}
                          />
                          <Field
                            component={CheckboxWithLabel}
                            type={'checkbox'}
                            color="default"
                            name={'weekdays[5]'}
                            Label={{
                              label: t('GENERAL.SATURDAY'),
                            }}
                            style={{ marginLeft: '15px' }}
                          />
                          <Field
                            component={CheckboxWithLabel}
                            type={'checkbox'}
                            color="default"
                            name={'weekdays[6]'}
                            Label={{
                              label: t('GENERAL.SUNDAY'),
                            }}
                            style={{ marginLeft: '15px' }}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={'month[0]'}
                          Label={{
                            label: t('GENERAL.JANUARY'),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={'month[1]'}
                          Label={{
                            label: t('GENERAL.FEBRUARY'),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={'month[2]'}
                          Label={{
                            label: t('GENERAL.MARCH'),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={'month[3]'}
                          Label={{
                            label: t('GENERAL.APRIL'),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={'month[4]'}
                          Label={{
                            label: t('GENERAL.MAY'),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={'month[5]'}
                          Label={{
                            label: t('GENERAL.JUNE'),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={'month[6]'}
                          Label={{
                            label: t('GENERAL.JULY'),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={'month[7]'}
                          Label={{
                            label: t('GENERAL.AUGUST'),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={'month[8]'}
                          Label={{
                            label: t('GENERAL.SEPTEMBER'),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={'month[9]'}
                          Label={{
                            label: t('GENERAL.OKTOBER'),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={'month[10]'}
                          Label={{
                            label: t('GENERAL.NOVEMBER'),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={'month[11]'}
                          Label={{
                            label: t('GENERAL.DECEMBER'),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography>{t('OFFER_JOBS.EXAMPLE_DATES')}</Typography>
                        <Typography>{rule.toText()}</Typography>
                        <List>
                          {matchingDateTimes.map((matchingDateTime) => (
                            <ListItem>
                              <ListItemText
                                primary={moment(matchingDateTime).format(
                                  'DD.MM.YYYY',
                                )}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  )}

                  {activeStep === 2 && (
                    <Grid item xs={12}>
                      <JobPositions
                        data={values}
                        readOnly={false}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        valSchema={valSchemas[activeStep]}
                      />
                    </Grid>
                  )}

                  {activeStep === 3 && (
                    <Grid item xs={12}>
                      <JobDeliveries
                        data={values}
                        readOnly={false}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        positions={values.positions}
                      />
                    </Grid>
                  )}

                  {activeStep === 4 && (
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Field
                            component={Select}
                            fullWidth
                            margin="dense"
                            label={t('OFFER_JOBS.SIGNATURE_USER')}
                            variant="outlined"
                            name="signatureUserId"
                            sx={{
                              width: 400,
                            }}
                          >
                            {usersOfPacurion.map((user) => (
                              <MenuItem value={user.id} key={user.id}>
                                {`${user.firstname} ${user.lastname} (${user.email})`}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            multiple
                            component={Select}
                            fullWidth
                            margin="dense"
                            label={t('OFFER_JOBS.USERS')}
                            variant="outlined"
                            name="users"
                            sx={{
                              width: 400,
                            }}
                          >
                            {usersOfPartner.map((user) => (
                              <MenuItem value={user.id} key={user.id}>
                                {`${user.firstname} ${user.lastname} (${user.email})`}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            fullWidth
                            margin="dense"
                            label={t('OFFER_JOBS.JOB_NAME')}
                            variant="outlined"
                            name="name"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {showHasReplacedComponentsDialog && (
                  <DeliveryDetailsReplacedComponentsDialog
                    setAddress={() => {
                      let replacedAddressComponents =
                        externalAdressValidationResult.address.addressComponents.filter(
                          (addressComponent) => addressComponent.replaced,
                        )

                      replacedAddressComponents.forEach((addressComponent) => {
                        switch (addressComponent.componentType) {
                          case 'route':
                          case 'street_number':
                            let adress =
                              externalAdressValidationResult.address.addressComponents.find(
                                (addressComponent) =>
                                  addressComponent.componentType === 'route',
                              )
                            let streetNumber =
                              externalAdressValidationResult.address.addressComponents.find(
                                (addressComponent) =>
                                  addressComponent.componentType ===
                                  'streetNumber',
                              )
                            setFieldValue(
                              `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                                (location) =>
                                  location.id ===
                                  externalAdressValidationResult.locationId,
                              )}].address`,
                              `${adress} ${streetNumber}`,
                            )
                            break
                          case 'postal_code':
                            setFieldValue(
                              `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                                (location) =>
                                  location.id ===
                                  externalAdressValidationResult.locationId,
                              )}].zipcode`,
                              addressComponent.componentName.text,
                            )
                            break
                          case 'locality':
                            setFieldValue(
                              `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                                (location) =>
                                  location.id ===
                                  externalAdressValidationResult.locationId,
                              )}].place`,
                              addressComponent.componentName.text,
                            )
                            break
                          // case 'country':
                          //   break
                          default:
                            break
                        }
                        setFieldValue()
                      })
                    }}
                    addressValidationResult={externalAdressValidationResult}
                    hide={() => setShowHasReplacedComponentsDialog(false)}
                  />
                )}
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="secondary"
                style={{ margin: '0 10px 0px 10px' }}
                onClick={() => {
                  if (activeStep > 0) {
                    setActiveStep((prevActiveStep) => prevActiveStep - 1)
                  } else {
                    hide()
                  }
                }}
              >
                {t('GENERAL.BACK')}
              </Button>
              {activeStep === 4 ? (
                <Fragment>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ margin: '0 10px 0px 10px' }}
                    disabled={isSubmitting}
                    onClick={() => {
                      valSchemas[activeStep]
                        .validate(values, {
                          abortEarly: false,
                          context: values,
                        })
                        .then(async function (value) {
                          await submitForm()
                        })
                        .catch((err) => {
                          let yupErrors = yupToFormErrors(err)
                          setErrors(yupErrors)
                          setTouched(getTouchedObj(yupErrors), false) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                        })
                    }}
                  >
                    {t('REQUEST.PUBLISH')}
                  </Button>
                </Fragment>
              ) : (
                <Fragment>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (valSchemas[activeStep]) {
                        valSchemas[activeStep]
                          .validate(values, {
                            abortEarly: false,
                            context: values,
                          })
                          .then(function (value) {
                            if (valSchemas[activeStep]) {
                              valSchemas[activeStep]
                                .validate(values, {
                                  abortEarly: false,
                                  context: values,
                                })
                                .then(function (value) {
                                  setActiveStep(
                                    (prevActiveStep) => prevActiveStep + 1,
                                  )
                                })
                                .catch((err) => {
                                  let yupErrors = yupToFormErrors(err)
                                  setErrors(yupErrors)
                                  setTouched(getTouchedObj(yupErrors), false) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                                })
                            } else {
                              setActiveStep(
                                (prevActiveStep) => prevActiveStep + 1,
                              )
                            }
                          })
                          .catch((err) => {
                            let yupErrors = yupToFormErrors(err)
                            setErrors(yupErrors)
                            setTouched(getTouchedObj(yupErrors), false) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                          })
                      } else {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1)
                      }
                    }}
                  >
                    {t('REQUEST.CONTINUE')}
                  </Button>
                </Fragment>
              )}
            </DialogActions>
          </Dialog>
        )
      }}
    </Formik>
  )
}

export default withTranslation()(connect()(OfferJobs))
