import BusinessIcon from '@mui/icons-material/Business'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import ListIcon from '@mui/icons-material/List'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PollIcon from '@mui/icons-material/Poll' // Import PollIcon
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { _applicationService } from '../../../../../../_services/_applicationService'
import { Loading } from '../../../../../Loading'
import SurveyParticipantDialog from '../../../PartnerSettings/_components/PartnerSurveys/_components/SurveyParticipantDialog'

function TaskBoard(props) {
  const { t, partner } = props
  let navigate = useNavigate()
  const theme = useTheme()
  const customGreen = '#0b6467'
  const [tasks, setTasks] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showSurveyDialog, setShowSurveyDialog] = useState(false)
  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const isSmallScreen = useMediaQuery('(max-width:1250px)')

  useEffect(() => {
    if (partner.id) {
      setIsLoading(true)
      _applicationService
        .getDashboardTasksOfPartner(partner.id)
        .then((data) => {
          const sortedTasks = sortTasksByPriorityAndDate(data)
          setTasks(sortedTasks)
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
        })
        .finally(() => setIsLoading(false))
    }
  }, [partner.id])

  const sortTasksByPriorityAndDate = (tasks) => {
    return tasks.sort((a, b) => {
      const priorityA =
        a.priority === null ? Number.MAX_SAFE_INTEGER : a.priority
      const priorityB =
        b.priority === null ? Number.MAX_SAFE_INTEGER : b.priority

      if (priorityA !== priorityB) {
        return priorityA - priorityB
      }

      const aDate = new Date(a.datum).setHours(0, 0, 0, 0)
      const bDate = new Date(b.datum).setHours(0, 0, 0, 0)

      return aDate - bDate
    })
  }

  const getPriorityChip = (priority, date) => {
    let backgroundColor = ''
    let textColor = '#000000'
    let label = moment(date).format('DD.MM.YYYY')
    let tooltipText = ''

    switch (priority) {
      case 1:
        backgroundColor = '#f00'
        tooltipText = t('DASHBOARD.TOOLTIP_TASK_PRIORITY_1')
        break
      case 2:
        backgroundColor = '#ff7f24'
        tooltipText = t('DASHBOARD.TOOLTIP_TASK_PRIORITY_2')
        break
      case 3:
        backgroundColor = '#7EC07E'
        tooltipText = t('DASHBOARD.TOOLTIP_TASK_PRIORITY_3')
        break
      default:
        backgroundColor = '#7EC07E'
        tooltipText = t('DASHBOARD.TOOLTIP_TASK_PRIORITY_3')
    }

    return (
      <Tooltip title={tooltipText}>
        <Chip
          label={<Typography variant="body2">{label}</Typography>}
          style={{
            backgroundColor: backgroundColor,
            color: textColor,
          }}
          variant="outlined"
          size="small"
        />
      </Tooltip>
    )
  }

  const getTooltipForTaskType = (type) => {
    switch (type) {
      case 'orders':
        return t('DASHBOARD.TOOLTIP_TASK_ORDER')
      case 'offers':
        return t('DASHBOARD.TOOLTIP_TASK_OFFERS')
      case 'requests':
        return t('DASHBOARD.TOOLTIP_TASK_REQUEST')
      case 'others':
        return t('DASHBOARD.TOOLTIP_TASK_OTHERS')
      case 'survey':
        return t('DASHBOARD.TOOLTIP_TASK_SURVEY')
      default:
        return t('DASHBOARD.TOOLTIP_TASK')
    }
  }

  function renderTaskTypeText(taskTypeObj, task) {
    if (taskTypeObj && taskTypeObj.type === 'orders') {
      return (
        <Fragment>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t('DASHBOARD.START_DELIVERY')}
            {task.id && (
              <Typography
                variant="body2"
                style={{ color: 'gray', marginLeft: '8px' }}
              >
                ({task.id})
              </Typography>
            )}
          </div>
        </Fragment>
      )
    } else if (taskTypeObj && taskTypeObj.type === 'others') {
      if (task && task.type && task.type === 'others') {
        if (task && task.name) {
          if (task.name === 'CP') {
            return t('DASHBOARD.DOCUMENT_CP')
          } else if (task.name === 'EPAL') {
            return t('DASHBOARD.DOCUMENT_EPAL')
          } else {
            return t('DASHBOARD.FILL_COMPANY_DATA')
          }
        }
      } else {
        return t('DASHBOARD.TASK')
      }
    } else if (taskTypeObj && taskTypeObj.type === 'offers') {
      return (
        <Fragment>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t('DASHBOARD.REACT_TO_OFFER')}
            {task.id && (
              <Typography
                variant="body2"
                style={{ color: 'gray', marginLeft: '8px' }}
              >
                ({task.id})
              </Typography>
            )}
          </div>
        </Fragment>
      )
    } else if (taskTypeObj && taskTypeObj.type === 'requests') {
      return (
        <Fragment>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t('DASHBOARD.REACT_TO_REQUEST')}
            {task.id && (
              <Typography
                variant="body2"
                style={{ color: 'gray', marginLeft: '8px' }}
              >
                ({task.id})
              </Typography>
            )}
          </div>
        </Fragment>
      )
    } else if (taskTypeObj && taskTypeObj.type === 'survey') {
      return (
        <Fragment>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {t('DASHBOARD.COMPLETE_SURVEY') + ': ' + task.name}
          </div>
        </Fragment>
      )
    } else {
      return t('DASHBOARD.TASK')
    }
  }

  const getIconForTaskType = (type) => {
    switch (type) {
      case 'orders':
        return <LocalShippingIcon style={{ color: 'primary' }} />
      case 'offers':
        return <LocalOfferIcon style={{ color: 'primary' }} />
      case 'others':
        return <BusinessIcon style={{ color: 'primary' }} />
      case 'survey':
        return <PollIcon style={{ color: 'primary' }} />
      default:
        return <ListIcon style={{ color: 'primary' }} />
    }
  }

  const handleTaskClick = (task) => {
    if (task.type === 'survey') {
      setSelectedSurvey(task)
      setShowSurveyDialog(true)
    } else {
      window.open(task.link, '_blank')
    }
  }

  const handleCloseDialog = () => {
    setShowSurveyDialog(false)
    setSelectedSurvey(null)
  }

  return (
    <Paper
      elevation={1}
      sx={{
        background: '#FFFFFF',
        paddingTop: 0,
        borderRadius: '10px',
        color: theme.palette.primary.main,
        height: '40vh',
      }}
    >
      {(isLoading && <Loading variant={'centered'} />) || (
        <Fragment>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: '#B5DBDE',
              borderRadius: '10px 10px 0 0',
            }}
            spacing={0}
          >
            <Grid item>
              <FormatListBulletedIcon
                color="secondary"
                style={{
                  fontSize: '40px',
                  padding: '10px 0',
                }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                align="center"
                style={{
                  margin: 0,
                  color: '#333333',
                  padding: '10px 0',
                }}
              >
                {t('DASHBOARD.TASKBOARD')}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            direction="column"
            spacing={1}
            padding={1}
            sx={{
              overflowY: 'auto',
              maxHeight: 'calc(40vh - 76px)',
              borderRadius: '0 0 10px 10px',
              margin: 0,
              '& > .MuiGrid-item': {
                padding: theme.spacing(1),
              },
              width: 'calc(100% - 1px)',
            }}
          >
            <Box padding={1} width="100%">
              {tasks.length > 0 ? (
                tasks.map((taskTypeObj, idx) => (
                  <Paper
                    elevation={1}
                    variant="outlined"
                    sx={{
                      margin: theme.spacing(0.5),
                      padding: theme.spacing(1),
                      display: 'flex',
                      flexDirection: isSmallScreen ? 'column' : 'row',
                      alignItems: { sm: 'center' },
                      borderRadius: 2,
                      backgroundColor: 'white',
                    }}
                    key={idx}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexGrow: 1,
                        marginBottom: { xs: 1, sm: 0 },
                      }}
                    >
                      <Tooltip title={getTooltipForTaskType(taskTypeObj.type)}>
                        <IconButton
                          sx={{ color: customGreen }}
                          onClick={() => handleTaskClick(taskTypeObj)}
                        >
                          {getIconForTaskType(taskTypeObj.type)}
                        </IconButton>
                      </Tooltip>
                      <Typography
                        variant="body1"
                        noWrap
                        sx={{ marginRight: 2 }}
                      >
                        {renderTaskTypeText(taskTypeObj, taskTypeObj)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'row-reverse', sm: 'row' },
                        justifyContent: { xs: 'center', sm: 'flex-end' },
                        alignItems: 'center',
                        width: { sm: 'auto' },
                        marginBottom: { xs: 1, sm: 0 },
                      }}
                    >
                      {getPriorityChip(taskTypeObj.priority, taskTypeObj.datum)}
                      {taskTypeObj.type === 'survey' ? (
                        <Tooltip title={t('DASHBOARD.TASKBOARD_OPEN_SURVEY')}>
                          <IconButton
                            onClick={() => handleTaskClick(taskTypeObj)}
                            sx={{
                              color: customGreen,
                              marginLeft: { sm: 1 },
                              order: { xs: -1, sm: 0 },
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title={t('DASHBOARD.TASKBOARD_OPEN_LINK')}>
                          <IconButton
                            component="a"
                            href={taskTypeObj.link}
                            sx={{
                              color: customGreen,
                              marginLeft: { sm: 1 },
                              order: { xs: -1, sm: 0 },
                            }}
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </Paper>
                ))
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <Typography variant="body1" marginBottom={2}>
                    {t('TASKBOARD.NO_TASKS')}
                  </Typography>
                  {partner.type === 'customer' && (
                    <Button
                      onClick={() => navigate('/request')}
                      color="secondary"
                      variant="contained"
                      size="small"
                    >
                      {t('DASHBOARD.CREATE_REQUEST')}
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Fragment>
      )}
      {showSurveyDialog && selectedSurvey && (
        <SurveyParticipantDialog
          surveyId={selectedSurvey.id}
          partnerId={partner.id}
          show={showSurveyDialog}
          hide={handleCloseDialog}
          t={t}
          mode={selectedSurvey.mode}
        />
      )}
    </Paper>
  )
}

export default withTranslation()(TaskBoard)
