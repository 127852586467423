import {
  MenuBook as AdvertsIcon,
  Groups,
  LocalOffer as LocalOfferIcon,
  EventAvailable as OrdersIcon,
  Assignment as RequestsIcon,
  Gavel as TendersIcon,
} from '@mui/icons-material'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FlagIcon from '@mui/icons-material/Flag'
import LooksOneIcon from '@mui/icons-material/LooksOne'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits'
import StarsIcon from '@mui/icons-material/Stars'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Switch,
  Typography,
} from '@mui/material'
import Select from '@mui/material/Select'
import { Fragment, default as React, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { mailSettingsStateLookup } from '../../../../_constants/lookupConstants'
import { mailSettingsService } from '../../../../_services/mailSettingsService'
import { userMailSettingsService } from '../../../../_services/userMailSettingsService'
import { usersService } from '../../../../_services/usersService'
const UsersMailSettings = (props) => {
  const { t } = props

  const [isLoading, setIsLoading] = React.useState(false)
  const userObject = JSON.parse(localStorage.getItem('user'))
  const [userMailSettings, setUserMailSettings] = useState([])
  const [mailSettings, setMailSettings] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const mailSettingIcons = {
    OFFER_RECIEVED: <LocalOfferIcon color="primary" />,
    OFFER_EDIT_STORNO: <LocalOfferIcon color="primary" />,
    ORDER: <OrdersIcon color="primary" />,
    JOB: <AdvertsIcon color="primary" />,
    TENDER: <TendersIcon color="primary" />,
    RATING: <StarsIcon color="primary" />,
    SPOT: <FlagIcon color="primary" />,
    REQUEST_NEWSLETTER: <RequestsIcon color="primary" />,
    COMMUNITY: <Groups color="primary" />,
    CLAIM: <ProductionQuantityLimitsIcon color="primary" />,
    NEW_HIGHEST_OFFER: <LooksOneIcon color="primary" />,
    FILTER: <FilterAltIcon color="primary" />,
  }

  const handleUserMailSettingsChange = async (mailSettingId, isActive) => {
    const updatedUserMailSettings = await Promise.all(
      userMailSettings.map(async (userMailsetting) => {
        if (userMailsetting.id === mailSettingId) {
          await userMailSettingsService.updateUserMailSetting(
            userMailsetting.id,
            isActive,
          )
          return { ...userMailsetting, isActive }
        } else {
          return userMailsetting
        }
      }),
    )
    setUserMailSettings(updatedUserMailSettings)
  }

  const handleLanguageChange = async (event) => {
    const newLanguage = event.target.value
    setSelectedLanguage(newLanguage)
    await usersService.updateUser(userObject.user.id, {
      notificationLanguage: newLanguage,
    })
  }

  useEffect(() => {
    setIsLoading(true)
    usersService.getUser(userObject.user.id).then((userData) => {
      setIsLoading(false)
      setSelectedLanguage(userData.notificationLanguage)
    })
  }, [t])

  useEffect(() => {
    setIsLoading(true)
    mailSettingsService.getMailSettings().then((mailSettings) => {
      setMailSettings(mailSettings)
      userMailSettingsService
        .getUserMailSettingsOfUser(userObject.user.id)
        .then((userMailSettings) => {
          setIsLoading(false)
          setUserMailSettings(userMailSettings)
        })
    })
  }, [t])
  return (
    <Fragment>
      <Grid container spacing={2}>
        {mailSettings.map((mailSetting) => {
          const userMailSettingsForSetting = userMailSettings.filter(
            (userMailSetting) =>
              userMailSetting.mailSettingId === mailSetting.id,
          )
          if (
            mailSetting.isManufacturer &&
            userObject.partner.type !== 'manufacturer'
          ) {
            return null
          }
          return (
            <Grid item xs={12} sm={6} key={mailSetting.id}>
              <Paper
                style={{
                  padding: '8px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1">Off</Typography>
                <Switch
                  checked={userMailSettingsForSetting[0]?.isActive}
                  onChange={(e) =>
                    handleUserMailSettingsChange(
                      userMailSettingsForSetting[0].id,
                      e.target.checked,
                    )
                  }
                  color="primary"
                />
                <Typography variant="body1">On</Typography>
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: 'center',
                    margin: '0 8px',
                  }}
                >
                  <Typography variant="body1">
                    {mailSettingsStateLookup[mailSetting.name]}
                  </Typography>
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  {mailSettingIcons[mailSetting.name]}
                </div>
              </Paper>
            </Grid>
          )
        })}
        <Grid item xs={12} sm={6}>
          <Paper>
            <FormControl fullWidth>
              <InputLabel id="input_label">
                {t('USER_SETTINGS.NOTIFICATION_LANGUAGE')}
              </InputLabel>
              <Select
                labelId="language"
                id="language_select"
                label={t('USER_SETTINGS.NOTIFICATION_LANGUAGE')}
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                <MenuItem key="DE" value="DE">
                  {t('USER_SETTINGS.GERMAN')}
                </MenuItem>
                <MenuItem key="EN" value="EN">
                  {t('USER_SETTINGS.ENGLISH')}
                </MenuItem>
                <MenuItem key="PL" value="PL">
                  {t('USER_SETTINGS.POLISH')}
                </MenuItem>
                <MenuItem key="CS" value="CS">
                  {t('USER_SETTINGS.CZECH')}
                </MenuItem>
              </Select>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default withTranslation()(UsersMailSettings)
