import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment/moment'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import NumberFormatCustom from '../../../../_helpers/numberFormatCustom'
import { invoicesService } from '../../../../_services/invoicesService'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { FieldErrorWrapper } from '../../../FieldErrorWrapper'

const EditInvoiceDialog = (props) => {
  const { invoice, t, show, hide, forceUpdate } = props

  const valSchema = Yup.object().shape({
    invoiceNumber: Yup.string().required(t('GENERAL.REQUIRED')),
    price: Yup.number()
      .required(t('GENERAL.REQUIRED'))
      .typeError(t('GENERAL.REQUIRED')),
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        invoiceNumber: invoice.invoiceNumber,
        date: moment(invoice.date),
        direction: invoice.direction,
        type: invoice.type,
        price: invoice.price,
        description: invoice.description,
        comment: invoice.comment,
      }}
      validationSchema={valSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting = true

        const payload = {
          ...values,
          date: values.date.set({
            hour: 12,
            minute: 0,
            second: 0,
            millisecond: 0,
          }),
        }

        invoicesService
          .updateInvoice(payload, invoice.id)
          .then(() => {
            setSubmitting = false
            forceUpdate()
            hide()
          })
          .catch(function (error) {
            setSubmitting = false
            console.log(error)
          })
      }}
    >
      {({ isSubmitting, submitForm }) => (
        <Form>
          <Dialog fullWidth={true} maxWidth="xl" open={show}>
            <CustomDialogTitle
              title={<Fragment>{t('INVOICES.EDIT_INVOICE')}</Fragment>}
              onClose={() => hide()}
            />
            <DialogContent>
              <Box margin={5}>
                <Field
                  component={TextField}
                  name="invoiceNumber"
                  fullWidth
                  label={t('INVOICES.INVOICE_NUMBER')}
                  variant="outlined"
                />
              </Box>
              <Box margin={5}>
                <FieldErrorWrapper
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      label: t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.DATE'),
                      margin: 'dense',
                    },
                  }}
                  name="date"
                  fullWidth
                  component={DatePicker}
                  inputFormat="DD/MM/YYYY"
                />
              </Box>
              <Box margin={5}>
                <Field
                  component={TextField}
                  label={t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.DIRECTION')}
                  name="direction"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  select
                >
                  <MenuItem value="INBOUND" key="INBOUND">
                    Eingehend
                  </MenuItem>
                  <MenuItem value="OUTBOUND" key="OUTBOUND">
                    Ausgehend
                  </MenuItem>
                </Field>
              </Box>
              <Box margin={5}>
                <Field
                  component={TextField}
                  label={t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.TYPE')}
                  name="type"
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  select
                >
                  <MenuItem value="POSITION" key="POSITION">
                    Position
                  </MenuItem>
                  <MenuItem value="FREIGHT" key="FREIGHT">
                    Fracht
                  </MenuItem>
                  <MenuItem value="PROVISION" key="PROVISION">
                    Provision
                  </MenuItem>
                  <MenuItem value="DUTY" key="DUTY">
                    Zoll
                  </MenuItem>
                  <MenuItem value="SERVICE" key="SERVICE">
                    Service
                  </MenuItem>
                  <MenuItem value="OTHER" key="OTHER">
                    Sonstiges
                  </MenuItem>
                </Field>
              </Box>
              <Box margin={5}>
                <Field
                  component={TextField}
                  name="price"
                  fullWidth
                  label={t('INVOICES.PRICE')}
                  variant="outlined"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    inputComponent: NumberFormatCustom,
                    decimalScale: 2,
                    allowNegative: true,
                    suffix: ' \u20AC',
                    fixedDecimalScale: true,
                  }}
                />
              </Box>
              <Box margin={5}>
                <Field
                  component={TextField}
                  name="description"
                  fullWidth
                  label={t(
                    'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.DESCRIPTION',
                  )}
                  variant="outlined"
                />
              </Box>
              <Box margin={5}>
                <Field
                  component={TextField}
                  name="comment"
                  fullWidth
                  label={t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.COMMENT')}
                  variant="outlined"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  hide()
                }}
                variant="outlined"
                color="secondary"
              >
                {t('GENERAL.CANCEL')}
              </Button>
              <Button
                onClick={() => {
                  submitForm()
                }}
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="secondary"
              >
                {t('GENERAL.SUBMIT')}
              </Button>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  )
}

export default withTranslation()(EditInvoiceDialog)
