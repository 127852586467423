import moment from 'moment'

export const generalService = {
  getSkontos,
  getPaymentTerms,
  addDaysToDate,
  convertDateToCalendarWeek,
}

function getPaymentTerms(allPaymentTermsAllowed) {
  return allPaymentTermsAllowed
    ? [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
        38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55,
        56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73,
        74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
      ]
    : [0]
}

function getSkontos() {
  return [0, 1, 2, 3, 4, 5]
}

function addDaysToDate(date, days) {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

function convertDateToCalendarWeek(deliveryValue) {
  let year = moment(deliveryValue).year()
  let week = moment(deliveryValue).format('W')

  return `${year}/${week}`
}

export * from './generalService'
