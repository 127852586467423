import _ from 'lodash'
import i18n from '../i18n'

export const salutationLookup = {}
export const requestStateLookup = {}
export const communityStateLookup = {}
export const automaticOfferPositionStateLookup = {}
export const deliveryTypeLookup = {}
export const productStateLookup = {}
export const unitLookup = {}
export const unloadLookup = {}
export var countryList = {}
export const countryLookup = {}
export const countrycodeLookup = {}
export const incotermLookup = {}
export const productLookup = {}
export const categorizedProductLookup = []
export const offerStateLookup = {}
export const orderStateLookup = {}
export const claimStateLookup = {}
export const jobOfferStateLookup = {}
export const jobOfferTypeLookup = {}
export const marketplaceRequestStateLookup = {}
export const advertsExchangeRequestStateLookup = {}
export const mailSettingsStateLookup = {}
export const tenderOrderStateLookup = {}
export const tenderOrderRequestStateLookup = {}
export const requestTypeLookup = {}
export const orderTypeLookup = {}
export const claimTypeLookup = {}
export const stackingSpaceLookup = {}
export const monthLookup = {}
export const rolesLookup = {}
export const badgesLookup = {}

export const productLengthLookup = {
  1: '',
  2: '1200',
  3: '1200',
  4: '1140',
  5: '1300',
  6: '1140',
  7: '1200',
  8: '1300',
  9: '1140',
  10: '1140',
  11: '1200',
  12: '1200',
  13: '1200',
  14: '800',
  15: '1200',
  16: '1200',
  20: '1200',
  21: '1200',
  22: '1200',
  24: '600',
  28: '',
  29: '800',
  30: '800',
  31: '1200',
  32: '1200',
  33: '1000',
  34: '1200',
  35: '1000',
  36: '800',
  37: '800',
  38: '1200',
  39: '',
  40: '600',
  41: '600',
  42: '600',
  43: '',
}

export const productWidthLookup = {
  1: '',
  2: '1000',
  3: '800',
  4: '1140',
  5: '1100',
  6: '760',
  7: '1000',
  8: '1100',
  9: '1140',
  10: '1140',
  11: '800',
  12: '800',
  13: '800',
  14: '600',
  15: '1000',
  16: '800',
  20: '800',
  21: '800',
  22: '800',
  24: '800',
  28: '',
  29: '600',
  30: '600',
  31: '1000',
  32: '1000',
  33: '800',
  34: '1000',
  35: '1200',
  36: '600',
  37: '600',
  38: '1000',
  39: '',
  40: '400',
  41: '400',
  42: '400',
  43: '',
}

export const productImageLookup = {
  1: ['wood/oneway_block.jpg', 'wood/oneway_square.jpg'],
  2: 'wood/CP1.jpg',
  3: 'wood/CP2.jpg',
  4: 'wood/CP3.jpg',
  5: 'wood/CP4.jpg',
  6: 'wood/CP5.jpg',
  7: 'wood/CP6.jpg',
  8: 'wood/CP7.jpg',
  9: 'wood/CP8.jpg',
  10: 'wood/CP9.jpg',
  11: 'wood/Wooden_Collar_1-Board_1200x800x200.jpg',
  12: 'wood/Wooden_Collar_2-Board_1200x800x200.jpg',
  13: 'plastic/H1.jpg',
  14: 'plastic/H2.jpg',
  15: 'plastic/H3.jpg',
  16: 'wood/Euro_Box_Pallet.jpg',
  20: 'wood/EPAL_1.jpg',
  21: 'wood/Euro Pallet UIC.jpg',
  22: 'wood/Euro Pallet WORLD.jpg',
  24: 'wood/Dusseldorfer.jpg',
  28: 'wood/square_timber.jpg',
  29: 'wood/Wooden_Collar_1-Board_800x600x200.jpg',
  30: 'wood/Wooden_Collar_2-Board_800x600x200.jpg',
  31: 'wood/Wooden_Collar_1-Board_1200x1000x200.jpg',
  32: 'wood/Wooden_Collar_2-Board_1200x1000x200.jpg',
  33: 'plastic/H4.jpg',
  34: 'wood/EPAL_2.jpg',
  35: 'wood/EPAL_3.jpg',
  36: 'wood/EPAL_6.jpg',
  37: 'wood/EPAL_7.jpg',
  38: 'wood/DIN Industrial.jpg',
  39: 'wood/wooden_board.jpg',
  40: 'plastic/E1.jpg',
  41: 'plastic/E2.jpg',
  42: 'plastic/E3.jpg',
  43: ['wood/oneway_block.jpg', 'wood/oneway_square.jpg'],
}
export const dryingLookup = {}
export const loadCapacityLookup = {}
export const blockLookup = {}
export const stackHeightUnitLookup = {}
export const invoicingTypeLookup = {}
export const invoicingDirectionLookup = {}
export const orderAttachmentTypeLookup = {}
export const tenderOrderAttachmentTypeLookup = {}
export const externalAddressComponentTypeLookup = {}
export const indexLookup = {}

function populate() {
  countryLookup[2] = i18n.t('manual:COUNTRIES.ALBANIA')
  countryLookup[11] = i18n.t('manual:COUNTRIES.ARMENIA')
  countryLookup[14] = i18n.t('manual:COUNTRIES.AUSTRIA')
  countryLookup[15] = i18n.t('manual:COUNTRIES.AZERBAIJAN')
  countryLookup[21] = i18n.t('manual:COUNTRIES.BELGIUM')
  countryLookup[27] = i18n.t('manual:COUNTRIES.BOSNIA AND HERZEGOVINA')
  countryLookup[33] = i18n.t('manual:COUNTRIES.BULGARIA')
  countryLookup[20] = i18n.t('manual:COUNTRIES.BELARUS')
  countryLookup[52] = i18n.t('manual:COUNTRIES.CROATIA')
  countryLookup[54] = i18n.t('manual:COUNTRIES.CYPRUS')
  countryLookup[55] = i18n.t('manual:COUNTRIES.CZECH REPUBLIC')
  countryLookup[56] = i18n.t('manual:COUNTRIES.DENMARK')
  countryLookup[66] = i18n.t('manual:COUNTRIES.ESTONIA')
  countryLookup[71] = i18n.t('manual:COUNTRIES.FINLAND')
  countryLookup[72] = i18n.t('manual:COUNTRIES.FRANCE')
  countryLookup[79] = i18n.t('manual:COUNTRIES.GEORGIA')
  countryLookup[80] = i18n.t('manual:COUNTRIES.GERMANY')
  countryLookup[84] = i18n.t('manual:COUNTRIES.GREECE')
  countryLookup[97] = i18n.t('manual:COUNTRIES.HUNGARY')
  countryLookup[98] = i18n.t('manual:COUNTRIES.ICELAND')
  countryLookup[104] = i18n.t('manual:COUNTRIES.IRELAND')
  countryLookup[106] = i18n.t('manual:COUNTRIES.ITALY')
  countryLookup[117] = i18n.t('manual:COUNTRIES.KOSOVO')
  countryLookup[121] = i18n.t('manual:COUNTRIES.LATVIA')
  countryLookup[126] = i18n.t('manual:COUNTRIES.LIECHTENSTEIN')
  countryLookup[127] = i18n.t('manual:COUNTRIES.LITHUANIA')
  countryLookup[128] = i18n.t('manual:COUNTRIES.LUXEMBOURG')
  countryLookup[136] = i18n.t('manual:COUNTRIES.MALTA')
  countryLookup[144] = i18n.t('manual:COUNTRIES.MOLDOVA')
  countryLookup[147] = i18n.t('manual:COUNTRIES.MONTENEGRO')
  countryLookup[155] = i18n.t('manual:COUNTRIES.NETHERLANDS')
  countryLookup[165] = i18n.t('manual:COUNTRIES.NORWAY')
  countryLookup[176] = i18n.t('manual:COUNTRIES.POLAND')
  countryLookup[177] = i18n.t('manual:COUNTRIES.PORTUGAL')
  countryLookup[181] = i18n.t('manual:COUNTRIES.ROMANIA')
  countryLookup[182] = i18n.t('manual:COUNTRIES.RUSSIA')
  countryLookup[192] = i18n.t('manual:COUNTRIES.SERBIA')
  countryLookup[196] = i18n.t('manual:COUNTRIES.SLOVAKIA')
  countryLookup[197] = i18n.t('manual:COUNTRIES.SLOVENIA')
  countryLookup[203] = i18n.t('manual:COUNTRIES.SPAIN')
  countryLookup[211] = i18n.t('manual:COUNTRIES.SWEDEN')
  countryLookup[212] = i18n.t('manual:COUNTRIES.SWITZERLAND')
  countryLookup[223] = i18n.t('manual:COUNTRIES.TURKEY')
  countryLookup[228] = i18n.t('manual:COUNTRIES.UKRAINE')
  countryLookup[230] = i18n.t('manual:COUNTRIES.UNITED KINGDOM')

  countrycodeLookup[14] = 'AT' // Austria
  countrycodeLookup[21] = 'BE' // Belgium
  countrycodeLookup[56] = 'DK' // Denmark
  countrycodeLookup[71] = 'FI' // Finland
  countrycodeLookup[72] = 'FR' // France
  countrycodeLookup[80] = 'DE' // Germany
  countrycodeLookup[97] = 'HU' // Hungary
  countrycodeLookup[104] = 'IE' // Ireland
  countrycodeLookup[106] = 'IT' // Italy
  countrycodeLookup[155] = 'NL' // Netherlands
  countrycodeLookup[176] = 'PL' // Poland
  countrycodeLookup[197] = 'SI' // Slovenia
  countrycodeLookup[203] = 'ES' // Spain
  countrycodeLookup[211] = 'SE' // Sweden
  countrycodeLookup[212] = 'CH' // Switzerland
  countrycodeLookup[230] = 'GB' // United Kingdom

  countryList = _(countryLookup)
    .invert()
    .toPairs()
    .sortBy(0)
    .fromPairs()
    .value()
  productLookup[1] = i18n.t('manual:PRODUCTS.ONEWAYPALLET_BLOCK')
  productLookup[2] = i18n.t('manual:PRODUCTS.CP1')
  productLookup[3] = i18n.t('manual:PRODUCTS.CP2')
  productLookup[4] = i18n.t('manual:PRODUCTS.CP3')
  productLookup[5] = i18n.t('manual:PRODUCTS.CP4')
  productLookup[6] = i18n.t('manual:PRODUCTS.CP5')
  productLookup[7] = i18n.t('manual:PRODUCTS.CP6')
  productLookup[8] = i18n.t('manual:PRODUCTS.CP7')
  productLookup[9] = i18n.t('manual:PRODUCTS.CP8')
  productLookup[10] = i18n.t('manual:PRODUCTS.CP9')
  productLookup[11] = i18n.t('manual:PRODUCTS.WOODENSTOCKFRAME_1_Board')
  productLookup[12] = i18n.t('manual:PRODUCTS.WOODENSTOCKFRAME_2_Boards')
  productLookup[13] = i18n.t('manual:PRODUCTS.H1')
  productLookup[14] = i18n.t('manual:PRODUCTS.H2')
  productLookup[15] = i18n.t('manual:PRODUCTS.H3')
  productLookup[16] = i18n.t('manual:PRODUCTS.MESHPALETT')
  productLookup[20] = i18n.t('manual:PRODUCTS.EURO_EPAL')
  productLookup[21] = i18n.t('manual:PRODUCTS.EURO_UIC')
  productLookup[22] = i18n.t('manual:PRODUCTS.EURO_WORLD')
  productLookup[24] = i18n.t('manual:PRODUCTS.DUESSELDORFERPALETTE')
  productLookup[28] = i18n.t('manual:PRODUCTS.SQUARE_TIMBER')
  productLookup[29] = i18n.t('manual:PRODUCTS.WOODENSTOCKFRAME 800x600_1-BOARD')
  productLookup[30] = i18n.t(
    'manual:PRODUCTS.WOODENSTOCKFRAME 800x600_ 2-BOARDS',
  )
  productLookup[31] = i18n.t(
    'manual:PRODUCTS.WOODENSTOCKFRAME 1200x1000_1-BOARD',
  )
  productLookup[32] = i18n.t(
    'manual:PRODUCTS.WOODENSTOCKFRAME 1200x1000_2-BOARDS',
  )
  productLookup[33] = i18n.t('manual:PRODUCTS.H4')
  productLookup[34] = i18n.t('manual:PRODUCTS.EURO_EPAL_2')
  productLookup[35] = i18n.t('manual:PRODUCTS.EURO_EPAL_3')
  productLookup[36] = i18n.t('manual:PRODUCTS.EURO_EPAL_6')
  productLookup[37] = i18n.t('manual:PRODUCTS.EURO_EPAL_7')
  productLookup[38] = i18n.t('manual:PRODUCTS.DIN_INDUSTRIAL_PALLET_1200_1000')
  productLookup[39] = i18n.t('manual:PRODUCTS.WOODEN_BOARD')
  productLookup[40] = i18n.t('manual:PRODUCTS.E1')
  productLookup[41] = i18n.t('manual:PRODUCTS.E2')
  productLookup[42] = i18n.t('manual:PRODUCTS.E3')
  productLookup[43] = i18n.t('manual:PRODUCTS.ONEWAYPALLET_BLOCK_WO_SPECS')
  productLookup[44] = i18n.t('manual:PRODUCTS.ONEWAYPALLET_SQUARE')
  productLookup[45] = i18n.t('manual:PRODUCTS.ONEWAYPALLET_SQUARE_WO_SPECS')
  productLookup[46] = i18n.t('manual:PRODUCTS.EPAL_NAIL_90')
  productLookup[47] = i18n.t('manual:PRODUCTS.EPAL_NAIL_70')
  productLookup[48] = i18n.t('manual:PRODUCTS.EPAL_NAIL_55')
  productLookup[49] = i18n.t('manual:PRODUCTS.EPAL_NAIL_40')
  productLookup[50] = i18n.t('manual:PRODUCTS.PLASTIC_OWP_RUNNER')
  productLookup[51] = i18n.t('manual:PRODUCTS.PLASTIC_OWP_FEET')
  productLookup[53] = i18n.t('manual:PRODUCTS.WOOD_PALLET_LID')
  productLookup[54] = i18n.t('manual:PRODUCTS.INDIVIDUAL_PRESS_BOARD_PALLET')
  productLookup[55] = i18n.t('manual:PRODUCTS.T5')
  productLookup[56] = i18n.t('manual:PRODUCTS.LAGERKARTON_SMALL')
  productLookup[57] = i18n.t('manual:PRODUCTS.LAGERKARTON_BIG')
  productLookup[58] = i18n.t('manual:PRODUCTS.LAGERKARTON_BIG_ACROSS')
  productLookup[59] = i18n.t('manual:PRODUCTS.LAGERKARTON_MAX')
  productLookup[60] = i18n.t('manual:PRODUCTS.ORIENTED_STRAND_BOARDS')
  productLookup[61] = i18n.t('manual:PRODUCTS.EURO_PALLET_REPAIR')
  productLookup[62] = i18n.t('manual:PRODUCTS.LASHING_BELT_TWO_PART')
  productLookup[63] = i18n.t('manual:PRODUCTS.INVIDUAL_WOODENSTOCKFRAME')

  // reset to empty array in order to handle repopulates
  categorizedProductLookup.length = 0

  const customProductsArray = [1, 28, 39, 43, 44, 45]

  categorizedProductLookup.push({
    name: i18n.t('manual:PRODUCT_CATEGORIES.CUSTOM_PRODUCTS'),
    products: Object.entries(productLookup)
      .filter(
        (product) => customProductsArray.indexOf(parseInt(product[0])) !== -1,
      )
      .map((product) => {
        return {
          id: product[0],
          name: product[1],
        }
      })
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0)),
  })

  const euroPalletsArray = [20, 34, 35, 36, 37, 21, 22]

  categorizedProductLookup.push({
    name: i18n.t('manual:PRODUCT_CATEGORIES.EURO_PALLETS'),
    products: Object.entries(productLookup)
      .filter(
        (product) => euroPalletsArray.indexOf(parseInt(product[0])) !== -1,
      )
      .map((product) => {
        return {
          id: product[0],
          name: product[1],
        }
      }),
  })

  const cpPalletsArray = [2, 3, 4, 5, 6, 7, 8, 9, 10]

  categorizedProductLookup.push({
    name: i18n.t('manual:PRODUCT_CATEGORIES.CP_PALLETS'),
    products: Object.entries(productLookup)
      .filter((product) => cpPalletsArray.indexOf(parseInt(product[0])) !== -1)
      .map((product) => {
        return {
          id: product[0],
          name: product[1],
        }
      }),
  })

  const woodenStockFramesArray = [11, 12, 29, 30, 31, 32, 63]

  categorizedProductLookup.push({
    name: i18n.t('manual:PRODUCT_CATEGORIES.WOODENSTOCKFRAMES'),
    products: Object.entries(productLookup)
      .filter(
        (product) =>
          woodenStockFramesArray.indexOf(parseInt(product[0])) !== -1,
      )
      .map((product) => {
        return {
          id: product[0],
          name: product[1],
        }
      }),
  })

  const hpalletsArray = [13, 14, 15, 33]

  categorizedProductLookup.push({
    name: i18n.t('manual:PRODUCT_CATEGORIES.H_PALLETS'),
    products: Object.entries(productLookup)
      .filter((product) => hpalletsArray.indexOf(parseInt(product[0])) !== -1)
      .map((product) => {
        return {
          id: product[0],
          name: product[1],
        }
      }),
  })

  const meatBoxesArray = [40, 41, 42]

  categorizedProductLookup.push({
    name: i18n.t('manual:PRODUCT_CATEGORIES.MEAT_BOXES'),
    products: Object.entries(productLookup)
      .filter((product) => meatBoxesArray.indexOf(parseInt(product[0])) !== -1)
      .map((product) => {
        return {
          id: product[0],
          name: product[1],
        }
      }),
  })

  categorizedProductLookup.push({
    name: i18n.t('manual:PRODUCT_CATEGORIES.OTHERS'),
    products: Object.entries(productLookup)
      .filter(
        (product) =>
          customProductsArray.indexOf(parseInt(product[0])) === -1 &&
          euroPalletsArray.indexOf(parseInt(product[0])) === -1 &&
          cpPalletsArray.indexOf(parseInt(product[0])) === -1 &&
          woodenStockFramesArray.indexOf(parseInt(product[0])) === -1 &&
          hpalletsArray.indexOf(parseInt(product[0])) === -1 &&
          meatBoxesArray.indexOf(parseInt(product[0])) === -1,
      )
      .map((product) => {
        return {
          id: product[0],
          name: product[1],
        }
      }),
  })

  rolesLookup[3] = i18n.t('ROLES.ROLE_ADMINISTRATOR')
  rolesLookup[4] = i18n.t('ROLES.LOCATION_ADMINISTRATOR')
  rolesLookup[5] = i18n.t('ROLES.INVOICE_ADDRESS_ADMINISTRATOR')

  communityStateLookup.ACTIVE = i18n.t('COMMUNITY_STATES.ACTIVE')
  communityStateLookup.ARCHIVED = i18n.t('COMMUNITY_STATES.ARCHIVED')
  automaticOfferPositionStateLookup.ACTIVE = i18n.t(
    'AUTOMATIC_OFFER_POSITION_STATES.ACTIVE',
  )
  automaticOfferPositionStateLookup.ARCHIVED = i18n.t(
    'AUTOMATIC_OFFER_POSITION_STATES.ARCHIVED',
  )
  requestStateLookup.DRAFT = i18n.t('GENERAL.STATE_DRAFT')
  requestStateLookup.PUBLISHED = i18n.t('GENERAL.STATE_PUBLISHED')
  requestStateLookup.NEGOTIATION = i18n.t('GENERAL.STATE_NEGOTIATION')
  requestStateLookup.ARCHIVED = i18n.t('GENERAL.STATE_ARCHIVED')
  requestStateLookup.EXPIRED = i18n.t('GENERAL.STATE_EXPIRED')
  deliveryTypeLookup.verschachtelt = i18n.t('REQUEST.NESTED')
  deliveryTypeLookup.entschachtelt = i18n.t('REQUEST.UNNESTED')
  deliveryTypeLookup.gestapelt = i18n.t('REQUEST.STACKED')
  deliveryTypeLookup.palletiert = i18n.t('REQUEST.PALLETIZED')
  deliveryTypeLookup['NOT NESTABLE'] = i18n.t('REQUEST.NOT_NESTABLE')
  deliveryTypeLookup['NESTABLE'] = i18n.t('REQUEST.NESTABLE')
  productStateLookup.new = i18n.t('REQUEST.NEW')
  productStateLookup.used = i18n.t('REQUEST.USED')
  productStateLookup.first = i18n.t('REQUEST.FIRST_CHOICE')
  productStateLookup.second = i18n.t('REQUEST.SECOND_CHOICE')
  productStateLookup.third = i18n.t('REQUEST.THIRD_CHOICE')
  productStateLookup.defect = i18n.t('REQUEST.DEFECT')
  productStateLookup.unsorted = i18n.t('REQUEST.UNSORTED')
  unitLookup.quantity = i18n.t('REQUEST.QUANTITY')
  unitLookup.truck = i18n.t('REQUEST.TRUCK')
  unloadLookup.ANY = i18n.t('GENERAL.ANY')
  unloadLookup.RAMP = i18n.t('GENERAL.RAMP')
  unloadLookup.SIDE = i18n.t('GENERAL.SIDE')
  stackHeightUnitLookup.QUANTITY = i18n.t('GENERAL.QUANTITY')
  stackHeightUnitLookup.CM = i18n.t('GENERAL.CM')
  tenderOrderRequestStateLookup.WAITING_FOR_MANUFACTURER = i18n.t(
    'TENDER_ORDER_REQUEST_STATES.WAITING_FOR_MANUFACTURER',
  )
  tenderOrderRequestStateLookup.WAITING_FOR_CUSTOMER = i18n.t(
    'TENDER_ORDER_REQUEST_STATES.WAITING_FOR_CUSTOMER',
  )
  tenderOrderRequestStateLookup.ARCHIVED = i18n.t(
    'TENDER_ORDER_REQUEST_STATES.ARCHIVED',
  )
  tenderOrderStateLookup.ACTIVE = i18n.t('TENDER_ORDER_STATES.ACTIVE')
  tenderOrderStateLookup.ARCHIVED = i18n.t('TENDER_ORDER_STATES.ARCHIVED')
  orderStateLookup.LOADING = i18n.t('ORDER_STATES.LOADING')
  orderStateLookup.SHIPPED = i18n.t('ORDER_STATES.SHIPPED')
  orderStateLookup.RECEIVED = i18n.t('ORDER_STATES.RECEIVED')
  orderStateLookup.CONFIRMED = i18n.t('ORDER_STATES.CONFIRMED')
  orderStateLookup.INVOICED = i18n.t('ORDER_STATES.INVOICED')
  orderStateLookup.CANCELED = i18n.t('ORDER_STATES.CANCELED')
  claimStateLookup.ACTIVE = i18n.t('CLAIM_STATES.ACTIVE')
  claimStateLookup.CLOSED = i18n.t('CLAIM_STATES.CLOSED')
  jobOfferStateLookup.ACCEPTED = i18n.t('JOB_OFFER_STATES.ACCEPTED')
  jobOfferStateLookup.ACTIVE = i18n.t('JOB_OFFER_STATES.ACTIVE')
  jobOfferStateLookup.DECLINED = i18n.t('JOB_OFFER_STATES.DECLINED')
  jobOfferStateLookup.EXPIRED = i18n.t('JOB_OFFER_STATES.EXPIRED')
  jobOfferStateLookup.WAITING_FOR_PRICE = i18n.t(
    'JOB_OFFER_STATES.WAITING_FOR_PRICE',
  )
  jobOfferStateLookup.READY = i18n.t('JOB_OFFER_STATES.READY')
  jobOfferTypeLookup.JOB = i18n.t('JOB_OFFER_TYPES.JOB')
  jobOfferTypeLookup.REQUEST = i18n.t('JOB_OFFER_TYPES.REQUEST')
  jobOfferTypeLookup.EXTERNAL = i18n.t('JOB_OFFER_TYPES.EXTERNAL')
  marketplaceRequestStateLookup.SUCCESSFUL = i18n.t(
    'MARKETPLACE_STATES.OFFER_SUCCESSFULL',
  )

  badgesLookup.GREENLEAF = i18n.t('BADGE_EXPLANATION.GREEN_LEAF')
  badgesLookup.BRONZE = i18n.t('BADGE_EXPLANATION.BRONZE')
  badgesLookup.SILBER = i18n.t('BADGE_EXPLANATION.SILVER')
  badgesLookup.GOLD = i18n.t('BADGE_EXPLANATION.GOLD')

  marketplaceRequestStateLookup.SENT = i18n.t('MARKETPLACE_STATES.OFFER_SEND')
  marketplaceRequestStateLookup.NO = i18n.t('MARKETPLACE_STATES.NO_OFFER')
  advertsExchangeRequestStateLookup.SUCCESSFUL = i18n.t(
    'ADVERTS_EXCHANGE_STATES.PURCHASE_SUCCESSFULL',
  )
  advertsExchangeRequestStateLookup.SENT = i18n.t(
    'ADVERTS_EXCHANGE_STATES.INTEREST_SHOWN',
  )
  advertsExchangeRequestStateLookup.NO = i18n.t(
    'ADVERTS_EXCHANGE_STATES.INTEREST_NOT_SHOWN_YET',
  )
  mailSettingsStateLookup.OFFER_RECIEVED = i18n.t(
    'MAIL_SETTINGS.OFFER_RECIEVED',
  )
  mailSettingsStateLookup.OFFER_EDIT_STORNO = i18n.t(
    'MAIL_SETTINGS.OFFER_EDIT_STORNO',
  )
  mailSettingsStateLookup.CLAIM = i18n.t('MAIL_SETTINGS.CLAIM')
  mailSettingsStateLookup.COMMUNITY = i18n.t('MAIL_SETTINGS.COMMUNITY')
  mailSettingsStateLookup.RATING = i18n.t('MAIL_SETTINGS.RATING')
  mailSettingsStateLookup.SPOT = i18n.t('MAIL_SETTINGS.SPOT')
  mailSettingsStateLookup.ORDER = i18n.t('MAIL_SETTINGS.ORDER')
  mailSettingsStateLookup.TENDER = i18n.t('MAIL_SETTINGS.TENDER')
  mailSettingsStateLookup.JOB = i18n.t('MAIL_SETTINGS.JOB')
  mailSettingsStateLookup.REQUEST_NEWSLETTER = i18n.t(
    'MAIL_SETTINGS.REQUEST_NEWSLETTER',
  )
  mailSettingsStateLookup.FILTER = i18n.t('MAIL_SETTINGS.FILTER')
  mailSettingsStateLookup.NEW_HIGHEST_OFFER = i18n.t(
    'MAIL_SETTINGS.NEW_HIGHEST_OFFER',
  )

  offerStateLookup.ACTIVE = i18n.t('OFFER_STATES.ACTIVE')
  offerStateLookup.ARCHIVED = i18n.t('OFFER_STATES.ARCHIVED')
  offerStateLookup.EXPIRED = i18n.t('OFFER_STATES.EXPIRED')
  offerStateLookup.CANCLED = i18n.t('OFFER_STATES.CANCELED')
  /*Workaround since in the DB the offer state is named "CANCLED" (Typing error) and in the frontend correctly spelled "CANCELED". 
  The next line was added to still make it appear when using "npm run translations-scan"
  */
  i18n.t('OFFER_STATES.CANCLED')
  requestTypeLookup.TENDER = i18n.t('REQUEST_TYPES.TENDER')
  requestTypeLookup.REQUEST = i18n.t('REQUEST_TYPES.REQUEST')
  requestTypeLookup.ADVERT = i18n.t('REQUEST_TYPES.ADVERT')
  orderTypeLookup.TENDER = i18n.t('ORDER_TYPES.TENDER')
  orderTypeLookup.REQUEST = i18n.t('ORDER_TYPES.REQUEST')
  orderTypeLookup.ADVERT = i18n.t('ORDER_TYPES.ADVERT')
  orderTypeLookup.JOB = i18n.t('ORDER_TYPES.JOB')
  orderTypeLookup.SERVICE = i18n.t('ORDER_TYPES.SERVICE')
  claimTypeLookup.QUALITY = i18n.t('CLAIM_TYPES.QUALITY')
  claimTypeLookup.QUANTITY = i18n.t('CLAIM_TYPES.QUANTITY')
  claimTypeLookup.BOTH = i18n.t('CLAIM_TYPES.BOTH')
  dryingLookup.none = i18n.t('DRYING_TYPES.NONE')
  dryingLookup.normal = i18n.t('DRYING_TYPES.NORMAL')
  dryingLookup.intense = i18n.t('DRYING_TYPES.INTENSE')
  blockLookup.both = i18n.t('BLOCK_TYPES.PRESS_AND_SOLID')
  blockLookup.press = i18n.t('BLOCK_TYPES.ONLY_PRESS')
  blockLookup.solid = i18n.t('BLOCK_TYPES.ONLY_SOLID_WOOD')
  stackingSpaceLookup.none = i18n.t('STACKING_SPACE_TYPES.NONE')
  stackingSpaceLookup[1] = i18n.t('STACKING_SPACE_TYPES.ONE_LAYER')
  stackingSpaceLookup[2] = i18n.t('STACKING_SPACE_TYPES.TWO_LAYER')
  stackingSpaceLookup[3] = i18n.t('STACKING_SPACE_TYPES.THREE_LAYER')
  stackingSpaceLookup[4] = i18n.t('STACKING_SPACE_TYPES.FOUR_LAYER')
  stackingSpaceLookup[5] = i18n.t('STACKING_SPACE_TYPES.FIVE_LAYER')
  incotermLookup.EXW = i18n.t('GENERAL.EXW')
  incotermLookup.DDP = i18n.t('GENERAL.DDP')
  loadCapacityLookup.upTo500 = i18n.t('LOAD_CAPACITY.UP_TO_500_KG')
  loadCapacityLookup.upTo1000 = i18n.t('LOAD_CAPACITY.UP_TO_1000_KG')
  loadCapacityLookup.MoreThan1000 = i18n.t('LOAD_CAPACITY.MORE_THAN_1000_KG')
  salutationLookup.MR = i18n.t('SALUTATION.MR')
  salutationLookup.MS = i18n.t('SALUTATION.MS')
  invoicingTypeLookup.POSITION = i18n.t('INVOCING_TYPE.POSITION')
  invoicingTypeLookup.FREIGHT = i18n.t('INVOCING_TYPE.FREIGHT')
  invoicingTypeLookup.PROVISION = i18n.t('INVOCING_TYPE.PROVISION')
  invoicingTypeLookup.DUTY = i18n.t('INVOCING_TYPE.DUTY')
  invoicingTypeLookup.SERVICE = i18n.t('INVOCING_TYPE.SERVICE')
  invoicingTypeLookup.OTHER = i18n.t('INVOCING_TYPE.OTHER')
  invoicingDirectionLookup.INBOUND = i18n.t(
    'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.INBOUND',
  )
  invoicingDirectionLookup.OUTBOUND = i18n.t(
    'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.OUTBOUND',
  )
  orderAttachmentTypeLookup.REQUEST_ATTACHMENT = i18n.t(
    'ORDER_ATTACHMENT_TYPE.REQUEST_ATTACHMENT',
  )
  orderAttachmentTypeLookup.OFFER_ATTACHMENT = i18n.t(
    'ORDER_ATTACHMENT_TYPE.OFFER_ATTACHMENT',
  )
  orderAttachmentTypeLookup.SIGNED_DELIVERY_NOTE = i18n.t(
    'ORDER_ATTACHMENT_TYPE.SIGNED_DELIVERY_NOTE',
  )
  orderAttachmentTypeLookup.CLAIM = i18n.t('ORDER_ATTACHMENT_TYPE.CLAIM')
  orderAttachmentTypeLookup.DELIVERY_DOCUMENT = i18n.t(
    'ORDER_ATTACHMENT_TYPE.DELIVERY_DOCUMENT',
  )
  orderAttachmentTypeLookup.OTHER = i18n.t('ORDER_ATTACHMENT_TYPE.OTHER')

  tenderOrderAttachmentTypeLookup.REQUEST_ATTACHMENT = i18n.t(
    'TENDER_ORDER_ATTACHMENT_TYPE.REQUEST_ATTACHMENT',
  )
  tenderOrderAttachmentTypeLookup.OFFER_ATTACHMENT = i18n.t(
    'TENDER_ORDER_ATTACHMENT_TYPE.OFFER_ATTACHMENT',
  )
  tenderOrderAttachmentTypeLookup.DELIVERY_DOCUMENT = i18n.t(
    'TENDER_ORDER_ATTACHMENT_TYPE.DELIVERY_DOCUMENT',
  )
  tenderOrderAttachmentTypeLookup.OTHER = i18n.t(
    'TENDER_ORDER_ATTACHMENT_TYPE.OTHER',
  )

  externalAddressComponentTypeLookup.route = i18n.t(
    'EXTERNAL_ADDRESS_COMPONENT_TYPE.ROUTE',
  )
  externalAddressComponentTypeLookup.street_number = i18n.t(
    'EXTERNAL_ADDRESS_COMPONENT_TYPE.STREET_NUMBER',
  )
  externalAddressComponentTypeLookup.postal_code = i18n.t(
    'EXTERNAL_ADDRESS_COMPONENT_TYPE.POSTAL_CODE',
  )
  externalAddressComponentTypeLookup.locality = i18n.t(
    'EXTERNAL_ADDRESS_COMPONENT_TYPE.LOCALITY',
  )
  externalAddressComponentTypeLookup.country = i18n.t(
    'EXTERNAL_ADDRESS_COMPONENT_TYPE.COUNTRY',
  )

  monthLookup[0] = i18n.t('GENERAL.JANUARY')
  monthLookup[1] = i18n.t('GENERAL.FEBRUARY')
  monthLookup[2] = i18n.t('GENERAL.MARCH')
  monthLookup[3] = i18n.t('GENERAL.APRIL')
  monthLookup[4] = i18n.t('GENERAL.MAY')
  monthLookup[5] = i18n.t('GENERAL.JUNE')
  monthLookup[6] = i18n.t('GENERAL.JULY')
  monthLookup[7] = i18n.t('GENERAL.AUGUST')
  monthLookup[8] = i18n.t('GENERAL.SEPTEMBER')
  monthLookup[9] = i18n.t('GENERAL.OKTOBER')
  monthLookup[10] = i18n.t('GENERAL.NOVEMBER')
  monthLookup[11] = i18n.t('GENERAL.DECEMBER')

  indexLookup.DE_EPAL_NEW_V2 = i18n.t('INDEXES.NAMES.DE_EPAL_NEW_V1')
  indexLookup.DE_EPAL_FIRST_V2 = i18n.t('INDEXES.NAMES.DE_EPAL_FIRST_V1')
  indexLookup.DE_EPAL_SECOND_V2 = i18n.t('INDEXES.NAMES.DE_EPAL_SECOND_V1')
  indexLookup.DE_EPAL_THIRD_V2 = i18n.t('INDEXES.NAMES.DE_EPAL_THIRD_V1')
}

//populate();
i18n.on('languageChanged init', () => {
  populate()
})
