import { Settings } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  countryList,
  countryLookup,
  productLookup,
  productStateLookup,
} from '../../../../../../../_constants/lookupConstants'
import { formatToPrice } from '../../../../../../../_helpers/number-formatting'
import NumberFormatCustom from '../../../../../../../_helpers/numberFormatCustom'
import { productPriceLimitsService } from '../../../../../../../_services/productPriceLimitsService'
import { productsService } from '../../../../../../../_services/productsService'
import CustomDialogTitle from '../../../../../../CustomDialogTitle'
import { Loading } from '../../../../../../Loading'
import ProductPriceLimitZipcodeAreaDialog from './_components/ProductPriceLimitZipcodeAreaDialog'

const ProductPriceLimits = (props) => {
  const { t, productPriceLimit, hide, forceUpdate } = props

  const [isLoading, setIsLoading] = useState(false)
  const [productStates, setProductStates] = useState([])

  const [selectedCountryId, setSelectedCountryId] = useState(null)

  const valSchema = Yup.object().shape({
    productId: Yup.number()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    state: Yup.string()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
  })

  useEffect(() => {
    if (!productPriceLimit) {
      return
    }
    productsService.getProduct(productPriceLimit.productId).then((data) => {
      const states = data.productProperties
        .find((productProperty) =>
          [21, 44, 45, 68].includes(productProperty.id),
        )
        .productPropertyEnumValues.map(
          (productPropertyEnumValue) => productPropertyEnumValue.value,
        )

      setProductStates(states)
    })
  }, [productPriceLimit])

  console.log(productPriceLimit)
  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Formik
        enableReinitialize={true}
        initialValues={
          productPriceLimit
            ? {
                id: productPriceLimit.id,
                productId: productPriceLimit.productId,
                state: productPriceLimit.state,
                countries: productPriceLimit.countries,
                zipcodeAreas: productPriceLimit.zipcodeAreas,
                minPrice: productPriceLimit.minPrice,
                maxPrice: productPriceLimit.maxPrice,
                backupPurchasePrice: productPriceLimit.backupPurchasePrice,
              }
            : {
                productId: '',
                state: '',
                countries: [],
                zipcodeAreas: {},
              }
        }
        validationSchema={valSchema}
        //validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          let payload = values

          if (!_.isEmpty(values.zipcodeAreas)) {
            const cleanData = (inputData) => {
              return _.transform(
                inputData,
                (result, value, key) => {
                  // Bereiche ohne 'showSecondDigits' und mit gültigem 'priceEachKm'
                  const cleanedZones = _.transform(
                    value,
                    (zonesResult, zoneValue, zoneKey) => {
                      if (
                        (zoneValue.minPrice &&
                          parseFloat(zoneValue.minPrice) !== 0 &&
                          zoneValue.maxPrice &&
                          parseFloat(zoneValue.maxPrice) !== 0) ||
                        zoneValue.backupPurchasePrice
                      ) {
                        zonesResult[zoneKey] = _.omit(zoneValue, [
                          'showSecondDigits',
                        ])
                      }
                    },
                    {},
                  )
                  if (!_.isEmpty(cleanedZones)) {
                    result[key] = cleanedZones
                  }
                },
                {},
              )
            }

            const cleanedZipcodeAreas = cleanData(values.zipcodeAreas)

            payload.zipcodeAreas = cleanedZipcodeAreas
          }
          console.log(payload)
          if (productPriceLimit) {
            productPriceLimitsService
              .updateProductPriceLimit(productPriceLimit.id, values)
              .then((data) => {
                forceUpdate()
                hide()
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                setSubmitting = false
                setIsLoading(false)
              })
          } else {
            productPriceLimitsService
              .createProductPriceLimit(values)
              .then((data) => {
                forceUpdate()
                hide()
                hide()
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                setSubmitting = false
                setIsLoading(false)
              })
          }
        }}
      >
        {({ values, isSubmitting, submitForm, isValid, setFieldValue }) => {
          const handleCountryDelete = (countryToDelete) => () => {
            setFieldValue(
              'countries',
              values.countries.filter((country) => country !== countryToDelete),
            )
          }

          const handleCountrySelectChange = (event) => {
            const {
              target: { value },
            } = event

            let newCountries = [...values.countries]

            if (!newCountries.some((countryId) => countryId === value)) {
              newCountries.push(value)
              setFieldValue('countries', newCountries)
            }
          }

          return (
            <Dialog fullWidth={true} maxWidth="md" open={true}>
              <CustomDialogTitle
                title={t('PRODUCT_PRICE_LIMITS.CREATE_PRODUCT_PRICE_LIMIT')}
                onClose={() => {
                  hide()
                }}
              />
              <DialogContent>
                <Form>
                  <Field
                    component={TextField}
                    select
                    name="productId"
                    label={t('PRODUCT_PRICE_LIMITS.PRODUCT')}
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                    onChange={(e) => {
                      setFieldValue('productId', e.target.value)
                      productsService
                        .getProduct(e.target.value)
                        .then((data) => {
                          const states = data.productProperties
                            .find((productProperty) =>
                              [21, 44, 45, 68].includes(productProperty.id),
                            )
                            .productPropertyEnumValues.map(
                              (productPropertyEnumValue) =>
                                productPropertyEnumValue.value,
                            )

                          setProductStates(states)
                        })
                    }}
                  >
                    {Object.keys(productLookup).map((key) => (
                      <MenuItem key={key} value={key}>
                        {productLookup[key]}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    component={TextField}
                    select
                    name="state"
                    label={t('PRODUCT_PRICE_LIMITS.PRODUCT_STATE')}
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                  >
                    {Object.keys(productStates).map((key) => (
                      <MenuItem key={key} value={productStates[key]}>
                        {productStateLookup[productStates[key]]}
                      </MenuItem>
                    ))}
                  </Field>
                  <FormControl fullWidth>
                    <InputLabel sx={{ marginTop: -0.5 }} id="countries-label">
                      {t('AUTOMATIC_OFFERS.COUNTRY_RESTRICTIONS')}
                    </InputLabel>
                    <Select
                      labelId={'countries-label'}
                      name="countries"
                      margin="dense"
                      size={'small'}
                      onChange={handleCountrySelectChange}
                      renderValue={() => null}
                    >
                      {Object.entries(countryList).map((country, index) => (
                        <MenuItem key={index} value={country[1]}>
                          {country[0]}
                        </MenuItem>
                      ))}
                    </Select>
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        marginBottom: 2,
                      }}
                    >
                      {values.countries.map((countryId) => {
                        return (
                          <Chip
                            icon={
                              <IconButton
                                aria-label="settings"
                                onClick={() => setSelectedCountryId(countryId)}
                              >
                                <Settings />
                              </IconButton>
                            }
                            sx={{ margin: 1 }}
                            key={countryId}
                            label={
                              Object.entries(countryList).find((country) => {
                                return (
                                  parseInt(country[1]) === parseInt(countryId)
                                )
                              })[0]
                            }
                            onDelete={handleCountryDelete(countryId)}
                          />
                        )
                      })}
                    </Box>
                  </FormControl>
                  <Field
                    fullWidth={true}
                    component={TextField}
                    margin="dense"
                    size={'small'}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{
                      decimalScale: 2,
                      allowNegative: false,
                      suffix: ' \u20AC',
                      fixedDecimalScale: true,
                    }}
                    label={t('PRODUCT_PRICE_LIMITS.BACKUP_PURCHASE_PRICE')}
                    variant="outlined"
                    name={`backupPurchasePrice`}
                  />
                  <Field
                    fullWidth={true}
                    component={TextField}
                    margin="dense"
                    size={'small'}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{
                      decimalScale: 2,
                      allowNegative: false,
                      suffix: ' \u20AC',
                      fixedDecimalScale: true,
                    }}
                    label={t('PRODUCT_PRICE_LIMITS.MIN_PRICE')}
                    variant="outlined"
                    name={`minPrice`}
                  />
                  <Field
                    fullWidth={true}
                    component={TextField}
                    margin="dense"
                    size={'small'}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{
                      decimalScale: 2,
                      allowNegative: false,
                      suffix: ' \u20AC',
                      fixedDecimalScale: true,
                    }}
                    label={t('PRODUCT_PRICE_LIMITS.MAX_PRICE')}
                    variant="outlined"
                    name={`maxPrice`}
                  />
                  {selectedCountryId && (
                    <ProductPriceLimitZipcodeAreaDialog
                      countryId={selectedCountryId}
                      data={values}
                      submit={(zipcodeAreaData) => {
                        setFieldValue(
                          `zipcodeAreas.${selectedCountryId}`,
                          zipcodeAreaData,
                        )
                        setSelectedCountryId(null)
                      }}
                      hide={() => setSelectedCountryId(null)}
                    />
                  )}
                  {values.countries.map((countryId) => (
                    <TableContainer component={Paper}>
                      {values.zipcodeAreas[countryId] && (
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                {' '}
                                <Typography variant="h6">
                                  {countryLookup[countryId]}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead>
                            <TableRow>
                              <TableCell>PLZ</TableCell>
                              <TableCell align="right">Min</TableCell>
                              <TableCell align="right">Max</TableCell>
                              <TableCell align="right">Backup</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(values.zipcodeAreas[countryId])
                              .sort()
                              .map((zipcode) => (
                                <TableRow
                                  key={zipcode}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {zipcode}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    component="th"
                                    scope="row"
                                  >
                                    {formatToPrice(
                                      values.zipcodeAreas[countryId][zipcode]
                                        .minPrice,
                                    )}
                                  </TableCell>

                                  <TableCell
                                    align="right"
                                    component="th"
                                    scope="row"
                                  >
                                    {formatToPrice(
                                      values.zipcodeAreas[countryId][zipcode]
                                        .maxPrice,
                                    )}
                                  </TableCell>

                                  <TableCell
                                    align="right"
                                    component="th"
                                    scope="row"
                                  >
                                    {formatToPrice(
                                      values.zipcodeAreas[countryId][zipcode]
                                        .backupPurchasePrice,
                                    )}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                  ))}
                </Form>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => hide()}
                  variant="outlined"
                  color={'secondary'}
                >
                  {t('GENERAL.CANCEL')}
                </Button>
                <Button
                  onClick={() => submitForm()}
                  disabled={!isValid || isSubmitting}
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  {t('GENERAL.CONFIRM')}
                </Button>
              </DialogActions>
            </Dialog>
          )
        }}
      </Formik>
    )
  )
}

export default withTranslation()(ProductPriceLimits)
