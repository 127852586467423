import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { ordersService } from '../../../../_services/ordersService'
import DeliveryDetailsView from '../../../DeliveryDetailsView'
import { Loading } from '../../../Loading'
import OrderPaymentDetailsView from '../../../OrderPaymentDetailsView'
import { Section } from '../../../Section'
import OrderAttachments from './_components/OrderAttachments'
import OrderOtherDetailsView from './_components/OrderOtherDetailsView'
import OrderPositionsView from './_components/OrderPositions'

const Order = (props) => {
  const { orderId } = props

  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState(null)

  var userObject = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (orderId) {
      setLoading(true)
      ordersService
        .getOrder(orderId, userObject.partner.id)
        .then((data) => setOrder(data))
        .finally(() => setLoading(false))
    }
  }, [orderId])

  return (
    (loading && <Loading variant={'centered'} />) || (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Section>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <DeliveryDetailsView data={order} parentType={'ORDER'} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <OrderPaymentDetailsView data={order} parentType={'ORDER'} />
                <OrderOtherDetailsView data={order} />
              </Grid>
            </Grid>
          </Section>
          <Grid item xs={12}>
            <OrderPositionsView data={order} />
          </Grid>
          <Grid item xs={12}>
            <OrderAttachments data={order} />
          </Grid>
        </Grid>
      </Grid>
    )
  )
}

export default withTranslation()(connect()(Order))
