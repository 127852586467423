import { Box, Button, Grid, Tab, Tabs } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { alertActions } from '../../../../_actions/alertActions'
import { _applicationService } from '../../../../_services/_applicationService'
import { tradeAgreementsService } from '../../../../_services/tradeAgreementsService'
import { unitTranslationsService } from '../../../../_services/unitTranslationsService'
import AgreementDialog from '../../../AgreementDialog'
import { Loading } from '../../../Loading'
import { Section } from '../../../Section'
import BadgeManagement from './_components/BadgeManagement'
import ProductPriceLimits from './_components/ProductPriceLimits'
import Surveys from './_components/Surveys'
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}
const AdminSettings = (props) => {
  const { t, dispatch } = props
  const [tabValue, setTabValue] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [showResetDialog, setShowResetDialog] = useState(false)
  const [showHubspotSyncDialog, setShowHubspotSyncDialog] = useState(false)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return isLoading ? (
    <Loading variant={'centered'} />
  ) : (
    <Fragment>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="admin tabs">
        <Tab label={t('ADMIN.SETTINGS')} />
        <Tab label={t('ADMIN.PRODUCT_PRICE_LIMITS')} />
        <Tab label={t('ADMIN.BADGE_MANAGEMENT')} />
        <Tab label={t('ADMIN.SURVEYS')} />
      </Tabs>
      <Fragment>
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Section header={t('ADMIN.MARGIN_SETTINGS')}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: '15px 15px' }}
                  onClick={() => {
                    tradeAgreementsService
                      .getTradeAgreements()
                      .then((response) => response.blob())
                      .then((blob) => {
                        // 2. Create blob link to download
                        const url = window.URL.createObjectURL(new Blob([blob]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', 'tradeagreements.csv')
                        // 3. Append to html page
                        document.body.appendChild(link)
                        // 4. Force download
                        link.click()
                        // 5. Clean up and remove the link
                        link.parentNode.removeChild(link)
                      })
                  }}
                >
                  {t('ADMIN.MARGIN_DOWNLOAD')}
                </Button>
                <input
                  accept=".csv"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(e) => {
                    const formData = new FormData()
                    formData.append('file', e.target.files[0])
                    e.target.value = null
                    tradeAgreementsService
                      .updateTradeAgreements(formData)
                      .then((response) => {
                        if (response.ok) {
                          dispatch(
                            alertActions.info(t('ADMIN.UPLOAD_SUCCESSFUL')),
                          )
                          setTimeout(() => {
                            dispatch(alertActions.clear())
                          }, alertActions.alertTimeout)
                        } else {
                          dispatch(alertActions.error(t('ADMIN.UPLOAD_FAILED')))
                          setTimeout(() => {
                            dispatch(alertActions.clear())
                          }, alertActions.alertTimeout)
                        }
                      })
                  }}
                />
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    color="secondary"
                    component="span"
                  >
                    {t('ADMIN.MARGIN_UPLOAD')}
                  </Button>
                </label>
              </Section>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Section header={t('ADMIN.UNIT_TRANSLATION_SETTING')}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: '15px 15px' }}
                  onClick={() => {
                    unitTranslationsService
                      .getUnitTranslations()
                      .then((response) => response.blob())
                      .then((blob) => {
                        // 2. Create blob link to download
                        const url = window.URL.createObjectURL(new Blob([blob]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', 'unittranslations.csv')
                        // 3. Append to html page
                        document.body.appendChild(link)
                        // 4. Force download
                        link.click()
                        // 5. Clean up and remove the link
                        link.parentNode.removeChild(link)
                      })
                  }}
                >
                  {t('ADMIN.UNIT_TRANSLATION_DOWNLOAD')}
                </Button>
                <input
                  accept=".csv"
                  style={{ display: 'none' }}
                  id="contained-button-file-unit-translation"
                  multiple
                  type="file"
                  onChange={(e) => {
                    const formData = new FormData()
                    formData.append('file', e.target.files[0])
                    e.target.value = null
                    unitTranslationsService
                      .updateUnitTranslations(formData)
                      .then((response) => {
                        if (response.ok) {
                          dispatch(
                            alertActions.info(t('ADMIN.UPLOAD_SUCCESSFUL')),
                          )
                          setTimeout(() => {
                            dispatch(alertActions.clear())
                          }, alertActions.alertTimeout)
                        } else {
                          dispatch(alertActions.error(t('ADMIN.UPLOAD_FAILED')))
                          setTimeout(() => {
                            dispatch(alertActions.clear())
                          }, alertActions.alertTimeout)
                        }
                      })
                  }}
                />
                <label htmlFor="contained-button-file-unit-translation">
                  <Button
                    variant="contained"
                    color="secondary"
                    component="span"
                  >
                    {t('ADMIN.UNIT_TRANSLATION_UPLOAD')}
                  </Button>
                </label>
              </Section>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Section header={t('ADMIN.HUBSPOT_SYNC')}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: '15px 15px' }}
                  onClick={() => {
                    setShowHubspotSyncDialog(true)
                  }}
                >
                  {t('ADMIN.HUBSPOT_SYNC')}
                </Button>
              </Section>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Section header={t('ADMIN.DEMO_SETTINGS')}>
                <Button
                  variant="contained"
                  color="secondary"
                  style={{ margin: '15px 15px' }}
                  onClick={() => {
                    setShowResetDialog(true)
                  }}
                >
                  {t('ADMIN.RESET')}
                </Button>
              </Section>
            </Grid>
          </Grid>
        </TabPanel>
      </Fragment>
      <TabPanel value={tabValue} index={1}>
        <ProductPriceLimits />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <BadgeManagement />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <Surveys />
      </TabPanel>
      <AgreementDialog
        open={showResetDialog}
        message={t('ADMIN.ARE_YOU_SURE')}
        acceptButtonText={t('GENERAL.YES')}
        handleClose={() => {
          setShowResetDialog(false)
        }}
        agree={() => {
          setIsLoading(true)
          setShowResetDialog(false)
          _applicationService
            .reset()
            .then((data) => {
              alert(data.message)
            })
            .finally(() => setIsLoading(false))
        }}
      />
      <AgreementDialog
        open={showHubspotSyncDialog}
        message={t('ADMIN.ARE_YOU_SURE')}
        acceptButtonText={t('GENERAL.YES')}
        handleClose={() => {
          setShowHubspotSyncDialog(false)
        }}
        agree={() => {
          setIsLoading(true)
          setShowHubspotSyncDialog(false)
          _applicationService
            .hubspotSync()
            .then((data) => {
              alert(data.message)
            })
            .finally(() => setIsLoading(false))
        }}
      />
    </Fragment>
  )
}

export default withTranslation()(connect()(AdminSettings))
