import { Attachment, Groups, Queue, Repeat } from '@mui/icons-material'
import {
  Chip,
  Dialog,
  DialogContent,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import {
  categorizedProductLookup,
  deliveryTypeLookup,
  productLookup,
  productStateLookup,
  tenderOrderStateLookup,
} from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import { tenderOrdersService } from '../../../../_services/tenderOrdersService'
import ResourceList from '../../..//ResourceList/_components/ResourceList'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { InfoTooltip } from '../../../InfoTooltip'
import { PositionsList } from '../../../PositionsList'
import ViewDescription from '../../../ViewDescription'
import TenderOrderDetails from '../../../_app/_components/TenderOrderDetails'
import TenderOrderAttachmentDialog from './_components/TenderOrderAttachmentDialog'
import TenderOrderRequestForm from './_components/TenderOrderRequestForm'
import TenderOrderRequestsDialog from './_components/TenderOrderRequestsDialog'

const TenderOrders = (props) => {
  const { t } = props

  const [selectedTenderOrder, setSelectedTenderOrder] = useState(null)
  const [tenderOrders, setTenderOrders] = useState([])
  const [showRequestTenderOrderDialog, setShowRequestTenderOrderDialog] =
    useState(false)
  const [showTenderOrderRequests, setShowTenderOrderRequests] = useState(false)

  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false)

  const [forceUpdateCount, forceUpdate] = useState(0)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const userObject = JSON.parse(localStorage.getItem('user'))

  const removeTenderOrderRequest = (tenderOrderRequest) => {
    setTenderOrders(
      tenderOrders
        .filter((tenderOrder) => {
          //If there is no second Object remove the requests
          return tenderOrder.offers[1]
        })
        .map((request) => {
          if (request.id === tenderOrderRequest.request.id) {
            request.offers.splice(
              request.offers.findIndex(
                (offer) => offer.id === tenderOrderRequest.id,
              ),
              1,
            )

            var offerState = ''

            var newestOffer = request.offers[0]

            if (newestOffer.state === 'ACTIVE') {
              offerState =
                newestOffer.partnerId === userObject.partner.id
                  ? 'WAITING_FOR_OTHER'
                  : 'NEW_OFFER_RECEIVED'
            } else {
              offerState = newestOffer.state
            }

            request.offerState = offerState
          }

          return {
            ...request,
          }
        }),
    )
  }

  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)
  let defaultValueId = params.get('id')
  let defaultValueRequestId = params.get('requestId')

  return (
    <Fragment>
      <ViewDescription description={t('TENDER_ORDERS.VIEW_DESCRPTION')} />
      <Fragment>
        <ResourceList
          fetchMethod={(filterValues, pagination) =>
            tenderOrdersService.getTenderOrdersOfPartner(
              userObject.partner.id,
              filterValues,
              pagination,
              userObject.user.partnerLocationIds,
            )
          }
          forceUpdateCount={forceUpdateCount}
          filterId={'TENDER_ORDERS_V2'}
          filters={[
            {
              key: 'id.startsWith',
              label: t('TENDER_ORDERS.TENDER_ORDER_ID'),
              variant: 'textfield',
              defaultValue: defaultValueId,
            },
            {
              key: 'requestId.startsWith',
              label: t('TENDER_ORDERS.REQUEST_ID'),
              variant: 'textfield',
              defaultValue: defaultValueRequestId,
            },
            {
              key: 'positions.productId.eq',
              variant: 'categorizedCheckboxList',
              values: categorizedProductLookup.map((category) => {
                return {
                  label: category.name,
                  items: category.products.map((product) => product.id),
                }
              }),
              lookup: productLookup,
              label: t('ORDERS.PRODUCT'),
            },
            {
              key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(productStateLookup),
              lookup: productStateLookup,
              label: t('ORDERS.PRODUCT_STATE'),
            },
            {
              key: 'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(deliveryTypeLookup),
              lookup: deliveryTypeLookup,
              label: t('ORDERS.DELIVERY_TYPE'),
            },
            {
              key: 'state',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(tenderOrderStateLookup),
              lookup: tenderOrderStateLookup,
              label: t('TENDER_ORDER.STATE'),
            },
          ]}
          hint={t('TENDER_ORDERS.VIEW_DESCRPTION')}
          selectionText={t('TENDER_ORDERS.SELECT_A_CONTRACT')}
          sorting={[
            {
              key: 'id',
              default: true,
              label: t('TENDER_ORDERS.TENDER_ORDER_ID'),
            },
          ]}
          resourceHeaderRenderFunction={(tenderOrder) =>
            `${t('TENDER_ORDERS.CONTRACT')}: ${tenderOrder.id}`
          }
          listHeaderRenderFunction={(tenderOrder) => (
            <Fragment>
              {`${t('TENDER_ORDERS.CONTRACT')}: ${tenderOrder.id}`}
              <div>
                {tenderOrder.start
                  ? `${t('TENDER_ORDER.START')}${tenderOrder.start}`
                  : ''}
                {tenderOrder.start && tenderOrder.end ? ` | ` : ''}
                {tenderOrder.end
                  ? `${t('TENDER_ORDER.END')}${tenderOrder.end}`
                  : ''}
              </div>
              {tenderOrder.title && <div>{tenderOrder.title}</div>}
              <br />
              <Fragment>
                {authorize('administrator') ? (
                  <Fragment>
                    <div>
                      {`${t('ORDER.CUSTOMER')}: ${
                        tenderOrder.customerUser.firstname
                      } ${tenderOrder.customerUser.lastname} | ${
                        tenderOrder.customer.name
                      } (${tenderOrder.customerId})`}
                    </div>
                    <div>
                      {`${t('ORDER.MANUFACTURER')}: ${
                        tenderOrder.manufacturerUser.firstname
                      } ${tenderOrder.manufacturerUser.lastname} | ${
                        tenderOrder.manufacturer.name
                      } (${tenderOrder.manufacturerId})`}
                    </div>
                  </Fragment>
                ) : tenderOrder.manufacturer ? (
                  <Fragment>
                    <div>{`${t('ORDERS.MANUFACTURER')}: ${
                      tenderOrder.manufacturer.name
                    }`}</div>
                  </Fragment>
                ) : tenderOrder.customer ? (
                  <Fragment>
                    <div>{`${t('ORDERS.CUSTOMER')}: ${
                      tenderOrder.customer.name
                    }`}</div>
                  </Fragment>
                ) : (
                  <div>
                    {tenderOrder.customerUser
                      ? `${tenderOrder.customerUser.firstname} ${tenderOrder.customerUser.lastname}`
                      : ''}
                    {tenderOrder.manufacturerUser
                      ? `${tenderOrder.manufacturerUser.firstname} ${tenderOrder.manufacturerUser.lastname}`
                      : ''}
                  </div>
                )}
              </Fragment>
            </Fragment>
          )}
          headerActionRenderFunction={(tenderOrder) => {
            return (
              <Fragment>
                <Stack direction={'column'}>
                  <Stack
                    direction={isMobile ? 'row-reverse' : 'row'}
                    spacing={1}
                  >
                    {tenderOrder.community && (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: isMobile ? 'flex-start' : 'center',
                          marginRight: '10px',
                        }}
                      >
                        <InfoTooltip title={tenderOrder.community.name}>
                          <Groups color={'secondary'} />
                        </InfoTooltip>
                      </div>
                    )}
                  </Stack>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: isMobile ? 'left' : 'right',
                      alignItems: 'center',
                    }}
                  >
                    <Chip
                      size={'small'}
                      sx={{ padding: 1 }}
                      color={
                        tenderOrder.state === 'ACTIVE' ? 'success' : 'default'
                      }
                      label={tenderOrderStateLookup[tenderOrder.state]}
                    />
                  </div>
                </Stack>
              </Fragment>
            )
          }}
          contentRenderFunction={(tenderOrder) => (
            <PositionsList
              positions={tenderOrder.positions.map((pos) => {
                return { ...pos, unit: 'quantity' }
              })}
            />
          )}
          resourceRenderFunction={(tenderOrder) => (
            <TenderOrderDetails tenderOrderId={tenderOrder.id} />
          )}
          actionsRenderFunction={(tenderOrder) => {
            return [
              {
                icon: <Attachment />,
                name: t('ORDERS.ATTACHMENTS'),
                hidden: !authorize('administrator'),
                primary: false,
                onClick: async () => {
                  var data = await tenderOrdersService.getTenderOrder(
                    tenderOrder.id,
                    userObject.partner.id,
                  )
                  setSelectedTenderOrder(data)
                  setShowAttachmentDialog(true)
                },
              },
              {
                icon: <Repeat />,
                name:
                  tenderOrder.tenderOrderRequestsAmount === 0
                    ? t('TENDER_ORDERS.NO_TENDER_ORDER_REQUESTS')
                    : t('TENDER_ORDERS.SHOW_TENDER_ORDER_REQUESTS'),
                hidden: tenderOrder.tenderOrderRequestsAmount === 0,
                primary: false,
                onClick: () => {
                  tenderOrdersService
                    .getTenderOrder(
                      tenderOrder.id,
                      userObject.partner.id,
                      userObject.user.partnerLocationIds,
                    )
                    .then((data) => {
                      setSelectedTenderOrder(data)
                      setShowTenderOrderRequests(true)
                    })
                },
              },
              {
                icon: <Queue />,
                hidden:
                  tenderOrder.customerId !== userObject.partner.id ||
                  tenderOrder.state === 'ARCHIVED',
                name:
                  tenderOrder.state === 'ARCHIVED'
                    ? t('TENDER_ORDERS.COLLABORATION_ENDED')
                    : t('TENDER_ORDERS.REQUEST_TENDER_POSITIONS'),
                primary: false,
                onClick: () => {
                  tenderOrdersService
                    .getTenderOrder(
                      tenderOrder.id,
                      userObject.partner.id,
                      userObject.user.partnerLocationIds,
                    )
                    .then((data) => {
                      setSelectedTenderOrder(data)
                      setShowRequestTenderOrderDialog(true)
                    })
                },
              },
            ]
          }}
          data={tenderOrders}
          dataIdentifier={'id'}
        />

        {selectedTenderOrder ? (
          <Fragment>
            <TenderOrderRequestsDialog
              tenderOrder={selectedTenderOrder}
              removeTenderOrderRequest={removeTenderOrderRequest}
              show={showTenderOrderRequests}
              hide={() => {
                setSelectedTenderOrder(null)
                setShowTenderOrderRequests(false)
              }}
            />
            <Dialog
              onClose={() => {
                setSelectedTenderOrder(null)
                setShowRequestTenderOrderDialog(false)
              }}
              fullWidth={true}
              maxWidth="xl"
              open={showRequestTenderOrderDialog}
            >
              <CustomDialogTitle
                title={t('TENDER_ORDERS.REQUEST_POSITIONS')}
                onClose={() => {
                  setSelectedTenderOrder(null)
                  setShowRequestTenderOrderDialog(false)
                }}
              />
              <DialogContent>
                <TenderOrderRequestForm tenderOrder={selectedTenderOrder} />
              </DialogContent>
            </Dialog>
          </Fragment>
        ) : null}

        {showAttachmentDialog && (
          <TenderOrderAttachmentDialog
            tenderOrderId={selectedTenderOrder.id}
            show={showAttachmentDialog}
            hide={() => setShowAttachmentDialog(false)}
            forceUpdateCount={forceUpdateCount}
            forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
          />
        )}
      </Fragment>
    </Fragment>
  )
}

export default withTranslation()(TenderOrders)
