import { Close } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { Field, FieldArray, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { alertActions } from '../../../../../../../../_actions/alertActions'
import { _applicationService } from '../../../../../../../../_services/_applicationService'
import { surveysService } from '../../../../../../../../_services/surveysService'
import { Loading } from '../../../../../../../Loading'

const SurveyParticipantDialog = ({
  surveyId,
  partnerId,
  show,
  hide,
  t,
  mode,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    partnerId,
    answers: [],
  })
  const [activeStep, setActiveStep] = useState(0)
  const [surveyData, setSurveyData] = useState(null)

  useEffect(() => {
    const fetchSurveyData = async () => {
      if (surveyId && partnerId) {
        setIsLoading(true)
        try {
          const survey = await surveysService.getSurvey(surveyId)
          const translatedSurveyQuestions =
            await fetchTranslatedSurveyQuestions(survey.surveyQuestions)

          setSurveyData({
            ...survey,
            surveyQuestions: translatedSurveyQuestions,
          })

          let formattedAnswers = translatedSurveyQuestions.map((question) => ({
            surveyQuestionId: question.id,
            type: question.type,
            questionText: question.text,
            offeredAnswers:
              question.type === 'TEXT'
                ? []
                : question.surveyQuestionOfferedAnswers.map((answer) => ({
                    ...answer,
                    value: answer.value,
                  })),
            answer:
              question.type === 'MULTI_SELECT'
                ? []
                : question.type === 'TEXT'
                ? ''
                : null,
            offeredAnswerId: question.type === 'MULTI_SELECT' ? [] : null,
          }))

          if (mode === 'EDIT') {
            const existingAnswers =
              await surveysService.getSurveyAnswersOfPartner(
                partnerId,
                surveyId,
              )
            formattedAnswers = formattedAnswers.map((question) => {
              const relevantAnswers = existingAnswers.filter(
                (answer) =>
                  answer.surveyQuestionId === question.surveyQuestionId,
              )
              if (relevantAnswers.length > 0) {
                let answerValue
                let offeredAnswerId = null
                if (question.type === 'MULTI_SELECT') {
                  answerValue = relevantAnswers.map(
                    (answer) =>
                      (question.offeredAnswers || []).find(
                        (opt) => opt.id === answer.offeredAnswerId,
                      )?.value || '',
                  )
                  offeredAnswerId = relevantAnswers.map(
                    (answer) => answer.offeredAnswerId,
                  )
                } else if (question.type === 'TEXT') {
                  answerValue = relevantAnswers[0].answer || ''
                } else {
                  const selectedOption = (question.offeredAnswers || []).find(
                    (opt) => opt.id === relevantAnswers[0].offeredAnswerId,
                  )
                  answerValue = selectedOption ? selectedOption.value : ''
                  offeredAnswerId = relevantAnswers[0].offeredAnswerId
                }

                return {
                  ...question,
                  answer: answerValue,
                  offeredAnswerId: offeredAnswerId,
                }
              }
              return question
            })
          }

          setInitialValues({
            partnerId,
            answers: formattedAnswers,
          })
        } catch (error) {
          console.error('Error fetching survey data:', error)
          alertActions.error(t('SURVEY.FETCH_ERROR'))
        } finally {
          setIsLoading(false)
        }
      }
    }

    if (show) {
      fetchSurveyData()
    }
  }, [surveyId, partnerId, t, show, mode])

  const fetchTranslatedSurveyQuestions = async (surveyQuestions) => {
    const translatedSurveyQuestions = []

    for (const question of surveyQuestions) {
      const translatedText = await _applicationService.translateText(
        question.text,
      )
      const translatedOfferedAnswers = []

      if (question.surveyQuestionOfferedAnswers) {
        for (const answer of question.surveyQuestionOfferedAnswers) {
          const translatedAnswer = await _applicationService.translateText(
            answer.value,
          )
          translatedOfferedAnswers.push({
            ...answer,
            translatedValue: translatedAnswer,
          })
        }
      }

      translatedSurveyQuestions.push({
        ...question,
        translatedText,
        translatedOfferedAnswers,
      })
    }

    return translatedSurveyQuestions
  }
  const valSchema = Yup.object().shape({
    answers: Yup.array().of(
      Yup.object().shape({
        surveyQuestionId: Yup.number().required(),
        type: Yup.string().required(),
        questionText: Yup.string().required(),
        answer: Yup.mixed().test(
          'is-required',
          t('GENERAL.REQUIRED'),
          function (value, context) {
            const { type } = context.parent
            if (type === 'TEXT') {
              return !!value
            } else if (type === 'SELECT') {
              return value !== null
            } else if (type === 'MULTI_SELECT') {
              return Array.isArray(value) && value.length > 0
            }
            return false
          },
        ),
        offeredAnswerId: Yup.mixed().nullable(),
      }),
    ),
  })

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    try {
      const formattedValues = {
        ...values,
        answers: values.answers.flatMap((answer) => {
          if (answer.type === 'TEXT') {
            return {
              ...answer,
              offeredAnswerId: null,
            }
          } else if (answer.type === 'SELECT') {
            const selectedOption = answer.offeredAnswers.find(
              (opt) => opt.id === answer.offeredAnswerId,
            )
            return {
              ...answer,
              answer: selectedOption ? selectedOption.value : '',
            }
          } else if (answer.type === 'MULTI_SELECT') {
            return answer.answer.map((value, idx) => {
              const option = answer.offeredAnswers.find(
                (opt) => opt.value === value,
              )
              return {
                surveyQuestionId: answer.surveyQuestionId,
                type: answer.type,
                questionText: answer.questionText,
                offeredAnswers: answer.offeredAnswers,
                answer: value,
                offeredAnswerId: option ? option.id : null,
              }
            })
          }
          return answer
        }),
      }

      await surveysService.completeSurvey(surveyId, formattedValues)
      hide()
      alertActions.info(t('SURVEY.SURVEY_SAVED'))
    } catch (error) {
      alertActions.error(t('SURVEY.SAVE_ERROR'))
    } finally {
      setSubmitting(false)
    }
  }

  const renderQuestion = (surveyQuestion, answer, index) => {
    if (!surveyQuestion) {
      return null
    }

    return (
      <Box
        key={surveyQuestion.surveyQuestionId}
        sx={{
          mb: 3,
          p: 2,
          border: '1px solid #ccc',
          borderRadius: 2,
          backgroundColor: '#f9f9f9',
        }}
      >
        <Typography variant="h6" sx={{ mb: 1 }}>
          {surveyQuestion.translatedText}
        </Typography>
        <Typography variant="caption" sx={{ mb: 2, color: 'grey.600' }}>
          {surveyQuestion.text}
        </Typography>
        {surveyQuestion.type === 'SELECT' && (
          <Field name={`answers.${index}.offeredAnswerId`}>
            {({ field, form }) => (
              <RadioGroup
                {...field}
                value={field.value}
                onChange={(event) => {
                  const selectedValue = event.target.value
                  const selectedOption =
                    surveyQuestion.translatedOfferedAnswers.find(
                      (opt) => opt.id === Number(selectedValue),
                    )
                  form.setFieldValue(
                    `answers.${index}.offeredAnswerId`,
                    selectedOption ? selectedOption.id : '',
                  )
                  form.setFieldValue(
                    `answers.${index}.answer`,
                    selectedOption ? selectedOption.value : '',
                  )
                }}
              >
                {surveyQuestion.translatedOfferedAnswers.map((option) => (
                  <React.Fragment key={option.id}>
                    <FormControlLabel
                      value={option.id}
                      control={<Radio />}
                      label={option.translatedValue}
                    />
                    <Typography
                      variant="caption"
                      sx={{ ml: 4, color: 'grey.600' }}
                    >
                      {option.value}
                    </Typography>
                  </React.Fragment>
                ))}
              </RadioGroup>
            )}
          </Field>
        )}
        {surveyQuestion.type === 'MULTI_SELECT' && (
          <FieldArray
            name={`answers.${index}.answer`}
            render={(arrayHelpers) => (
              <FormGroup>
                {surveyQuestion.translatedOfferedAnswers.map((option) => (
                  <React.Fragment key={option.id}>
                    <Field name={`answers.${index}.answer`}>
                      {({ field, form }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              value={option.value}
                              checked={
                                Array.isArray(answer.answer) &&
                                answer.answer.includes(option.value)
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  arrayHelpers.push(option.value)
                                  form.setFieldValue(
                                    `answers.${index}.offeredAnswerId`,
                                    [
                                      ...(form.values.answers[index]
                                        .offeredAnswerId || []),
                                      option.id,
                                    ],
                                  )
                                } else {
                                  const idx = answer.answer.indexOf(
                                    option.value,
                                  )
                                  arrayHelpers.remove(idx)
                                  form.setFieldValue(
                                    `answers.${index}.offeredAnswerId`,
                                    form.values.answers[
                                      index
                                    ].offeredAnswerId.filter(
                                      (id) => id !== option.id,
                                    ),
                                  )
                                }
                              }}
                            />
                          }
                          label={option.translatedValue}
                        />
                      )}
                    </Field>
                    <Typography
                      variant="caption"
                      sx={{ ml: 4, color: 'grey.600' }}
                    >
                      {option.value}
                    </Typography>
                  </React.Fragment>
                ))}
              </FormGroup>
            )}
          />
        )}
        {surveyQuestion.type === 'TEXT' && (
          <Field
            component={TextField}
            name={`answers.${index}.answer`}
            fullWidth
            multiline
            rows={4}
          />
        )}
      </Box>
    )
  }

  const handleNext = (isValid) => {
    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const isStepValid = (answers, index) => {
    if (!answers[index]) return false
    const currentAnswer = answers[index]
    if (currentAnswer.type === 'TEXT') {
      return currentAnswer.answer !== ''
    } else if (currentAnswer.type === 'SELECT') {
      return currentAnswer.offeredAnswerId !== null
    } else if (currentAnswer.type === 'MULTI_SELECT') {
      return (
        Array.isArray(currentAnswer.answer) && currentAnswer.answer.length > 0
      )
    }
    return false
  }

  return (
    <Fragment>
      {isLoading ? (
        <Loading variant={'centered'} />
      ) : (
        <Formik
          validationSchema={valSchema}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, submitForm, values }) => (
            <Form>
              <Dialog
                open={show}
                onClose={hide}
                maxWidth="md"
                fullWidth
                sx={{ margin: 3, minWidth: 500 }}
              >
                <DialogTitle align="center">
                  {surveyData?.title ? surveyData?.title : t('SURVEY.TITLE')}
                  <IconButton
                    aria-label="close"
                    onClick={hide}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent sx={{ margin: 3 }}>
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {surveyData?.surveyQuestions.map((question, index) => (
                      <Step key={index}>
                        <StepLabel>
                          {t('SURVEY.QUESTION')} {index + 1}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <Grid
                    container
                    spacing={2}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                  >
                    <Grid item xs={12}>
                      {renderQuestion(
                        surveyData?.surveyQuestions[activeStep],
                        values.answers[activeStep],
                        activeStep,
                      )}
                    </Grid>
                  </Grid>
                  <Typography variant="h8" sx={{ mt: 10, color: 'grey.600' }}>
                    {t('SURVEY.AUTOMATIC_TRANSLATION')}
                  </Typography>
                </DialogContent>
                <DialogActions sx={{ margin: 3 }}>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    variant="outlined"
                    color="secondary"
                  >
                    {t('GENERAL.BACK')}
                  </Button>
                  {activeStep < values.answers.length - 1 ? (
                    <Button
                      onClick={() =>
                        handleNext(isStepValid(values.answers, activeStep))
                      }
                      variant="contained"
                      color="secondary"
                      disabled={!isStepValid(values.answers, activeStep)}
                    >
                      {t('GENERAL.NEXT')}
                    </Button>
                  ) : (
                    <Button
                      disabled={
                        isSubmitting || !isStepValid(values.answers, activeStep)
                      }
                      onClick={submitForm}
                      variant="contained"
                      color="secondary"
                    >
                      {t('GENERAL.SAVE')}
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  )
}

export default withTranslation()(SurveyParticipantDialog)
