import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import config from '../../../../_config/config.js'
import { handleResponse } from '../../../../_helpers/api'
import { authHeader } from '../../../../_helpers/auth-header'
import ResourceListLegacy from '../../../ResourceListLegacy/_components/ResourceListLegacy'
const Visitors = () => {
  const [visitors, setVisitors] = useState([])

  const [forceUpdateCount, forceUpdate] = useState(0)

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    }
    let timer

    fetch(`${config.apiUrlBase}/visitors`, requestOptions)
      .then(handleResponse)
      .then((visitors) => {
        setVisitors(visitors)
        timer = setTimeout(() => forceUpdate(forceUpdateCount + 1), 10000)
      })

    return () => clearTimeout(timer)
  }, [forceUpdateCount])

  return (
    <ResourceListLegacy
      listHeaderRenderFunction={(user) => `${user.firstname} ${user.lastname}`}
      resourceHeaderRenderFunction={(user) => `${user.firstname}`}
      contentRenderFunction={(user) =>
        `${user.partner.name} (${user.partner.id})`
      }
      resourceRenderFunction={(user) => null}
      actionsRenderFunction={() => {
        return []
      }}
      data={visitors}
      dataIdentifier={'id'}
    />
  )
}

export default withTranslation()(Visitors)
