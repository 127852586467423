import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material'
import { Field, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { countryList } from '../../_constants/lookupConstants'

import { useNavigate } from 'react-router'
import { _googleService } from '../../_services/_googleService'
import { jobOffersService } from '../../_services/jobOffersService'
import DeliveryTimes from '../DeliveryTimes'
import InvoiceAddress from '../InvoiceAddress'
import { Section } from '../Section'

const AcceptJobOfferDialog = (props) => {
  const { t, hide, forceUpdate, jobOffer, token } = props

  const userObject = JSON.parse(localStorage.getItem('user'))

  const [error, setError] = useState('')

  const [initialValues, setInitialValues] = useState({
    orderNumber: '',
    countryId: '',
    deliveryTimes: [
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: false,
        start: null,
        end: null,
      },
      {
        isActive: false,
        start: null,
        end: null,
      },
    ],
  })

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  useEffect(() => {
    setInitialValues({
      ...initialValues,
      address: jobOffer.address,
      company: jobOffer.company,
      zipcode: jobOffer.zipcode,
      place: jobOffer.place,
      countryId: jobOffer.countryId,
      deliveryTimes: jobOffer.deliveryTimes,
      isDespatchAdviceRequired: jobOffer.isDespatchAdviceRequired,
      despatchAdviceInstructions: jobOffer.despatchAdviceInstructions
        ? jobOffer.despatchAdviceInstructions
        : '',
    })
  }, [jobOffer])

  let navigate = useNavigate()

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={Yup.object()
        .shape({
          orderNumber: Yup.string().nullable(),
          address: Yup.string().required(t('GENERAL.REQUIRED')),
          company: Yup.string().required(t('GENERAL.REQUIRED')),
          partnerInvoiceLocationId: Yup.number().required(
            t('GENERAL.REQUIRED'),
          ),
        })
        .test({
          test: async function (orderDetails, context) {
            if (!orderDetails.address || initialValues.countryId !== 80) {
              return true
            }
            let validationResult =
              await _googleService.validateAddressOfLocation({
                address: orderDetails.address,
                zipcode: initialValues.zipcode,
                place: initialValues.place,
              })
            if (validationResult.address.missingComponentTypes) {
              for (
                let index = 0;
                index < validationResult.address.missingComponentTypes.length;
                index++
              ) {
                const missingComponentType =
                  validationResult.address.missingComponentTypes[index]

                switch (missingComponentType) {
                  case 'route':
                    return this.createError({
                      message: t('LITTLE.ADDRESS_MISSING'),
                      path: `address`,
                    })
                  case 'street_number':
                    return this.createError({
                      message: t('LITTLE.STREET_NUMBER_MISSING'),
                      path: `address`,
                    })
                  default:
                    break
                }
              }
            }
          },
        })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting = true
        jobOffersService
          .confirmJobOffer(
            jobOffer.id,
            {
              orderNumber: values.orderNumber,
              partnerInvoiceLocationId: values.partnerInvoiceLocationId,
              address: values.address,
              company: values.company,
              deliveryTimes: values.deliveryTimes,
              isDespatchAdviceRequired: values.isDespatchAdviceRequired,
              despatchAdviceInstructions: values.isDespatchAdviceRequired
                ? values.despatchAdviceInstructions
                : undefined,
              userId: userObject ? userObject.user.id : undefined,
            },
            token,
          )
          .then(() => setShowSuccessMessage(true))
          .catch((err) => {
            if (err === 'Offer is no longer active') {
              setError(t('JOB_OFFERS.NO_LONGER_ACTIVE'))
            } else {
              setError(err)
            }
          })
          .finally(() => {
            setSubmitting = false
          })
      }}
    >
      {({ values, isValid, submitForm, setFieldValue, isSubmitting }) => {
        return showSuccessMessage ? (
          <Dialog open={true}>
            <DialogTitle>{t('JOB_OFFERS.OFFER_ACCEPTED')}</DialogTitle>
            <DialogContent>
              {t('JOB_OFFERS.OFFER_ACCEPTED_DESCRIPTION')}
            </DialogContent>
            {jobOffer.type === 'JOB' && (
              <DialogContent>
                {t('JOB_OFFERS.OFFER_ACCEPTED_DESCRIPTION_2')}
              </DialogContent>
            )}
            <DialogActions>
              <Button
                variant={'contained'}
                color="secondary"
                onClick={() => {
                  navigate('/deliveries-customer')
                }}
              >
                {t('JOB_OFFERS.GOTO_ORDER')}
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog open={true} fullWidth={true} maxWidth={'md'}>
            <DialogTitle>{t('OFFER.ACCEPT_OFFER_DIALOG_TITLE')}</DialogTitle>

            {error ? (
              <Fragment>
                <DialogContent>
                  <Alert severity="error">{error}</Alert>{' '}
                </DialogContent>
                <DialogActions>
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => {
                      if (forceUpdate) {
                        forceUpdate()
                      }
                      hide()
                    }}
                  >
                    {t('GENERAL.BACK')}
                  </Button>
                </DialogActions>
              </Fragment>
            ) : (
              <Fragment>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Field
                        name="orderNumber"
                        label={t('OFFER.INTERNAL_ORDERNUMBER')}
                        component={TextField}
                        type="text"
                        fullWidth={true}
                        margin="normal"
                      />
                    </Grid>
                    <Section header={t('OFFER.DELIVERY_ADDRESS')}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={TextField}
                              label={t('REQUEST.DELIVERY_DETAILS.COMPANY')}
                              name={'company'}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={TextField}
                              label={t('REQUEST.DELIVERY_DETAILS.COUNTRY')}
                              disabled={true}
                              name={'countryId'}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              select
                            >
                              {Object.entries(countryList).map((country) => (
                                <MenuItem value={country[1]} key={country[0]}>
                                  {country[0]}
                                </MenuItem>
                              ))}
                            </Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              label={t('REQUEST.DELIVERY_DETAILS.ADDRESS')}
                              name={'address'}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={TextField}
                              label={t('REQUEST.DELIVERY_DETAILS.ZIP_CODE')}
                              disabled={true}
                              name={'zipcode'}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              component={TextField}
                              label={t('REQUEST.DELIVERY_DETAILS.PLACE')}
                              disabled={true}
                              name={'place'}
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} spacing={1}>
                            <Typography variant="caption">
                              {t('DELIVERY_TIMES.LOADING_TIMES')}
                            </Typography>
                            <DeliveryTimes
                              data={values.deliveryTimes}
                              formikName={'deliveryTimes'}
                              setFieldValue={setFieldValue}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="caption">
                              {t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
                            </Typography>
                            <Grid container>
                              <Grid item xs={12}>
                                <Field
                                  component={CheckboxWithLabel}
                                  type={'checkbox'}
                                  color="default"
                                  name={'isDespatchAdviceRequired'}
                                  Label={{
                                    label: t(
                                      'DELIVERY_DETAILS.IS_DESPATCH_ADVICE_REQUIRED',
                                    ),
                                  }}
                                  style={{ marginLeft: '15px' }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Field
                                  component={TextField}
                                  disabled={!values.isDespatchAdviceRequired}
                                  name={'despatchAdviceInstructions'}
                                  fullWidth
                                  margin="dense"
                                  label={t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
                                  variant="outlined"
                                  multiline
                                  rows="4"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Section>
                    <Grid item xs={12}>
                      <InvoiceAddress
                        partnerId={
                          userObject ? userObject.partner.id : undefined
                        }
                        data={values}
                        setFieldValue={setFieldValue}
                        jobOfferToken={token}
                        userId={userObject ? userObject.user.id : undefined}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => {
                      hide()
                    }}
                  >
                    {t('GENERAL.CANCEL')}
                  </Button>
                  <Button
                    variant={'contained'}
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      submitForm()
                    }}
                  >
                    {t('OFFERS.ACCEPT_BINDING_OFFER')}
                  </Button>
                </DialogActions>
              </Fragment>
            )}
          </Dialog>
        )
      }}
    </Formik>
  )
}

export default withTranslation()(AcceptJobOfferDialog)
