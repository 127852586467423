import Skeleton from '@mui/material/Skeleton'
import React from 'react'

function BarChartSkeleton() {
  return (
    <div style={{ padding: '20px' }}>
      {/* Bar Skeletons. */}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          height: '250px',
        }}
      >
        {Array(12)
          .fill(0)
          .map((_, idx) => (
            <Skeleton
              key={idx}
              variant="rectangular"
              width={30}
              height={`${50 + Math.random() * 150}px`}
              style={{ marginBottom: '20px' }}
            />
          ))}
      </div>

      {/* X-Axis Skeleton */}
      <Skeleton variant="text" width="100%" height={20} />
    </div>
  )
}

export default BarChartSkeleton
