import { Flag } from '@mui/icons-material'
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import {
  categorizedProductLookup,
  countryLookup,
  deliveryTypeLookup,
  productLookup,
  productStateLookup,
} from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import { generalService } from '../../../../_services/generalService'
import { ordersService } from '../../../../_services/ordersService'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { Loading } from '../../../Loading'
import { PositionsList } from '../../../PositionsList'
import ResourceList from '../../../ResourceList/_components/ResourceList'
import ViewDescription from '../../../ViewDescription'
import Order from '../Order'

const SpotMarketplace = (props) => {
  const { t, dispatch } = props
  const theme = useTheme()

  const isLessThanLg = useMediaQuery(theme.breakpoints.down('lg'))

  const [isLoading, setIsLoading] = useState(false)

  const [selectedOrder, setSelectedOrder] = useState(null)

  const [forceUpdateCount, forceUpdate] = useState(0)

  const [showClaimOrderDialog, setShowClaimOrderDialog] = useState(0)

  const [claimError, setClaimError] = useState(null)

  var userObject = JSON.parse(localStorage.getItem('user'))

  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)
  let defaultValueId = params.get('id')

  return (
    <Fragment>
      <ViewDescription description={t('SPOT_MARKETPLACE.VIEW_DESCRPTION')} />
      {(isLoading && <Loading variant={'centered'} />) || (
        <Fragment>
          <ResourceList
            fetchMethod={(filterValues, pagination) =>
              ordersService.getUnallocatedOrdersOfPartner(
                parseInt(userObject.partner.id),
                filterValues,
                pagination,
              )
            }
            forceUpdateCount={forceUpdateCount}
            filterId={'SPOT_MARKETPLACE'}
            filters={[
              {
                key: 'id.startsWith',
                label: t('ORDERS.ORDER_ID'),
                variant: 'textfield',
                defaultValue: defaultValueId,
                nothingFoundMessage: t('SPOT_MARKETPLACE.ORDER_MAY_ALLOCATED'),
              },
              {
                key: 'calendarWeek',
                label: t('ORDERS.CALENDAR_WEEK'),
                variant: 'calendarWeekPicker',
              },
              {
                key: 'positions.productId.eq',
                variant: 'categorizedCheckboxList',
                values: categorizedProductLookup.map((category) => {
                  return {
                    label: category.name,
                    items: category.products.map((product) => product.id),
                  }
                }),
                lookup: productLookup,
                label: t('ORDERS.PRODUCT'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(productStateLookup),
                lookup: productStateLookup,
                label: t('ORDERS.PRODUCT_STATE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(deliveryTypeLookup),
                lookup: deliveryTypeLookup,
                label: t('ORDERS.DELIVERY_TYPE'),
              },
              {
                key: 'countryId.eq',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(countryLookup),
                lookup: countryLookup,
                label: t('ORDERS.COUNTRY'),
              },
              {
                key: 'zipcode.startsWith',
                label: t('ORDERS.ZIPCODE'),
                variant: 'textfield',
              },
              {
                key: 'place.startsWith',
                label: t('ORDERS.PLACE'),
                variant: 'textfield',
              },
            ]}
            sorting={[
              {
                key: 'id',
                default: true,
                label: t('ORDERS.ORDER_ID'),
              },
              {
                key: 'deliveryValue',
                default: false,
                label: t('ORDERS.DELIVERY_DATE'),
              },
            ]}
            dataIdentifier={'id'}
            resourceHeaderRenderFunction={(order) =>
              `${t('ORDERS.ORDER')}: ${order.id}`
            }
            listHeaderRenderFunction={(order) => (
              <Fragment>
                <div>{`${t('ORDERS.ORDER')}: ${order.id}`} </div>
                <div>
                  {authorize('administrator')
                    ? `${t('ORDER.CUSTOMER')}: ${
                        order.customerUser.firstname
                      } ${order.customerUser.lastname} | ${
                        order.customer.name
                      } (${order.customer.id})`
                    : order.customerId === userObject.partner.id
                    ? `${order.customerUser.firstname} ${order.customerUser.lastname}`
                    : ''}
                </div>
                <div>
                  {`${order.zipcode} | ${order.place} | `}
                  {`${countryLookup[order.countryId]} |  ${
                    order.deliveryType === 'date'
                      ? moment(order.deliveryValue).format('DD.MM.YYYY')
                      : `${t(
                          'ORDERS.CW',
                        )} ${generalService.convertDateToCalendarWeek(
                          order.deliveryValue,
                        )}`
                  }`}
                </div>
              </Fragment>
            )}
            headerActionRenderFunction={(order) => {
              return null
            }}
            contentRenderFunction={(order) => (
              <PositionsList positions={order.positions} />
            )}
            resourceRenderFunction={(order) => {
              return <Order orderId={order.id} />
            }}
            actionsRenderFunction={(order) => {
              return [
                {
                  icon: <Flag />,
                  name: t('SPOT_MARKETPLACE.CLAIM_ORDER'),
                  primary: false,
                  onClick: async () => {
                    setSelectedOrder(order)
                    setShowClaimOrderDialog(true)
                  },
                },
              ]
            }}
          />
          <Dialog open={showClaimOrderDialog} fullScreen={isLessThanLg}>
            <CustomDialogTitle
              title={t('SPOT_MARKETPLACE.ACCEPT_ORDER_TITLE')}
              onClose={() => {
                setShowClaimOrderDialog(false)
                setClaimError(null)
              }}
            />
            {claimError ? (
              <Alert severity="error">
                <AlertTitle>{t('GENERAL.ERROR')}</AlertTitle>
                {claimError}
              </Alert>
            ) : (
              <DialogContent>
                {t('SPOT_MARKETPLACE.ACCEPT_ORDER_DESCRIPTION')}
              </DialogContent>
            )}
            {!claimError && (
              <DialogActions>
                <Button
                  variant={'outlined'}
                  color="secondary"
                  style={{ margin: '0 10px 0px 10px' }}
                  onClick={() => {
                    setShowClaimOrderDialog(false)
                    setClaimError(null)
                  }}
                >
                  {t('GENERAL.BACK')}
                </Button>
                <Button
                  variant={'contained'}
                  color="secondary"
                  style={{ margin: '0 10px 0px 10px' }}
                  onClick={() => {
                    setIsLoading(true)
                    ordersService
                      .claimOrder(
                        selectedOrder.id,
                        userObject.partner.id,
                        userObject.user.id,
                      )
                      .then((response) => {
                        setShowClaimOrderDialog(false)
                      })
                      .catch(function (error) {
                        console.log(error)
                        if (error === 'Order already allocated') {
                          setClaimError(
                            t('SPOT_MARKETPLACE.ORDER_ALREADY_ALLOCATED'),
                          )
                        } else {
                          setClaimError(error)
                        }
                      })
                      .finally(() => {
                        setIsLoading(false)
                        forceUpdate(forceUpdateCount + 1)
                      })
                  }}
                >
                  {t('SPOT_MARKETPLACE.CLAIM_ORDER')}
                </Button>
              </DialogActions>
            )}
          </Dialog>
        </Fragment>
      )}
    </Fragment>
  )
}

export default withTranslation()(connect()(SpotMarketplace))
