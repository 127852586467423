import { AddBox } from '@mui/icons-material'
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField as MuiTextField,
} from '@mui/material'
import { Field, Form, Formik, yupToFormErrors } from 'formik'
import { Autocomplete, TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { alertActions } from '../../../../../../_actions/alertActions'
import { validateLocation } from '../../../../../../_helpers/little'
import { partnersService } from '../../../../../../_services/partnersService'
import { tenderOrdersService } from '../../../../../../_services/tenderOrdersService'
import { usersService } from '../../../../../../_services/usersService'
import DeliveryDetailsMultipleLocations from '../../../../../DeliveryDetailsMultipleLocations'
import DeliveryDetailsReplacedComponentsDialog from '../../../../../DeliveryDetailsReplacedComponentsDialog'
import { FieldErrorWrapper } from '../../../../../FieldErrorWrapper'
import PaymentDetails from '../../../../../PaymentDetails'
import RegisterPartnerDialog from '../../../../../RegisterPartnerDialog'
import { Section } from '../../../../../Section'
import TenderOrderRegistrationPositions from './_components/TenderOrderRegistrationPositions'

const TenderOrderRegistration = (props) => {
  const { t, dispatch } = props

  const [customers, setCustomers] = useState([])
  const [customerUsers, setCustomerUsers] = useState([])
  const [selectedCustomerId, setSelectedCustomerId] = useState()
  const [manufacturers, setManufacturers] = useState([])
  const [manufacturerUsers, setManufacturerUsers] = useState([])
  const [selectedManufacturerId, setSelectedManufacturerId] = useState()
  const [registerPartnerOpen, setRegisterPartnerOpen] = React.useState(false)

  const [showHasReplacedComponentsDialog, setShowHasReplacedComponentsDialog] =
    useState(false)
  const [externalAdressValidationResult, setExternalAdressValidationResult] =
    useState(false)

  const [geoData, setGeoData] = useState({})

  useEffect(() => {
    partnersService.getPartners().then((data) => {
      setCustomers(
        data.map((partner) => ({
          id: partner.id,
          name: partner.name,
          place: partner.place,
        })),
      )
      setManufacturers(
        data
          .filter((partner) => partner.type === 'manufacturer')
          .map((partner) => ({
            id: partner.id,
            name: partner.name,
            place: partner.place,
          })),
      )
    })
  }, [registerPartnerOpen])
  useEffect(() => {
    if (selectedCustomerId) {
      usersService.getUsersOfPartner(selectedCustomerId).then((data) => {
        setCustomerUsers(
          data.map((user) => ({
            id: user.id,
            name: user.email,
          })),
        )
      })
    }
  }, [selectedCustomerId])

  useEffect(() => {
    if (selectedManufacturerId) {
      usersService.getUsersOfPartner(selectedManufacturerId).then((data) => {
        setManufacturerUsers(
          data.map((user) => ({
            id: user.id,
            name: user.email,
          })),
        )
      })
    }
  }, [selectedManufacturerId])

  const initialValues = {
    selectedPartnerLocations: [''],
    customerId: '',
    customerUserId: '',
    manufacturerId: '',
    manufacturerUserId: '',
    generalInformation: {
      deliveryDetails: {
        locations: [
          {
            id: 1,
            company: '',
            address: '',
            zipcode: '',
            place: '',
            countryId: 80,
            deliveryInformations: '',
            deliveryTimes: [
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
            ],
            positions: [],
          },
        ],
      },
      paymentDetails: {
        paymentTerm: 14,
        skonto: 0,
        skontoPeriod: null,
      },
      otherDetails: {
        title: '',
        start: null,
        end: null,
      },
    },
    positions: [],
    deliveries: [],
  }

  const valSchema = Yup.object().shape({
    customerId: Yup.string().required(t('GENERAL.REQUIRED')),
    customerUserId: Yup.string().required(t('GENERAL.REQUIRED')),
    manufacturerId: Yup.string().required(t('GENERAL.REQUIRED')),
    manufacturerUserId: Yup.string().required(t('GENERAL.REQUIRED')),
    generalInformation: Yup.object().shape({
      deliveryDetails: Yup.object().shape({
        locations: Yup.array()
          .of(
            Yup.object()
              .shape({
                company: Yup.string().required(t('GENERAL.REQUIRED')),
                address: Yup.string().required(t('GENERAL.REQUIRED')),
                zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
                place: Yup.string().required(t('GENERAL.REQUIRED')),
                countryId: Yup.number().required(t('GENERAL.REQUIRED')),
                deliveryInformation: Yup.string().nullable(),
                positions: Yup.array().of(
                  Yup.object().shape({
                    amount: Yup.mixed().when('checked', ([checked], schema) => {
                      return checked
                        ? Yup.number()
                            .required(t('GENERAL.REQUIRED'))
                            .typeError(t('GENERAL.REQUIRED'))
                            .min(0.1, t('GENERAL.GREATER_THAN_0'))
                            .test({
                              message: t('GENERAL.TOO_MUCH'),
                              test: function (v) {
                                if (
                                  this.parent.productUnitId === 3 &&
                                  parseFloat(v) > 200
                                ) {
                                  return false
                                } else {
                                  return true
                                }
                              },
                            })
                        : Yup.string().typeError(t('GENERAL.REQUIRED'))
                    }),
                    purchasePrice: Yup.mixed().when(
                      'checked',
                      ([checked], schema) => {
                        return checked
                          ? Yup.number()
                              .required(t('GENERAL.REQUIRED'))
                              .typeError(t('GENERAL.REQUIRED'))
                              .min(0.01, t('GENERAL.GREATER_THAN_0'))
                          : Yup.string().typeError(t('GENERAL.REQUIRED'))
                      },
                    ),
                    salesPrice: Yup.mixed().when('checked', {
                      is: true,
                      then: Yup.number()
                        .required(t('GENERAL.REQUIRED'))
                        .typeError(t('GENERAL.REQUIRED'))
                        .min(0.01, t('GENERAL.GREATER_THAN_0')),
                      otherwise: Yup.string().typeError(t('GENERAL.REQUIRED')),
                    }),
                  }),
                ),
              })
              .test({
                test: async function (location, context) {
                  let newGeoData = { ...geoData }
                  return await validateLocation(
                    location,
                    context,
                    `generalInformation.deliveryDetails.locations[${context.parent.findIndex(
                      (locationInValues) => locationInValues.id === location.id,
                    )}]`,
                    setExternalAdressValidationResult,
                    setShowHasReplacedComponentsDialog,
                    (validationResult) => {
                      newGeoData[location.id] =
                        validationResult.geocode.location
                      setGeoData(newGeoData)
                    },
                    this,
                    ['route', 'street_number'],
                  )
                },
              }),
          )
          .min(1, t('GENERAL.MIN_1')),
      }),
      paymentDetails: Yup.object().shape({
        paymentTerm: Yup.number()
          .min(0, t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        skonto: Yup.number()
          .min(0, t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
      }),
      otherDetails: Yup.object().shape({
        start: Yup.date()
          .typeError(t('GENERAL.INVALID_DATE'))
          .required(t('GENERAL.REQUIRED')),
        end: Yup.date()
          .typeError(t('GENERAL.INVALID_DATE'))
          .required(t('GENERAL.REQUIRED'))
          .test('end', t('TENDER.INVALID_DATE_REF_START'), function (end) {
            if (this.resolve(Yup.ref('start')) && end) {
              return (
                moment(this.resolve(Yup.ref('start'))).diff(
                  moment(end),
                  'days',
                ) <= 0
              )
            } else {
              return true
            }
          }),
      }),
    }),
    positions: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required(t('GENERAL.REQUIRED')),
          productId: Yup.string().required(t('GENERAL.REQUIRED')),
          productUnitId: Yup.number().required(t('GENERAL.REQUIRED')),
        }),
      )
      .min(1, t('GENERAL.MIN_1')),
  })

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validateOnBlur={false}
        validateOnChange={false}
        validate={(values) => {
          return valSchema
            .validate(values, { abortEarly: false, context: values })
            .then(function (value) {})
            .catch((err) => {
              console.log(err.stack)
              return yupToFormErrors(err)
            })
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let requestBody = {
            ...values,
            generalInformation: {
              ...values.generalInformation,
              deliveryDetails: {
                ...values.generalInformation.deliveryDetails,
                locations:
                  values.generalInformation.deliveryDetails.locations.map(
                    (location) => {
                      return {
                        ...location,
                        geoData: geoData[location.id],
                      }
                    },
                  ),
              },
            },
          }

          tenderOrdersService
            .createTenderOrder(requestBody)
            .then((data) => {
              setSubmitting(false)
              resetForm(initialValues)
              setSelectedCustomerId()
              setSelectedManufacturerId()
              dispatch(
                alertActions.info(t('ORDER_REGISTRATION.PUBLISH_SUCCESSFUL')),
              )
              setTimeout(() => {
                dispatch(alertActions.clear())
              }, alertActions.alertTimeout)
            })
            .catch(function (error) {
              setSubmitting(false)
              console.log(error)
              dispatch(alertActions.error(error))
              setTimeout(() => {
                dispatch(alertActions.clear())
              }, alertActions.alertTimeout)
            })
        }}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          setFieldError,
          setFieldTouched,
          errors,
          handleBlur,
          touched,
          setValues,
        }) => (
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Section header={t('REQUEST.GENERAL_INFORMATION')}>
                  <Grid container spacing={1}>
                    <Grid container spacing={1} item xs={12}>
                      <Grid
                        container
                        item
                        xs={12}
                        sm={3}
                        justifyContent={'flex-end'}
                      >
                        <Grid item xs>
                          <Field
                            name="customerId"
                            component={Autocomplete}
                            options={customers.map((customer) => customer.id)}
                            getOptionLabel={(option) => {
                              if (!option) {
                                return ''
                              }
                              let partner = customers.find(
                                (customer) => customer.id === option,
                              )

                              return `${partner.name} (${partner.id}) - ${partner.place}`
                            }}
                            onChange={(event, value) => {
                              setFieldValue('customerId', value)
                              setSelectedCustomerId(value)
                            }}
                            disabled={values.tenderOrderId}
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                // We have to manually set the corresponding fields on the input component
                                name="customerId"
                                error={
                                  touched['customerId'] &&
                                  !!errors['customerId']
                                }
                                helperText={errors['customerId']}
                                label={t('ORDER_REGISTRATION.CUSTOMER')}
                                variant="outlined"
                                margin={'dense'}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{ alignSelf: 'center', paddingLeft: '5px' }}
                        >
                          <IconButton
                            size={'small'}
                            onClick={(e) => {
                              setRegisterPartnerOpen(true)
                            }}
                          >
                            <AddBox />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Field
                          component={TextField}
                          label={t('ORDER_REGISTRATION.CUSTOMER_USER')}
                          disabled={values.tenderOrderId}
                          name={'customerUserId'}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          select
                        >
                          {customerUsers.map((customerUser) => (
                            <MenuItem
                              value={customerUser.id}
                              key={customerUser.id}
                            >
                              {customerUser.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Field
                          name="manufacturerId"
                          component={Autocomplete}
                          options={manufacturers.map(
                            (manufacturer) => manufacturer.id,
                          )}
                          getOptionLabel={(option) => {
                            if (!option) {
                              return ''
                            }

                            let partner = manufacturers.find(
                              (manufacturer) => manufacturer.id === option,
                            )

                            return `${partner.name} (${partner.id}) - ${partner.place}`
                          }}
                          onChange={(event, value) => {
                            setFieldValue('manufacturerId', value)
                            setSelectedManufacturerId(value)
                          }}
                          disabled={values.tenderOrderId}
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              // We have to manually set the corresponding fields on the input component
                              name="manufacturerId"
                              error={
                                touched['manufacturerId'] &&
                                !!errors['manufacturerId']
                              }
                              helperText={errors['manufacturerId']}
                              label={t('ORDER_REGISTRATION.MANUFACTURER')}
                              variant="outlined"
                              margin={'dense'}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <Field
                          component={TextField}
                          label={t('ORDER_REGISTRATION.MANUFACTURER_USER')}
                          disabled={values.tenderOrderId}
                          name={'manufacturerUserId'}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          select
                        >
                          {manufacturerUsers.map((manufacturerUser) => (
                            <MenuItem
                              value={manufacturerUser.id}
                              key={manufacturerUser.id}
                            >
                              {manufacturerUser.name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DeliveryDetailsMultipleLocations
                        readOnly={
                          values.state === 'NEGOTIATION' ||
                          values.state === 'ARCHIVED'
                        }
                        data={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <PaymentDetails
                        data={values}
                        setFieldValue={setFieldValue}
                        readOnly={
                          values.state === 'NEGOTIATION' ||
                          values.state === 'ARCHIVED'
                        }
                      />
                      <Section header={t('REQUEST.OTHER_DETAILS')}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t('REQUEST.TITLE')}
                          variant="outlined"
                          name="generalInformation.otherDetails.title"
                        />
                        <FieldErrorWrapper
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              label: t('REQUEST.COLLOBORATION_START'),
                              margin: 'dense',
                            },
                          }}
                          disabled={
                            values.state === 'NEGOTIATION' ||
                            values.state === 'ARCHIVED'
                          }
                          name="generalInformation.otherDetails.start"
                          component={DatePicker}
                          inputFormat="DD.MM.YYYY"
                          minDate={moment()}
                        />
                        <FieldErrorWrapper
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              label: t('REQUEST.COLLOBORATION_END'),
                              margin: 'dense',
                            },
                          }}
                          disabled={
                            values.state === 'NEGOTIATION' ||
                            values.state === 'ARCHIVED'
                          }
                          name="generalInformation.otherDetails.end"
                          component={DatePicker}
                          inputFormat="DD.MM.YYYY"
                          minDate={moment()}
                        />
                      </Section>
                    </Grid>
                  </Grid>
                </Section>
              </Grid>
              <Grid item xs={12}>
                <TenderOrderRegistrationPositions
                  data={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
                style={{ margin: '0 0 15px 15px' }}
                type={'submit'}
              >
                {t('REQUEST.PUBLISH')}
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                component={Link}
                to="/order-registration"
                style={{ margin: '0 0 15px 15px' }}
                disabled={isSubmitting}
              >
                {t('GENERAL.CANCEL')}
              </Button>
            </Grid>
            {showHasReplacedComponentsDialog && (
              <DeliveryDetailsReplacedComponentsDialog
                setAddress={() => {
                  let replacedAddressComponents =
                    externalAdressValidationResult.address.addressComponents.filter(
                      (addressComponent) => addressComponent.replaced,
                    )

                  replacedAddressComponents.forEach((addressComponent) => {
                    switch (addressComponent.componentType) {
                      case 'route':
                      case 'street_number':
                        let adress =
                          externalAdressValidationResult.address.addressComponents.find(
                            (addressComponent) =>
                              addressComponent.componentType === 'route',
                          )
                        let streetNumber =
                          externalAdressValidationResult.address.addressComponents.find(
                            (addressComponent) =>
                              addressComponent.componentType === 'streetNumber',
                          )
                        setFieldValue(
                          `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                            (location) =>
                              location.id ===
                              externalAdressValidationResult.locationId,
                          )}].address`,
                          `${adress} ${streetNumber}`,
                        )
                        break
                      case 'postal_code':
                        setFieldValue(
                          `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                            (location) =>
                              location.id ===
                              externalAdressValidationResult.locationId,
                          )}].zipcode`,
                          addressComponent.componentName.text,
                        )
                        break
                      case 'locality':
                        setFieldValue(
                          `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                            (location) =>
                              location.id ===
                              externalAdressValidationResult.locationId,
                          )}].place`,
                          addressComponent.componentName.text,
                        )
                        break
                      // case 'country':
                      //   break
                      default:
                        break
                    }
                    setFieldValue()
                  })
                }}
                addressValidationResult={externalAdressValidationResult}
                hide={() => setShowHasReplacedComponentsDialog(false)}
              />
            )}
          </Form>
        )}
      </Formik>
      <RegisterPartnerDialog
        open={registerPartnerOpen}
        close={() => setRegisterPartnerOpen(false)}
      />
    </Fragment>
  )
}

export default withTranslation()(connect()(TenderOrderRegistration))
