import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import React from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { countryList } from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import { partnerInvoiceLocationsService } from '../../../../_services/partnerInvoiceLocationsService'
import CustomDialogTitle from '../../../CustomDialogTitle'
import GoogleAutocomplete from '../../../GoogleAutocomplete'
import { Loading } from '../../../Loading'

const CreatePartnerInvoiceLocationDialog = (props) => {
  const { t, hide, open, partnerId, jobOfferToken } = props

  const [isLoading, setIsLoading] = React.useState(false)

  const valSchema = Yup.object().shape({
    taxId: Yup.string().required(t('GENERAL.REQUIRED')),
    company: Yup.string().required(t('GENERAL.REQUIRED')),
    address: Yup.string().required(t('GENERAL.REQUIRED')),
    zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
    place: Yup.string().required(t('GENERAL.REQUIRED')),
    countryId: Yup.number().required(t('GENERAL.REQUIRED')),
    contactPerson: Yup.string().nullable(),
    additionalAddressLine: Yup.string().nullable(),
    email: Yup.string()
      .email(t('GENERAL.INVALID_EMAIL'))
      .required(t('GENERAL.REQUIRED')),
    reference: Yup.string().nullable(),
  })

  let initialValues = {
    company: '',
    countryId: '',
    address: '',
    zipcode: '',
    place: '',
    contactPerson: '',
    additionalAddressLine: '',
  }

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={valSchema}
        //validateOnBlur={false}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setIsLoading(true)
          partnerInvoiceLocationsService
            .createPartnerInvoiceLocation(
              {
                ...values,
                partnerId: partnerId,
              },
              jobOfferToken,
            )
            .then(() => {
              resetForm(initialValues)
              hide()
            })
            .catch(function (error) {
              console.log(error)
            })
            .finally(() => {
              setSubmitting = false
              setIsLoading(false)
            })
        }}
      >
        {({ isSubmitting, submitForm, resetForm, setFieldValue }) => (
          <Dialog
            onClose={() => {
              resetForm(initialValues)
              hide()
            }}
            fullWidth={true}
            maxWidth="md"
            open={open}
          >
            <CustomDialogTitle
              title={t('PARTNER_LOCATION_DIALOG.CREATE_INVOICE_ADDRESS')}
              onClose={() => {
                resetForm(initialValues)
                hide()
              }}
            />
            <DialogContent>
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      label={t('REQUEST.DELIVERY_DETAILS.COMPANY')}
                      name={'company'}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      label={t('REQUEST.DELIVERY_DETAILS.COUNTRY')}
                      name={'countryId'}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      select
                    >
                      {Object.entries(countryList).map((country) => (
                        <MenuItem value={country[1]} key={country[0]}>
                          {country[0]}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PARTNER_SETTINGS.TAX_ID')}
                      variant="outlined"
                      name={'taxId'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label={t('REQUEST.DELIVERY_DETAILS.CONTACT_PERSON')}
                      name={'contactPerson'}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <GoogleAutocomplete
                      setFieldValue={setFieldValue}
                      formikName={'address'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      label={t(
                        'REQUEST.DELIVERY_DETAILS.ADDITIONAL_ADDRESS_LINE',
                      )}
                      name={'additionalAddressLine'}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PARTNER_SETTINGS.INVOICE_EMAIL')}
                      variant="outlined"
                      name={'email'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PARTNER_SETTINGS.INVOICE_REFERENCE')}
                      variant="outlined"
                      name={'reference'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {authorize('administrator') && (
                      <Field
                        component={TextField}
                        fullWidth
                        margin="dense"
                        label={t(
                          'REQUEST.DELIVERY_DETAILS.ADDITIONAL_INVOICE_TEXT',
                        )}
                        variant="outlined"
                        multiline
                        rows="4"
                        name={'additionalInvoiceText'}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      label={t('REQUEST.DELIVERY_DETAILS.ZIP_CODE')}
                      name={'zipcode'}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      label={t('REQUEST.DELIVERY_DETAILS.PLACE')}
                      name={'place'}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  resetForm(initialValues)
                  hide()
                }}
              >
                {t('PARTNER_LOCATION_DIALOG.CANCEL')}
              </Button>
              <Button
                disabled={isSubmitting}
                onClick={() => submitForm()}
                variant="contained"
                color="secondary"
              >
                {t('PARTNER_LOCATION_DIALOG.SAVE')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    )
  )
}

export default withTranslation()(CreatePartnerInvoiceLocationDialog)
