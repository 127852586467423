import { Chip } from '@mui/material'
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import config from '../../../../_config/config.js'
import { handleResponse } from '../../../../_helpers/api'
import { authHeader } from '../../../../_helpers/auth-header'
import ResourceListLegacy from '../../../ResourceListLegacy/_components/ResourceListLegacy'
const APIOverview = (props) => {
  const { t } = props
  const [routes, setRoutes] = React.useState([])

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: authHeader(),
    }
    return fetch(`${config.apiUrlBase}/api`, requestOptions)
      .then(handleResponse)
      .then((routes) => {
        setRoutes(routes)
      })
  }, [t])

  return (
    <ResourceListLegacy
      listHeaderRenderFunction={(route) => `${route.route}`}
      resourceHeaderRenderFunction={(route) => `${route.route}`}
      contentRenderFunction={(route) => <Chip label={route.method} />}
      resourceRenderFunction={(route) => null}
      actionsRenderFunction={() => {
        return []
      }}
      data={routes}
      dataIdentifier={'id'}
    />
  )
}

export default withTranslation()(APIOverview)
