import { Grid } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import FormattedPrice from '../../../../../FormattedPrice'
import PositionCard from '../../../../../PositionCard'
import { Section } from '../../../../../Section'

const OrderPositions = ({ data, t }) => {
  return (
    <Section header={t('ORDER.POSITIONS')}>
      <Grid container spacing={3} direction="row" alignItems="stretch">
        {data.positions
          .filter((pos) => pos.amount > 0)
          .map((pos) => (
            <Grid item xs={12} sm={6} key={pos.id}>
              <PositionCard
                data={pos}
                readOnly={true}
                showAmount={true}
                showImage={true}
                showProductDetails={true}
                imageSize={'small'}
              >
                {<FormattedPrice pos={pos} />}
              </PositionCard>
            </Grid>
          ))}
      </Grid>
    </Section>
  )
}

export default withTranslation()(OrderPositions)
