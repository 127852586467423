import { useTheme } from '@emotion/react'
import { Lock } from '@mui/icons-material'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  Tooltip as MUITooltip,
  Slider,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { groupBy, map } from 'lodash'
import moment from 'moment'
import {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  CartesianGrid,
  LabelList,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { indexLookup } from '../../../../_constants/lookupConstants'
import { generateColorMap } from '../../../../_helpers/little'
import { formatToNumber } from '../../../../_helpers/number-formatting'
import { _proxiesService } from '../../../../_services/_proxiesService'
import { Loading } from '../../../Loading'
import IndexesRendererMobile from './_components/IndexesRendererMobile'

const prepareDataForChart = (rawData, selectedIdentifiers) => {
  // Gruppieren der Daten nach dem Datum (ohne Zeit)
  const groupedByDate = groupBy(rawData, (item) => item.timestamp.split('T')[0])

  // Transformieren der gruppierten Daten in das gewünschte Format
  return map(groupedByDate, (values, timestamp) => {
    // Starte mit dem timestamp im resultierenden Objekt
    const result = { timestamp }

    // Durchlaufe jede Gruppe, um die mediane der ausgewählten Identifiers hinzuzufügen
    values.forEach((item) => {
      if (selectedIdentifiers.includes(item.identifier)) {
        result[item.identifier] = {
          indexValue: item.value.indexValue,
          // range: [item.value.min, item.value.max],
          difference: item.value.difference,
        }
      }
    })

    return result
  })
}

const Indexes = (props) => {
  const { t } = props

  const userObject = JSON.parse(localStorage.getItem('user'))

  const allIdentifiers = Object.keys(indexLookup)

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedIdentifiers, setSelectedIdentifiers] = useState(
    userObject.partner.hasAccessToIndex
      ? [allIdentifiers[0]]
      : userObject.partner.activeTenderIndexes
      ? [userObject.partner.activeTenderIndexes[0]]
      : [],
  )
  const [dateFrom, setDateFrom] = useState(
    moment().subtract(1, 'year').startOf('month'),
  )
  const [dateTo, setDateTo] = useState(moment().endOf('month'))

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload // Angenommen, dass 'payload' die relevanten Daten enthält

      const indexesToRender = Object.entries(data)
        .filter(([key]) => key !== 'timestamp') // Filtert den 'timestamp' Schlüssel aus
        .map(([key, value]) => ({
          key,
          data: value,
        }))

      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: '#e0e0e0',
            padding: '10px',
            border: '1px solid #ccc',
          }}
        >
          {indexesToRender.map((entry, index) => {
            return (
              <Fragment>
                <p
                  key={`item-${index}`}
                  style={{
                    color: payload.find((pl) => {
                      return pl.dataKey.includes(entry.key)
                    }).stroke,
                  }}
                >
                  <strong>{`${indexLookup[entry.key]}: `}</strong>
                  {`${formatToNumber(entry.data.indexValue)} ${
                    entry.data.difference === 0
                      ? ''
                      : entry.data.difference > 0
                      ? `(+${formatToNumber(entry.data.difference * 100)}%)`
                      : `(${formatToNumber(entry.data.difference * 100)}%)`
                  }`}
                </p>
              </Fragment>
            )
          })}
        </div>
      )
    }

    return null
  }

  const CustomLabel = ({ x, y, value }) => {
    return (
      <text x={x} y={y} dy={-25} fill="#000" fontSize={10} textAnchor="middle">
        {value > 0
          ? `+${formatToNumber(value * 100)}%`
          : `${formatToNumber(value * 100)}%`}
      </text>
    )
  }

  const minDate = moment('01.02.2023', 'DD.MM.YYYY')
  const maxDate = moment().endOf('month')

  const dateToSliderValue = (date) => {
    // Berechne die Differenz zwischen dem aktuellen Datum und dem minDate in Tagen
    const diffFromMinDate = date.diff(minDate, 'days')

    // Berechne die Gesamtdifferenz zwischen maxDate und minDate in Tagen
    const totalDiff = maxDate.diff(minDate, 'days')

    // Berechne den relativen Anteil des aktuellen Datums im Gesamtzeitraum
    const ratio = diffFromMinDate / totalDiff

    // Konvertiere das Verhältnis in einen Wert zwischen 0 und 100
    return ratio * 100
  }

  const sliderValuesToDateRange = (values) => {
    // Berechne die Gesamtdifferenz zwischen maxDate und minDate in Tagen
    const totalDiff = maxDate.diff(minDate, 'days')

    return values.map((value) => {
      // Berechne, wie viele Tage dem Wert entsprechen
      const daysFromMinDate = (value / 100) * totalDiff

      // Addiere diese Tage auf minDate, um das entsprechende Datum zu erhalten
      const date = minDate.clone().add(daysFromMinDate, 'days')

      return date
    })
  }

  const [sliderValue, setSliderValue] = useState([
    dateToSliderValue(dateFrom),
    100,
  ])

  const theme = useTheme() // MUI Theme verwenden, falls benötigt für Abstände etc.
  const [fullPageHeight, setFullPageHeight] = useState('calc(100vh - 117px)')

  const gridContainerRef = useRef(null)

  useLayoutEffect(() => {
    const newHeight = `calc(100vh - 117px)`
    setFullPageHeight(newHeight)
  }, [])

  useEffect(() => {
    setIsLoading(true)
    ;(async () => {
      let newData = []

      for (let index = 0; index < selectedIdentifiers.length; index++) {
        const identifier = selectedIdentifiers[index]

        const response = await _proxiesService.getRangeOfIndex(
          identifier,
          dateFrom,
          dateTo,
          userObject.partner.id,
        )

        newData = newData.concat(
          response.data.map((entry) => {
            return {
              ...entry,
              identifier: identifier,
            }
          }),
        )
      }

      setData(newData)
      setIsLoading(false)
    })()
  }, [selectedIdentifiers, dateFrom, dateTo])

  const colorMap = generateColorMap(allIdentifiers)
  const colorMapAlpha = generateColorMap(allIdentifiers, 0.35)

  const handleSliderValueChange = (event, newValue) => {
    if (
      newValue[0] < dateToSliderValue(dateTo) &&
      newValue[1] > dateToSliderValue(dateFrom)
    ) {
      setSliderValue(newValue)
      const dates = sliderValuesToDateRange(newValue)
      setDateFrom(dates[0])
      setDateTo(dates[1])
    }
  }

  function valueLabelFormat(value) {
    let dateRange = sliderValuesToDateRange(sliderValue)
    const from = dateRange[0].format('DD.MM.YY')
    const to = dateRange[1].format('DD.MM.YY')
    return `${from}-${to}`
  }

  const startDate = minDate.clone()
  const endDate = maxDate.clone()
  const months = []

  while (startDate.isBefore(endDate) || startDate.isSame(endDate, 'month')) {
    months.push(startDate.clone())
    startDate.add(1, 'month')
  }

  if (!months.some((date) => date.isSame(endDate, 'day'))) {
    months.push(endDate.clone())
  }

  const sliderMarkers = months.map((month, index) => {
    return {
      value: dateToSliderValue(month),
      label:
        index === months.length - 1
          ? month.format('DD.MM.YY')
          : month.format('MM.YY'),
    }
  })

  const handleSelectIndex = (identifier) => {
    if (selectedIdentifiers.includes(identifier)) {
      setSelectedIdentifiers(
        selectedIdentifiers.filter((id) => id !== identifier),
      )
    } else {
      setSelectedIdentifiers([...selectedIdentifiers, identifier])
    }
  }

  let containerHeight = 0
  if (gridContainerRef.current) {
    containerHeight =
      window.innerHeight - gridContainerRef.current.offsetTop - 117
  }

  const mappedData = prepareDataForChart(data, selectedIdentifiers)

  const containerMemo = useMemo(() => {
    return isLoading ? (
      <Loading variant={'centered'} />
    ) : (
      <Box
        sx={{
          height: '100%',
          '& .recharts-responsive-container': { height: '100%' },
        }}
      >
        <ResponsiveContainer width="100%" height="100%">
          {
            <LineChart
              margin={{ top: 50, left: 0, right: 20, bottom: 0 }}
              data={mappedData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="timestamp"
                tickFormatter={(timestamp) =>
                  moment(timestamp).format('DD.MM.YYYY')
                }
                dy={5}
              />
              <YAxis type="number" domain={['auto', 'auto']} />
              <Tooltip content={<CustomTooltip />} />
              <Legend
                payload={selectedIdentifiers.map((identifier, index) => ({
                  id: identifier,
                  type: 'square',
                  value: `${indexLookup[identifier]}`,
                  color: colorMap[index],
                }))}
              />
              {selectedIdentifiers.map((identifier, index) => {
                return (
                  <Fragment key={identifier}>
                    {/* <Area
                         activeDot={false}
                         connectNulls
                         type="monotone"
                         dataKey={`${identifier}.range`}
                         stroke={colorMap[index]}
                         fill={colorMapAlpha[index]}
                       /> */}
                    <Line
                      connectNulls
                      type="monotone"
                      dataKey={`${identifier}.indexValue`}
                      stroke={colorMap[index]}
                      strokeWidth={3}
                    >
                      <LabelList
                        dataKey={`${identifier}.difference`}
                        position="top"
                        content={({ x, y, index, value }) => {
                          if (index === 0) {
                            return null
                          }
                          return <CustomLabel x={x} y={y} value={value} />
                        }}
                      />
                    </Line>
                  </Fragment>
                )
              })}
            </LineChart>
          }
        </ResponsiveContainer>
      </Box>
    )
  }, [data, isLoading])

  const isMediumResolution = useMediaQuery(theme.breakpoints.down('md'))
  const isLargeResolutionOrHigher = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Container maxWidth={false} disableGutters>
      <Grid
        container
        spacing={3}
        style={{ height: fullPageHeight, padding: theme.spacing(3) }}
      >
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <DatePicker
                minDate={minDate}
                label={t('CHARTS.FROM')}
                value={dateFrom}
                onChange={(newValue) => {
                  setDateFrom(newValue)
                  setSliderValue([dateToSliderValue(newValue), sliderValue[1]])
                }}
                sx={{ marginRight: 1 }}
              />
            </Grid>
            <Grid item>
              <DatePicker
                maxDate={maxDate}
                label={t('CHARTS.TO')}
                value={dateTo}
                onChange={(newValue) => {
                  setDateTo(newValue)
                  setSliderValue([sliderValue[0], dateToSliderValue(newValue)])
                }}
              />
            </Grid>
            {!isLargeResolutionOrHigher && (
              <Grid item xs={9}>
                <Box marginLeft={2} marginTop={1}>
                  <Slider
                    disableSwap
                    step={null}
                    marks={sliderMarkers}
                    getAriaLabel={() => 'Temperature range'}
                    value={sliderValue}
                    onChange={handleSliderValueChange}
                    valueLabelDisplay="auto"
                    valueLabelFormat={valueLabelFormat}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        {isMediumResolution ? (
          <Grid item xs={12}>
            <IndexesRendererMobile
              selectedIdentifiers={selectedIdentifiers}
              setSelectedIdentifiers={setSelectedIdentifiers}
              identifiers={allIdentifiers}
              data={data}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={9} style={{ flexGrow: 1 }} ref={gridContainerRef}>
              {containerMemo}
            </Grid>
            <Grid item xs={3} style={{ flexGrow: 1 }}>
              {
                <Box
                  sx={{
                    maxHeight: containerHeight,
                    overflowY: 'scroll',
                    padding: theme.spacing(2),
                  }}
                >
                  {allIdentifiers
                    .sort((a, b) => {
                      const aValue =
                        userObject.partner.activeTenderIndexes?.includes(a)
                          ? 0
                          : 1

                      const bValue =
                        userObject.partner.activeTenderIndexes?.includes(b)
                          ? 0
                          : 1

                      return aValue - bValue
                    })
                    .map((identifier, index) => {
                      const isSelected =
                        selectedIdentifiers.includes(identifier)
                      const currentIdentifierData = data.filter(
                        (entry) => entry.identifier === identifier,
                      )

                      let latestEntry = null
                      let oldestEntry = null
                      let difference = null

                      if (currentIdentifierData.length > 0) {
                        latestEntry =
                          currentIdentifierData[
                            currentIdentifierData.length - 1
                          ]
                        oldestEntry = currentIdentifierData[0]

                        difference =
                          (latestEntry.value.indexValue -
                            oldestEntry.value.indexValue) /
                          oldestEntry.value.indexValue
                      }

                      const hasAccessToIndex =
                        userObject.partner.hasAccessToIndex ||
                        userObject.partner.activeTenderIndexes?.includes(
                          identifier,
                        )

                      return (
                        <Card
                          elevation={0}
                          key={identifier}
                          sx={{
                            mb: 1,
                            bgcolor: isSelected ? '#e0e0e0' : '#fff',
                            color: isSelected
                              ? 'primary.contrastText'
                              : 'text.primary',
                          }}
                          onClick={() =>
                            hasAccessToIndex
                              ? handleSelectIndex(identifier)
                              : null
                          }
                        >
                          {hasAccessToIndex ? (
                            <CardActionArea>
                              <CardContent>
                                <Typography
                                  variant="subtitle1"
                                  component="div"
                                  sx={{
                                    color: isSelected
                                      ? colorMap[index]
                                      : '#333333',
                                  }}
                                >
                                  {indexLookup[identifier]}
                                </Typography>
                                {currentIdentifierData.length > 0 && (
                                  <Box
                                    sx={{
                                      display: 'flex', // Aktiviert Flexbox
                                      alignItems: 'center', // Zentriert die Items vertikal
                                      flexWrap: 'nowrap', // Verhindert Zeilenumbrüche
                                    }}
                                  >
                                    <Typography
                                      variant="subtitle2"
                                      sx={{
                                        color: isSelected
                                          ? colorMap[index]
                                          : '#333333',
                                      }}
                                    >
                                      {formatToNumber(
                                        latestEntry.value.indexValue,
                                      )}
                                    </Typography>
                                    <Typography
                                      variant="subtitle2"
                                      sx={{
                                        color:
                                          difference >= 0
                                            ? '#7EC07E'
                                            : '#fb5315',
                                        ml: 1, // Fügt einen linken Margin hinzu, um Abstand zwischen den Texten zu schaffen
                                      }}
                                    >
                                      {difference >= 0
                                        ? `(+${formatToNumber(
                                            difference * 100,
                                          )}%)`
                                        : `(${formatToNumber(
                                            difference * 100,
                                          )}%)`}
                                    </Typography>
                                  </Box>
                                )}
                              </CardContent>
                            </CardActionArea>
                          ) : (
                            <CardContent>
                              <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{
                                  color: isSelected
                                    ? colorMap[index]
                                    : '#333333',
                                }}
                              >
                                {indexLookup[identifier]}
                              </Typography>
                              <MUITooltip title={t('INDEXES.NO_ACCESS')}>
                                <Lock color={'secondary'} />
                              </MUITooltip>
                            </CardContent>
                          )}
                        </Card>
                      )
                    })}
                </Box>
              }
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  )
}

export default withTranslation()(connect()(Indexes))
