import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { alertActions } from '../../../../_actions/alertActions'
import { countryList } from '../../../../_constants/lookupConstants'
import validatePassword from '../../../../_helpers/validatePassword'
import { partnersService } from '../../../../_services/partnersService'
import GoogleAutocomplete from '../../../GoogleAutocomplete'
import { InfoTooltip } from '../../../InfoTooltip'
import { Section } from '../../../Section'

const Register = (props) => {
  const { t, dispatch } = props

  const initialValues = {
    name: '',
    taxId: '',
    countryId: '80',
    address: '',
    zipcode: '',
    place: '',
    type: 'customer',
    website: '',
    AGBAccept: false,
    users: [
      {
        salutation: 'MR',
        email: '',
        password: '',
        passwordConfirm: '',
        firstname: '',
        lastname: '',
        position: '',
        phone: '',
        mobile: '',
        fax: '',

        notificationLanguage: 'DE',
      },
    ],
    control: {
      showPassword: false,
    },
  }
  const phoneRegExp = /^[\d+]+$/

  const taxIdValidation = {
    14: '(AT)?U[0-9]{8}',
    21: '(BE)?0[0-9]{9}',
    33: '(BG)?[0-9]{9,10}',
    54: '(CY)?[0-9]{8}L',
    55: '(CZ)?[0-9]{8,10}',
    80: '(DE)?[0-9]{9}',
    56: '(DK)?[0-9]{8}',
    66: '(EE)?[0-9]{9}',
    84: '(EL|GR)?[0-9]{9}',
    203: '(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]',
    71: '(FI)?[0-9]{8}',
    72: '(FR)?[0-9A-Z]{2}[0-9]{9}',
    230: '(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})',
    97: '(HU)?[0-9]{8}',
    104: '(IE)?[0-9]S[0-9]{5}L',
    106: '(IT)?[0-9]{11}',
    127: '(LT)?([0-9]{9}|[0-9]{12})',
    128: '(LU)?[0-9]{8}',
    121: '(LV)?[0-9]{11}',
    136: '(MT)?[0-9]{8}',
    155: '(NL)?[0-9]{9}B[0-9]{2}',
    176: '(PL)?[0-9]{10}',
    177: '(PT)?[0-9]{9}',
    181: '(RO)?[0-9]{2,10}',
    211: '(SE)?[0-9]{12}',
    197: '(SI)?[0-9]{8}',
    196: '(SK)?[0-9]{10}',
  }

  const taxIdPlaceholders = {
    14: 'ATU12345678',
    21: 'BE0123456789',
    33: 'BG1234567890',
    54: 'CY12345678L',
    55: 'CZ1234567890',
    80: 'DE123456789',
    56: 'DK12345678',
    66: 'EE123456789',
    84: 'EL123456789',
    203: 'ESX1234567X',
    71: 'FI12345678',
    72: 'FRXX123456789',
    230: 'GB123456789',
    97: 'HU12345678',
    104: 'IE1S12345L',
    106: 'IT12345678901',
    127: 'LT123456789',
    128: 'LU12345678',
    121: 'LV12345678901',
    136: 'MT12345678',
    155: 'NL123456789B12',
    176: 'PL1234567890',
    177: 'PT123456789',
    181: 'RO1234567890',
    211: 'SE123456789012',
    197: 'SI12345678',
    196: 'SK1234567890',
  }
  const valSchema = !props.isAdmin
    ? Yup.object().shape({
        name: Yup.string().required(t('GENERAL.REQUIRED')),
        taxId: Yup.string().when('countryId', ([countryId], schema) => {
          if (countryId && countryId.length > 0) {
            return schema
              .test(
                'tax validation',
                t('GENERAL.INVALID_TAX_ID'),
                function (value) {
                  return value.match(taxIdValidation[countryId])
                },
              )
              .required(t('GENERAL.REQUIRED'))
          } else {
            return schema.notRequired()
          }
        }),
        countryId: Yup.string().required(t('GENERAL.REQUIRED')),
        address: Yup.string().required(t('GENERAL.REQUIRED')),
        zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
        place: Yup.string().required(t('GENERAL.REQUIRED')),
        type: Yup.string().required(t('GENERAL.REQUIRED')),
        website: Yup.string(),
        AGBAccept: Yup.boolean()
          .oneOf([true], t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        users: Yup.array(
          Yup.object().shape({
            email: Yup.string()
              .required(t('GENERAL.REQUIRED'))
              .email(t('GENERAL.INVALID_EMAIL')),
            password: Yup.string()
              .required(t('GENERAL.REQUIRED'))
              .test({
                test: validatePassword,
                message: t('GENERAL.PASSWORD_RULE'),
              }),
            passwordConfirm: Yup.string()
              .oneOf([Yup.ref('password')], t('GENERAL.MATCH'))
              .required(t('GENERAL.REQUIRED')),
            salutation: Yup.string(),
            firstname: Yup.string().required(t('GENERAL.REQUIRED')),
            lastname: Yup.string().required(t('GENERAL.REQUIRED')),
            position: Yup.string(),
            notificationLanguage: Yup.string().required(t('GENERAL.REQUIRED')),
            languages: Yup.string(),
            phone: Yup.string()
              .matches(phoneRegExp, t('GENERAL.INVALID_PHONE'))
              .required(t('GENERAL.REQUIRED')),
            mobile: Yup.string(),
            fax: Yup.string(),
          }),
        ),
      })
    : Yup.object().shape({
        name: Yup.string().required(t('GENERAL.REQUIRED')),
        taxId: Yup.string().when('countryId', ([countryId], schema) => {
          if (countryId && countryId.length > 0) {
            return schema
              .test(
                'tax validation',
                t('GENERAL.INVALID_TAX_ID'),
                function (value) {
                  return value.match(taxIdValidation[countryId])
                },
              )
              .required(t('GENERAL.REQUIRED'))
          } else {
            return schema.notRequired()
          }
        }),
        countryId: Yup.string().required(t('GENERAL.REQUIRED')),
        address: Yup.string().required(t('GENERAL.REQUIRED')),
        zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
        place: Yup.string().required(t('GENERAL.REQUIRED')),
        type: Yup.string().required(t('GENERAL.REQUIRED')),
        website: Yup.string(),
        AGBAccept: Yup.boolean(),
        users: Yup.array(
          Yup.object().shape({
            email: Yup.string()
              .required(t('GENERAL.REQUIRED'))
              .email(t('GENERAL.INVALID_EMAIL')),
            salutation: Yup.string(),
            firstname: Yup.string().required(t('GENERAL.REQUIRED')),
            lastname: Yup.string().required(t('GENERAL.REQUIRED')),
            notificationLanguage: Yup.string().required(t('GENERAL.REQUIRED')),
            position: Yup.string(),
            languages: Yup.string(),
            phone: Yup.string().required(t('GENERAL.REQUIRED')),
            mobile: Yup.string(),
            fax: Yup.string(),
          }),
        ),
      })

  let navigate = useNavigate()

  return (
    <div>
      <h2>{t('REGISTER.REGISTER')}</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={valSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await partnersService.createPartner(values)

            dispatch(
              alertActions.success(t('REGISTER.REGISTRATION_SUCCESSFUL')),
            )
            setTimeout(() => {
              dispatch(alertActions.clear())
              navigate('/')
            }, alertActions.alertTimeout)
          } catch (err) {
            dispatch(alertActions.error(t('manual:' + err.toString())))
            setTimeout(() => {
              dispatch(alertActions.clear())
            }, alertActions.alertTimeout)
          }
        }}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            <div className="form-row">
              <div className="col-md-6">
                <Section header={t('REGISTER.USER_DETAILS')}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        select
                        name="users[0].salutation"
                        label={t('REGISTER.SALUTATION')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      >
                        <MenuItem key="MR" value="MR">
                          {t('REGISTER.MR')}
                        </MenuItem>
                        <MenuItem key="MS" value="MS">
                          {t('REGISTER.MS')}
                        </MenuItem>
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="users[0].firstname"
                        label={t('REGISTER.FIRST_NAME')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="users[0].lastname"
                        label={t('REGISTER.LAST_NAME')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Field
                        component={TextField}
                        select
                        name="users[0].notificationLanguage"
                        label={t('REGISTER.NOTIFICATION_LANGUAGE')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      >
                        <MenuItem key="DE" value="DE">
                          {t('REGISTER.GERMAN')}
                        </MenuItem>
                        <MenuItem key="EN" value="EN">
                          {t('REGISTER.ENGLISH')}
                        </MenuItem>
                        <MenuItem key="PL" value="PL">
                          {t('REGISTER.POLISH')}
                        </MenuItem>
                        <MenuItem key="CS" value="CS">
                          {t('REGISTER.CZECH')}
                        </MenuItem>
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="users[0].position"
                        label={t('REGISTER.POSITION')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="users[0].phone"
                        label={t('REGISTER.PHONE')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="users[0].mobile"
                        label={t('REGISTER.MOBILE')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={TextField}
                        name="users[0].fax"
                        label={t('REGISTER.FAX')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="users[0].email"
                        label={t('REGISTER.EMAIL')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                    {!props.isAdmin ? (
                      <Fragment>
                        <Grid item xs={12} sm={6}>
                          <InfoTooltip
                            title={
                              <Fragment>
                                {t('GENERAL.PASSWORD_TOOLTIP0')}
                                <br />
                                <ul>
                                  <li>{t('GENERAL.PASSWORD_TOOLTIP1')}</li>
                                  <li>{t('GENERAL.PASSWORD_TOOLTIP2')}</li>
                                  <li>{t('GENERAL.PASSWORD_TOOLTIP3')}</li>
                                </ul>
                              </Fragment>
                            }
                          >
                            <Field
                              name="users[0].password"
                              label={t('GENERAL.PASSWORD')}
                              component={TextField}
                              variant="outlined"
                              type={
                                values.control.showPassword
                                  ? 'text'
                                  : 'password'
                              }
                              fullWidth={true}
                              margin="dense"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() => {
                                        setFieldValue(
                                          'control.showPassword',
                                          !values.control.showPassword,
                                        )
                                      }}
                                      edge="end"
                                      size="large"
                                    >
                                      {values.control.showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </InfoTooltip>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="users[0].passwordConfirm"
                            label={t('REGISTER.PASSWORD_CONFIRM')}
                            component={TextField}
                            variant="outlined"
                            type={
                              values.control.showPassword ? 'text' : 'password'
                            }
                            fullWidth={true}
                            margin="dense"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                      setFieldValue(
                                        'control.showPassword',
                                        !values.control.showPassword,
                                      )
                                    }}
                                    edge="end"
                                    size="large"
                                  >
                                    {values.control.showPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </Fragment>
                    ) : null}
                  </Grid>
                </Section>
              </div>
              <div className="col-md-6">
                <Section header={t('REGISTER.COMPANY_DETAILS')}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="name"
                        label={t('REGISTER.COMPANY_NAME')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="taxId"
                        placeholder={taxIdPlaceholders[values.countryId]}
                        label={
                          t('REGISTER.TAX_ID') +
                          '(' +
                          taxIdPlaceholders[values.countryId] +
                          ')'
                        }
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <GoogleAutocomplete
                        setFieldValue={setFieldValue}
                        formikName={'address'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="zipcode"
                        label={t('REGISTER.ZIP_CODE')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="place"
                        label={t('REGISTER.PLACE')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        select
                        name="countryId"
                        label={t('REGISTER.HOME_COUNTRY')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      >
                        {Object.entries(countryList).map((country) => (
                          <MenuItem value={country[1]} key={country[0]}>
                            {country[0]}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="type"
                        label={t('REGISTER.COMPANY_TYPE')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                        select
                      >
                        <MenuItem key="manufacturer" value="manufacturer">
                          {t('REGISTER.MANUFACTURER')}
                        </MenuItem>
                        <MenuItem key="customer" value="customer">
                          {t('REGISTER.CUSTOMER')}
                        </MenuItem>
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        component={TextField}
                        name="website"
                        label={t('REGISTER.WEBSITE')}
                        variant="outlined"
                        fullWidth={true}
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                </Section>
              </div>
            </div>
            <Field
              component={CheckboxWithLabel}
              type={'checkbox'}
              color="default"
              name="AGBAccept"
              Label={{
                label: (
                  <span>
                    {t('REGISTER.AGB_ACCEPT_0') + ' '}
                    <Link
                      onClick={() =>
                        window.open('https://www.pacurion.com/agb/')
                      }
                    >
                      {t('REGISTER.AGB_ACCEPT_1')}
                    </Link>
                    {' ' + t('REGISTER.AGB_ACCEPT_2')}
                  </span>
                ),
              }}
              style={{ marginLeft: '15px' }}
            />
            <br />
            <Button
              variant="contained"
              color="secondary"
              disabled={isSubmitting}
              type="submit"
              style={{ margin: '0 10px 0 15px' }}
            >
              {t('REGISTER.REGISTER')}
            </Button>
            <Button
              component={Link}
              to="/"
              variant="outlined"
              color="secondary"
              disabled={isSubmitting}
            >
              {t('GENERAL.CANCEL')}
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

function mapStateToProps(state) {
  const { registering } = state.registration
  return {
    registering,
  }
}

export default withTranslation()(connect(mapStateToProps)(Register))
