import {
  Grid,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import numeral from 'numeral'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { authorize } from '../../../../_helpers/authorization'
import { translateProductUnit } from '../../../../_helpers/little'
import NumberFormatCustom from '../../../../_helpers/numberFormatCustom'
import DifferenceWrapper from '../../../DifferenceWrapper'
import PositionCard from '../../../PositionCard'
import { Section } from '../../../Section'

const OfferRequestPositions = (props) => {
  const {
    t,
    data,
    errors,
    setFieldValue,
    differences,
    diffObjectType,
    diffData,
  } = props

  return (
    <Section header={t('OFFER.POSITIONS')}>
      {typeof errors.positions === 'string' ? (
        <ErrorMessage
          name={'positions'}
          render={(msg) => <div className="help-block">{msg}</div>}
        />
      ) : null}
      <Grid container spacing={3} direction="row" alignItems="stretch">
        {data.positions.map((pos, index) => {
          var combinedPosProperties = []
          differences.forEach((pos) => {
            combinedPosProperties.push(...pos.positionProperties)
          })

          let showDifference =
            combinedPosProperties.filter(
              (positionProperty) => positionProperty.value !== false,
            ).length > 0

          return (
            <Grid item xs={12} md={6} lg={4}>
              <PositionCard
                data={pos}
                readOnly={false}
                showProductDetails={true}
                differences={differences.find(
                  (diffPos) =>
                    parseInt(diffPos.id) === parseInt(pos.id) &&
                    parseInt(diffPos.locationId) === parseInt(pos.locationId),
                )}
                diffObjectType={diffObjectType}
                diffData={diffData.positions.find(
                  (diffPos) => parseInt(diffPos.id) === parseInt(pos.id),
                )}
                showDifference={showDifference}
                updatePositionFunction={(product, positionProperties) => {
                  var newRowData = {
                    productId: product.id,
                    locationId: pos.locationId,
                    checked: pos.checked,
                    price: pos.price,
                    purchasePrice: pos.purchasePrice,
                    salesPrice: pos.salesPrice,
                    productUnitId: pos.productUnitId,
                    positionProperties: positionProperties,
                    product: product,
                    requestPositionId: pos.requestPositionId,
                  }

                  const newPositions = [...data.positions]

                  let i = newPositions.findIndex(
                    (newPos) => newPos.id === pos.id,
                  )
                  newPositions[i] = {
                    ...newRowData,
                    id: newPositions[i].id,
                    amount: newPositions[i].amount ? newPositions[i].amount : 0,
                  }
                  setFieldValue('positions', newPositions)
                }}
                parentType={'OFFER_POSITION'}
                showAmount={false}
                showImage={true}
                imageSize={'small'}
              >
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Field
                      component={CheckboxWithLabel}
                      margin="dense"
                      variant="outlined"
                      name={'positions[' + index + '].checked'}
                      checked={data.positions[index].checked}
                      onChange={(e) => {
                        if (data.positions[index].checked) {
                          let newDeliveries = [...data.deliveries]
                            .map((delivery) => {
                              return {
                                ...delivery,
                                positions: delivery.positions.map((dPos) => {
                                  return {
                                    ...dPos,
                                    amount:
                                      dPos.positionId ===
                                      data.positions[index].id
                                        ? 0
                                        : dPos.amount,
                                  }
                                }),
                              }
                            })
                            .filter((delivery) => {
                              return delivery.positions.some(
                                (dPos) => dPos.amount > 0,
                              )
                            })

                          setFieldValue('deliveries', newDeliveries)
                        }

                        setFieldValue(
                          'positions[' + index + '].checked',
                          !data.positions[index].checked,
                        )
                      }}
                      Label={{ label: t('OFFER.OFFER_YES_NO') }}
                      type={'checkbox'}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <DifferenceWrapper
                      showDifference={
                        differences.filter(
                          (diffPos) => diffPos.amount !== false,
                        ).length > 0
                      }
                      difference={
                        differences.find(
                          (diffPos) =>
                            parseInt(diffPos.id) === parseInt(pos.id) &&
                            parseInt(diffPos.locationId) ===
                              parseInt(pos.locationId),
                        ).amount
                      }
                      differenceDisplay={numeral(
                        differences.find(
                          (diffPos) =>
                            parseInt(diffPos.id) === parseInt(pos.id) &&
                            parseInt(diffPos.locationId) ===
                              parseInt(pos.locationId),
                        ).amount,
                      ).format('0,0.[00]')}
                      diffObjectType={diffObjectType}
                    >
                      <Field
                        fullWidth={true}
                        disabled={!data.positions[index].checked}
                        component={TextField}
                        margin="dense"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                          endAdornment: (
                            <InputAdornment position="end">
                              {translateProductUnit(
                                data.positions[index].product.productUnits.find(
                                  (productUnit) => {
                                    return (
                                      productUnit.id ===
                                      data.positions[index].productUnitId
                                    )
                                  },
                                ),
                              )}
                            </InputAdornment>
                          ),
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        inputProps={{
                          decimalScale:
                            data.positions[index].productUnitId &&
                            data.positions[index].product.productUnits.find(
                              (productUnit) => {
                                return (
                                  productUnit.id ===
                                  data.positions[index].productUnitId
                                )
                              },
                            ).type === 'FLOAT'
                              ? 2
                              : 0,
                          allowNegative: false,
                        }}
                        label={t('REQUEST.AMOUNT')}
                        variant="outlined"
                        name={`positions[${index}].amount`}
                      />
                    </DifferenceWrapper>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <DifferenceWrapper
                      showDifference={
                        differences.filter((diffPos) => diffPos.unit !== false)
                          .length > 0
                      }
                      difference={
                        differences.find(
                          (diffPos) =>
                            parseInt(diffPos.id) === parseInt(pos.id) &&
                            parseInt(diffPos.locationId) ===
                              parseInt(pos.locationId),
                        ).unit
                      }
                      differenceDisplay={translateProductUnit(
                        differences.find(
                          (diffPos) =>
                            parseInt(diffPos.id) === parseInt(pos.id) &&
                            parseInt(diffPos.locationId) ===
                              parseInt(pos.locationId),
                        ).productUnitId,
                      )}
                      diffObjectType={diffObjectType}
                    >
                      <ErrorMessage
                        name={`generalInformation.positions[${index}].productUnitId`}
                        render={(msg) => (
                          <div className="help-block">{msg}</div>
                        )}
                      />
                      <ToggleButtonGroup
                        fullWidth
                        value={data.positions[index].productUnitId}
                        sx={{ alignSelf: 'center' }}
                        size={'large'}
                      >
                        {data.request.positions
                          .find(
                            (rPos) =>
                              rPos.id ===
                              data.positions[index].requestPositionId,
                          )
                          .product.productUnits.map((productUnit) => {
                            return (
                              <ToggleButton
                                value={productUnit.id}
                                onClick={() =>
                                  setFieldValue(
                                    `positions[${index}].productUnitId`,
                                    productUnit.id,
                                  )
                                }
                              >
                                {translateProductUnit(productUnit)}
                              </ToggleButton>
                            )
                          })}
                      </ToggleButtonGroup>
                    </DifferenceWrapper>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth={true}
                      disabled={!data.positions[index].checked}
                      component={TextField}
                      margin="dense"
                      size={'small'}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        inputComponent: NumberFormatCustom,
                        decimalScale: 2,
                        allowNegative: false,
                        suffix: ' \u20AC',
                        fixedDecimalScale: true,
                      }}
                      label={`${t('OFFER.PRICE_EACH')} ${
                        data.positions[index].product.productUnits.find(
                          (productUnit) => {
                            return (
                              productUnit.id ===
                              data.positions[index].productUnitId
                            )
                          },
                        ).deliveryProductUnitId
                          ? translateProductUnit(
                              data.positions[index].product.productUnits.find(
                                (productUnit) => {
                                  return (
                                    productUnit.id ===
                                    data.positions[
                                      index
                                    ].product.productUnits.find(
                                      (productUnit) => {
                                        return (
                                          productUnit.id ===
                                          data.positions[index].productUnitId
                                        )
                                      },
                                    ).deliveryProductUnitId
                                  )
                                },
                              ),
                            )
                          : translateProductUnit(
                              data.positions[index].product.productUnits.find(
                                (productUnit) => {
                                  return (
                                    productUnit.id ===
                                    data.positions[index].productUnitId
                                  )
                                },
                              ),
                            )
                      }`}
                      variant="outlined"
                      name={`positions[${index}].price`}
                    />
                  </Grid>
                  {authorize('administrator') && (
                    <Fragment>
                      <Grid item xs={12}>
                        <Field
                          fullWidth={true}
                          disabled={!data.positions[index].checked}
                          component={TextField}
                          margin="dense"
                          size={'small'}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{
                            inputComponent: NumberFormatCustom,
                            decimalScale: 2,
                            allowNegative: false,
                            suffix: ' \u20AC',
                            fixedDecimalScale: true,
                          }}
                          label={t('OFFER.PURCHASE_PRICE')}
                          variant="outlined"
                          name={`positions[${index}].purchasePrice`}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth={true}
                          disabled={!data.positions[index].checked}
                          component={TextField}
                          margin="dense"
                          size={'small'}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{
                            inputComponent: NumberFormatCustom,
                            decimalScale: 2,
                            allowNegative: false,
                            suffix: ' \u20AC',
                            fixedDecimalScale: true,
                          }}
                          label={t('OFFER.SALES_PRICE')}
                          variant="outlined"
                          name={`positions[${index}].salesPrice`}
                        />
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </PositionCard>
            </Grid>
          )
        })}
      </Grid>
    </Section>
  )
}
export default withTranslation()(connect()(OfferRequestPositions))
