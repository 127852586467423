import { Business } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { countryLookup } from '../../_constants/lookupConstants'
import { authorize } from '../../_helpers/authorization'
import { _applicationService } from '../../_services/_applicationService'
import { partnerInvoiceLocationsService } from '../../_services/partnerInvoiceLocationsService'
import CustomDialogTitle from '../CustomDialogTitle'
import { Section } from '../Section'
import CreatePartnerInvoiceLocationDialog from './_components/CreatePartnerInvoiceLocationDialog'

const InvoiceAddress = (props) => {
  const { t, partnerId, setFieldValue, data, jobOfferToken, userId } = props

  const [
    showSelectPartnerInvoiceLocation,
    setShowSelectPartnerInvoiceLocation,
  ] = useState(false)

  const [
    showCreatePartnerInvoiceLocation,
    setShowCreatePartnerInvoiceLocation,
  ] = useState(false)

  const [selectedInvoiceAddress, setSelectedInvoiceAddress] = useState(false)
  const [partnerInvoiceLocations, setPartnerInvoiceLocations] = useState([])

  useEffect(() => {
    const fetchPartnerInvoiceLocations = async () => {
      if (!userId && !jobOfferToken) {
        return
      }
      try {
        let locations = jobOfferToken
          ? await _applicationService.getPartnerInvoiceLocationsByToken(
              jobOfferToken,
            )
          : await partnerInvoiceLocationsService.getPartnerInvoiceLocationsOfUser(
              userId,
            )

        setPartnerInvoiceLocations(
          locations.filter((location) => location.active),
        )

        let selectedPartnerInvoiceLocation = data.partnerInvoiceLocationId
          ? locations.find(
              (location) => location.id === data.partnerInvoiceLocationId,
            )
          : locations.find((location) => location.primary)

        if (!selectedPartnerInvoiceLocation) {
          selectedPartnerInvoiceLocation = locations[0]
        }

        setFieldValue(
          'partnerInvoiceLocationId',
          selectedPartnerInvoiceLocation.id,
        )

        setSelectedInvoiceAddress(selectedPartnerInvoiceLocation)
      } catch (error) {
        console.log(error)
        // Handle error if needed
      }
    }

    fetchPartnerInvoiceLocations()
  }, [showCreatePartnerInvoiceLocation, userId])

  return (
    <Fragment>
      <Section header={t('OFFERS.INVOICE_ADDRESS')}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ButtonGroup color={'secondary'} size={'small'}>
              <Button
                onClick={() => setShowSelectPartnerInvoiceLocation(true)}
                variant={'contained'}
              >
                {t('REQUEST.DELIVERY_DETAILS.CHOOSE_INVOICE_ADDRESS')}
              </Button>
              {authorize('addPartnerInvoiceLocation') && (
                <Button
                  onClick={() => setShowCreatePartnerInvoiceLocation(true)}
                  variant={'outlined'}
                >
                  {t('REQUEST.ADD_INVOICE_ADDRESS_TO_MASTER_DATA')}
                </Button>
              )}
            </ButtonGroup>
          </Grid>
          <Grid item xs={12}>
            {selectedInvoiceAddress && (
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                    <Fragment>{`${selectedInvoiceAddress.company},${
                      selectedInvoiceAddress.contactPerson
                        ? ' ' + selectedInvoiceAddress.contactPerson + ', '
                        : ''
                    } ${selectedInvoiceAddress.address},${
                      selectedInvoiceAddress.additionalAddressLine
                        ? ' ' +
                          selectedInvoiceAddress.additionalAddressLine +
                          ', '
                        : ''
                    } `}</Fragment>
                    <Fragment>{`${selectedInvoiceAddress.zipcode} ${
                      selectedInvoiceAddress.place
                    }, ${
                      countryLookup[selectedInvoiceAddress.countryId]
                    }`}</Fragment>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Section>
      <Dialog open={showSelectPartnerInvoiceLocation}>
        <CustomDialogTitle
          title={t('REQUEST.DELIVERY_DETAILS.CHOOSE_DELIVERY_ADDRESS')}
          onClose={() => setShowSelectPartnerInvoiceLocation(false)}
        />
        <DialogContent>
          <List>
            {partnerInvoiceLocations.map((location) => (
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    let selectedPartnerInvoiceLocation =
                      partnerInvoiceLocations.find(
                        (loc) => loc.id === location.id,
                      )
                    setFieldValue(
                      'partnerInvoiceLocationId',
                      selectedPartnerInvoiceLocation.id,
                    )

                    setSelectedInvoiceAddress(selectedPartnerInvoiceLocation)

                    setShowSelectPartnerInvoiceLocation(false)
                  }}
                >
                  <ListItemIcon>
                    <Business />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${location.company}, ${location.address}, ${
                      location.zipcode
                    } ${location.place}, ${
                      countryLookup[location.countryId]
                    }, ${location.taxId}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'contained'}
            color={'secondary'}
            onClick={() => {
              setShowSelectPartnerInvoiceLocation(false)
            }}
          >
            {t('GENERAL.CANCEL')}
          </Button>
        </DialogActions>
      </Dialog>
      {data ? (
        <CreatePartnerInvoiceLocationDialog
          hide={() => {
            setShowCreatePartnerInvoiceLocation(false)
          }}
          open={showCreatePartnerInvoiceLocation}
          partnerId={partnerId}
          jobOfferToken={jobOfferToken}
        />
      ) : null}
    </Fragment>
  )
}

export default withTranslation()(connect()(InvoiceAddress))
