import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const productPropertyCategoriesService = {
  getProductPropertyCategories,
  createProductPropertyCategory,
  updateProductPropertyCategory,
  deleteProductPropertyCategory,
}

function getProductPropertyCategories() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/product-property-categories`,
    requestOptions,
  )
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function createProductPropertyCategory(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(
    `${config.apiUrlBase}/product-property-categories`,
    requestOptions,
  ).then(handleResponse)
}

function updateProductPropertyCategory(productPropertyCategoryId, payload) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(
    `${config.apiUrlBase}/product-property-categories/${productPropertyCategoryId}`,
    requestOptions,
  ).then(handleResponse)
}

function deleteProductPropertyCategory(productPropertyCategoryId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/product-property-categories/${productPropertyCategoryId}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './productPropertyCategoriesService'
