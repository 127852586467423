import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ClaimsOverview from './_components/ClaimsOverview'
import ContractsOverview from './_components/ContractsOverview'
import DespatchAdviceOverview from './_components/DespatchAdviceOverview'
import JobOffersOverview from './_components/JobOffersOverview'
import OffersOverview from './_components/OffersOverview'
import OrdersOverview from './_components/OrdersOverview'
import RequestsOverview from './_components/RequestsOverview'

const Overviews = (props) => {
  const { t, dispatch } = props

  const [selectedTab, setSelectedTab] = useState(0)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <TabContext value={selectedTab}>
      <TabList
        value={selectedTab}
        onChange={(e, value) => setSelectedTab(value)}
        variant={isMobile ? 'scrollable' : 'standard'}
        scrollButtons={isMobile ? 'auto' : 'off'}
        allowScrollButtonsMobile={isMobile}
      >
        <Tab label={t('TAB.ORDERS_OVERVIEW')} />
        <Tab label={t('TAB.REQUESTS_OVERVIEW')} />
        <Tab label={t('TAB.CONTRACTS_OVERVIEW')} />
        <Tab label={t('TAB.DESPATCH_ADVICE_OVERVIEW')} />
        <Tab label={t('TAB.CLAIM_OVERVIEW')} />
        <Tab label={t('TAB.JOB_OFFERS_OVERVIEW')} />
        <Tab label={t('TAB.OFFERS_OVERVIEW')} />
      </TabList>
      <TabPanel value={0}>
        <OrdersOverview />
      </TabPanel>
      <TabPanel value={1}>
        <RequestsOverview />
      </TabPanel>
      <TabPanel value={2}>
        <ContractsOverview />
      </TabPanel>
      <TabPanel value={3}>
        <DespatchAdviceOverview />
      </TabPanel>
      <TabPanel value={4}>
        <ClaimsOverview />
      </TabPanel>
      <TabPanel value={5}>
        <JobOffersOverview />
      </TabPanel>
      <TabPanel value={6}>
        <OffersOverview />
      </TabPanel>
    </TabContext>
  )
}

export default withTranslation()(connect()(Overviews))
