import { Forest, Info } from '@mui/icons-material'
import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
  formatToNumber,
  formatToPrice,
} from '../../../../../../_helpers/number-formatting'
import CustomDialogTitle from '../../../../../CustomDialogTitle'
import PositionCard from '../../../../../PositionCard'

const AdvertRecommendations = (props) => {
  const { t, show, hide, advertRecommendations } = props

  let navigate = useNavigate()

  return (
    <Dialog open={show} onClose={hide} fullWidth={true} maxWidth={'md'}>
      <CustomDialogTitle
        title={t('ADVERT_RECOMMENDATIONS.RECOMMENDED_ADVERTS')}
        onClose={() => hide()}
      />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Alert icon={<Info fontSize="inherit" />} severity={'info'}>
              {t('ADVERT_RECOMMENDATIONS.DESCRIPTION')}
            </Alert>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PositionCard
              data={{
                productId: advertRecommendations.closest.productId,
                positionProperties: [],
              }}
              showProductDetails={false}
              readOnly={true}
              showImage={true}
              imageSize={'small'}
            >
              <Stack direction="row" spacing={2}>
                <Typography variant={'h6'}>
                  {t('ADVERT_RECOMMENDATIONS.CLOSEST_ADVERT')}{' '}
                </Typography>
              </Stack>

              <Typography>
                {`${formatToNumber(advertRecommendations.closest.distance)} ${t(
                  'ADVERT_RECOMMENDATIONS.KILOMETRES_AWAY',
                )}`}
              </Typography>
              <Typography>
                {advertRecommendations.closest.price
                  ? `${formatToPrice(advertRecommendations.closest.price)} ${t(
                      'ADVERT_RECOMMENDATIONS.EXW',
                    )}`
                  : `${t('ADVERT.NO_PRICE')}`}
              </Typography>
              <Button
                sx={{ marginTop: 1 }}
                variant={'contained'}
                size={'medium'}
                color={'secondary'}
                endIcon={<Forest />}
                onClick={() =>
                  navigate({
                    pathname: '/adverts-exchange',
                    search: 'id=' + advertRecommendations.closest.id,
                  })
                }
              >
                {t('ADVERT_RECOMMENDATIONS.GO_TO_ADVERT')}
              </Button>
            </PositionCard>
          </Grid>
          {advertRecommendations.cheapest && (
            <Grid item xs={12} sm={6}>
              <PositionCard
                data={{
                  productId: advertRecommendations.cheapest.productId,
                  positionProperties: [],
                }}
                showProductDetails={false}
                readOnly={true}
                showImage={true}
                imageSize={'small'}
              >
                <Typography variant={'h6'}>
                  {t('ADVERT_RECOMMENDATIONS.ATTRACTIVE_PRICE')}
                </Typography>
                <Typography>
                  {`${formatToNumber(
                    advertRecommendations.cheapest.distance,
                  )} ${t('ADVERT_RECOMMENDATIONS.KILOMETRES_AWAY')}`}
                </Typography>
                <Typography>
                  {`${formatToPrice(advertRecommendations.cheapest.price)} ${t(
                    'ADVERT_RECOMMENDATIONS.EXW',
                  )}`}
                </Typography>
                <Button
                  sx={{ marginTop: 1 }}
                  variant={'contained'}
                  size={'medium'}
                  color={'secondary'}
                  onClick={() =>
                    navigate({
                      pathname: '/adverts-exchange',
                      search: 'id=' + advertRecommendations.cheapest.id,
                    })
                  }
                >
                  {t('ADVERT_RECOMMENDATIONS.GO_TO_ADVERT')}
                </Button>
              </PositionCard>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default withTranslation()(AdvertRecommendations)
