import { Delete, Edit, ToggleOn } from '@mui/icons-material'
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { jobsService } from '../../../../../../_services/jobsService'
import AgreementDialog from '../../../../../AgreementDialog'
import { Loading } from '../../../../../Loading'
import OfferJobDialog from './_components/JobDialog'

const OfferJobs = (props) => {
  const { t, dispatch } = props

  const [selectedJob, setSelectedJob] = useState(null)
  const [showJobDialog, setShowJobDialog] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)
  const [showArchiveDialog, setShowArchiveDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [jobs, setJobs] = useState([])

  const userObject = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    jobsService.getJobsOfPartner(userObject.partner.id).then((jobs) => {
      setJobs(jobs)
    })
  }, [forceUpdateCount])

  return isLoading ? (
    <Loading variant={'center'} />
  ) : (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          style={{ margin: '15px 15px' }}
          onClick={() => {
            setShowJobDialog(true)
          }}
        >
          {t('OFFER_JOBS.SHOW_OFFER_JOB_DIALOG')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('OFFER_JOBS.JOB_ID')}</TableCell>
              <TableCell>{t('OFFER_JOBS.STATE')}</TableCell>
              <TableCell>{t('OFFER_JOBS.NAME')}</TableCell>
              <TableCell>{t('OFFER_JOBS.ACTIONS')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.map((job) => (
              <TableRow key={job.id}>
                <TableCell>{job.id}</TableCell>
                <TableCell>{job.state}</TableCell>
                <TableCell>{job.name}</TableCell>
                <TableCell>
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    size={'small'}
                    startIcon={<Edit />}
                    sx={{ marginRight: 1 }}
                    onClick={() => {
                      setSelectedJob(job)
                      setShowJobDialog(true)
                    }}
                  >
                    {t('OFFER_JOBS.EDIT_JOB')}
                  </Button>
                  {job.state === 'ACTIVE' ? (
                    <Button
                      variant={'outlined'}
                      color={'error'}
                      size={'small'}
                      startIcon={<Delete />}
                      sx={{ marginRight: 1 }}
                      onClick={() => {
                        setSelectedJob(job)
                        setShowArchiveDialog(true)
                      }}
                    >
                      {t('OFFER_JOBS.ARCHIVE_JOB')}
                    </Button>
                  ) : (
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      size={'small'}
                      startIcon={<ToggleOn />}
                      sx={{ marginRight: 1 }}
                      onClick={() => {
                        setSelectedJob(job)
                        setShowArchiveDialog(true)
                      }}
                    >
                      {t('OFFER_JOBS.ACTIVATE')}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      {showJobDialog && (
        <OfferJobDialog
          jobId={selectedJob ? selectedJob.id : null}
          hide={() => {
            setShowJobDialog(false)
            setSelectedJob(null)
          }}
          forceUpdate={() => {
            forceUpdate(forceUpdateCount + 1)
          }}
        />
      )}
      <AgreementDialog
        open={showArchiveDialog}
        message={t('ADMIN.ARE_YOU_SURE')}
        acceptButtonText={t('GENERAL.YES')}
        handleClose={() => {
          setShowArchiveDialog(false)
        }}
        agree={() => {
          setIsLoading(true)
          setShowArchiveDialog(false)
          jobsService
            .updateJob(selectedJob.id, {
              state: selectedJob.state === 'ACTIVE' ? 'ARCHIVED' : 'ACTIVE',
            })
            .then((data) => {
              forceUpdate(forceUpdateCount + 1)
            })
            .finally(() => setIsLoading(false))
        }}
      />
    </Grid>
  )
}

export default withTranslation()(connect()(OfferJobs))
