import { Add, Info } from '@mui/icons-material'
import {
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import { TextField } from 'formik-mui'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  countryLookup,
  indexLookup,
} from '../../../../../../_constants/lookupConstants'
import {
  getIndexesOfPosition,
  reorgArray,
  translateProductUnit,
} from '../../../../../../_helpers/little'
import NumberFormatCustom from '../../../../../../_helpers/numberFormatCustom'
import PositionCard from '../../../../../PositionCard'
import ProductConfigurator from '../../../../../ProductConfigurator'
import { Section } from '../../../../../Section'

const TenderPositions = (props) => {
  const { t, data, setFieldValue, readOnly } = props

  const [showProductConfigurator, setShowProductConfigurator] = useState(
    data.positions.length === 0 ? true : false,
  )

  return (
    <Fragment>
      <Section header={t('REQUEST.TENDER_POSITIONS')}>
        <ErrorMessage
          name={'positions'}
          render={(msg) =>
            typeof msg === 'string' ? (
              <div className="help-block">{msg}</div>
            ) : null
          }
        />
        {!readOnly && (
          <Button
            startIcon={<Add />}
            variant="contained"
            color="secondary"
            size={'medium'}
            style={{ marginBottom: 20, marginTop: 10 }}
            onClick={() => setShowProductConfigurator(true)}
          >
            {t('REQUEST.ADD_POSITION')}
          </Button>
        )}
        <ProductConfigurator
          readOnly={false}
          show={showProductConfigurator}
          data={data.details}
          close={(e) => {
            setShowProductConfigurator(false)
          }}
          createPositionFunction={(product, posProperties) => {
            setShowProductConfigurator(false)
            var newRowData = {
              productId: product.id,
              amount: 0,
              productUnitId: product.productUnits.filter(
                (productUnit) => productUnit.isTenderUnit,
              )[0].id,
              product: product,
              positionProperties: posProperties,
              index: null,
            }

            const res = data.positions
            const length = data.positions.length
            const nextId = length + 1
            let newData = {
              ...newRowData,
              id: nextId,
            }
            res.push(newData)
            setFieldValue('positions', res)

            let locations =
              data.generalInformation.deliveryDetails.locations.map(
                (location) => {
                  const locationPositions = location.positions

                  locationPositions.push({
                    ...newData,
                    positionId: newData.id,
                    amount: 0,
                    checked: true,
                  })

                  return {
                    ...location,
                    positions: locationPositions,
                  }
                },
              )
            setFieldValue(
              'generalInformation.deliveryDetails.locations',
              locations,
            )
          }}
        />

        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent={'flex-start'}
        >
          {data.positions.map((pos, index) => {
            let amount = 0
            data.generalInformation.deliveryDetails.locations.forEach(
              (location) => {
                let position = location.positions.find((lPos) => {
                  return pos.id === lPos.id
                })

                if (position && position.checked && position.amount) {
                  amount += parseInt(position.amount)
                }
              },
            )
            return (
              <Grid item xs={12} md={6} lg={4}>
                <PositionCard
                  data={pos}
                  readOnly={false}
                  showProductDetails={true}
                  updatePositionFunction={(product, positionProperties) => {
                    var newRowData = {
                      productId: product.id,
                      productUnitId: pos.productUnitId,
                      product: product,
                      positionProperties: positionProperties,
                    }

                    const newPositions = [...data.positions]

                    let i = newPositions.findIndex(
                      (newPos) => newPos.id === pos.id,
                    )
                    newPositions[i] = {
                      ...newRowData,
                      id: newPositions[i].id,
                      amount: newPositions[i].amount
                        ? newPositions[i].amount
                        : 0,
                    }
                    setFieldValue('positions', newPositions)
                    // update all existing location positions accordingly
                    data.generalInformation.deliveryDetails.locations.forEach(
                      async (location) => {
                        const locationPositions = location.positions

                        locationPositions.splice(index, 1, {
                          ...newPositions[i],
                          positionId: newPositions[i].id,
                          amount: locationPositions[index].amount,
                          checked: locationPositions[index].checked,
                        })

                        await setFieldValue(
                          'generalInformation.deliveryDetails.locations.positions',
                          locationPositions,
                        )
                      },
                    )
                  }}
                  deletePositionFunction={() => {
                    var posData = data.positions

                    posData.splice(index, 1)
                    reorgArray(posData, 'id', 1)

                    data.generalInformation.deliveryDetails.locations.forEach(
                      async (location) => {
                        const locationPositions = location.positions

                        locationPositions.splice(index, 1)
                        reorgArray(locationPositions, 'positionId', 1)

                        await setFieldValue(
                          'generalInformation.deliveryDetails.locations.positions',
                          locationPositions,
                        )
                      },
                    )
                    setFieldValue('positions', posData)
                  }}
                  showImage={true}
                  imageSize={'small'}
                >
                  {data.positions[index].product.productUnits.filter(
                    (productUnit) => productUnit.isTenderUnit,
                  ).length > 1 ? (
                    <Grid
                      container
                      spacing={1}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <ToggleButtonGroup
                          fullWidth
                          value={data.positions[index].productUnitId}
                          disabled={readOnly}
                          sx={{ alignSelf: 'center' }}
                          size={'large'}
                        >
                          {data.positions[index].product.productUnits
                            .filter((productUnit) => productUnit.isTenderUnit)
                            .map((productUnit) => {
                              return (
                                <ToggleButton
                                  value={productUnit.id}
                                  onClick={() => {
                                    setFieldValue(
                                      `positions[${index}].productUnitId`,
                                      productUnit.id,
                                    )
                                  }}
                                >
                                  {translateProductUnit(productUnit)}
                                </ToggleButton>
                              )
                            })}
                        </ToggleButtonGroup>
                      </Grid>
                      <Grid item xs={12}>
                        {`${amount} ${translateProductUnit(
                          data.positions[index].product.productUnits.find(
                            (productUnit) =>
                              data.positions[index].productUnitId ===
                              productUnit.id,
                          ),
                        )}`}
                      </Grid>
                    </Grid>
                  ) : (
                    <Typography>
                      {`${amount} ${translateProductUnit(
                        data.positions[index].product.productUnits.find(
                          (productUnit) => productUnit.isTenderUnit,
                        ),
                      )}`}
                    </Typography>
                  )}
                </PositionCard>
              </Grid>
            )
          })}
        </Grid>
      </Section>

      {data.positions.length > 0 &&
        data.generalInformation.deliveryDetails.locations.map(
          (location, locationIndex) => (
            <Section
              variant={'h6'}
              header={
                <Fragment>
                  {t('REQUEST.LOCATION')}
                  {' ' + (locationIndex + 1)}
                  {location.company ? ` | ${location.company}` : null}
                  {location.countryId
                    ? ` | ${countryLookup[location.countryId]}`
                    : null}
                  {location.address ? ` | ${location.address}` : null}
                  {location.zipcode ? ` | ${location.zipcode}` : null}
                  {location.place ? ` | ${location.place}` : null}
                </Fragment>
              }
            >
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
              >
                {location.positions.map((pos, posIndex) => {
                  return (
                    <Grid item xs={12} md={6} lg={4}>
                      <PositionCard
                        data={pos}
                        readOnly={true}
                        showProductDetails={true}
                        showImage={true}
                        imageSize={'small'}
                      >
                        <Grid
                          container
                          spacing={1}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t('REQUEST.AMOUNT')}
                              variant="outlined"
                              name={`generalInformation.deliveryDetails.locations[${locationIndex}].positions[${posIndex}].amount`}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {translateProductUnit(
                                      data.positions[
                                        posIndex
                                      ].product.productUnits.find(
                                        (productUnit) => {
                                          return (
                                            productUnit.id ===
                                            data.positions[posIndex]
                                              .productUnitId
                                          )
                                        },
                                      ),
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                decimalScale:
                                  data.generalInformation.deliveryDetails
                                    .locations[locationIndex].positions[
                                    posIndex
                                  ].productUnitId &&
                                  data.generalInformation.deliveryDetails.locations[
                                    locationIndex
                                  ].positions[
                                    posIndex
                                  ].product.productUnits.find((productUnit) => {
                                    return (
                                      productUnit.id ===
                                      data.generalInformation.deliveryDetails
                                        .locations[locationIndex].positions[
                                        posIndex
                                      ].productUnitId
                                    )
                                  }).type === 'FLOAT'
                                    ? 2
                                    : 0,
                                allowNegative: false,
                              }}
                              disabled={readOnly}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              disabled={readOnly}
                              fullWidth={true}
                              component={TextField}
                              margin="dense"
                              size={'small'}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              inputProps={{
                                decimalScale: 2,
                                allowNegative: false,
                                suffix: ' \u20AC',
                                fixedDecimalScale: true,
                              }}
                              label={`${t('GENERAL.TARGET_PRICE')} (${t(
                                'GENERAL.OPTIONAL',
                              )})`}
                              variant="outlined"
                              name={`generalInformation.deliveryDetails.locations[${locationIndex}].positions[${posIndex}].targetPrice`}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item xs={11}>
                                <Field
                                  component={TextField}
                                  name={`generalInformation.deliveryDetails.locations[${locationIndex}].positions[${posIndex}].index`}
                                  select
                                  fullWidth
                                  label={t('REQUEST.REFERENCE_INDEX')}
                                  variant="outlined"
                                  margin="dense"
                                >
                                  <MenuItem value={null} key={null}>
                                    {t('REQUEST.NO_REFERENCE_INDEX')}
                                  </MenuItem>
                                  {getIndexesOfPosition(pos).map(
                                    (identifier) => (
                                      <MenuItem
                                        value={identifier}
                                        key={identifier}
                                      >
                                        {indexLookup[identifier]}
                                      </MenuItem>
                                    ),
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={1}>
                                <Tooltip
                                  title={t('REQUEST.REFERENCE_INDEX_TOOLTIP')}
                                >
                                  <Info color={'info'} fontSize="medium" />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </PositionCard>
                    </Grid>
                  )
                })}
              </Grid>
            </Section>
          ),
        )}
    </Fragment>
  )
}

export default withTranslation()(connect()(TenderPositions))
