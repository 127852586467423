import { Add, Delete, Edit } from '@mui/icons-material'
import { Button, IconButton, List, ListItem, ListItemText } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  productLookup,
  productStateLookup,
} from '../../../../../../_constants/lookupConstants'
import { productPriceLimitsService } from '../../../../../../_services/productPriceLimitsService'
import AgreementDialog from '../../../../../AgreementDialog'
import { Loading } from '../../../../../Loading'
import ComposeProductPriceLimitsDialog from './ComposeProductPriceLimitsDialog'

const ProductPriceLimits = (props) => {
  const { t } = props

  const [isLoading, setIsLoading] = useState(true)
  const [productPriceLimits, setProductPriceLimits] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)

  useEffect(() => {
    setIsLoading(true)
    productPriceLimitsService.getProductPriceLimits().then((data) => {
      setProductPriceLimits(data)
      setIsLoading(false)
    })
  }, [forceUpdateCount])

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Fragment>
        <Button
          size="medium"
          color={'secondary'}
          variant={'contained'}
          startIcon={<Add />}
          onClick={() => {
            setSelectedItem(null)
            setShowCreateDialog(true)
          }}
        >
          {t('PRODUCT_PRICE_LIMITS.ADD_PRODUCT_LIMIT')}
        </Button>
        <List sx={{ width: '40%' }}>
          {productPriceLimits.map((productPriceLimit) => (
            <ListItem
              secondaryAction={
                <Fragment>
                  <IconButton
                    onClick={() => {
                      setSelectedItem(productPriceLimit)
                      setShowCreateDialog(true)
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSelectedItem(productPriceLimit)
                      setShowDeleteDialog(true)
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Fragment>
              }
              sx={{
                marginBottom: '10px', // fügt einen Abstand unter jedem Listenelement hinzu
                borderBottom: '1px solid #ccc', // fügt eine untere Umrandung hinzu
                padding: '10px 0', // optional: fügt etwas Innenabstand hinzu
              }}
            >
              <ListItemText
                primary={productLookup[productPriceLimit.productId]}
                secondary={productStateLookup[productPriceLimit.state]}
              ></ListItemText>
            </ListItem>
          ))}
        </List>
        <AgreementDialog
          open={showDeleteDialog}
          message={t('PRODUCT_PRICE_LIMITS.DELETE_MESSAGE')}
          acceptButtonText={t('GENERAL.CONFIRM')}
          handleClose={() => {
            setShowDeleteDialog(false)
            setSelectedItem(null)
          }}
          agree={() => {
            setIsLoading(true)
            setShowDeleteDialog(false)
            productPriceLimitsService
              .deleteProductPriceLimit(selectedItem.id)
              .then(() => {
                forceUpdate(forceUpdateCount + 1)
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                setIsLoading(false)
                setSelectedItem(null)
              })
          }}
        />
        {showCreateDialog && (
          <ComposeProductPriceLimitsDialog
            productPriceLimit={selectedItem}
            hide={() => {
              setSelectedItem(null)
              setShowCreateDialog(false)
            }}
            forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
          />
        )}
      </Fragment>
    )
  )
}

export default withTranslation()(ProductPriceLimits)
