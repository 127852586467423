import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material'
import { Field, FieldArray, Form, Formik } from 'formik'
import { CheckboxWithLabel, Select, TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { alertActions } from '../../../../../../../../_actions/alertActions'
import NumberFormatCustom from '../../../../../../../../_helpers/numberFormatCustom'
import { surveyQuestionsService } from '../../../../../../../../_services/surveyQuestionsService'
import { surveysService } from '../../../../../../../../_services/surveysService'
import { Loading } from '../../../../../../../Loading'

const ComposeSurveyDialog = (props) => {
  const { t, surveyId, show, hide, forceUpdate, dispatch, mode } = props
  const [isLoading, setIsLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    id: undefined,
    title: '',
    type: 'CUSTOMER',
    isRequired: false,
    upToDateTimeInDays: '',
    state: 'ARCHIVED',
    questions: [],
  })
  const [removedQuestions, setRemovedQuestions] = useState([])

  useEffect(() => {
    const fetchSurveyData = async () => {
      if (surveyId && mode === 'EDIT') {
        setIsLoading(true)
        try {
          const surveyData = await surveysService.getSurvey(surveyId)
          let questions = surveyData.surveyQuestions || []
          questions = questions.map((question) => ({
            ...question,
            answers: question.surveyQuestionOfferedAnswers || [],
          }))

          setInitialValues({
            id: surveyData.id,
            title: surveyData.title,
            type: surveyData.type,
            isRequired: surveyData.isRequired,
            upToDateTimeInDays: surveyData.upToDateTimeInDays,
            state: surveyData.state,
            questions: questions.map((q) => ({
              id: q.id,
              renderPosition: q.renderPosition,
              questionText: q.text,
              type: q.type,
              answers: q.answers.map((a) => ({
                id: a.id,
                renderPosition: a.renderPosition,
                answerText: a.value,
              })),
            })),
          })
        } catch (error) {
          alertActions.error(t('SURVEY.FETCH_ERROR'))
        } finally {
          setIsLoading(false)
        }
      }
    }
    fetchSurveyData()
  }, [surveyId, mode, t])

  const valSchema = Yup.object().shape({
    title: Yup.string().required(t('GENERAL.REQUIRED')),
    type: Yup.string().required(t('GENERAL.REQUIRED')),
    isRequired: Yup.boolean(),
    upToDateTimeInDays: Yup.number().nullable(true),
    questions: Yup.array().of(
      Yup.object().shape({
        renderPosition: Yup.number().required(t('GENERAL.REQUIRED')),
        questionText: Yup.string().required(t('GENERAL.REQUIRED')),
        type: Yup.string().required(t('GENERAL.REQUIRED')),
        answers: Yup.array()
          .of(
            Yup.object().shape({
              renderPosition: Yup.number().required(t('GENERAL.REQUIRED')),
              answerText: Yup.string().required(t('GENERAL.REQUIRED')),
            }),
          )
          .nullable(true),
      }),
    ),
  })

  const handleAddQuestion = (push, values) => {
    push({
      renderPosition: values.questions.length + 1,
      questionText: '',
      type: 'TEXT',
      answers: [],
    })
  }

  const handleRemoveQuestion = (index, question, remove) => {
    if (question.id) {
      setRemovedQuestions([...removedQuestions, question.id])
    }
    remove(index)
  }

  const handleSave = async (values, { setSubmitting }) => {
    setSubmitting(true)

    let survey
    if (mode === 'EDIT') {
      survey = await surveysService.updateSurvey(surveyId, values)
    } else {
      survey = await surveysService.createSurvey(values)
    }

    const addedQuestions = values.questions.filter((q) => !q.id)
    const updatedQuestions = values.questions.filter(
      (q) => q.id && !removedQuestions.includes(q.id),
    )
    const removedQuestionIds = removedQuestions

    try {
      for (const question of addedQuestions) {
        await surveyQuestionsService.createSurveyQuestionOfSurvey(
          survey.id || surveyId,
          question,
        )
      }

      for (const question of updatedQuestions) {
        await surveyQuestionsService.updateSurveyQuestionOfSurvey(
          question.id,
          question,
        )
      }

      for (const id of removedQuestionIds) {
        await surveyQuestionsService.deleteSurveyQuestionOfSurvey(id)
      }
    } catch (error) {
      console.error('Error updating survey questions:', error)
    } finally {
      setSubmitting(false)
      hide()
      alertActions.info(t('SURVEY.SURVEY_SAVED'))
      setTimeout(() => {
        alertActions.clear()
      }, alertActions.alertTimeout)

      forceUpdate()
    }
  }

  const handleTypeChange = (event, setFieldValue, index) => {
    const newType = event.target.value
    setFieldValue(`questions[${index}].type`, newType)
    setFieldValue(`questions[${index}].answers`, [])
    if (newType === 'SELECT' || newType === 'MULTI_SELECT') {
      setFieldValue(`questions[${index}].answers`, [
        { renderPosition: 1, answerText: '' },
      ])
    }
  }

  return (
    <Fragment>
      {isLoading ? (
        <Loading variant={'centered'} />
      ) : (
        <Formik
          validationSchema={valSchema}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleSave}
        >
          {({ isSubmitting, values, submitForm, setFieldValue }) => (
            <Form>
              <Dialog open={show} onClose={hide} maxWidth="xl">
                <DialogTitle align="center">
                  {mode === 'EDIT'
                    ? t('SURVEY.EDIT_SURVEY')
                    : t('SURVEY.CREATE_SURVEY')}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        component={TextField}
                        name="title"
                        label={t('SURVEY.TITLE')}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        component={TextField}
                        name="upToDateTimeInDays"
                        label={t('SURVEY.UP_TO_DATE_TIME_IN_DAYS')}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={CheckboxWithLabel}
                        type="checkbox"
                        name="isRequired"
                        Label={{ label: t('SURVEY.IS_REQUIRED') }}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        component={Select}
                        name="type"
                        label={t('SURVEY.TYPE')}
                        margin="normal"
                      >
                        <MenuItem value="CUSTOMER">
                          {t('SURVEY.CUSTOMER')}
                        </MenuItem>
                        <MenuItem value="MANUFACTURER">
                          {t('SURVEY.MANUFACTURER')}
                        </MenuItem>
                        <MenuItem value="ANY">{t('SURVEY.ANY')}</MenuItem>
                      </Field>
                    </Grid>
                  </Grid>
                  <FieldArray name="questions">
                    {({ remove, push }) => (
                      <Fragment>
                        <Grid container spacing={2} style={{ marginTop: 16 }}>
                          <Grid item xs={2}>
                            <Typography variant="body2">
                              {t('SURVEY.QUESTION_NR')}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="body2">
                              {t('SURVEY.QUESTION_TYPE')}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2">
                              {t('SURVEY.QUESTION_TEXT')}
                            </Typography>
                          </Grid>
                          <Grid item xs={1}></Grid>
                        </Grid>
                        {values.questions &&
                          values.questions.map((question, index) => (
                            <Fragment key={index}>
                              <Grid container spacing={2}>
                                <Grid
                                  container
                                  spacing={2}
                                  alignItems="center"
                                  style={{ marginTop: 8 }}
                                >
                                  <Grid item xs={2}>
                                    <Field
                                      fullWidth
                                      component={TextField}
                                      name={`questions[${index}].renderPosition`}
                                      margin="normal"
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                        inputProps: {
                                          decimalScale: 0,
                                          allowNegative: false,
                                        },
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Field
                                      fullWidth
                                      component={Select}
                                      name={`questions[${index}].type`}
                                      onChange={(event) =>
                                        handleTypeChange(
                                          event,
                                          setFieldValue,
                                          index,
                                        )
                                      }
                                      margin="normal"
                                      style={{ verticalAlign: 'middle' }}
                                    >
                                      <MenuItem value="SELECT">
                                        {t('SURVEY.SELECT')}
                                      </MenuItem>
                                      <MenuItem value="MULTI_SELECT">
                                        {t('SURVEY.MULTI_SELECT')}
                                      </MenuItem>
                                      <MenuItem value="TEXT">
                                        {t('SURVEY.TEXT')}
                                      </MenuItem>
                                    </Field>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Field
                                      fullWidth
                                      component={TextField}
                                      name={`questions[${index}].questionText`}
                                      margin="normal"
                                    />
                                  </Grid>
                                  <Grid item xs={1}>
                                    <IconButton
                                      color="secondary"
                                      onClick={() =>
                                        handleRemoveQuestion(
                                          index,
                                          question,
                                          remove,
                                        )
                                      }
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                                {(question.type === 'MULTI_SELECT' ||
                                  question.type === 'SELECT') && (
                                  <FieldArray
                                    name={`questions[${index}].answers`}
                                  >
                                    {({
                                      remove: removeAnswer,
                                      push: pushAnswer,
                                    }) => (
                                      <Grid
                                        container
                                        spacing={2}
                                        style={{ marginTop: 8 }}
                                      >
                                        <Grid item xs={2} />
                                        <Grid item xs={10}>
                                          <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                          >
                                            <Grid item xs={2}>
                                              <Typography variant="body2">
                                                {t('SURVEY.ANSWER_NR')}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                              <Typography variant="body2">
                                                {t('SURVEY.ANSWER_TEXT')}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={2} />
                                          </Grid>
                                          {question.answers.map(
                                            (answer, ansIndex) => (
                                              <Fragment key={ansIndex}>
                                                <Grid
                                                  container
                                                  spacing={2}
                                                  alignItems="center"
                                                  style={{ marginTop: 8 }}
                                                >
                                                  <Grid item xs={2}>
                                                    <Field
                                                      fullWidth
                                                      component={TextField}
                                                      name={`questions[${index}].answers[${ansIndex}].renderPosition`}
                                                      margin="normal"
                                                      InputProps={{
                                                        inputComponent:
                                                          NumberFormatCustom,
                                                        inputProps: {
                                                          decimalScale: 0,
                                                          allowNegative: false,
                                                        },
                                                      }}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={8}>
                                                    <Field
                                                      fullWidth
                                                      component={TextField}
                                                      name={`questions[${index}].answers[${ansIndex}].answerText`}
                                                      margin="normal"
                                                      label={t(
                                                        'SURVEY.ANSWER_TEXT',
                                                      )}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={2}>
                                                    <IconButton
                                                      color="secondary"
                                                      onClick={() =>
                                                        removeAnswer(ansIndex)
                                                      }
                                                    >
                                                      <RemoveIcon />
                                                    </IconButton>
                                                  </Grid>
                                                </Grid>
                                              </Fragment>
                                            ),
                                          )}
                                          <Button
                                            variant="contained"
                                            onClick={() =>
                                              pushAnswer({
                                                renderPosition:
                                                  question.answers.length + 1,
                                                answerText: '',
                                              })
                                            }
                                            startIcon={<AddIcon />}
                                            color="secondary"
                                            style={{ marginTop: 8 }}
                                          >
                                            {t('SURVEY.ADD_ANSWER')}
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    )}
                                  </FieldArray>
                                )}
                              </Grid>
                            </Fragment>
                          ))}
                        <Button
                          variant="contained"
                          onClick={() => handleAddQuestion(push, values)}
                          startIcon={<AddIcon />}
                          color={'secondary'}
                          style={{ marginTop: 16 }}
                        >
                          {t('SURVEY.ADD_QUESTION')}
                        </Button>
                      </Fragment>
                    )}
                  </FieldArray>
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" color="secondary" onClick={hide}>
                    {t('GENERAL.CANCEL')}
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    onClick={submitForm}
                    variant="contained"
                    color="secondary"
                  >
                    {t('GENERAL.SAVE')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  )
}

export default withTranslation()(connect()(ComposeSurveyDialog))
