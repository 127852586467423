import { Delete, SmartToy } from '@mui/icons-material'
import {
  Box,
  Chip,
  Grid,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  categorizedProductLookup,
  countryLookup,
  offerStateLookup,
  productLookup,
  requestTypeLookup,
} from '../../../../../../_constants/lookupConstants'
import { offersService } from '../../../../../../_services/offersService'
import AcceptCounterOfferDialog from '../../../../../AcceptCounterOfferDialog'
import AcceptOfferDialog from '../../../../../AcceptOfferDialog'
import AgreementDialog from '../../../../../AgreementDialog'
import { Loading } from '../../../../../Loading'
import OfferStepper from '../../../../../OfferStepper'
import Rank from '../../../../../Rank'
import ResourceList from '../../../../../ResourceList/_components/ResourceList'

const OffersOverview = (props) => {
  const { t } = props
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)

  const [showDeleteOfferDialog, setShowDeleteOfferDialog] = useState(false)
  const [showDeclineOfferDialog, setShowDeclineOfferDialog] = useState(false)
  const [showAcceptOfferDialog, setShowAcceptOfferDialog] = useState(false)
  const [showSendOfferDialog, setShowSendOfferDialog] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [mode, setMode] = React.useState('VIEW')

  return (
    <Fragment>
      {isLoading && <Loading variant={'centered'} />}

      <Fragment>
        {
          <ResourceList
            fetchMethod={(filterValues, pagination) =>
              offersService.getOffers(filterValues, pagination)
            }
            forceUpdateCount={forceUpdateCount}
            filterId={'ADMIN_OFFERS_OVERVIEW_V3'}
            filters={[
              {
                key: 'rootOfferId',
                label: t('OFFERS.OFFER_ID'),
                variant: 'textfield',
              },
              {
                key: 'requestId.startsWith',
                label: t('OFFERS.REQUEST_ID'),
                variant: 'textfield',
              },
              {
                key: 'state',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(offerStateLookup),
                lookup: offerStateLookup,
                label: t('OFFERS.STATE'),
              },
              {
                key: 'request.type',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(requestTypeLookup),
                lookup: requestTypeLookup,
                label: t('REQUESTS.REQUEST_TYPE'),
              },
              {
                key: 'positions.requestPosition.productId.eq',
                variant: 'categorizedCheckboxList',
                values: categorizedProductLookup.map((category) => {
                  return {
                    label: category.name,
                    items: category.products.map((product) => product.id),
                  }
                }),
                lookup: productLookup,
                label: t('REQUESTS.PRODUCT'),
              },
              {
                key: 'positions.location.countryId.eq',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(countryLookup),
                lookup: countryLookup,
                label: t('OFFERS.COUNTRY'),
              },
              {
                key: 'positions.location.zipcode.startsWith',
                label: t('REQUESTS.ZIPCODE'),
                variant: 'textfield',
              },
              {
                key: 'positions.location.place.startsWith',
                label: t('REQUESTS.PLACE'),
                variant: 'textfield',
              },
            ]}
            sorting={[
              {
                key: 'updatedAt',
                default: true,
                label: t('OFFERS.LAST_ACTIVITY'),
              },
            ]}
            resourceHeaderRenderFunction={(offer) =>
              `${t('OFFERS.OFFER')}: ${offer.rootOfferId} | ${
                requestTypeLookup[offer.requestType]
              }`
            }
            listHeaderRenderFunction={(offer) => {
              return (
                <Fragment>
                  <div>
                    <Box>
                      {offer.rank !== 'N/A' && <Rank rank={offer.rank} />}
                    </Box>
                    {`${requestTypeLookup[offer.requestType]}
                    : ${offer.requestId}`}
                  </div>
                  {`${t('OFFERS.LAST_ACTIVITY')} ${moment(
                    offer.updatedAt,
                  ).fromNow()}`}
                  <Fragment>
                    <div>{`${t('ORDERS.CUSTOMER')}: ${
                      offer.customerUser.firstname
                    } ${offer.customerUser.lastname} | ${
                      offer.customer.name
                    } (${offer.customer.id})`}</div>
                    <div>{`${t('ORDERS.MANUFACTURER')}: ${
                      offer.manufacturerUser.firstname
                    } ${offer.manufacturerUser.lastname} | ${
                      offer.manufacturer.name
                    } (${offer.manufacturer.id})`}</div>
                  </Fragment>
                </Fragment>
              )
            }}
            headerActionRenderFunction={(offer) => {
              return (
                <Fragment>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'center',
                    }}
                  >
                    {offer.automaticOfferPositionId && (
                      <Tooltip
                        title={`${t(
                          'OFFERS.CREATED_BY_OFFER_SERVICE_WITH_ID',
                        )}: ${offer.automaticOfferPositionId}`}
                      >
                        <SmartToy color="primary" sx={{ marginRight: 1 }} />
                      </Tooltip>
                    )}
                    <Chip
                      size={'small'}
                      color={
                        offer.state === 'EXPIRED' ||
                        offer.state === 'ARCHIVED' ||
                        offer.state === 'CANCLED'
                          ? 'default'
                          : 'info'
                      }
                      label={offerStateLookup[offer.state]}
                    />
                  </Box>
                </Fragment>
              )
            }}
            contentRenderFunction={(offer) => (
              <Fragment>
                <Grid container spacing={1} marginTop={1} marginBottom={1}>
                  <Grid item xs={12}>
                    {`${t('OFFERS.MANUFACTURER_COUNTRY')}: ${
                      countryLookup[offer.manufacturer.countryId]
                    }`}
                  </Grid>
                </Grid>
              </Fragment>
            )}
            resourceRenderFunction={(offer) => {
              return (
                <OfferStepper
                  rootOfferId={offer.rootOfferId}
                  forceUpdate={(seed) => {
                    forceUpdate(seed)
                  }}
                  cancelOffering={() => {
                    setMode('VIEW')
                  }}
                  mode={mode}
                />
              )
            }}
            actionsRenderFunction={(offer) => {
              return [
                {
                  icon: <Delete />,
                  name: t('OFFERS.DELETE_OFFER'),
                  hidden:
                    offer.state !== 'ACTIVE' ||
                    offer.requestState === 'ARCHIVED',
                  primary: false,
                  onClick: () => {
                    setSelectedOffer(offer)
                    setShowDeleteOfferDialog(true)
                  },
                },
              ]
            }}
            enableActionUpdateLoop={true}
            action
            dataIdentifier={'rootOfferId'}
          />
        }
        {selectedOffer && (
          <Fragment>
            {showAcceptOfferDialog && (
              <AcceptOfferDialog
                selectedOffer={selectedOffer}
                hide={() => setShowAcceptOfferDialog(false)}
                forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
              />
            )}
            {showSendOfferDialog && (
              <AcceptCounterOfferDialog
                selectedOffer={selectedOffer}
                hide={() => setShowSendOfferDialog(false)}
                forceUpdate={() => {
                  forceUpdate(forceUpdateCount + 1)
                }}
              />
            )}
            <AgreementDialog
              open={showDeleteOfferDialog}
              handleClose={() => {
                setShowDeleteOfferDialog(false)
              }}
              agree={() => {
                setIsLoading(true)
                offersService
                  .deleteOffer(selectedOffer.id)
                  .then(() => {
                    forceUpdate(forceUpdateCount + 1)
                  })
                  .catch(function (error) {
                    console.log(error)
                  })
                  .finally(() => {
                    setIsLoading(false)
                    forceUpdate(forceUpdateCount + 1)
                    setShowDeleteOfferDialog(false)
                  })
              }}
            />
            <AgreementDialog
              open={showDeclineOfferDialog}
              handleClose={() => {
                setShowDeclineOfferDialog(false)
              }}
              agree={() => {
                setIsLoading(true)
                offersService
                  .declineOffer(selectedOffer.id)
                  .catch(function (error) {
                    console.log(error)
                  })
                  .finally(() => {
                    setIsLoading(false)
                    forceUpdate(forceUpdateCount + 1)
                    setShowDeclineOfferDialog(false)
                  })
              }}
            />
          </Fragment>
        )}
      </Fragment>
    </Fragment>
  )
}

export default withTranslation()(OffersOverview)
