import { Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'

const PREFIX = 'InfoTooltip'

const classes = {
  tooltip: `${PREFIX}-tooltip`,
}

const WrappedTooltip = Tooltip

const StyledWrappedTooltip = styled(WrappedTooltip)({
  [`& .${classes.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.8rem',
    border: '1px solid #dadde9',
  },
})

export const InfoTooltip = (props) => {
  return (
    <StyledWrappedTooltip {...props} placement="top-start">
      {props.children}
    </StyledWrappedTooltip>
  )
}
