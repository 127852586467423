import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import OrderRegistration from './_components/OrderRegistration'
import TenderOrderRegistration from './_components/TenderOrderRegistration'

const Registrations = (props) => {
  const { t, dispatch } = props

  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <TabContext value={selectedTab}>
      <TabList
        value={selectedTab}
        onChange={(e, value) => setSelectedTab(value)}
      >
        <Tab label={t('TAB.ORDER_REGISTRATION')} />
        <Tab label={t('TAB.TENDER_ORDER_REGISTRATION')} />
      </TabList>
      <TabPanel value={0}>
        <OrderRegistration />
      </TabPanel>
      <TabPanel value={1}>
        <TenderOrderRegistration />
      </TabPanel>
    </TabContext>
  )
}

export default withTranslation()(connect()(Registrations))
