import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import { default as Numeral, default as numeral } from 'numeral'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { translateProductUnit } from '../../../../_helpers/little'
import { generalService } from '../../../../_services/generalService'
import { productUnitsService } from '../../../../_services/productUnitsService'
import PositionCard from '../../../PositionCard'
import { Section } from '../../../Section'

const AdvertPositionsView = (props) => {
  const { t, data } = props
  const [productUnits, setProductUnits] = useState([])

  useEffect(() => {
    productUnitsService.getProductUnits().then((data) => setProductUnits(data))
  }, [])

  return (
    <Section header={t('REQUEST.ADVERT_POSITIONS')}>
      <Grid container spacing={3} direction="row" alignItems="stretch">
        {productUnits.length > 0 &&
          data.generalInformation.deliveryDetails.locations[0].positions.map(
            (pos, index) => {
              let positions =
                data.generalInformation.deliveryDetails.locations[0].positions

              let deliveriesWithPosition = data.deliveries.filter((delivery) =>
                delivery.positions.some(
                  (dPos) => pos.id === dPos.positionId && dPos.amount > 0,
                ),
              )
              return (
                <Fragment>
                  <Grid item xs={12} sm={6}>
                    <PositionCard
                      data={pos}
                      showProductDetails={true}
                      readOnly={true}
                      showAmount={true}
                      showImage={true}
                      imageSize={'small'}
                    >
                      <Typography>
                        <div>
                          {!pos.price
                            ? t('ADVERT.NO_PRICE')
                            : `${t('OFFER.PRICE_EACH')} ${
                                positions[index].product.productUnits.find(
                                  (productUnit) =>
                                    productUnit.id ===
                                    positions[index].productUnitId,
                                ).deliveryProductUnitId
                                  ? translateProductUnit(
                                      positions[
                                        index
                                      ].product.productUnits.find(
                                        (productUnit) =>
                                          productUnit.id ===
                                          positions[
                                            index
                                          ].product.productUnits.find(
                                            (productUnit) =>
                                              productUnit.id ===
                                              positions[index].productUnitId,
                                          ).deliveryProductUnitId,
                                      ),
                                    )
                                  : translateProductUnit(
                                      positions[
                                        index
                                      ].product.productUnits.find(
                                        (productUnit) =>
                                          productUnit.id ===
                                          positions[index].productUnitId,
                                      ),
                                    )
                              }: ${Numeral(parseFloat(pos.price)).format(
                                '0,0.00 $',
                              )}`}
                        </div>
                        <div>
                          {deliveriesWithPosition.map((delivery) => {
                            let dPos = delivery.positions.find(
                              (dPos) => pos.id === dPos.positionId,
                            )
                            return (
                              <div>
                                <Typography>{`${t('REQUEST.PICKUP_DATE')} ${
                                  delivery.type === 'date'
                                    ? moment(delivery.value).format(
                                        'DD.MM.YYYY',
                                      )
                                    : `${t(
                                        'ORDERS.CW',
                                      )} ${generalService.convertDateToCalendarWeek(
                                        delivery.value,
                                      )}`
                                }: ${numeral(parseFloat(dPos.amount)).format(
                                  '0,0.[00]',
                                )} ${translateProductUnit(
                                  productUnits.find(
                                    (productUnit) =>
                                      productUnit.id === pos.productUnitId,
                                  ),
                                )}`}</Typography>
                              </div>
                            )
                          })}
                        </div>
                      </Typography>
                    </PositionCard>
                  </Grid>
                </Fragment>
              )
            },
          )}
      </Grid>
    </Section>
  )
}

export default withTranslation()(connect()(AdvertPositionsView))
