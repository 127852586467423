import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const partnersService = {
  getPartner,
  updatePartner,
  createPartner,
  activatePartner,
  getPartners,
  deletePartner,
}

function getPartners() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/partners`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function getPartner(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}`,
    requestOptions,
  ).then(handleResponse)
}

function updatePartner(partnerId, requestBody) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }
  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}`,
    requestOptions,
  ).then(handleResponse)
}

function createPartner(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }

  return fetch(`${config.apiUrlBase}/partners`, requestOptions).then(
    handleResponse,
  )
}

function activatePartner(partnerId, requestBody) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/activate`,
    requestOptions,
  ).then(handleResponse)
}

function deletePartner(partnerId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './partnersService'
