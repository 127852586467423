import config from '../_config/config.js'
import { authHeader } from '../_helpers/auth-header'

export const tradeAgreementsService = {
  getTradeAgreements,
  updateTradeAgreements,
}

function getTradeAgreements() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/tradeagreements`, requestOptions)
}

function updateTradeAgreements(formData) {
  const requestOptions = {
    method: 'PUT',
    headers: authHeader(),
    body: formData,
  }

  return fetch(`${config.apiUrlBase}/tradeagreements`, requestOptions)
}

export * from './tradeAgreementsService'
