import { Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

const CubicMeterCalculator = () => {
  const { values } = useFormikContext() // Zugriff auf Formik-Werte
  const [cubicMeters, setCubicMeters] = useState(0) // Zustand für die Kubikmeter

  const calculateCubicMetersFromFormikValues = (values) => {
    let cubicMeter = 0

    const topboards = values.find(
      (positionProperty) => positionProperty.productProperty.id === 40,
    )
    const crossboards = values.find(
      (positionProperty) => positionProperty.productProperty.id === 41,
    )
    const blocks = values.find(
      (positionProperty) => positionProperty.productProperty.id === 42,
    )
    const bottomBoards = values.find(
      (positionProperty) => positionProperty.productProperty.id === 43,
    )
    const squareTimbers = values.find(
      (positionProperty) => positionProperty.productProperty.id === 55,
    )

    if (topboards) {
      topboards.value.forEach((innerArray) => {
        let amount = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 3).value,
        )
        let length = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 5).value,
        )
        let width = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 6).value,
        )
        let thickness = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 7).value,
        )
        cubicMeter = cubicMeter + amount * (length * width * thickness)
      })
    }

    if (crossboards) {
      crossboards.value.forEach((innerArray) => {
        let amount = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 4).value,
        )
        let length = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 8).value,
        )
        let width = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 9).value,
        )
        let thickness = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 10).value,
        )
        cubicMeter = cubicMeter + amount * (length * width * thickness)
      })
    }

    if (blocks) {
      blocks.value.forEach((innerArray) => {
        let amount = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 11).value,
        )
        let length = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 12).value,
        )
        let width = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 13).value,
        )
        let thickness = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 14).value,
        )
        cubicMeter = cubicMeter + amount * (length * width * thickness)
      })
    }

    if (bottomBoards) {
      bottomBoards.value.forEach((innerArray) => {
        let amount = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 15).value,
        )
        let length = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 16).value,
        )
        let width = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 17).value,
        )
        let thickness = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 18).value,
        )
        cubicMeter = cubicMeter + amount * (length * width * thickness)
      })
    }

    if (squareTimbers) {
      squareTimbers.value.forEach((innerArray) => {
        let amount = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 51).value,
        )
        let length = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 52).value,
        )
        let width = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 53).value,
        )
        let thickness = parseFloat(
          innerArray.find((pair) => pair.productPropertyId === 54).value,
        )
        cubicMeter = cubicMeter + amount * (length * width * thickness)
      })
    }

    return cubicMeter / 1000000000
  }
  useEffect(() => {
    const totalCubicMeters = calculateCubicMetersFromFormikValues(values)
    setCubicMeters(totalCubicMeters)
  }, [values])

  return (
    <Typography
      gutterBottom
      variant="h6"
      component="div"
      sx={{ marginTop: '20px' }}
    >
      {`${t('PRODUCT_CONFIGURATOR.CUBIC_METER')} ${cubicMeters.toFixed(4)}m³`}
    </Typography>
  )
}

export default withTranslation()(CubicMeterCalculator)
