import {
  Breadcrumbs,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField as MUITextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Form, Formik } from 'formik'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  translateProduct,
  translateProductCategory,
  translateProductPropertyCategory,
} from '../../_helpers/little'
import {
  generateProductConfiguratorField,
  generateValidationSchemaOfProduct,
  isProductPropertyConditionFullfilled,
} from '../../_helpers/productPropertyFieldGenerator'
import { productCategoriesService } from '../../_services/productCategoriesService'
import { productPropertyCategoriesService } from '../../_services/productPropertyCategoriesService'
import { productsService } from '../../_services/productsService'
import CustomDialogTitle from '../CustomDialogTitle'
import { Loading } from '../Loading'
import { Section } from '../Section'
import CubicMeterCalculator from './_components/cubicMeterCalculator'

const ProductConfigurator = (props) => {
  const {
    t,
    show,
    close,
    readOnly,
    data,
    createPositionFunction,
    updatePositionFunction,
    showDifference,
    diffObjectType,
    differences,
    parentType,
  } = props

  const [isLoading, setIsLoading] = useState(true)

  const [initialValues, setInitialValues] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedProductCategory, setSelectedProductCategory] = useState(null)
  const [products, setProducts] = useState([])
  const [categoryProducts, setCategoryProducts] = useState([])
  const [productCategories, setProductCategories] = useState([])
  const [productPropertyCategories, setProductPropertyCategories] = useState([])

  //positionBackup is required for 2D_ARRAYS, since setFieldValue is used to add/remove more rows (2. Dimension)
  const [positionPropertiesBackup, setPositionPropertiesBackup] = useState(null)

  const [searchValue, setSearchValue] = useState('')

  const theme = useTheme()

  useEffect(
    () => {
      if (show) {
        setIsLoading(true)
        productCategoriesService
          .getProductCategories(false)
          .then((productCategories) => {
            setProductCategories(productCategories)
            productPropertyCategoriesService
              .getProductPropertyCategories()
              .then(async (productPropertyCategories) => {
                setProductPropertyCategories(productPropertyCategories)
                if (data) {
                  let currentProduct = await productsService.getProduct(
                    data.productId,
                  )
                  setSelectedProduct(currentProduct)

                  let targetProductCategory = productCategories.find(
                    (productCategory) =>
                      productCategory.id === currentProduct.productCategoryId,
                  )

                  setSelectedProductCategory(targetProductCategory)

                  //Deep copy required, since otherwise 2D_Array elements only get referenced. This causes the backup to be invalid.
                  setPositionPropertiesBackup(
                    JSON.parse(JSON.stringify(data.positionProperties)),
                  )
                } else {
                  let allProducts = await productsService.getProducts()
                  setProducts(allProducts)
                }
                setIsLoading(false)
              })
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [show],
  )

  useEffect(() => {
    if (selectedProductCategory) {
      async function fetchProducts(productCategoryId) {
        setIsLoading(true)
        let productsOfCategory =
          await productsService.getProductsOfProductCategory(productCategoryId)
        setCategoryProducts(productsOfCategory)

        setIsLoading(false)
      }
      fetchProducts(selectedProductCategory.id)
    }
  }, [selectedProductCategory])

  useEffect(() => {
    if (searchValue === '' && !data && selectedProductCategory) {
      async function fetchProducts(productCategoryId) {
        setIsLoading(true)
        let productsOfCategory =
          await productsService.getProductsOfProductCategory(productCategoryId)
        setCategoryProducts(productsOfCategory)

        setIsLoading(false)
      }
      fetchProducts(selectedProductCategory.id)
    } else {
      let fittingProducts = []
      products.forEach((product) => {
        if (
          product.productLocalizations.some((productLocalization) =>
            productLocalization.value
              .toLowerCase()
              .includes(searchValue.toLowerCase()),
          )
        ) {
          fittingProducts.push(product)
        }
      })
      setCategoryProducts(fittingProducts)
    }
  }, [searchValue])

  useEffect(() => {
    if (selectedProduct) {
      let newInitialValues = []
      selectedProduct.productProperties.forEach((productProperty) => {
        newInitialValues.push(
          data &&
            data.positionProperties.some(
              (positionProperty) =>
                positionProperty.productProperty.id === productProperty.id,
            ) &&
            data.productId === selectedProduct.id
            ? {
                value: data.positionProperties.find((positionProperty) => {
                  return (
                    positionProperty.productProperty.id === productProperty.id
                  )
                }).value,
                productProperty: productProperty,
              }
            : productProperty.type === 'BOOLEAN' &&
              !productProperty.defaultValue
            ? {
                value: false,
                productProperty: productProperty,
              }
            : productProperty.type === 'ARRAY'
            ? {
                value:
                  productProperty.productPropertyArray.productPropertyArrayElements.map(
                    (arrayElement) =>
                      productProperty.defaultValue.some(
                        (dValue) =>
                          dValue.productPropertyId ===
                          arrayElement.productProperty.id,
                      )
                        ? {
                            value:
                              productProperty.defaultValue[
                                productProperty.defaultValue.findIndex(
                                  (dValue) =>
                                    dValue.productPropertyId ===
                                    arrayElement.productProperty.id,
                                )
                              ].value,
                            productPropertyId: arrayElement.productProperty.id,
                          }
                        : arrayElement.productProperty.type === 'BOOLEAN'
                        ? false
                        : null,
                  ),
                productProperty: productProperty,
              }
            : productProperty.type === '2D_ARRAY'
            ? {
                value: [
                  ...Array(productProperty.defaultArrayLength).keys(),
                ].map(() =>
                  productProperty.productPropertyArray.productPropertyArrayElements.map(
                    (arrayElement) =>
                      productProperty.defaultValue.some(
                        (dValue) =>
                          dValue.productPropertyId ===
                          arrayElement.productProperty.id,
                      )
                        ? {
                            value:
                              productProperty.defaultValue[
                                productProperty.defaultValue.findIndex(
                                  (dValue) =>
                                    dValue.productPropertyId ===
                                    arrayElement.productProperty.id,
                                )
                              ].value,
                            productPropertyId: arrayElement.productProperty.id,
                          }
                        : arrayElement.productProperty.type === 'BOOLEAN'
                        ? false
                        : null,
                  ),
                ),
                productProperty: productProperty,
              }
            : productProperty.type === 'ENUM' && !productProperty.defaultValue
            ? {
                value: '',
                productProperty: productProperty,
              }
            : {
                value: productProperty.defaultValue,
                productProperty: productProperty,
              },
        )
      })
      setInitialValues(newInitialValues)
    }
  }, [selectedProduct])

  let currentProductCategories = selectedProductCategory
    ? productCategories.filter(
        (productCategory) =>
          productCategory.parentId === selectedProductCategory.id,
      )
    : productCategories.filter(
        (productCategory) => productCategory.parentId === null,
      )

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <Dialog open={show} fullWidth={true} maxWidth="xl">
      <CustomDialogTitle
        title={
          <Fragment>
            {readOnly
              ? t('PRODUCT_CONFIGURATOR.PRODUCT_DETAILS')
              : t('PRODUCT_CONFIGURATOR.CONFIGURE_PRODUCT')}
            {!readOnly && parentType !== 'OFFER_POSITION' && (
              <Breadcrumbs>
                {
                  <Button
                    onClick={() => {
                      setSelectedProduct(null)
                      setCategoryProducts([])
                      setSelectedProductCategory(null)
                      setSearchValue('')
                    }}
                    color={'secondary'}
                  >
                    {t('PRODUCT_CONFIGURATOR.START')}
                  </Button>
                }
                {selectedProductCategory &&
                  getParentCategories(selectedProductCategory, [
                    selectedProductCategory,
                  ])
                    .slice(0)
                    .reverse()
                    .map((productCategory) => (
                      <Button
                        onClick={() => {
                          setSelectedProduct(null)
                          setSelectedProductCategory(productCategory)
                          setSearchValue('')
                        }}
                        color={'secondary'}
                      >
                        {translateProductCategory(productCategory)}
                      </Button>
                    ))}
                {selectedProduct && (
                  <Typography variant={'button'}>
                    {translateProduct(selectedProduct)}
                  </Typography>
                )}
              </Breadcrumbs>
            )}
          </Fragment>
        }
        onClose={() => {
          close()
          setSelectedProduct(null)
          setSelectedProductCategory(null)
        }}
      />
      {isLoading && (
        <DialogContent>
          <Loading />
        </DialogContent>
      )}
      {!isLoading && (
        <Fragment>
          {selectedProduct && selectedProductCategory ? (
            <Formik
              enableReinitialize={true}
              validationSchema={generateValidationSchemaOfProduct(
                selectedProduct,
              )}
              initialValues={initialValues}
              // isInitialValid={false}
              onSubmit={(values, { setSubmitting }) => {
                let positionProperties = values

                positionProperties = positionProperties.filter(
                  (positionProperty) =>
                    isProductPropertyConditionFullfilled(
                      positionProperty.productProperty.conditions,
                      positionProperties,
                    ),
                )

                if (data) {
                  updatePositionFunction(selectedProduct, positionProperties)
                } else {
                  createPositionFunction(selectedProduct, positionProperties)
                }
                setSubmitting(false)
                setSelectedProduct(null)
                setSelectedProductCategory(null)
              }}
            >
              {({
                values,
                setValues,
                setFieldValue,
                isSubmitting,
                errors,
                setFieldError,
                setFieldTouched,
                touched,
                submitForm,
                setTouched,
                validateForm,
              }) =>
                values.length > 0 ? (
                  <Fragment>
                    <DialogContent>
                      <Form>
                        <Fragment>
                          {productPropertyCategories
                            .filter((productPropertyCategory) =>
                              selectedProduct.productProperties.some(
                                (productProperty) =>
                                  productProperty.productPropertyCategoryId ===
                                  productPropertyCategory.id,
                              ),
                            )
                            .sort((a, b) => a.renderPosition - b.renderPosition)
                            .map((productPropertyCategory, index) => (
                              <Section>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} marginTop={2}>
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      component="div"
                                    >
                                      {translateProductPropertyCategory(
                                        productPropertyCategory,
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={
                                      index === 0 &&
                                      selectedProduct &&
                                      !isMobile
                                        ? 6
                                        : 12
                                    }
                                  >
                                    <Grid
                                      container
                                      spacing={4}
                                      alignItems={'center'}
                                    >
                                      {values
                                        .map(
                                          (positionProperty) =>
                                            positionProperty.productProperty,
                                        )
                                        .filter(
                                          (productProperty) =>
                                            productProperty.productPropertyCategoryId ===
                                              productPropertyCategory.id &&
                                            isProductPropertyConditionFullfilled(
                                              productProperty.conditions,
                                              values,
                                            ),
                                        )
                                        .sort(
                                          (a, b) =>
                                            a.renderPosition - b.renderPosition,
                                        )
                                        .map((productProperty) => {
                                          return (
                                            <Grid
                                              item
                                              sm={productProperty.width}
                                              xs={12}
                                            >
                                              {generateProductConfiguratorField(
                                                productProperty,
                                                productProperty.readonly
                                                  ? undefined
                                                  : `[${values.findIndex(
                                                      (value) =>
                                                        value.productProperty
                                                          .id ===
                                                        productProperty.id,
                                                    )}].value`,
                                                productProperty.readonly
                                                  ? productProperty.defaultValue
                                                  : values[
                                                      values.findIndex(
                                                        (value) =>
                                                          value.productProperty
                                                            .id ===
                                                          productProperty.id,
                                                      )
                                                    ].value,
                                                setFieldValue,
                                                readOnly ||
                                                  !isProductPropertyConditionFullfilled(
                                                    productProperty.disableConditions,
                                                    values,
                                                  ),
                                                showDifference,
                                                diffObjectType,
                                                differences,
                                                values,
                                                isMobile,
                                              )}
                                            </Grid>
                                          )
                                        })}
                                    </Grid>
                                  </Grid>
                                  {index === 0 &&
                                    selectedProduct &&
                                    !isMobile && (
                                      <Grid
                                        item
                                        xs={6}
                                        sx={{
                                          backgroundImage: `url(${selectedProduct.imageRef})`,
                                          backgroundSize: 'contain',
                                          backgroundRepeat: 'no-repeat',
                                          backgroundPosition: 'center',
                                        }}
                                      />
                                    )}
                                </Grid>
                                {values &&
                                  selectedProduct.id === 1 &&
                                  index === 1 && (
                                    <CubicMeterCalculator
                                      formikValues={values}
                                    />
                                  )}
                              </Section>
                            ))}
                        </Fragment>
                      </Form>
                    </DialogContent>
                    <DialogActions>
                      {!readOnly &&
                        selectedProductCategory &&
                        searchValue === '' &&
                        parentType !== 'OFFER_POSITION' && (
                          <Button
                            onClick={() => {
                              setSelectedProductCategory(
                                productCategories.find(
                                  (productCategory) =>
                                    productCategory.id ===
                                    selectedProductCategory.parentId,
                                ),
                              )
                              setSelectedProduct(null)
                            }}
                            variant={'outlined'}
                            color={'secondary'}
                          >
                            {t('PRODUCT_CONFIGURATOR.BACK')}
                          </Button>
                        )}
                      {!readOnly && (
                        <Fragment>
                          <Button
                            color={'secondary'}
                            variant="outlined"
                            onClick={(e) => {
                              close()

                              if (data) {
                                updatePositionFunction(
                                  data.product,
                                  positionPropertiesBackup,
                                )
                              }

                              setSelectedProduct(null)
                              setSelectedProductCategory(null)
                            }}
                          >
                            {t('GENERAL.CANCEL')}
                          </Button>
                          <Button
                            color="secondary"
                            variant="contained"
                            disabled={isSubmitting}
                            onClick={async () => {
                              await submitForm()
                              //setTouched(getTouchedObj(errors)) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                            }}
                          >
                            {t('GENERAL.SUBMIT')}
                          </Button>
                        </Fragment>
                      )}
                    </DialogActions>
                  </Fragment>
                ) : null
              }
            </Formik>
          ) : (
            <Fragment>
              {!readOnly && (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3}>
                    <MUITextField
                      fullWidth={true}
                      label={t('PRODUCT_CONFIGURATOR.SEARCH_PRODUCT')}
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      sx={{ margin: 1 }}
                    />
                  </Grid>
                </Grid>
              )}
              {(selectedProductCategory || searchValue !== '') &&
                categoryProducts.length > 0 && (
                  <Grid
                    container
                    justifyContent={'start'}
                    alignItems={'center'}
                  >
                    {categoryProducts
                      .sort((a, b) => a.renderPosition - b.renderPosition)
                      .map((product) => (
                        <Grid
                          item
                          sm={
                            (currentProductCategories.length +
                              categoryProducts.length) %
                              4 ===
                            0
                              ? 3
                              : 4
                          }
                          xs={12}
                        >
                          <Card
                            variant="outlined"
                            sx={{ margin: theme.spacing(1) }}
                          >
                            <CardActionArea
                              onClick={async () => {
                                let currentProduct =
                                  await productsService.getProduct(product.id)
                                setSelectedProduct(currentProduct)

                                let categoryOfSelectedProduct =
                                  productCategories.find(
                                    (productCategory) =>
                                      productCategory.id ===
                                      product.productCategoryId,
                                  )

                                if (
                                  !selectedProductCategory ||
                                  selectedProductCategory.id !==
                                    categoryOfSelectedProduct.id
                                ) {
                                  setSelectedProductCategory(
                                    categoryOfSelectedProduct,
                                  )
                                }
                              }}
                            >
                              {product.imageRef && (
                                <CardMedia
                                  component="img"
                                  height="275"
                                  image={product.imageRef}
                                  alt={product.name}
                                  sx={{
                                    padding: '1em 1em 0 1em',
                                    objectFit: 'contain',
                                  }}
                                />
                              )}
                              <CardContent>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                >
                                  {translateProduct(product)}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {product.description}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                )}
              {searchValue === '' && (
                <Grid
                  container
                  direction={'row'}
                  justifyContent={'start'}
                  alignItems={'center'}
                >
                  {currentProductCategories
                    .sort((a, b) => a.renderPosition - b.renderPosition)
                    .map((productCategory) => (
                      <Grid
                        item
                        sm={
                          (currentProductCategories.length +
                            categoryProducts.length) %
                            4 ===
                          0
                            ? 3
                            : 4
                        }
                        xs={12}
                      >
                        <Card
                          variant="outlined"
                          sx={{ margin: theme.spacing(1) }}
                        >
                          <CardActionArea
                            onClick={() => {
                              setSelectedProductCategory(productCategory)
                            }}
                          >
                            {productCategory.imageRef && (
                              <CardMedia
                                component="img"
                                height="275"
                                image={productCategory.imageRef}
                                alt={productCategory.name}
                                sx={{
                                  padding: '1em 1em 0 1em',
                                  objectFit: 'contain',
                                }}
                              />
                            )}
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                {translateProductCategory(productCategory)}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {productCategory.description}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              )}
              <DialogActions>
                {!readOnly && selectedProductCategory && (
                  <Button
                    onClick={() => {
                      setSelectedProductCategory(
                        productCategories.find(
                          (productCategory) =>
                            productCategory.id ===
                            selectedProductCategory.parentId,
                        ),
                      )
                    }}
                    variant={'outlined'}
                    color={'secondary'}
                  >
                    {t('PRODUCT_CONFIGURATOR.BACK')}
                  </Button>
                )}
                {!readOnly && (
                  <Fragment>
                    <Button
                      color={'secondary'}
                      variant="outlined"
                      onClick={(e) => {
                        close()

                        if (data) {
                          updatePositionFunction(
                            data.product,
                            positionPropertiesBackup,
                          )
                        }

                        setSelectedProduct(null)
                        setSelectedProductCategory(null)
                      }}
                    >
                      {t('GENERAL.CANCEL')}
                    </Button>
                  </Fragment>
                )}
              </DialogActions>
            </Fragment>
          )}
        </Fragment>
      )}
    </Dialog>
  )

  function getParentCategories(productCategory, parentProductCategories = []) {
    if (productCategory.parentId) {
      let parentCategory = productCategories.find(
        (pCategory) => pCategory.id === productCategory.parentId,
      )
      parentProductCategories = parentProductCategories.concat(parentCategory)

      return getParentCategories(parentCategory, parentProductCategories)
    } else {
      return parentProductCategories
    }
  }
}

export default withTranslation()(ProductConfigurator)
