import {
  Cancel,
  Check,
  Edit,
  Send,
  SmartToy,
  SwapHoriz,
} from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import moment from 'moment'
import Numeral from 'numeral'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import {
  categorizedProductLookup,
  countryLookup,
  deliveryTypeLookup,
  offerStateLookup,
  productLookup,
  productStateLookup,
  requestTypeLookup,
} from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import { offersService } from '../../../../_services/offersService'
import { usersService } from '../../../../_services/usersService'
import AcceptCounterOfferDialog from '../../../AcceptCounterOfferDialog'
import AcceptOfferDialog from '../../../AcceptOfferDialog'
import AgreementDialog from '../../../AgreementDialog'
import { CustomRating } from '../../../CustomRating'
import { InfoTooltip } from '../../../InfoTooltip'
import { Loading } from '../../../Loading'
import OfferStepper from '../../../OfferStepper'
import { PositionsList } from '../../../PositionsList'
import Rank from '../../../Rank'
import ResourceList from '../../../ResourceList/_components/ResourceList'
import ViewDescription from '../../../ViewDescription'

const Offers = (props) => {
  const userObject = JSON.parse(localStorage.getItem('user'))

  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)
  let id = null
  let requestId = null

  const { t } = props
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)
  const [offerType, setOfferType] = useState(
    userObject.partner.type === 'customer'
      ? 'RECEIVED'
      : params.get('offerType')
      ? params.get('offerType')
      : null,
  )
  const [usersOfPartner, setUsersOfPartner] = useState([])
  const [showDeclineOfferDialog, setShowDeclineOfferDialog] = useState(false)
  const [showAcceptOfferDialog, setShowAcceptOfferDialog] = useState(false)
  const [showSendOfferDialog, setShowSendOfferDialog] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [mode, setMode] = React.useState('VIEW')

  id = params.get('id')
  requestId = params.get('requestId')
  window.history.pushState({}, document.title, window.location.pathname)

  useEffect(() => {
    usersService.getUsersOfPartner(userObject.partner.id).then((users) => {
      setUsersOfPartner(users)
    })
  }, [userObject.partner.id])

  return (
    <Fragment>
      <ViewDescription
        description={
          userObject.partner.type === 'customer'
            ? t('OFFERS.VIEW_DESCRPTION_RECEIVED')
            : t('OFFERS.VIEW_DESCRPTION')
        }
      />
      {isLoading && <Loading variant={'centered'} />}

      <Fragment>
        {
          <ResourceList
            fetchMethod={
              offerType
                ? (filterValues, pagination) =>
                    offersService.getOffersOfPartner(
                      userObject.partner.id,
                      location.pathname === '/offers-requests'
                        ? 'REQUEST'
                        : 'TENDER',
                      offerType,
                      filterValues,
                      pagination,
                      userObject.user.id,
                    )
                : null
            }
            forceUpdateCount={forceUpdateCount}
            filterId={
              location.pathname === '/offers-requests'
                ? 'OFFERS_REQUESTS'
                : 'OFFERS_TENDERS'
            }
            hint={
              userObject.partner.type === 'customer'
                ? t('OFFERS.VIEW_DESCRPTION_RECEIVED')
                : t('OFFERS.VIEW_DESCRPTION')
            }
            selectionText={t('OFFERS.SELECT_AN_OFFER')}
            sorting={[
              {
                key: 'updatedAt',
                default: true,
                label: t('OFFERS.LAST_ACTIVITY'),
              },
            ]}
            toolbar={
              userObject.partner.type === 'manufacturer' && offerType ? (
                <Chip
                  color={'primary'}
                  size={'small'}
                  style={{ maxWidth: '50%', marginTop: 5, marginBottom: 5 }}
                  label={
                    <Fragment>
                      <strong>
                        {`${t('REQUESTS.OFFERS')}: ${
                          offerType === 'SENT'
                            ? t('OFFERS.SENT')
                            : t('OFFERS.RECEIVED')
                        }
                        `}
                      </strong>
                    </Fragment>
                  }
                  onDelete={() => {
                    setOfferType(null)
                  }}
                />
              ) : null
            }
            filters={[
              {
                key: 'rootOfferId',
                label: t('OFFERS.OFFER_ID'),
                variant: 'textfield',
                defaultValue: id,
              },
              {
                key: 'requestId.startsWith',
                label: t('OFFERS.REQUEST_ID'),
                variant: 'textfield',
                defaultValue: requestId,
              },
              {
                key: 'state',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(offerStateLookup),
                lookup: offerStateLookup,
                label: t('OFFERS.STATE'),
              },
              {
                key: 'positions.requestPosition.productId.eq',
                variant: 'categorizedCheckboxList',
                values: categorizedProductLookup.map((category) => {
                  return {
                    label: category.name,
                    items: category.products.map((product) => product.id),
                  }
                }),
                lookup: productLookup,
                label: t('REQUESTS.PRODUCT'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(productStateLookup),
                lookup: productStateLookup,
                label: t('MARKETPLACE.PRODUCT_STATE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(deliveryTypeLookup),
                lookup: deliveryTypeLookup,
                label: t('MARKETPLACE.DELIVERY_TYPE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=1&positions.positionProperties.value',
                label: t('MARKETPLACE.PRODUCT_LENGTH'),
                variant: 'textfield',
              },
              {
                key: 'positions.positionProperties.productPropertyId=2&positions.positionProperties.value',
                label: t('MARKETPLACE.PRODUCT_WIDTH'),
                variant: 'textfield',
              },
              {
                key: 'positions.location.countryId.eq',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(countryLookup),
                lookup: countryLookup,
                label: t('OFFERS.COUNTRY'),
              },
              {
                key: 'positions.location.zipcode.startsWith',
                label: t('REQUESTS.ZIPCODE'),
                variant: 'textfield',
              },
              {
                key: 'positions.location.place.startsWith',
                label: t('REQUESTS.PLACE'),
                variant: 'textfield',
              },
              {
                key: 'userIds',
                variant: 'checkboxList',
                values: usersOfPartner.map((user) => user.id),
                lookup: Object.fromEntries(
                  usersOfPartner.map((user) => [
                    user.id,
                    `${user.firstname} ${user.lastname}`,
                  ]),
                ),
                label: t('GENERAL.USER'),
              },
            ]}
            resourceHeaderRenderFunction={(offer) =>
              `${t('OFFERS.OFFER')}: ${offer.rootOfferId}`
            }
            listHeaderRenderFunction={(offer) => (
              <Fragment>
                <div>
                  {`${t('OFFERS.OFFER')}: ${offer.rootOfferId}`}
                  {offerType === 'RECEIVED'
                    ? ` | ${countryLookup[offer.manufacturerCountryId]}`
                    : null}
                </div>
                <div>
                  {`${t('OFFERS.LAST_ACTIVITY')} ${moment(
                    offer.updatedAt,
                  ).fromNow()}`}
                </div>
                <Fragment>
                  {!authorize('administrator') && offer.customerUser && (
                    <div>{`${offer.customerUser.firstname} ${offer.customerUser.lastname}`}</div>
                  )}
                  {!authorize('administrator') && offer.manufacturerUser && (
                    <div>{`${offer.manufacturerUser.firstname} ${offer.manufacturerUser.lastname}`}</div>
                  )}
                </Fragment>
                {authorize('administrator') ? (
                  <Fragment>
                    <br />
                    <div>{`${t('ORDERS.CUSTOMER')}: ${
                      offer.customerUser.firstname
                    } ${offer.customerUser.lastname} | ${offer.customer} (${
                      offer.customerId
                    })`}</div>
                    <div>{`${t('ORDERS.MANUFACTURER')}: ${
                      offer.manufacturerUser.firstname
                    } ${offer.manufacturerUser.lastname} | ${
                      offer.manufacturer
                    } (${offer.manufacturerId})`}</div>
                  </Fragment>
                ) : offer.manufacturer ? (
                  <Fragment>
                    <br />
                    <div>{`${t('ORDERS.MANUFACTURER')}: ${
                      offer.manufacturer
                    }`}</div>
                  </Fragment>
                ) : null}
              </Fragment>
            )}
            headerActionRenderFunction={(offer) => {
              let averageRating =
                (parseFloat(offer.manufacturerAverageQuality) +
                  parseFloat(offer.manufacturerAverageReliability)) /
                2

              return (
                <Fragment>
                  <Stack
                    direction={isMobile ? 'row-reverse' : 'row'}
                    spacing={1}
                  >
                    {offer.community && (
                      <InfoTooltip
                        title={
                          offer.manufacturerId !== userObject.partner.id
                            ? offer.community.name
                            : offer.customer
                        }
                      >
                        <GroupsIcon color={'secondary'} />
                      </InfoTooltip>
                    )}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'right',
                        marginBottom: '3px',
                      }}
                    >
                      {offer.positions.some(
                        (pos) => pos.automaticOfferPositionId,
                      ) && (
                        <Tooltip
                          title={`${t(
                            'OFFERS.CREATED_BY_OFFER_SERVICE_WITH_ID',
                          )}: ${
                            offer.positions.find(
                              (pos) => pos.automaticOfferPositionId,
                            ).automaticOfferPositionId
                          }`}
                        >
                          <SmartToy color="primary" sx={{ marginRight: 1 }} />
                        </Tooltip>
                      )}
                      <Chip
                        size={'small'}
                        color={
                          offer.state === 'EXPIRED' ||
                          offer.state === 'ARCHIVED' ||
                          offer.state === 'CANCLED'
                            ? 'default'
                            : 'info'
                        }
                        label={offerStateLookup[offer.state]}
                      />
                    </div>
                  </Stack>
                  {offer.manufacturerId !== userObject.partner.id && (
                    <Box padding={1}>
                      {offer.manufacturerAverageQuality ? (
                        <InfoTooltip
                          title={
                            <table>
                              <thead>
                                <th
                                  style={{
                                    borderRight: 'solid 15px transparent',
                                  }}
                                >
                                  {t('OFFERS.PARTNER_RATING')}
                                </th>
                                <th>
                                  {Numeral(
                                    (parseFloat(
                                      offer.manufacturerAverageQuality,
                                    ) +
                                      parseFloat(
                                        offer.manufacturerAverageReliability,
                                      )) /
                                      2,
                                  ).format('0,0.00')}
                                </th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {t('OFFERS.RATING_QUALITY')}
                                  </td>
                                  <td>
                                    {Numeral(
                                      parseFloat(
                                        offer.manufacturerAverageQuality,
                                      ),
                                    ).format('0,0.00')}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {t('OFFERS.RATING_RELIABILITY')}
                                  </td>
                                  <td>
                                    {Numeral(
                                      parseFloat(
                                        offer.manufacturerAverageReliability,
                                      ),
                                    ).format('0,0.00')}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {t('OFFERS.TOTAL_RECEIVED_RATINGS')}
                                  </td>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {offer.manufacturerRatingAmount}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          }
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'right',
                              alignItems: 'center',
                            }}
                          >
                            <CustomRating
                              size={'small'}
                              name="read-only"
                              value={averageRating}
                              readOnly
                              precision={0.5}
                            >
                              {`(${offer.manufacturerRatingAmount})`}
                            </CustomRating>
                          </Box>
                        </InfoTooltip>
                      ) : (
                        <div
                          style={{
                            textAlign: isMobile ? 'left' : 'right',
                          }}
                        >
                          <Typography variant="caption">
                            {t('OFFERS.NOT_ENOUGH_RATINGS_YET')}
                          </Typography>
                        </div>
                      )}
                    </Box>
                  )}
                </Fragment>
              )
            }}
            contentRenderFunction={(offer) => (
              <Fragment>
                <Typography>
                  {<Rank rank={offer.rank}>{t('OFFERS.RANK')}</Rank>}
                  <div>
                    {`${requestTypeLookup[offer.requestType]}: ${
                      offer.requestId
                    }`}
                  </div>
                  <div>
                    {`${
                      offer.locations[0].company
                        ? offer.locations[0].company + ' | '
                        : ''
                    }${offer.locations[0].zipcode} | ${
                      offer.locations[0].place
                    } | ${countryLookup[offer.locations[0].countryId]}`}
                  </div>
                </Typography>
                <PositionsList positions={offer.positions} />
              </Fragment>
            )}
            resourceRenderFunction={(offer) => {
              return (
                <OfferStepper
                  rootOfferId={offer.rootOfferId}
                  forceUpdate={() => {
                    forceUpdate(forceUpdateCount + 1)
                  }}
                  cancelOffering={() => {
                    setMode('VIEW')
                  }}
                  mode={mode}
                />
              )
            }}
            actionsRenderFunction={(offer) => {
              return [
                {
                  icon: <SwapHoriz />,
                  name: t('OFFERS.CREATE_COUNTEROFFER'),
                  hidden:
                    offer.requestState === 'ARCHIVED' ||
                    (offer.partnerId === userObject.partner.id &&
                      offer.state !== 'ARCHIVED' &&
                      offer.state !== 'EXPIRED' &&
                      offer.state !== 'CANCLED'),
                  primary: false,
                  onClick: () => {
                    setSelectedOffer(offer)
                    setMode('COUNTER')
                  },
                },
                {
                  icon: <Cancel />,
                  name:
                    offer.partnerId === userObject.partner.id
                      ? t('OFFERS.CANCEL_OFFER')
                      : t('OFFERS.DECLINE_OFFER'),
                  hidden:
                    offer.requestState === 'ARCHIVED' ||
                    offer.state !== 'ACTIVE',
                  primary: false,
                  onClick: () => {
                    setSelectedOffer(offer)
                    setShowDeclineOfferDialog(true)
                  },
                },
                {
                  icon: <Edit />,
                  name:
                    offer.revision > 1 &&
                    moment.utc(offer.updatedAt).add(90, 'minutes') >
                      moment.utc()
                      ? `${t('OFFERS.EDIT_OFFER')} (${Math.abs(
                          Math.floor(
                            moment
                              .duration(
                                moment
                                  .utc()
                                  .diff(
                                    moment.utc(
                                      moment
                                        .utc(offer.updatedAt)
                                        .add(90, 'minutes'),
                                    ),
                                  ),
                              )
                              .asMinutes(),
                          ),
                        )} ${t('OFFERS.MINUTES')})`
                      : t('OFFERS.EDIT_OFFER'),
                  disabled:
                    offer.revision > 1 &&
                    moment.utc(offer.updatedAt).add(90, 'minutes') >
                      moment.utc(),
                  hidden:
                    offer.requestState === 'ARCHIVED' ||
                    offer.state !== 'ACTIVE' ||
                    offer.partnerId !== userObject.partner.id,
                  primary: true,
                  onClick: () => {
                    setSelectedOffer(offer)
                    setMode('EDIT')
                  },
                },
                {
                  icon: <Check />,
                  name: t('OFFERS.ACCEPT_OFFER'),
                  hidden:
                    offer.requestState === 'ARCHIVED' ||
                    offer.state !== 'ACTIVE' ||
                    offer.partnerId === userObject.partner.id,
                  primary: true,
                  onClick: () => {
                    setSelectedOffer(offer)
                    if (
                      parseInt(userObject.partner.id) ===
                      parseInt(offer.requestPartnerId)
                    ) {
                      setShowAcceptOfferDialog(true)
                    } else {
                      setShowSendOfferDialog(true)
                    }
                  },
                },
              ]
            }}
            dataIdentifier={'rootOfferId'}
          />
        }

        {!isLoading && (
          <Dialog
            open={userObject.partner.type === 'manufacturer' && !offerType}
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogContent>
              <Grid
                container
                spacing={3}
                alignItems={'stretch'}
                justifyContent={'center'}
              >
                <Grid item xs={12} sm={6}>
                  <Card
                    variant={'outlined'}
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <CardActionArea
                      sx={{
                        height: '100%',
                        flexDirection: 'column',
                      }}
                      onClick={() => {
                        setOfferType('SENT')
                        forceUpdate(forceUpdateCount + 1)
                      }}
                    >
                      <CardHeader avatar={<Send />} title={t('OFFERS.SENT')} />
                      <CardContent>{t('OFFERS.SENT_DESCRIPTION')}</CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Card
                    variant={'outlined'}
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <CardActionArea
                      sx={{
                        height: '100%',
                        flexDirection: 'column',
                      }}
                      onClick={() => {
                        setOfferType('RECEIVED')
                        forceUpdate(forceUpdateCount + 1)
                      }}
                    >
                      <CardHeader
                        avatar={
                          <Send
                            sx={{
                              transform: 'scaleX(-1)',
                            }}
                          />
                        }
                        title={t('OFFERS.RECEIVED')}
                      />
                      <CardContent>
                        {t('OFFERS.RECEIVED_DESCRIPTION')}
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        )}

        {selectedOffer && (
          <Fragment>
            {showAcceptOfferDialog && (
              <AcceptOfferDialog
                selectedOffer={selectedOffer}
                hide={() => setShowAcceptOfferDialog(false)}
                forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
              />
            )}
            {showSendOfferDialog && (
              <AcceptCounterOfferDialog
                selectedOffer={selectedOffer}
                hide={() => setShowSendOfferDialog(false)}
                forceUpdate={() => {
                  forceUpdate(forceUpdateCount + 1)
                }}
              />
            )}
            <AgreementDialog
              open={showDeclineOfferDialog}
              handleClose={() => {
                setShowDeclineOfferDialog(false)
              }}
              agree={() => {
                setIsLoading(true)
                offersService
                  .declineOffer(selectedOffer.id, userObject.partner.id)
                  .catch(function (error) {
                    console.log(error)
                  })
                  .finally(() => {
                    setIsLoading(false)
                    forceUpdate(forceUpdateCount + 1)
                    setShowDeclineOfferDialog(false)
                  })
              }}
            />
          </Fragment>
        )}
      </Fragment>
    </Fragment>
  )
}

export default withTranslation()(Offers)
