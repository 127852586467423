import config from '../_config/config.js'
import { authHeader } from '../_helpers/auth-header'

export const orderConfirmationsService = {
  getOrderConfirmation,
}

function getOrderConfirmation(orderId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/orders/` + orderId + '/order-confirmation',
    requestOptions,
  )
}

export * from './orderConfirmationsService'
