import { Grid } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import DeliveryDetailsMultipleLocationsView from '../../../DeliveryDetailsMultipleLocationsView'
import DeliveryDetailsView from '../../../DeliveryDetailsView'
import OfferAttachmentsView from '../../../OfferAttachmentsView'
import OfferDetailsPositions from '../../../OfferDetailsPositions'
import OfferPaymentDetailsView from '../../../OfferPaymentDetailsView'
import OtherDetailsView from '../../../OtherDetailsView'
import { Section } from '../../../Section'

const OfferDetails = (props) => {
  const { t, data, differences, diffObjectType } = props

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Section header={t('OFFER.GENERAL_INFORMATION')}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              {data.request.type === 'TENDER' ? (
                <DeliveryDetailsMultipleLocationsView data={data.request} />
              ) : null}
              {data.request.type === 'REQUEST' ||
              data.request.type === 'ADVERT' ? (
                <DeliveryDetailsView
                  data={data}
                  parentType={'OFFER'}
                  differences={differences.generalInformation.deliveryDetails}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} sm={6}>
              <OfferPaymentDetailsView
                data={data}
                differences={differences.generalInformation.paymentDetails}
                parentType={'OFFER'}
              />
              <OtherDetailsView
                data={{
                  // offer information
                  validUntil: data.generalInformation.otherDetails.validUntil,
                  internalNote:
                    data.generalInformation.otherDetails.internalNote,
                  // start and end comes from the request, as it can not be modified in offer
                  start: data.request.generalInformation.otherDetails.start,
                  end: data.request.generalInformation.otherDetails.end,
                }}
                requestType={data.request.type}
              />
            </Grid>
          </Grid>
        </Section>
      </Grid>
      <Grid item xs={12}>
        <OfferDetailsPositions
          data={{
            ...data,
            positions: data.positions.filter((pos) => pos.checked),
          }}
          differences={differences}
          diffObjectType={diffObjectType}
          diffData={data.ancestor ? data.ancestor : data.request}
        />
      </Grid>
      <Grid item xs={12}>
        <OfferAttachmentsView data={data} />
      </Grid>
    </Grid>
  )
}

export default withTranslation()(connect()(OfferDetails))
