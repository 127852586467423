import { Box, Paper, Tab, Tabs, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { documentsService } from '../../../../_services/documentsService'
import { Loading } from '../../../Loading'
import DocumentTab from './_components/DocumentTab'

const PREFIX = 'index'

const classes = {
  root: `${PREFIX}-root`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return value === index ? (
    <Typography
      component="div"
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  ) : null
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const Documents = (props) => {
  const { t } = props

  const userObject = JSON.parse(localStorage.getItem('user'))
  const [isLoading, setIsLoading] = React.useState(false)
  const [documents, setDocuments] = React.useState([])
  const [forceUpdateCount, forceUpdate] = useState(0)

  useEffect(() => {
    setIsLoading(true)

    documentsService
      .getDocumentsOfPartner(userObject.partner.id)
      .then((documents) => {
        setDocuments(documents)
        setIsLoading(false)
      })
  }, [forceUpdateCount])

  const deleteDocument = (documentToDelete) => {
    documentsService
      .deleteDocument(documentToDelete)
      .then(() => {
        forceUpdate(forceUpdateCount + 1)
      })
      .catch(() => {
        alert('Error while deleting file')
      })
  }

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Root>
      {(isLoading && <Loading variant={'centered'} />) || (
        <div className={classes.root}>
          <Paper>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label={t('DOCUMENTS.IPPC_LICENCES')} {...a11yProps(0)} />
              <Tab label={t('DOCUMENTS.EPAL_LICENCES')} {...a11yProps(1)} />
              <Tab label={t('DOCUMENTS.CP_LICENCES')} {...a11yProps(2)} />
              <Tab label={t('DOCUMENTS.GL_LICENCES')} {...a11yProps(3)} />
              <Tab label={t('DOCUMENTS.OTHER')} {...a11yProps(4)} />
            </Tabs>
          </Paper>
          <TabPanel value={value} index={0}>
            <DocumentTab
              documents={documents}
              type="ippc"
              deleteDocument={deleteDocument}
              forceUpdate={() => {
                forceUpdate(forceUpdateCount + 1)
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DocumentTab
              documents={documents}
              type="epal"
              deleteDocument={deleteDocument}
              forceUpdate={() => {
                forceUpdate(forceUpdateCount + 1)
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DocumentTab
              documents={documents}
              type="cp"
              deleteDocument={deleteDocument}
              forceUpdate={() => {
                forceUpdate(forceUpdateCount + 1)
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <DocumentTab
              documents={documents}
              type="gl"
              deleteDocument={deleteDocument}
              forceUpdate={() => {
                forceUpdate(forceUpdateCount + 1)
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <DocumentTab
              documents={documents}
              type="other"
              deleteDocument={deleteDocument}
              forceUpdate={() => {
                forceUpdate(forceUpdateCount + 1)
              }}
            />
          </TabPanel>
        </div>
      )}
    </Root>
  )
}

export default withTranslation()(Documents)
