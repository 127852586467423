import MedalIcon from '@iconify/icons-mdi/medal'
import { InlineIcon } from '@iconify/react'
import { LocalOffer } from '@mui/icons-material'
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import moment from 'moment'
import { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryLookup,
  offerStateLookup,
} from '../../../../../../../../_constants/lookupConstants'
import {
  formatToNumber,
  formatToPrice,
} from '../../../../../../../../_helpers/number-formatting'
import { generalService } from '../../../../../../../../_services/generalService'
import { offerPositionsService } from '../../../../../../../../_services/offerPositionsService'
import OfferStepperDialog from '../../../../../../../OfferStepper/_components/OfferStepperDialog'
import Rank from '../../../../../../../Rank'
import Filter from '../../../../../../../filter'

const OffersOfAutomaticOfferPositionDialog = (props) => {
  const { t, automaticOfferPositionId, forceUpdate } = props

  const [offerPositions, setOfferPositions] = useState([])
  const [filterValues, setFilterValues] = useState(null)
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)

  const [showOffer, setShowOffer] = useState(false) // Zustand zum Anzeigen des OfferStepperDialogs
  const [selectedOfferPosition, setSelectedOfferPosition] = useState(null) // Zustand für die ausgewählte Angebotsposition

  const [offerPositionRequestPosition, setOfferPositionRequestPosition] =
    useState(null)

  // Avoid a layout jump when reaching the last offset with empty rows.

  const handleChangePage = (event, newPage) => {
    setOffset(newPage)
  }

  useEffect(() => {
    if (!filterValues) {
      //Filter not initialized
      return
    }
    let mappedFilterValues = Object.fromEntries(
      Object.entries(filterValues).filter(([key, value]) => {
        return (
          (!Array.isArray(value) && value) ||
          (Array.isArray(value) && value.length > 0)
        )
      }),
    )
    setIsLoading(true)
    offerPositionsService
      .getOfferPositionsOfAutomaticOfferPosition(
        automaticOfferPositionId,
        mappedFilterValues,
        offset * 10,
        limit,
      )
      .then((data) => {
        setOfferPositions(data.offerPositions)
        setCount(data.count)
        setIsLoading(false)
      })
  }, [automaticOfferPositionId, filterValues, offset, limit])

  const openOffer = (offerPosition) => {
    setSelectedOfferPosition(offerPosition)
    setOfferPositionRequestPosition(offerPosition.requestPosition)
    setShowOffer(true)
  }

  const filters = [
    {
      key: 'offer.state',
      variant: 'checkboxList',
      values: Object.getOwnPropertyNames(offerStateLookup),
      lookup: offerStateLookup,
      label: t('OFFERS.STATE'),
      shortcut: true,
      defaultValue: ['ACTIVE'],
    },
    {
      key: 'location.countryId.eq',
      variant: 'checkboxList',
      values: Object.getOwnPropertyNames(countryLookup),
      lookup: countryLookup,
      label: t('OFFERS.COUNTRY'),
    },
    {
      key: 'location.zipcode.startsWith',
      label: t('REQUESTS.ZIPCODE'),
      variant: 'textfield',
    },
    {
      key: 'location.place.startsWith',
      label: t('REQUESTS.PLACE'),
      variant: 'textfield',
    },
  ]

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box margin={1}>
            <Filter
              id={'AUTOMATIC_OFFERPOSITION_OFFERS'}
              filterValues={filterValues}
              saveFilterValues={true}
              setFilterValues={(fValues) => {
                setFilterValues(fValues)
              }}
              filters={filters}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('AUTOMATIC_OFFERS.OFFER_ID')}</TableCell>
                  <TableCell>{t('OFFERS.LAST_ACTIVITY')}</TableCell>
                  <TableCell>{t('AUTOMATIC_OFFERS.DELIVERY_PLACE')}</TableCell>
                  <TableCell>{t('AUTOMATIC_OFFERS.AMOUNT')}</TableCell>
                  <TableCell>{t('AUTOMATIC_OFFERS.DELIVERY_DATES')}</TableCell>
                  <TableCell>{t('AUTOMATIC_OFFERS.PRICE')}</TableCell>
                  <TableCell>{t('OFFERS.RANK')}</TableCell>
                  <TableCell>{t('AUTOMATIC_OFFERS.ACTIONS')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {offerPositions.length > 0 ? (
                  <Fragment>
                    {offerPositions.map((offerPosition) => (
                      <TableRow
                        key={offerPosition.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {offerPosition.offerId}
                        </TableCell>
                        <TableCell>
                          {moment(offerPosition.updatedAt).fromNow()}
                        </TableCell>
                        <TableCell>{`${offerPosition.zipcode} ${
                          offerPosition.place
                        }, ${
                          countryLookup[offerPosition.countryId]
                        }`}</TableCell>
                        <TableCell>
                          {formatToNumber(offerPosition.amount)}
                        </TableCell>
                        <TableCell>
                          {offerPosition.deliveries
                            .map((delivery) =>
                              delivery.type === 'date'
                                ? delivery.value
                                : generalService.convertDateToCalendarWeek(
                                    delivery.value,
                                  ),
                            )
                            .join(', ')}
                        </TableCell>
                        <TableCell>
                          {formatToPrice(offerPosition.price)}
                        </TableCell>
                        <TableCell>
                          {<Rank rank={offerPosition.rank} />}
                        </TableCell>
                        <TableCell style={{ verticalAlign: 'center' }}>
                          <Button
                            variant={'contained'}
                            color={'secondary'}
                            size={'small'}
                            startIcon={<LocalOffer />}
                            onClick={() => openOffer(offerPosition)}
                          >
                            {t('AUTOMATIC_OFFER.OPEN_OFFER')}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={3} />
                      <TableCell>
                        {t('AUTOMATIC_OFFERS.AVERAGE_RANK')}
                      </TableCell>
                      <TableCell align="right">
                        <Grid container spacing={0.5} alignItems="center">
                          <Grid item>
                            {formatToNumber(offerPositions[0].avgRank)}
                          </Grid>
                          <Grid item>
                            {offerPositions[0].avgRank !== 'N/A' &&
                            offerPositions[0].avgRank <= 3.5 ? (
                              <InlineIcon
                                style={{ fontSize: '16px' }}
                                icon={MedalIcon}
                                color={
                                  offerPositions[0].avgRank <= 1.5
                                    ? '#D6AF36'
                                    : offerPositions[0].avgRank <= 2.5
                                    ? '#A7A7AD'
                                    : '#A77044'
                                }
                              />
                            ) : null}
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ) : (
                  <TableRow style={{ height: '200px' }}>
                    <TableCell
                      colSpan={5}
                      align="center"
                      style={{ paddingTop: '40px', paddingBottom: '40px' }}
                    >
                      {t('AUTOMATIC_OFFERS.NO_DATA_YET')}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              showFirstButton={true}
              showLastButton={true}
              count={count?.count?.length}
              page={offset}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
            />
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          {showOffer && (
            <OfferStepperDialog
              showOffer={showOffer}
              setShowOffer={setShowOffer}
              selectedOfferPosition={selectedOfferPosition}
              setSelectedOfferPosition={setSelectedOfferPosition}
              forceUpdate={forceUpdate}
              requestPosition={offerPositionRequestPosition}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default withTranslation()(OffersOfAutomaticOfferPositionDialog)
