import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { authorize } from '../../../../_helpers/authorization'
import Addresses from './_components/Addresses'
import Jobs from './_components/Jobs'
import Masterdata from './_components/Masterdata'
import PartnerSurveys from './_components/PartnerSurveys'

const PartnerSettings = (props) => {
  const { t, dispatch } = props

  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <TabContext value={selectedTab}>
      <TabList
        value={selectedTab}
        onChange={(e, value) => setSelectedTab(value)}
      >
        <Tab label={t('PARTNER_SETTING.COMPANY_SETTINGS')} />
        <Tab
          label={`${t('PARTNER_SETTING.DELIVERY_ADDRESSES')} / ${t(
            'PARTNER_SETTING.INVOICE_ADDRESSES',
          )}`}
        />
        <Tab label={t('PARTNER_SETTING.SURVEYS')} />
        {authorize('administrator') && (
          <Tab label={t('PARTNER_SETTING.OFFER_JOBS')} />
        )}
      </TabList>
      <TabPanel value={0}>
        <Masterdata />
      </TabPanel>
      <TabPanel value={1}>
        <Addresses />
      </TabPanel>
      <TabPanel value={2}>
        <PartnerSurveys />
      </TabPanel>
      <TabPanel value={3}>
        <Jobs />
      </TabPanel>
    </TabContext>
  )
}

export default withTranslation()(connect()(PartnerSettings))
