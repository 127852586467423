import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header'

export const userMailSettingsService = {
  getUserMailSettingsOfUser,
  updateUserMailSetting,
}

function getUserMailSettingsOfUser(userId) {
  var requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${config.apiUrlBase}/users/${userId}/user-mail-settings`,
    requestOptions,
  ).then(handleResponse)
}

function updateUserMailSetting(userMailSettingId, isActive, token) {
  var requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ isActive: isActive }),
  }

  return fetch(
    token
      ? `${config.apiUrlBase}/user-mail-settings/${userMailSettingId}?token=${token}`
      : `${config.apiUrlBase}/user-mail-settings/${userMailSettingId}`,
    requestOptions,
  ).then(handleResponse)
}
export * from './userMailSettingsService.js'
