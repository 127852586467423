import numeral from 'numeral'

export function formatToPrice(price) {
  return numeral(parseFloat(price)).format('0,0.00 $')
}

export function formatToNumber(number) {
  return numeral(number).format('0,0.[00]')
}

export function round(number, decimals) {
  return (
    Math.round((number + Number.EPSILON) * Math.pow(10, decimals)) /
    Math.pow(10, decimals)
  )
}
