import { ErrorMessage, Field } from 'formik'
import React, { Fragment } from 'react'

export const FieldErrorWrapper = (props) => {
  return (
    <Fragment>
      <Field {...props} />
      <div id={props.name} style={{ margin: '5px' }}>
        <ErrorMessage
          name={props.name}
          render={(msg) => <div className="help-block">{msg}</div>}
        />
      </div>
    </Fragment>
  )
}
