import { Box, Grid, Tab, Tabs, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { salutationLookup } from '../../../../../../_constants/lookupConstants'
import { authorize } from '../../../../../../_helpers/authorization'
import UserPartnerInvoiceLocations from './_components/UserPartnerInvoiceLocations'
import UserPartnerLocations from './_components/UserPartnerLocations'
import UserRoles from './_components/UserRoles'

const UserDetails = (props) => {
  const { t, user, forceUpdate } = props

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const userObject = JSON.parse(localStorage.getItem('user'))

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return value === index ? (
      <Typography
        component="div"
        role="tabpanel"
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    ) : null
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  }

  return (
    <Fragment>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={t('USERS.USER_DETAILS')}></Tab>
        <Tab label={t('USERS.PERMISSIONS')}></Tab>
      </Tabs>
      <TabPanel value={value} index={0}>
        <Grid container spacing={3} padding={5}>
          <Grid item xs={12} sm={4}>
            <div>
              <strong>{t('USERS.SALUTATION')}</strong>
            </div>
            <div>{salutationLookup[user.salutation]}</div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <strong>{t('USERS.FIRSTNAME')}</strong>
            </div>
            <div>{user.firstname}</div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <strong>{t('USERS.LASTNAME')}</strong>
            </div>
            <div>{user.lastname}</div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <strong>{t('USERS.MAIL')}</strong>
            </div>
            <div>{user.email}</div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <strong>{t('USERS.FAX')}</strong>
            </div>
            <div>{user.fax}</div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <strong>{t('USERS.PHONE')}</strong>
            </div>
            <div>{user.phone}</div>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {user.id === userObject.user.id ? (
          <Grid item xs={12}>
            <Typography variant={'h6'} align={'center'}>
              {t('USERS.CANNOT_CHANGE_OWN_PERMISSIONS')}
            </Typography>
          </Grid>
        ) : (
          <Fragment>
            {authorize('updateUserPartnerLocations') && (
              <Grid item xs={12}>
                <UserPartnerLocations user={user} forceUpdate={forceUpdate} />
              </Grid>
            )}
            {authorize('updateUserPartnerInvoiceLocations') && (
              <Grid item xs={12}>
                <UserPartnerInvoiceLocations
                  user={user}
                  forceUpdate={forceUpdate}
                />
              </Grid>
            )}
            {authorize('updateUserRoles') && (
              <Grid item xs={12}>
                <UserRoles user={user} forceUpdate={forceUpdate} />
              </Grid>
            )}
          </Fragment>
        )}
      </TabPanel>
    </Fragment>
  )
}

export default withTranslation()(UserDetails)
