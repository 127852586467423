import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const partnerLocationsService = {
  createPartnerLocation,
  getPartnerLocationsOfPartner,
  getPartnerLocationsOfUser,
}

function getPartnerLocationsOfPartner(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/partner-locations`,
    requestOptions,
  ).then(handleResponse)
}

function getPartnerLocationsOfUser(userId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/users/${userId}/partner-locations`,
    requestOptions,
  ).then(handleResponse)
}

function createPartnerLocation(requestBody) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }
  return fetch(`${config.apiUrlBase}/partner-locations`, requestOptions).then(
    handleResponse,
  )
}

export * from './partnerLocationsService'
