import { Button, Grid, MenuItem } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { alertActions } from '../../../../_actions/alertActions'
import { usersService } from '../../../../_services/usersService'
import { Loading } from '../../../Loading'
import { Section } from '../../../Section'

const UserSettings = (props) => {
  const { t, dispatch } = props

  const [isLoading, setIsLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    salutation: 'MR',
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    mobile: '',
    notificationLanguage: '',
    fax: '',
  })
  var userObject = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    setIsLoading(true)
    usersService.getUser(userObject.user.id).then((userData) => {
      setInitialValues(userData)
      setIsLoading(false)
    })
  }, [])

  const valSchema = Yup.object().shape({
    salutation: Yup.string().required('required'),
    firstname: Yup.string().required('required'),
    lastname: Yup.string().required('required'),
    position: Yup.string(),
    phone: Yup.string().required('required'),
    mobile: Yup.string(),
    fax: Yup.string(),
  })

  return (
    <Fragment>
      {(isLoading && <Loading variant={'centered'} />) || (
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={valSchema}
            //validateOnBlur={false}
            onSubmit={(values, { setSubmitting }) => {
              usersService
                .updateUser(userObject.user.id, values)
                .then((data) => {
                  dispatch(
                    alertActions.info(t('USER_SETTINGS.SAVING_SUCCESSFUL')),
                  )
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                  setSubmitting(false)
                })
                .catch(function (error) {
                  dispatch(alertActions.error(error))
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                  setSubmitting(false)
                })
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Section header={t('USER_SETTINGS.USER_SETTINGS')}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <Section header={t('USER_SETTINGS.MY_PROFILE')}>
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t('USER_SETTINGS.SALUTATION')}
                              variant="outlined"
                              name="salutation"
                              select
                            >
                              <MenuItem key="MR" value="MR">
                                {t('USER_SETTINGS.MR')}
                              </MenuItem>
                              <MenuItem key="MS" value="MS">
                                {t('USER_SETTINGS.MS')}
                              </MenuItem>
                            </Field>
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t('USER_SETTINGS.FIRSTNAME')}
                              variant="outlined"
                              name="firstname"
                            />
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t('USER_SETTINGS.LASTNAME')}
                              variant="outlined"
                              name="lastname"
                            />
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t('USER_SETTINGS.POSITION')}
                              variant="outlined"
                              name="position"
                            />
                          </Section>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Section
                            header={t('USER_SETTINGS.CONTACT_INFORMATIONS')}
                          >
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t('USER_SETTINGS.PHONE')}
                              variant="outlined"
                              name="phone"
                            />
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t('USER_SETTINGS.MOBILE')}
                              variant="outlined"
                              name="mobile"
                            />
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t('USER_SETTINGS.FAX')}
                              variant="outlined"
                              name="fax"
                            />
                          </Section>
                        </Grid>
                      </Grid>
                    </Section>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={isSubmitting}
                      style={{ margin: '0 0 15px 15px' }}
                      type="submit"
                    >
                      {t('USER_SETTINGS.SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Fragment>
  )
}

export default withTranslation()(connect()(UserSettings))
