import { Directions } from '@mui/icons-material'
import { Button, Grid, Tooltip, Typography, debounce } from '@mui/material'
import {
  Circle,
  DirectionsRenderer,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api'
import React, { Fragment, useCallback, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'

const ResourceMapGoogleMapChildren = (props) => {
  const {
    t,
    data,
    setSelectedItem,
    geoCoordinatePath,
    mouseOverFunction,
    iconFunction,
    origin,
    radius,
    googleMapChildrenFunction,
    hoveredItem,
  } = props

  const [selectedInfoWindow, setSelectedInfoWindow] = useState(null)
  const [lastCalculationTarget, setLastCalculationTarget] = useState(null)
  const [distance, setDistance] = useState('')
  const [directions, setDirections] = useState(null)

  const handleMarkerClick = useCallback(function callback(entity) {
    setSelectedItem(entity)
  }, [])

  const handleMouseOver = useCallback(
    debounce((entity) => {
      setSelectedInfoWindow(entity)
    }, 300),
    [],
  )

  const calculateRoute = (destination) => {
    const directionsService = new window.google.maps.DirectionsService()
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result)

          const totalDistance = result.routes[0].legs[0].distance
          setDistance(totalDistance.text)
        } else {
          console.error(`error fetching directions ${result}`)
        }
      },
    )
  }

  const markerClusterer = useMemo(() => {
    return (
      <MarkerClusterer>
        {(clusterer) =>
          data &&
          data.map((entity) => {
            return (
              <Fragment>
                <Marker
                  icon={iconFunction(entity)}
                  position={{
                    lat: geoCoordinatePath
                      ? entity[geoCoordinatePath].latitude
                      : entity.latitude,
                    lng: geoCoordinatePath
                      ? entity[geoCoordinatePath].longitude
                      : entity.longitude,
                  }}
                  clusterer={clusterer}
                  onClick={() => handleMarkerClick(entity)}
                  onMouseOver={() => handleMouseOver(entity)}
                />
              </Fragment>
            )
          })
        }
      </MarkerClusterer>
    )
  }, [data])

  return (
    <Fragment>
      {data &&
        googleMapChildrenFunction &&
        data.map((entity) => googleMapChildrenFunction(entity))}

      {hoveredItem && (
        <Marker
          icon={{
            url: '/icons/map/circle.svg',
            scaledSize: new window.google.maps.Size(360, 360),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(180, 180),
          }}
          position={{
            lat: geoCoordinatePath
              ? hoveredItem[geoCoordinatePath].latitude
              : hoveredItem.latitude,
            lng: geoCoordinatePath
              ? hoveredItem[geoCoordinatePath].longitude
              : hoveredItem.longitude,
          }}
        />
      )}
      {origin && (
        <Circle
          center={origin}
          radius={radius}
          options={{
            strokeColor: '#0b6467',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#0b6467',
            fillOpacity: 0.35,
          }}
        />
      )}
      {origin && (
        <Marker
          position={{
            lat: origin.lat,
            lng: origin.lng,
          }}
          icon={{
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 10,
            fillColor: '#fb5315',
            fillOpacity: 0.75,
            strokeWeight: 0.5,
          }}
        />
      )}
      {directions && <DirectionsRenderer directions={directions} />}
      {selectedInfoWindow && (
        <InfoWindow
          position={{
            lat: geoCoordinatePath
              ? selectedInfoWindow[geoCoordinatePath].latitude
              : selectedInfoWindow.latitude,
            lng: geoCoordinatePath
              ? selectedInfoWindow[geoCoordinatePath].longitude
              : selectedInfoWindow.longitude,
          }}
          onCloseClick={() => setSelectedInfoWindow(null)}
          options={{
            pixelOffset: new window.google.maps.Size(0, -35),
          }}
        >
          <Fragment>
            {mouseOverFunction(selectedInfoWindow)}
            <Grid
              container
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                {lastCalculationTarget !== selectedInfoWindow && (
                  <Tooltip
                    title={
                      origin ? '' : t('RESOURCE_LIST.SELECT_A_STARTING_POINT')
                    }
                  >
                    <span>
                      <Button
                        disabled={!origin}
                        variant={'contained'}
                        color={'secondary'}
                        endIcon={<Directions />}
                        onClick={() => {
                          calculateRoute({
                            lat: geoCoordinatePath
                              ? selectedInfoWindow[geoCoordinatePath].latitude
                              : selectedInfoWindow.latitude,
                            lng: geoCoordinatePath
                              ? selectedInfoWindow[geoCoordinatePath].longitude
                              : selectedInfoWindow.longitude,
                          })
                          setLastCalculationTarget(selectedInfoWindow)
                        }}
                      >
                        {t('RESOURCE_LIST.SHOW_ROUTE')}
                      </Button>
                    </span>
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {lastCalculationTarget === selectedInfoWindow && (
                  <Typography>{`${t(
                    'RESOURCE_LIST.DISTANCE',
                  )}: ${distance}`}</Typography>
                )}
              </Grid>
            </Grid>
          </Fragment>
        </InfoWindow>
      )}
      {markerClusterer}
    </Fragment>
  )
}
export default withTranslation()(ResourceMapGoogleMapChildren)
