import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header.js'

export const partnerBadgesService = {
  getBadgesOfPartner,
  updateBadgeOfPartner,
}

function getBadgesOfPartner(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/badges`,
    requestOptions,
  )
    .then(handleResponse)
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error('Error fetching partner badges:', error)
    })
}

function updateBadgeOfPartner(partnerId, badgeId) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/badge/${badgeId}`,
    requestOptions,
  )
    .then(handleResponse)
    .then((data) => {
      return data
    })
    .catch((error) => {
      console.error('Error updating partner badges:', error)
    })
}

export * from './partnerBadgesService.js'
