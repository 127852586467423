import { SaveAlt } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { groupBy } from 'lodash'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  categorizedProductLookup,
  countryLookup,
  productLookup,
  productStateLookup,
} from '../../../../_constants/lookupConstants'
import { generateColorMap } from '../../../../_helpers/little'
import {
  formatToNumber,
  formatToPrice,
} from '../../../../_helpers/number-formatting'
import { _applicationService } from '../../../../_services/_applicationService'
import { partnerLocationsService } from '../../../../_services/partnerLocationsService'
import Filter from '../../../filter'
import BarChartSkeleton from './_components/BarChartSkeleton'
import CustomBarChart from './_components/CustomBarChart'

const Charts = (props) => {
  const { t } = props
  var userObject = JSON.parse(localStorage.getItem('user'))

  const [isLoading, setIsLoading] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [data, setData] = useState({
    numbers: [],
    processSavings: [],
  })
  const [mode, setMode] = useState(
    userObject.partner.type === 'customer' ? 'PURCHASE' : 'SALE',
  )
  //Fixes common issue of labels not getting rendered correctly, by adding a small delay.
  const [shouldRenderChart, setShouldRenderChart] = useState(false)

  const [filterValues, setFilterValues] = useState(null)
  const [dateFrom, setDateFrom] = useState(moment().startOf('year'))
  const [dateTo, setDateTo] = useState(moment().endOf('month'))

  const [partnerLocations, setPartnerLocations] = useState([])

  const theme = useTheme()
  const isLargeOrHigher = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldRenderChart(true)
    }, 500) // Verzögerung von 500ms

    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    if (!filterValues) {
      //Filter not initialized
      return
    }
    setIsLoading(true)
    let mappedFilterValues = Object.fromEntries(
      Object.entries(filterValues).filter(([key, value]) => {
        return (
          (!Array.isArray(value) && value) ||
          (Array.isArray(value) && value.length > 0)
        )
      }),
    )

    setIsLoading(true)
    _applicationService
      .getReportingOfPartner(
        userObject.partner.id,
        dateFrom,
        dateTo,
        mode,
        mappedFilterValues,
        userObject.user.partnerLocationIds,
      )
      .then((data) => {
        setData(data)
      })
      .finally(() => setIsLoading(false))
  }, [mode, dateFrom, dateTo, filterValues])

  useEffect(() => {
    setIsLoading(true)

    partnerLocationsService
      .getPartnerLocationsOfUser(userObject.user.id)
      .then((partnerLocation) => {
        setPartnerLocations(partnerLocation)
        setIsLoading(false)
      })
  }, [])

  function compareMonths(a, b) {
    //Add day to month to be a valid date
    const dateA = new Date(a.month + '-01')
    const dateB = new Date(b.month + '-01')

    if (dateA < dateB) {
      return -1
    }
    if (dateA > dateB) {
      return 1
    }
    return 0
  }

  let ordersGroupedByMonth = groupBy(data.numbers, (order) => {
    const date = new Date(order.deliveryValue)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    return `${year}-${month.toString().padStart(2, '0')}`
  })

  const summarizedOrderGroupedByMonth = Object.entries(ordersGroupedByMonth)
    .map(([month, ordersInMonth]) => {
      const revenueRealized = ordersInMonth.reduce(
        (sum, order) => sum + order.revenueRealized,
        0,
      )

      const revenueOpen = ordersInMonth.reduce(
        (sum, order) => sum + order.revenueOpen,
        0,
      )

      const amountRealized = ordersInMonth.reduce(
        (sum, order) => sum + order.amountRealized,
        0,
      )

      const amountOpen = ordersInMonth.reduce(
        (sum, order) => sum + Math.round(order.amountOpen),
        0,
      )

      const revenueTotal = revenueRealized + revenueOpen

      const totalAmount = ordersInMonth.reduce(
        (sum, order) => sum + order.amountOpen + order.amountRealized,
        0,
      )
      return {
        month,
        revenueTotal,
        totalAmount,
        revenueRealized,
        revenueOpen,
        amountRealized,
        amountOpen,
      }
    })
    .sort(compareMonths)

  let savingsGroupedByMonth = groupBy(data.processSavings, (saving) => {
    const date = new Date(saving.deliveryValue)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    return `${year}-${month.toString().padStart(2, '0')}`
  })

  const summarizedSavingsGroupedByMonth = Object.entries(savingsGroupedByMonth)
    .map(([month, savingsInMonth]) => {
      const smartSavings = savingsInMonth.reduce(
        (sum, saving) => sum + saving.smartSavings,
        0,
      )

      const primeSavings = savingsInMonth.reduce(
        (sum, saving) => sum + saving.primeSavings,
        0,
      )
      return {
        month,
        smartSavings,
        primeSavings,
      }
    })
    .sort(compareMonths)

  let productSavingsGroupedByMonth = groupBy(data.productSavings, (saving) => {
    const date = new Date(saving.deliveryValue)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    return `${year}-${month.toString().padStart(2, '0')}`
  })

  const summarizedProductSavingsGroupedByMonth = Object.entries(
    productSavingsGroupedByMonth,
  )
    .map(([month, savingsInMonth]) => {
      const productSavings = savingsInMonth.reduce(
        (sum, saving) => sum + saving.productSavings,
        0,
      )

      const negotiationSavings = savingsInMonth.reduce(
        (sum, saving) => sum + saving.negotiationSavings,
        0,
      )
      return {
        month,
        productSavings,
        negotiationSavings,
      }
    })
    .sort(compareMonths)

  let ordersGroupedByMonthAndPlace = {}
  let ordersGroupedByMonthAndProductId = {}

  data.numbers.forEach((order) => {
    const date = new Date(order.deliveryValue)
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const monthKey = `${year}-${month.toString().padStart(2, '0')}`

    if (!ordersGroupedByMonthAndPlace[monthKey]) {
      ordersGroupedByMonthAndPlace[monthKey] = {}
    }

    const place = order.place

    if (!ordersGroupedByMonthAndPlace[monthKey][place]) {
      ordersGroupedByMonthAndPlace[monthKey][place] = { amount: 0, revenue: 0 }
    }

    ordersGroupedByMonthAndPlace[monthKey][place].amount +=
      Math.round(order.amountOpen) + order.amountRealized
    ordersGroupedByMonthAndPlace[monthKey][place].revenue +=
      order.revenueOpen + order.revenueRealized

    if (!ordersGroupedByMonthAndProductId[monthKey]) {
      ordersGroupedByMonthAndProductId[monthKey] = {}
    }

    const productId = order.productId
    const state = order.state

    const productStateKey = `productId_${productId}_${state}`

    if (!ordersGroupedByMonthAndProductId[monthKey][productStateKey]) {
      ordersGroupedByMonthAndProductId[monthKey][productStateKey] = {
        amount: 0,
        revenue: 0,
      }
    }

    ordersGroupedByMonthAndProductId[monthKey][productStateKey].amount +=
      Math.round(order.amountOpen) + order.amountRealized
    ordersGroupedByMonthAndProductId[monthKey][productStateKey].revenue +=
      order.revenueOpen + order.revenueRealized
  })

  const summarizedOrdersByMonthAndPlace = Object.entries(
    ordersGroupedByMonthAndPlace,
  )
    .map(([month, places]) => {
      return {
        month,
        ...places,
      }
    })
    .sort(compareMonths)

  const summarizedOrdersByMonthAndProductId = Object.entries(
    ordersGroupedByMonthAndProductId,
  )
    .map(([month, productIds]) => {
      return {
        month,
        ...productIds,
      }
    })
    .sort(compareMonths)

  const avgWeightedPriceByMonthAndProductId = Object.entries(
    ordersGroupedByMonthAndProductId,
  )
    .map(([month, productIds]) => {
      const productPrices = {}
      for (let [productId, data] of Object.entries(productIds)) {
        if (!productPrices[productId]) {
          productPrices[productId] = {
            avgPrice: 0,
          }
        }

        if (data.amount !== 0) {
          productPrices[productId].avgPrice = data.revenue / data.amount
        }
      }

      return {
        month,
        ...productPrices,
      }
    })
    .sort(compareMonths)

  //Just some generated colors to work with
  const colorMapRevenue = generateColorMap([
    {
      1: null,
    },
    {
      2: null,
    },
    {
      3: null,
    },
    {
      4: null,
    },
  ])
  const colorMapPlace = generateColorMap(summarizedOrdersByMonthAndPlace)
  const colorMapProductId = generateColorMap(
    summarizedOrdersByMonthAndProductId,
  )

  const allPlaces = new Set()
  const allProductIds = new Set()

  summarizedOrdersByMonthAndPlace.forEach((record) => {
    Object.keys(record).forEach((key) => {
      if (key !== 'month') {
        allPlaces.add(key)
      }
    })
  })

  summarizedOrdersByMonthAndProductId.forEach((record) => {
    Object.keys(record).forEach((key) => {
      if (key !== 'month') {
        allProductIds.add(key)
      }
    })
  })

  const formatterFunction = (value, dataKey, lookup) => {
    let formattedValue
    let name

    if (
      /avgPrice$/.test(dataKey) ||
      /revenue$/.test(dataKey) ||
      dataKey === 'revenueRealized' ||
      dataKey === 'revenueOpen' ||
      dataKey === 'smartSavings' ||
      dataKey === 'primeSavings' ||
      dataKey === 'productSavings' ||
      dataKey === 'negotiationSavings'
    ) {
      formattedValue = formatToPrice(value)
    } else if (
      /amount$/.test(dataKey) ||
      dataKey === 'amountRealized' ||
      dataKey === 'amountOpen'
    ) {
      formattedValue = formatToNumber(value)
    } else {
      formattedValue = value.toString()
    }

    if (dataKey === 'revenueRealized') {
      name = t('CHARTS.REALIZED_REVENUE')
    } else if (dataKey === 'revenueOpen') {
      name = t('CHARTS.OPEN_REVENUE')
    } else if (dataKey === 'amountRealized') {
      name = t('CHARTS.REALIZED_AMOUNT')
    } else if (dataKey === 'amountOpen') {
      name = t('CHARTS.OPEN_AMOUNT')
    } else if (dataKey === 'smartSavings') {
      name = t('CHARTS.SMART_SAVINGS')
    } else if (dataKey === 'primeSavings') {
      name = t('CHARTS.PRIME_SAVINGS')
    } else if (dataKey === 'productSavings') {
      name = t('CHARTS.PRODUCT_SAVINGS')
    } else if (dataKey === 'negotiationSavings') {
      name = t('CHARTS.NEGOTIATION_SAVINGS')
    } else if (/^productId/.test(dataKey)) {
      let productId = dataKey.split('_')[1]
      let state = dataKey.split('_')[2]

      state = state ? productStateLookup[state.split('.')[0]] : undefined

      name = `${productLookup[productId]} ${state ? state : ''}`
    } else {
      let renderedName = dataKey.includes('.') ? dataKey.split('.')[0] : dataKey

      name = lookup ? lookup[renderedName] : renderedName
    }

    return [formattedValue, name]
  }

  const filters = [
    {
      key: 'positions.productId',
      variant: 'categorizedCheckboxList',
      values: categorizedProductLookup.map((category) => {
        return {
          label: category.name,
          items: category.products.map((product) => product.id),
        }
      }),
      lookup: productLookup,
      label: t('MARKETPLACE.PRODUCT'),
      shortcut: true,
    },
    {
      key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
      variant: 'checkboxList',
      values: Object.getOwnPropertyNames(productStateLookup),
      lookup: productStateLookup,
      label: t('MARKETPLACE.PRODUCT_STATE'),
      shortcut: true,
    },
    {
      key: 'diagrams',
      variant: 'checkboxList',
      values: ['SUMMARY', 'PRODUCT', 'LOCATION_COMPARISON', 'SAVINGS'],
      lookup: {
        SUMMARY: t('CHARTS.DIAGRAM_SUMMARY'),
        PRODUCT: t('CHARTS.DIAGRAM_PRODUCT'),
        LOCATION_COMPARISON: t('CHARTS.DIAGRAM_LOCATION_COMPARISON'),
        SAVINGS: t('CHARTS.DIAGRAM_SAVINGS'),
      },
      label: t('CHARTS.DIAGRAMS'),
    },
    {
      key: 'partnerLocationId',
      variant: 'checkboxList',
      values: partnerLocations.map((partnerLocation) => partnerLocation.id),
      lookup: partnerLocations.reduce((lookupObj, partnerLocation) => {
        lookupObj[partnerLocation.id] = `${partnerLocation.company}, ${
          partnerLocation.zipcode
        } ${partnerLocation.place}, ${countryLookup[partnerLocation.countryId]}`
        return lookupObj
      }, {}),
      label: t('CHARTS.PARTNER_LOCATIONS'),
    },
  ]

  const exportChartsToPDF = async () => {
    const pdf = new jsPDF({
      orientation: 'landscape',
      // Erwägen Sie, eine höhere Einheit für die DPI zu setzen, um die Bildqualität zu verbessern
    })

    const chartElements = document.querySelectorAll('.chartContainer')
    const topMargin = 50 // Oberer Rand auf jeder Seite in mm
    const sideMargin = 10 // Seitenrand in mm

    const templatePage = 'templates/Pacurion_PDF_Template.png'
    pdf.addImage(templatePage, 'PNG', 0, 0, 297, 210, undefined, 'FAST')

    // Deckblatt Informationen einfügen
    const modeText =
      mode === 'PURCHASE'
        ? t('CHARTS.PURCHASE_REPORT')
        : t('CHARTS.SALES_REPORT')
    const dateRangeText = `${moment(dateFrom).format('DD.MM.YYYY')} - ${moment(
      dateTo,
    ).format('DD.MM.YYYY')}`

    // Festlegen des Schriftformats
    pdf.setFont('comfortaa')
    pdf.setFontSize(36)
    pdf.setTextColor(51, 51, 51)

    // Zentrieren des Textes auf dem Deckblatt
    const pageWidth = pdf.internal.pageSize.getWidth()

    const centerText = (text, fontSize, y) => {
      pdf.setFontSize(fontSize)
      const textWidth = pdf.getTextWidth(text)
      const textX = (pageWidth - textWidth) / 2 // Berechnung der X-Position für die Zentrierung
      pdf.text(text, textX, y)
    }

    // Positionieren und Einfügen des zentrierten Textes
    const startY = 70 // Startposition für Y-Achse
    centerText(modeText, 36, startY)
    centerText(dateRangeText, 24, startY + 20)

    // Filterwerte rendern
    pdf.setFontSize(10)
    let currentY = startY + 2 * 20

    const lineSpacing = 10 // Zeilenabstand

    Object.entries(filterValues).forEach(([key, value]) => {
      const filter = filters.find((filter) => filter.key === key)

      if (Array.isArray(value)) {
        const mappedValues = value.map((val) =>
          filter.lookup ? filter.lookup[val] : val,
        )

        if (mappedValues.length > 0) {
          // Label nur einmal rendern
          centerText(`${filter.label}:`, 16, currentY)
          currentY += lineSpacing

          const valuesEachRow = key === 'partnerLocationId' ? 2 : 5

          // Werte in Gruppen von bis zu drei rendern
          for (let i = 0; i < mappedValues.length; i += valuesEachRow) {
            const valuesToRender = mappedValues
              .slice(i, i + valuesEachRow)
              .join(', ')
            centerText(valuesToRender, 12, currentY) // Einrückung für die Werte
            currentY += lineSpacing // Zeilenumbruch
          }
        }
      } else if (value !== undefined && value !== null) {
        pdf.text(`${filter.label}: ${value}`, 10, currentY)
        currentY += lineSpacing
      }
    })

    pdf.addPage()

    for (let i = 0; i < chartElements.length; i++) {
      // Ihr gebrandetes Template hinzufügen
      const templatePage = 'templates/Pacurion_PDF_Template.png'
      pdf.addImage(templatePage, 'PNG', 0, 0, 297, 210, undefined, 'FAST')

      const canvas = await html2canvas(chartElements[i], {
        scale: 2,
        useCORS: true,
      })
      const imgData = canvas.toDataURL('image/jpeg', 1.0)

      const pageWidth = pdf.internal.pageSize.getWidth()
      const pageHeight = pdf.internal.pageSize.getHeight()

      // Berechnen Sie die maximal mögliche Breite und Höhe unter Berücksichtigung der Ränder
      const maxWidth = pageWidth - 2 * sideMargin
      const maxHeight = pageHeight - topMargin

      // Berechnen Sie das Seitenverhältnis des Originalbildes
      const originalAspectRatio = canvas.width / canvas.height

      // Bestimmen Sie die endgültige Breite und Höhe des Bildes
      let finalWidth, finalHeight
      if (maxWidth / originalAspectRatio <= maxHeight) {
        finalWidth = maxWidth
        finalHeight = maxWidth / originalAspectRatio
      } else {
        finalHeight = maxHeight
        finalWidth = maxHeight * originalAspectRatio
      }

      // Berechnen der X-Position für die Zentrierung
      const centerX = (pageWidth - finalWidth) / 2

      pdf.addImage(
        imgData,
        'JPEG',
        centerX,
        topMargin,
        finalWidth,
        finalHeight,
        undefined,
        'FAST',
      )

      if (i !== chartElements.length - 1) {
        pdf.addPage()
      }
    }

    pdf.save(
      mode ? 'Pacurion_Reporting_Purchase.pdf' : 'Pacurion_Reporting_Sale.pdf',
    )
  }

  const handleModeChange = (event) => {
    setMode(event.target.value)
  }

  return (
    <Fragment>
      <Box>
        <Paper elevation={4} style={{ margin: '20px 0', padding: '20px' }}>
          <Toolbar>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={2}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="year-select-label">
                    {t('CHARTS.PURCHASE_OR_SALE')}
                  </InputLabel>
                  <Select
                    labelId="year-select-label"
                    value={mode}
                    onChange={handleModeChange}
                    label={t('CHARTS.PURCHASE_OR_SALE')}
                  >
                    <MenuItem key={'PURCHASE'} value={'PURCHASE'}>
                      {t('CHARTS.PURCHASE')}
                    </MenuItem>
                    <MenuItem key={'SALE'} value={'SALE'}>
                      {t('CHARTS.SALES')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack
                  spacing={2}
                  direction={isLargeOrHigher ? 'row' : 'column'}
                >
                  <DatePicker
                    label={t('CHARTS.FROM')}
                    value={dateFrom}
                    onChange={(newValue) => setDateFrom(newValue)}
                  />
                  <DatePicker
                    label={t('CHARTS.TO')}
                    value={dateTo}
                    onChange={(newValue) => setDateTo(newValue)}
                  />
                </Stack>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                container
                justifyContent="flex-end"
                alignItems="center"
              >
                {data.numbers.length > 0 || data.processSavings.length > 0 ? (
                  <Tooltip title={t('CHARTS.EXPORT')}>
                    <Button
                      disabled={isExporting}
                      variant="contained"
                      color="secondary"
                      startIcon={
                        isExporting ? (
                          <CircularProgress size={24} />
                        ) : (
                          <SaveAlt />
                        )
                      }
                      onClick={async () => {
                        setIsExporting(true)
                        await exportChartsToPDF()
                        setIsExporting(false)
                      }}
                    >
                      {isExporting
                        ? `${t('CHARTS.EXPORTING')}...`
                        : t('CHARTS.EXPORT')}
                    </Button>
                  </Tooltip>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Filter
                  id={'CHARTS'}
                  filterValues={filterValues}
                  saveFilterValues={true}
                  setFilterValues={(fValues) => {
                    setFilterValues(fValues)
                  }}
                  filters={filters}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </Paper>
      </Box>
      {data.numbers.length === 0 && data.processSavings.length === 0 ? (
        <Typography
          variant="h5"
          sx={{
            marginTop: '10%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          {t('CHARTS.NO_DATA_FOUND')}
        </Typography>
      ) : (
        <Grid container spacing={3} alignItems="stretch">
          {(filterValues.diagrams.length === 0 ||
            filterValues.diagrams.includes('SUMMARY')) && (
            <Fragment>
              <Grid item lg={6} xs={12}>
                {shouldRenderChart && !isLoading ? (
                  <CustomBarChart
                    isExporting={isExporting}
                    showSums={true}
                    data={summarizedOrderGroupedByMonth}
                    xAxisDataKey="month"
                    xAxisLabel={t('CHARTS.MONTH')}
                    yAxisLabel={t('CHARTS.REVENUE_IN_EURO')}
                    formatterFunction={formatterFunction}
                    bars={[
                      {
                        stackId: 'a',
                        dataKey: 'revenueRealized',
                        fill: colorMapRevenue[1],
                      },
                      {
                        stackId: 'a',
                        dataKey: 'revenueOpen',
                        fill: colorMapRevenue[3],
                      },
                    ]}
                    title={t('CHARTS.REVENUE_EACH_MONTH')}
                  />
                ) : (
                  <BarChartSkeleton />
                )}
              </Grid>
              <Grid item lg={6} xs={12}>
                {shouldRenderChart && !isLoading ? (
                  <CustomBarChart
                    isExporting={isExporting}
                    showSums={true}
                    data={summarizedOrderGroupedByMonth}
                    xAxisDataKey="month"
                    xAxisLabel={t('CHARTS.MONTH')}
                    yAxisLabel={t('CHARTS.AMOUNT')}
                    formatterFunction={formatterFunction}
                    bars={[
                      {
                        stackId: 'a',
                        dataKey: 'amountRealized',
                        fill: colorMapRevenue[1],
                      },
                      {
                        stackId: 'a',
                        dataKey: 'amountOpen',
                        fill: colorMapRevenue[3],
                      },
                    ]}
                    title={t('CHARTS.AMOUNT_EACH_MONTH')}
                  />
                ) : (
                  <BarChartSkeleton />
                )}
              </Grid>
            </Fragment>
          )}
          {(filterValues.diagrams.length === 0 ||
            filterValues.diagrams.includes('LOCATION_COMPARISON')) && (
            <Fragment>
              <Grid item lg={allPlaces.size > 7 ? 12 : 6} xs={12}>
                {shouldRenderChart && !isLoading ? (
                  <CustomBarChart
                    isExporting={isExporting}
                    showSums={true}
                    data={summarizedOrdersByMonthAndPlace}
                    xAxisDataKey="month"
                    xAxisLabel={t('CHARTS.MONTH')}
                    yAxisLabel={t('CHARTS.REVENUE_IN_EURO')}
                    bars={Array.from(allPlaces).map((place) => ({
                      key: place,
                      dataKey: `${place}.revenue`,
                      fill: colorMapPlace[place],
                    }))}
                    formatterFunction={formatterFunction}
                    hasLegend={true}
                    title={t('CHARTS.REVENUE_BY_LOCATION')}
                  />
                ) : (
                  <BarChartSkeleton />
                )}
              </Grid>
              <Grid item lg={allPlaces.size > 7 ? 12 : 6} xs={12}>
                {shouldRenderChart && !isLoading ? (
                  <CustomBarChart
                    isExporting={isExporting}
                    showSums={true}
                    data={summarizedOrdersByMonthAndPlace}
                    xAxisDataKey="month"
                    xAxisLabel={t('CHARTS.MONTH')}
                    bars={Array.from(allPlaces).map((place) => ({
                      key: place,
                      dataKey: `${place}.amount`,
                      fill: colorMapPlace[place],
                    }))}
                    yAxisLabel={t('CHARTS.AMOUNT')}
                    formatterFunction={formatterFunction}
                    hasLegend={true}
                    title={t('CHARTS.AMOUNT_BY_LOCATION')}
                  />
                ) : (
                  <BarChartSkeleton />
                )}
              </Grid>
            </Fragment>
          )}
          {(filterValues.diagrams.length === 0 ||
            filterValues.diagrams.includes('PRODUCT')) && (
            <Fragment>
              <Grid item lg={allProductIds.size > 7 ? 12 : 6} xs={12}>
                {shouldRenderChart && !isLoading ? (
                  <CustomBarChart
                    isExporting={isExporting}
                    showSums={true}
                    data={summarizedOrdersByMonthAndProductId}
                    xAxisDataKey="month"
                    xAxisLabel={t('CHARTS.MONTH')}
                    yAxisLabel={t('CHARTS.REVENUE_IN_EURO')}
                    bars={Array.from(allProductIds).map((productId) => ({
                      key: productId,
                      dataKey: `${productId}.revenue`,
                      fill: colorMapProductId[productId],
                    }))}
                    lookup={productLookup}
                    formatterFunction={formatterFunction}
                    hasLegend={true}
                    title={t('CHARTS.REVENUE_BY_PRODUCT')}
                  />
                ) : (
                  <BarChartSkeleton />
                )}
              </Grid>
              <Grid item lg={allProductIds.size > 7 ? 12 : 6} xs={12}>
                {shouldRenderChart && !isLoading ? (
                  <CustomBarChart
                    isExporting={isExporting}
                    showSums={true}
                    data={summarizedOrdersByMonthAndProductId}
                    xAxisDataKey="month"
                    xAxisLabel={t('CHARTS.MONTH')}
                    yAxisLabel={t('CHARTS.AMOUNT')}
                    bars={Array.from(allProductIds).map((productId) => ({
                      key: productId,
                      dataKey: `${productId}.amount`,
                      fill: colorMapProductId[productId],
                    }))}
                    lookup={productLookup}
                    formatterFunction={formatterFunction}
                    hasLegend={true}
                    title={t('CHARTS.AMOUNT_BY_PRODUCT')}
                  />
                ) : (
                  <BarChartSkeleton />
                )}
              </Grid>
              <Grid item lg={allProductIds.size > 7 ? 12 : 6} xs={12}>
                {shouldRenderChart && !isLoading ? (
                  <CustomBarChart
                    isExporting={isExporting}
                    showSums={true}
                    sumOperation={'average'}
                    data={avgWeightedPriceByMonthAndProductId}
                    xAxisDataKey="month"
                    xAxisLabel={t('CHARTS.MONTH')}
                    yAxisLabel={t('CHARTS.AVERAGE_PRICE')}
                    bars={Array.from(allProductIds).map((productId) => ({
                      key: productId,
                      dataKey: `${productId}.avgPrice`,
                      fill: colorMapProductId[productId],
                    }))}
                    formatterFunction={formatterFunction}
                    hasLegend={true}
                    title={t('CHARTS.AVERAGE_PRICES')}
                  />
                ) : (
                  <BarChartSkeleton />
                )}
              </Grid>
            </Fragment>
          )}

          {(filterValues.diagrams.length === 0 ||
            filterValues.diagrams.includes('SAVINGS')) && (
            <Fragment>
              <Grid item lg={6} xs={12}>
                {shouldRenderChart && !isLoading ? (
                  <CustomBarChart
                    hasLegend={true}
                    isExporting={isExporting}
                    showSums={true}
                    data={summarizedSavingsGroupedByMonth}
                    xAxisDataKey="month"
                    xAxisLabel={t('CHARTS.MONTH')}
                    yAxisLabel={t('CHARTS.PROCESS_SAVINGS_IN_EURO')}
                    formatterFunction={formatterFunction}
                    bars={[
                      {
                        dataKey: 'smartSavings',
                        fill: '#7EC07E',
                      },
                      { dataKey: 'primeSavings', fill: '#0b6467' },
                    ]}
                    title={
                      <Grid container spacing={1} alignItems={'center'}>
                        <Grid item>{t('CHARTS.SAVINGS_EACH_MONTH')}</Grid>
                        {/* {!isExporting && (
                          <Grid item>
                            <Tooltip
                              title={t('CHARTS.SAVINGS_GET_MORE_DETAILS')}
                            >
                              <IconButton
                                onClick={() =>
                                  window.open(
                                    'https://pacurion.com/kooperationsmodelle',
                                    '_blank',
                                  )
                                }
                                aria-label="delete"
                              >
                                <Info />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )} */}
                      </Grid>
                    }
                  />
                ) : (
                  <BarChartSkeleton />
                )}
              </Grid>
              {data.productSavings.length > 0 && (
                <Grid item lg={6} xs={12}>
                  {shouldRenderChart && !isLoading ? (
                    <CustomBarChart
                      hasLegend={true}
                      isExporting={isExporting}
                      showSums={true}
                      data={summarizedProductSavingsGroupedByMonth}
                      xAxisDataKey="month"
                      xAxisLabel={t('CHARTS.MONTH')}
                      yAxisLabel={t('CHARTS.PRODUCT_SAVINGS_IN_EURO')}
                      formatterFunction={formatterFunction}
                      bars={[
                        {
                          dataKey: 'productSavings',
                          fill: '#7EC07E',
                        },
                        { dataKey: 'negotiationSavings', fill: '#0b6467' },
                      ]}
                      title={t('CHARTS.PRODUCT_SAVINGS_EACH_MONTH')}
                    />
                  ) : (
                    <BarChartSkeleton />
                  )}
                </Grid>
              )}
            </Fragment>
          )}
        </Grid>
      )}
    </Fragment>
  )
}

export default withTranslation()(Charts)
