import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import CustomDialogTitle from '../CustomDialogTitle'

const DeliveryTimes = (props) => {
  const { t, data, setFieldValue, formikName, readOnly } = props
  const [showDialog, setShowDialog] = useState(false)
  const [deliveryTimes, setDeliveryTimes] = useState([])

  const weekDayLabels = [
    t('DELIVERY_TIMES.MONDAY'),
    t('DELIVERY_TIMES.TUESDAY'),
    t('DELIVERY_TIMES.WEDNESDAY'),
    t('DELIVERY_TIMES.THURSDAY'),
    t('DELIVERY_TIMES.FRIDAY'),
    t('DELIVERY_TIMES.SATURDAY'),
    t('DELIVERY_TIMES.SUNDAY'),
  ]
  const valueLabelFormat = (value) => {
    const hours = Math.floor(value)
    const minutes = Math.floor((value % 1) * 60)
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`
  }
  const marks = [
    {
      value: 0,
      label: '0:00',
    },
    {
      value: 8,
      label: '08:00',
    },
    {
      value: 16,
      label: '16:00',
    },
    {
      value: 24,
      label: '24:00',
    },
  ]

  useEffect(() => {
    setDeliveryTimes(data)
  }, [data])

  return (
    <Fragment>
      <TableContainer>
        <Table aria-label="simple table" width="max-content">
          <TableHead>
            <TableRow>
              {weekDayLabels.map((day) => (
                <TableCell align="center" style={{ width: '10%' }}>
                  {day}
                </TableCell>
              ))}
              <TableCell align="center" style={{ width: '20%' }}>
                <Button
                  disabled={readOnly}
                  variant="contained"
                  color="secondary"
                  size={'small'}
                  endIcon={<OpenInNewIcon />}
                  onClick={(e) => {
                    setShowDialog(true)
                  }}
                >
                  {t('DELIVERY_TIMES.EDIT')}
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {data.map((deliveryTime) => (
                <TableCell align="center" style={{ width: '10%' }}>
                  {deliveryTime.isActive
                    ? `${valueLabelFormat(
                        deliveryTime.start,
                      )}-${valueLabelFormat(deliveryTime.end)}`
                    : t('DELIVERY_TIMES.CLOSED')}
                </TableCell>
              ))}
              <TableCell
                align="center"
                style={{ width: '20%' }}
              >{`Lokale Zeit`}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={showDialog} fullWidth={true} maxWidth="md">
        <CustomDialogTitle
          title={t('DELIVERY_TIMES.SET_DELIVERY_TIMES')}
          onClose={() => setShowDialog(false)}
        />
        <DialogContent>
          <Grid container spacing={5} padding={5}>
            {deliveryTimes.map((day, index) => (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography gutterBottom>{weekDayLabels[index]}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Checkbox
                      color="secondary"
                      checked={day.isActive}
                      onChange={(e) => {
                        let newDeliveryTimes = [...deliveryTimes]

                        if (e.target.checked) {
                          newDeliveryTimes[index] = {
                            isActive: true,
                            start: day.start !== null ? day.start : 8,
                            end: day.end !== null ? day.end : 16,
                          }
                          setDeliveryTimes(newDeliveryTimes)
                        } else {
                          newDeliveryTimes[index] = {
                            isActive: false,
                            start: null,
                            end: null,
                          }
                          setDeliveryTimes(newDeliveryTimes)
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Slider
                      color="secondary"
                      getAriaLabel={() => 'Minimum distance'}
                      step={0.25}
                      min={0}
                      max={24}
                      valueLabelFormat={valueLabelFormat}
                      value={[day.start, day.end]}
                      onChange={(e) => {
                        let newDeliveryTimes = [...deliveryTimes]
                        newDeliveryTimes[index] = {
                          isActive: day.isActive,
                          start: e.target.value[0],
                          end: e.target.value[1],
                        }
                        if (e.target.value[1] - e.target.value[0] < 1) {
                          return
                        }
                        setDeliveryTimes(newDeliveryTimes)
                      }}
                      valueLabelDisplay="auto"
                      disableSwap
                      marks={marks}
                      disabled={!day.isActive}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'outlined'}
            color={'secondary'}
            onClick={() => {
              setShowDialog(false)
            }}
          >
            {t('GENERAL.BACK')}
          </Button>
          <Button
            variant={'contained'}
            color={'secondary'}
            onClick={() => {
              setShowDialog(false)

              setFieldValue(formikName, deliveryTimes)
            }}
          >
            {t('GENERAL.CONFIRM')}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

DeliveryTimes.propTypes = {
  data: PropTypes.array.isRequired,
  formikName: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default withTranslation()(DeliveryTimes)
