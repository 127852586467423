import { Add, Delete, Edit } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { generateField } from '../../../../_helpers/productPropertyFieldGenerator'
import { _applicationService } from '../../../../_services/_applicationService'
import { productPropertiesService } from '../../../../_services/productPropertiesService'
import { productUnitsService } from '../../../../_services/productUnitsService'
import { productsService } from '../../../../_services/productsService'
import AgreementDialog from '../../../AgreementDialog'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { Loading } from '../../../Loading'

const ProductEditor = (props) => {
  const { t } = props

  const [isLoading, setIsLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [productUnits, setProductUnits] = useState([])
  const [productProperties, setProductProperties] = useState([])
  const [productCategories, setProductCategories] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)

  const valSchema = Yup.object().shape({
    name: Yup.string()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    description: Yup.string().nullable(),
    productCategoryId: Yup.number(),
    isActive: Yup.bool()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    productUnits: Yup.array().test({
      message: t('GENERAL.MIN_1'),
      test: function (productUnits) {
        let unitAmount = productUnits.filter(
          (productUnit) => productUnit.checked,
        ).length

        return unitAmount === 0 ? false : true
      },
    }),
    renderPosition: Yup.number()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED'))
      .min(0),
  })

  useEffect(() => {
    setIsLoading(true)
    productPropertiesService.getProductProperties().then((data) => {
      setProductProperties(data)
      _applicationService
        .getProductCategoriesForProductEditor(false)
        .then((data) => {
          setProductCategories(data)
          productUnitsService.getProductUnits().then((data) => {
            setProductUnits(data)
            setIsLoading(false)
          })
        })
    })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    _applicationService.getProductsForProductEditor().then((data) => {
      setProducts(data)
      setIsLoading(false)
    })
  }, [forceUpdateCount])

  return (
    (isLoading &&
      productUnits.length > 0 &&
      productProperties.length > 0 &&
      productCategories.length > 0 && <Loading variant={'centered'} />) || (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              size="medium"
              color={'secondary'}
              variant={'contained'}
              startIcon={<Add />}
              onClick={() => {
                setSelectedItem(null)
                setShowCreateDialog(true)
              }}
            >
              {t('PRODUCT_EDITOR.ADD_PRODUCT')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <List sx={{ width: '40%' }}>
              {products.map((product) => (
                <ListItem
                  secondaryAction={
                    <Fragment>
                      <IconButton
                        onClick={async () => {
                          let selectedProduct =
                            await productsService.getProduct(product.id)
                          setSelectedItem(selectedProduct)
                          setShowCreateDialog(true)
                        }}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setSelectedItem(product)
                          setShowDeleteDialog(true)
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Fragment>
                  }
                >
                  <ListItemText primary={product.name}></ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>

        <AgreementDialog
          open={showDeleteDialog}
          message={t('PRODUCT_EDITOR.DELETE_MESSAGE_PRODUCT')}
          acceptButtonText={t('GENERAL.CONFIRM')}
          handleClose={() => {
            setShowDeleteDialog(false)
            setSelectedItem(null)
          }}
          agree={() => {
            setIsLoading(true)
            setShowDeleteDialog(false)
            productsService
              .deleteProduct(selectedItem.id)
              .then(() => {
                forceUpdate(forceUpdateCount + 1)
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                setIsLoading(false)
                setSelectedItem(null)
              })
          }}
        />
        <Formik
          enableReinitialize={true}
          initialValues={
            selectedItem
              ? {
                  name: selectedItem.name,
                  description: selectedItem.description,
                  productCategoryId: selectedItem.productCategoryId,
                  isActive: selectedItem.isActive,
                  imageRef: selectedItem.imageRef,
                  renderPosition: selectedItem.renderPosition,
                  productLocalizations: selectedItem.productLocalizations,
                  productUnits: productUnits.map((productUnit) => {
                    if (
                      selectedItem.productUnits.some(
                        (selectedProductUnit) =>
                          selectedProductUnit.id === productUnit.id,
                      )
                    ) {
                      return {
                        ...productUnit,
                        checked: true,
                      }
                    } else {
                      return {
                        ...productUnit,
                        checked: false,
                      }
                    }
                  }),
                  productProperties: productProperties.map(
                    (productProperty) => {
                      if (
                        selectedItem.productProperties.some(
                          (selectedProductProperty) =>
                            selectedProductProperty.id === productProperty.id,
                        )
                      ) {
                        return {
                          ...productProperty,
                          checked: true,
                          readonly: selectedItem.productProperties.find(
                            (selectedProductProperty) =>
                              selectedProductProperty.id === productProperty.id,
                          ).readonly,
                          defaultValue: selectedItem.productProperties.find(
                            (selectedProductProperty) =>
                              selectedProductProperty.id === productProperty.id,
                          ).defaultValue,
                          defaultArrayLength:
                            selectedItem.productProperties.find(
                              (selectedProductProperty) =>
                                selectedProductProperty.id ===
                                productProperty.id,
                            ).defaultArrayLength,
                        }
                      } else {
                        return {
                          ...productProperty,
                          checked: false,
                          readonly: false,
                          defaultValue:
                            productProperty.type === 'BOOLEAN'
                              ? false
                              : undefined,
                          defaultArrayLength:
                            productProperty.type === '2D_ARRAY' ? 1 : null,
                        }
                      }
                    },
                  ),
                }
              : {
                  name: '',
                  description: '',
                  productCategoryId: null,
                  isActive: true,
                  renderPosition: 0,
                  productLocalizations: [
                    { language: 'de', value: '' },
                    { language: 'en', value: '' },
                    { language: 'pl', value: '' },
                    { language: 'cs', value: '' },
                    { language: 'bg', value: '' },
                    { language: 'da', value: '' },
                    { language: 'et', value: '' },
                    { language: 'fi', value: '' },
                    { language: 'fr', value: '' },
                    { language: 'el', value: '' },
                    { language: 'it', value: '' },
                    { language: 'lt', value: '' },
                    { language: 'lv', value: '' },
                    { language: 'nl', value: '' },
                    { language: 'pt-PT', value: '' },
                    { language: 'ro', value: '' },
                    { language: 'sv', value: '' },
                    { language: 'sk', value: '' },
                    { language: 'sl', value: '' },
                    { language: 'es', value: '' },
                    { language: 'uk', value: '' },
                    { language: 'hu', value: '' },
                  ],
                  productUnits: productUnits.map((productUnit) => {
                    return {
                      ...productUnit,
                      checked: false,
                    }
                  }),
                  productProperties: productProperties.map(
                    (productProperty) => {
                      return {
                        ...productProperty,
                        checked: false,
                        readonly: false,
                        defaultValue:
                          productProperty.type === 'BOOLEAN'
                            ? false
                            : undefined,
                        defaultArrayLength:
                          productProperty.type === '2D_ARRAY' ? 1 : null,
                      }
                    },
                  ),
                }
          }
          validationSchema={valSchema}
          //validateOnBlur={false}
          onSubmit={(values, { setSubmitting }) => {
            let productCategory = selectedItem
              ? productCategories.find(
                  (productCategory) =>
                    productCategory.id === selectedItem.productCategoryId,
                )
              : null
            let parentProductProperties = productCategory
              ? getParentProductProperties(
                  productCategory,
                  productCategory.productProperties,
                )
              : []

            let payload = {
              ...values,
              productUnits: values.productUnits
                .filter((productUnit) => productUnit.checked)
                .map((productUnit) => productUnit.id),
              productProperties: values.productProperties
                .filter(
                  (productProperty) =>
                    productProperty.checked ||
                    parentProductProperties.some(
                      (parentProductProperty) =>
                        parentProductProperty.id === productProperty.id,
                    ),
                )
                .map((productProperty) => {
                  return {
                    id: productProperty.id,
                    readonly: productProperty.readonly,
                    defaultArrayLength: productProperty.defaultArrayLength,
                    defaultValue:
                      productProperty.type === 'ARRAY' ||
                      productProperty.type === '2D_ARRAY'
                        ? productProperty.defaultValue
                          ? productProperty.defaultValue.map(
                              (defaultValue, index) => {
                                return {
                                  productPropertyId:
                                    productProperty.productPropertyArray
                                      .productPropertyArrayElements[index]
                                      .productPropertyId,
                                  value: defaultValue
                                    ? defaultValue.value
                                    : null,
                                }
                              },
                            )
                          : productProperty.productPropertyArray.productPropertyArrayElements.map(
                              (arrayElement) => {
                                return {
                                  productPropertyId:
                                    arrayElement.productPropertyId,
                                  value: null,
                                }
                              },
                            )
                        : productProperty.defaultValue,
                  }
                }),
            }

            if (selectedItem) {
              productsService
                .updateProduct(selectedItem.id, payload)
                .then((data) => {
                  forceUpdate(forceUpdateCount + 1)
                  setShowCreateDialog(false)
                })
                .catch(function (error) {
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                })
            } else {
              productsService
                .createProduct(payload)
                .then((data) => {
                  forceUpdate(forceUpdateCount + 1)
                  setShowCreateDialog(false)
                })
                .catch(function (error) {
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                })
            }
          }}
        >
          {({ values, isSubmitting, submitForm, isValid }) => {
            let productCategory = selectedItem
              ? productCategories.find(
                  (productCategory) =>
                    productCategory.id === selectedItem.productCategoryId,
                )
              : !selectedItem && values.productCategoryId
              ? productCategories.find(
                  (productCategory) =>
                    productCategory.id === values.productCategoryId,
                )
              : null

            let parentProductProperties = productCategory
              ? getParentProductProperties(
                  productCategory,
                  productCategory.productProperties,
                )
              : []

            return (
              <Dialog fullWidth={true} maxWidth="md" open={showCreateDialog}>
                <CustomDialogTitle
                  title={t('PRODUCT_EDITOR.CREATE_PRODUCT')}
                  onClose={() => {
                    setSelectedItem(null)
                    setShowCreateDialog(false)
                  }}
                />
                <DialogContent>
                  <Form>
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PRODUCT_EDITOR.PRODUCT_PROPERTY_CATEGORY_NAME')}
                      variant="outlined"
                      name="name"
                    />
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PRODUCT_EDITOR.IMAGE_LINK')}
                      variant="outlined"
                      name="imageRef"
                    />
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PRODUCT_EDITOR.DESCRIPTION')}
                      variant="outlined"
                      name="description"
                      multiline
                      rows="4"
                    />
                    <Field
                      component={TextField}
                      select
                      name="productCategoryId"
                      label={t('PRODUCT_EDITOR.PRODUCT_CATEGORY')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    >
                      {productCategories.map((productCategory) => (
                        <MenuItem
                          key={productCategory.id}
                          value={productCategory.id}
                        >
                          {productCategory.name}
                        </MenuItem>
                      ))}
                    </Field>
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PRODUCT_EDITOR.RENDER_POSITION')}
                      variant="outlined"
                      name="renderPosition"
                      inputProps={{
                        decimalScale: 0,
                        allowNegative: false,
                      }}
                    />
                    <Field
                      component={CheckboxWithLabel}
                      type={'checkbox'}
                      checked={values.isActive}
                      name={'isActive'}
                      margin="dense"
                      Label={{
                        label: t('PRODUCT_EDITOR.IS_ACTIVE'),
                      }}
                    />
                    <Divider />
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography>
                          {t('PRODUCT_EDITOR.PRODUCT_UNITS')}
                        </Typography>
                        <ErrorMessage
                          name={'productUnits'}
                          render={(msg) => (
                            <div className="help-block">{msg}</div>
                          )}
                        />
                      </Grid>
                      {productUnits &&
                        values.productUnits.length > 0 &&
                        productUnits.map((productUnit, index) => {
                          return (
                            <Grid item xs={4}>
                              <Field
                                component={CheckboxWithLabel}
                                type={'checkbox'}
                                checked={values.productUnits[index].checked}
                                name={`productUnits[${index}].checked`}
                                margin="dense"
                                Label={{
                                  label: productUnit.name,
                                }}
                              />
                            </Grid>
                          )
                        })}
                      <Divider />
                    </Grid>
                    <Divider />
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography>
                          {t('PRODUCT_EDITOR.PRODUCT_PROPERTIES')}
                        </Typography>
                      </Grid>
                      {productProperties &&
                        values.productProperties.length > 0 &&
                        productProperties.map((productProperty, index) => {
                          return (
                            <Grid item xs={4}>
                              <Field
                                disabled={parentProductProperties.some(
                                  (parentProductProperty) =>
                                    parentProductProperty.id ===
                                    productProperty.id,
                                )}
                                component={CheckboxWithLabel}
                                type={'checkbox'}
                                checked={
                                  values.productProperties[index].checked ||
                                  parentProductProperties.some(
                                    (parentProductProperty) =>
                                      parentProductProperty.id ===
                                      productProperty.id,
                                  )
                                }
                                name={`productProperties[${index}].checked`}
                                margin="dense"
                                Label={{
                                  label: productProperty.name,
                                }}
                              />
                              {values.productProperties[index].checked ||
                              parentProductProperties.some(
                                (parentProductProperty) =>
                                  parentProductProperty.id ===
                                  productProperty.id,
                              ) ? (
                                <Fragment>
                                  <Field
                                    component={CheckboxWithLabel}
                                    type={'checkbox'}
                                    checked={
                                      values.productProperties[index].readonly
                                    }
                                    name={`productProperties[${index}].readonly`}
                                    margin="dense"
                                    Label={{
                                      label: t('PRODUCT_EDITOR.READ_ONLY'),
                                    }}
                                  />
                                  {productProperty.type === '2D_ARRAY' && (
                                    <Field
                                      component={TextField}
                                      name={`productProperties[${index}].defaultArrayLength`}
                                      margin="dense"
                                      variant="outlined"
                                      label={t(
                                        'PRODUCT_EDITOR.DEFAULT_ARRAY_LENGTH',
                                      )}
                                      inputProps={{
                                        decimalScale: 0,
                                        allowNegative: false,
                                      }}
                                    />
                                  )}
                                  {productProperty.type === 'ARRAY' ||
                                  productProperty.type === '2D_ARRAY'
                                    ? productProperty.productPropertyArray.productPropertyArrayElements.map(
                                        (arrayElement, arrayIndex) =>
                                          generateField(
                                            productProperties.find(
                                              (productProperty) =>
                                                productProperty.id ===
                                                arrayElement.productPropertyId,
                                            ),
                                            `productProperties[${index}].defaultValue[${arrayIndex}].value`,
                                            Array.isArray(
                                              values.productProperties[index]
                                                .defaultValue,
                                            )
                                              ? values.productProperties[index]
                                                  .defaultValue[arrayIndex]
                                              : null,
                                          ),
                                      )
                                    : generateField(
                                        productProperty,
                                        `productProperties[${index}].defaultValue`,
                                        values.productProperties[index]
                                          .defaultValue,
                                      )}
                                </Fragment>
                              ) : null}
                            </Grid>
                          )
                        })}
                    </Grid>
                    <Divider />
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography>
                          {t('PRODUCT_EDITOR.TRANSLATIONS')}
                        </Typography>
                      </Grid>
                      {values.productLocalizations.map(
                        (productLocalization, index) => (
                          <Grid item xs={3}>
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={productLocalization.language}
                              variant="outlined"
                              name={`productLocalizations[${index}].value`}
                            />
                          </Grid>
                        ),
                      )}
                    </Grid>
                  </Form>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setShowCreateDialog(false)}
                    variant="outlined"
                    color={'secondary'}
                  >
                    {t('GENERAL.CANCEL')}
                  </Button>
                  <Button
                    onClick={() => submitForm()}
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    {t('GENERAL.CONFIRM')}
                  </Button>
                </DialogActions>
              </Dialog>
            )
          }}
        </Formik>
      </Fragment>
    )
  )
  function getParentProductProperties(productCategory, productProperties = []) {
    if (productCategory.parentId) {
      let parentCategory = productCategories.find(
        (pCategory) => pCategory.id === productCategory.parentId,
      )
      productProperties = productProperties.concat(
        parentCategory.productProperties,
      )

      return getParentProductProperties(parentCategory, productProperties)
    } else {
      return productProperties
    }
  }
}

export default withTranslation()(ProductEditor)
