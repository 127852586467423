import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { Checkbox, TextField } from 'formik-mui'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { alertActions } from '../../_actions/alertActions'
import { countryList } from '../../_constants/lookupConstants'
import { partnersService } from '../../_services/partnersService'
import CustomDialogTitle from '../CustomDialogTitle'
import { Section } from '../Section'

const RegisterPartnerDialog = (props) => {
  const { t, dispatch, open, close } = props

  const initialValues = {
    name: '',
    taxId: '',
    countryId: '80',
    address: '',
    zipcode: '',
    place: '',
    type: 'customer',
    website: '',
    skipMailing: false,
    users: [
      {
        salutation: 'MR',
        email: 'dummy@pacurion.com',
        firstname: '',
        lastname: '',
        position: '',
        phone: '',
        mobile: '',
        fax: '',
        notificationLanguage: 'DE',
      },
    ],
  }
  const phoneRegExp = /^[\d+]+$/
  const valSchema = Yup.object().shape({
    name: Yup.string().required(t('GENERAL.REQUIRED')),
    taxId: Yup.string().required(t('GENERAL.REQUIRED')),
    countryId: Yup.string().required(t('GENERAL.REQUIRED')),
    address: Yup.string().required(t('GENERAL.REQUIRED')),
    zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
    place: Yup.string().required(t('GENERAL.REQUIRED')),
    type: Yup.string().required(t('GENERAL.REQUIRED')),
    website: Yup.string(),
    skipMailing: Yup.string().required(t('GENERAL.REQUIRED')),
    users: Yup.array(
      Yup.object().shape({
        email: Yup.string()
          .required(t('GENERAL.REQUIRED'))
          .email(t('GENERAL.INVALID_EMAIL')),
        salutation: Yup.string(),
        firstname: Yup.string().required(t('GENERAL.REQUIRED')),
        lastname: Yup.string().required(t('GENERAL.REQUIRED')),
        position: Yup.string(),
        notificationLanguage: Yup.string().required(t('GENERAL.REQUIRED')),
        languages: Yup.string(),
        phone: Yup.string()
          .matches(phoneRegExp, t('GENERAL.INVALID_PHONE'))
          .required(t('GENERAL.REQUIRED')),
        mobile: Yup.string(),
        fax: Yup.string(),
      }),
    ),
  })

  return (
    <Dialog onClose={() => close()} fullWidth={true} maxWidth="xl" open={open}>
      <CustomDialogTitle
        title={t('REGISTER.REGISTER')}
        onClose={() => close()}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={valSchema}
        onSubmit={(values, { setSubmitting }) => {
          partnersService
            .createPartner(values)
            .then((data) => {
              close()
              setSubmitting(false)
              dispatch(
                alertActions.info(t('PARTNER_REGISTRATION.PUBLISH_SUCCESSFUL')),
              )
              setTimeout(() => {
                dispatch(alertActions.clear())
              }, alertActions.alertTimeout)
            })
            .catch(function (error) {
              setSubmitting(false)
              dispatch(alertActions.error(error))
              setTimeout(() => {
                dispatch(alertActions.clear())
              }, alertActions.alertTimeout)
              console.log(error)
            })
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <DialogContent>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Section header={t('REGISTER.USER_DETAILS')}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={4}>
                        <Field
                          component={TextField}
                          select
                          name="users[0].salutation"
                          label={t('REGISTER.SALUTATION')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        >
                          <MenuItem key="MR" value="MR">
                            {t('REGISTER.MR')}
                          </MenuItem>
                          <MenuItem key="MS" value="MS">
                            {t('REGISTER.MS')}
                          </MenuItem>
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          component={TextField}
                          name="users[0].firstname"
                          label={t('REGISTER.FIRST_NAME')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          component={TextField}
                          name="users[0].lastname"
                          label={t('REGISTER.LAST_NAME')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          component={TextField}
                          select
                          name="users[0].notificationLanguage"
                          label={t('REGISTER.NOTIFICATION_LANGUAGE')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        >
                          <MenuItem key="DE" value="DE">
                            {t('REGISTER.GERMAN')}
                          </MenuItem>
                          <MenuItem key="EN" value="EN">
                            {t('REGISTER.ENGLISH')}
                          </MenuItem>
                          <MenuItem key="PL" value="PL">
                            {t('REGISTER.POLISH')}
                          </MenuItem>
                          <MenuItem key="CS" value="CS">
                            {t('REGISTER.CZECH')}
                          </MenuItem>
                        </Field>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="users[0].position"
                          label={t('REGISTER.POSITION')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          component={TextField}
                          name="users[0].phone"
                          label={t('REGISTER.PHONE')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          component={TextField}
                          name="users[0].mobile"
                          label={t('REGISTER.MOBILE')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          component={TextField}
                          name="users[0].fax"
                          label={t('REGISTER.FAX')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="users[0].email"
                          label={t('REGISTER.EMAIL')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                    </Grid>
                  </Section>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Section header={t('REGISTER.COMPANY_DETAILS')}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="name"
                          label={t('REGISTER.COMPANY_NAME')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="taxId"
                          label={t('REGISTER.TAX_ID')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          name="address"
                          label={t('REGISTER.ADDRESS')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="zipcode"
                          label={t('REGISTER.ZIP_CODE')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="place"
                          label={t('REGISTER.PLACE')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          select
                          name="countryId"
                          label={t('REGISTER.HOME_COUNTRY')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        >
                          {Object.entries(countryList).map((country) => (
                            <MenuItem value={country[1]} key={country[0]}>
                              {country[0]}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="type"
                          label={t('REGISTER.COMPANY_TYPE')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                          select
                        >
                          <MenuItem key="manufacturer" value="manufacturer">
                            {t('REGISTER.MANUFACTURER')}
                          </MenuItem>
                          <MenuItem key="customer" value="customer">
                            {t('REGISTER.CUSTOMER')}
                          </MenuItem>
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          name="website"
                          label={t('REGISTER.WEBSITE')}
                          variant="outlined"
                          fullWidth={true}
                          margin="dense"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          alignSelf: 'center',
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Field
                              component={Checkbox}
                              type={'checkbox'}
                              color="default"
                              margin="dense"
                              name="skipMailing"
                              style={{ margin: '0px' }}
                            />
                          }
                          label={t('REGISTER.SKIP_MAILING')}
                          style={{ margin: '0 0 0 10px' }}
                        />
                      </Grid>
                    </Grid>
                  </Section>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                disabled={!isValid || isSubmitting}
                type="submit"
                style={{ margin: '0 10px 0 15px' }}
              >
                {t('REGISTER.REGISTER')}
              </Button>
              <Button
                variant="contained"
                disabled={isSubmitting}
                onClick={(e) => close()}
              >
                {t('GENERAL.CANCEL')}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default withTranslation()(connect()(RegisterPartnerDialog))
