import { productLookup } from '../_constants/lookupConstants'
import { _proxiesService } from '../_services/_proxiesService'
import i18n from '../i18n'

const useOCIPunchout = () => {
  const punchout = async (
    hookURL,
    offerPositions,
    locationStackHeight,
    stackHeightUnit,
    requestId,
    orderIds,
  ) => {
    var myHeaders = new Headers()

    var formdata = new FormData()

    for (let index = 0; index < offerPositions.length; index++) {
      const oPos = offerPositions[index]

      let amount = oPos.amount

      if (oPos.productUnitId === 3) {
        let stackHeight =
          stackHeightUnit === 'CM'
            ? parseFloat(locationStackHeight) / 100 //CM->M
            : parseFloat(locationStackHeight)

        let length =
          parseInt(
            oPos.positionProperties.find(
              (positionProperty) => positionProperty.productPropertyId === 1,
            ).value,
          ) / 1000

        let width =
          parseInt(
            oPos.positionProperties.find(
              (positionProperty) => positionProperty.productPropertyId === 2,
            ).value,
          ) / 1000

        let height = null

        let heightNested = null

        let deliverType =
          oPos.positionProperties.find((positionProperty) =>
            [22, 47].includes(positionProperty.productPropertyId),
          ).value === 'verschachtelt'
            ? 1
            : 0

        let positionPropertyTopBoard = oPos.positionProperties.find(
          (positionProperty) => positionProperty.productPropertyId === 40,
        )

        let positionPropertyCrossBoard = oPos.positionProperties.find(
          (positionProperty) => positionProperty.productPropertyId === 41,
        )

        let positionPropertyBlocks = oPos.positionProperties.find(
          (positionProperty) => positionProperty.productPropertyId === 42,
        )

        let positionPropertyBottomBoards = oPos.positionProperties.find(
          (positionProperty) => positionProperty.productPropertyId === 43,
        )

        let positionPropertySquareTimber = oPos.positionProperties.find(
          (positionProperty) => positionProperty.productPropertyId === 55,
        )

        switch (oPos.productId) {
          case 20:
            height = 0.144
            heightNested = 0.188

            break
          case 2:
            height = 0.138
            heightNested = 0.178

            break
          case 1:
            var topboardHeight = parseFloat(
              positionPropertyTopBoard.value.length > 0
                ? positionPropertyTopBoard.value[0].find(
                    (pair) => pair.productPropertyId === 7,
                  ).value
                : 0,
            )

            var crossboardHeight = parseFloat(
              positionPropertyCrossBoard.value.length > 0
                ? positionPropertyCrossBoard.value[0].find(
                    (pair) => pair.productPropertyId === 10,
                  ).value
                : 0,
            )

            var blockHeight = parseFloat(
              positionPropertyBlocks.value.length > 0
                ? positionPropertyBlocks.value[0].find(
                    (pair) => pair.productPropertyId === 14,
                  ).value
                : 0,
            )

            var bottomboardHeight = parseFloat(
              positionPropertyBottomBoards.value.length > 0
                ? positionPropertyBottomBoards.value[0].find(
                    (pair) => pair.productPropertyId === 18,
                  ).value
                : 0,
            )

            height =
              (topboardHeight +
                crossboardHeight +
                blockHeight +
                bottomboardHeight) /
              1000

            heightNested =
              (2 * topboardHeight +
                2 * crossboardHeight +
                blockHeight +
                bottomboardHeight) /
              1000
            break
          case 44:
            topboardHeight = parseFloat(
              positionPropertyTopBoard.value.length > 0
                ? positionPropertyTopBoard.value[0].find(
                    (pair) => pair.productPropertyId === 7,
                  ).value
                : 0,
            )

            var squareTimberHeight = parseFloat(
              positionPropertySquareTimber.value.length > 0
                ? positionPropertySquareTimber.value[0].find(
                    (pair) => pair.productPropertyId === 54,
                  ).value
                : 0,
            )

            bottomboardHeight = parseFloat(
              positionPropertyBottomBoards &&
                positionPropertyBottomBoards.value.length > 0
                ? positionPropertyBottomBoards.value[0].find(
                    (pair) => pair.productPropertyId === 18,
                  ).value
                : 0,
            )

            height =
              (topboardHeight + squareTimberHeight + bottomboardHeight) / 1000

            heightNested =
              (2 * topboardHeight + squareTimberHeight + bottomboardHeight) /
              1000

            break
          default:
            height =
              parseInt(
                oPos.positionProperties.find(
                  (positionProperty) =>
                    positionProperty.productPropertyId === 28,
                ).value,
              ) / 1000

            //Factor of EPAL 1 for estimating nested height
            heightNested = height * 1.3055
            break
        }

        if (!stackHeight) {
          let pieces = Math.floor(
            deliverType === 1 ? (2.9 - height) / heightNested : 2.9 / height,
          )

          stackHeight =
            deliverType === 1 ? height + heightNested * pieces : height * pieces
        } else {
          if (stackHeightUnit === 'CM') {
            if (
              stackHeight < 1.44 /*Unlikely*/ ||
              stackHeight > 3.0 /*Trucks are smaller than 300*/
            ) {
              continue
            }
          } else {
            if (
              stackHeight < 10 /*10 Pieces are highly unusual*/ ||
              stackHeight > 50 /*More than 50 is highly unusual*/
            ) {
              continue
            } else {
              stackHeight =
                deliverType === 0
                  ? stackHeight * height
                  : height + ((stackHeight - 1) / 2) * heightNested

              if (
                stackHeight < 1.44 /*Unlikely*/ ||
                stackHeight > 3.0 /*Trucks are smaller than 300*/
              ) {
                continue
              }
            }
          }
        }

        let cubicmeter = length * width * height

        let predictions = await _proxiesService.predictAmountWithinTruck([
          [cubicmeter, deliverType, stackHeight],
        ])
        //Add Buffer: 27.5% more to prediction,
        amount = Math.ceil(oPos.amount * (predictions[0] * 1.275))
      }

      formdata.append(
        `NEW_ITEM-DESCRIPTION[${index + 1}]`,
        productLookup[oPos.productId],
      )
      formdata.append(`NEW_ITEM-QUANTITY[${index + 1}]`, amount.toString())
      formdata.append(`NEW_ITEM-UNIT[${index + 1}]`, 'PCE')
      formdata.append(`NEW_ITEM-CURRENCY[${index + 1}]`, 'EUR')
      formdata.append(`NEW_ITEM-PRICE[${index + 1}]`, oPos.price)

      formdata.append(
        `NEW_ITEM-LONGTEXT_${index + 1}:132[]`,
        requestId
          ? `Anfrage Nr.: ${requestId.toString()} Aufträge: ${orderIds.join(
              ',',
            )}`
          : `Aufträge: ${orderIds.join(',')}`,
      )
    }

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    }

    fetch(hookURL, requestOptions)
      .then((response) => response.text())
      .then((html) => {
        console.log(html)

        const responseObj = parseResponse(html)
        processResponse(responseObj)
      })
      .catch((error) => console.log('error', error))
  }

  return {
    punchout,
  }
}

function parseResponse(html) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const successScript = doc.querySelector('script')

  if (successScript) {
    return {
      event: 'punchoutsuccess',
    }
  }

  const errorMessage = doc.querySelector('body form')

  if (errorMessage) {
    return {
      event: 'punchoutfailure',
      message: errorMessage.textContent.trim(),
    }
  }

  return html
}

function processResponse(response) {
  if (response.event === 'punchoutsuccess') {
    console.log('Punchout erfolgreich!')
    if (window.opener) {
      window.opener.focus()
    }
    closeWindowWithMessage()
  } else if (response.event === 'punchoutfailure') {
    alert(`Punchout fehlgeschlagen! Fehlermeldung: ${response.message}`)
    // Führen Sie hier den gewünschten Code aus, wenn der Punchout fehlgeschlagen ist
  } else {
    alert(`Unbekannter Fehler biem durchführen des Punchouts: ${response}`)
  }
}

function closeWindowWithMessage() {
  const closeMessage = document.createElement('div')
  closeMessage.textContent = i18n.t('PUNCHOUT.SUCCESS_CLOSE_NOW')
  closeMessage.style.position = 'absolute'
  closeMessage.style.top = '50%'
  closeMessage.style.left = '50%'
  closeMessage.style.transform = 'translate(-50%, -50%)'
  closeMessage.style.fontSize = '24px'
  closeMessage.style.textAlign = 'center'
  document.body.innerHTML = ''
  document.body.appendChild(closeMessage)
}

export default useOCIPunchout
