import { Button, Grid, InputAdornment, MenuItem } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { alertActions } from '../../../../../../_actions/alertActions'
import { countryList } from '../../../../../../_constants/lookupConstants'
import { authorize } from '../../../../../../_helpers/authorization'
import NumberFormatCustom from '../../../../../../_helpers/numberFormatCustom'
import { generalService } from '../../../../../../_services/generalService'
import { partnersService } from '../../../../../../_services/partnersService'
import GoogleAutocomplete from '../../../../../GoogleAutocomplete'
import { Loading } from '../../../../../Loading'
import { Section } from '../../../../../Section'

const Masterdata = (props) => {
  const { t, dispatch } = props

  const [isLoading, setIsLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    id: null,
    size: 0,
    founded: 0,
    capacity: 0,
    address: '',
    zipcode: '',
    place: '',
    website: '',
    company: '',
    countryId: undefined,
  })
  const [paymentTerms, setPaymentTerms] = useState([])
  const [skontos, setSkontos] = useState([])

  var userObject = JSON.parse(localStorage.getItem('user'))
  let partnerId = parseInt(userObject.partner.id)

  useEffect(() => {
    setPaymentTerms(
      generalService.getPaymentTerms(userObject.partner.allPaymentTermsAllowed),
    )
    setSkontos(generalService.getSkontos())
  }, [userObject.partner.allPaymentTermsAllowed])

  useEffect(() => {
    setIsLoading(true)

    partnersService.getPartner(partnerId).then((partnerData) => {
      partnerData.size = partnerData.size ? partnerData.size : 0
      partnerData.founded = partnerData.founded ? partnerData.founded : 0
      partnerData.capacity = partnerData.capacity ? partnerData.capacity : 0
      partnerData.selectedLocationId = partnerData.partnerLocations.find(
        (partnerLocation) => partnerLocation.primary,
      ).id

      partnerData.selectedInvoiceLocationId =
        partnerData.partnerInvoiceLocations.find(
          (partnerInvoiceLocation) => partnerInvoiceLocation.primary,
        ).id

      setInitialValues({
        ...partnerData,
        partnerLocations: undefined,
        partnerInvoiceLocations: undefined,
      })
      setIsLoading(false)
    })
  }, [partnerId])

  const valSchema = Yup.object().shape({
    size: Yup.number(),
    founded: Yup.number(),
    capacity: Yup.number(),
    address: Yup.string().required(t('GENERAL.REQUIRED')),
    zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
    place: Yup.string().required(t('GENERAL.REQUIRED')),
    countryId: Yup.number().required(t('GENERAL.REQUIRED')),
    website: Yup.string(),
    defaultPaymentTerms: Yup.string().required(t('GENERAL.REQUIRED')),
    defaultSkonto: Yup.string().required(t('GENERAL.REQUIRED')),
    externalCreditorId: Yup.number().nullable(),
    defaultSkontoPeriod: Yup.string()
      .nullable()
      .test('required', t('GENERAL.REQUIRED'), function (defaultSkontoPeriod) {
        return (
          parseInt(this.parent.defaultSkonto) === 0 ||
          (defaultSkontoPeriod !== null && defaultSkontoPeriod !== '')
        )
      })
      .test(
        'paymentTerm',
        t('REQUEST.PAYMENT_DETAILS.INVALID_REF_PAYMENT_TERM'),
        function (defaultSkontoPeriod) {
          return (
            defaultSkontoPeriod === null ||
            defaultSkontoPeriod === '' ||
            parseInt(defaultSkontoPeriod) <=
              parseInt(this.parent.defaultPaymentTerms)
          )
        },
      ),
  })

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={valSchema}
        //validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          let requestBody = {
            size: values.size,
            founded: values.founded,
            capacity: values.capacity,
            name: values.company,
            address: values.address,
            zipcode: values.zipcode,
            place: values.place,
            website: values.website,
            countryId: values.countryId,
            defaultPaymentTerms: values.defaultPaymentTerms,
            defaultSkonto: values.defaultSkonto,
            defaultSkontoPeriod: values.defaultSkontoPeriod,
            externalCreditorId: parseInt(values.externalCreditorId),
          }

          partnersService
            .updatePartner(partnerId, requestBody)
            .then(() => {
              partnersService.getPartner(partnerId).then((partnerData) => {
                setInitialValues({
                  ...partnerData,
                  selectedLocationId: partnerData.partnerLocations.find(
                    (location) => location.primary,
                  ).id,
                  selectedInvoiceLocationId:
                    partnerData.partnerInvoiceLocations.find(
                      (location) => location.primary,
                    ).id,
                  partnerLocations: partnerData.partnerLocations.map(
                    (location) => {
                      return {
                        ...location,
                        deliveryInformations: location.deliveryInformations
                          ? location.deliveryInformations
                          : '',
                        despatchAdviceInstructions:
                          location.despatchAdviceInstructions
                            ? location.despatchAdviceInstructions
                            : '',
                      }
                    },
                  ),
                  partnerInvoiceLocations:
                    partnerData.partnerInvoiceLocations.map((location) => {
                      return {
                        ...location,
                        contactPerson: location.contactPerson
                          ? location.contactPerson
                          : '',
                        additionalAddressLine: location.additionalAddressLine
                          ? location.additionalAddressLine
                          : '',
                        email: location.email ? location.email : '',
                      }
                    }),
                })
                setIsLoading(false)
              })
              dispatch(
                alertActions.info(t('PARTNER_SETTINGS.SAVING_SUCCESSFUL')),
              )
              setTimeout(() => {
                dispatch(alertActions.clear())
              }, alertActions.alertTimeout)
              setSubmitting(false)
            })
            .catch(function (error) {
              dispatch(alertActions.error(error))
              setTimeout(() => {
                dispatch(alertActions.clear())
              }, alertActions.alertTimeout)
              setSubmitting(false)
            })
        }}
      >
        {({ isSubmitting, values, setFieldValue, submitForm, isValid }) => {
          return (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <Section
                        header={t('PARTNER_SETTINGS.PUBLIC_INFORMATIONS')}
                      >
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t('PARTNER_SETTINGS.COMPANY_ID')}
                          variant="outlined"
                          name="id"
                          disabled={true}
                        />
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t('PARTNER_SETTINGS.EMPLOYEE_AMOUNT')}
                          variant="outlined"
                          name="size"
                        />
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t('PARTNER_SETTINGS.FOUNDATION_YEAR')}
                          variant="outlined"
                          name="founded"
                        />
                        {userObject.partner.type === 'manufacturer' ? (
                          <Field
                            component={TextField}
                            fullWidth
                            margin="dense"
                            label={t('PARTNER_SETTINGS.MANUFACTURING_CAPACITY')}
                            variant="outlined"
                            name="capacity"
                          />
                        ) : null}
                      </Section>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Section
                        header={t('PARTNER_SETTINGS.PRIVATE_INFORMATIONS')}
                      >
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t('PARTNER_SETTINGS.COMPANY')}
                          variant="outlined"
                          name="company"
                          disabled={!authorize('administrator')}
                        />
                        <GoogleAutocomplete
                          setFieldValue={setFieldValue}
                          formikName={`address`}
                        />
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t('PARTNER_SETTINGS.ZIPCODE')}
                          variant="outlined"
                          name="zipcode"
                        />
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t('PARTNER_SETTINGS.PLACE')}
                          variant="outlined"
                          name="place"
                        />
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t('PARTNER_SETTINGS.WEBSITE')}
                          variant="outlined"
                          name="website"
                        />
                        <Field
                          disabled={true}
                          component={TextField}
                          name="countryId"
                          select
                          fullWidth
                          label={t('PARTNER_SETTINGS.COUNTRY')}
                          variant="outlined"
                          margin="dense"
                        >
                          {Object.entries(countryList).map((country) => (
                            <MenuItem value={country[1]} key={country[0]}>
                              {country[0]}
                            </MenuItem>
                          ))}
                        </Field>
                        <Field
                          component={TextField}
                          name="defaultPaymentTerms"
                          select
                          fullWidth
                          label={t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM')}
                          disabled={
                            !JSON.parse(localStorage.getItem('user')).partner
                              .allPaymentTermsAllowed
                          }
                          variant="outlined"
                          margin="dense"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM_UNIT')}
                              </InputAdornment>
                            ),
                            onChange: (e) => {
                              if (parseInt(e.target.value) === 0) {
                                setFieldValue('defaultSkontoPeriod', null)
                                setFieldValue('defaultSkonto', 0)
                              }
                              setFieldValue(
                                'defaultPaymentTerms',
                                e.target.value,
                              )
                            },
                          }}
                        >
                          {paymentTerms.map((paymentTerm) => (
                            <MenuItem value={paymentTerm} key={paymentTerm}>
                              {paymentTerm !== 0
                                ? paymentTerm
                                : t('GENERAL.PREPAYMENT')}
                            </MenuItem>
                          ))}
                        </Field>
                        {JSON.parse(localStorage.getItem('user')).partner
                          .allPaymentTermsAllowed && (
                          <Fragment>
                            <Field
                              component={TextField}
                              name="defaultSkonto"
                              select
                              fullWidth
                              label={t('REQUEST.PAYMENT_DETAILS.SKONTO')}
                              variant="outlined"
                              margin="dense"
                              disabled={
                                parseInt(values.defaultPaymentTerms) === 0
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    style={{ paddingRight: '20px' }}
                                  >
                                    {t('REQUEST.PAYMENT_DETAILS.SKONTO_UNIT')}
                                  </InputAdornment>
                                ),
                                onChange: (e) => {
                                  if (parseInt(e.target.value) === 0) {
                                    setFieldValue('defaultSkontoPeriod', null)
                                  }
                                  setFieldValue('defaultSkonto', e.target.value)
                                },
                              }}
                            >
                              {skontos.map((skonto) => (
                                <MenuItem value={skonto} key={skonto}>
                                  {skonto}
                                </MenuItem>
                              ))}
                            </Field>
                            <Field
                              component={TextField}
                              name="defaultSkontoPeriod"
                              select
                              fullWidth
                              label={t('REQUEST.PAYMENT_DETAILS.SKONTO_PERIOD')}
                              variant="outlined"
                              margin="dense"
                              disabled={parseInt(values.defaultSkonto) === 0}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {t(
                                      'REQUEST.PAYMENT_DETAILS.PAYMENT_TERM_UNIT',
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            >
                              {paymentTerms
                                .filter(
                                  (paymentTerm) =>
                                    paymentTerm <= values.defaultPaymentTerms,
                                )
                                .map((paymentTerm) => (
                                  <MenuItem
                                    value={paymentTerm}
                                    key={paymentTerm}
                                  >
                                    {paymentTerm}
                                  </MenuItem>
                                ))}
                            </Field>
                          </Fragment>
                        )}
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          inputProps={{
                            inputComponent: NumberFormatCustom,
                            decimalScale: 0,
                            allowNegative: false,
                          }}
                          label={t('PARTNER_SETTINGS.CREDITOR_ID')}
                          variant="outlined"
                          name="externalCreditorId"
                          hidden={!authorize('administrator')}
                        />
                      </Section>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    style={{ margin: '0 0 15px 15px' }}
                    type="submit"
                  >
                    {t('PARTNER_SETTINGS.SAVE')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )
        }}
      </Formik>
    )
  )
}

export default withTranslation()(connect()(Masterdata))
