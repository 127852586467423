import { AppBar, Box, Toolbar } from '@mui/material'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

const ScrollableFullHeightContainer = (props) => {
  const { alert, children, toolbar } = props

  //Force update on resize: https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const containerRef = useRef(null)
  const appBarRef = useRef(null)

  let containerHeight = 0

  if (containerRef.current && appBarRef.current) {
    containerHeight =
      window.innerHeight -
      containerRef.current.offsetTop -
      appBarRef.current.clientHeight -
      73 -
      (alert.hasOwnProperty('type') ? 44 : 0)
  }

  return (
    <Box ref={containerRef} height={containerHeight}>
      <Scrollbars height={containerHeight} autoHide={false}>
        {children}
      </Scrollbars>
      <AppBar
        position={'relative'}
        color={'default'}
        sx={{ top: 'auto', bottom: 0 }}
        ref={appBarRef}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          {toolbar}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

function mapStateToProps(state) {
  const { alert } = state
  return {
    alert,
  }
}

export default withTranslation()(
  connect(mapStateToProps)(ScrollableFullHeightContainer),
)
