import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const partnerCommissionGroupsService = {
  getPartnerCommissionGroups,
}

function getPartnerCommissionGroups(partnerId, jobOfferToken) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/partner-commission-groups`,
    requestOptions,
  ).then(handleResponse)
}

export * from './partnerCommissionGroupsService'
