import {
  Add as CreateOfferIcon,
  EventAvailable as GotoOrdersIcon,
  LocalOffer as OfferIcon,
  Receipt,
  Assignment as RequestsIcon,
  Gavel as TendersIcon,
} from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import HighQualityOutlinedIcon from '@mui/icons-material/HighQualityOutlined'
import {
  Chip,
  Dialog,
  DialogContent,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { groupBy, sumBy } from 'lodash'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import {
  categorizedProductLookup,
  countryLookup,
  deliveryTypeLookup,
  marketplaceRequestStateLookup,
  productLookup,
  productStateLookup,
  requestTypeLookup,
} from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import { partnerCommissionGroupsService } from '../../../../_services/partnerCommissionGroupsService'
import { requestsService } from '../../../../_services/requestsService'
import CustomDialogTitle from '../../../CustomDialogTitle'
import Offer from '../../../Offer'
import { PositionsList } from '../../../PositionsList'
import RequestDetails from '../../../RequestDetails'
import ResourceMap from '../../../ResourceMap'
import ViewDescription from '../../../ViewDescription'

const Marketplace = (props) => {
  const { t } = props

  var userObject = JSON.parse(localStorage.getItem('user'))

  const [requests, setRequests] = useState([])
  const [showOfferModal, setShowOfferModal] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)
  const [priceList, setPriceList] = useState([])
  const [partnerCommissionGroups, setPartnerCommissionGroups] = useState([])
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLessThanLg = useMediaQuery(theme.breakpoints.down('lg'))

  const closeOfferDialog = (offer) => {
    setShowOfferModal(false)
    setRequests(
      requests.map((request) => {
        if (request.id === offer.requestId) {
          request.offerAmount = 1
        }
        return {
          ...request,
        }
      }),
    )
  }

  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)
  let defaultValueRequestId = params.get('id')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const requestId = params.get('createOfferShortcut')

    if (requestId) {
      const parsedRequestId = parseInt(requestId, 10)
      window.history.pushState({}, document.title, window.location.pathname)
      requestsService
        .getRequest(parsedRequestId)
        .then((data) => {
          setSelectedRequest(data)
          setPriceList([])
          setShowOfferModal(true)
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen der Daten:', error)
        })
    }
  }, [])

  useEffect(() => {
    if (authorize('administrator')) {
      partnerCommissionGroupsService
        .getPartnerCommissionGroups()
        .then((partnerCommissioNGroups) => {
          setPartnerCommissionGroups(partnerCommissioNGroups)
        })
    }
  }, [])

  useEffect(() => {
    if (requests.length === 0) {
      return
    }
    const search = location.search
    const params = new URLSearchParams(search)
    let targetRequest = requests.find(
      (request) => request.id === parseInt(params.get('id')),
    )

    let prices = params.get('prices')
    if (prices && targetRequest.offerState === 'NO' && !selectedRequest) {
      window.history.pushState({}, document.title, window.location.pathname)
      setPriceList(params.get('prices').split(','))
      setSelectedRequest(targetRequest)
      setShowOfferModal(true)
    }
  }, [requests])

  let navigate = useNavigate()

  return (
    <Fragment>
      <ViewDescription description={t('MARKETPLACE.VIEW_DESCRPTION')} />
      {
        <Fragment>
          <ResourceMap
            geoCoordinatePath={'location'}
            fetchMethod={(filterValues, pagination) => {
              return requestsService.getPublishedRequests(
                ['REQUEST', 'TENDER'],
                userObject.partner.id,
                filterValues,
                pagination,
              )
            }}
            iconFunction={(request) => {
              let iconUrl = undefined

              if (request.offerState !== 'NO') {
                iconUrl = '/icons/map/Pacurion_Iconset_Map_Grün_Erledigt.svg'
              } else {
                if (request.type === 'REQUEST') {
                  iconUrl = '/icons/map/Pacurion_Iconset_Map_Orange_Anfrage.svg'
                }

                if (request.type === 'TENDER') {
                  iconUrl =
                    '/icons/map/Pacurion_Iconset_Map_Orange_Ausschreibungen.svg'
                }
              }

              return {
                url: iconUrl,
                scaledSize: new window.google.maps.Size(90, 90),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(45, 45),
              }
            }}
            mouseOverFunction={(request) => (
              <Stack spacing={1}>
                <Typography variant="h6">
                  {request.type === 'REQUEST'
                    ? `${t('MARKETPLACE.REQUEST_ID')} ${request.id}`
                    : `${t('MARKETPLACE.TENDER_ID')} ${request.id}`}
                </Typography>
                <Chip
                  size={'medium'}
                  color={
                    request.offerState === 'SENT'
                      ? 'info'
                      : request.offerState === 'SUCCESSFUL'
                      ? 'success'
                      : 'default'
                  }
                  label={marketplaceRequestStateLookup[request.offerState]}
                />
                <Fragment>
                  <div>
                    {`${moment(request.publishedAt)
                      .local()
                      .format('DD.MM.YYYY')}`}
                  </div>
                  <div>
                    {authorize('administrator')
                      ? `${request.user.firstname} ${request.user.lastname} | ${
                          authorize('administrator')
                            ? `${request.partner.name} (${request.partner.id})`
                            : ''
                        }`
                      : ''}
                  </div>
                  {`${request.location.zipcode} | ${request.location.place} | ${
                    countryLookup[request.location.countryId]
                  }`}
                  <div>
                    {authorize('administrator')
                      ? `Angebote: ${request.offerAmountAll} | Aufträge: ${
                          request.orderAmountAll + request.tenderOrderAmountAll
                        }`
                      : null}
                  </div>
                </Fragment>
                <PositionsList positions={request.positions} />
              </Stack>
            )}
            filterId={'Marketplace_V3'}
            forceUpdateCount={forceUpdateCount}
            filters={[
              {
                key: 'id.startsWith',
                label: t('MARKETPLACE.REQUEST_ID'),
                variant: 'textfield',
                defaultValue: defaultValueRequestId,
              },
              {
                key: 'positions.productId.eq',
                variant: 'categorizedCheckboxList',
                values: categorizedProductLookup.map((category) => {
                  return {
                    label: category.name,
                    items: category.products.map((product) => product.id),
                  }
                }),
                lookup: productLookup,
                label: t('MARKETPLACE.PRODUCT'),
                shortcut: true,
              },
              {
                key: '~IGNORE~calendarWeek',
                label: t('MARKETPLACE.DELIVERY_CALENDAR_WEEK'),
                variant: 'calendarWeekPicker',
              },
              {
                key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(productStateLookup),
                lookup: productStateLookup,
                label: t('MARKETPLACE.PRODUCT_STATE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(deliveryTypeLookup),
                lookup: deliveryTypeLookup,
                label: t('MARKETPLACE.DELIVERY_TYPE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=1&positions.positionProperties.value',
                label: t('MARKETPLACE.PRODUCT_LENGTH'),
                variant: 'textfield',
              },
              {
                key: 'positions.positionProperties.productPropertyId=2&positions.positionProperties.value',
                label: t('MARKETPLACE.PRODUCT_WIDTH'),
                variant: 'textfield',
              },
              {
                key: 'positions.location.countryId.eq',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(countryLookup),
                lookup: countryLookup,
                label: t('MARKETPLACE.COUNTRY'),
              },
              {
                key: 'positions.location.zipcode.startsWith',
                label: t('MARKETPLACE.ZIPCODE'),
                variant: 'textfield',
              },
              {
                key: 'positions.location.place.startsWith',
                label: t('MARKETPLACE.PLACE'),
                variant: 'textfield',
              },
              authorize('administrator')
                ? {
                    key: 'partner.partnerCommissionGroups.id.eq',
                    label: t('PARTNER_OVERVIEW.ACCOUNT_MANAGER'),
                    variant: 'autocomplete',
                    options: partnerCommissionGroups.map(
                      (partnerCommissionGroup) => partnerCommissionGroup.id,
                    ),
                    lookup: Object.fromEntries(
                      partnerCommissionGroups.map((partnerCommissionGroup) => [
                        partnerCommissionGroup.id,
                        `${partnerCommissionGroup.name}`,
                      ]),
                    ),
                  }
                : null,
            ].filter((filter) => filter !== null)}
            sorting={[
              {
                key: 'id',
                default: true,
                label: t('MARKETPLACE.NEWEST_FIRST'),
              },
            ]}
            resourceHeaderRenderFunction={(request) =>
              `${
                request.type === 'REQUEST'
                  ? t('MARKETPLACE.REQUEST')
                  : t('MARKETPLACE.TENDER')
              }: ${request.id}`
            }
            listHeaderRenderFunction={(request) => (
              <Fragment>
                <div>
                  <Tooltip
                    color={'primary'}
                    title={requestTypeLookup[request.type]}
                    style={{ marginRight: 5 }}
                  >
                    {request.type === 'REQUEST' ? (
                      <RequestsIcon />
                    ) : (
                      <TendersIcon />
                    )}
                  </Tooltip>
                  {`${
                    request.type === 'REQUEST'
                      ? t('MARKETPLACE.REQUEST')
                      : t('MARKETPLACE.TENDER')
                  }
                  : ${request.id} | ${moment(request.publishedAt)
                    .local()
                    .format('DD.MM.YYYY')}`}
                </div>
                <div>
                  {authorize('administrator')
                    ? `${request.user.firstname} ${request.user.lastname} | ${
                        authorize('administrator')
                          ? `${request.partner.name} (${request.partner.id})`
                          : ''
                      }`
                    : ''}
                </div>

                {`${request.location.zipcode} | ${request.location.place} | ${
                  countryLookup[request.location.countryId]
                }`}
                <div>
                  {authorize('administrator')
                    ? `Angebote: ${request.offerAmountAll} | Aufträge: ${
                        request.orderAmountAll + request.tenderOrderAmountAll
                      }`
                    : null}
                </div>
              </Fragment>
            )}
            headerActionRenderFunction={(request) => (
              <Fragment>
                <Stack direction={isMobile ? 'row-reverse' : 'row'} spacing={1}>
                  <Chip
                    size="small"
                    color={
                      request.offerState === 'SENT' ? 'primary' : 'default'
                    }
                    label={marketplaceRequestStateLookup[request.offerState]}
                  />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '4px',
                    }}
                  >
                    {request.qualityCategory === 'HIGH' && (
                      <Tooltip title={t('MARKETPLACE.HIGH_QUALITY')}>
                        <HighQualityOutlinedIcon
                          style={{ marginRight: '5px', fontSize: '1.7rem' }}
                          color="secondary"
                        />
                      </Tooltip>
                    )}
                    {request.community && (
                      <Tooltip title={request.partner.name}>
                        <GroupsIcon
                          color="secondary"
                          style={{ marginRight: '4px', fontSize: '1.7rem' }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </Stack>
              </Fragment>
            )}
            contentRenderFunction={(request) => (
              <PositionsList
                positions={
                  request.type === 'TENDER'
                    ? Object.values(groupBy(request.positions, 'id')).map(
                        (pos) => ({
                          ...pos[0],
                          amount: sumBy(pos, 'amount'),
                        }),
                      )
                    : request.positions
                }
              />
            )}
            resourceRenderFunction={(request) => {
              return <RequestDetails requestId={request.id} />
            }}
            actionsRenderFunction={(request) => {
              return [
                {
                  icon: <Receipt />,
                  hidden:
                    request.type !== 'TENDER' ||
                    request.offerState !== 'SUCCESSFUL',
                  name:
                    request.offerState !== 'SUCCESSFUL'
                      ? t('MARKETPLACE.NO_CONTRACTS_YET')
                      : t('REQUESTS.GOTO_CONTRACTS'),
                  primary: false,
                  onClick: () =>
                    navigate({
                      pathname: '/contracts',
                      search: 'requestId=' + request.id,
                    }),
                },
                {
                  icon: <GotoOrdersIcon />,
                  name:
                    request.offerState !== 'SUCCESSFUL'
                      ? t('MARKETPLACE.NO_ORDERS_YET')
                      : t('MARKETPLACE.GOTO_ORDERS'),
                  hidden: request.offerState !== 'SUCCESSFUL',
                  primary: false,
                  onClick: () =>
                    navigate({
                      pathname: '/deliveries-manufacturer',
                      search: '?requestId=' + request.id,
                    }),
                },
                {
                  icon: <OfferIcon />,
                  name:
                    request.offerAmount === 0
                      ? t('MARKETPLACE.NO_OFFER_SEND_YET')
                      : t('MARKETPLACE.GOTO_OFFER'),
                  hidden: request.offerAmount === 0,
                  primary: false,
                  onClick: () =>
                    navigate({
                      pathname:
                        request.type === 'REQUEST'
                          ? '/offers-requests'
                          : '/offers-tenders',
                      search: `requestId=${request.id}&offerType=SENT`,
                    }),
                },
                {
                  icon: <CreateOfferIcon />,
                  name:
                    request.offerState !== 'NO'
                      ? t('MARKETPLACE.OFFER_ALREADY_SEND')
                      : t('MARKETPLACE.CREATE_OFFER'),
                  hidden: request.offerState !== 'NO',
                  primary: true,
                  onClick: () =>
                    requestsService.getRequest(request.id).then((data) => {
                      setSelectedRequest(data)
                      setPriceList([])
                      setShowOfferModal(true)
                    }),
                },
              ]
            }}
            data={requests}
            dataIdentifier={'id'}
          />

          <Dialog
            fullWidth={true}
            maxWidth="xl"
            fullScreen={isLessThanLg}
            open={showOfferModal}
          >
            <CustomDialogTitle
              title={t('MARKETPLACE.CREATE_OFFER')}
              onClose={() => setShowOfferModal(false)}
            />
            <DialogContent>
              <Offer
                priceList={priceList}
                closeOfferDialog={(offer) => closeOfferDialog(offer)}
                requestId={selectedRequest ? selectedRequest.id : null}
                key={selectedRequest ? selectedRequest.id : null}
                forceUpdate={() => {
                  forceUpdate(forceUpdateCount + 1)
                }}
              />
            </DialogContent>
          </Dialog>
        </Fragment>
      }
    </Fragment>
  )
}

export default withTranslation()(Marketplace)
