import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions/alertActions'
import { offersService } from '../../_services/offersService'
import AgreementDialog from '../AgreementDialog'

const AcceptCounterOfferDialog = (props) => {
  const { t, selectedOffer, hide, dispatch, forceUpdate } = props

  const userObject = JSON.parse(localStorage.getItem('user'))

  const [recentOffer, setRecentOffer] = useState(null)

  useEffect(() => {
    offersService
      .getOffer(selectedOffer.id, userObject.partner.id)
      .then((offer) => {
        setRecentOffer(offer)
      })
  }, [selectedOffer])
  return (
    <AgreementDialog
      title={t('OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_0')}
      acceptButtonText={t('OFFERS.YES')}
      declineButtonText={t('OFFERS.NO')}
      message={
        parseInt(userObject.partner.id) ===
        parseInt(selectedOffer.requestPartnerId) ? (
          <Fragment>
            {t('OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_1')}
            <br />
            <br />
            {t('OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_2')}
          </Fragment>
        ) : (
          <Fragment>
            {t('OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_INSTANT_1')}
            <br />
            <br />
            {t('OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_INSTANT_2')}
          </Fragment>
        )
      }
      open={true}
      handleClose={() => {
        hide()
      }}
      agree={() => {
        offersService
          .counterOffer(recentOffer, recentOffer.id)
          .then((res) => {
            hide()
            if (forceUpdate) {
              forceUpdate()
            }

            dispatch(alertActions.info(t('OFFER.COUNTEROFFER_CREATED')))
            setTimeout(() => {
              dispatch(alertActions.clear())
            }, alertActions.alertTimeout)
          })
          .catch(function (error) {
            console.log(error)
          })
          .finally(() => {
            if (forceUpdate) {
              forceUpdate()
            }
            hide()
          })
      }}
    />
  )
}

export default withTranslation()(connect()(AcceptCounterOfferDialog))
