import moment from 'moment'
import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'
import { generateFilterValuesQueryString } from '../_helpers/little.js'
import { offerAttachmentsService } from './offerAttachmentsService.js'

export const offersService = {
  acceptOffer,
  counterOffer,
  createOffer,
  getOffersOfPartner,
  getOffer,
  deleteOffer,
  declineOffer,
  getOffers,
  getOffersOfOffer,
  updateOffer,
  createCounterOffersOfRequest,
}

function getOffers(filterValues = undefined, pagination) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  let queryString = generateFilterValuesQueryString(filterValues, pagination)

  return fetch(
    `${config.apiUrlBase}/offers${queryString}`,
    requestOptions,
  ).then(handleResponse)
}

function getOffersOfOffer(offerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/offers/${offerId}/offers`,
    requestOptions,
  ).then(handleResponse)
}

function getOffersOfPartner(
  partnerId,
  requestType,
  offerType,
  filterValues = undefined,
  pagination,
  userId,
) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  let queryString = generateFilterValuesQueryString(filterValues, pagination)

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/offers${queryString}&requestType=${requestType}&offerType=${offerType}&userId=${userId}`,
    requestOptions,
  ).then(handleResponse)
}

function getOffer(offerId, partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/offers/${offerId}?partnerId=${partnerId}`,
    requestOptions,
  ).then(handleResponse)
}

function acceptOffer(offerId, requestBody) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }

  return fetch(
    `${config.apiUrlBase}/offers/${offerId}/accept-offer?partnerInvoiceLocationIds=${requestBody.partnerInvoiceLocationId}`,
    requestOptions,
  ).then(handleResponse)
}

function deleteOffer(offerId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(`${config.apiUrlBase}/offers/` + offerId, requestOptions).then(
    handleResponse,
  )
}

function declineOffer(offerId, partnerId) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      partnerId: partnerId,
    }),
  }

  return fetch(
    `${config.apiUrlBase}/offers/${offerId}/decline-offer`,
    requestOptions,
  ).then(handleResponse)
}

function createOffer(offer) {
  return new Promise(function (resolve, reject) {
    var payload = mapOffer(offer)
    var requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }
    fetch(`${config.apiUrlBase}/offers`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        var createdOfferId = data.offerId

        let fileUploadPromiseList = []
        offer.attachments.forEach(function (attachment) {
          if (!attachment.isNew) {
            return
          }

          var requestBody = new FormData()

          requestBody.append('file', attachment)

          var attachmentPromiseList = new Promise(function (resolve, reject) {
            offerAttachmentsService
              .createAttachmentOfOffer(createdOfferId, requestBody)
              .then(function (response) {
                if (response.ok) {
                  handleResponse(response).then((data) => {
                    resolve(data)
                  })
                } else {
                  reject()
                }
              })
          })

          fileUploadPromiseList.push(attachmentPromiseList)
        })

        Promise.all(fileUploadPromiseList).then(function (responses) {
          data.attachments = responses.map((response) => response.attachment)
          resolve(data)
        })
      })
  })
}
function createCounterOffersOfRequest(requestId, requestBody) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }

  return fetch(
    `${config.apiUrlBase}/requests/${requestId}/counter-offer`,
    requestOptions,
  ).then(handleResponse)
}
function counterOffer(offer, offerId) {
  return new Promise(function (resolve, reject) {
    var payload = mapOffer(offer)
    let requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }

    fetch(
      `${config.apiUrlBase}/offers/` + offerId + '/counter-offer',
      requestOptions,
    )
      .then(handleResponse)
      .then((data) => {
        var createdOfferId = data.offerId

        let fileUploadPromiseList = []

        offer.attachments.forEach(function (attachment) {
          if (!attachment.isNew) {
            return
          }

          var requestBody = new FormData()

          requestBody.append('file', attachment)

          requestOptions = {
            method: 'POST',
            headers: { ...authHeader() },
            body: requestBody,
          }

          var attachmentPromiseList = new Promise(function (resolve, reject) {
            fetch(
              `${config.apiUrlBase}/offers/` +
                createdOfferId +
                '/offer-attachments',
              requestOptions,
            ).then(function (response) {
              if (response.ok) {
                handleResponse(response).then((data) => {
                  resolve(data)
                })
              } else {
                reject()
              }
            })
          })

          fileUploadPromiseList.push(attachmentPromiseList)
        })
        //TODO: Wird das gebraucht, gebe jetzt nur noch die offer id zurück danach wird ohnehin neu abgerufen. kann vermutlich raus
        Promise.all(fileUploadPromiseList).then(function (responses) {
          data.attachments = responses.map((response) => response.attachment)
          resolve(data)
        })
      })
  })
}

function updateOffer(offer) {
  return new Promise(function (resolve, reject) {
    var payload = mapOffer(offer)
    let requestOptions = {
      method: 'PUT',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }

    fetch(`${config.apiUrlBase}/offers/${offer.id}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        let fileUploadPromiseList = []
        offer.attachments.forEach(function (attachment) {
          if (attachment.isDeleted) {
            let attachmentPromiseList = new Promise(function (resolve, reject) {
              requestOptions = {
                method: 'DELETE',
                headers: { ...authHeader() },
              }

              offerAttachmentsService
                .deleteOfferAttachment(attachment.id)
                .then(function (response) {
                  if (response.ok) {
                    handleResponse(response).then((data) => {
                      resolve(data)
                    })
                  } else {
                    reject()
                  }
                })
            })
            fileUploadPromiseList.push(attachmentPromiseList)
          } else if (attachment.isNew) {
            var requestBody = new FormData()

            requestBody.append('file', attachment)

            var attachmentPromiseList = new Promise(function (resolve, reject) {
              offerAttachmentsService
                .createAttachmentOfOffer(offer.id, requestBody)
                .then(function (response) {
                  if (response.ok) {
                    handleResponse(response).then((data) => {
                      resolve(data)
                    })
                  } else {
                    reject()
                  }
                })
            })

            fileUploadPromiseList.push(attachmentPromiseList)
          }
        })
        //TODO: Wird das gebraucht, gebe jetzt nur noch die offer id zurück danach wird ohnehin neu abgerufen. kann vermutlich raus
        Promise.all(fileUploadPromiseList).then(function (responses) {
          data.attachments = responses.map((response) => response.attachment)
          resolve(data)
        })
      })
  })
}

function mapOffer(offer) {
  const userObject = JSON.parse(localStorage.getItem('user'))

  if (
    offer.generalInformation.paymentDetails.skontoPeriod >
    offer.generalInformation.paymentDetails.paymentTerm
  ) {
    offer.generalInformation.paymentDetails.skontoPeriod =
      offer.generalInformation.paymentDetails.paymentTerm
  }

  return {
    incoTerm: offer.generalInformation.deliveryDetails.incoTerm
      ? offer.generalInformation.deliveryDetails.incoTerm
      : 'DDP',
    requestId: offer.request.id,
    partnerId: userObject.partner.id,
    userId: userObject.user.id,
    validUntil: moment(offer.generalInformation.otherDetails.validUntil).format(
      'YYYY-MM-DD',
    ),
    internalNote: offer.generalInformation.otherDetails.internalNote,
    paymentTerms: offer.generalInformation.paymentDetails.paymentTerm,
    skonto: offer.generalInformation.paymentDetails.skonto,
    skontoPeriod: offer.generalInformation.paymentDetails.skontoPeriod,
    location:
      offer.request.type === 'REQUEST' || offer.request.type === 'ADVERT'
        ? {
            company:
              offer.generalInformation.deliveryDetails.locations[0].company,
            countryId:
              offer.generalInformation.deliveryDetails.locations[0].countryId,
            address:
              offer.generalInformation.deliveryDetails.locations[0].address,
            zipcode:
              offer.generalInformation.deliveryDetails.locations[0].zipcode,
            place: offer.generalInformation.deliveryDetails.locations[0].place,
            deliveryInformations:
              offer.generalInformation.deliveryDetails.locations[0]
                .deliveryInformations,
            unload:
              offer.generalInformation.deliveryDetails.locations[0].unload,
            deliveryTimes:
              offer.generalInformation.deliveryDetails.locations[0]
                .deliveryTimes,
            stackHeight: Number(
              offer.generalInformation.deliveryDetails.locations[0].stackHeight,
            )
              ? offer.generalInformation.deliveryDetails.locations[0]
                  .stackHeight
              : null,
            stackHeightUnit:
              offer.generalInformation.deliveryDetails.locations[0]
                .stackHeightUnit,
            isDespatchAdviceRequired:
              offer.generalInformation.deliveryDetails.locations[0]
                .isDespatchAdviceRequired,
            despatchAdviceInstructions:
              offer.generalInformation.deliveryDetails.locations[0]
                .despatchAdviceInstructions,
            partnerLocationId:
              offer.generalInformation.deliveryDetails.locations[0]
                .partnerLocationId,
          }
        : undefined,
    positions: offer.positions
      .filter((opos) => {
        return opos.checked
      })
      .map((opos) => {
        return {
          id: opos.id,
          locationId: opos.locationId,
          amount: parseFloat(opos.amount),
          productUnitId: opos.productUnitId,
          price: opos.price,
          purchasePrice: opos.purchasePrice,
          salesPrice: opos.salesPrice,
          positionProperties: opos.positionProperties.map(
            (positionProperty) => {
              if (
                positionProperty.productProperty.type === 'TEXT' &&
                positionProperty.value === ''
              ) {
                positionProperty.value = null
              }
              return {
                value: positionProperty.value,
                productPropertyId: positionProperty.productProperty.id,
              }
            },
          ),
          index: opos.index,
        }
      }),
    deliveries: !offer.deliveries
      ? null
      : offer.deliveries.map((delivery) => {
          return {
            type: delivery.type,
            value: moment(delivery.value).format('YYYY-MM-DD'),
            deliveryPositions: delivery.positions
              .filter((dPos) => dPos.amount > 0)
              .map((dpos) => ({
                amount: dpos.amount,
                offerPositionId: dpos.positionId,
              })),
          }
        }),
    attachments: offer.attachments,
  }
}

export * from './offersService'
