import MaterialTable from '@material-table/core'
import { Add } from '@mui/icons-material'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import { Button, Dialog, DialogContent } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { tableIcons, tableLocalization } from '../../_helpers/muiTable'
import { _applicationService } from '../../_services/_applicationService'
import { claimsService } from '../../_services/claimsService'
import AgreementDialog from '../AgreementDialog'
import CustomDialogTitle from '../CustomDialogTitle'
import { Loading } from '../Loading'
import CreateClaimOfDeliveryNoteDialog from './_components/CreateClaimOfDeliveryNoteDialog'

const ClaimsOfDeliveryNoteDialog = (props) => {
  const { t, order, deliveryNote, show, hide, forceUpdate } = props

  const [isLoading, setIsLoading] = useState(true)
  const [claims, setClaims] = useState([])
  const [selectedDeliveryNote, setSelectedDeliveryNote] = useState(null)
  const [createClaimDialogOpen, setCreateClaimDialogOpen] = useState(false)
  const [showResetDialog, setShowResetDialog] = useState(false)
  const [claim, setClaim] = useState(null)

  useEffect(() => {
    if (deliveryNote) {
      setSelectedDeliveryNote(deliveryNote)
      setIsLoading(true)
      claimsService.getClaimsOfDeliveryNote(deliveryNote.id).then((data) => {
        setClaims(data)
        setIsLoading(false)
      })
    } else {
      claimsService.getClaimsOfOrder(order.id).then((data) => {
        setClaims(data)
        setIsLoading(false)
      })
    }
  }, [deliveryNote, order, selectedDeliveryNote])

  const tableActions = [
    (rowData) => ({
      icon: () => (
        <ZoomInIcon color={rowData.delivery ? 'disabled' : 'secondary'} />
      ),
      tooltip: t('CLAIM.DETAILS'),
      onClick: (event, rowData) => {
        var selectedClaim = rowData
        setSelectedDeliveryNote(selectedClaim.deliveryNote)
        setCreateClaimDialogOpen(true)
        setClaim(selectedClaim)
      },
    }),
  ]
  const openCreateClaimDialog = () => {
    setClaim(null)
    setCreateClaimDialogOpen(true)
  }
  const closeCreateClaimDialog = () => {
    setCreateClaimDialogOpen(false)
    setClaim(null)
    forceUpdate()
  }
  return (
    <Dialog fullWidth={true} maxWidth="xl" open={show}>
      <DialogContent>
        <CustomDialogTitle title={t('CLAIM.CLAIMS')} onClose={() => hide()} />
        {isLoading ? (
          <Loading variant={'centered'} />
        ) : (
          <Fragment>
            <div style={{ marginBottom: 10 }}>
              <Button
                size="medium"
                color={'secondary'}
                variant={'contained'}
                startIcon={<Add />}
                onClick={() => {
                  openCreateClaimDialog()
                }}
              >
                {t('CLAIM.GENERATE')}
              </Button>
            </div>

            <MaterialTable
              icons={tableIcons}
              localization={tableLocalization()}
              columns={[
                { title: '#', field: 'id', defaultSort: 'desc' },
                { title: t('CLAIM.CLAIM_TYPE'), field: 'type' },
                { title: t('CLAIM.STATE'), field: 'state' },
              ]}
              data={claims}
              actions={tableActions}
              options={{
                toolbarButtonAlignment: 'left',
                toolbar: false,
                showTitle: false,
                search: false,
                paging: true,
                pageSize: 10,
                padding: 'dense',
                actionsColumnIndex: -1,
                filterCellStyle: {
                  maxWidth: 0,
                },
                headerStyle: {
                  fontWeight: 'bold',
                },
              }}
              style={{ boxShadow: 'none' }}
            />
          </Fragment>
        )}
      </DialogContent>
      {createClaimDialogOpen && (
        <CreateClaimOfDeliveryNoteDialog
          deliveryNote={selectedDeliveryNote}
          order={order}
          claim={claim}
          show={createClaimDialogOpen}
          hide={closeCreateClaimDialog}
          forceUpdate={forceUpdate}
        />
      )}
      <AgreementDialog
        open={showResetDialog}
        message={t('ADMIN.ARE_YOU_SURE')}
        acceptButtonText={t('GENERAL.YES')}
        handleClose={() => {
          setShowResetDialog(false)
        }}
        agree={() => {
          setIsLoading(true)
          setShowResetDialog(false)
          _applicationService
            .reset()
            .then((data) => {
              alert(data.message)
            })
            .finally(() => setIsLoading(false))
        }}
      />
    </Dialog>
  )
}

export default withTranslation()(ClaimsOfDeliveryNoteDialog)
