import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header.js'
import { generateFilterValuesQueryString } from '../_helpers/little.js'

export const orderPositionsService = {
  getOrderPositionsOfAutomaticOfferPosition,
}

function getOrderPositionsOfAutomaticOfferPosition(
  automaticOfferPositionId,
  filterValues,
  offset,
  limit,
) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  let queryString = generateFilterValuesQueryString(filterValues)
  return fetch(
    `${config.apiUrlBase}/automatic-offer-positions/${automaticOfferPositionId}/order-positions${queryString}&offset=${offset}&limit=${limit}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './orderPositionsService'
