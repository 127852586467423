import { useTheme } from '@emotion/react'
import { Build, Delete, Info, WarningRounded } from '@mui/icons-material'
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import numeral from 'numeral'
import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { translateProduct, translateProductUnit } from '../../_helpers/little'
import { productsService } from '../../_services/productsService'
import AgreementDialog from '../AgreementDialog'
import DifferenceWrapper from '../DifferenceWrapper'
import { InfoTooltip } from '../InfoTooltip'
import { Loading } from '../Loading'
import PositionDetailSummary from '../PositionDetailSummary'
import ProductConfigurator from '../ProductConfigurator'

const PositionCard = (props) => {
  const {
    t,
    data,
    differences,
    diffObjectType,
    showDifference,
    readOnly,
    showProductDetails,
    updatePositionFunction,
    deletePositionFunction,
    parentType,
    showAmount,
    amount,
    children,
    showImage,
    imageSize,
  } = props

  const [showProductConfigurator, setShowProductConfigurator] = useState(false)

  const [showDeletePositionDialog, setShowDeletePositionDialog] =
    useState(false)

  const [product, setProduct] = useState(null)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(
    () => {
      if (data.productId) {
        setIsLoading(true)
        productsService.getProduct(data.productId).then((product) => {
          setProduct(product)
          setIsLoading(false)
        })
      }
    }, // props.location does not exists in the adminpanel
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.productId],
  )

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Fragment>
        <Card
          elevation={2}
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <CardHeader
            action={
              <Fragment>
                <Tooltip
                  title={readOnly ? t('POSITION.DETAILS') : t('POSITION.EDIT')}
                >
                  {showProductDetails && (
                    <IconButton
                      color="default"
                      style={{ margin: '0px', padding: '8px' }}
                      onClick={() => {
                        setShowProductConfigurator(true)
                      }}
                      size="large"
                    >
                      {readOnly ? <Info /> : <Build />}
                    </IconButton>
                  )}
                </Tooltip>
                {!readOnly && deletePositionFunction ? (
                  <Tooltip title={t('POSITION.DELETE_POSITION')}>
                    <IconButton
                      color="default"
                      style={{ margin: '0px', padding: '8px' }}
                      onClick={() => {
                        setShowDeletePositionDialog(true)
                      }}
                      size="large"
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {showDifference ? (
                  differences &&
                  differences.positionProperties.reduce(
                    (accumulator, positionProperty) => {
                      if (positionProperty.productProperty.type === 'ARRAY') {
                        positionProperty.value.forEach(
                          (subPositionProperty) => {
                            if (subPositionProperty.value !== false) {
                              accumulator++
                            }
                          },
                        )
                        return accumulator
                      } else if (
                        positionProperty.productProperty.type === '2D_ARRAY'
                      ) {
                        if (Array.isArray(positionProperty.value)) {
                          positionProperty.value.forEach((outerArray) => {
                            outerArray.forEach((subPositionProperty) => {
                              if (subPositionProperty.value !== false) {
                                accumulator++
                              }
                            })
                          })
                          return accumulator
                        } else {
                          return positionProperty.value !== false
                            ? accumulator + 1
                            : accumulator
                        }
                      } else {
                        return positionProperty.value !== false
                          ? accumulator + 1
                          : accumulator
                      }
                    },
                    0,
                  ) > 0 ? (
                    <InfoTooltip
                      title={
                        (diffObjectType === 'REQUEST'
                          ? t('OFFER.DIFFERENCE_ORIGIN')
                          : t('OFFER.DIFFERENCE_COMPARE_OFFER')) +
                        ' ' +
                        differences.positionProperties.reduce(
                          (accumulator, positionProperty) => {
                            if (
                              positionProperty.productProperty.type === 'ARRAY'
                            ) {
                              positionProperty.value.forEach(
                                (subPositionProperty) => {
                                  if (subPositionProperty.value !== false) {
                                    accumulator++
                                  }
                                },
                              )
                              return accumulator
                            } else if (
                              positionProperty.productProperty.type ===
                              '2D_ARRAY'
                            ) {
                              if (Array.isArray(positionProperty.value)) {
                                positionProperty.value.forEach((outerArray) => {
                                  outerArray.forEach((subPositionProperty) => {
                                    if (subPositionProperty.value !== false) {
                                      accumulator++
                                    }
                                  })
                                })
                                return accumulator
                              } else {
                                return positionProperty.value !== false
                                  ? accumulator + 1
                                  : accumulator
                              }
                            } else {
                              return positionProperty.value !== false
                                ? accumulator + 1
                                : accumulator
                            }
                          },
                          0,
                        ) +
                        ' ' +
                        t('OFFER.DIFFERENCE_DETAILS')
                      }
                    >
                      <WarningRounded style={{ color: '#ffe57f' }} />
                    </InfoTooltip>
                  ) : null
                ) : null}
              </Fragment>
            }
            title={
              <Grid container justifyContent={'center'} aligntItems={'center'}>
                {imageSize === 'small' && !isMobile && (
                  <Grid
                    item
                    xs={3}
                    sx={{
                      backgroundImage: `url(${product.imageRef})`,
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                    }}
                  />
                )}
                <Grid item xs={imageSize === 'small' && !isMobile ? 9 : 12}>
                  <CardHeader
                    title={
                      <Typography
                        variant={'h6'}
                        sx={{ wordBreak: 'break-word' }}
                      >
                        {translateProduct(product)}
                      </Typography>
                    }
                    subheader={
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        component="div"
                      >
                        {data.positionProperties.some(
                          (positionProperty) =>
                            positionProperty.productProperty.id === 1,
                        )
                          ? `${
                              data.positionProperties.find(
                                (positionProperty) =>
                                  positionProperty.productProperty.id === 1,
                              ).value
                            } ${
                              data.positionProperties.find(
                                (positionProperty) =>
                                  positionProperty.productProperty.id === 1,
                              ).productProperty.unit
                            }`
                          : null}
                        {data.positionProperties.some(
                          (positionProperty) =>
                            positionProperty.productProperty.id === 2,
                        )
                          ? ` x ${
                              data.positionProperties.find(
                                (positionProperty) =>
                                  positionProperty.productProperty.id === 2,
                              ).value
                            } ${
                              data.positionProperties.find(
                                (positionProperty) =>
                                  positionProperty.productProperty.id === 2,
                              ).productProperty.unit
                            }`
                          : null}

                        {data.positionProperties.some(
                          (positionProperty) =>
                            positionProperty.productProperty.id === 28,
                        )
                          ? ` x ${
                              data.positionProperties.find(
                                (positionProperty) =>
                                  positionProperty.productProperty.id === 28,
                              ).value
                            } ${
                              data.positionProperties.find(
                                (positionProperty) =>
                                  positionProperty.productProperty.id === 28,
                              ).productProperty.unit
                            }`
                          : null}
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            }
          ></CardHeader>
          {showImage && imageSize === 'default' && (
            <CardActionArea onClick={() => setShowProductConfigurator(true)}>
              <CardMedia
                onClick={() => setShowProductConfigurator(true)}
                component="img"
                style={{
                  maxHeight: '30vh',
                }}
                image={product.imageRef}
                alt={translateProduct(product)}
              />
            </CardActionArea>
          )}

          {showProductDetails && product && (
            <CardContent
              sx={{
                height: '100%',
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <PositionDetailSummary
                    data={data}
                    differences={differences}
                    showDifference={showDifference}
                    diffObjectType={diffObjectType}
                  />
                </Grid>
              </Grid>
            </CardContent>
          )}
          <CardContent>
            <Fragment>
              <DifferenceWrapper
                showDifference={
                  showAmount &&
                  differences &&
                  (differences.amount || differences.productUnitId)
                }
                difference={
                  differences
                    ? differences.amount || differences.productUnitId
                    : false
                }
                differenceDisplay={
                  differences
                    ? `${numeral(
                        differences.amount ? differences.amount : data.amount,
                      ).format('0,0.[00]')} ${translateProductUnit(
                        product.productUnits.find(
                          (productUnit) =>
                            productUnit.id ===
                            (differences.productUnitId
                              ? differences.productUnitId
                              : data.productUnitId),
                        ),
                      )}`
                    : null
                }
                diffObjectType={diffObjectType}
              >
                {showAmount ? (
                  amount != null ? (
                    <Typography>{`${numeral(parseFloat(amount)).format(
                      '0,0.[00]',
                    )} ${translateProductUnit(
                      product.productUnits.find((productUnit) => {
                        return productUnit.id === data.productUnitId
                      }),
                    )}`}</Typography>
                  ) : (
                    <Typography>
                      {`${numeral(parseFloat(data.amount)).format(
                        '0,0.[00]',
                      )} ${translateProductUnit(
                        product.productUnits.find(
                          (productUnit) =>
                            productUnit.id === data.productUnitId,
                        ),
                      )}`}
                    </Typography>
                  )
                ) : null}
              </DifferenceWrapper>
              {children}
            </Fragment>
          </CardContent>
        </Card>

        <ProductConfigurator
          key={data ? 'ProductConfigurator' + data.id : 'ProductConfigurator'}
          readOnly={readOnly}
          show={showProductConfigurator}
          data={data}
          close={(e) => {
            setShowProductConfigurator(false)
          }}
          updatePositionFunction={(product, positionProperties) => {
            setShowProductConfigurator(false)
            updatePositionFunction(product, positionProperties)
          }}
          parentType={parentType}
          showDifference={showDifference}
          diffObjectType={diffObjectType}
          differences={differences}
        />
        <AgreementDialog
          open={showDeletePositionDialog}
          handleClose={() => {
            setShowDeletePositionDialog(false)
          }}
          agree={() => {
            deletePositionFunction()
            setShowDeletePositionDialog(false)
          }}
        />
      </Fragment>
    )
  )
}
export default withTranslation()(PositionCard)

PositionCard.propTypes = {
  imageSize: PropTypes.oneOf(['default', 'small']),
}

PositionCard.defaultProps = {
  imageSize: 'default',
}
