import { Alert, InputAdornment, MenuItem } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { generalService } from '../../_services/generalService'
import { partnersService } from '../../_services/partnersService'
import { Section } from '../Section'

const PaymentDetails = (props) => {
  const { t, readOnly, data, setFieldValue } = props

  const [paymentTerms, setPaymentTerms] = useState([])
  const [skontos, setSkontos] = useState([])

  let userObject = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    setPaymentTerms(
      generalService.getPaymentTerms(userObject.partner.allPaymentTermsAllowed),
    )
    setSkontos(generalService.getSkontos())
  }, [userObject.partner.allPaymentTermsAllowed])

  useEffect(() => {
    if (data.id) {
      return
    }
    partnersService.getPartner(userObject.partner.id).then((partner) => {
      setFieldValue(
        'generalInformation.paymentDetails.paymentTerm',
        partner.defaultPaymentTerms,
      )
      if (['REQUEST', 'TENDER', 'ADVERT'].includes(data.type)) {
        setFieldValue(
          'generalInformation.paymentDetails.skonto',
          partner.defaultSkonto,
        )
        setFieldValue(
          'generalInformation.paymentDetails.skontoPeriod',
          partner.defaultSkontoPeriod,
        )
      }
    })
  }, [userObject.partner.id])

  return (
    <Section header={t('REQUEST.PAYMENT_DETAILS.HEADING')}>
      <Field
        component={TextField}
        name="generalInformation.paymentDetails.paymentTerm"
        select
        fullWidth
        disabled={
          readOnly ||
          !JSON.parse(localStorage.getItem('user')).partner
            .allPaymentTermsAllowed
        }
        label={t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM')}
        variant="outlined"
        margin="dense"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM_UNIT')}
            </InputAdornment>
          ),
          onChange: (e) => {
            if (parseInt(e.target.value) === 0) {
              setFieldValue(
                'generalInformation.paymentDetails.skontoPeriod',
                null,
              )
              setFieldValue('generalInformation.paymentDetails.skonto', 0)
            }
            setFieldValue(
              'generalInformation.paymentDetails.paymentTerm',
              e.target.value,
            )
          },
        }}
      >
        {paymentTerms.map((paymentTerm) => (
          <MenuItem value={paymentTerm} key={paymentTerm}>
            {paymentTerm !== 0 ? paymentTerm : t('GENERAL.PREPAYMENT')}
          </MenuItem>
        ))}
      </Field>

      {data.type !== 'ADVERT' &&
        JSON.parse(localStorage.getItem('user')).partner
          .allPaymentTermsAllowed && (
          <Fragment>
            <Field
              component={TextField}
              name="generalInformation.paymentDetails.skonto"
              select
              fullWidth
              disabled={
                readOnly ||
                parseInt(data.generalInformation.paymentDetails.paymentTerm) ===
                  0
              }
              label={t('REQUEST.PAYMENT_DETAILS.SKONTO')}
              variant="outlined"
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ paddingRight: '20px' }}
                  >
                    {t('REQUEST.PAYMENT_DETAILS.SKONTO_UNIT')}
                  </InputAdornment>
                ),
                onChange: (e) => {
                  if (parseInt(e.target.value) === 0) {
                    setFieldValue(
                      'generalInformation.paymentDetails.skontoPeriod',
                      null,
                    )
                  }
                  setFieldValue(
                    'generalInformation.paymentDetails.skonto',
                    e.target.value,
                  )
                },
              }}
            >
              {skontos.map((skonto) => (
                <MenuItem value={skonto} key={skonto}>
                  {skonto}
                </MenuItem>
              ))}
            </Field>
            <Field
              component={TextField}
              name="generalInformation.paymentDetails.skontoPeriod"
              select
              fullWidth
              disabled={
                readOnly ||
                parseInt(data.generalInformation.paymentDetails.skonto) === 0
              }
              label={t('REQUEST.PAYMENT_DETAILS.SKONTO_PERIOD')}
              variant="outlined"
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM_UNIT')}
                  </InputAdornment>
                ),
              }}
            >
              {paymentTerms
                .filter(
                  (paymentTerm) =>
                    paymentTerm <=
                    data.generalInformation.paymentDetails.paymentTerm,
                )
                .map((paymentTerm) => (
                  <MenuItem value={paymentTerm} key={paymentTerm}>
                    {paymentTerm}
                  </MenuItem>
                ))}
            </Field>
          </Fragment>
        )}

      {parseInt(data.generalInformation.paymentDetails.skonto) !== 0 && (
        <Alert sx={{ marginTop: 1 }} severity="info">
          {t('REQUEST.PAYMENT_DETAILS.SKONTO_WARNING')}
        </Alert>
      )}
      {data.type !== 'ADVERT' &&
        parseInt(data.generalInformation.paymentDetails.paymentTerm) > 30 && (
          <Alert sx={{ marginTop: 1 }} severity="info">
            {t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM_WARNING')}
          </Alert>
        )}
    </Section>
  )
}

export default withTranslation()(PaymentDetails)
