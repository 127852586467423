import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header.js'

export const rolesService = {
  getRoles,
}

function getRoles() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/roles`, requestOptions).then(
    handleResponse,
  )
}

export * from './rolesService.js'
