import { Add } from '@mui/icons-material'
import { Button, Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import { TextField } from 'formik-mui'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions/alertActions'
import NumberFormatCustom from '../../_helpers/numberFormatCustom'
import PositionCard from '../PositionCard'
import ProductConfigurator from '../ProductConfigurator'
import { Section } from '../Section'

const OrderRegistrationPositions = (props) => {
  const { dispatch, t, data, errors, setFieldValue } = props

  const [showProductConfigurator, setShowProductConfigurator] = useState(false)

  return (
    <Section header={t('REQUEST.REQUEST_POSITIONS')}>
      <Button
        startIcon={<Add />}
        variant="contained"
        color="secondary"
        size={'small'}
        style={{ marginBottom: 10, marginTop: 10 }}
        onClick={() => setShowProductConfigurator(true)}
      >
        {t('REQUEST.ADD_POSITION')}
      </Button>
      {errors.generalInformation &&
      typeof errors.generalInformation.deliveryDetails.locations[0]
        .positions === 'string' ? (
        <ErrorMessage
          name={'generalInformation.deliveryDetails.locations[0].positions'}
          render={(msg) => <div className="help-block">{msg}</div>}
        />
      ) : null}
      <ProductConfigurator
        readOnly={false}
        show={showProductConfigurator}
        data={data.details}
        close={(e) => {
          setShowProductConfigurator(false)
        }}
        createPositionFunction={(product, positionProperties) => {
          setShowProductConfigurator(false)
          var newRowData = {
            productId: product.id,
            amount: 0,
            productUnitId: product.productUnits[0].id,
            product: product,
            positionProperties: positionProperties,
          }

          const res = [
            ...data.generalInformation.deliveryDetails.locations[0].positions,
          ]
          const length =
            data.generalInformation.deliveryDetails.locations[0].positions
              .length
          const nextId = length + 1
          let newData = {
            ...newRowData,
            id: nextId,
          }

          res.push(newData)
          setFieldValue(
            'generalInformation.deliveryDetails.locations[0].positions',
            res,
          )
          let newDeliveries = data.deliveries

          newDeliveries = newDeliveries.map((delivery) => {
            return {
              ...delivery,
              positions: delivery.positions.concat([
                {
                  amount: 0,
                  positionId: newData.id,
                },
              ]),
            }
          })

          setFieldValue('deliveries', newDeliveries)
        }}
      />
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        {data.generalInformation.deliveryDetails.locations[0].positions.map(
          (pos, index) => (
            <Grid item xs={12} sm={4}>
              <PositionCard
                data={pos}
                showProductDetails={true}
                updatePositionFunction={(product, positionProperties) => {
                  var newRowData = {
                    productId: product.id,
                    productUnitId: pos.productUnitId,
                    salesPrice: pos.salesPrice,
                    purchasePrice: pos.purchasePrice,
                    product: product,
                    positionProperties: positionProperties,
                  }

                  const newPositions = [
                    ...data.generalInformation.deliveryDetails.locations[0]
                      .positions,
                  ]

                  let i = newPositions.findIndex(
                    (newPos) => newPos.id === pos.id,
                  )
                  newPositions[i] = {
                    ...newRowData,
                    id: newPositions[i].id,
                    amount: newPositions[i].amount ? newPositions[i].amount : 0,
                  }
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].positions',
                    newPositions,
                  )
                }}
                deletePositionFunction={() => {
                  if (data.type === 'REQUEST' && data.deliveries.length > 0) {
                    setFieldValue('deliveries', [])
                    dispatch(alertActions.info(t('REQUEST.DELIVERIES_DELETED')))
                    setTimeout(() => {
                      dispatch(alertActions.clear())
                    }, alertActions.alertTimeout)
                  }

                  var posData = [
                    ...data.generalInformation.deliveryDetails.locations[0]
                      .positions,
                  ]

                  posData.splice(index, 1)
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].positions',
                    posData,
                  )
                }}
                readOnly={false}
                showImage={true}
                imageSize={'small'}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      fullWidth={true}
                      component={TextField}
                      margin="dense"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        decimalScale:
                          data.generalInformation.deliveryDetails.locations[0]
                            .positions[index].productUnitId &&
                          data.generalInformation.deliveryDetails.locations[0].positions[
                            index
                          ].product.productUnits.find((productUnit) => {
                            return (
                              productUnit.id ===
                              data.generalInformation.deliveryDetails
                                .locations[0].positions[index].productUnitId
                            )
                          }).type === 'FLOAT'
                            ? 2
                            : 0,
                        allowNegative: false,
                      }}
                      label={t('REQUEST.AMOUNT')}
                      variant="outlined"
                      name={`generalInformation.deliveryDetails.locations[0].positions[${index}].amount`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ErrorMessage
                      name={`generalInformation.deliveryDetails.locations[0].positions[${index}].productUnitId`}
                      render={(msg) => <div className="help-block">{msg}</div>}
                    />
                    <ToggleButtonGroup
                      fullWidth
                      value={
                        data.generalInformation.deliveryDetails.locations[0]
                          .positions[index].productUnitId
                      }
                      sx={{ alignSelf: 'center' }}
                      size={'large'}
                    >
                      {data.generalInformation.deliveryDetails.locations[0].positions[
                        index
                      ].product.productUnits.map((productUnit) => {
                        return (
                          <ToggleButton
                            value={productUnit.id}
                            onClick={() =>
                              setFieldValue(
                                `generalInformation.deliveryDetails.locations[0].positions[${index}].productUnitId`,
                                productUnit.id,
                              )
                            }
                          >
                            {productUnit.name}
                          </ToggleButton>
                        )
                      })}
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      fullWidth={true}
                      component={TextField}
                      margin="dense"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        inputComponent: NumberFormatCustom,
                        decimalScale: 2,
                        allowNegative: false,
                        suffix: ' \u20AC',
                        fixedDecimalScale: true,
                      }}
                      label={t('ORDER_REGISTRATION.PURCHASE_PRICE')}
                      variant="outlined"
                      name={`generalInformation.deliveryDetails.locations[0].positions[${index}].purchasePrice`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      fullWidth={true}
                      component={TextField}
                      margin="dense"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      inputProps={{
                        inputComponent: NumberFormatCustom,
                        decimalScale: 2,
                        allowNegative: false,
                        suffix: ' \u20AC',
                        fixedDecimalScale: true,
                      }}
                      label={t('ORDER_REGISTRATION.SALES_PRICE')}
                      variant="outlined"
                      name={`generalInformation.deliveryDetails.locations[0].positions[${index}].salesPrice`}
                    />
                  </Grid>
                </Grid>
              </PositionCard>
            </Grid>
          ),
        )}
      </Grid>
    </Section>
  )
}

export default withTranslation()(connect()(OrderRegistrationPositions))
