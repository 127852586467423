import { Box, useMediaQuery, useTheme } from '@mui/material'
import Alert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import React from 'react'

const PREFIX = 'ViewDescripton'

const classes = {
  customAlert: `${PREFIX}-customAlert`,
}

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.customAlert}`]: {
    backgroundColor: '#f8f9fa',
    '& .MuiAlert-icon': {
      color: '#6C6C6C',
      alignItems: 'center',
    },
  },
}))

const ViewDescripton = (props) => {
  const { description } = props

  const theme = useTheme()
  const isSmallLargeOrHigher = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    isSmallLargeOrHigher && (
      <StyledBox>
        <Alert
          severity="info"
          // variant={'outlined'}
          className={classes.customAlert}
          style={{ marginBottom: '10px' }}
        >
          {description}
        </Alert>
      </StyledBox>
    )
  )
}

export default ViewDescripton
