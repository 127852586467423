import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const requestPositionsService = {
  getOfferPositionsOfRequestPosition,
}

function getOfferPositionsOfRequestPosition(requestPositionId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  let url = `${config.apiUrlBase}/request-positions/${requestPositionId}/offer-positions`

  return fetch(url, requestOptions).then(handleResponse)
}

export * from './requestPositionsService'
