import { Add, Delete } from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import {
  Alert,
  AlertTitle,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { Fragment, default as React, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { authorize } from '../../../../../../_helpers/authorization'
import { communitiesService } from '../../../../../../_services/communitiesService'
import { communityInvitationsService } from '../../../../../../_services/communityInvitationsService'
import { communityPartnersService } from '../../../../../../_services/communityPartnersService'
import AgreementDialog from '../../../../../AgreementDialog'

const CommunityView = ({ communityId, handleCloseDialog, t }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [forceUpdateCount, forceUpdate] = React.useState(0)
  const [selectedInvitationId, setSelectedInvitationId] = useState(null)
  const [selectedPartnerId, setSelectedPartnerId] = useState(null)
  const [invitationError, setInvitationError] = useState(null)
  const [community, setCommunity] = useState(null)
  const [
    showDeleteCommunityInvitationDialog,
    setShowDeleteCommunityInvitationDialog,
  ] = useState(false)
  const [
    showDeleteCommunityPartnerDialog,
    setShowDeleteCommunityPartnerDialog,
  ] = useState(false)
  const userObject = JSON.parse(localStorage.getItem('user'))
  const partnerId = userObject.partner.id

  useEffect(() => {
    setIsLoading(true)
    communitiesService
      .getCommunity(communityId)
      .then((data) => {
        setCommunity(data)
      })
      .catch((error) => {
        console.error('Error fetching community:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [forceUpdateCount, communityId])

  if (!community) {
    return null
  }
  return (
    <Fragment>
      <DialogTitle style={{ marginBottom: '30px' }}>
        <GroupsIcon style={{ marginRight: '5px' }} /> {community.name}
      </DialogTitle>
      <DialogContent>
        <Grid item sm={12} xs={12}>
          <Typography variant="body1">
            {t('COMMUNITIES.INVITE_MEMBER')}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
              <Formik
                initialValues={{
                  email: '',
                  state: 'ACTIVE',
                  partnerId: partnerId,
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .required(t('GENERAL.REQUIRED'))
                    .email(t('GENERAL.INVALID_EMAIL')),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  let requestBody = {
                    email: values.email,
                    state: 'ACTIVE',
                    communityId: community.id,
                  }

                  setSubmitting(true)
                  communityInvitationsService
                    .createCommunityInvitationOfCommunity(
                      community.id,
                      requestBody,
                    )
                    .then(() => {
                      forceUpdate(forceUpdateCount + 1)
                    })
                    .catch((error) => {
                      console.error('Error creating invtiation:', error)
                      if (error === 'Already in Community') {
                        setInvitationError(
                          t('COMMUNITIES.INVITATION_ALREADY_EXISTS'),
                        )
                      } else if (error === 'Same partner') {
                        setInvitationError(t('COMMUNITIES.INVITATION_OWNER'))
                      } else {
                        setInvitationError(error)
                      }
                    })
                  setSubmitting(false)
                }}
              >
                {({ values, submitForm }) => (
                  <Form>
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('COMMUNITY.INVITATION_EMAIL')}
                      variant="outlined"
                      name="email"
                    />

                    <Button
                      variant="contained"
                      color="secondary"
                      fullWidth
                      size="medium"
                      onClick={() => {
                        setInvitationError(null)
                        submitForm()
                      }}
                      startIcon={<Add />}
                    >
                      {t('COMMUNITY.INVITE_PARTNER')}
                    </Button>
                  </Form>
                )}
              </Formik>
              {invitationError ? (
                <Alert severity="error">
                  <AlertTitle>
                    {t('COMMUNITIES.INVITATION_ALREADY_EXISTS')}
                  </AlertTitle>
                  {invitationError}
                </Alert>
              ) : (
                <DialogContent></DialogContent>
              )}
            </Grid>
            {authorize('administrator') && (
              <Grid item xs={6} sm={6}>
                <Formik
                  initialValues={{
                    privacySetting: community.privacySetting,
                    transparencySetting: community.transparencySetting,
                  }}
                  validationSchema={Yup.object().shape({
                    privacySetting: Yup.string()
                      .required(t('GENERAL.REQUIRED'))
                      .oneOf(['PUBLIC', 'PRIVATE'], t('GENERAL.INVALID_VALUE')),
                    transparencySetting: Yup.string()
                      .required(t('GENERAL.REQUIRED'))
                      .oneOf(
                        ['FULL', 'INTERNAL', 'NONE'],
                        t('GENERAL.INVALID_VALUE'),
                      ),
                  })}
                  onSubmit={(values, { setSubmitting }) => {
                    let requestBody = {
                      privacySetting: values.privacySetting,
                      transparencySetting: values.transparencySetting,
                    }

                    setSubmitting(true)
                    communitiesService
                      .updateCommunity(community.id, requestBody)
                      .then(() => {
                        forceUpdate(forceUpdateCount + 1)
                      })
                      .catch((error) => {
                        console.error('Error updating community:', error)
                      })

                    setSubmitting(false)
                  }}
                >
                  {({ values, handleChange, submitForm }) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="privacy-setting-label">
                              {t('GENERAL.PRIVACY_SETTING')}
                            </InputLabel>
                            <Select
                              label={t('GENERAL.PRIVACY_SETTING')}
                              labelId="privacy-setting-label"
                              value={values.privacySetting}
                              onChange={(e) => {
                                handleChange(e)

                                submitForm()
                              }}
                              inputProps={{
                                name: 'privacySetting',
                              }}
                            >
                              <MenuItem value={'PUBLIC'}>
                                {t('COMMUNITIES.PRIVACY_SETTING_PUBLIC')}
                              </MenuItem>
                              <MenuItem value={'PRIVATE'}>
                                {t('COMMUNITIES.PRIVACY_SETTING_PRIVATE')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="transparency-setting-label">
                              {t('GENERAL.TRANSPARENCY_SETTING')}
                            </InputLabel>
                            <Select
                              label={t('GENERAL.TRANSPARENCY_SETTING')}
                              labelId="transparency-setting-label"
                              value={values.transparencySetting}
                              onChange={(e) => {
                                handleChange(e)

                                submitForm()
                              }}
                              inputProps={{
                                name: 'transparencySetting',
                              }}
                              fullWidth
                              margin="dense"
                            >
                              <MenuItem value={'FULL'}>
                                {t('COMMUNITIES.TRANSPARENCY_SETTING_FULL')}
                              </MenuItem>
                              <MenuItem value={'INTERNAL'}>
                                {t('COMMUNITIES.TRANSPARENCY_SETTING_INTERNAL')}
                              </MenuItem>
                              <MenuItem value={'NONE'}>
                                {t('COMMUNITIES.TRANSPARENCY_SETTING_NONE')}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={6}>
            <Typography variant="body1" style={{ marginBottom: '10px' }}>
              {t('COMMUNITIES.COMMUNITY_INVITATIONS')}
            </Typography>
            {community.communityInvitations &&
            community.communityInvitations.length > 0 ? (
              <Grid>
                {community.communityInvitations
                  .filter(
                    (communityInvitation) =>
                      communityInvitation.state !== 'ACCEPTED',
                  )
                  .map((communityInvitation) => (
                    <Paper
                      style={{
                        padding: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <div
                        style={{
                          flexGrow: 1,
                          textAlign: 'center',
                          margin: '0 8px',
                        }}
                      >
                        <Typography variant="body1">
                          {communityInvitation.email
                            ? communityInvitation.email
                            : communityInvitation.partner.name}
                        </Typography>
                      </div>
                      <Typography variant="body1">
                        {communityInvitation.state}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          setIsLoading(true)
                          setSelectedInvitationId(communityInvitation.id)
                          setShowDeleteCommunityInvitationDialog(true)
                        }}
                        color={'secondary'}
                        variant="contained"
                      >
                        <Delete />
                      </IconButton>
                    </Paper>
                  ))}
              </Grid>
            ) : (
              <Grid item>
                <Paper
                  style={{
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <div
                    style={{
                      flexGrow: 1,
                      textAlign: 'center',
                      margin: '0 8px',
                    }}
                  >
                    <Typography variant="body1">
                      {t('COMMUNITIES.COMMUNITY_INVITATIONS_NONE')}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            )}
          </Grid>
          <Grid item sm={6} xs={6}>
            <Typography variant="body1" style={{ marginBottom: '10px' }}>
              {t('COMMUNITIES.COMMUNITY_PARTNER')}
            </Typography>
            {community.partners && community.partners.length > 0 ? (
              <Grid>
                {community.partners
                  .filter((partner) => partnerId !== partner.id)
                  .map((partner) => (
                    <Paper
                      style={{
                        padding: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <div
                        style={{
                          flexGrow: 1,
                          textAlign: 'center',
                          margin: '0 8px',
                        }}
                      >
                        <Typography variant="body1">{partner.name}</Typography>
                      </div>
                      <IconButton
                        onClick={() => {
                          setIsLoading(true)
                          setSelectedPartnerId(partner.CommunityPartner.id)
                          setShowDeleteCommunityPartnerDialog(true)
                        }}
                        color={'secondary'}
                        variant="contained"
                      >
                        <Delete />
                      </IconButton>
                    </Paper>
                  ))}
              </Grid>
            ) : (
              <Grid item>
                <Paper
                  style={{
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                  }}
                >
                  <div
                    style={{
                      flexGrow: 1,
                      textAlign: 'center',
                      margin: '0 8px',
                    }}
                  >
                    <Typography variant="body1">
                      {t('COMMUNITIES.COMMUNITY_PARTNERS_NONE')}
                    </Typography>
                  </div>
                </Paper>
              </Grid>
            )}
          </Grid>
          <AgreementDialog
            open={showDeleteCommunityPartnerDialog}
            message={t('COMMUNITY.PARTNER_DELETE')}
            acceptButtonText={t('COMMUNITY.DELETE_COMMUNITY_PARTNER')}
            handleClose={() => {
              setShowDeleteCommunityPartnerDialog(false)
            }}
            agree={() => {
              setIsLoading(true)
              setShowDeleteCommunityPartnerDialog(false)
              communityPartnersService
                .deleteCommunityPartner(selectedPartnerId)
                .then(() => {
                  forceUpdate(forceUpdateCount + 1)
                  setIsLoading(false)
                })
                .catch((error) => {
                  console.error('Error deleting community partner:', error)
                })
            }}
          />
          <AgreementDialog
            open={showDeleteCommunityInvitationDialog}
            message={t('COMMUNITY.INVITATION_DELETE')}
            acceptButtonText={t('COMMUNITY.DELETE_COMMUNITY_INVITATION')}
            handleClose={() => {
              setShowDeleteCommunityInvitationDialog(false)
            }}
            agree={() => {
              setIsLoading(true)
              setShowDeleteCommunityInvitationDialog(false)
              communityInvitationsService
                .deleteCommunityInvitation(selectedInvitationId)
                .then(() => {
                  forceUpdate(forceUpdateCount + 1)
                  setIsLoading(false)
                })
                .catch((error) => {
                  console.error('Error deleting community invitation:', error)
                })
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseDialog}
          color="secondary"
          variant="contained"
        >
          {t('COMMUNITIES.CLOSE')}
        </Button>
      </DialogActions>
    </Fragment>
  )
}

export default withTranslation()(CommunityView)
