import moment from 'moment'
import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'
import { generateFilterValuesQueryString } from '../_helpers/little.js'

export const tenderOrdersService = {
  getTenderOrder,
  createTenderOrder,
  getTenderOrdersOfPartner,
  getTenderOrders,
}
function getTenderOrder(tenderOrderId, partnerId, partnerLocationIds) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    partnerLocationIds.length === 0
      ? `${config.apiUrlBase}/tender-orders/${tenderOrderId}?partnerId=${partnerId}&partnerLocationIds=null`
      : `${
          config.apiUrlBase
        }/tender-orders/${tenderOrderId}?partnerId=${partnerId}&${partnerLocationIds
          .map((partnerLocationId) => 'partnerLocationIds=' + partnerLocationId)
          .join('&')}`,
    requestOptions,
  ).then(handleResponse)
}

function createTenderOrder(tenderOrder) {
  var payload = mapTenderOrder(tenderOrder)
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(`${config.apiUrlBase}/tender-orders`, requestOptions).then(
    handleResponse,
  )
}

function getTenderOrdersOfPartner(
  partnerId,
  filterValues,
  pagination,
  partnerLocationIds,
) {
  if (partnerId === -1) {
    Promise.resolve([])
  }
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  let queryString = generateFilterValuesQueryString(filterValues, pagination)

  return fetch(
    partnerLocationIds.length === 0
      ? `${config.apiUrlBase}/partners/${partnerId}/tender-orders${queryString}&partnerLocationIds=null`
      : `${
          config.apiUrlBase
        }/partners/${partnerId}/tender-orders${queryString}&${partnerLocationIds
          .map((partnerLocationId) => 'partnerLocationIds=' + partnerLocationId)
          .join('&')}`,
    requestOptions,
  ).then(handleResponse)
}

function getTenderOrders(filterValues = undefined, pagination) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  filterValues = filterValues.calendarWeek
    ? {
        ...filterValues,
        calendarWeek: moment(filterValues.calendarWeek[0]).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      }
    : filterValues

  let queryString = generateFilterValuesQueryString(filterValues, pagination)
  return fetch(
    `${config.apiUrlBase}/tender-orders${queryString}`,
    requestOptions,
  ).then(handleResponse)
}

function mapTenderOrder(tenderOrder) {
  return {
    incoTerm: 'DDP',
    customerId: tenderOrder.customerId,
    customerUserId: tenderOrder.customerUserId,
    manufacturerId: tenderOrder.manufacturerId,
    manufacturerUserId: tenderOrder.manufacturerUserId,
    skonto: tenderOrder.generalInformation.paymentDetails.skonto,
    paymentTerms: tenderOrder.generalInformation.paymentDetails.paymentTerm,
    title: tenderOrder.generalInformation.otherDetails.title,
    start: moment(tenderOrder.generalInformation.otherDetails.start).format(
      'YYYY-MM-DD',
    ),
    end: moment(tenderOrder.generalInformation.otherDetails.end).format(
      'YYYY-MM-DD',
    ),
    invoiceAddress: tenderOrder.invoiceAddress,
    skontoPeriod: tenderOrder.generalInformation.paymentDetails.skontoPeriod,
    locations: tenderOrder.generalInformation.deliveryDetails.locations.map(
      (location) => ({
        company: location.company,
        zipcode: location.zipcode,
        place: location.place,
        address: location.address,
        deliveryInformations: location.deliveryInformations,
        countryId: location.countryId,
        localId: location.id,
        deliveryTimes: location.deliveryTimes,
        stackHeight: location.stackHeight,
        stackHeightUnit: location.stackHeightUnit,
        unload: location.unload,
        isDespatchAdviceRequired: location.isDespatchAdviceRequired,
        despatchAdviceInstructions: location.isDespatchAdviceRequired
          ? location.despatchAdviceInstructions
          : null,
        //remove unchecked positions before mapping to target structure
        tenderOrderPositions: location.positions
          .filter((pos) => pos.checked)
          .map((pos) => ({
            localId: pos.id,
            productId: pos.productId,
            productUnitId: pos.productUnitId,
            amount: pos.amount,
            purchasePrice: pos.purchasePrice,
            salesPrice: pos.salesPrice,
            positionProperties: pos.positionProperties.map(
              (positionProperty) => {
                return {
                  value: positionProperty.value,
                  productPropertyId: positionProperty.productProperty.id,
                }
              },
            ),
          })),
      }),
    ),
  }
}

export * from './tenderOrdersService'
