import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { alertActions } from '../../../../../../_actions/alertActions'
import { authorize } from '../../../../../../_helpers/authorization'
import { partnerLocationsService } from '../../../../../../_services/partnerLocationsService'
import { partnersService } from '../../../../../../_services/partnersService'
import { requestsService } from '../../../../../../_services/requestsService'
import { usersService } from '../../../../../../_services/usersService'
import CustomDialogTitle from '../../../../../CustomDialogTitle'
import { Loading } from '../../../../../Loading'

const RequestPartnerSwitchDialog = ({
  t,
  request,
  show,
  hide,
  forceUpdate,
  forceUpdateCount,
}) => {
  const [partners, setPartners] = useState([])
  const [selectedPartnerId, setSelectedPartnerId] = useState(request?.partnerId)
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [selectedPartnerInputValue, setSelectedPartnerInputValue] = useState('')
  const [partnerUsers, setPartnerUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchPartners = async () => {
      setIsLoading(true)
      try {
        const partnersData = await partnersService.getPartners()
        setPartners(partnersData)
      } catch (error) {
        console.error('Error loading partners:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchPartners()
  }, [])

  useEffect(() => {
    if (!selectedPartnerId) return

    const fetchUsers = async () => {
      if (!authorize('administrator')) {
        return
      }
      const users = await usersService.getUsersOfPartner(selectedPartnerId)
      setPartnerUsers(users)
      const masterUserId = users.find((user) => user.isMaster)?.id || null
      setSelectedUserId(masterUserId)
    }

    fetchUsers()
  }, [selectedPartnerId])

  const handleSave = async () => {
    try {
      if (!authorize('administrator')) {
        return
      }

      const userLocationsIds = (
        await partnerLocationsService.getPartnerLocationsOfUser(selectedUserId)
      ).map((location) => location.id)

      await requestsService
        .updateRequest(
          { partnerId: selectedPartnerId, userId: selectedUserId },
          request.id,
          false,
          userLocationsIds,
        )
        .then(() => {
          forceUpdate(forceUpdateCount + 1)
          alertActions.info(t('REQUESTS.PARTNER_SWITCHED'))
          setTimeout(() => {
            alertActions.clear()
          }, alertActions.alertTimeout)

          hide()
        })
        .catch((error) => {
          alertActions.error(error)
          setTimeout(() => {
            alertActions.clear()
          }, alertActions.alertTimeout)
        })
    } catch (error) {
      console.error('Error updating request partner:', error)
    }
  }

  if (isLoading) return <Loading variant={'centered'} />

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={show}
      onClose={hide}
      sx={{
        '& .MuiDialog-paper': {
          minHeight: '80vh',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <CustomDialogTitle
        title={t('REQUEST_PARTNER_SWAP.TITLE')}
        onClose={hide}
      />
      <DialogContent sx={{ flex: 1, overflow: 'auto' }}>
        <Fragment>
          {partners.length > 0 && (
            <Fragment>
              <FormControl variant="outlined" fullWidth margin="normal">
                <Autocomplete
                  value={selectedPartnerId}
                  onChange={(event, newValue) => {
                    setSelectedPartnerId(newValue)
                  }}
                  inputValue={selectedPartnerInputValue}
                  onInputChange={(event, newInputValue) => {
                    setSelectedPartnerInputValue(newInputValue)
                  }}
                  size="small"
                  disablePortal
                  options={partners.map((partner) => partner.id)}
                  getOptionLabel={(option) => {
                    const partner = partners.find(
                      (partner) => partner.id === option,
                    )
                    return partner
                      ? `${partner.name} (${partner.id}) - ${partner.place}`
                      : option
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('GENERAL.SELECTED_PARTNER')}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant="outlined" fullWidth margin="normal">
                <InputLabel id="selected-user-label">
                  {t('GENERAL.SELECTED_USER')}
                </InputLabel>
                <Select
                  labelId="selected-user-label"
                  value={selectedUserId || ''}
                  onChange={(e) => setSelectedUserId(e.target.value)}
                  label={t('GENERAL.SELECTED_USER')}
                >
                  {partnerUsers.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Fragment>
          )}
        </Fragment>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide} color="secondary" variant={'outlined'}>
          {t('GENERAL.CANCEL')}
        </Button>
        <Button
          onClick={handleSave}
          color="secondary"
          variant={'contained'}
          disabled={!selectedUserId}
        >
          {t('GENERAL.SUBMIT')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(RequestPartnerSwitchDialog)
