import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header.js'

export const userPartnerLocationsService = {
  updateUserPartnerLocations,
}

function updateUserPartnerLocations(userId, partnerLocationIds) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      partnerLocationIds: partnerLocationIds,
    }),
  }

  return fetch(
    `${config.apiUrlBase}/users/${userId}/user-partner-locations`,
    requestOptions,
  ).then(handleResponse)
}

export * from './userPartnerLocationsService.js'
