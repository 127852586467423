import { Grid, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { authorize } from '../../../../../../_helpers/authorization'
import { Section } from '../../../../../Section'
const OrderOtherDetailsView = (props) => {
  const { t, data } = props
  return (
    <Section header={t('ORDER.OTHER_DETAILS')} hideBorder={true}>
      <Grid container spacing={1}>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography variant={'subtitle1'}>
              {t('ORDER.CUSTOMER_ORDER_NUMBER') + ':'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} color={'text.secondary'}>
              {data.orderNumber ? (
                data.orderNumber
              ) : (
                <em>{t('ORDER.NO_ORDER_NUMBER')}</em>
              )}
            </Typography>
          </Grid>
        </Grid>
        {authorize('administrator') && data.internalNote && (
          <Grid item container xs={12}>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle1'}>
                {t('OFFER.INTERNAL_NOTE') + ':'}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {data.internalNote}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Section>
  )
}

export default withTranslation()(OrderOtherDetailsView)
