import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material'
import React from 'react'

export const Section = (props) => {
  const { children, header, variant, hideBorder } = props

  const theme = useTheme()
  return (
    <Card
      variant={'outlined'}
      sx={{
        margin: theme.spacing(1),
        border: hideBorder ? 'none' : 'default',
      }}
    >
      {header && (
        <CardHeader
          title={
            <Typography variant={variant ? variant : 'h5'}>{header}</Typography>
          }
          sx={{ paddingBottom: 2 }}
        />
      )}
      <CardContent sx={{ paddingTop: 0, paddingBottom: 0 }}>
        {children}
      </CardContent>
    </Card>
  )
}
