import { LibraryBooksOutlined } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DateRangeIcon from '@mui/icons-material/DateRange'
import {
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material'
import Popover from '@mui/material/Popover'
import { useTheme } from '@mui/material/styles'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryLookup,
  productLookup,
} from '../../../../../../_constants/lookupConstants'
import { generatePositionPropertyValue } from '../../../../../../_helpers/little'
import { _applicationService } from '../../../../../../_services/_applicationService'
import { ordersService } from '../../../../../../_services/ordersService'
import { Loading } from '../../../../../Loading'

const CalendarComponent = (props) => {
  const { t } = props
  var userObject = JSON.parse(localStorage.getItem('user'))
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [mouseOverOrder, setMouseOverOrder] = useState(null)
  const [fetchedOrder, setFetchedOrder] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (!mouseOverOrder || fetchedOrder[mouseOverOrder.orderId]) {
      return
    }

    ordersService.getOrder(mouseOverOrder.id).then((data) => {
      setMouseOverOrder(data)
      setFetchedOrder((prev) => ({ ...prev, [mouseOverOrder.id]: true }))
    })
  }, [anchorEl])

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setMouseOverOrder(null)
  }

  const handlePopoverOpen = (event, order) => {
    setAnchorEl(event.target)
    setMouseOverOrder(order)
  }

  const getWeekNumber = (date) => {
    const tempDate = new Date(date.getFullYear(), 0, 1)
    const dayNumber = (date - tempDate + (tempDate.getDay() || 7)) / 86400000
    return Math.ceil(dayNumber / 7)
  }
  const [currentYearAndWeek, setCurrentYearAndWeek] = useState(() => {
    const week = getWeekNumber(new Date())
    const year = new Date().getFullYear()
    return `${year}-KW${week}`
  })
  const groupByWeekAndYear = (orders) => {
    return orders.reduce((acc, order) => {
      const key = `${order.year}-KW${order.week}`
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(order)
      return acc
    }, {})
  }

  let today = new Date()
  const startCw = getWeekNumber(today)
  const startYear = today.getFullYear()

  useEffect(() => {
    setIsLoading(true)
    _applicationService
      .getDashboardCalendarOfPartner(userObject.partner.id, startCw, startYear)
      .then((data) => {
        setData(groupByWeekAndYear(data))
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
      .finally(() => setIsLoading(false))
  }, [startCw, startYear])
  const getWeeksInYear = (year) => {
    const lastDayOfYear = new Date(year, 11, 31)
    const weekNumber = getWeekNumber(lastDayOfYear)
    return weekNumber === 1 ? 52 : weekNumber
  }
  const handlePrevCW = () => {
    let [year, week] = currentYearAndWeek.split('-KW')
    week = parseInt(week, 10)
    week--
    if (week < 1) {
      year--
      week = getWeeksInYear(parseInt(year))
    }
    setCurrentYearAndWeek(`${year}-KW${week}`)
  }
  const extractWeekNumber = (yearWeek) => {
    return yearWeek.split('-KW')[1]
  }
  const handleNextCW = () => {
    let [year, week] = currentYearAndWeek.split('-KW')
    week = parseInt(week, 10)
    const maxWeeks = getWeeksInYear(parseInt(year))

    week++
    if (week > maxWeeks) {
      week = 1
      year++
    }
    setCurrentYearAndWeek(`${year}-KW${week}`)
  }

  const renderWeek = (cwNumber, weekOrders) => {
    return (
      <Paper
        elevation={1}
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '20px',
          height: '30vh',
        }}
      >
        <div
          style={{
            backgroundColor: '#B5DBDE',
            padding: '8px',
            borderRadius: '10px 10px 0 0',
          }}
        >
          <Typography
            variant="h7"
            style={{ marginRight: '5px', marginLeft: '10px' }}
          >
            {t('DASHBOARD.ORDERS_KW') + ' ' + cwNumber}
          </Typography>
        </div>
        <div
          style={{
            padding: '10px 0px 8px 8px',
          }}
        >
          <div
            style={{
              overflowY: 'auto',
              height: '18vh',
              paddingTop: '8px',
            }}
          >
            {weekOrders && weekOrders.length > 0 ? (
              <List dense={true}>
                {weekOrders.map((order) => (
                  <div
                    key={order.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '5px',
                    }}
                  >
                    <div
                      id="hoverContainer"
                      onMouseEnter={(event) => handlePopoverOpen(event, order)}
                      onMouseLeave={handlePopoverClose}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Link
                        href={order.orderLink}
                        style={{
                          color: '#0B6467',
                          textDecoration: 'none',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ListItem>
                          <ListItemIcon>
                            <LibraryBooksOutlined color="primary" />
                          </ListItemIcon>
                          <ListItemText
                            primary={order.id}
                            secondary={`${order.zipcode} ${order.place}`}
                          />
                        </ListItem>
                      </Link>
                    </div>
                  </div>
                ))}
              </List>
            ) : (
              <Typography
                variant="body1"
                style={{ marginRight: '5px', marginLeft: '10px' }}
              >
                {t('DASGHBOARD.NO_ORDERS')}
              </Typography>
            )}
          </div>
        </div>
      </Paper>
    )
  }
  const getNextWeeksIncludingCurrent = (currentYearWeek) => {
    const [year, week] = currentYearWeek.split('-KW').map(Number)
    let weeks = []
    if (isMobile) {
      return [`${year}-KW${week}`]
    } else {
      for (let i = 0; i < 3; i++) {
        if (week + i > 52) {
          weeks.push(`${year + 1}-KW${week + i - 52}`)
        } else {
          weeks.push(`${year}-KW${week + i}`)
        }
      }
    }

    return weeks
  }

  const weeksToShow = getNextWeeksIncludingCurrent(currentYearAndWeek)
  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Fragment>
        <div style={{ marginBottom: '5px' }}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <IconButton onClick={handlePrevCW}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <DateRangeIcon color="primary" fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="h5" style={{ marginTop: '4px' }}>
                {weeksToShow[0].replace('KW', '')}
                {weeksToShow.length === 1
                  ? null
                  : '-' + weeksToShow[weeksToShow.length - 1].replace('KW', '')}
              </Typography>
            </Grid>
            <Grid item>
              <DateRangeIcon color="primary" fontSize="large" />
            </Grid>
            <Grid item>
              <IconButton onClick={handleNextCW}>
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2}>
          {weeksToShow.map((yearWeek) => (
            <Grid item xs={12} sm={12} md={4} key={yearWeek}>
              {renderWeek(extractWeekNumber(yearWeek), data[yearWeek] || [])}
            </Grid>
          ))}
        </Grid>
        <Popover
          style={{
            pointerEvents: 'none',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Grid
            container
            direction="column"
            spacing={2}
            sx={{
              paddingTop: '30px',
              paddingBottom: '20px',
              paddingLeft: '20px',
              paddingRight: '20px',
              fontSize: '0.9rem',
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item paddingLeft={2}>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  {t('DASHBOARD.ORDER_DATA')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {`${t('DASGHBOARD.DATE')}: ${moment(
                  mouseOverOrder?.deliveryValue,
                ).format('DD.MM.YYYY')}`}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1">
                {`${t('DASGHBOARD.PLZ')}: ${mouseOverOrder?.zipcode}`}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1">
                {`${t('DASGHBOARD.PLACE')}: ${mouseOverOrder?.place}`}
              </Typography>
            </Grid>

            <Grid item>
              <Typography variant="body1">
                {`${t('DASGHBOARD.ADDRESS')}: ${mouseOverOrder?.address}`}
              </Typography>
            </Grid>

            {mouseOverOrder && (
              <Grid item>
                <Typography variant="body1">
                  {`${countryLookup[mouseOverOrder.countryId]}`}
                </Typography>
              </Grid>
            )}
            <Grid item>
              {mouseOverOrder?.positions?.length > 0 && (
                <List dense>
                  {mouseOverOrder.positions.map((position, index) => (
                    <React.Fragment key={index}>
                      <ListItem>
                        <ListItemText
                          primary={productLookup[position.productId]}
                          secondary={
                            <>
                              {position.positionProperties
                                .filter(
                                  (prop) => prop.productProperty.isHighlight,
                                )
                                .map((prop, propIndex) => (
                                  <Typography
                                    component="span"
                                    key={propIndex}
                                    variant="body2"
                                    style={{
                                      fontWeight: 'bold',
                                      display: 'block',
                                    }}
                                  >
                                    {generatePositionPropertyValue(prop)}
                                  </Typography>
                                ))}
                            </>
                          }
                        />
                      </ListItem>
                      {index < mouseOverOrder.positions.length - 1 && (
                        <Divider />
                      )}
                    </React.Fragment>
                  ))}
                </List>
              )}
            </Grid>
          </Grid>
        </Popover>
      </Fragment>
    )
  )
}

export default withTranslation()(CalendarComponent)
