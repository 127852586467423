import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { alertActions } from '../../../../../../_actions/alertActions'
import { surveysService } from '../../../../../../_services/surveysService'
import { Loading } from '../../../../../Loading'
import SurveyParticipantDialog from './_components/SurveyParticipantDialog'

const PartnerSurveys = (props) => {
  const { t, dispatch } = props

  const [surveys, setSurveys] = useState([])
  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const [showSurveyDialog, setShowSurveyDialog] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const userObject = JSON.parse(localStorage.getItem('user'))
  let partnerId = parseInt(userObject.partner.id)

  useEffect(() => {
    fetchSurveysOfPartner()
  }, [partnerId, forceUpdateCount])

  const fetchSurveysOfPartner = async () => {
    setIsLoading(true)
    try {
      const data = await surveysService.getSurveysOfPartner(partnerId)
      const activeSurveys = data.filter((survey) => survey.state === 'ACTIVE')
      const surveysWithNextParticipationDate = activeSurveys.map((survey) => {
        const firstAnswer =
          survey.surveyQuestions[0].surveyQuestionPartnerAnswers[0]?.createdAt

        const lastParticipationDate = firstAnswer ? firstAnswer : null
        const nextParticipationDate = calculateNextParticipationDate(
          lastParticipationDate,
          survey.upToDateTimeInDays,
        )
        return {
          ...survey,
          nextParticipationDate,
          hasParticipated: !!lastParticipationDate,
        }
      })
      setSurveys(surveysWithNextParticipationDate)
    } catch (error) {
      dispatch(alertActions.error(t('SURVEY.FETCH_ERROR')))
    } finally {
      setIsLoading(false)
    }
  }

  const handleOpenDialog = (survey) => {
    setSelectedSurvey(survey)
    setShowSurveyDialog(true)
  }

  const handleCloseDialog = () => {
    setShowSurveyDialog(false)
    setSelectedSurvey(null)
  }

  const calculateNextParticipationDate = (lastParticipationDate, daysValid) => {
    if (!lastParticipationDate) return t('SURVEY.NO_LAST_PARTICIPATION_DATE')
    try {
      const nextDate = moment(lastParticipationDate).add(daysValid, 'days')
      return nextDate.format('DD/MM/YYYY')
    } catch (error) {
      console.error('Error parsing date:', error)
      return t('SURVEY.INVALID_DATE')
    }
  }

  return isLoading ? (
    <Loading variant={'center'} />
  ) : (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('SURVEY.TITLE')}</TableCell>
              <TableCell>{t('SURVEY.IS_REQUIRED')}</TableCell>
              <TableCell>{t('SURVEY.NEXT_PARTICIPATION_DATE')}</TableCell>
              <TableCell>{t('SURVEY.ACTIONS')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {surveys.map((survey) => (
              <TableRow key={survey.id}>
                <TableCell>{survey.title}</TableCell>
                <TableCell>
                  <Typography>
                    {survey.isRequired ? t('SURVEY.YES') : t('SURVEY.NO')}
                  </Typography>
                </TableCell>
                <TableCell>{survey.nextParticipationDate}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={() => handleOpenDialog(survey)}
                    sx={{ marginRight: 1 }}
                  >
                    {t('SURVEY.PARTICIPATE')}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      {showSurveyDialog && selectedSurvey && (
        <SurveyParticipantDialog
          surveyId={selectedSurvey.id}
          partnerId={partnerId}
          show={showSurveyDialog}
          hide={handleCloseDialog}
          t={t}
          mode={selectedSurvey.hasParticipated ? 'EDIT' : 'NEW'}
        />
      )}
    </Grid>
  )
}

export default withTranslation()(connect()(PartnerSurveys))
