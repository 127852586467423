import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { Chip, Grid, IconButton, Paper, Switch } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { productLookup } from '../../../../../../_constants/lookupConstants'
import { partnerProductsService } from '../../../../../../_services/partnerProductsService'
import ProductStatesAllowedDialog from './_components/ProductStatesAllowedDialog'

const PartnerProductsAllowed = (props) => {
  const { t, selectedPartnerId } = props
  const [partnerProducts, setPartnerProducts] = useState(null)
  const [open, setOpen] = React.useState(false)
  const [selectedPartnerProduct, setSelectedPartnerProduct] = useState(null)
  const [forceUpdateCount, forceUpdate] = useState(0)

  useEffect(() => {
    if (selectedPartnerId) {
      partnerProductsService
        .getPartnerProducts(selectedPartnerId)
        .then(async (partnerProducts) => {
          let mappedPartnerProducts = partnerProducts.map((partnerProduct) => {
            return {
              ...partnerProduct,
              product: { name: productLookup[partnerProduct.productId] },
            }
          })
          setPartnerProducts(mappedPartnerProducts)
        })
    }
  }, [selectedPartnerId, forceUpdateCount])

  const handleProductActivationSwitch = async (partnerProductId, isActive) => {
    try {
      await partnerProductsService.updatePartnerProduct(partnerProductId, {
        isActive: isActive,
      })
      setPartnerProducts((prevPartnerProducts) => {
        return prevPartnerProducts.map((prevPartnerProduct) => {
          if (prevPartnerProduct.id === partnerProductId) {
            return { ...prevPartnerProduct, isActive }
          }
          return prevPartnerProduct
        })
      })
    } catch (error) {
      console.log('Fehler Produkt-Partners:', error)
    }
  }

  const handleOpenDialog = (partnerProduct) => {
    setSelectedPartnerProduct(partnerProduct)
    setOpen(true)
  }
  return (
    <Fragment>
      <Grid container spacing={1}>
        {partnerProducts &&
          partnerProducts.map((partnerProduct, index) => {
            return (
              <Grid item xs={12} sm={4} key={index}>
                <Paper
                  elevation={3}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    margin: '10px',
                    opacity: partnerProduct.isActive ? 1 : 0.5,
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={9}>
                      <h3>{partnerProduct.product.name}</h3>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                      <Switch
                        checked={partnerProduct.isActive}
                        onChange={(e) =>
                          handleProductActivationSwitch(
                            partnerProduct.id,
                            e.target.checked,
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={9}>
                      {partnerProduct.states.map((state, stateIndex) => (
                        <Chip
                          key={stateIndex}
                          label={state}
                          style={{ marginRight: '5px' }}
                        />
                      ))}
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                      <IconButton
                        variant="contained"
                        color="secondary"
                        onClick={() => handleOpenDialog(partnerProduct)}
                      >
                        <OpenInNewOutlinedIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )
          })}
      </Grid>
      {selectedPartnerProduct && (
        <ProductStatesAllowedDialog
          open={open}
          handleClose={() => setOpen(false)}
          forceUpdate={() => {
            forceUpdate(forceUpdateCount + 1)
          }}
          handleCancel={() => setSelectedPartnerProduct(null)}
          title="Produkt States"
          partnerProduct={selectedPartnerProduct}
        />
      )}
    </Fragment>
  )
}

export default PartnerProductsAllowed
