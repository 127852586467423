import { Info, ShowChart } from '@mui/icons-material'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { indexLookup } from '../../../../../../_constants/lookupConstants'
import { formatToNumber } from '../../../../../../_helpers/number-formatting'
import { _proxiesService } from '../../../../../../_services/_proxiesService'
import { Loading } from '../../../../../Loading'

function DashboardIndex(props) {
  const { t } = props

  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({})

  useEffect(() => {
    setIsLoading(true)
    ;(async () => {
      const response = await _proxiesService.getLatestOfIndex(
        userObject.partner.hasAccessToIndex
          ? 'DE_EPAL_NEW_V2'
          : userObject.partner.activeTenderIndexes[0],
      )

      setData(response)
      setIsLoading(false)
    })()
  }, [])

  const userObject = JSON.parse(localStorage.getItem('user'))

  const hasIndexAccess =
    userObject.partner.hasAccessToIndex ||
    userObject.partner.activeTenderIndexes.length > 0

  return (
    <Paper
      style={{
        overflow: 'hidden',
        borderRadius: '10px',
        height: '40vh',
        background: '#B5DBDE',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)', // Leichter Schatten für Tiefe
      }}
      elevation={1}
    >
      {(isLoading && <Loading variant={'centered'} />) || (
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Grid container alignItems="center" justifyContent="center">
            <ShowChart
              color="secondary"
              style={{
                fontSize: '40px',
              }}
            />
            <Typography
              variant="h5"
              align="center"
              style={{
                color: '#333',
                marginLeft: '10px',
              }}
            >
              {t('DASHBOARD.INDEX')}
            </Typography>
          </Grid>
          <Grid
            container
            spacing={1}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ flexGrow: 1 }}
          >
            {data.value && (
              <Fragment>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{
                    color: '#333333',
                    margin: 1,
                  }}
                >
                  {`${
                    userObject.partner.hasAccessToIndex
                      ? indexLookup['DE_EPAL_NEW_V2']
                      : indexLookup[userObject.partner.activeTenderIndexes[0]]
                  } - ${moment(data.timestamp).format('DD.MM.YYYY')}`}
                </Typography>
                <Typography
                  variant="h3"
                  align="center"
                  style={{ color: '#333333' }}
                >
                  {formatToNumber(data.value.indexValue)}
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  style={{
                    color: data.difference >= 0 ? '#0b6467' : '#fb5315',
                  }}
                >
                  {data.difference >= 0
                    ? `+${formatToNumber(data.difference * 100)}%`
                    : `${formatToNumber(data.difference * 100)}%`}
                </Typography>
              </Fragment>
            )}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            {hasIndexAccess ? (
              <Button
                size={'large'}
                variant="contained"
                color="secondary"
                endIcon={<ShowChart />}
                component={Link}
                to="indexes"
              >
                {t('DASHBOARD.GO_TO_INDEX')}
              </Button>
            ) : (
              <Button
                size={'large'}
                variant="contained"
                color="secondary"
                endIcon={<Info />}
                component={Link}
                to="https://pacurion.com/index"
                target="_blank"
              >
                {t('DASHBOARD.INDEX_MORE_DETAILS')}
              </Button>
            )}
          </Box>
        </Box>
      )}
    </Paper>
  )
}

export default withTranslation()(DashboardIndex)
