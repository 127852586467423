import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const productCategoriesService = {
  getProductCategories,
  createProductCategory,
  updateProductCategory,
  deleteProductCategory,
}

function getProductCategories(hierarchy = false) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/product-categories?hierarchy=${hierarchy}`,
    requestOptions,
  )
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function createProductCategory(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(`${config.apiUrlBase}/product-categories`, requestOptions).then(
    handleResponse,
  )
}

function updateProductCategory(productCategoryId, payload) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(
    `${config.apiUrlBase}/product-categories/${productCategoryId}`,
    requestOptions,
  ).then(handleResponse)
}

function deleteProductCategory(productCategoryId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/product-categories/${productCategoryId}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './productCategoriesService'
