import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const invoicesService = {
  getInvoicesOfDeliveryNote,
  getInvoicesOfOrder,
  createInvoices,
  deleteInvoice,
  getInvoiceDraft,
  getInvoice,
  updateInvoice,
}

function createInvoices(invoices) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(invoices),
  }

  return fetch(`${config.apiUrlBase}/invoices`, requestOptions).then(
    handleResponse,
  )
}

function getInvoicesOfDeliveryNote(deliveryNoteId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/delivery-notes/${deliveryNoteId}/invoices`,
    requestOptions,
  ).then(handleResponse)
}

function getInvoicesOfOrder(orderId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/orders/${orderId}/invoices`,
    requestOptions,
  ).then(handleResponse)
}

function deleteInvoice(invoiceId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/invoices/${invoiceId}`,
    requestOptions,
  ).then(handleResponse)
}

function getInvoiceDraft(deliveryNoteId, receiptType, ref, refDate) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/invoices/${deliveryNoteId}/draft?receiptType=${receiptType}&ref=${ref}&refDate=${refDate}`,
    requestOptions,
  ).then(handleResponse)
}

function getInvoice(invoiceId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(`${config.apiUrlBase}/invoices/` + invoiceId, requestOptions)
}

function updateInvoice(payload, invoiceId) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }

  return fetch(
    `${config.apiUrlBase}/invoices/${invoiceId}`,
    requestOptions,
  ).then(handleResponse)
}
