import { Add, Delete, Edit } from '@mui/icons-material'
import { Alert, Button, Grid, IconButton } from '@mui/material'
import _ from 'lodash'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { reorgArray } from '../../../../../../../../../../_helpers/little'
import AgreementDialog from '../../../../../../../../../AgreementDialog'
import PositionCard from '../../../../../../../../../PositionCard'
import { Section } from '../../../../../../../../../Section'
import JobDeliveryWizard from './_components/JobDeliveryWizard'

const JobDeliveries = (props) => {
  const { t, data, setFieldValue, readOnly, positions, errors, type } = props

  const [deliveryWizardOpen, setDeliveryWizardOpen] = useState(
    data.deliveries && data.deliveries.length === 0 ? true : false,
  )

  const [selectedDelivery, setSelectedDelivery] = useState(null)

  const [showDeleteDeliveryDialog, setShowDeleteDeliveryDialog] =
    useState(false)

  let dPosAmountLookup = {}

  positions.forEach((pos) => {
    dPosAmountLookup[pos.id] = 0
    data.deliveries.forEach((delivery) => {
      delivery.deliveryPositions.forEach((dPos) => {
        if (dPos.positionId === pos.id) {
          dPosAmountLookup[pos.id] =
            parseFloat(dPosAmountLookup[pos.id]) +
            (isNaN(parseFloat(dPos.amount)) ? 0 : parseFloat(dPos.amount))
        }
      })
    })
  })

  let positionsWithoutDeliveriesExists = positions.some((pos) => {
    return pos.amount - dPosAmountLookup[pos.id] !== 0
  })

  return (
    <Fragment>
      {positionsWithoutDeliveriesExists && !readOnly && (
        <Alert severity="error">{t('JOB_OFFERS.OFFER_CANT_BE_ACCEPTED')}</Alert>
      )}
      {!readOnly && errors && _.some(errors.deliveries) ? (
        Array.isArray(errors.deliveries) ? (
          errors.deliveries.map((msg, index) => {
            return (
              <div className="help-block">
                {t('REQUEST.DELIVERY') + ' ' + (index + 1) + ': ' + msg}
              </div>
            )
          })
        ) : (
          <div className="help-block">{errors.deliveries}</div>
        )
      ) : null}
      {!readOnly && (
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          size={'medium'}
          style={{ marginBottom: 20, marginTop: 10 }}
          onClick={() => setDeliveryWizardOpen(true)}
        >
          {t('DELIVERIES.ADD_DELIVERY')}
        </Button>
      )}
      {data.deliveries
        .sort((a, b) => (a.value < b.value ? -1 : 1))
        .map((delivery, index) => (
          <Section
            header={
              <Fragment>
                {`${t('JOB_DELIVERIES.DELIVERY_IN')} ${delivery.value} ${
                  delivery.type === 'RELATIVE_WEEKS'
                    ? t('JOB_DELIVERIES.WEEKS')
                    : t('JOB_DELIVERIES.DAYS')
                }`}

                {!readOnly && (
                  <Fragment>
                    <IconButton
                      color={'secondary'}
                      onClick={() => {
                        setSelectedDelivery(delivery)
                        setDeliveryWizardOpen(true)
                      }}
                      size="large"
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      color={'secondary'}
                      onClick={() => {
                        setSelectedDelivery(delivery)
                        setShowDeleteDeliveryDialog(true)
                      }}
                      size="large"
                    >
                      <Delete />
                    </IconButton>
                  </Fragment>
                )}
              </Fragment>
            }
          >
            <Grid
              container
              spacing={3}
              direction="row"
              alignItems="stretch"
              justifyContent={'flex-start'}
            >
              {positions
                .filter((pos) => {
                  let deliveryPosition =
                    data.deliveries[index].deliveryPositions[
                      data.deliveries[index].deliveryPositions.findIndex(
                        (dPos) => dPos.positionId === pos.id,
                      )
                    ]

                  return deliveryPosition && deliveryPosition.amount > 0
                })
                .map((pos) => {
                  return (
                    <Grid item xs={12} sm={4}>
                      <PositionCard
                        data={pos}
                        readOnly={true}
                        showProductDetails={true}
                        showAmount={true}
                        amount={
                          data.deliveries[index].deliveryPositions[
                            data.deliveries[index].deliveryPositions.findIndex(
                              (dPos) => dPos.positionId === pos.id,
                            )
                          ].amount
                        }
                        showImage={true}
                        imageSize={'small'}
                      />
                    </Grid>
                  )
                })}
            </Grid>
          </Section>
        ))}

      <JobDeliveryWizard
        data={data}
        open={deliveryWizardOpen}
        close={() => {
          setDeliveryWizardOpen(false)
          setSelectedDelivery(null)
        }}
        readOnly={readOnly}
        submit={(delivery) => {
          var deliveries = [...data.deliveries]
          reorgArray(deliveries, 'id', 1)
          if (!selectedDelivery) {
            delivery.id = data.deliveries.length + 1
            deliveries.push(delivery)
          } else {
            deliveries[
              data.deliveries.findIndex((del) => del === selectedDelivery)
            ] = delivery
          }
          setFieldValue('deliveries', deliveries)
        }}
        type={type}
        positions={positions}
        deliveries={data.deliveries}
        selectedDelivery={selectedDelivery}
      />
      <AgreementDialog
        open={showDeleteDeliveryDialog}
        handleClose={() => {
          setShowDeleteDeliveryDialog(false)
        }}
        agree={() => {
          var newDeliveries = data.deliveries
          var i = newDeliveries.indexOf(selectedDelivery)
          newDeliveries.splice(i, 1)
          reorgArray(newDeliveries, 'id', 1)
          setFieldValue('deliveries', newDeliveries)
          setSelectedDelivery(null)
          setShowDeleteDeliveryDialog(false)
        }}
      />
    </Fragment>
  )
}

export default withTranslation()(JobDeliveries)
