import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const productPropertiesService = {
  getProductProperties,
  createProductProperty,
  updateProductProperty,
  deleteProductProperty,
}

function getProductProperties() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/product-properties`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function createProductProperty(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(`${config.apiUrlBase}/product-properties`, requestOptions).then(
    handleResponse,
  )
}

function updateProductProperty(productPropertyId, payload) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(
    `${config.apiUrlBase}/product-properties/${productPropertyId}`,
    requestOptions,
  ).then(handleResponse)
}

function deleteProductProperty(productPropertyId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/product-properties/${productPropertyId}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './productPropertiesService'
