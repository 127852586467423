import { Info } from '@mui/icons-material'
import { Grid, InputAdornment, MenuItem, Tooltip } from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import _ from 'lodash'
import numeral from 'numeral'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryLookup,
  indexLookup,
} from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import {
  getIndexesOfPosition,
  translateProductUnit,
} from '../../../../_helpers/little'
import NumberFormatCustom from '../../../../_helpers/numberFormatCustom'
import DifferenceWrapper from '../../../DifferenceWrapper'
import PositionCard from '../../../PositionCard'
import { Section } from '../../../Section'

const OfferTenderPositions = (props) => {
  const {
    t,
    data,
    errors,
    setFieldValue,
    differences,
    diffData,
    diffObjectType,
  } = props

  //group positions based on localId as location aggregated positions share the same localId
  var group = _.groupBy(data.positions, 'id')
  var aggregatedPositions = _.map(_.keys(group), function (e) {
    return _.reduce(
      group[e],
      function (r, o) {
        if (o.checked) {
          r.amount += +o.amount
        }
        return r
      },
      {
        ...group[e][0],
        amount: 0,
      },
    )
  })

  let request = data.request ? data.request : data

  return (
    <Fragment>
      <Section header={t('OFFER.POSITIONS')}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          style={{ marginTop: 10 }}
        >
          {typeof errors.positions === 'string' ? (
            <ErrorMessage
              name={'positions'}
              render={(msg) => <div className="help-block">{msg}</div>}
            />
          ) : null}
          {aggregatedPositions.map((pos, index) => {
            var combinedPosProperties = []
            differences.forEach((pos) => {
              combinedPosProperties.push(...pos.positionProperties)
            })

            let showDifference =
              combinedPosProperties.filter(
                (positionProperty) => positionProperty.value !== false,
              ).length > 0

            return (
              <Grid item xs={12} md={6} lg={4}>
                <PositionCard
                  data={pos}
                  readOnly={false}
                  showProductDetails={true}
                  differences={differences.find(
                    (diffPos) =>
                      parseInt(diffPos.id) === parseInt(pos.id) &&
                      parseInt(diffPos.locationId) === parseInt(pos.locationId),
                  )}
                  diffData={diffData.positions.find(
                    (diffPos) => parseInt(diffPos.id) === parseInt(pos.id),
                  )}
                  diffObjectType={diffObjectType}
                  showDifference={showDifference}
                  updatePositionFunction={(product, positionProperties) => {
                    var newRowData = {
                      productId: product.id,
                      productUnitId: pos.productUnitId,
                      positionProperties: positionProperties,
                      product: product,
                    }

                    const newPositions = [...data.positions]
                    for (let index = 0; index < newPositions.length; index++) {
                      if (pos.id === newPositions[index].id) {
                        newPositions[index] = {
                          ...newRowData,
                          id: newPositions[index].id,
                          amount: newPositions[index].amount
                            ? newPositions[index].amount
                            : 0,
                          locationId: newPositions[index].locationId,
                          price: newPositions[index].price,
                          requestPositionId:
                            newPositions[index].requestPositionId,
                          checked: newPositions[index].checked,
                        }
                      }
                    }
                    setFieldValue('positions', newPositions)
                  }}
                  parentType={'OFFER_POSITION'}
                  showImage={true}
                  imageSize={'small'}
                >
                  {`${numeral(pos.amount).format(
                    '0,0.[00]',
                  )} ${translateProductUnit(
                    pos.product.productUnits.find((productUnit) => {
                      return (
                        productUnit.id === data.positions[index].productUnitId
                      )
                    }),
                  )}`}
                </PositionCard>
              </Grid>
            )
          })}
        </Grid>
      </Section>
      {data.generalInformation.deliveryDetails.locations.map(
        (location, index) => (
          <Section
            header={
              <Fragment>
                {t('REQUEST.LOCATION')}
                {' ' + (index + 1)}
                {location.company ? ` | ${location.company}` : null}
                {location.countryId
                  ? ` | ${countryLookup[location.countryId]}`
                  : null}
                {location.address ? ` | ${location.address}` : null}
                {location.zipcode ? ` | ${location.zipcode}` : null}
                {location.place ? ` | ${location.place}` : null}
              </Fragment>
            }
          >
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              style={{ marginTop: 10 }}
            >
              {data.positions
                .filter(
                  (pos) =>
                    pos.locationId === location.id &&
                    request.generalInformation.deliveryDetails.locations
                      .find((loc) => loc.id === location.id)
                      .positions.find((rPos) => {
                        return (
                          rPos.locationId === location.id && rPos.id === pos.id
                        )
                      }).amount > 0,
                )
                .map((pos, posIndex) => (
                  <Grid item xs={12} md={6} lg={4}>
                    <PositionCard
                      data={pos}
                      readOnly={true}
                      showProductDetails={true}
                      showImage={true}
                      imageSize={'small'}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Field
                            component={CheckboxWithLabel}
                            margin="dense"
                            variant="outlined"
                            name={
                              'positions[' +
                              data.positions.findIndex(
                                (position) =>
                                  position.locationId === location.id &&
                                  position.id === pos.id,
                              ) +
                              '].checked'
                            }
                            checked={
                              data.positions[
                                data.positions.findIndex(
                                  (position) =>
                                    position.locationId === location.id &&
                                    position.id === pos.id,
                                )
                              ].checked
                            }
                            Label={{ label: t('OFFER.OFFER_YES_NO') }}
                            type={'checkbox'}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <DifferenceWrapper
                            showDifference={
                              differences.filter(
                                (diffPos) => diffPos.amount !== false,
                              ).length > 0
                            }
                            difference={
                              differences.find(
                                (diffPos) =>
                                  parseInt(diffPos.id) === parseInt(pos.id) &&
                                  parseInt(diffPos.locationId) ===
                                    parseInt(pos.locationId),
                              ).amount
                            }
                            differenceDisplay={numeral(
                              differences.find(
                                (diffPos) =>
                                  parseInt(diffPos.id) === parseInt(pos.id) &&
                                  parseInt(diffPos.locationId) ===
                                    parseInt(pos.locationId),
                              ).amount,
                            ).format('0,0.[00]')}
                            diffObjectType={diffObjectType}
                          >
                            <Field
                              fullWidth={true}
                              disabled={
                                !data.positions[
                                  data.positions.findIndex(
                                    (position) =>
                                      position.locationId === location.id &&
                                      position.id === pos.id,
                                  )
                                ].checked
                              }
                              component={TextField}
                              margin="dense"
                              size={'small'}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {translateProductUnit(
                                      data.positions[
                                        posIndex
                                      ].product.productUnits.find(
                                        (productUnit) => {
                                          return (
                                            productUnit.id ===
                                            data.positions[posIndex]
                                              .productUnitId
                                          )
                                        },
                                      ),
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                decimalScale:
                                  data.positions[posIndex].productUnitId &&
                                  data.positions[
                                    posIndex
                                  ].product.productUnits.find((productUnit) => {
                                    return (
                                      productUnit.id ===
                                      data.positions[posIndex].productUnitId
                                    )
                                  }).type === 'FLOAT'
                                    ? 2
                                    : 0,
                                allowNegative: false,
                              }}
                              label={t('REQUEST.AMOUNT')}
                              variant="outlined"
                              name={
                                'positions[' +
                                data.positions.findIndex(
                                  (position) =>
                                    position.locationId === location.id &&
                                    position.id === pos.id,
                                ) +
                                '].amount'
                              }
                            />
                          </DifferenceWrapper>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            fullWidth={true}
                            disabled={
                              !data.positions[
                                data.positions.findIndex(
                                  (position) =>
                                    position.locationId === location.id &&
                                    position.id === pos.id,
                                )
                              ].checked
                            }
                            component={TextField}
                            margin="dense"
                            size={'small'}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            inputProps={{
                              inputComponent: NumberFormatCustom,
                              decimalScale: 2,
                              allowNegative: false,
                              suffix: ' \u20AC',
                              fixedDecimalScale: true,
                            }}
                            label={t('REQUEST.PRICE_PER_PIECE')}
                            variant="outlined"
                            name={
                              'positions[' +
                              data.positions.findIndex(
                                (position) =>
                                  position.locationId === location.id &&
                                  position.id === pos.id,
                              ) +
                              '].price'
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item xs={11}>
                              <DifferenceWrapper
                                showDifference={
                                  differences.filter(
                                    (diffPos) => diffPos.index !== false,
                                  ).length > 0
                                }
                                difference={
                                  differences.find(
                                    (diffPos) =>
                                      parseInt(diffPos.id) ===
                                        parseInt(pos.id) &&
                                      parseInt(diffPos.locationId) ===
                                        parseInt(pos.locationId),
                                  ).index
                                }
                                differenceDisplay={
                                  differences.find(
                                    (diffPos) =>
                                      parseInt(diffPos.id) ===
                                        parseInt(pos.id) &&
                                      parseInt(diffPos.locationId) ===
                                        parseInt(pos.locationId),
                                  ).index
                                }
                                diffObjectType={diffObjectType}
                              >
                                <Field
                                  component={TextField}
                                  name={
                                    'positions[' +
                                    data.positions.findIndex(
                                      (position) =>
                                        position.locationId === location.id &&
                                        position.id === pos.id,
                                    ) +
                                    '].index'
                                  }
                                  select
                                  fullWidth
                                  label={t('REQUEST.REFERENCE_INDEX')}
                                  variant="outlined"
                                  margin="dense"
                                >
                                  <MenuItem value={null} key={null}>
                                    {t('REQUEST.NO_REFERENCE_INDEX')}
                                  </MenuItem>
                                  {getIndexesOfPosition(pos).map(
                                    (identifier) => (
                                      <MenuItem
                                        value={identifier}
                                        key={identifier}
                                      >
                                        {indexLookup[identifier]}
                                      </MenuItem>
                                    ),
                                  )}
                                </Field>
                              </DifferenceWrapper>
                            </Grid>
                            <Grid item xs={1}>
                              <Tooltip
                                title={t('REQUEST.REFERENCE_INDEX_TOOLTIP')}
                              >
                                <Info color={'info'} fontSize="medium" />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                        {authorize('administrator') && (
                          <Fragment>
                            <Grid item xs={12}>
                              <Field
                                fullWidth={true}
                                disabled={
                                  !data.positions[
                                    data.positions.findIndex(
                                      (position) =>
                                        position.locationId === location.id &&
                                        position.id === pos.id,
                                    )
                                  ].checked
                                }
                                component={TextField}
                                margin="dense"
                                size={'small'}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                inputProps={{
                                  inputComponent: NumberFormatCustom,
                                  decimalScale: 2,
                                  allowNegative: false,
                                  suffix: ' \u20AC',
                                  fixedDecimalScale: true,
                                }}
                                label={t('OFFER.PURCHASE_PRICE')}
                                variant="outlined"
                                name={
                                  'positions[' +
                                  data.positions.findIndex(
                                    (position) =>
                                      position.locationId === location.id &&
                                      position.id === pos.id,
                                  ) +
                                  '].purchasePrice'
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                fullWidth={true}
                                disabled={
                                  !data.positions[
                                    data.positions.findIndex(
                                      (position) =>
                                        position.locationId === location.id &&
                                        position.id === pos.id,
                                    )
                                  ].checked
                                }
                                component={TextField}
                                margin="dense"
                                size={'small'}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                inputProps={{
                                  inputComponent: NumberFormatCustom,
                                  decimalScale: 2,
                                  allowNegative: false,
                                  suffix: ' \u20AC',
                                  fixedDecimalScale: true,
                                }}
                                label={t('OFFER.SALES_PRICE')}
                                variant="outlined"
                                name={
                                  'positions[' +
                                  data.positions.findIndex(
                                    (position) =>
                                      position.locationId === location.id &&
                                      position.id === pos.id,
                                  ) +
                                  '].salesPrice'
                                }
                              />
                            </Grid>
                          </Fragment>
                        )}
                      </Grid>
                    </PositionCard>
                  </Grid>
                ))}
            </Grid>
          </Section>
        ),
      )}
    </Fragment>
  )
}
export default withTranslation()(OfferTenderPositions)
