import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { alertActions } from '../../_actions/alertActions'
import NumberFormatCustom from '../../_helpers/numberFormatCustom'
import { offersService } from '../../_services/offersService'
import { requestsService } from '../../_services/requestsService'
import { Loading } from '../Loading'
import PositionCard from '../PositionCard'

const CounterOfferTargetPriceDialog = (props) => {
  const { t, requestId, show, hide, forceUpdate, dispatch } = props
  const [selectedPosition, setSelectedPosition] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    id: undefined,
    selectedPartnerLocationId: '',
    type: 'REQUEST',
    state: 'DRAFT',
    generalInformation: {
      deliveryDetails: {
        incoTerm: 'DDP',
        locations: [
          {
            id: 1,
            company: '',
            address: '',
            zipcode: '',
            place: '',
            countryId: 80,
            deliveryInformations: '',
            positions: [],
            unload: 'ANY',
            stackHeightUnit: 'QUANTITY',
            isDespatchAdviceRequired: false,
            despatchAdviceInstructions: '',
            deliveryTimes: [
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
            ],
          },
        ],
      },
      paymentDetails: {
        paymentTerm: JSON.parse(localStorage.getItem('user')).partner
          .allPaymentTermsAllowed
          ? '14'
          : '0',
        skonto: '0',
        skontoPeriod: '',
        additionalPaymentInformation: '',
      },
      otherDetails: {
        title: '',
        validUntil: null,
      },
    },
    positions: [],
    deliveries: [],
    attachments: [],
  })
  const userObject = JSON.parse(localStorage.getItem('user'))
  let userId = userObject.user.id

  const handleTargetPriceDelete = async (inputPosition, values) => {
    if (selectedPosition) {
      setIsLoading(true)

      try {
        let requestBody = {
          position: {
            localId: inputPosition.id,
            targetPrice: null,
          },
        }
        await requestsService.updateRequest(
          requestBody,
          requestId,
          false,
          values.generalInformation.deliveryDetails.locations.map(
            (location) => location.partnerLocationId,
          ),
        )
      } catch (e) {
        console.error('Update failed')
      }

      forceUpdate()
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (requestId) {
      setIsLoading(true)
      requestsService.getRequest(requestId).then((data) => {
        setInitialValues(data)
        setIsLoading(false)
      })
    }
  }, [t])

  const valSchema = Yup.object().shape({
    generalInformation: Yup.object().shape({
      deliveryDetails: Yup.object().shape({
        locations: Yup.array().of(
          Yup.object().shape({
            positions: Yup.array().of(
              Yup.object().shape({
                price: Yup.mixed().when('checked', (checked, schema) => {
                  return checked
                    ? Yup.number()
                        .transform((currentValue, originalValue) =>
                          originalValue === '' ? null : currentValue,
                        )
                        .nullable(true)
                        .typeError(t('GENERAL.REQUIRED'))
                        .min(0.01, t('GENERAL.GREATER_THAN_0'))
                        .test({
                          message: t('GENERAL.REQUIRED'),
                          test: function (price) {
                            return (
                              price ||
                              this.parent.salesPrice ||
                              this.parent.purchasePrice
                            )
                          },
                        })
                    : Yup.string().typeError(t('GENERAL.REQUIRED'))
                }),
              }),
            ),
          }),
        ),
      }),
    }),
  })
  return (
    <Fragment>
      {isLoading ? (
        <Loading variant={'centered'} />
      ) : (
        <Formik
          validationSchema={valSchema}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            let positions = []
            values.generalInformation.deliveryDetails.locations.forEach(
              (location) => {
                location.positions.forEach((position) => {
                  positions.push(position)
                })
              },
            )
            let requestBody = {
              requestId: values.id,
              attachments: [],
              deliveries: values.deliveries.map((delivery) => {
                return {
                  type: delivery.type,
                  value: delivery.value,
                  deliveryPositions: delivery.positions
                    .filter((dPos) => dPos.amount > 0)
                    .map((dpos) => ({
                      amount: dpos.amount,
                      offerPositionId: dpos.positionId,
                    })),
                }
              }),
              incoTerm: values.generalInformation.deliveryDetails.incoTerm,
              location:
                values.type === 'REQUEST' || values.request.type === 'ADVERT'
                  ? {
                      company:
                        values.generalInformation.deliveryDetails.locations[0]
                          .company,
                      address:
                        values.generalInformation.deliveryDetails.locations[0]
                          .address,
                      countryId:
                        values.generalInformation.deliveryDetails.locations[0]
                          .countryId,
                      deliveryInformations:
                        values.generalInformation.deliveryDetails.locations[0]
                          .deliveryInformations,
                      deliveryTimes:
                        values.generalInformation.deliveryDetails.locations[0]
                          .deliveryTimes,
                      despatchAdviceInstructions:
                        values.generalInformation.deliveryDetails.locations[0]
                          .despatchAdviceInstructions,
                      isDespatchAdviceRequired:
                        values.generalInformation.deliveryDetails.locations[0]
                          .isDespatchAdviceRequired,
                      place:
                        values.generalInformation.deliveryDetails.locations[0]
                          .place,
                      stackHeight:
                        values.generalInformation.deliveryDetails.locations[0]
                          .stackHeight,
                      stackHeightUnit:
                        values.generalInformation.deliveryDetails.locations[0]
                          .stackHeightUnit,
                      unload:
                        values.generalInformation.deliveryDetails.locations[0]
                          .unload,
                      zipcode:
                        values.generalInformation.deliveryDetails.locations[0]
                          .zipcode,
                    }
                  : undefined,
              internalNote: null,
              partnerId: values.partnerId,
              paymentTerms:
                values.generalInformation.paymentDetails.paymentTerm,
              skonto: values.generalInformation.paymentDetails.skonto,
              skontoPeriod:
                values.generalInformation.paymentDetails.skontoPeriod,
              userId: userId,
              validUntil: moment().add(7, 'days'),
              positions: positions
                .filter((position) => position.checked)
                .map((position) => {
                  return {
                    id: position.id,
                    amount: position.amount,
                    positionProperties: position.positionProperties,
                    price: position.price,
                    locationId: position.locationId,
                    productUnitId: position.productUnitId,
                  }
                }),
            }
            setSubmitting(true)
            await requestsService.updateRequest(
              { state: 'PUBLISHED' },
              values.id,
              false,
              values.generalInformation.deliveryDetails.locations.map(
                (location) => location.partnerLocationId,
              ),
            )
            offersService
              .createCounterOffersOfRequest(values.id, requestBody)
              .then((res) => {
                hide()

                dispatch(alertActions.info(t('OFFER.COUNTEROFFER_CREATED')))
                setTimeout(() => {
                  dispatch(alertActions.clear())
                }, alertActions.alertTimeout)
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                setSubmitting(false)
                forceUpdate()
              })
          }}
        >
          {({
            isSubmitting,
            values,
            setFieldValue,
            errors,
            submitForm,
            setErrors,
            setTouched,
          }) => (
            <Form>
              <Dialog open={show} onClose={hide}>
                <DialogTitle>{t('COUNTEROFFER_TARGETPRICE')}</DialogTitle>
                <DialogContent>
                  <Alert severity="info">
                    {t('COUNTEROFFER_TARGETPRICE_INFO')}
                  </Alert>
                </DialogContent>
                <DialogContent>
                  {values.generalInformation.deliveryDetails.locations.map(
                    (location, locationIndex) => (
                      <Fragment>
                        <Typography variant={'caption'}>
                          {`${t('COUNTEROFFER_TAGRETPRICE_LOCATION')}: ${
                            location.address +
                            ',' +
                            location.zipcode +
                            ',' +
                            location.place
                          }`}
                          setSelectedPosition
                        </Typography>
                        {location.positions.map((position, positionIndex) => (
                          <PositionCard
                            data={position}
                            readOnly={true}
                            showAmount={true}
                            showImage={true}
                            showProductDetails={true}
                            imageSize={'small'}
                          >
                            {setSelectedPosition(position)}
                            <Grid item xs={12} sm={6}>
                              <Field
                                component={CheckboxWithLabel}
                                type="checkbox"
                                checked={position.checked}
                                name={`generalInformation.deliveryDetails.locations[${locationIndex}].positions[${positionIndex}].checked`}
                                margin="dense"
                                Label={{
                                  label: t('COUNTEROFFER_TAGRETPRICE_POSITION'),
                                }}
                                onChange={() =>
                                  setFieldValue(
                                    `generalInformation.deliveryDetails.locations[${locationIndex}].positions[${positionIndex}].checked`,
                                    !position.checked,
                                  )
                                }
                              />
                              <Field
                                fullWidth={true}
                                component={TextField}
                                margin="dense"
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                }}
                                inputProps={{
                                  inputComponent: NumberFormatCustom,
                                  decimalScale: 2,
                                  allowNegative: false,
                                  suffix: ' \u20AC',
                                  fixedDecimalScale: true,
                                }}
                                label={t('COUNTEROFFER_TARGETPRICE_UNIT')}
                                variant="outlined"
                                name={`generalInformation.deliveryDetails.locations[${locationIndex}].positions[${positionIndex}].price`}
                              />
                            </Grid>
                          </PositionCard>
                        ))}
                      </Fragment>
                    ),
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    disabled={isSubmitting}
                    onClick={() =>
                      handleTargetPriceDelete(selectedPosition, values)
                    }
                    variant="contained"
                    color="secondary"
                  >
                    {t('COUNTEROFFER_TAGRETPRICE.DELETE')}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      hide()
                    }}
                  >
                    {t('COUNTEROFFER_TAGRETPRICE.CANCEL')}
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    onClick={() => submitForm()}
                    variant="contained"
                    color="secondary"
                  >
                    {t('COUNTEROFFER_TAGRETPRICE.SAVE')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Form>
          )}
        </Formik>
      )}
    </Fragment>
  )
}

export default withTranslation()(connect()(CounterOfferTargetPriceDialog))
