import config from '../_config/config.js'
import { authHeader } from '../_helpers/auth-header'

export const offerAttachmentsService = {
  getOfferAttachment,
  deleteOfferAttachment,
  createAttachmentOfOffer,
}

function getOfferAttachment(attachment) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/offer-attachments/` + attachment.id,
    requestOptions,
  )
}

function deleteOfferAttachment(attachmentId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/offer-attachments/${attachmentId}`,
    requestOptions,
  )
}

function createAttachmentOfOffer(offerId, requestBody) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: requestBody,
  }

  return fetch(
    `${config.apiUrlBase}/offers/` + offerId + '/offer-attachments',
    requestOptions,
  )
}

export * from './offerAttachmentsService'
