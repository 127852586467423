import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'

const AgreementDialog = (props) => {
  const {
    t,
    open,
    handleClose,
    agree,
    message,
    acceptButtonText,
    declineButtonText,
    title,
  } = props
  return (
    <Dialog open={open}>
      <DialogTitle>{title ? title : null}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message ? message : t('GENERAL.CONFIRMATION_REQUEST')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="secondary">
          {declineButtonText ? declineButtonText : t('GENERAL.CANCEL')}
        </Button>
        <Button variant="contained" onClick={agree} color="secondary">
          {acceptButtonText ? acceptButtonText : t('GENERAL.SUBMIT')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(AgreementDialog)
