import { Add, Delete, Edit } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { _applicationService } from '../../../../_services/_applicationService'
import { productUnitsService } from '../../../../_services/productUnitsService'
import AgreementDialog from '../../../AgreementDialog'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { Loading } from '../../../Loading'

const ProductUnitEditor = (props) => {
  const { t } = props

  const [isLoading, setIsLoading] = useState(true)
  const [productUnits, setProductUnits] = useState([])

  const [selectedItem, setSelectedItem] = useState(null)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)

  const valSchema = Yup.object().shape({
    name: Yup.string()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    type: Yup.string()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    renderPosition: Yup.number()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED'))
      .min(0),
  })

  useEffect(() => {
    setIsLoading(true)
    _applicationService.getProductUnitsForProductEditor().then((data) => {
      setProductUnits(data)
      setIsLoading(false)
    })
  }, [forceUpdateCount])
  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Fragment>
        <Button
          size="medium"
          color={'secondary'}
          variant={'contained'}
          startIcon={<Add />}
          onClick={() => {
            setSelectedItem(null)
            setShowCreateDialog(true)
          }}
        >
          {t('PRODUCT_EDITOR.ADD_PRODUCT_UNIT')}
        </Button>
        <List sx={{ width: '40%' }}>
          {productUnits.map((productUnit) => (
            <ListItem
              secondaryAction={
                <Fragment>
                  <IconButton
                    onClick={() => {
                      setSelectedItem(productUnit)
                      setShowCreateDialog(true)
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setSelectedItem(productUnit)
                      setShowDeleteDialog(true)
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Fragment>
              }
            >
              <ListItemText primary={productUnit.name}></ListItemText>
            </ListItem>
          ))}
        </List>
        <AgreementDialog
          open={showDeleteDialog}
          message={t('PRODUCT_EDITOR.DELETE_MESSAGE_UNIT')}
          acceptButtonText={t('GENERAL.CONFIRM')}
          handleClose={() => {
            setShowDeleteDialog(false)
            setSelectedItem(null)
          }}
          agree={() => {
            setIsLoading(true)
            setShowDeleteDialog(false)
            productUnitsService
              .deleteProductUnit(selectedItem.id)
              .then(() => {
                forceUpdate(forceUpdateCount + 1)
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                setIsLoading(false)
                setSelectedItem(null)
              })
          }}
        />
        <Formik
          enableReinitialize={true}
          initialValues={
            selectedItem
              ? {
                  id: selectedItem.id,
                  name: selectedItem.name,
                  type: selectedItem.type,
                  isTenderUnit: selectedItem.isTenderUnit,
                  deliveryProductUnitId: selectedItem.deliveryProductUnitId,
                  productUnitLocalizations:
                    selectedItem.productUnitLocalizations,
                  renderPosition: selectedItem.renderPosition,
                }
              : {
                  name: '',
                  type: 'INTEGER',
                  isTenderUnit: false,
                  deliveryProductUnitId: null,
                  renderPosition: 0,
                  productUnitLocalizations: [
                    { language: 'de', value: '' },
                    { language: 'en', value: '' },
                    { language: 'pl', value: '' },
                    { language: 'cs', value: '' },
                    { language: 'bg', value: '' },
                    { language: 'da', value: '' },
                    { language: 'et', value: '' },
                    { language: 'fi', value: '' },
                    { language: 'fr', value: '' },
                    { language: 'el', value: '' },
                    { language: 'it', value: '' },
                    { language: 'lt', value: '' },
                    { language: 'lv', value: '' },
                    { language: 'nl', value: '' },
                    { language: 'pt-PT', value: '' },
                    { language: 'ro', value: '' },
                    { language: 'sv', value: '' },
                    { language: 'sk', value: '' },
                    { language: 'sl', value: '' },
                    { language: 'es', value: '' },
                    { language: 'uk', value: '' },
                    { language: 'hu', value: '' },
                  ],
                }
          }
          validationSchema={valSchema}
          //validateOnBlur={false}
          onSubmit={(values, { setSubmitting }) => {
            if (selectedItem) {
              productUnitsService
                .updateProductUnit(selectedItem.id, values)
                .then((data) => {
                  forceUpdate(forceUpdateCount + 1)
                  setShowCreateDialog(false)
                })
                .catch(function (error) {
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                })
            } else {
              productUnitsService
                .createProductUnit(values)
                .then((data) => {
                  forceUpdate(forceUpdateCount + 1)
                  setShowCreateDialog(false)
                })
                .catch(function (error) {
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                })
            }
          }}
        >
          {({ values, isSubmitting, submitForm, isValid }) => (
            <Dialog fullWidth={true} maxWidth="md" open={showCreateDialog}>
              <CustomDialogTitle
                title={t('PRODUCT_EDITOR.CREATE_PRODUCT_UNIT')}
                onClose={() => {
                  setSelectedItem(null)
                  setShowCreateDialog(false)
                }}
              />
              <DialogContent>
                <Form>
                  <Field
                    component={TextField}
                    fullWidth
                    margin="dense"
                    label={t('PRODUCT_EDITOR.PRODUCT_UNIT_NAME')}
                    variant="outlined"
                    name="name"
                  />
                  <Field
                    component={TextField}
                    select
                    name="type"
                    label={t('PRODUCT_EDITOR.PRODUCT_UNIT_TYPE')}
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                  >
                    <MenuItem key={'INTEGER'} value={'INTEGER'}>
                      {'INTEGER'}
                    </MenuItem>
                    <MenuItem key={'FLOAT'} value={'FLOAT'}>
                      {'FLOAT'}
                    </MenuItem>
                  </Field>
                  <Field
                    component={CheckboxWithLabel}
                    type={'checkbox'}
                    checked={values.isTenderUnit}
                    name={'isTenderUnit'}
                    margin="dense"
                    Label={{
                      label: t('PRODUCT_EDITOR.TENDER_UNIT'),
                    }}
                  />
                  <Field
                    component={TextField}
                    select
                    name="deliveryProductUnitId"
                    label={t('PRODUCT_EDITOR.DELIVERY_UNIT_ID')}
                    variant="outlined"
                    fullWidth={true}
                    margin="dense"
                  >
                    <MenuItem key={null} value={null}>
                      {t('PRODUCT_EDITOR.NO_DELIVERY_PRODUCT_UNIT')}
                    </MenuItem>
                    {productUnits
                      .filter((productUnit) => productUnit.id !== values.id)
                      .map((deliveryProductUnit) => (
                        <MenuItem
                          key={deliveryProductUnit.id}
                          value={deliveryProductUnit.id}
                        >
                          {deliveryProductUnit.name}
                        </MenuItem>
                      ))}
                  </Field>
                  <Field
                    component={TextField}
                    fullWidth
                    margin="dense"
                    label={t('PRODUCT_EDITOR.RENDER_POSITION')}
                    variant="outlined"
                    name="renderPosition"
                    inputProps={{
                      decimalScale: 0,
                      allowNegative: false,
                    }}
                  />
                  <Divider />
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography>
                        {t('PRODUCT_EDITOR.TRANSLATIONS')}
                      </Typography>
                    </Grid>
                    {values.productUnitLocalizations.map(
                      (localizations, index) => (
                        <Grid item xs={3}>
                          <Field
                            component={TextField}
                            fullWidth
                            margin="dense"
                            label={localizations.language}
                            variant="outlined"
                            name={`productUnitLocalizations[${index}].value`}
                          />
                        </Grid>
                      ),
                    )}
                  </Grid>
                </Form>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setShowCreateDialog(false)}
                  variant="outlined"
                  color={'secondary'}
                >
                  {t('GENERAL.CANCEL')}
                </Button>
                <Button
                  onClick={() => submitForm()}
                  disabled={!isValid || isSubmitting}
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  {t('GENERAL.CONFIRM')}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </Formik>
      </Fragment>
    )
  )
}

export default withTranslation()(ProductUnitEditor)
