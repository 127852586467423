import { Grid, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Section } from '../../../Section'

const JobOfferPaymentDetailsView = (props) => {
  const { t, data } = props

  return (
    <Section header={t('REQUEST.PAYMENT_DETAILS.HEADING')} hideBorder={true}>
      <Grid container spacing={1}>
        <Grid item container xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant={'subtitle1'}>
              {t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM') + ':'}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            {data.skonto !== 0 ? (
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {`${data.skontoPeriod} ${t('OFFER.DAYS')} ${data.skonto} % ${t(
                  'REQUEST.PAYMENT_DETAILS.SKONTO',
                )}, ${data.paymentTerm} ${t('OFFER.DAYS')} ${t('GENERAL.NET')}`}
              </Typography>
            ) : (
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {`${data.paymentTerm} ${t('OFFER.DAYS')} ${t('GENERAL.NET')}`}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Section>
  )
}

export default withTranslation()(JobOfferPaymentDetailsView)
