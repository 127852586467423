import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import moment from 'moment'
import Numeral from 'numeral'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { areTenderOrderRequestsEqual } from '../../../../../../../../../../_helpers/little'
import { tenderOrderRequestsService } from '../../../../../../../../../../_services/tenderOrderRequestsService'
import AgreementDialog from '../../../../../../../../../AgreementDialog'
import Deliveries from '../../../../../../../../../Deliveries'
import { Loading } from '../../../../../../../../../Loading'
import PositionCard from '../../../../../../../../../PositionCard'
import { Section } from '../../../../../../../../../Section'

const TenderOrderNegotationForm = (props) => {
  const {
    isEditing,
    isNewestTenderOrderRequest,
    startCreateCounterTenderOrderRequest,
    cancelCreateCounterTenderOrderRequest,
    t,
    counterTenderOrderRequest,
    tenderOrder,
    tenderOrderRequest,
  } = props

  const [isLoading, setIsLoading] = React.useState(false)

  const [
    showDeleteTenderOrderRequestDialog,
    setShowDeleteTenderOrderRequestDialog,
  ] = React.useState(false)
  const [
    showAcceptTenderOrderRequestDialog,
    setShowAcceptTenderOrderRequestDialog,
  ] = React.useState(false)

  const userObject = JSON.parse(localStorage.getItem('user'))

  const valSchema = Yup.object().shape({
    orderNumber: Yup.string().nullable(),
    deliveries: Yup.array()
      .of(
        Yup.object().shape({
          type: Yup.string().required(t('GENERAL.REQUIRED')),
          value: Yup.string(),
          positions: Yup.array()
            .of(
              Yup.object().shape({
                amount: Yup.number(),
              }),
            )
            .compact(function (v) {
              return v.amount === ''
            })
            .min(1, t('GENERAL.MIN_1')),
        }),
      )
      .min(1, t('GENERAL.MIN_1')),
  })

  return tenderOrder
    ? (isLoading && <Loading variant={'centered'} />) || (
        <Fragment>
          <Formik
            enableReinitialize={true}
            initialValues={{
              deliveries: tenderOrderRequest.deliveries.map((delivery) => {
                return {
                  ...delivery,
                  value: moment(delivery.value),
                }
              }),
            }}
            validationSchema={valSchema}
            //validateOnBlur={false}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting = true
              setIsLoading(true)
              var payLoad = values
              payLoad.locationId = tenderOrderRequest.location.id

              payLoad.deliveries = payLoad.deliveries.map((delivery) => {
                return {
                  ...delivery,
                  deliveryPositions: delivery.positions.map((dpos) => {
                    return {
                      amount: dpos.amount,
                      tenderOrderPositionId: dpos.positionId,
                    }
                  }),
                }
              })
              tenderOrderRequestsService
                .renegotiateTenderOrderRequest(
                  payLoad,
                  userObject.partner.id,
                  tenderOrderRequest.id,
                )
                .then(() => {
                  setSubmitting = false
                  window.location.reload()
                })
                .catch(function (error) {
                  setSubmitting = false
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                })
            }}
          >
            {({
              isSubmitting,
              isValidating,
              isValid,
              values,
              setFieldValue,
              setFieldError,
              setFieldTouched,
              errors,
              handleBlur,
              submitForm,
            }) => (
              <Form>
                <Fragment>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Section
                        header={t('TENDER_ORDERS.CONTRACT_POSITIONS_OVERVIEW')}
                      >
                        <Grid container spacing={1}>
                          {tenderOrder.generalInformation.deliveryDetails.locations
                            .find((location) => {
                              return (
                                location.id === tenderOrderRequest.location.id
                              )
                            })
                            .positions.map((tenderOrderPosition) => (
                              <Grid item xs={6}>
                                <PositionCard
                                  data={tenderOrderPosition}
                                  readOnly={true}
                                  showProductDetails={true}
                                  showImage={true}
                                  imageSize={'small'}
                                >
                                  {
                                    <Typography>
                                      {`${t(
                                        'TENDER_ORDERS.ORDERED_AMOUNT',
                                      )}: ${Numeral(
                                        tenderOrderPosition.orderedAmount,
                                      ).format('0,0')}`}
                                    </Typography>
                                  }
                                  {
                                    <Typography>
                                      {`${t(
                                        'TENDER_ORDERS.REQUESTED_AMOUNT',
                                      )}: ${Numeral(
                                        tenderOrderPosition.requestedAmount,
                                      ).format('0,0')}`}
                                    </Typography>
                                  }
                                  {
                                    <Typography>
                                      {`${t(
                                        'TENDER_ORDERS.TOTAL_AMOUNT',
                                      )}: ${Numeral(
                                        tenderOrderPosition.amount,
                                      ).format('0,0')}`}
                                    </Typography>
                                  }
                                </PositionCard>
                              </Grid>
                            ))}
                        </Grid>
                      </Section>
                    </Grid>

                    <Grid item xs={12}>
                      <Deliveries
                        data={{
                          ...values,
                          generalInformation: tenderOrder.generalInformation,
                          positions:
                            tenderOrder.generalInformation.deliveryDetails.locations.find(
                              (location) => {
                                return (
                                  location.id === tenderOrderRequest.location.id
                                )
                              },
                            ).positions,
                        }}
                        readOnly={!isEditing}
                        setFieldValue={setFieldValue}
                        positions={
                          tenderOrder.generalInformation.deliveryDetails.locations.find(
                            (location) => {
                              return (
                                location.id === tenderOrderRequest.location.id
                              )
                            },
                          ).positions
                        }
                        errors={errors}
                        type={'TENDER_ORDER'}
                      />
                    </Grid>
                  </Grid>
                </Fragment>
                {isNewestTenderOrderRequest ? (
                  !isEditing ? (
                    <Fragment>
                      {(tenderOrderRequest.state ===
                        'WAITING_FOR_MANUFACTURER' &&
                        tenderOrder.manufacturerId === userObject.partner.id) ||
                      (tenderOrderRequest.state === 'WAITING_FOR_CUSTOMER' &&
                        tenderOrder.customerId === userObject.partner.id) ? (
                        <Fragment>
                          <Tooltip
                            title={t(
                              'TENDER_ORDER.ACCEPT_TENDER_ORDER_REQUEST_TOOLTIP',
                            )}
                          >
                            <Button
                              type="button"
                              onClick={() => {
                                setShowAcceptTenderOrderRequestDialog(true)
                              }}
                              variant="contained"
                              color="secondary"
                              style={{ margin: '0 10px 10px 15px' }}
                            >
                              {t('TENDER_ORDER.ACCEPT_TENDER_ORDER_REQUEST')}
                            </Button>
                          </Tooltip>
                        </Fragment>
                      ) : tenderOrderRequest.state !== 'ARCHIVED' ? (
                        <Button
                          onClick={() => {
                            setShowDeleteTenderOrderRequestDialog(true)
                          }}
                          variant="outlined"
                          color={'error'}
                          style={{ margin: '0 10px 10px 15px' }}
                        >
                          {t('TENDER_ORDER.DELETE_TENDER_ORDER_REQUEST')}
                        </Button>
                      ) : null}
                      {tenderOrderRequest.state === 'ARCHIVED' ||
                      (tenderOrderRequest.state ===
                        'WAITING_FOR_MANUFACTURER' &&
                        tenderOrder.manufacturerId === userObject.partner.id) ||
                      (tenderOrderRequest.state === 'WAITING_FOR_CUSTOMER' &&
                        tenderOrder.customerId === userObject.partner.id) ? (
                        <Button
                          type="button"
                          onClick={() => {
                            startCreateCounterTenderOrderRequest()
                          }}
                          variant="outlined"
                          color="secondary"
                          style={{ margin: '0 10px 10px 15px' }}
                        >
                          {t(
                            'TENDER_ORDER.CREATE_COUNTER_TENDER_ORDER_REQUEST',
                          )}
                        </Button>
                      ) : null}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Button
                        type="button"
                        onClick={() => {
                          submitForm()
                        }}
                        disabled={
                          areTenderOrderRequestsEqual(
                            values,
                            counterTenderOrderRequest,
                            tenderOrderRequest,
                          ) || isSubmitting
                        }
                        variant="contained"
                        color="secondary"
                        style={{ margin: '0 10px 10px 15px' }}
                      >
                        {t('OFFERS.SEND_OFFER')}
                      </Button>
                      <Button
                        type="button"
                        onClick={() => {
                          setFieldValue(
                            'deliveries',
                            tenderOrderRequest.deliveries.map((delivery) => {
                              return {
                                ...delivery,
                                value: moment(delivery.value),
                              }
                            }),
                          )
                          cancelCreateCounterTenderOrderRequest()
                        }}
                        variant="outlined"
                        color={'secondary'}
                        disabled={isSubmitting}
                        style={{ margin: '0 10px 10px 15px' }}
                      >
                        {t('OFFERS.CANCEL')}
                      </Button>
                    </Fragment>
                  )
                ) : null}
                <AgreementDialog
                  open={showDeleteTenderOrderRequestDialog}
                  handleClose={() => {
                    setShowDeleteTenderOrderRequestDialog(false)
                  }}
                  agree={() => {
                    setShowDeleteTenderOrderRequestDialog(false)
                    tenderOrderRequestsService
                      .deleteTenderOrderRequest(tenderOrderRequest.id)
                      .then(() => window.location.reload())
                  }}
                />
              </Form>
            )}
          </Formik>
          <Formik
            enableReinitialize={true}
            initialValues={{ orderNumber: '' }}
            validationSchema={Yup.object().shape({
              orderNumber: Yup.string(),
            })}
            validateOnBlur={true}
            validateOnChange={true}
          >
            {({
              values,
              isValid,
              setSubmitting,
              setFieldValue,
              isSubmitting,
            }) => (
              <Dialog open={showAcceptTenderOrderRequestDialog}>
                <DialogTitle>
                  {t('OFFER.ACCEPT_TENDER_ORDER_REQUEST_DIALOG_TITLE')}
                </DialogTitle>
                <DialogContent>
                  {t('OFFER.TENDER_ORDER_REQUEST_DIALOG_TEXT')}
                </DialogContent>
                <DialogActions>
                  <Button
                    color={'secondary'}
                    variant={'outlined'}
                    onClick={() => {
                      setShowAcceptTenderOrderRequestDialog(false)
                    }}
                  >
                    {t('GENERAL.CANCEL')}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    onClick={() => {
                      setSubmitting = true
                      setIsLoading(true)
                      tenderOrderRequestsService
                        .confirmTenderOrderRequest(tenderOrderRequest.id)
                        .then(() => window.location.reload())
                        .finally(() => {
                          setSubmitting = false
                          setIsLoading(false)
                        })
                    }}
                  >
                    {t('GENERAL.SUBMIT')}
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Formik>
        </Fragment>
      )
    : null
}

export default withTranslation()(TenderOrderNegotationForm)
