import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'
import { generateFilterValuesQueryString } from '../_helpers/little.js'

export const jobOffersService = {
  getJobOffer,
  confirmJobOffer,
  declineJobOffer,
  getJobOffers,
  createJobOffer,
  getJobOfferOfRequest,
}
function getJobOffer(jobOfferId, token) {
  var requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${config.apiUrlBase}/job-offers/${jobOfferId}?token=${token}`,
    requestOptions,
  ).then(handleResponse)
}

function confirmJobOffer(jobOfferId, requestBody, token) {
  var requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }

  return fetch(
    token
      ? `${config.apiUrlBase}/job-offers/${jobOfferId}/confirm?token=${token}`
      : `${config.apiUrlBase}/job-offers/${jobOfferId}/confirm`,
    requestOptions,
  ).then(handleResponse)
}

function createJobOffer(requestBody) {
  var requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }

  return fetch(`${config.apiUrlBase}/job-offers`, requestOptions).then(
    handleResponse,
  )
}

function declineJobOffer(jobOfferId, token) {
  var requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    token
      ? `${config.apiUrlBase}/job-offers/${jobOfferId}/decline?token=${token}`
      : `${config.apiUrlBase}/job-offers/${jobOfferId}/decline`,
    requestOptions,
  ).then(handleResponse)
}

function getJobOffers(filterValues = undefined, pagination) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  let queryString = generateFilterValuesQueryString(filterValues, pagination)

  return fetch(
    `${config.apiUrlBase}/job-offers${queryString}`,
    requestOptions,
  ).then(handleResponse)
}

function getJobOfferOfRequest(requestId) {
  var requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/requests/${requestId}/job-offer`,
    requestOptions,
  ).then(handleResponse)
}

export * from './jobOffersService'
