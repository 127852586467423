import { Business } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material'
import { Field } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryList,
  countryLookup,
} from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import NumberFormatCustom from '../../../../_helpers/numberFormatCustom'
import { partnerLocationsService } from '../../../../_services/partnerLocationsService'
import CustomDialogTitle from '../../../CustomDialogTitle'
import DeliveryTimes from '../../../DeliveryTimes'
import GoogleAutocomplete from '../../../GoogleAutocomplete'
import PartnerLocationDialog from '../../../PartnerLocationDialog'
import { Section } from '../../../Section'
const OfferDeliveryDetails = (props) => {
  const { t, offer, data, setFieldValue } = props

  const [partnerLocations, setPartnerLocations] = useState([])

  const [showSelectPartnerLocation, setShowSelectPartnerLocation] =
    useState(false)

  const [showCreatePartnerLocation, setShowCreatePartnerLocation] =
    useState(false)

  const userObject = JSON.parse(localStorage.getItem('user'))

  let offerIncoterm = offer.generalInformation.deliveryDetails.incoTerm
  let formIncoterm = data.generalInformation.deliveryDetails.incoTerm

  var ResetDeliveryDetails = () => {
    setFieldValue(
      'generalInformation.deliveryDetails.locations[0].company',
      offer.generalInformation.deliveryDetails.locations[0].company,
    )

    setFieldValue(
      'generalInformation.deliveryDetails.locations[0].countryId',
      offer.generalInformation.deliveryDetails.locations[0].countryId,
    )

    setFieldValue(
      'generalInformation.deliveryDetails.locations[0].address',
      offer.generalInformation.deliveryDetails.locations[0].address,
    )

    setFieldValue(
      'generalInformation.deliveryDetails.locations[0].zipcode',
      offer.generalInformation.deliveryDetails.locations[0].zipcode,
    )

    setFieldValue(
      'generalInformation.deliveryDetails.locations[0].place',
      offer.generalInformation.deliveryDetails.locations[0].place,
    )

    setFieldValue(
      'generalInformation.deliveryDetails.locations[0].deliveryInformations',
      offer.generalInformation.deliveryDetails.locations[0]
        .deliveryInformations,
    )

    setFieldValue(
      'generalInformation.deliveryDetails.locations[0].unload',
      offer.generalInformation.deliveryDetails.locations[0].unload,
    )

    setFieldValue(
      'generalInformation.deliveryDetails.locations[0].stackHeight',
      offer.generalInformation.deliveryDetails.locations[0].stackHeight
        ? offer.generalInformation.deliveryDetails.locations[0].stackHeight
        : '',
    )

    setFieldValue(
      'generalInformation.deliveryDetails.locations[0].stackHeightUnit',
      offer.generalInformation.deliveryDetails.locations[0].stackHeightUnit,
    )

    setFieldValue(
      'generalInformation.deliveryDetails.locations[0].deliveryTimes',
      offer.generalInformation.deliveryDetails.locations[0].deliveryTimes,
    )

    setFieldValue(
      'generalInformation.deliveryDetails.locations[0].isDespatchAdviceRequired',
      offer.generalInformation.deliveryDetails.locations[0]
        .isDespatchAdviceRequired,
    )

    setFieldValue(
      'generalInformation.deliveryDetails.locations[0].despatchAdviceInstructions',
      offer.generalInformation.deliveryDetails.locations[0]
        .despatchAdviceInstructions,
    )
  }

  const isManufacturer =
    offer.request.type === 'ADVERT'
      ? offer.request.partnerId === userObject.partner.id
      : offer.request.partnerId !== userObject.partner.id

  let isIncotermDisabled =
    offerIncoterm === 'DDP' && !isManufacturer
      ? true
      : offerIncoterm === 'EXW' && isManufacturer
      ? true
      : false

  let isNotAllowedToEditDeliveryDetails =
    formIncoterm === 'DDP' && isManufacturer
      ? true
      : formIncoterm === 'EXW' && !isManufacturer
      ? true
      : false

  useEffect(() => {
    partnerLocationsService
      .getPartnerLocationsOfUser(userObject.user.id)
      .then((partnerLocations) => {
        setPartnerLocations(partnerLocations)
      })
  }, [showCreatePartnerLocation])

  return (
    <Section header={t('REQUEST.DELIVERY_DETAILS.HEADING')}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Field
            component={TextField}
            label={t('REQUEST.DELIVERY_DETAILS.INCOTERM')}
            disabled={isIncotermDisabled}
            InputProps={{
              onChange: (e) => {
                if (offerIncoterm === e.target.value) {
                  ResetDeliveryDetails()
                }

                if (e.target.value === 'DDP' && !isManufacturer) {
                  let primaryLocation = partnerLocations.find(
                    (location) => location.primary,
                  )

                  if (!primaryLocation) {
                    primaryLocation = partnerLocations[0]
                  }

                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].company',
                    primaryLocation.company,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].countryId',
                    primaryLocation.countryId,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].address',
                    primaryLocation.address,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].zipcode',
                    primaryLocation.zipcode,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].place',
                    primaryLocation.place,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].deliveryInformations',
                    primaryLocation.deliveryInformations
                      ? primaryLocation.deliveryInformations
                      : '',
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].unload',
                    primaryLocation.unload,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].stackHeight',
                    primaryLocation.stackHeight
                      ? primaryLocation.stackHeight
                      : '',
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].stackHeightUnit',
                    primaryLocation.stackHeightUnit,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].deliveryTimes',
                    primaryLocation.deliveryTimes,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].isDespatchAdviceRequired',
                    primaryLocation.isDespatchAdviceRequired,
                  )

                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].despatchAdviceInstructions',
                    primaryLocation.despatchAdviceInstructions
                      ? primaryLocation.despatchAdviceInstructions
                      : '',
                  )
                } else if (e.target.value === 'EXW' && isManufacturer) {
                  let primaryLocation = partnerLocations.find(
                    (location) => location.primary,
                  )

                  if (!primaryLocation) {
                    primaryLocation = partnerLocations[0]
                  }

                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].company',
                    primaryLocation.company,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].countryId',
                    primaryLocation.countryId,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].address',
                    primaryLocation.address,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].zipcode',
                    primaryLocation.zipcode,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].place',
                    primaryLocation.place,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].deliveryInformations',
                    primaryLocation.deliveryInformations
                      ? primaryLocation.deliveryInformations
                      : '',
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].unload',
                    primaryLocation.unload,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].stackHeight',
                    primaryLocation.stackHeight
                      ? primaryLocation.stackHeight
                      : '',
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].stackHeightUnit',
                    primaryLocation.stackHeightUnit,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].deliveryTimes',
                    primaryLocation.deliveryTimes,
                  )
                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].isDespatchAdviceRequired',
                    primaryLocation.isDespatchAdviceRequired,
                  )

                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].despatchAdviceInstructions',
                    primaryLocation.despatchAdviceInstructions
                      ? primaryLocation.despatchAdviceInstructions
                      : '',
                  )

                  setFieldValue(
                    'generalInformation.deliveryDetails.locations[0].partnerLocationId',
                    primaryLocation.id,
                  )
                }

                setFieldValue(e.target.name, e.target.value)
              },
            }}
            name={'generalInformation.deliveryDetails.incoTerm'}
            variant="outlined"
            margin="dense"
            fullWidth
            select
          >
            <MenuItem value={'DDP'} key={'DDP'}>
              {t('REQUEST.DELIVERY_DETAILS.DELIVERED_DUTY_PAID')}
            </MenuItem>

            <MenuItem value={'EXW'} key={'EXW'}>
              {t('REQUEST.DELIVERY_DETAILS.EX_WORKS')}
            </MenuItem>
          </Field>
        </Grid>
        {!isNotAllowedToEditDeliveryDetails &&
          authorize('addPartnerLocation') && (
            <Grid item xs={12}>
              <ButtonGroup color={'secondary'} size={'small'}>
                <Button
                  onClick={() => setShowSelectPartnerLocation(true)}
                  variant={'contained'}
                >
                  {t('REQUEST.DELIVERY_DETAILS.CHOOSE_DELIVERY_ADDRESS')}
                </Button>
                <Button
                  onClick={() => setShowCreatePartnerLocation(true)}
                  variant={'outlined'}
                >
                  {t('REQUEST.ADD_LOCATION_TO_MASTER_DATA')}
                </Button>
              </ButtonGroup>
            </Grid>
          )}

        {!isNotAllowedToEditDeliveryDetails && (
          <Grid item xs={12} sm={6}>
            <Field
              component={TextField}
              label={t('REQUEST.DELIVERY_DETAILS.COMPANY')}
              name={'generalInformation.deliveryDetails.locations[0].company'}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            label={t('REQUEST.DELIVERY_DETAILS.COUNTRY')}
            name={'generalInformation.deliveryDetails.locations[0].countryId'}
            disabled={isNotAllowedToEditDeliveryDetails}
            variant="outlined"
            margin="dense"
            fullWidth
            select
          >
            {Object.entries(countryList).map((country) => (
              <MenuItem value={country[1]} key={country[0]}>
                {country[0]}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        {!isNotAllowedToEditDeliveryDetails && (
          <Grid item xs={12}>
            <GoogleAutocomplete
              setFieldValue={setFieldValue}
              formikName={
                'generalInformation.deliveryDetails.locations[0].address'
              }
              formikPath={'generalInformation.deliveryDetails.locations[0]'}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            label={t('REQUEST.DELIVERY_DETAILS.ZIP_CODE')}
            name={'generalInformation.deliveryDetails.locations[0].zipcode'}
            disabled={isNotAllowedToEditDeliveryDetails}
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            label={t('REQUEST.DELIVERY_DETAILS.PLACE')}
            name={'generalInformation.deliveryDetails.locations[0].place'}
            disabled={isNotAllowedToEditDeliveryDetails}
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="caption">
            {t('DELIVERY_DETAILS.POSSIBLE_LOADING')}
          </Typography>
          <ButtonGroup
            disabled={isNotAllowedToEditDeliveryDetails}
            color={'secondary'}
            sx={{
              height: '36.7px',
              marginTop: '8px',
            }}
            fullWidth
          >
            <Button
              variant={
                data.generalInformation.deliveryDetails.locations[0].unload ===
                'ANY'
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() =>
                setFieldValue(
                  `generalInformation.deliveryDetails.locations[0].unload`,
                  'ANY',
                )
              }
            >
              {t('DELIVERY_DETAILS.ANY_UNLOAD')}
            </Button>
            <Button
              variant={
                data.generalInformation.deliveryDetails.locations[0].unload ===
                'RAMP'
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() =>
                setFieldValue(
                  `generalInformation.deliveryDetails.locations[0].unload`,
                  'RAMP',
                )
              }
            >
              {t('DELIVERY_DETAILS.RAMP')}
            </Button>
            <Button
              variant={
                data.generalInformation.deliveryDetails.locations[0].unload ===
                'SIDE'
                  ? 'contained'
                  : 'outlined'
              }
              onClick={() =>
                setFieldValue(
                  `generalInformation.deliveryDetails.locations[0].unload`,
                  'SIDE',
                )
              }
            >
              {t('DELIVERY_DETAILS.SIDE')}
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} spacing={1}>
          <Typography variant="caption">
            {t('DELIVERY_TIMES.LOADING_TIMES')}
          </Typography>
          <DeliveryTimes
            readOnly={isNotAllowedToEditDeliveryDetails}
            data={
              data.generalInformation.deliveryDetails.locations[0].deliveryTimes
            }
            formikName={
              'generalInformation.deliveryDetails.locations[0].deliveryTimes'
            }
            setFieldValue={setFieldValue}
          />
        </Grid>
        {(data.generalInformation.deliveryDetails.locations[0].stackHeight ||
          !isNotAllowedToEditDeliveryDetails) && (
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Field
                  disabled={isNotAllowedToEditDeliveryDetails}
                  fullWidth={true}
                  component={TextField}
                  margin="dense"
                  size={'small'}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    decimalScale: 0,
                    allowNegative: false,
                  }}
                  label={`${t('DELIVERY_DETAILS.STACK_HEIGHT')} (${t(
                    'GENERAL.OPTIONAL',
                  )})`}
                  variant="outlined"
                  name={`generalInformation.deliveryDetails.locations[0].stackHeight`}
                />
              </Grid>
              <Grid item xs={6}>
                <ButtonGroup
                  disabled={isNotAllowedToEditDeliveryDetails}
                  color={'secondary'}
                  sx={{
                    height: '36.7px',
                    marginTop: '8px',
                  }}
                  fullWidth
                >
                  <Button
                    variant={
                      data.generalInformation.deliveryDetails.locations[0]
                        .stackHeightUnit === 'QUANTITY'
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() =>
                      setFieldValue(
                        `generalInformation.deliveryDetails.locations[0].stackHeightUnit`,
                        'QUANTITY',
                      )
                    }
                  >
                    {t('DELIVERY_DETAILS.PIECES')}
                  </Button>
                  <Button
                    variant={
                      data.generalInformation.deliveryDetails.locations[0]
                        .stackHeightUnit === 'CM'
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() =>
                      setFieldValue(
                        `generalInformation.deliveryDetails.locations[0].stackHeightUnit`,
                        'CM',
                      )
                    }
                  >
                    {t('DELIVERY_DETAILS.CENTIMETER')}
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        )}

        {!isNotAllowedToEditDeliveryDetails && (
          <Grid item xs={12}>
            <Typography variant="caption">
              {t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <Field
                  component={CheckboxWithLabel}
                  type={'checkbox'}
                  color="default"
                  name={
                    'generalInformation.deliveryDetails.locations[0].isDespatchAdviceRequired'
                  }
                  Label={{
                    label: t('DELIVERY_DETAILS.IS_DESPATCH_ADVICE_REQUIRED'),
                  }}
                  style={{ marginLeft: '15px' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  disabled={
                    !data.generalInformation.deliveryDetails.locations[0]
                      .isDespatchAdviceRequired
                  }
                  name={
                    'generalInformation.deliveryDetails.locations[0].despatchAdviceInstructions'
                  }
                  fullWidth
                  margin="dense"
                  label={t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
                  variant="outlined"
                  multiline
                  rows="4"
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Field
            component={TextField}
            name={
              'generalInformation.deliveryDetails.locations[0].deliveryInformations'
            }
            disabled={isNotAllowedToEditDeliveryDetails}
            fullWidth
            margin="dense"
            label={t(
              'REQUEST.DELIVERY_DETAILS.ADDITIONAL_DELIVERY_INFORMATION',
            )}
            variant="outlined"
            multiline
            rows="4"
          />
        </Grid>
      </Grid>
      {data ? (
        <PartnerLocationDialog
          hide={() => {
            setShowCreatePartnerLocation(false)
          }}
          open={showCreatePartnerLocation}
        />
      ) : null}
      <Dialog open={showSelectPartnerLocation}>
        <CustomDialogTitle
          title={t('REQUEST.DELIVERY_DETAILS.CHOOSE_DELIVERY_ADDRESS')}
          onClose={() => setShowSelectPartnerLocation(false)}
        />
        <DialogContent>
          <List>
            {partnerLocations.map((location) => (
              <ListItem>
                <ListItemButton
                  onClick={() => {
                    let selectedPartnerLocation = partnerLocations.find(
                      (loc) => loc.id === location.id,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].company',
                      selectedPartnerLocation.company,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].countryId',
                      selectedPartnerLocation.countryId,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].address',
                      selectedPartnerLocation.address,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].zipcode',
                      selectedPartnerLocation.zipcode,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].place',
                      selectedPartnerLocation.place,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].deliveryInformations',
                      selectedPartnerLocation.deliveryInformations
                        ? selectedPartnerLocation.deliveryInformations
                        : '',
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].unload',
                      selectedPartnerLocation.unload,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].stackHeight',
                      selectedPartnerLocation.stackHeight
                        ? selectedPartnerLocation.stackHeight
                        : '',
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].stackHeightUnit',
                      selectedPartnerLocation.stackHeightUnit,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].deliveryTimes',
                      selectedPartnerLocation.deliveryTimes,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].isDespatchAdviceRequired',
                      selectedPartnerLocation.isDespatchAdviceRequired,
                    )
                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].despatchAdviceInstructions',
                      selectedPartnerLocation.despatchAdviceInstructions
                        ? selectedPartnerLocation.despatchAdviceInstructions
                        : '',
                    )

                    setFieldValue(
                      'generalInformation.deliveryDetails.locations[0].partnerLocationId',
                      selectedPartnerLocation.id,
                    )

                    setShowSelectPartnerLocation(false)
                  }}
                >
                  <ListItemIcon>
                    <Business />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${location.company}, ${location.address}, ${
                      location.zipcode
                    } ${location.place}, ${countryLookup[location.countryId]}`}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant={'contained'}
            color={'secondary'}
            onClick={() => {
              setShowSelectPartnerLocation(false)
            }}
          >
            {t('GENERAL.CANCEL')}
          </Button>
        </DialogActions>
      </Dialog>
    </Section>
  )
}

export default withTranslation()(OfferDeliveryDetails)
