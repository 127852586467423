import MaterialTable from '@material-table/core'
import { Add, Delete, Search } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { communityStateLookup } from '../../../../../_constants/lookupConstants'
import { tableIcons, tableLocalization } from '../../../../../_helpers/muiTable'
import { communitiesService } from '../../../../../_services/communitiesService'
import AgreementDialog from '../../../../AgreementDialog'
import { Loading } from '../../../../Loading'
import ViewDescription from '../../../../ViewDescription'
import CommunityView from './CommunityView'

const MyCommunities = ({ t, partnerId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [communities, setCommunities] = useState(null)
  const [selectedCommunity, setSelectedCommunity] = useState(null)
  const [forceUpdateCount, forceUpdate] = React.useState(0)

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
  const [showViewCommunityDialog, setShowViewCommunityDialog] = useState(false)
  const [showDeleteCommunityDialog, setShowDeleteCommunityDialog] =
    useState(false)

  useEffect(() => {
    setIsLoading(true)
    communitiesService
      .getCommunitiesOfPartner(partnerId)
      .then((data) => {
        setCommunities(data)
      })
      .catch((error) => {
        console.error('Error fetching communities:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [partnerId, forceUpdateCount])

  return (
    <Fragment>
      <ViewDescription description={t('COMMUNITY.MYCOMMUNITIES_DESCRIPTION')} />
      {isLoading ? (
        <Loading variant={'centered'} />
      ) : (
        <Fragment>
          <div style={{ marginBottom: 10 }}>
            <Button
              size="medium"
              color={'secondary'}
              variant={'contained'}
              startIcon={<Add />}
              onClick={() => {
                setIsCreateDialogOpen(true)
              }}
            >
              {t('GENERAL.CREATE')}
            </Button>
          </div>
          {communities && communities.length > 0 ? (
            <MaterialTable
              icons={tableIcons}
              localization={tableLocalization()}
              columns={[
                { title: '#', field: 'id', defaultSort: 'desc' },
                { title: t('COMMUNITY.NAME'), field: 'name' },
                {
                  title: t('COMMUNITY.STATE'),
                  field: 'state',
                  render: (community) => (
                    <span>{communityStateLookup[community.state]}</span>
                  ),
                },
              ]}
              data={communities}
              actions={[
                (rowData) => ({
                  icon: () =>
                    rowData.state === 'ACTIVE' ? (
                      <Search color={'secondary'} />
                    ) : null,
                  tooltip: t('COMMUNITY.DETAILS'),
                  onClick: (event, rowData) => {
                    var selectedCommunity = rowData
                    setShowViewCommunityDialog(true)
                    setSelectedCommunity(selectedCommunity)
                  },
                }),
                (rowData) => ({
                  icon: () =>
                    rowData.state === 'ACTIVE' ? (
                      <Delete color={'secondary'} />
                    ) : null,
                  tooltip: t('COMMUNITY.DELETE'),
                  onClick: (event, rowData) => {
                    var selectedCommunity = rowData
                    setShowDeleteCommunityDialog(true)
                    setSelectedCommunity(selectedCommunity)
                  },
                }),
              ]}
              options={{
                toolbarButtonAlignment: 'left',
                toolbar: false,
                showTitle: false,
                search: false,
                paging: true,
                pageSize: 10,
                padding: 'dense',
                actionsColumnIndex: -1,
                filterCellStyle: {
                  maxWidth: 0,
                },
                headerStyle: {
                  fontWeight: 'bold',
                },
              }}
              style={{ boxShadow: 'none' }}
            />
          ) : (
            <p style={{ marginTop: '30px' }}>{t('COMMUNITY.NO_COMMUNITES')}</p>
          )}
          <AgreementDialog
            open={showDeleteCommunityDialog}
            message={t('COMMUNITY.DELETE')}
            acceptButtonText={t('COMMUNITY.DELETE_COMMUNITY')}
            handleClose={() => {
              setShowDeleteCommunityDialog(false)
            }}
            agree={() => {
              setIsLoading(true)
              setShowDeleteCommunityDialog(false)
              const updatedCommunity = {
                ...selectedCommunity,
                state: 'ARCHIVED',
              }
              communitiesService
                .updateCommunity(updatedCommunity.id, updatedCommunity)
                .then(() => {
                  forceUpdate(forceUpdateCount + 1)
                  setIsLoading(false)
                })
                .catch((error) => {
                  console.error('Error archiving community:', error)
                })
            }}
          />
          <Fragment>
            {showViewCommunityDialog && selectedCommunity && (
              <Dialog
                fullWidth={true}
                maxWidth="lg"
                maxheight="60vh"
                open={showViewCommunityDialog}
                onClose={() => setShowViewCommunityDialog(false)}
              >
                <CommunityView
                  communityId={selectedCommunity.id}
                  handleCloseDialog={() => setShowViewCommunityDialog(false)}
                />
              </Dialog>
            )}
          </Fragment>
        </Fragment>
      )}
      <Formik
        initialValues={{
          name: '',
          state: 'ACTIVE',
          partnerId: partnerId,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required(t('COMMUNITY.REQUIRED_NAME')),
        })}
        onSubmit={(values, { setSubmitting }) => {
          let requestBody = {
            name: values.name,
            state: 'ACTIVE',
            ownerPartnerId: partnerId,
          }
          setSubmitting(true)
          communitiesService
            .createCommunityOfPartner(requestBody)
            .then(() => {
              forceUpdate(forceUpdateCount + 1)
              setIsCreateDialogOpen(false)
            })
            .catch((error) => {
              console.error('Error creating community:', error)
            })
          setSubmitting(false)
        }}
      >
        {({ values, submitForm }) => (
          <Form>
            <Dialog
              open={isCreateDialogOpen}
              onClose={() => setIsCreateDialogOpen(false)}
            >
              <DialogTitle>{t('COMMUNITY.CREATE')}</DialogTitle>
              <DialogContent>
                <Field
                  component={TextField}
                  fullWidth
                  margin="dense"
                  label={t('COMMUNITY.NAME')}
                  variant="outlined"
                  name="name"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setIsCreateDialogOpen(false)}
                  color="secondary"
                  variant="outlined"
                >
                  {t('GENERAL.CANCEL')}
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    submitForm()
                  }}
                  color="secondary"
                  variant="contained"
                >
                  {t('GENERAL.CREATE')}
                </Button>
              </DialogActions>
            </Dialog>
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default withTranslation()(MyCommunities)
