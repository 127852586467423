import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import React, { Fragment, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { translateProductUnit } from '../../../../_helpers/little'
import { deliveryNotesService } from '../../../../_services/deliveryNotesService'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { FieldErrorWrapper } from '../../../FieldErrorWrapper'
import { Loading } from '../../../Loading'
import PositionCard from '../../../PositionCard'

const DeliveryStartedDialog = (props) => {
  const { t, deliveryNote, show, hide, forceUpdate } = props

  useEffect(() => {}, [])

  const [isLoading, setIsLoading] = React.useState(false)

  const valSchema = Yup.object().shape({
    deliveryDate: Yup.string()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    positions: Yup.array()
      .of(
        Yup.object().shape({
          amount: Yup.number()
            .required(t('GENERAL.REQUIRED'))
            .typeError(t('GENERAL.REQUIRED'))
            .min(0.01, t('GENERAL.GREATER_THAN_0')),
        }),
      )
      .min(1, t('GENERAL.MIN_1')),
  })

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Formik
        enableReinitialize={true}
        initialValues={
          deliveryNote
            ? {
                id: deliveryNote.id,
                deliveryDate: deliveryNote.delivery
                  ? moment(deliveryNote.delivery)
                  : null,
                positions: deliveryNote.positions.map(
                  (deliveryNotePosition) => {
                    return {
                      id: deliveryNotePosition.id,
                      amount: deliveryNote
                        ? deliveryNote.positions.find((position) => {
                            return position.id === deliveryNotePosition.id
                          }).amount
                        : 0,
                      orderPosition: deliveryNote.order.positions.find(
                        (orderPosition) =>
                          orderPosition.id ===
                          deliveryNotePosition.orderPositionId,
                      ),
                    }
                  },
                ),
              }
            : null
        }
        validationSchema={valSchema}
        //validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting = true
          let deliveryDate = values.deliveryDate
          deliveryDate = deliveryDate.format('YYYY-MM-DD')

          var payLoad = {
            delivery: deliveryDate,
            positions: values.positions.map((deliveryPosition) => {
              return {
                id: deliveryPosition.id,
                amount: deliveryPosition.amount,
              }
            }),
          }

          deliveryNotesService
            .updateDeliveryNote(values.id, payLoad)
            .then((data) => {
              forceUpdate()
              hide()
            })
            .catch(function (error) {
              console.log(error)
            })
            .finally(() => {
              setSubmitting = false
              setIsLoading(false)
            })
        }}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          submitForm,
          isValid,
          errors,
          touched,
        }) => (
          <Dialog fullWidth={true} maxWidth="md" open={show}>
            {!deliveryNote.delivery ? (
              deliveryNote.order.incoTerm === 'DDP' ? (
                <CustomDialogTitle
                  title={t('ORDERS.DELIVERY_NOTES.DELIVERY_STARTED')}
                  onClose={() => hide()}
                />
              ) : (
                <CustomDialogTitle
                  title={t('ORDERS.DELIVERY_NOTES.START_PICKUP')}
                  onClose={() => hide()}
                />
              )
            ) : (
              <CustomDialogTitle
                title={t('ORDERS.DELIVERY_NOTES.EDIT_DELIVERY')}
                onClose={() => hide()}
              />
            )}
            <DialogContent>
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12} sx={{ marginTop: 1 }}>
                    <FieldErrorWrapper
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          label:
                            deliveryNote.order.incoTerm === 'DDP'
                              ? t('ORDERS.DELIVERY_STARTED.DELIVERY_DATE')
                              : t('ORDERS.DELIVERY_STARTED.PICKUP_DATE'),
                          margin: 'dense',
                        },
                      }}
                      disabled={
                        values.state === 'NEGOTIATION' ||
                        values.state === 'ARCHIVED'
                      }
                      name="deliveryDate"
                      component={DatePicker}
                      inputFormat="DD/MM/YYYY"
                      error={
                        touched.deliveryDate && Boolean(errors.deliveryDate)
                      }
                      helperText={touched.deliveryDate && errors.deliveryDate}
                    />
                  </Grid>
                  {values && values.positions.length && values.deliveryDate > 0
                    ? values.positions.map((deliveryNotePosition, index) => {
                        console.log(
                          deliveryNotePosition.orderPosition.productUnit,
                        )
                        return (
                          <Fragment>
                            <Grid item xs={12} sm={6}>
                              <PositionCard
                                data={deliveryNotePosition.orderPosition}
                                readOnly={true}
                                showProductDetails={true}
                                showImage={true}
                                imageSize={'small'}
                              >
                                <Field
                                  component={TextField}
                                  label={translateProductUnit(
                                    deliveryNotePosition.orderPosition
                                      .productUnit.deliveryProductUnit
                                      ? deliveryNotePosition.orderPosition
                                          .productUnit.deliveryProductUnit
                                      : deliveryNotePosition.orderPosition
                                          .productUnit,
                                  )}
                                  name={`positions[${index}].amount`}
                                  type={'number'}
                                  min={0}
                                  variant="outlined"
                                  margin="dense"
                                  fullWidth
                                />
                              </PositionCard>
                            </Grid>
                          </Fragment>
                        )
                      })
                    : null}
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => hide()}
                variant="outlined"
                color={'secondary'}
              >
                {t('ORDERS.DELIVERY_STARTED.CANCEL')}
              </Button>
              <Button
                onClick={() => submitForm()}
                disabled={!isValid || isSubmitting}
                type="submit"
                variant="contained"
                color="secondary"
              >
                {t('ORDERS.DELIVERY_STARTED.CONFIRM')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    )
  )
}

export default withTranslation()(DeliveryStartedDialog)
