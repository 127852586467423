import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@mui/material'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { filter, maxBy } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { authorize } from '../../_helpers/authorization'
import { translateProductUnit } from '../../_helpers/little'
import { formatToPrice, round } from '../../_helpers/number-formatting'
import NumberFormatCustom from '../../_helpers/numberFormatCustom'
import { generalService } from '../../_services/generalService'
import { jobOfferPositionsService } from '../../_services/jobOfferPositionsService'
import { productPriceLimitsService } from '../../_services/productPriceLimitsService'
import PositionCard from '../PositionCard'
import { Section } from '../Section'

const JobOfferPositionsView = (props) => {
  const { t, data, forceUpdate } = props

  const [showSetPriceDialog, setShowSetPriceDialog] = useState(false)
  const [selectedPosition, setSelectedPosition] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [productPriceLimits, setProductPriceLimits] = useState([])

  useEffect(() => {
    setIsLoading(true)
    productPriceLimitsService.getProductPriceLimits().then((data) => {
      setProductPriceLimits(data)
      setIsLoading(false)
    })
  }, [])

  return (
    <Section header={t('JOB_POSITIONS_VIEW.POSITIONS')}>
      <Grid container spacing={3} direction="row" alignItems="stretch">
        {data.positions
          .filter((pos) => pos.amount > 0)
          .map((pos, index) => {
            let productUnit = data.positions[index].product.productUnits.find(
              (productUnit) => {
                return productUnit.id === data.positions[index].productUnitId
              },
            ).deliveryProductUnitId
              ? translateProductUnit(
                  data.positions[index].product.productUnits.find(
                    (productUnit) => {
                      return (
                        productUnit.id ===
                        data.positions[index].product.productUnits.find(
                          (productUnit) => {
                            return (
                              productUnit.id ===
                              data.positions[index].productUnitId
                            )
                          },
                        ).deliveryProductUnitId
                      )
                    },
                  ),
                )
              : translateProductUnit(
                  data.positions[index].product.productUnits.find(
                    (productUnit) => {
                      return (
                        productUnit.id === data.positions[index].productUnitId
                      )
                    },
                  ),
                )

            let price = `${t('OFFER.PRICE_EACH')} ${productUnit}: `

            let deliveriesWithPosition = data.deliveries.filter((delivery) =>
              delivery.deliveryPositions.some(
                (dPos) => pos.id === dPos.positionId && dPos.amount > 0,
              ),
            )

            return (
              <Grid item xs={12} sm={6}>
                <PositionCard
                  data={pos}
                  readOnly={true}
                  showAmount={true}
                  showImage={true}
                  showProductDetails={true}
                  imageSize={'small'}
                >
                  <Typography>
                    {`${price} ${numeral(parseFloat(pos.price)).format(
                      '0,0.00 $',
                    )}`}
                  </Typography>
                  {deliveriesWithPosition.map((delivery) => {
                    let dPos = delivery.deliveryPositions.find(
                      (dPos) => pos.id === dPos.positionId,
                    )
                    return (
                      <div>
                        <Typography>{`${t('REQUEST.DELIVERY')} ${
                          delivery.type === 'date'
                            ? moment(delivery.value).format('DD.MM.YYYY')
                            : `${t(
                                'ORDERS.CW',
                              )} ${generalService.convertDateToCalendarWeek(
                                delivery.value,
                              )}`
                        }: ${numeral(parseFloat(dPos.amount)).format(
                          '0,0.[00]',
                        )} ${translateProductUnit(
                          data.positions[index].product.productUnits.find(
                            (productUnit) => {
                              return (
                                productUnit.id ===
                                data.positions[index].productUnitId
                              )
                            },
                          ),
                        )}`}</Typography>
                      </div>
                    )
                  })}
                  {authorize('administrator') && (
                    <Button
                      color={'secondary'}
                      variant={'contained'}
                      onClick={() => {
                        setSelectedPosition(pos)
                        setShowSetPriceDialog(true)
                      }}
                    >
                      {t('JOB_OFFERS.SET_PRICE')}
                    </Button>
                  )}
                </PositionCard>
              </Grid>
            )
          })}
      </Grid>
      <Dialog open={showSetPriceDialog}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            price: 0,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting = true
            setIsLoading(true)

            jobOfferPositionsService
              .setSalesPrice(selectedPosition.id, values.price)
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                setSubmitting = false
                setIsLoading(false)
                forceUpdate()
              })
          }}
        >
          {({ values, submitForm, isSubmitting }) => {
            let state = selectedPosition.positionProperties.some(
              (positionProperty) =>
                [21, 44, 45, 68].includes(positionProperty.productPropertyId),
            )
              ? selectedPosition.positionProperties.find((positionProperty) =>
                  [21, 44, 45, 68].includes(positionProperty.productPropertyId),
                ).value
              : null

            let productPriceLimit = productPriceLimits.find(
              (productPriceLimit) => {
                return (
                  productPriceLimit.productId === selectedPosition.productId &&
                  productPriceLimit.state === state
                )
              },
            )

            if (productPriceLimit) {
              if (productPriceLimit.countries.length > 0) {
                const countryId = productPriceLimit.countries.find(
                  (countryId) => countryId === data.countryId,
                )

                if (countryId) {
                  const zipcodeAreas =
                    productPriceLimit.zipcodeAreas[data.countryId]

                  if (zipcodeAreas) {
                    // Find matching key
                    const allMatchingKeys = filter(
                      Object.keys(zipcodeAreas),
                      (key) => data.zipcode.startsWith(key),
                    )

                    if (allMatchingKeys.length > 0) {
                      const bestMatchingKey = maxBy(allMatchingKeys, 'length')

                      if (bestMatchingKey) {
                        productPriceLimit.minPrice =
                          zipcodeAreas[bestMatchingKey].minPrice
                        productPriceLimit.maxPrice =
                          zipcodeAreas[bestMatchingKey].maxPrice
                        productPriceLimit.backupPurchasePrice =
                          zipcodeAreas[bestMatchingKey].backupPurchasePrice
                      }
                    }

                    productPriceLimit.minPrice = round(
                      parseFloat(productPriceLimit.minPrice),
                      2,
                    )
                    productPriceLimit.maxPrice = round(
                      parseFloat(productPriceLimit.maxPrice),
                      2,
                    )

                    if (productPriceLimit.backupPurchasePrice) {
                      productPriceLimit.backupPurchasePrice = round(
                        parseFloat(productPriceLimit.backupPurchasePrice),
                        2,
                      )
                    }
                  }
                } else {
                  productPriceLimit = null
                }
              } else {
                productPriceLimit.minPrice = round(
                  parseFloat(productPriceLimit.minPrice),
                  2,
                )
                productPriceLimit.maxPrice = round(
                  parseFloat(productPriceLimit.maxPrice),
                  2,
                )

                if (productPriceLimit.backupPurchasePrice) {
                  productPriceLimit.backupPurchasePrice = round(
                    parseFloat(productPriceLimit.backupPurchasePrice),
                    2,
                  )
                }
              }
            }

            return (
              <Fragment>
                <DialogContent>
                  {productPriceLimit &&
                    (values.price < productPriceLimit.minPrice ||
                      values.price > productPriceLimit.maxPrice) && (
                      <Alert severity="warning">
                        {`${t(
                          'JOB_OFFERS.OUTSIDE_OF_MIN_MAX_PRICES',
                        )}: ${formatToPrice(
                          productPriceLimit.minPrice,
                        )} - ${formatToPrice(productPriceLimit.maxPrice)}`}
                      </Alert>
                    )}
                  <Field
                    fullWidth={true}
                    component={TextField}
                    margin="dense"
                    size={'small'}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{
                      inputComponent: NumberFormatCustom,
                      decimalScale: 2,
                      allowNegative: false,
                      suffix: ' \u20AC',
                      fixedDecimalScale: true,
                    }}
                    label={`${t('OFFER.PRICE_EACH')} ${
                      selectedPosition.product.productUnits.find(
                        (productUnit) => {
                          return (
                            productUnit.id === selectedPosition.productUnitId
                          )
                        },
                      ).deliveryProductUnitId
                        ? translateProductUnit(
                            selectedPosition.product.productUnits.find(
                              (productUnit) => {
                                return (
                                  productUnit.id ===
                                  selectedPosition.product.productUnits.find(
                                    (productUnit) => {
                                      return (
                                        productUnit.id ===
                                        selectedPosition.productUnitId
                                      )
                                    },
                                  ).deliveryProductUnitId
                                )
                              },
                            ),
                          )
                        : translateProductUnit(
                            selectedPosition.product.productUnits.find(
                              (productUnit) => {
                                return (
                                  productUnit.id ===
                                  selectedPosition.productUnitId
                                )
                              },
                            ),
                          )
                    }`}
                    variant="outlined"
                    name={`price`}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ margin: '0 10px 0px 10px' }}
                    disabled={isSubmitting}
                    onClick={() => setShowSetPriceDialog(false)}
                  >
                    {t('GENERAL.CANCEL')}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ margin: '0 10px 0px 10px' }}
                    disabled={isSubmitting}
                    onClick={() => submitForm()}
                  >
                    {t('JOB_OFFERS.SET_PRICE')}
                  </Button>
                </DialogActions>
              </Fragment>
            )
          }}
        </Formik>
      </Dialog>
    </Section>
  )
}
export default withTranslation()(JobOfferPositionsView)
