import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header.js'

export const userRolesService = {
  updateUserRoles,
}

function updateUserRoles(userId, roleIds) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({
      roleIds: roleIds,
    }),
  }

  return fetch(
    `${config.apiUrlBase}/users/${userId}/user-roles`,
    requestOptions,
  ).then(handleResponse)
}

export * from './userRolesService.js'
