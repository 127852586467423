import React, { useEffect, useRef, useState } from 'react'

// Create a hook to seperate out logic.
const useHubspotChat = (portalId) => {
  const [hasLoaded, setHasLoaded] = useState(false)
  const [isChatOpen, setIsChatOpen] = useState(false)
  const [activeConversation, setActiveConversation] = useState(false)
  const eventRef = useRef(null)

  const initializeChat = () => {
    window.hsConversationsSettings = {
      loadImmediately: false,
      inlineEmbedSelector: '#hubspot-conversations-inline-parent',
    }
    // Add event listener.
    window.hsConversationsOnReady = [
      () => {
        const userObject = JSON.parse(localStorage.getItem('user'))

        if (userObject.user.hubspotVisitorIdentificationToken) {
          window.hsConversationsSettings = {
            loadImmediately: false,
            identificationEmail: userObject.user.email
              ? userObject.user.email
              : undefined,
            identificationToken: userObject.user
              .hubspotVisitorIdentificationToken
              ? userObject.user.hubspotVisitorIdentificationToken
              : undefined,
            inlineEmbedSelector: '#hubspot-conversations-inline-parent',
          }
        } else {
          window.hsConversationsSettings = {
            loadImmediately: false,
            inlineEmbedSelector: '#hubspot-conversations-inline-parent',
          }
        }

        setHasLoaded(true)
      },
    ]

    // Create script component.
    let script = document.createElement('script')
    script.src = `//js.hs-scripts.com/${portalId}.js`
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
      window.hsConversationsOnReady = []
    }
  }

  // Subscripe to conversation events.
  useEffect(() => {
    eventRef.current = (payload) => {
      setActiveConversation(payload.conversation.conversationId)
    }

    if (hasLoaded) {
      window.HubSpotConversations.on('conversationStarted', eventRef.current)
    }

    return () => {
      window.HubSpotConversations.off('conversationStarted', eventRef.current)
    }
  }, [hasLoaded])

  useEffect(() => {
    if (isChatOpen) {
      window.HubSpotConversations.widget.load({ widgetOpen: true })
    }
  }, [isChatOpen])

  const openHandler = React.useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.open()
    }
  }, [hasLoaded])

  const closeHandler = React.useCallback(() => {
    if (hasLoaded) {
      window.HubSpotConversations.widget.close()
    }
  }, [hasLoaded])

  return {
    hasLoaded,
    activeConversation,
    openHandler,
    closeHandler,
    initializeChat,
    isChatOpen,
    setIsChatOpen,
  }
}

export default useHubspotChat
