import { Grid, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { groupOpenHours } from '../../_helpers/little'

const DeliveryTimesView = (props) => {
  const { t, data } = props
  const weekDayLabels = [
    t('DELIVERY_TIMES.MONDAY'),
    t('DELIVERY_TIMES.TUESDAY'),
    t('DELIVERY_TIMES.WEDNESDAY'),
    t('DELIVERY_TIMES.THURSDAY'),
    t('DELIVERY_TIMES.FRIDAY'),
    t('DELIVERY_TIMES.SATURDAY'),
    t('DELIVERY_TIMES.SUNDAY'),
  ]
  let deliveryTimesOutout = groupOpenHours(data)
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} style={{ alignSelf: 'center' }}>
        <Typography variant={'subtitle1'}>
          {t('DELIVERY_TIMES.LOADING_TIMES') + ':'}
        </Typography>
      </Grid>
      <Typography variant={'subtitle2'} color={'text.secondary'}>
        {`${deliveryTimesOutout} ${t('DELIVERY_TIMES.TIME')}`}
      </Typography>
    </Grid>
  )
}

export default withTranslation()(DeliveryTimesView)
