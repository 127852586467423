import MedalIcon from '@iconify/icons-mdi/medal'
import { InlineIcon } from '@iconify/react'
import { Grid } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'

export const Rank = (props) => {
  const { rank, children } = props

  const colorDict = {
    1: '#D6AF36',
    2: '#A7A7AD',
    3: '#A77044',
  }

  return (
    <Grid container spacing={0.5} alignItems="center">
      <Grid item>{children}</Grid>
      <Grid item>{rank}</Grid>
      <Grid item>
        {rank !== 'N/A' ? (
          rank < 4 ? (
            <InlineIcon
              style={{ fontSize: '16px' }}
              icon={MedalIcon}
              color={colorDict[rank]}
            />
          ) : null
        ) : null}
      </Grid>
    </Grid>
  )
}

export default withTranslation()(Rank)
