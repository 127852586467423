import {
  Attachment,
  Cancel,
  Delete,
  LocalShipping as DeliveryNoteIcon,
  Drafts,
  Edit,
  Euro,
  Groups,
} from '@mui/icons-material'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits'
import {
  Box,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import moment from 'moment'
import Numeral from 'numeral'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import { alertActions } from '../../../../../../_actions/alertActions'
import {
  categorizedProductLookup,
  countryLookup,
  deliveryTypeLookup,
  orderStateLookup,
  orderTypeLookup,
  productLookup,
  productStateLookup,
} from '../../../../../../_constants/lookupConstants'
import { authorize } from '../../../../../../_helpers/authorization'
import { generalService } from '../../../../../../_services/generalService'
import { orderConfirmationsService } from '../../../../../../_services/orderConfirmationsService'
import { ordersService } from '../../../../../../_services/ordersService'
import { partnerCommissionGroupsService } from '../../../../../../_services/partnerCommissionGroupsService'
import { partnersService } from '../../../../../../_services/partnersService'
import AgreementDialog from '../../../../../AgreementDialog'
import { CustomRating } from '../../../../../CustomRating'
import DeliveryNotes from '../../../../../DeliveryNotes'
import EditOrderDialog from '../../../../../EditOrderDialog'
import { InfoTooltip } from '../../../../../InfoTooltip'
import InvoicingDialog from '../../../../../InvoicingDialog'
import { Loading } from '../../../../../Loading'
import OrderAttachmentDialog from '../../../../../OrderAttachmentDialog'
import { PositionsList } from '../../../../../PositionsList'
import RateOrderDialog from '../../../../../RateOrderDialog'
import ResourceList from '../../../../../ResourceList/_components/ResourceList'
import Order from '../../../Order'

const OrdersOverview = (props) => {
  const { t, dispatch } = props

  const [isLoading, setIsLoading] = useState(true)

  const [partners, setPartners] = useState([])
  const [orders, setOrders] = useState([])

  const [selectedOrder, setSelectedOrder] = useState(null)
  const [showDeliveryNoteDialog, setShowDeliveryNoteDialog] = useState(false)

  const [showRateOrderDialog, setShowRateOrderDialog] = useState(false)

  const [showCancelOrderDialog, setShowCancelOrderDialog] = useState(false)

  const [showEditOrderDialog, setShowEditOrderDialog] = useState(false)

  const [showDeleteOrderDialog, setShowDeleteOrderDialog] = useState(false)

  const [showInvoicingDialog, setShowInvoicingDialog] = useState(false)

  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false)

  const [forceUpdateCount, forceUpdate] = useState(0)

  const [partnerCommissionGroups, setPartnerCommissionGroups] = useState([])

  const userObject = JSON.parse(localStorage.getItem('user'))
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    partnerCommissionGroupsService
      .getPartnerCommissionGroups()
      .then((partnerCommissioNGroups) => {
        setPartnerCommissionGroups(partnerCommissioNGroups)
      })
  }, [])

  useEffect(
    () => {
      setIsLoading(true)
      partnersService
        .getPartners()
        .then((data) => {
          setPartners(data)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)
  let defaultValueId = params.get('id')
  let defaultValueRequestId = params.get('requestId')

  return (
    <Fragment>
      {isLoading && <Loading variant={'centered'} />}
      <ResourceList
        fetchMethod={(filterValues, pagination) =>
          ordersService.getOrders(filterValues, pagination)
        }
        forceUpdateCount={forceUpdateCount}
        filterId={'ADMIN_ORDERS_OVERVIEW_V2'}
        filters={[
          {
            key: 'id.startsWith',
            label: t('ORDERS.ORDER_ID'),
            variant: 'textfield',
            defaultValue: defaultValueId,
          },
          {
            key: 'deliveryNotes.id.startsWith',
            label: t('ORDERS.DELIVERY_NOTE_ID'),
            variant: 'textfield',
          },
          {
            key: 'orderNumber.like',
            label: t('ORDER.CUSTOMER_ORDER_NUMBER'),
            variant: 'textfield',
          },
          {
            key: 'invoices.invoiceNumber.like',
            label: t('ORDERS.INVOICE_NUMBER'),
            variant: 'textfield',
          },
          {
            key: 'calendarWeek',
            label: t('ORDERS.CALENDAR_WEEK'),
            variant: 'calendarWeekPicker',
          },
          {
            key: 'requestId.startsWith',
            label: t('ORDERS.REQUEST_ID'),
            variant: 'textfield',
            defaultValue: defaultValueRequestId,
          },
          {
            key: 'customerId.startsWith',
            label: t('ORDERS.CUSTOMER'),
            variant: 'autocomplete',
            options: partners.map((partner) => partner.id),
            lookup: Object.fromEntries(
              partners.map((partner) => [
                partner.id,
                `${partner.name} (${partner.id}) - ${partner.place}`,
              ]),
            ),
          },
          {
            key: 'manufacturerId.startsWith',
            label: t('ORDERS.MANUFACTURER'),
            variant: 'autocomplete',
            options: partners.map((partner) => partner.id),
            lookup: Object.fromEntries(
              partners.map((partner) => [
                partner.id,
                `${partner.name} (${partner.id}) - ${partner.place}`,
              ]),
            ),
          },
          {
            key: 'state',
            variant: 'checkboxList',
            values: Object.getOwnPropertyNames(orderStateLookup),
            lookup: orderStateLookup,
            label: t('ORDERS.STATE'),
          },
          {
            key: 'type',
            variant: 'checkboxList',
            values: Object.getOwnPropertyNames(orderTypeLookup),
            lookup: orderTypeLookup,
            label: t('ORDERS.TYPE'),
          },
          {
            key: 'positions.productId.eq',
            variant: 'categorizedCheckboxList',
            values: categorizedProductLookup.map((category) => {
              return {
                label: category.name,
                items: category.products.map((product) => product.id),
              }
            }),
            lookup: productLookup,
            label: t('ORDERS.PRODUCT'),
          },
          {
            key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
            variant: 'checkboxList',
            values: Object.getOwnPropertyNames(productStateLookup),
            lookup: productStateLookup,
            label: t('ORDERS.PRODUCT_STATE'),
          },
          {
            key: 'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value',
            variant: 'checkboxList',
            values: Object.getOwnPropertyNames(deliveryTypeLookup),
            lookup: deliveryTypeLookup,
            label: t('ORDERS.DELIVERY_TYPE'),
          },
          {
            key: 'positions.positionProperties.productPropertyId=1&positions.positionProperties.value',
            label: t('ORDERS.PRODUCT_LENGTH'),
            variant: 'textfield',
          },
          {
            key: 'positions.positionProperties.productPropertyId=2&positions.positionProperties.value',
            label: t('ORDERS.PRODUCT_WIDTH'),
            variant: 'textfield',
          },
          {
            key: 'countryId.eq',
            variant: 'checkboxList',
            values: Object.getOwnPropertyNames(countryLookup),
            lookup: countryLookup,
            label: t('ORDERS.COUNTRY'),
          },
          {
            key: 'zipcode.startsWith',
            label: t('ORDERS.ZIPCODE'),
            variant: 'textfield',
          },
          {
            key: 'place.startsWith',
            label: t('ORDERS.PLACE'),
            variant: 'textfield',
          },
          {
            key: 'address.startsWith',
            label: t('ORDERS.ADDRESS'),
            variant: 'textfield',
          },
          {
            key: 'company.startsWith',
            label: t('ORDERS.COMPANY'),
            variant: 'textfield',
          },
          {
            key: 'isDespatchAdviceRequired.eq',
            variant: 'checkboxList',
            values: ['true', 'false'],
            lookup: {
              true: 'Ja',
              false: 'Nein',
            },
            label: t('ORDERS.IS_DESPATCH'),
          },
          {
            key: '~IGNORE~hasOpenDelivery',
            variant: 'checkboxList',
            values: [true],
            lookup: {
              true: 'Ja',
            },
            label: t('ORDERS.OPEN_DELIVERY'),
          },
          {
            key: 'manufacturer.partnerCommissionGroups.id.eq',
            label: t('PARTNER_OVERVIEW.ACCOUNT_MANAGER_MANUFACTURER'),
            variant: 'autocomplete',
            options: partnerCommissionGroups.map(
              (partnerCommissionGroup) => partnerCommissionGroup.id,
            ),
            lookup: Object.fromEntries(
              partnerCommissionGroups.map((partnerCommissionGroup) => [
                partnerCommissionGroup.id,
                `${partnerCommissionGroup.name}`,
              ]),
            ),
          },
          {
            key: 'customer.partnerCommissionGroups.id.eq',
            label: t('PARTNER_OVERVIEW.ACCOUNT_MANAGER_CUSTOMER'),
            variant: 'autocomplete',
            options: partnerCommissionGroups.map(
              (partnerCommissionGroup) => partnerCommissionGroup.id,
            ),
            lookup: Object.fromEntries(
              partnerCommissionGroups.map((partnerCommissionGroup) => [
                partnerCommissionGroup.id,
                `${partnerCommissionGroup.name}`,
              ]),
            ),
          },
        ]}
        sorting={[
          {
            key: 'id',
            default: true,
            label: t('ORDERS.ORDER_ID'),
          },
          {
            key: 'deliveryValue',
            default: false,
            label: t('ORDERS.DELIVERY_DATE'),
          },
        ]}
        listHeaderRenderFunction={(order) => (
          <Fragment>
            <div>
              {`${t('ORDERS.ORDER')}: ${order.id}`}
              {` | ${moment(order.createdAt).format('DD.MM.YYYY HH:mm:ss')}`}
            </div>
            <div>
              {orderTypeLookup[order.type]}
              {order.jobOfferId &&
                ` ${t('ORDERS.NUMBER_SHORT')}: ${order.jobOfferId}`}
              {order.request &&
                ` ${t('ORDERS.NUMBER_SHORT')}: ${order.request.id}`}
            </div>
            <Fragment>
              <div>
                {`${t('ORDER.CUSTOMER')}: ${order.customerUser.firstname} ${
                  order.customerUser.lastname
                } | ${order.customer.name} (${order.customer.id})`}
              </div>
              {order.manufacturer && (
                <div>
                  {`${t('ORDER.MANUFACTURER')}: ${
                    order.manufacturerUser.firstname
                  } ${order.manufacturerUser.lastname} | ${
                    order.manufacturer.name
                  } (${order.manufacturer.id})`}
                </div>
              )}
            </Fragment>
            <div>
              {`${order.zipcode} | ${order.place} | `}
              {`${countryLookup[order.countryId]} |  ${
                order.deliveryType === 'date'
                  ? moment(order.deliveryValue).format('DD.MM.YYYY')
                  : `${t(
                      'ORDERS.CW',
                    )} ${generalService.convertDateToCalendarWeek(
                      order.deliveryValue,
                    )}`
              }`}
            </div>
          </Fragment>
        )}
        resourceHeaderRenderFunction={(order) =>
          `${t('ORDERS.ORDER')}: ${order.id}`
        }
        headerActionRenderFunction={(order) => {
          return (
            <Fragment>
              <Stack direction={'column'}>
                <Stack direction={isMobile ? 'row-reverse' : 'row'} spacing={1}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginRight: '10px',
                    }}
                  >
                    {order.deliveryNotes.some(
                      (deliveryNote) =>
                        deliveryNote.claims && deliveryNote.claims.length > 0,
                    ) && <ProductionQuantityLimitsIcon color={'secondary'} />}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: isMobile ? 'flex-start' : 'flex-end',
                      alignItems: 'center',
                      marginRight: '10px',
                    }}
                  >
                    {order.community && (
                      <InfoTooltip title={order.community.name}>
                        <Groups color={'secondary'} />
                      </InfoTooltip>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: isMobile ? 'left' : 'right',
                      alignItems: 'center',
                    }}
                  >
                    <Chip
                      size={'small'}
                      sx={{ padding: 1 }}
                      color={
                        order.state === 'RECEIVED' || order.state === 'INVOICED'
                          ? 'success'
                          : order.state === 'CANCELED'
                          ? 'default'
                          : 'info'
                      }
                      label={orderStateLookup[order.state]}
                    />
                  </div>
                </Stack>

                <Box padding={1}>
                  {order.rating ? (
                    <InfoTooltip
                      title={
                        <table>
                          <thead>
                            <th
                              style={{
                                borderRight: 'solid 15px transparent',
                              }}
                            >
                              {t('ORDERS.RATING_TOTAL')}
                            </th>
                            <th>
                              {Numeral(
                                (order.rating.quality +
                                  order.rating.reliability) /
                                  2,
                              ).format('0,0.[00]')}
                            </th>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  borderRight: 'solid 15px transparent',
                                }}
                              >
                                {t('ORDERS.RATING_QUALITY')}
                              </td>
                              <td>
                                {Numeral(
                                  parseFloat(order.rating.quality),
                                ).format('0,0.[00]')}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  borderRight: 'solid 15px transparent',
                                }}
                              >
                                {t('ORDERS.RATING_RELIABILITY')}
                              </td>
                              <td>
                                {Numeral(
                                  parseFloat(order.rating.reliability),
                                ).format('0,0.[00]')}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      }
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'right',
                          alignItems: 'center',
                        }}
                      >
                        <CustomRating
                          size={'small'}
                          name="read-only"
                          value={
                            (order.rating.quality + order.rating.reliability) /
                            2
                          }
                          readOnly
                          precision={0.5}
                        />
                      </div>
                    </InfoTooltip>
                  ) : (
                    <div
                      style={{
                        textAlign: isMobile ? 'left' : 'right',
                      }}
                    >
                      <Typography variant={'caption'}>
                        {t('ORDERS.NOT_RATED_YET')}
                      </Typography>
                    </div>
                  )}
                </Box>
              </Stack>
            </Fragment>
          )
        }}
        contentRenderFunction={(order) => (
          <PositionsList positions={order.positions} />
        )}
        resourceRenderFunction={(order) => {
          return <Order orderId={order.id} />
        }}
        actionsRenderFunction={(order) => {
          return [
            {
              icon: <Cancel />,
              name: t('ORDERS.CANCEL'),
              hidden: order.state === 'CANCELED',
              primary: false,
              onClick: async () => {
                var data = await ordersService.getOrder(order.id)
                setSelectedOrder(data)
                setShowCancelOrderDialog(true)
              },
            },
            {
              icon: <Edit />,
              name: t('ORDERS.EDIT_ORDER'),
              primary: false,
              onClick: async () => {
                var data = await ordersService.getOrder(order.id)
                setSelectedOrder(data)
                setShowEditOrderDialog(true)
              },
            },
            {
              icon: <Delete />,
              disabled: order.state === 'RECEIVED',
              name: t('ORDERS.DELETE_ORDER'),
              primary: false,
              onClick: async () => {
                var data = await ordersService.getOrder(order.id)
                setSelectedOrder(data)
                setShowDeleteOrderDialog(true)
              },
            },

            {
              icon: <Drafts />,
              name: t('ORDERS.DOWNLOAD_ORDER_CONFIRMATION'),
              primary: false,
              onClick: () =>
                orderConfirmationsService
                  .getOrderConfirmation(order.id)
                  .then((response) => response.blob())
                  .then((blob) => {
                    // 2. Create blob link to download
                    const url = window.URL.createObjectURL(new Blob([blob]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                      'download',
                      'order-confirmation-' + order.id + '.pdf',
                    )
                    // 3. Append to html page
                    document.body.appendChild(link)
                    // 4. Force download
                    link.click()
                    // 5. Clean up and remove the link
                    link.parentNode.removeChild(link)
                  }),
            },
            {
              icon: <DeliveryNoteIcon />,
              name: t('ORDERS.SHOW_DELIVERY_NOTES_MANUFACTURER'),
              primary: true,
              onClick: async () => {
                var data = await ordersService.getOrder(order.id)
                setSelectedOrder(data)
                setShowDeliveryNoteDialog(true)
              },
            },
            {
              icon: <Euro />,
              name: t('ORDERS.INVOICING'),
              hidden: !authorize('administrator'),
              primary: false,
              onClick: async () => {
                var data = await ordersService.getOrder(order.id)
                setSelectedOrder(data)
                setShowInvoicingDialog(true)
              },
            },
            {
              icon: <Attachment />,
              name: t('ORDERS.ATTACHMENTS'),
              hidden: !authorize('administrator'),
              primary: false,
              onClick: async () => {
                var data = await ordersService.getOrder(order.id)
                setSelectedOrder(data)
                setShowAttachmentDialog(true)
              },
            },
          ]
        }}
        data={orders}
        dataIdentifier={'id'}
      />
      <AgreementDialog
        open={showDeleteOrderDialog}
        message={t('ORDERS.DELETE_ORDER_MESSAGE')}
        acceptButtonText={t('ORDERS.DELETE_ORDER')}
        handleClose={() => {
          setShowDeleteOrderDialog(false)
        }}
        agree={() => {
          setIsLoading(true)
          setShowDeleteOrderDialog(false)
          ordersService
            .deleteOrder(selectedOrder.id)
            .then((data) => {
              setSelectedOrder(null)
              forceUpdate()
            })
            .catch(function (error) {
              console.log(error)
            })
            .finally(() => {
              setIsLoading(false)
            })
        }}
      />
      {selectedOrder && (
        <AgreementDialog
          open={showCancelOrderDialog}
          message={t('ORDERS.CANCEL_ORDER_MESSAGE')}
          acceptButtonText={t('ORDERS.CANCEL_ORDER')}
          handleClose={() => setShowCancelOrderDialog(false)}
          agree={() => {
            setIsLoading(true)
            setShowCancelOrderDialog(false)
            ordersService
              .updateOrder({ state: 'CANCELED' }, selectedOrder.id)
              .then((data) => {
                console.log('update Order Canceled')
                forceUpdate(forceUpdateCount + 1)
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                setIsLoading(false)
              })
          }}
        />
      )}
      <DeliveryNotes
        order={selectedOrder}
        productLookup={productLookup}
        show={showDeliveryNoteDialog}
        hide={() => {
          setSelectedOrder(null)
          setShowDeliveryNoteDialog(false)
        }}
        key={
          selectedOrder ? selectedOrder.id + 'deliveryNotes' : 'deliveryNotes'
        }
      />

      <RateOrderDialog
        order={selectedOrder}
        show={showRateOrderDialog}
        hide={() => {
          setSelectedOrder(null)
          setShowRateOrderDialog(false)
        }}
        key={selectedOrder ? selectedOrder.id + 'rateOrder' : 'rateOrder'}
        forceUpdate={() => {
          forceUpdate(forceUpdateCount + 1)
          dispatch(alertActions.info(t('ORDER.ORDER_SUCCESSFULLY_RATED')))
          setTimeout(() => {
            dispatch(alertActions.clear())
          }, alertActions.alertTimeout)
        }}
      />

      {showEditOrderDialog && (
        <EditOrderDialog
          orderId={selectedOrder ? selectedOrder.id : null}
          show={showEditOrderDialog}
          hide={() => {
            setSelectedOrder(null)
            setShowEditOrderDialog(false)
          }}
          forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
          key={selectedOrder ? selectedOrder.id + 'editOrder' : 'editOrder'}
        />
      )}

      {showInvoicingDialog && (
        <InvoicingDialog
          order={selectedOrder}
          show={showInvoicingDialog}
          hide={() => setShowInvoicingDialog(false)}
        />
      )}

      {showAttachmentDialog && (
        <OrderAttachmentDialog
          orderId={selectedOrder.id}
          show={showAttachmentDialog}
          hide={() => setShowAttachmentDialog(false)}
          forceUpdateCount={forceUpdateCount}
          forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
        />
      )}
    </Fragment>
  )
}

export default withTranslation()(connect()(OrdersOverview))
