import { Delete, HelpOutline, Save } from '@mui/icons-material'
import { Card, Grid, IconButton, Tooltip } from '@mui/material'
import React, { Fragment } from 'react'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { alertActions } from '../../_actions/alertActions'
import config from '../../_config/config'
import { handleResponse } from '../../_helpers/api'
import { authHeader } from '../../_helpers/auth-header'
import { attachmentsService } from '../../_services/attachmentsService'
import { InfoTooltip } from '../InfoTooltip'
import { Section } from '../Section'
const dropzoneStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: '2px',
  borderRadius: '2px',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in -out',
  margin: '15px',
}

const minFileSize = 1
const maxFileSize = 10000000

const Attachments = (props) => {
  const { t, dispatch, data, setFieldValue, readOnly } = props

  return (
    <Section
      header={
        <Fragment>
          {t('REQUEST.REQUEST_ATTACHMENTS')}
          <InfoTooltip
            disableTouchListener
            disableFocusListener
            title={t('REQUEST.ATTACHMENTS_TOOLTIP')}
          >
            <IconButton
              color="default"
              style={{
                padding: 0,
                margin: '0 8px',
              }}
              size="large"
            >
              <HelpOutline />
            </IconButton>
          </InfoTooltip>
        </Fragment>
      }
    >
      {data.attachments && data.attachments.length > 0 ? (
        <Grid container spacing={1} style={{ padding: '15px' }}>
          {data.attachments.map((attachment) => (
            <Grid item xs={12} lg={3} sm={6}>
              <Card
                key={attachment.name}
                elevation={0}
                style={{ border: '1px solid #ddd' }}
              >
                <Grid container>
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="flex-end"
                    wrap="nowrap"
                    style={{ lineHeight: '27px' }}
                  >
                    <Grid
                      item
                      xs
                      zeroMinWidth
                      style={{
                        padding: '0 5px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <b>{attachment.name}</b>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        textAlign: 'center',
                        backgroundColor: '#ddd',
                      }}
                    >
                      <Tooltip title={t('GENERAL.DOWNLOAD')}>
                        <IconButton
                          color="default"
                          size="small"
                          disabled={attachment.new}
                          onClick={() => {
                            attachmentsService
                              .getAttachment(attachment)
                              .then((response) => response.blob())
                              .then((blob) => {
                                // 2. Create blob link to download
                                const url = window.URL.createObjectURL(
                                  new Blob([blob]),
                                )
                                const link = document.createElement('a')
                                link.href = url
                                link.setAttribute('download', attachment.name)
                                // 3. Append to html page
                                document.body.appendChild(link)
                                // 4. Force download
                                link.click()
                                // 5. Clean up and remove the link
                                link.parentNode.removeChild(link)
                              })
                          }}
                        >
                          <Save />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  {!readOnly ? (
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="flex-end"
                      wrap="nowrap"
                      style={{ lineHeight: '27px' }}
                    >
                      <Grid
                        item
                        xs={2}
                        style={{
                          textAlign: 'center',
                          backgroundColor: '#ddd',
                        }}
                      >
                        <Tooltip title={t('GENERAL.DELETE')}>
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => {
                              var files = []
                              if (attachment.new) {
                                files = data.attachments.filter((file) => {
                                  return file.name !== attachment.name
                                })
                              } else {
                                attachmentsService.deleteAttachment(attachment)
                                files = data.attachments.filter((file) => {
                                  return file.id !== attachment.id
                                })
                              }
                              setFieldValue('attachments', files)
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div style={{ textAlign: 'center', padding: '10px' }}>
          {t('REQUEST.NO_ATTACHMENTS')}
        </div>
      )}
      {!readOnly ? (
        <Dropzone
          minSize={minFileSize}
          maxSize={maxFileSize}
          accept="image/*,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.pdf,.zip,.txt"
          onDropAccepted={(files) => {
            if (data.id) {
              let fileUploadPromiseList = []
              files.forEach((attachment) => {
                var requestBody = new FormData()

                requestBody.append('file', attachment)

                var requestOptions = {
                  method: 'POST',
                  headers: { ...authHeader() },
                  body: requestBody,
                }

                var attachmentPromiseList = new Promise(function (
                  resolve,
                  reject,
                ) {
                  fetch(
                    `${config.apiUrlBase}/requests/` + data.id + '/attachments',
                    requestOptions,
                  ).then(function (response) {
                    if (response.ok) {
                      handleResponse(response).then((data) => {
                        resolve(data)
                      })
                    } else {
                      reject()
                    }
                  })
                })

                fileUploadPromiseList.push(attachmentPromiseList)
              })

              Promise.all(fileUploadPromiseList).then(function (responses) {
                Promise.resolve()
                setFieldValue(
                  'attachments',
                  data.attachments.concat(
                    responses.map((response) => response.attachment),
                  ),
                )
              })
            } else {
              files.forEach((file) => {
                file.new = true
              })

              setFieldValue('attachments', data.attachments.concat(files))
            }
          }}
          onDropRejected={(files) => {
            files.some((file) => {
              if (file.size < minFileSize || file.size > maxFileSize) {
                dispatch(
                  alertActions.error(
                    file.name + ' --> ' + t('GENERAL.ATTACHMENT_SIZE_ERROR'),
                  ),
                )
                return true
              }
              dispatch(
                alertActions.error(
                  file.name + ' --> ' + t('GENERAL.ATTACHMENT_TYPE_ERROR'),
                ),
              )
              return true
            })
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              style={dropzoneStyle}
              {...getRootProps({ className: 'dropzone' })}
            >
              <input {...getInputProps()} />
              <p>{t('REQUEST.CLICK_HERE_OR_DRAG_FILES_HERE')}</p>
            </div>
          )}
        </Dropzone>
      ) : null}
    </Section>
  )
}

export default connect()(withTranslation()(Attachments))
