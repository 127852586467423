import { Grid, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
  countryLookup,
  indexLookup,
} from '../../../../_constants/lookupConstants'
import PositionCard from '../../../PositionCard'
import { Section } from '../../../Section'

const TenderPositionsView = (props) => {
  const { t, data } = props
  return (
    <Fragment>
      <Section header={t('REQUEST.TENDER_POSITIONS')}>
        <Grid container spacing={3} direction="row" alignItems="stretch">
          {data.positions.length > 0 &&
            data.generalInformation.deliveryDetails.locations.map(
              (location, index) =>
                location.positions
                  .filter((pos) => pos.amount > 0)
                  .map((pos) => (
                    <Grid item xs={12} sm={6}>
                      <PositionCard
                        data={pos}
                        readOnly={true}
                        showAmount={true}
                        showProductDetails={true}
                        showImage={true}
                        imageSize={'small'}
                      >
                        <Fragment>
                          <Typography>{`${t('REQUEST.LOCATION')} ${
                            index + 1
                          }`}</Typography>
                          {location.company && (
                            <Typography>{`${location.company}`} </Typography>
                          )}
                          <Typography>
                            {location.address ? `${location.address}, ` : null}
                            {`${location.zipcode} ${location.place}`}
                            {location.countryId
                              ? `, ${countryLookup[location.countryId]}`
                              : null}
                          </Typography>
                          {pos.index && (
                            <Typography>{`${t('REQUEST.REFERENCE_INDEX')}: ${
                              indexLookup[pos.index]
                            }`}</Typography>
                          )}
                        </Fragment>
                      </PositionCard>
                    </Grid>
                  )),
            )}
        </Grid>
      </Section>
    </Fragment>
  )
}

export default withTranslation()(connect()(TenderPositionsView))
