import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { alertActions } from '../../../../../../_actions/alertActions'
import { surveysService } from '../../../../../../_services/surveysService'
import ComposeSurveyDialog from './_components/ComposeSurveyDialog'

const Surveys = ({ t, dispatch }) => {
  const [surveys, setSurveys] = useState([])
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [currentSurveyId, setCurrentSurveyId] = useState(null)
  const [mode, setMode] = useState('CREATE')

  useEffect(() => {
    fetchSurveys()
  }, [])

  const fetchSurveys = async () => {
    try {
      const data = await surveysService.getSurveys()

      setSurveys(data)
    } catch (error) {
      alertActions.error(t('SURVEY.FETCH_ERROR'))
    }
  }

  const handleCreate = () => {
    setCurrentSurveyId(null)
    setMode('CREATE')
    setIsDialogOpen(true)
  }

  const handleEdit = (surveyId) => {
    setCurrentSurveyId(surveyId)
    setMode('EDIT')
    setIsDialogOpen(true)
  }

  const handleDelete = async (surveyId) => {
    try {
      await surveysService.deleteSurvey(surveyId)
      fetchSurveys()
    } catch (error) {
      dispatch(alertActions.error(t('SURVEY.DELETE_ERROR')))
    }
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    fetchSurveys()
  }

  const handleToggleState = async (survey) => {
    const newState = survey.state === 'ACTIVE' ? 'ARCHIVED' : 'ACTIVE'
    try {
      await surveysService.updateSurvey(survey.id, {
        ...survey,
        state: newState,
      })
      fetchSurveys()
    } catch (error) {
      dispatch(alertActions.error(t('SURVEY.UPDATE_ERROR')))
    }
  }

  return (
    <Paper>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: 16 }}
      >
        <Grid item>
          <h2>{t('SURVEY.TITLE_HEAD')}</h2>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={handleCreate}>
            {t('SURVEY.CREATE_SURVEY')}
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('SURVEY.ID')}</TableCell>
              <TableCell>{t('SURVEY.TITLE')}</TableCell>
              <TableCell>{t('SURVEY.TYPE')}</TableCell>
              <TableCell>{t('SURVEY.STATE')}</TableCell>
              <TableCell>{t('SURVEY.IS_REQUIRED')}</TableCell>
              <TableCell>{t('SURVEY.UP_TO_DATE_TIME_IN_DAYS')}</TableCell>
              <TableCell>{t('SURVEY.ACTIONS')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {surveys.map((survey) => (
              <TableRow key={survey.id}>
                <TableCell>{survey.id}</TableCell>
                <TableCell>{survey.title}</TableCell>
                <TableCell>{t(`SURVEY.${survey.type}`)}</TableCell>
                <TableCell>
                  <Switch
                    checked={survey.state === 'ACTIVE'}
                    onChange={() => handleToggleState(survey)}
                    color="primary"
                  />
                </TableCell>
                <TableCell>
                  {survey.isRequired ? t('GENERAL.YES') : t('GENERAL.NO')}
                </TableCell>
                <TableCell>{survey.upToDateTimeInDays}</TableCell>
                <TableCell>
                  <IconButton
                    color="secondary"
                    onClick={() => handleEdit(survey.id)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => handleDelete(survey.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isDialogOpen && (
        <ComposeSurveyDialog
          surveyId={currentSurveyId}
          show={isDialogOpen}
          hide={handleCloseDialog}
          mode={mode}
          forceUpdate={fetchSurveys}
        />
      )}
    </Paper>
  )
}

export default withTranslation()(Surveys)
