import PropTypes from 'prop-types'
import React from 'react'
import { NumericFormat } from 'react-number-format'

const NumberFormatCustom = React.forwardRef((props, ref) => {
  const { inputRef, onChange, ...other } = props
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            name: other.name,
          },
        })
      }}
      isNumericString
      thousandSeparator="."
      decimalSeparator=","
    />
  )
})

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default NumberFormatCustom
