import { countrycodeLookup } from '../_constants/lookupConstants'

export const _googleService = {
  validateAddressOfLocation,
}

async function validateAddressOfLocation(location) {
  const response = await fetch(
    'https://addressvalidation.googleapis.com/v1:validateAddress?key=AIzaSyDMkOqlNgnP7V5zkTY6RcpYs7Rj9vshtuo',
    {
      method: 'POST',
      body: JSON.stringify({
        address: {
          revision: 0,
          postalCode: location.zipcode,
          locality: location.place,
          regionCode: countrycodeLookup[location.countryId],
          addressLines: [location.address],
        },
      }),
    },
  )

  const data = await response.json()

  return data.result
}

export * from './_googleService'
