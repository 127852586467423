import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'
import { generateFilterValuesQueryString } from '../_helpers/little.js'

export const _applicationService = {
  reset,
  getReportingOfPartner,
  getDashboardCalendarOfPartner,
  getDashboardTasksOfPartner,
  getPartnerLocationsMapData,
  getDashboardRevenueOfPartner,
  getProductsForProductEditor,
  getProductUnitsForProductEditor,
  getProductCategoriesForProductEditor,
  translateText,
  getPartnerInvoiceLocationsByToken,
  getAutomaticOfferPositionsMapData,
  checkPrice,
  hubspotSync,
  searchPartners,
}

function reset() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(`${config.apiUrlBase}/reset`, requestOptions).then(
    handleResponse,
  )
}

function hubspotSync() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(`${config.apiUrlBase}/hubspot-sync`, requestOptions).then(
    handleResponse,
  )
}

function translateText(text) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ text: text }),
  }
  return fetch(`${config.apiUrlBase}/translate-text`, requestOptions).then(
    handleResponse,
  )
}
function getReportingOfPartner(
  partnerId,
  dateFrom,
  dateTo,
  mode,
  filterValues,
  partnerLocationIds,
) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  let queryString = generateFilterValuesQueryString(filterValues)

  return fetch(
    `${
      config.apiUrlBase
    }/partners/${partnerId}/reporting${queryString}&dateFrom=${dateFrom.valueOf()}&dateTo=${dateTo.valueOf()}&mode=${mode}&${
      partnerLocationIds.length === 0
        ? 'partnerLocationIds=null'
        : partnerLocationIds
            .map(
              (partnerLocationId) => 'partnerLocationIds=' + partnerLocationId,
            )
            .join('&')
    }`,
    requestOptions,
  ).then(handleResponse)
}
function getDashboardRevenueOfPartner(partnerId, mode) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/dashboard-volume-revenue?mode=${mode}`,
    requestOptions,
  ).then(handleResponse)
}
function getDashboardCalendarOfPartner(partnerId, startCW, startYear) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/dashboard-calendar?currentDate=${startYear}&startCW=${startCW}`,
    requestOptions,
  ).then(handleResponse)
}

function getDashboardTasksOfPartner(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/dashboard-tasks`,
    requestOptions,
  ).then(handleResponse)
}
function getPartnerLocationsMapData(filterValues) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  let queryString = generateFilterValuesQueryString(filterValues)

  let url = `${config.apiUrlBase}/partner-locations-map${queryString}`

  if (filterValues?.geo?.origin) {
    url += `&latitude=${filterValues.geo.origin.lat}&longitude=${
      filterValues.geo.origin.lng
    }&radius=${filterValues.geo.radius / 1000}`
  }

  return fetch(url, requestOptions).then(handleResponse)
}

function getProductsForProductEditor() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(`${config.apiUrlBase}/products-for-editor`, requestOptions).then(
    handleResponse,
  )
}

function getProductUnitsForProductEditor() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/product-units-for-editor`,
    requestOptions,
  ).then(handleResponse)
}

function getProductCategoriesForProductEditor() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/product-categories-for-editor`,
    requestOptions,
  ).then(handleResponse)
}

function getPartnerInvoiceLocationsByToken(token) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/partner-invoice-locations-by-token?token=${token}`,
    requestOptions,
  ).then(handleResponse)
}

function getAutomaticOfferPositionsMapData(filterValues) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  let queryString = generateFilterValuesQueryString(filterValues)

  let url = `${config.apiUrlBase}/partner-locations-automatic-offer-positions-map${queryString}`

  return fetch(url, requestOptions).then(handleResponse)
}

function checkPrice(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(`${config.apiUrlBase}/check-price`, requestOptions).then(
    handleResponse,
  )
}

function searchPartners(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(`${config.apiUrlBase}/search-partners`, requestOptions).then(
    handleResponse,
  )
}

export * from './_applicationService'
