import { NotificationAdd } from '@mui/icons-material'
import { Chip, Stack, useMediaQuery, useTheme } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { orderStateLookup } from '../../../../../../_constants/lookupConstants'
import { deliveryNotesService } from '../../../../../../_services/deliveryNotesService'
import { partnersService } from '../../../../../../_services/partnersService'
import ResourceList from '../../../../../ResourceList/_components/ResourceList'
import Order from '../../../Order'
import DespatchAdvice from './_components/DespatchAdvice'

const DespatchAdviceOverview = (props) => {
  const { t } = props
  const [forceUpdateCount, forceUpdate] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedDeliveryNote, setSelectedDeliveryNote] = useState(null)
  const [showConfirmDespatch, setShowConfirmDespatch] = useState(false)
  const [partners, setPartners] = useState([])
  useEffect(
    () => {
      setIsLoading(true)
      partnersService
        .getPartners()
        .then((data) => {
          setPartners(data)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Fragment>
      <ResourceList
        fetchMethod={(filterValues, pagination) =>
          deliveryNotesService.getDeliveryNotes(
            {
              ...filterValues,
              hasBeenAdviced: false,
              'order.isDespatchAdviceRequired': true,
            },
            pagination,
          )
        }
        forceUpdateCount={forceUpdateCount}
        filterId={'DESPATCH_ADVICE_OVERVIEW'}
        filters={[
          {
            key: 'id.startsWith',
            label: t('DESPATCH_OVERVIEW.DELIVERY_NOTE_ID'),
            variant: 'textfield',
          },
          {
            key: 'calendarWeek',
            label: t('ORDERS.CALENDAR_WEEK'),
            variant: 'calendarWeekPicker',
          },
          {
            key: 'order.customerId.startsWith',
            label: t('ORDERS.CUSTOMER'),
            variant: 'autocomplete',
            options: partners.map((partner) => partner.id),
            lookup: Object.fromEntries(
              partners.map((partner) => [
                partner.id,
                `${partner.name} (${partner.id}) - ${partner.place}`,
              ]),
            ),
          },
          {
            key: 'order.manufacturerId.startsWith',
            label: t('ORDERS.MANUFACTURER'),
            variant: 'autocomplete',
            options: partners.map((partner) => partner.id),
            lookup: Object.fromEntries(
              partners.map((partner) => [
                partner.id,
                `${partner.name} (${partner.id}) - ${partner.place}`,
              ]),
            ),
          },
        ]}
        sorting={[
          {
            key: 'id',
            default: true,
            label: t('DESPATCH_OVERVIEW.DELIVERY_NOTE_ID'),
          },
        ]}
        dataIdentifier={'id'}
        resourceHeaderRenderFunction={(deliveryNote) =>
          `${t('DESPATCH_OVERVIEW.DELIVERY_NOTE')}: ${deliveryNote.id}`
        }
        listHeaderRenderFunction={(deliveryNote) => (
          <Fragment>
            {`${t('DESPATCH_OVERVIEW.DELIVERY_NOTE')}: ${deliveryNote.id}`}
            <Fragment>
              <div>{`${t('ORDER.CUSTOMER')}: ${
                deliveryNote.order.customer?.name ?? 'Name nicht verfügbar'
              } (${deliveryNote.order.customerId})`}</div>
              <div>{`${t('ORDER.MANUFACTURER')}: ${
                deliveryNote.order.manufacturer?.name ?? 'Name nicht verfügbar'
              } (${deliveryNote.order.manufacturerId})`}</div>
            </Fragment>
          </Fragment>
        )}
        contentRenderFunction={(deliveryNote) =>
          `${t('DESPATCH_OVERVIEW.ORDER_ID')}: ${deliveryNote.orderId}`
        }
        resourceRenderFunction={(deliveryNote) => {
          return <Order orderId={deliveryNote.orderId} />
        }}
        actionsRenderFunction={(deliveryNote) => {
          return [
            {
              icon: <NotificationAdd />,
              name: t('DESPATCH_OVERVIEW.SET_DESPATCH_ADVICE'),
              primary: true,
              onClick: async () => {
                setSelectedDeliveryNote(deliveryNote)
                setShowConfirmDespatch(true)
              },
            },
          ]
        }}
        // Test commit pull request frontend

        headerActionRenderFunction={(deliveryNote) => {
          return (
            <Fragment>
              <Stack direction={'column'}>
                <Stack direction={isMobile ? 'row-reverse' : 'row'} spacing={1}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: isMobile ? 'left' : 'right',
                      alignItems: 'center',
                    }}
                  >
                    <Chip
                      size={'small'}
                      sx={{ padding: 1 }}
                      color={
                        deliveryNote.order.state === 'RECEIVED' ||
                        deliveryNote.order.state === 'INVOICED'
                          ? 'success'
                          : deliveryNote.order.state === 'CANCELED'
                          ? 'default'
                          : 'info'
                      }
                      label={orderStateLookup[deliveryNote.order.state]}
                    />
                  </div>
                </Stack>
              </Stack>
            </Fragment>
          )
        }}
      />
      {selectedDeliveryNote && showConfirmDespatch && (
        <DespatchAdvice
          deliveryNoteId={selectedDeliveryNote.id}
          handleClose={() => {
            setShowConfirmDespatch(false)
          }}
          forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
          hide={() => {
            setShowConfirmDespatch(false)
          }}
        />
      )}
    </Fragment>
  )
}

export default withTranslation()(connect()(DespatchAdviceOverview))
