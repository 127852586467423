import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const jobsService = {
  createJobOfPartner,
  getJobsOfPartner,
  updateJob,
  getJob,
}

function createJobOfPartner(partnerId, requestbody) {
  var requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestbody),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/jobs`,
    requestOptions,
  ).then(handleResponse)
}

function updateJob(jobId, requestbody) {
  var requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestbody),
  }

  return fetch(`${config.apiUrlBase}/jobs/${jobId}`, requestOptions).then(
    handleResponse,
  )
}

function getJob(jobId) {
  var requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(`${config.apiUrlBase}/jobs/${jobId}`, requestOptions).then(
    handleResponse,
  )
}

function getJobsOfPartner(partnerId) {
  var requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/jobs`,
    requestOptions,
  ).then(handleResponse)
}

export * from './jobsService'
