import { Grid, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryLookup,
  incotermLookup,
  stackHeightUnitLookup,
  unloadLookup,
} from '../../../../_constants/lookupConstants'
import DeliveryTimesView from '../../../DeliveryTimesView'
import { Section } from '../../../Section'

const JobOfferDeliveryDetailsView = (props) => {
  const { data, t } = props

  return (
    <Section header={t('REQUEST.DELIVERY_DETAILS.HEADING')} hideBorder={true}>
      <Grid container spacing={1}>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant={'subtitle1'}>
              {t('DELIVERY_DETAILS_VIEW.INCOTERM') + ':'}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant={'subtitle2'} color={'text.secondary'}>
              {incotermLookup[data.incoTerm]}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant={'subtitle1'}>
              {t('OFFER.DELIVERY_ADDRESS') + ':'}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant={'subtitle2'} color={'text.secondary'}>
              <Fragment>{`${data.company}, ${data.address}, `}</Fragment>
              <Fragment>{`${data.zipcode} ${data.place}, ${
                countryLookup[data.countryId]
              }`}</Fragment>
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant={'subtitle1'}>
              {t('DELIVERY_DETAILS.POSSIBLE_LOADING') + ':'}
            </Typography>
          </Grid>
          <Typography variant={'subtitle2'} color={'text.secondary'}>
            {unloadLookup[data.unload]}
          </Typography>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant={'subtitle1'}>
              {t('DELIVERY_DETAILS.STACK_HEIGHT') + ':'}
            </Typography>
          </Grid>
          <Typography variant={'subtitle2'} color={'text.secondary'}>
            {`${data.stackHeight} ${
              stackHeightUnitLookup[data.stackHeightUnit]
            }`}
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <DeliveryTimesView data={data.deliveryTimes} />
          </Grid>
        </Grid>
        {data.isDespatchAdviceRequired && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} style={{ alignSelf: 'center' }}>
                <Typography variant={'subtitle1'}>
                  {t('OFFER.DESPATCH_ADVICE_INSTRUCTIONS') + ':'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  {data.despatchAdviceInstructions}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid container item xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'flex-start' }}>
            <Typography variant={'subtitle1'}>
              {t('OFFER.DELIVERY_INFORMATION') + ':'}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            {data.deliveryInformations ? (
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {data.deliveryInformations}
              </Typography>
            ) : (
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                <em>{t('OFFER.NO_DELIVERY_INFORMATION')}</em>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Section>
  )
}

export default withTranslation()(JobOfferDeliveryDetailsView)
