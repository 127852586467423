import { Add, Info, Settings } from '@mui/icons-material'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import _ from 'lodash'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { countryList } from '../../../../../../_constants/lookupConstants'
import { calculatePriceByKm } from '../../../../../../_helpers/little'
import { formatToPrice } from '../../../../../../_helpers/number-formatting'
import NumberFormatCustom from '../../../../../../_helpers/numberFormatCustom'
import { automaticOfferPositionsService } from '../../../../../../_services/automaticOfferPositionsService'
import { usersService } from '../../../../../../_services/usersService'
import CustomDialogTitle from '../../../../../CustomDialogTitle'
import { FieldErrorWrapper } from '../../../../../FieldErrorWrapper'
import { Loading } from '../../../../../Loading'
import PositionCard from '../../../../../PositionCard'
import ProductConfigurator from '../../../../../ProductConfigurator'
import ZipcodeAreaDialog from './_components/ZipcodeAreaDialog'

const ComposeAutomaticOfferPositionDialog = (props) => {
  const {
    t,
    automaticOfferPositionId,
    partnerLocation,
    forceUpdate,
    hide,
    mode,
  } = props

  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState([])

  const [automaticOfferPosition, setAutomaticOfferPosition] = useState(null)

  const [showProductConfigurator, setShowProductConfigurator] = useState(
    !automaticOfferPositionId ? true : false,
  )

  const [selectedCountryId, setSelectedCountryId] = useState(null)

  const stackHeightProductLookup = {
    20: [19, 18, 17, 16, 15],
    2: [20, 19, 18, 17, 16, 15],
  }

  const weekDayLookup = {
    1: t('DELIVERY_TIMES.MONDAY'),
    2: t('DELIVERY_TIMES.TUESDAY'),
    3: t('DELIVERY_TIMES.WEDNESDAY'),
    4: t('DELIVERY_TIMES.THURSDAY'),
    5: t('DELIVERY_TIMES.FRIDAY'),
    6: t('DELIVERY_TIMES.SATURDAY'),
    7: t('DELIVERY_TIMES.SUNDAY'),
  }

  var userObject = JSON.parse(localStorage.getItem('user'))
  useEffect(() => {
    usersService.getUsersOfPartner(userObject.partner.id).then((data) => {
      setUsers(data)
    })
  }, [])

  useEffect(() => {
    if (!automaticOfferPositionId) {
      return
    }
    automaticOfferPositionsService
      .getAutomaticOfferPosition(automaticOfferPositionId)
      .then((data) => {
        setAutomaticOfferPosition(data)
      })
  }, [automaticOfferPositionId])

  return isLoading ? (
    <Loading variant={'centered'} />
  ) : (
    <Grid container spacing={1}>
      <Formik
        enableReinitialize={true}
        initialValues={
          automaticOfferPosition
            ? {
                ...automaticOfferPosition,
                id: mode === 'COPY' ? undefined : automaticOfferPosition.id,
                validUntil: moment(automaticOfferPosition.validUntil),
                earliestDeliveryDate:
                  automaticOfferPosition.earliestDeliveryDate
                    ? moment(automaticOfferPosition.earliestDeliveryDate)
                    : null,
                state: undefined,
                offerBindingCheckbox: false,
                editOffersOfServiceCheckbox: false,
                reactivateOffersOfServiceCheckbox: false,
              }
            : {
                maxPaymentTerm: 14,
                validUntil: null,
                earliestDeliveryDate: null,
                offerValidityInDays: 7,
                leadTimeInDays: 2,
                maxAmountEachCalendarWeek: '',
                maxDistanceInKm: '',
                stackHeightLookup: { null: 0 },
                price: '',
                priceEachKm: null,
                basePriceEachTruck: '',
                productId: null,
                productUnitId: null,
                userId: '',
                countries: [],
                zipcodeAreas: {},
                offerBindingCheckbox: false,
                editOffersOfServiceCheckbox: false,
                reactivateOffersOfServiceCheckbox: false,
                type: 'PRICE_BASED',
                deliveryDays: {
                  1: true,
                  2: true,
                  3: true,
                  4: true,
                  5: true,
                  6: false,
                  7: false,
                },
              }
        }
        validationSchema={Yup.object()
          .shape({
            maxPaymentTerm: Yup.string().required(t('GENERAL.REQUIRED')),
            validUntil: Yup.date().required(t('GENERAL.REQUIRED')),
            earliestDeliveryDate: Yup.date().nullable(),
            offerValidityInDays: Yup.number().min(
              1,
              t('GENERAL.GREATER_THAN_0'),
            ),
            type: Yup.string().required(t('GENERAL.REQUIRED')),
            leadTimeInDays: Yup.number().min(1, t('GENERAL.GREATER_THAN_0')),
            maxAmountEachCalendarWeek: Yup.number().min(
              1,
              t('GENERAL.GREATER_THAN_0'),
            ),
            maxDistanceInKm: Yup.number()
              .transform((value, originalValue) =>
                originalValue === '' ? undefined : value,
              )
              .min(1, t('GENERAL.GREATER_THAN_0'))
              .nullable(true),
            countries: Yup.array().nullable(true),
            stackHeightLookup: Yup.object().test(
              'atLeastOneNumber',
              t('AUTOMATIC_OFFERS.PLEASE_ENTER_AN_AMOUNT'),
              (obj, context) => {
                if (context.parent.type === 'PRICE_BASED') return true
                return obj && Object.values(obj).some((value) => value > 0)
              },
            ),
            price: Yup.string().min(0.01, t('GENERAL.GREATER_THAN_0')),
            priceEachKm: Yup.string().when('type', ([type], schema) => {
              return type === 'DISTANCE_BASED'
                ? Yup.string()
                    .required(t('GENERAL.REQUIRED'))
                    .min(0.0, t('GENERAL.GREATER_THAN_0'))
                : Yup.string().nullable()
            }),
            basePriceEachTruck: Yup.string()
              .min(0.01, t('GENERAL.GREATER_THAN_0'))
              .nullable(),
            productId: Yup.number().required(t('GENERAL.REQUIRED')),
            productUnitId: Yup.number().required(t('GENERAL.REQUIRED')),
            userId: Yup.number().required(t('GENERAL.REQUIRED')),
            offerBindingCheckbox: Yup.boolean().oneOf(
              [true],
              t('GENERAL.REQUIRED'),
            ),
          })
          .test(
            'maxDistanceInKm-or-countries',
            t('AUTOMATIC_OFFERS.MAX_DISTANCE_OR_COUNTRY_REQUIRED'),
            function (value) {
              const { maxDistanceInKm, countries } = value

              // Prüft, ob maxDistanceInKm einen Wert hat oder countries mindestens einen Eintrag hat
              if (
                (maxDistanceInKm && maxDistanceInKm > 0) ||
                (countries && countries.length > 0)
              ) {
                return true // Validierung erfolgreich
              }

              return this.createError({
                message: t('AUTOMATIC_OFFERS.MAX_DISTANCE_OR_COUNTRY_REQUIRED'),
                path: 'maxDistanceInKmOrCountries',
              })
            },
          )}
        //validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          setIsLoading(true)

          let payload = {
            ...values,
            maxDistanceInKm: values.maxDistanceInKm
              ? values.maxDistanceInKm
              : null,
            basePriceEachTruck: values.basePriceEachTruck
              ? values.basePriceEachTruck
              : null,
            stackHeightLookup: _.omitBy(
              values.stackHeightLookup,
              (value) => value === '',
            ),
            positionProperties: values.positionProperties.map(
              (positionProperty) => {
                return {
                  value: positionProperty.value,
                  productPropertyId: positionProperty.productProperty.id,
                }
              },
            ),
            validUntil: values.validUntil.set({
              hour: 12,
              minute: 0,
              second: 0,
              millisecond: 0,
            }),
            earliestDeliveryDate: values.earliestDeliveryDate
              ? values.earliestDeliveryDate.set({
                  hour: 12,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
              : null,
          }

          if (!_.isEmpty(values.zipcodeAreas)) {
            const cleanData = (inputData) => {
              return _.transform(
                inputData,
                (result, value, key) => {
                  // Bereiche ohne 'showSecondDigits' und mit gültigem 'price'
                  const cleanedZones = _.transform(
                    value,
                    (zonesResult, zoneValue, zoneKey) => {
                      if (
                        zoneValue.price &&
                        parseFloat(zoneValue.price) !== 0
                      ) {
                        zonesResult[zoneKey] = _.omit(zoneValue, [
                          'showSecondDigits',
                        ])
                      }
                    },
                    {},
                  )
                  if (!_.isEmpty(cleanedZones)) {
                    result[key] = cleanedZones
                  }
                },
                {},
              )
            }

            const cleanedZipcodeAreas = cleanData(values.zipcodeAreas)

            payload.zipcodeAreas = cleanedZipcodeAreas
          }

          if (values.id) {
            automaticOfferPositionsService
              .updateAutomaticOfferPosition(payload, automaticOfferPosition.id)
              .then((data) => {
                forceUpdate()
                hide()
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                setSubmitting = false
                setIsLoading(false)
                forceUpdate()
              })
          } else {
            automaticOfferPositionsService
              .createAutomaticOfferPositionOfPartnerLocation(
                payload,
                partnerLocation.id,
              )
              .then((data) => {
                forceUpdate()
                hide()
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                setSubmitting = false
                setIsLoading(false)
              })
          }
        }}
      >
        {({
          values,
          isSubmitting,
          submitForm,
          isValid,
          setFieldValue,
          errors,
          touched,
        }) => {
          const showError = touched.maxDistanceInKm || touched.countries

          const handleCountrySelectChange = (event) => {
            const {
              target: { value },
            } = event

            let newCountries = [...values.countries]

            if (!newCountries.some((countryId) => countryId === value)) {
              newCountries.push(value)
              setFieldValue('countries', newCountries)
            }
          }

          const handleCountryDelete = (countryToDelete) => () => {
            setFieldValue(
              'countries',
              values.countries.filter((country) => country !== countryToDelete),
            )
          }

          return (
            <Fragment>
              <Dialog fullWidth={true} maxWidth="md" open={true}>
                <CustomDialogTitle
                  title={
                    automaticOfferPosition
                      ? t('AUTOMATIC_OFFERS.UPDATE_AUTOMATIC_OFFER_POSITION')
                      : t('AUTOMATIC_OFFERS.CREATE_AUTOMATIC_OFFER_POSITION')
                  }
                  onClose={() => {
                    hide()
                  }}
                />
                <DialogContent>
                  <Form>
                    <Grid
                      container
                      spacing={1}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        {values.productId ? (
                          <PositionCard
                            data={values}
                            showProductDetails={true}
                            updatePositionFunction={(
                              product,
                              positionProperties,
                            ) => {
                              if (product) {
                                setFieldValue('productId', product.id)
                                setFieldValue(
                                  'positionProperties',
                                  positionProperties,
                                )
                              }
                            }}
                            deletePositionFunction={() => {
                              setFieldValue('productId', null)
                              setFieldValue('positionProperties', [])
                            }}
                            readOnly={false}
                            showImage={true}
                            imageSize={'small'}
                          ></PositionCard>
                        ) : (
                          <Fragment>
                            <Button
                              startIcon={<Add />}
                              variant="contained"
                              color="secondary"
                              size={'medium'}
                              style={{ marginBottom: 20, marginTop: 10 }}
                              onClick={() => setShowProductConfigurator(true)}
                            >
                              {t('REQUEST.ADD_POSITION')}
                            </Button>
                            <ErrorMessage
                              name={'productId'}
                              render={(msg) => (
                                <div className="help-block">{msg}</div>
                              )}
                            />
                          </Fragment>
                        )}
                      </Grid>

                      <Grid item xs={11}>
                        <Field
                          variant={'outlined'}
                          component={TextField}
                          name="type"
                          select
                          fullWidth
                          label={t('AUTOMATIC_OFFERS.TYPE')}
                          margin="dense"
                          size={'small'}
                          onChange={(e) => {
                            setFieldValue('type', e.target.value)

                            setFieldValue('price', '')

                            setFieldValue('basePriceEachTruck', '')

                            setFieldValue('countries', [])
                            setFieldValue('zipcodeAreas', {})

                            if (e.target.value === 'PRICE_BASED') {
                              setFieldValue('stackHeightLookup', { null: 0 })
                              setFieldValue('priceEachKm', null)
                            } else {
                              setFieldValue('stackHeightLookup', {})
                              setFieldValue('priceEachKm', '')
                            }
                          }}
                        >
                          <MenuItem value={'PRICE_BASED'} key={'PRICE_BASED'}>
                            {t('AUTOMATIC_OFFERS.PRICE_BASED')}
                          </MenuItem>
                          <MenuItem
                            value={'DISTANCE_BASED'}
                            key={'DISTANCE_BASED'}
                          >
                            {t('AUTOMATIC_OFFERS.DISTANCE_BASED')}
                          </MenuItem>
                        </Field>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t('AUTOMATIC_OFFERS.TOOLTIP_MAX_PAYMENT_TERM')}
                        >
                          <Info color={'secondary'} fontSize={'large'} />
                        </Tooltip>
                      </Grid>

                      <Grid
                        item
                        xs={11}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Field
                          variant={'outlined'}
                          component={TextField}
                          name="userId"
                          select
                          fullWidth
                          label={t('AUTOMATIC_OFFERS.USER')}
                          margin="dense"
                          size={'small'}
                        >
                          {users.map((user) => (
                            <MenuItem value={user.id} key={user.id}>
                              {`${user.firstname} ${user.lastname}`}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip title={t('AUTOMATIC_OFFERS.TOOLTIP_USER')}>
                          <Info color={'secondary'} fontSize={'large'} />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={11}>
                        <Field
                          variant={'outlined'}
                          component={TextField}
                          name="maxPaymentTerm"
                          select
                          fullWidth
                          label={t('AUTOMATIC_OFFERS.MAX_PAYMENT_TERM')}
                          margin="dense"
                          size={'small'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM_UNIT')}
                              </InputAdornment>
                            ),
                          }}
                        >
                          {[
                            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
                            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
                            28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
                            41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53,
                            54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
                            67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
                            80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90,
                          ].map((paymentTerm) => (
                            <MenuItem value={paymentTerm} key={paymentTerm}>
                              {paymentTerm !== 0
                                ? paymentTerm
                                : t('GENERAL.PREPAYMENT')}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t('AUTOMATIC_OFFERS.TOOLTIP_MAX_PAYMENT_TERM')}
                        >
                          <Info color={'secondary'} fontSize={'large'} />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={11}>
                        <FieldErrorWrapper
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              label: t('AUTOMATIC_OFFERS.VALID_UNTIL'),
                              margin: 'dense',
                              size: 'small',
                            },
                          }}
                          name="validUntil"
                          component={DatePicker}
                          inputFormat="DD/MM/YYYY"
                          minDate={moment()}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t('AUTOMATIC_OFFERS.TOOLTIP_VALID_UNTIL')}
                        >
                          <Info color={'secondary'} fontSize={'large'} />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={11}>
                        <FieldErrorWrapper
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              label: `${t(
                                'AUTOMATIC_OFFERS.EARLIEST_DELIVERY_DATE',
                              )} (${t('GENERAL.OPTIONAL')})`,
                              margin: 'dense',
                              size: 'small',
                            },
                          }}
                          name="earliestDeliveryDate"
                          component={DatePicker}
                          inputFormat="DD/MM/YYYY"
                          minDate={moment()}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t(
                            'AUTOMATIC_OFFERS.TOOLTIP_EARLIEST_DELIVERY_DATE',
                          )}
                        >
                          <Info color={'secondary'} fontSize={'large'} />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={11}>
                        <Typography variant={'subtitle1'}>
                          {t('AUTOMATIC_OFFERS.DELIVERY_DAYS')}
                        </Typography>
                        <Stack spacing={2} direction={'row'}>
                          {Object.keys(weekDayLookup).map((day) => (
                            <Field
                              key={day}
                              component={CheckboxWithLabel}
                              type={'checkbox'}
                              color="default"
                              name={`deliveryDays.${day}`}
                              Label={{
                                label: `${weekDayLookup[day]}`,
                              }}
                            />
                          ))}
                        </Stack>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t('AUTOMATIC_OFFERS.TOOLTIP_DELIVERY_DAYS')}
                        >
                          <Info color={'secondary'} fontSize={'large'} />
                        </Tooltip>
                      </Grid>

                      <Grid item xs={11}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          size={'small'}
                          label={t('AUTOMATIC_OFFERS.OFFER_VALIDITY_IN_DAYS')}
                          variant="outlined"
                          name="offerValidityInDays"
                          inputProps={{
                            decimalScale: 0,
                            allowNegative: false,
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t(
                            'AUTOMATIC_OFFERS.TOOLTIP_OFFER_VALIDITY_IN_DAYS',
                          )}
                        >
                          <Info color={'secondary'} fontSize={'large'} />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={11}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          size={'small'}
                          label={t('AUTOMATIC_OFFERS.LEAD_TIME_IN_DAYS')}
                          variant="outlined"
                          name="leadTimeInDays"
                          inputProps={{
                            decimalScale: 0,
                            allowNegative: false,
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t(
                            'AUTOMATIC_OFFERS.TOOLTIP_LEAD_TIME_IN_DAYS',
                          )}
                        >
                          <Info color={'secondary'} fontSize={'large'} />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={11}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          size={'small'}
                          label={t(
                            'AUTOMATIC_OFFERS.MAX_AMOUNT_EACH_CALENDAR_WEEK',
                          )}
                          variant="outlined"
                          name="maxAmountEachCalendarWeek"
                          inputProps={{
                            decimalScale: 0,
                            allowNegative: false,
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t(
                            'AUTOMATIC_OFFERS.TOOLTIP_MAX_AMOUNT_EACH_CALENDAR_WEEK',
                          )}
                        >
                          <Info color={'secondary'} fontSize={'large'} />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={11}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          size={'small'}
                          label={t('AUTOMATIC_OFFERS.MAX_DISTANCE_IN_KM')}
                          variant="outlined"
                          name="maxDistanceInKm"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          inputProps={{
                            decimalScale: 0,
                            allowNegative: false,
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t(
                            'AUTOMATIC_OFFERS.TOOLTIP_MAX_DISTANCE_IN_KM',
                          )}
                        >
                          <Info color={'secondary'} fontSize={'large'} />
                        </Tooltip>
                      </Grid>

                      <Grid
                        item
                        xs={11}
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <FormControl fullWidth>
                          <InputLabel
                            sx={{ marginTop: -0.5 }}
                            id="countries-label"
                          >
                            {t('AUTOMATIC_OFFERS.COUNTRY_RESTRICTIONS')}
                          </InputLabel>
                          <Select
                            labelId={'countries-label'}
                            name="countries"
                            margin="dense"
                            size={'small'}
                            onChange={handleCountrySelectChange}
                            renderValue={() => null}
                          >
                            {Object.entries(countryList).map(
                              (country, index) => (
                                <MenuItem key={index} value={country[1]}>
                                  {country[0]}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                          <Box
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              marginBottom: 2,
                            }}
                          >
                            {values.countries.map((countryId) => (
                              <Chip
                                icon={
                                  <IconButton
                                    aria-label="settings"
                                    onClick={() =>
                                      setSelectedCountryId(countryId)
                                    }
                                  >
                                    <Settings />
                                  </IconButton>
                                }
                                sx={{ margin: 1 }}
                                key={countryId}
                                label={
                                  Object.entries(countryList).find(
                                    (country) =>
                                      parseInt(country[1]) ===
                                      parseInt(countryId),
                                  )[0]
                                }
                                onDelete={handleCountryDelete(countryId)}
                              />
                            ))}
                          </Box>
                        </FormControl>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t(
                            'AUTOMATIC_OFFERS.TOOLTIP_COUNTRY_RESTRICTIONS',
                          )}
                        >
                          <Info color={'secondary'} fontSize={'large'} />
                        </Tooltip>
                      </Grid>

                      {errors.maxDistanceInKmOrCountries && showError && (
                        <Grid item xs={12}>
                          <div className="help-block">
                            {errors.maxDistanceInKmOrCountries}
                          </div>{' '}
                        </Grid>
                      )}

                      <Grid item xs={11}>
                        {stackHeightProductLookup[values.productId] ? (
                          <Grid container spacing={1}>
                            {values.type === 'DISTANCE_BASED' && (
                              <Grid item xs={6}>
                                {
                                  <Field
                                    component={TextField}
                                    fullWidth
                                    margin="dense"
                                    size={'small'}
                                    label={`${t(
                                      'AUTOMATIC_OFFERS.PIECES_EACH_TRUCK',
                                    )} - ${t('AUTOMATIC_OFFERS.MAXIMUM')}`}
                                    variant="outlined"
                                    name={`stackHeightLookup.null`}
                                    InputProps={{
                                      inputComponent: NumberFormatCustom,
                                    }}
                                    inputProps={{
                                      decimalScale: 0,
                                      allowNegative: false,
                                    }}
                                  />
                                }
                              </Grid>
                            )}
                            {stackHeightProductLookup[values.productId].map(
                              (stackHeight) => (
                                <Grid item xs={6}>
                                  {values.type === 'DISTANCE_BASED' ? (
                                    <Field
                                      component={TextField}
                                      fullWidth
                                      margin="dense"
                                      size={'small'}
                                      label={`${t(
                                        'AUTOMATIC_OFFERS.PIECES_EACH_TRUCK',
                                      )} - ${t(
                                        'AUTOMATIC_OFFERS.STACK_HEIGHT',
                                      )} ${stackHeight}`}
                                      variant="outlined"
                                      name={`stackHeightLookup[${stackHeight}]`}
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                      inputProps={{
                                        decimalScale: 0,
                                        allowNegative: false,
                                      }}
                                      error={false}
                                      helperText={''}
                                    />
                                  ) : (
                                    <Field
                                      component={TextField}
                                      fullWidth
                                      margin="dense"
                                      size={'small'}
                                      label={`${t(
                                        'AUTOMATIC_OFFERS.SURCHARGE_PER_PIECE',
                                      )} - ${t(
                                        'AUTOMATIC_OFFERS.STACK_HEIGHT',
                                      )} ${stackHeight}`}
                                      variant="outlined"
                                      name={`stackHeightLookup[${stackHeight}]`}
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                      // eslint-disable-next-line react/jsx-no-duplicate-props
                                      inputProps={{
                                        inputComponent: NumberFormatCustom,
                                        decimalScale: 2,
                                        allowNegative: false,
                                        suffix: ' \u20AC',
                                        fixedDecimalScale: true,
                                      }}
                                      error={false}
                                      helperText={''}
                                      // onChange={(event) => {

                                      // }}
                                    />
                                  )}
                                </Grid>
                              ),
                            )}
                          </Grid>
                        ) : values.type === 'DISTANCE_BASED' ? (
                          <>
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              size={'small'}
                              label={t('AUTOMATIC_OFFERS.PIECES_EACH_TRUCK')}
                              variant="outlined"
                              name="stackHeightLookup.null"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                inputComponent: NumberFormatCustom,
                                decimalScale: 2,
                                allowNegative: false,
                                suffix: ' \u20AC',
                                fixedDecimalScale: true,
                              }}
                            />
                            {
                              <Box margin={1}>
                                <ErrorMessage
                                  name={'stackHeightLookup'}
                                  render={(msg) => (
                                    <div className="help-block">{msg}</div>
                                  )}
                                />
                              </Box>
                            }
                          </>
                        ) : null}
                      </Grid>

                      <Grid item xs={1}>
                        {values.type === 'DISTANCE_BASED' ? (
                          <Tooltip
                            title={t(
                              'AUTOMATIC_OFFERS.TOOLTIP_PIECES_EACH_TRUCK',
                            )}
                          >
                            <Info color={'secondary'} fontSize={'large'} />
                          </Tooltip>
                        ) : stackHeightProductLookup[values.productId] ? (
                          <Tooltip
                            title={t(
                              'AUTOMATIC_OFFERS.TOOLTIP_SURCHARGE_PER_PIECE',
                            )}
                          >
                            <Info color={'secondary'} fontSize={'large'} />
                          </Tooltip>
                        ) : null}
                      </Grid>
                      <Grid item xs={11}>
                        <Field
                          fullWidth={true}
                          component={TextField}
                          margin="dense"
                          size={'small'}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{
                            inputComponent: NumberFormatCustom,
                            decimalScale: 2,
                            allowNegative: false,
                            suffix: ' \u20AC',
                            fixedDecimalScale: true,
                          }}
                          label={
                            values.type === 'DISTANCE_BASED'
                              ? t('AUTOMATIC_OFFERS.EXW_PRICE_EACH')
                              : t('AUTOMATIC_OFFERS.PRICE_EACH')
                          }
                          variant="outlined"
                          name={`price`}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={
                            values.type === 'DISTANCE_BASED'
                              ? t('AUTOMATIC_OFFERS.TOOLTIP_EXW_PRICE_EACH')
                              : t('AUTOMATIC_OFFERS.TOOLTIP_PRICE_EACH')
                          }
                        >
                          <Info color={'secondary'} fontSize={'large'} />
                        </Tooltip>
                      </Grid>
                      {values.type === 'DISTANCE_BASED' && (
                        <>
                          <Grid item xs={11}>
                            <Field
                              fullWidth={true}
                              component={TextField}
                              margin="dense"
                              size={'small'}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                inputComponent: NumberFormatCustom,
                                decimalScale: 2,
                                allowNegative: false,
                                suffix: ' \u20AC',
                                fixedDecimalScale: true,
                              }}
                              label={`${t('AUTOMATIC_OFFERS.PRICE_EACH_KM')}`}
                              variant="outlined"
                              name={`priceEachKm`}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Tooltip
                              title={t(
                                'AUTOMATIC_OFFERS.TOOLTIP_PRICE_EACH_KM',
                              )}
                            >
                              <Info color={'secondary'} fontSize={'large'} />
                            </Tooltip>
                          </Grid>
                          <Grid
                            item
                            xs={11}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Field
                              fullWidth={true}
                              component={TextField}
                              margin="dense"
                              size={'small'}
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                inputComponent: NumberFormatCustom,
                                decimalScale: 2,
                                allowNegative: false,
                                suffix: ' \u20AC',
                                fixedDecimalScale: true,
                              }}
                              label={`${t(
                                'AUTOMATIC_OFFERS.BASE_PRICE_EACH_TRUCK',
                              )} (${t('GENERAL.OPTIONAL')})`}
                              variant="outlined"
                              name={`basePriceEachTruck`}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <Tooltip
                              title={t(
                                'AUTOMATIC_OFFERS.TOOLTIP_BASE_PRICE_EACH_TRUCK',
                              )}
                            >
                              <Info color={'secondary'} fontSize={'large'} />
                            </Tooltip>
                          </Grid>
                        </>
                      )}

                      {values.type === 'DISTANCE_BASED' ? (
                        <Grid item xs={12}>
                          {values.stackHeightLookup &&
                            values.price &&
                            values.priceEachKm && (
                              <Fragment>
                                <Box margin={1}>
                                  <Typography variant={'caption'}>
                                    {t('AUTOMATIC_OFFERS.PRICE_EXAMPLES')}
                                  </Typography>
                                </Box>
                                <TableContainer component={Paper}>
                                  <Table
                                    sx={{ minWidth: 650 }}
                                    aria-label="Preview table"
                                    size={'small'}
                                  >
                                    <TableHead>
                                      <TableRow>
                                        {stackHeightProductLookup[
                                          values.productId
                                        ] && (
                                          <TableCell>
                                            {t('AUTOMATIC_OFFERS.STACK_HEIGHT')}
                                          </TableCell>
                                        )}
                                        <TableCell>
                                          {values.maxDistanceInKm
                                            ? values.maxDistanceInKm / 4
                                            : 250}{' '}
                                          KM
                                        </TableCell>
                                        <TableCell>
                                          {values.maxDistanceInKm
                                            ? (values.maxDistanceInKm / 4) * 2
                                            : 500}{' '}
                                          KM
                                        </TableCell>
                                        <TableCell>
                                          {values.maxDistanceInKm
                                            ? (values.maxDistanceInKm / 4) * 3
                                            : 750}{' '}
                                          KM
                                        </TableCell>
                                        <TableCell>
                                          {values.maxDistanceInKm
                                            ? values.maxDistanceInKm
                                            : 1000}{' '}
                                          KM
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableRow
                                        sx={{
                                          '&:last-child td, &:last-child th': {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        {stackHeightProductLookup[
                                          values.productId
                                        ] && (
                                          <TableCell>
                                            {t('AUTOMATIC_OFFERS.MAXIMUM')}{' '}
                                          </TableCell>
                                        )}
                                        <TableCell component="th" scope="row">
                                          {formatToPrice(
                                            calculatePriceByKm(
                                              values.maxDistanceInKm
                                                ? values.maxDistanceInKm / 4
                                                : 250,
                                              null,
                                              values.priceEachKm,
                                              values,
                                            ),
                                          )}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {formatToPrice(
                                            calculatePriceByKm(
                                              values.maxDistanceInKm
                                                ? (values.maxDistanceInKm / 4) *
                                                    2
                                                : 500,
                                              null,
                                              values.priceEachKm,
                                              values,
                                            ),
                                          )}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {formatToPrice(
                                            calculatePriceByKm(
                                              values.maxDistanceInKm
                                                ? (values.maxDistanceInKm / 4) *
                                                    3
                                                : 750,
                                              null,
                                              values.priceEachKm,
                                              values,
                                            ),
                                          )}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {formatToPrice(
                                            calculatePriceByKm(
                                              values.maxDistanceInKm
                                                ? values.maxDistanceInKm
                                                : 1000,
                                              null,
                                              values.priceEachKm,
                                              values,
                                            ),
                                          )}
                                        </TableCell>
                                      </TableRow>
                                      {stackHeightProductLookup[
                                        values.productId
                                      ] &&
                                        stackHeightProductLookup[
                                          values.productId
                                        ].map((stackHeight) => (
                                          <TableRow
                                            sx={{
                                              '&:last-child td, &:last-child th':
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <TableCell>
                                              {' '}
                                              {stackHeight}{' '}
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {formatToPrice(
                                                calculatePriceByKm(
                                                  values.maxDistanceInKm / 4,
                                                  stackHeight,
                                                  values.priceEachKm,
                                                  values,
                                                ),
                                              )}
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {formatToPrice(
                                                calculatePriceByKm(
                                                  (values.maxDistanceInKm / 4) *
                                                    2,
                                                  stackHeight,
                                                  values.priceEachKm,
                                                  values,
                                                ),
                                              )}
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {formatToPrice(
                                                calculatePriceByKm(
                                                  (values.maxDistanceInKm / 4) *
                                                    3,
                                                  stackHeight,
                                                  values.priceEachKm,
                                                  values,
                                                ),
                                              )}
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {formatToPrice(
                                                calculatePriceByKm(
                                                  values.maxDistanceInKm,
                                                  stackHeight,
                                                  values.priceEachKm,
                                                  values,
                                                ),
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Fragment>
                            )}
                        </Grid>
                      ) : (
                        <Grid item xs={12}>
                          {values.stackHeightLookup && values.price && (
                            <Fragment>
                              <Box margin={1}>
                                <Typography variant={'caption'}>
                                  {t('AUTOMATIC_OFFERS.PRICE_EXAMPLES')}
                                </Typography>
                              </Box>
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="Preview table"
                                  size={'small'}
                                >
                                  <TableHead>
                                    <TableRow>
                                      {stackHeightProductLookup[
                                        values.productId
                                      ] && (
                                        <TableCell>
                                          {t('AUTOMATIC_OFFERS.STACK_HEIGHT')}
                                        </TableCell>
                                      )}
                                      <TableCell>
                                        {t('AUTOMATIC_OFFERS.PRICE_EACH')}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow
                                      sx={{
                                        '&:last-child td, &:last-child th': {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      {stackHeightProductLookup[
                                        values.productId
                                      ] && (
                                        <TableCell>
                                          {t('AUTOMATIC_OFFERS.MAXIMUM')}
                                        </TableCell>
                                      )}
                                      <TableCell component="th" scope="row">
                                        {formatToPrice(
                                          parseFloat(values.price) +
                                            parseFloat(
                                              values.stackHeightLookup[null],
                                            ),
                                        )}
                                      </TableCell>
                                    </TableRow>
                                    {stackHeightProductLookup[
                                      values.productId
                                    ] &&
                                      stackHeightProductLookup[
                                        values.productId
                                      ].map((stackHeight) => (
                                        <TableRow
                                          sx={{
                                            '&:last-child td, &:last-child th':
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <TableCell>{stackHeight}</TableCell>
                                          <TableCell component="th" scope="row">
                                            {formatToPrice(
                                              parseFloat(values.price) +
                                                parseFloat(
                                                  values.stackHeightLookup[
                                                    stackHeight
                                                  ],
                                                ),
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Fragment>
                          )}
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Box margin={2}>
                          <Field
                            component={CheckboxWithLabel}
                            type={'checkbox'}
                            color="default"
                            name={'offerBindingCheckbox'}
                            Label={{
                              label: t('AUTOMATIC_OFFERS.OFFER_BINDING_TEXT'),
                            }}
                            style={{ marginLeft: '15px' }}
                          />
                          <ErrorMessage
                            name={'offerBindingCheckbox'}
                            render={(msg) => (
                              <div className="help-block">{msg}</div>
                            )}
                          />
                        </Box>
                      </Grid>
                      {mode !== 'COPY' && automaticOfferPosition && (
                        <Grid item xs={12}>
                          <Box margin={2}>
                            <Field
                              component={CheckboxWithLabel}
                              type={'checkbox'}
                              color="default"
                              name={'editOffersOfServiceCheckbox'}
                              Label={{
                                label: t(
                                  'AUTOMATIC_OFFERS.EDIT_OFFERS_OF_SERVICE',
                                ),
                              }}
                              style={{ marginLeft: '15px' }}
                            />
                          </Box>
                        </Grid>
                      )}
                      {mode !== 'COPY' && automaticOfferPosition && (
                        <Grid item xs={12}>
                          <Box margin={2}>
                            <Field
                              component={CheckboxWithLabel}
                              type={'checkbox'}
                              color="default"
                              name={'reactivateOffersOfServiceCheckbox'}
                              Label={{
                                label: t(
                                  'AUTOMATIC_OFFERS.REACTIVATE_OFFERS_OF_SERVICE',
                                ),
                              }}
                              style={{ marginLeft: '15px' }}
                            />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                    <ProductConfigurator
                      readOnly={false}
                      show={showProductConfigurator}
                      close={(e) => {
                        setShowProductConfigurator(false)
                      }}
                      createPositionFunction={(product, posProperties) => {
                        setShowProductConfigurator(false)
                        setFieldValue('productId', product.id)
                        setFieldValue(
                          'productUnitId',
                          product.productUnits.find((productUnit) =>
                            [3, 5, 7].includes(productUnit.id),
                          ).id,
                        )
                        setFieldValue('positionProperties', posProperties)
                      }}
                    />
                  </Form>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => hide()}
                    variant="outlined"
                    color={'secondary'}
                  >
                    {t('GENERAL.CANCEL')}
                  </Button>
                  <Button
                    onClick={() => submitForm()}
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    variant={
                      automaticOfferPosition &&
                      automaticOfferPosition.state === 'ARCHIVED'
                        ? 'outlined'
                        : 'contained'
                    }
                    color="secondary"
                  >
                    {t('GENERAL.CONFIRM')}
                  </Button>
                  {automaticOfferPosition &&
                    automaticOfferPosition.state === 'ARCHIVED' && (
                      <Button
                        onClick={async () => {
                          await setFieldValue('state', 'ACTIVE')
                          await submitForm()
                        }}
                        disabled={!isValid || isSubmitting}
                        type="submit"
                        variant="contained"
                        color="secondary"
                      >
                        {t('AUTOMATIC_OFFERS.SUBMIT_AND_ACTIVATE')}
                      </Button>
                    )}
                </DialogActions>
              </Dialog>

              {selectedCountryId && (
                <ZipcodeAreaDialog
                  countryId={selectedCountryId}
                  stackHeightProductLookup={stackHeightProductLookup}
                  data={values}
                  submit={(zipcodeAreaData) => {
                    setFieldValue(
                      `zipcodeAreas.${selectedCountryId}`,
                      zipcodeAreaData,
                    )
                    setSelectedCountryId(null)
                  }}
                  partnerLocation={partnerLocation}
                  hide={() => setSelectedCountryId(null)}
                />
              )}
            </Fragment>
          )
        }}
      </Formik>
    </Grid>
  )
}

export default withTranslation()(ComposeAutomaticOfferPositionDialog)
