import { Edit } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { badgesService } from '../../../../../../_services/badgesService'

const BadgeManagement = (props) => {
  const { t } = props
  const [badges, setBadges] = useState([])
  const [editingBadge, setEditingBadge] = useState(null)
  const [openEditDialog, setOpenEditDialog] = useState(false)

  useEffect(() => {
    fetchBadges()
  }, [])

  const fetchBadges = () => {
    badgesService
      .getBadges()
      .then((data) => {
        setBadges(data)
      })
      .catch((error) => {
        console.error('There was an error retrieving the badges', error)
      })
  }

  const handleOpenEditDialog = (badge) => {
    setEditingBadge(badge)
    setOpenEditDialog(true)
  }
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false)
    setEditingBadge(null)
  }
  const handleDeleteBadge = (badgeId) => {
    badgesService
      .deleteBadge(badgeId)
      .then(() => {
        fetchBadges()
      })
      .catch((error) => {
        console.error('There was an error deleting the badge', error)
      })
  }
  const handleUpdateBadge = (values) => {
    if (editingBadge) {
      handleOpenEditDialog()
      const payload = {
        name: values.name,
        imageLink: values.imageLink,
      }
      badgesService
        .updateBadge(values.id, payload)
        .then(() => {
          fetchBadges()
          handleCloseEditDialog()
        })
        .catch((error) => {
          console.error('There was an error updating the badge', error)
        })
    } else {
      console.error('Editing badge is null')
    }
  }
  const valSchema = Yup.object().shape({
    badgeName: Yup.string().required(t('GENERAL.REQUIRED')),
    badgeImageLink: Yup.string().required(t('GENERAL.REQUIRED')),
  })
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Formik
          initialValues={{ badgeName: '', badgeImageLink: '' }}
          onSubmit={(values, { resetForm }) => {
            const payload = {
              name: values.badgeName,
              imageLink: values.badgeImageLink,
            }
            badgesService
              .createBadge(payload)
              .then(() => {
                fetchBadges()
                resetForm()
              })
              .catch((error) => {
                console.error('There was an error creating the badge', error)
              })
          }}
          validationSchema={valSchema}
          enableReinitialize={true}
        >
          {() => (
            <Form>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={12} md={5}>
                  <Field
                    component={TextField}
                    name="badgeName"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label={t('BADGE.NAME')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Field
                    component={TextField}
                    name="badgeImageLink"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    label={t('BADGE.LINK')}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2} container justify="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{ height: '100%', width: '100%', maxWidth: 'none' }}
                  >
                    {t('BADGE.CREATE')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('BADGE.ICON')}</TableCell>
                <TableCell>{t('BADGE.NAME')}</TableCell>
                <TableCell>{t('BADGE.PATH')}</TableCell>
                <TableCell>{t('BADGE.ACTIONS')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(badges) &&
                badges.map((badge) => (
                  <TableRow key={badge.id}>
                    <TableCell
                      style={{ display: 'table-cell', verticalAlign: 'middle' }}
                    >
                      <img
                        src={badge.imageLink}
                        alt={badge.name}
                        style={{
                          width: '100px',
                          height: '100px',
                          marginRight: '10px',
                        }}
                      />
                    </TableCell>
                    <TableCell> {badge.name}</TableCell>
                    <TableCell>{badge.imageLink}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleDeleteBadge(badge.id)}>
                        <DeleteIcon />
                      </IconButton>
                      <IconButton onClick={() => handleOpenEditDialog(badge)}>
                        <Edit></Edit>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>{t('BADGE.EDIT')}</DialogTitle>
        <Formik
          initialValues={{
            id: editingBadge ? editingBadge.id : undefined,
            name: editingBadge ? editingBadge.name : '',
            imageLink: editingBadge ? editingBadge.imageLink : '',
          }}
          enableReinitialize={true}
          onSubmit={(values) => handleUpdateBadge(values)}
        >
          {() => (
            <Form>
              <Grid container>
                <DialogContent>
                  <Grid item>
                    <DialogContentText>
                      <Typography
                        variant="body1"
                        style={{ marginBottom: '5px' }}
                      >
                        {t('BADGE.EDIT_TEXT')}
                      </Typography>
                    </DialogContentText>
                  </Grid>
                  <Grid item>
                    <Field
                      component={TextField}
                      name="name"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      label={t('BADGE.NAME')}
                    />
                    <Field
                      component={TextField}
                      name="imageLink"
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      label={t('BADGE.LINK')}
                    />
                  </Grid>
                </DialogContent>

                <DialogActions>
                  <Button type="submit" color="secondary">
                    {t('GENERAL.SAVE')}
                  </Button>
                  <Button onClick={handleCloseEditDialog} color="secondary">
                    {t('GENERAL.CANCEL')}
                  </Button>
                </DialogActions>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog>
    </Grid>
  )
}

export default withTranslation()(BadgeManagement)
