import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const tenderOrderAttachmentsService = {
  getTenderOrderAttachment,
  deleteTenderOrderAttachment,
  createTenderOrderAttachmentOfTenderOrder,
  getTenderOrderAttachmentOfTenderOrder,
}

function getTenderOrderAttachment(attachmentId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/tender-order-attachments/${attachmentId}`,
    requestOptions,
  )
}

function deleteTenderOrderAttachment(attachment) {
  if (attachment.new) {
    return true
  }

  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/tender-order-attachments/` + attachment.id,
    requestOptions,
  ).then(function (response) {
    if (response.ok) {
      return true
    } else {
      return false
    }
  })
}

function createTenderOrderAttachmentOfTenderOrder(tenderOrderId, requestBody) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: requestBody,
  }

  return fetch(
    `${config.apiUrlBase}/tender-orders/${tenderOrderId}/tender-order-attachments`,
    requestOptions,
  )
}

function getTenderOrderAttachmentOfTenderOrder(tenderOrderId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/tender-orders/${tenderOrderId}/tender-order-attachments`,
    requestOptions,
  ).then(handleResponse)
}

export * from './tenderOrderAttachmentsService'
