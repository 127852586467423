import {
  Store as AdvertsExchangeIcon,
  MenuBookOutlined as AdvertsIcon,
  BarChart,
  WorkOutline as CompanyDataIcon,
  ReceiptOutlined as ContractsIcon,
  DescriptionOutlined as DocumentsIcon,
  ShoppingCart as MarketplaceIcon,
  LocalShippingOutlined as OrdersIcon,
  Person as ProfileIcon,
  AssignmentOutlined as RequestsIcon,
  ShowChart,
  SmartToy,
  GavelOutlined as TendersIcon,
  PeopleOutlined as UsersIcon,
} from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import HomeIcon from '@mui/icons-material/Home'
import MailIcon from '@mui/icons-material/Mail'
import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const PREFIX = 'index'

const classes = {
  breadcrumb: `${PREFIX}-breadcrumb`,
  link: `${PREFIX}-link`,
  linkCurrent: `${PREFIX}-linkCurrent`,
  icon: `${PREFIX}-icon`,
}

const BreadcrumbsRouted = (props) => {
  const { t } = props

  const breadcrumbIconMap = {
    requests: (
      <RequestsIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    tenders: (
      <TendersIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    adverts: (
      <AdvertsIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    offers_requests: (
      <RequestsIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    offers_tenders: (
      <TendersIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    offers_adverts: (
      <AdvertsIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    adverts_exchange: (
      <AdvertsExchangeIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    marketplace: (
      <MarketplaceIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    contracts: (
      <ContractsIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    deliveries_manufacturer: (
      <OrdersIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    deliveries_customer: (
      <OrdersIcon
        className={classes.icon}
        style={{ transform: 'scaleX(-1)', verticalAlign: 'middle' }}
      />
    ),
    company_data: (
      <CompanyDataIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    communities: (
      <GroupsIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    company_users: (
      <UsersIcon className={classes.icon} style={{ verticalAlign: 'middle' }} />
    ),
    company_documents: (
      <DocumentsIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    mail_settings: (
      <MailIcon className={classes.icon} style={{ verticalAlign: 'middle' }} />
    ),
    user_settings: (
      <ProfileIcon
        className={classes.icon}
        style={{ verticalAlign: 'middle' }}
      />
    ),
    charts: (
      <BarChart className={classes.icon} style={{ verticalAlign: 'middle' }} />
    ),
    automatic_offers: (
      <SmartToy className={classes.icon} style={{ verticalAlign: 'middle' }} />
    ),
    indexes: (
      <ShowChart className={classes.icon} style={{ verticalAlign: 'middle' }} />
    ),
  }

  const location = useLocation()

  const pathnames = location.pathname.split('/').filter((x) => x)

  if (pathnames.length === 0) return null

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ height: 56, padding: '4px 0' }}
    >
      <Breadcrumbs aria-label="Breadcrumb" className={classes.breadcrumb}>
        <Grid container>
          <Grid item>
            <Link href="/" className={classes.link}>
              <HomeIcon
                className={classes.icon}
                style={{ verticalAlign: 'middle' }}
              />
              {t('manual:BREADCRUMBS.DASHBOARD')}
            </Link>
          </Grid>
        </Grid>

        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1
          const to = `/${pathnames.slice(0, index + 1).join('/')}`
          value = value.split('-').join('_')

          if (value !== 'tender' && value !== 'request' && value !== 'advert') {
            return last ? (
              <Typography key={to} className={classes.linkCurrent}>
                {breadcrumbIconMap[value] ? breadcrumbIconMap[value] : null}
                {t('manual:BREADCRUMBS.' + value.toUpperCase())}
              </Typography>
            ) : (
              <Link to={to} key={to} className={classes.link}>
                {breadcrumbIconMap[value] ? breadcrumbIconMap[value] : null}
                {t('manual:BREADCRUMBS.' + value.toUpperCase())}
              </Link>
            )
          } else {
            //Exception: When in request or tender View
            const search = location.search
            const params = new URLSearchParams(search)
            const id = params.get('id')
            const mode = params.get('mode') || 'create'

            let elements = []

            let type =
              value === 'request'
                ? 'requests'
                : value === 'tender'
                ? 'tenders'
                : 'adverts'
            elements.push(
              <Link href={'/' + type} to={to} key={to} className={classes.link}>
                {breadcrumbIconMap[type] ? breadcrumbIconMap[type] : null}
                {t('manual:BREADCRUMBS.' + type.toLocaleUpperCase())}
              </Link>,
            )

            if (mode === 'edit') {
              elements.push(
                <Typography key={to} className={classes.linkCurrent}>
                  {id}
                </Typography>,
              )
            } else {
              elements.push(
                <Link to={to} key={to} className={classes.link}>
                  {breadcrumbIconMap[value] ? breadcrumbIconMap[value] : null}
                  {mode === 'edit'
                    ? t('manual:BREADCRUMBS.EDIT_' + value.toUpperCase())
                    : t('manual:BREADCRUMBS.CREATE_' + value.toUpperCase())}
                </Link>,
              )
            }
            return elements.map((element) => element)
          }
        })}
      </Breadcrumbs>
    </Box>
  )
}

export default withTranslation()(BreadcrumbsRouted)
