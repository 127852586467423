import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header.js'

export const surveyQuestionsService = {
  createSurveyQuestionOfSurvey,
  deleteSurveyQuestionOfSurvey,
  updateSurveyQuestionOfSurvey,
  getSurveyQuestion,
}

function createSurveyQuestionOfSurvey(surveyId, question) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(question),
  }
  return fetch(
    `${config.apiUrlBase}/surveys/${surveyId}/survey-questions`,
    requestOptions,
  ).then(handleResponse)
}

function deleteSurveyQuestionOfSurvey(questionId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/survey-questions/${questionId}`,
    requestOptions,
  ).then(handleResponse)
}

function updateSurveyQuestionOfSurvey(questionId, payload) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(
    `${config.apiUrlBase}/survey-questions/${questionId}`,
    requestOptions,
  ).then(handleResponse)
}

function getSurveyQuestion(questionId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/survey-questions/${questionId}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './surveyQuestionsService'
