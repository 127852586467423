import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const productUnitsService = {
  getProductUnits,
  createProductUnit,
  updateProductUnit,
  deleteProductUnit,
}

function getProductUnits() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/product-units`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function createProductUnit(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(`${config.apiUrlBase}/product-units`, requestOptions).then(
    handleResponse,
  )
}

function updateProductUnit(productUnitId, payload) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(
    `${config.apiUrlBase}/product-units/${productUnitId}`,
    requestOptions,
  ).then(handleResponse)
}

function deleteProductUnit(productUnitId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/product-units/${productUnitId}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './productUnitsService'
