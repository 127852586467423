import { Info } from '@mui/icons-material'
import { Box, Grid, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { countryLookup, indexLookup } from '../../_constants/lookupConstants'
import { translateProductUnit } from '../../_helpers/little'
import { formatToNumber } from '../../_helpers/number-formatting'
import { generalService } from '../../_services/generalService'
import DifferenceWrapper from '../DifferenceWrapper'
import FormattedPrice from '../FormattedPrice'
import PositionCard from '../PositionCard'
import Rank from '../Rank'
import { Section } from '../Section'

const OfferDetailsPositions = ({
  t,
  data,
  differences,
  diffObjectType,
  diffData,
}) => {
  return (
    <Section header={t('OFFER.POSITIONS')}>
      <Grid container spacing={3} direction="row" alignItems="stretch">
        {data.positions
          .filter((pos) => pos.amount > 0)
          .map((pos) => {
            var combinedPositionProperties = []
            differences.positions.forEach((pos) => {
              combinedPositionProperties.push(...pos.positionProperties)
            })
            var showDifference =
              combinedPositionProperties.filter(
                (positionProperty) => positionProperty.value !== false,
              ).length > 0

            const location =
              data.generalInformation.deliveryDetails.locations.find(
                (loc) => loc.id === pos.locationId,
              )

            return (
              <Grid item xs={12} sm={6} key={pos.id}>
                <PositionCard
                  data={pos}
                  readOnly={true}
                  differences={differences.positions.find(
                    (diffPos) =>
                      parseInt(diffPos.id) === parseInt(pos.id) &&
                      parseInt(diffPos.locationId) === parseInt(pos.locationId),
                  )}
                  diffData={diffData.positions.find(
                    (diffPos) => parseInt(diffPos.id) === parseInt(pos.id),
                  )}
                  diffObjectType={diffObjectType}
                  showAmount={true}
                  showImage={true}
                  showProductDetails={true}
                  showDifference={showDifference}
                  imageSize={'small'}
                >
                  {data.request && data.request.type === 'TENDER' && (
                    <Fragment>
                      <Typography>{`${t('OFFER.LOCATION')} ${
                        pos.locationId
                      }`}</Typography>
                      <Typography>
                        {location.address ? `${location.address}, ` : null}
                        {`${location.zipcode} ${location.place}`}
                        {location.countryId
                          ? `, ${countryLookup[location.countryId]}`
                          : null}
                      </Typography>
                    </Fragment>
                  )}
                  {<FormattedPrice pos={pos} differences={differences} />}

                  {pos.index && (
                    <DifferenceWrapper
                      showDifference={
                        differences.positions.find(
                          (diffPos) =>
                            parseInt(diffPos.id) === parseInt(pos.id) &&
                            parseInt(diffPos.locationId) ===
                              parseInt(pos.locationId),
                        ).index
                          ? true
                          : false
                      }
                      difference={
                        differences.positions.find(
                          (diffPos) =>
                            parseInt(diffPos.id) === parseInt(pos.id) &&
                            parseInt(diffPos.locationId) ===
                              parseInt(pos.locationId),
                        ).index
                      }
                      differenceDisplay={
                        differences.positions.find(
                          (diffPos) =>
                            parseInt(diffPos.id) === parseInt(pos.id) &&
                            parseInt(diffPos.locationId) ===
                              parseInt(pos.locationId),
                        ).index
                      }
                      diffObjectType={diffObjectType}
                    >
                      <Tooltip title={t('REQUEST.REFERENCE_INDEX_TOOLTIP')}>
                        <Box display="flex" alignItems="center">
                          <Typography sx={{ marginRight: 1 }}>
                            {`${t('REQUEST.REFERENCE_INDEX')}: ${
                              indexLookup[pos.index]
                            }`}
                          </Typography>
                          <Info color="info" fontSize="medium" />
                        </Box>
                      </Tooltip>
                    </DifferenceWrapper>
                  )}
                  {pos.rank && pos.rank !== 'N/A' && (
                    <Typography>
                      <Rank rank={pos.rank}>{t('OFFERS.RANK')} </Rank>
                    </Typography>
                  )}

                  {data.request.type !== 'TENDER' &&
                    data.deliveries.map((delivery) => {
                      let dPos = delivery.positions.find(
                        (dPos) => pos.id === dPos.positionId,
                      )

                      return (
                        <DifferenceWrapper
                          showDifference={differences && differences.deliveries}
                          difference={
                            differences ? differences.deliveries : false
                          }
                          differenceDisplayOverride={t(
                            'OFFER.DELIVERIES_CHANGED',
                          )}
                          diffObjectType={diffObjectType}
                        >
                          {`${
                            data.generalInformation.deliveryDetails.incoTerm ===
                            'EXW'
                              ? t('REQUEST.DELIVERY_DETAILS.EX_WORKS')
                              : t('REQUEST.DELIVERY')
                          } ${
                            delivery.type === 'date'
                              ? moment(delivery.value).format('DD.MM.YYYY')
                              : `${t(
                                  'ORDERS.CW',
                                )} ${generalService.convertDateToCalendarWeek(
                                  delivery.value,
                                )}`
                          }: ${formatToNumber(dPos?.amount)}`}
                          {translateProductUnit(
                            pos.product.productUnits.find(
                              (productUnit) =>
                                productUnit.id === pos.productUnitId,
                            ),
                          )}
                        </DifferenceWrapper>
                      )
                    })}
                </PositionCard>
              </Grid>
            )
          })}
      </Grid>
    </Section>
  )
}

export default withTranslation()(OfferDetailsPositions)
