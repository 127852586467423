import config from '../_config/config.js'
import { authHeader } from '../_helpers/auth-header'

export const attachmentsService = {
  getAttachment,
  deleteAttachment,
  createAttachmentOfRequest,
}

function getAttachment(attachment) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/attachments/` + attachment.id,
    requestOptions,
  )
}

function deleteAttachment(attachment) {
  if (attachment.new) {
    return true
  }

  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/attachments/` + attachment.id,
    requestOptions,
  ).then(function (response) {
    if (response.ok) {
      return true
    } else {
      return false
    }
  })
}

function createAttachmentOfRequest(requestId, requestBody) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: requestBody,
  }
  return fetch(
    `${config.apiUrlBase}/requests/` + requestId + '/attachments',
    requestOptions,
  )
}

export * from './attachmentsService'
