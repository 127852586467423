import { Add } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import { TextField } from 'formik-mui'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { countryLookup } from '../../../../../../../../_constants/lookupConstants'
import { reorgArray } from '../../../../../../../../_helpers/little'
import NumberFormatCustom from '../../../../../../../../_helpers/numberFormatCustom'
import PositionCard from '../../../../../../../PositionCard'
import ProductConfigurator from '../../../../../../../ProductConfigurator'
import { Section } from '../../../../../../../Section'

const TenderOrderRegistrationPositions = (props) => {
  const { t, data, setFieldValue } = props

  const [showProductConfigurator, setShowProductConfigurator] = useState(false)

  return (
    <Fragment>
      <Section header={t('REQUEST.TENDER_POSITIONS')}>
        <ErrorMessage
          name={'positions'}
          render={(msg) =>
            typeof msg === 'string' ? (
              <div className="help-block">{msg}</div>
            ) : null
          }
        />
        <Button
          startIcon={<Add />}
          variant="contained"
          color="secondary"
          size={'small'}
          style={{ marginBottom: 10, marginTop: 10 }}
          onClick={() => setShowProductConfigurator(true)}
        >
          {t('REQUEST.ADD_POSITION')}
        </Button>
        <ProductConfigurator
          readOnly={false}
          show={showProductConfigurator}
          data={data.details}
          close={(e) => {
            setShowProductConfigurator(false)
          }}
          createPositionFunction={(product, positionProperties) => {
            setShowProductConfigurator(false)
            var newRowData = {
              productId: product.id,
              amount: 0,
              positionProperties: positionProperties,
              product: product,
              productUnitId: product.productUnits.filter(
                (productUnit) => productUnit.isTenderUnit,
              )[0].id,
            }

            const res = data.positions
            const length = data.positions.length
            const nextId = length + 1
            let newData = {
              ...newRowData,
              id: nextId,
            }
            res.push(newData)
            setFieldValue('positions', res)

            let locations =
              data.generalInformation.deliveryDetails.locations.map(
                (location) => {
                  const locationPositions = location.positions

                  locationPositions.push({
                    ...newData,
                    positionId: newData.id,
                    amount: 0,
                    checked: true,
                  })

                  return {
                    ...location,
                    positions: locationPositions,
                  }
                },
              )
            setFieldValue(
              'generalInformation.deliveryDetails.locations',
              locations,
            )
          }}
        />

        <Grid container spacing={3} direction="row" justifyContent="center">
          {data.positions.map((pos, index) => {
            let amount = 0
            data.generalInformation.deliveryDetails.locations.forEach(
              (location) => {
                let position = location.positions.find((lPos) => {
                  return pos.id === lPos.id
                })

                if (position && position.checked && position.amount) {
                  amount += parseInt(position.amount)
                }
              },
            )
            return (
              <Grid item xs={12} sm={3}>
                <PositionCard
                  data={pos}
                  readOnly={false}
                  showProductDetails={true}
                  updatePositionFunction={(product, positionProperties) => {
                    var newRowData = {
                      productId: product.id,
                      productUnitId: pos.productUnitId,
                      positionProperties: positionProperties,
                      product: product,
                    }

                    const newPositions = [...data.positions]

                    let i = newPositions.findIndex(
                      (newPos) => newPos.id === pos.id,
                    )
                    newPositions[i] = {
                      ...newRowData,
                      id: newPositions[i].id,
                      amount: newPositions[i].amount
                        ? newPositions[i].amount
                        : 0,
                    }
                    setFieldValue('positions', newPositions)
                    // update all existing location positions accordingly
                    data.generalInformation.deliveryDetails.locations.forEach(
                      async (location) => {
                        const locationPositions = location.positions

                        locationPositions.splice(index, 1, {
                          ...newPositions[i],
                          positionId: newPositions[i].id,
                          amount: locationPositions[index].amount,
                          productUnitId: locationPositions[index].productUnitId,
                          checked: locationPositions[index].checked,
                        })

                        await setFieldValue(
                          'generalInformation.deliveryDetails.locations.positions',
                          locationPositions,
                        )
                      },
                    )
                  }}
                  deletePositionFunction={() => {
                    var posData = data.positions

                    posData.splice(index, 1)
                    reorgArray(posData, 'id', 1)

                    data.generalInformation.deliveryDetails.locations.forEach(
                      async (location) => {
                        const locationPositions = location.positions

                        locationPositions.splice(index, 1)
                        reorgArray(locationPositions, 'positionId', 1)

                        await setFieldValue(
                          'generalInformation.deliveryDetails.locations.positions',
                          locationPositions,
                        )
                      },
                    )
                    setFieldValue('positions', posData)
                  }}
                  showAmount={true}
                  amount={amount}
                />
              </Grid>
            )
          })}
        </Grid>
      </Section>

      {data.positions.length > 0 &&
        data.generalInformation.deliveryDetails.locations.map(
          (location, index) => (
            <Section
              header={
                <Fragment>
                  {t('REQUEST.LOCATION')}
                  {' ' + (index + 1)}
                  {location.company ? ` | ${location.company}` : null}
                  {location.countryId
                    ? ` | ${countryLookup[location.countryId]}`
                    : null}
                  {location.address ? ` | ${location.address}` : null}
                  {location.zipcode ? ` | ${location.zipcode}` : null}
                  {location.place ? ` | ${location.place}` : null}
                </Fragment>
              }
            >
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
              >
                {location.positions.map((pos, posIndex) => {
                  return (
                    <Grid item xs={12} sm={3}>
                      <PositionCard
                        data={pos}
                        readOnly={true}
                        showProductDetails={true}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Field
                              fullWidth={true}
                              component={TextField}
                              margin="dense"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                decimalScale:
                                  data.generalInformation.deliveryDetails
                                    .locations[0].positions[index]
                                    .productUnitId &&
                                  data.generalInformation.deliveryDetails.locations[0].positions[
                                    index
                                  ].product.productUnits.find((productUnit) => {
                                    return (
                                      productUnit.id ===
                                      data.generalInformation.deliveryDetails
                                        .locations[0].positions[index]
                                        .productUnitId
                                    )
                                  }).type === 'FLOAT'
                                    ? 2
                                    : 0,
                                allowNegative: false,
                              }}
                              label={t('REQUEST.AMOUNT')}
                              variant="outlined"
                              name={
                                'generalInformation.deliveryDetails.locations[' +
                                index +
                                '].positions[' +
                                posIndex +
                                '].amount'
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Field
                              fullWidth={true}
                              component={TextField}
                              margin="dense"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                inputComponent: NumberFormatCustom,
                                decimalScale: 2,
                                allowNegative: false,
                                suffix: ' \u20AC',
                                fixedDecimalScale: true,
                              }}
                              label={t('ORDER_REGISTRATION.PURCHASE_PRICE')}
                              variant="outlined"
                              name={
                                'generalInformation.deliveryDetails.locations[' +
                                index +
                                '].positions[' +
                                posIndex +
                                '].purchasePrice'
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Field
                              fullWidth={true}
                              component={TextField}
                              margin="dense"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                inputComponent: NumberFormatCustom,
                                decimalScale: 2,
                                allowNegative: false,
                                suffix: ' \u20AC',
                                fixedDecimalScale: true,
                              }}
                              label={t('ORDER_REGISTRATION.SALES_PRICE')}
                              variant="outlined"
                              name={
                                'generalInformation.deliveryDetails.locations[' +
                                index +
                                '].positions[' +
                                posIndex +
                                '].salesPrice'
                              }
                            />
                          </Grid>
                        </Grid>
                      </PositionCard>
                    </Grid>
                  )
                })}
              </Grid>
            </Section>
          ),
        )}
    </Fragment>
  )
}

export default withTranslation()(connect()(TenderOrderRegistrationPositions))
