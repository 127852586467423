import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { partnerProductsService } from '../../../../../../../../_services/partnerProductsService'
import { productsService } from '../../../../../../../../_services/productsService'

const ProductStatesAllowedDialog = (props) => {
  const {
    open,
    handleClose,
    handleCancel,
    title,
    partnerProduct,
    forceUpdate,
  } = props

  const [states, setStates] = useState([])
  const [selectedStates, setSelectedStates] = useState(partnerProduct.states)

  const handleCheckboxChange = (state) => {
    const selectedIndex = selectedStates.findIndex((item) => item === state)
    let newSelectedStates = []

    if (selectedIndex === -1) {
      newSelectedStates = [...selectedStates, state]
    } else {
      newSelectedStates = selectedStates.filter((item) => item !== state)
    }
    setSelectedStates(newSelectedStates)
  }
  useEffect(() => {
    productsService
      .getProduct(partnerProduct.productId)
      .then((data) => {
        const filteredEnumValues = []
        data.productProperties.forEach((property) => {
          if (property.productPropertyEnumValues) {
            const filteredValues = property.productPropertyEnumValues.filter(
              (enumValue) => {
                const allowedStates = [68, 44, 45, 21]
                return allowedStates.includes(enumValue.productPropertyId)
              },
            )
            filteredEnumValues.push(...filteredValues)
          }
        })

        const filteredStates = filteredEnumValues.map((enumValue) => {
          return enumValue.value
        })

        setStates(filteredStates)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }, [partnerProduct.productId])

  const handleSaveClick = async () => {
    await partnerProductsService.updatePartnerProduct(partnerProduct.id, {
      states: selectedStates,
    })
    forceUpdate()
    handleCancel()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Wählen sie die erlaubten States für das Produkt
        </DialogContentText>
        {states.map((state) => (
          <div key={state}>
            <Checkbox
              label={state}
              checked={selectedStates.some((item) => item === state)}
              onChange={() => handleCheckboxChange(state)}
            />
            {state}
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSaveClick} color="secondary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProductStatesAllowedDialog
