import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { Checkbox, TextField } from 'formik-mui'
import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { authorize } from '../../../../../../_helpers/authorization'
import { usersService } from '../../../../../../_services/usersService'
import CustomDialogTitle from '../../../../../CustomDialogTitle'
import { Loading } from '../../../../../Loading'

const RateOrderDialog = (props) => {
  const { t, show, hide } = props

  useEffect(() => {}, [])
  var userObject = JSON.parse(localStorage.getItem('user'))

  const [isLoading, setIsLoading] = React.useState(false)

  const phoneRegExp = /^[\d+]+$/

  const valSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('GENERAL.REQUIRED'))
      .email(t('GENERAL.INVALID_EMAIL')),
    salutation: Yup.string(),
    firstname: Yup.string().required(t('GENERAL.REQUIRED')),
    lastname: Yup.string().required(t('GENERAL.REQUIRED')),
    position: Yup.string(),
    languages: Yup.string(),
    phone: Yup.string()
      .matches(phoneRegExp, t('GENERAL.INVALID_PHONE'))
      .required(t('GENERAL.REQUIRED')),
    mobile: Yup.string(),
    fax: Yup.string(),
    skipMailing: Yup.string().required(t('GENERAL.REQUIRED')),
  })

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Formik
        enableReinitialize={true}
        initialValues={{
          salutation: 'MR',
          email: '',
          firstname: '',
          lastname: '',
          phone: '',
          mobile: '',
          fax: '',
          notificationLanguage: 'DE',
          skipMailing: false,
        }}
        validationSchema={valSchema}
        //validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          setIsLoading(true)
          setSubmitting = true
          usersService
            .createUserOfPartner(userObject.partner.id, values)
            .then((data) => {
              window.location.reload()
            })
            .catch(function (error) {
              alert(error)

              console.log(error)
            })
            .finally(() => {
              setIsLoading(false)
              setSubmitting = false
            })
        }}
      >
        {({ values, setFieldValue, isSubmitting, submitForm, isValid }) => (
          <Dialog
            onClose={() => hide()}
            fullWidth={true}
            maxWidth="md"
            open={show}
          >
            <CustomDialogTitle
              title={t('USERS.CREATE_USER')}
              onClose={() => hide()}
            />
            <DialogContent>
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <Field
                      component={TextField}
                      select
                      name="salutation"
                      label={t('REGISTER.SALUTATION')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    >
                      <MenuItem key="MR" value="MR">
                        {t('REGISTER.MR')}
                      </MenuItem>
                      <MenuItem key="MS" value="MS">
                        {t('REGISTER.MS')}
                      </MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      component={TextField}
                      name="firstname"
                      label={t('REGISTER.FIRST_NAME')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      component={TextField}
                      name="lastname"
                      label={t('REGISTER.LAST_NAME')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Field
                      component={TextField}
                      select
                      name="notificationLanguage"
                      label={t('REGISTER.NOTIFICATION_LANGUAGE')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    >
                      <MenuItem key="DE" value="DE">
                        {t('REGISTER.GERMAN')}
                      </MenuItem>
                      <MenuItem key="EN" value="EN">
                        {t('REGISTER.ENGLISH')}
                      </MenuItem>
                      <MenuItem key="PL" value="PL">
                        {t('REGISTER.POLISH')}
                      </MenuItem>
                      <MenuItem key="CS" value="CS">
                        {t('REGISTER.CZECH')}
                      </MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="position"
                      label={t('REGISTER.POSITION')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      component={TextField}
                      name="phone"
                      label={t('REGISTER.PHONE')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      component={TextField}
                      name="mobile"
                      label={t('REGISTER.MOBILE')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Field
                      component={TextField}
                      name="fax"
                      label={t('REGISTER.FAX')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="email"
                      label={t('REGISTER.EMAIL')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    />
                  </Grid>
                  {authorize('administrator') && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Field
                            component={Checkbox}
                            type={'checkbox'}
                            color="default"
                            margin="dense"
                            name="skipMailing"
                            style={{ margin: '0px' }}
                          />
                        }
                        label={t('REGISTER.SKIP_MAILING')}
                        style={{ margin: '0 0 0 10px' }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => submitForm()}
                disabled={!isValid || isSubmitting}
                type="submit"
                variant="contained"
                color="secondary"
              >
                {t('USERS.CREATE_USER')}
              </Button>
              <Button
                onClick={() => hide()}
                variant="outlined"
                color="secondary"
              >
                {t('USERS.CANCEL')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    )
  )
}

export default withTranslation()(RateOrderDialog)
