import TranslateIcon from '@mui/icons-material/Translate'
import { Grid, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import React, { Fragment, useCallback, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryLookup,
  stackHeightUnitLookup,
  unloadLookup,
} from '../../_constants/lookupConstants'
import { _applicationService } from '../../_services/_applicationService'
import DeliveryTimesView from '../DeliveryTimesView'
import { Section } from '../Section'

const DeliveryDetailsMultipleLocationsView = (props) => {
  const { t, data } = props
  const [showTranslated, setShowTranslated] = useState(false)
  const [translations, setTranslations] = useState({})
  const toggleTranslation = () => {
    setShowTranslated(!showTranslated)
    if (!showTranslated) {
      data.generalInformation.deliveryDetails.locations.forEach(
        (location, index) => {
          translateLocation(location, index)
        },
      )
    }
  }
  const translateLocation = useCallback(
    async (location, index) => {
      if (
        !showTranslated &&
        location.deliveryInformations &&
        !translations[index]
      ) {
        const translatedText = await _applicationService.translateText(
          location.deliveryInformations,
        )
        setTranslations((prevTranslations) => ({
          ...prevTranslations,
          [index]: translatedText,
        }))
      }
    },
    [showTranslated, translations],
  )

  return (
    <Fragment>
      {data.generalInformation.deliveryDetails.locations.map(
        (location, index) => (
          <Section
            header={t('REQUEST.DELIVERY_DETAILS.LOCATION') + (index + 1)}
            hideBorder={true}
          >
            <Grid container spacing={1}>
              {
                <Grid container item xs={12}>
                  <Grid item xs={12} style={{ alignSelf: 'center' }}>
                    <Typography variant={'subtitle1'}>
                      {t('OFFER.DELIVERY_ADDRESS') + ':'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} style={{ alignSelf: 'center' }}>
                    <Typography variant={'subtitle2'} color={'text.secondary'}>
                      {location.company && (
                        <Fragment>{`${location.company}, ${location.address}, `}</Fragment>
                      )}
                      <Fragment>{`${location.zipcode} ${location.place}, ${
                        countryLookup[location.countryId]
                      }`}</Fragment>
                    </Typography>
                  </Grid>
                </Grid>
              }
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} style={{ alignSelf: 'center' }}>
                <Typography variant={'subtitle1'}>
                  {t('DELIVERY_DETAILS.POSSIBLE_LOADING') + ':'}
                </Typography>
              </Grid>
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {unloadLookup[location.unload]}
              </Typography>
            </Grid>
            {location.stackHeight && (
              <Grid container item xs={12}>
                <Grid item xs={12} style={{ alignSelf: 'center' }}>
                  <Typography variant={'subtitle1'}>
                    {t('DELIVERY_DETAILS.STACK_HEIGHT') + ':'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                    {`${location.stackHeight} ${
                      stackHeightUnitLookup[location.stackHeightUnit]
                    }`}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid container item xs={12}>
              <Grid item xs={12} style={{ alignSelf: 'center' }}>
                <DeliveryTimesView data={location.deliveryTimes} />
              </Grid>
            </Grid>
            {location.isDespatchAdviceRequired && (
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12} style={{ alignSelf: 'center' }}>
                    <Typography variant={'subtitle1'}>
                      {t('OFFER.DESPATCH_ADVICE_INSTRUCTIONS') + ':'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'subtitle2'} color={'text.secondary'}>
                      {location.despatchAdviceInstructions}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid container item xs={12}>
              <Grid item xs={12} style={{ alignSelf: 'flex-start' }}>
                <Typography variant={'subtitle1'}>
                  {t('OFFER.DELIVERY_INFORMATION') + ':'}
                </Typography>
              </Grid>
              <Grid container spacing={1}>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <Grid
                    item
                    style={{ flexShrink: 0, flexGrow: 1, marginRight: 8 }}
                  >
                    <Typography
                      variant={'subtitle2'}
                      color={'text.secondary'}
                      sx={{ whiteSpace: 'normal' }}
                    >
                      {showTranslated && translations[index] ? (
                        translations[index]
                      ) : location.deliveryInformations &&
                        location.deliveryInformations !== '' ? (
                        location.deliveryInformations
                      ) : (
                        <em>{t('OFFER.NO_DELIVERY_INFORMATION')}</em>
                      )}
                    </Typography>
                  </Grid>
                  {(translations[index] || location.deliveryInformations) && (
                    <Grid item sx={{ flexShrink: 0 }}>
                      <Tooltip title={t('OFFER.TRANSLATION_TOOLTIP')}>
                        <IconButton onClick={toggleTranslation}>
                          <TranslateIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Section>
        ),
      )}
    </Fragment>
  )
}

export default withTranslation()(DeliveryDetailsMultipleLocationsView)
