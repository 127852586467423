import { Box, Toolbar } from '@mui/material'
import PropTypes from 'prop-types'
import React, { Fragment, useLayoutEffect, useRef, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { withTranslation } from 'react-i18next'

const ScrollableFixedHeightContainer = (props) => {
  const { children, toolbar, height } = props

  //Force update on resize: https://stackoverflow.com/questions/19014250/rerender-view-on-browser-resize-with-react
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const toolbarRef = useRef(null)

  let toolbarHeight = 0

  if (toolbarRef.current) {
    toolbarHeight = toolbarRef.current.clientHeight
  }

  return (
    <Fragment>
      <Box height={`calc( ${height} - ${toolbarHeight}px)`}>
        <Scrollbars
          height={`calc( ${height} - ${toolbarHeight}px)`}
          autoHide={false}
        >
          {children}
        </Scrollbars>
      </Box>
      <Toolbar ref={toolbarRef}>
        <Box sx={{ flexGrow: 1 }} />
        {toolbar}
      </Toolbar>
    </Fragment>
  )
}

ScrollableFixedHeightContainer.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default withTranslation()(ScrollableFixedHeightContainer)
