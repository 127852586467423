import { HelpOutline, Save } from '@mui/icons-material'
import { Card, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import React, { Fragment, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { orderAttachmentTypeLookup } from '../../../../../../_constants/lookupConstants'
import { orderAttachmentsService } from '../../../../../../_services/orderAttachmentsService'
import { InfoTooltip } from '../../../../../InfoTooltip'
import { Section } from '../../../../../Section'

const OrderAttachments = (props) => {
  const { t, data } = props

  const [attachments, setAttachments] = React.useState([])

  useEffect(() => {
    if (data) {
      orderAttachmentsService
        .getOrderAttachmentOfOrder(data.id)
        .then((attachments) => {
          setAttachments(attachments)
        })
    }
  }, [data])

  return (
    <Section
      header={
        <Fragment>
          {t('REQUEST.ORDER_ATTACHMENTS')}
          <InfoTooltip
            disableTouchListener
            disableFocusListener
            title={t('REQUEST.ATTACHMENTS_TOOLTIP')}
          >
            <IconButton
              color="default"
              style={{
                padding: 0,
                margin: '0 8px',
              }}
              size="large"
            >
              <HelpOutline />
            </IconButton>
          </InfoTooltip>
        </Fragment>
      }
    >
      {attachments.length > 0 ? (
        <Fragment>
          {[
            'REQUEST_ATTACHMENT',
            'OFFER_ATTACHMENT',
            'SIGNED_DELIVERY_NOTE',
            'CLAIM',
            'DELIVERY_DOCUMENT',
            'OTHER',
          ].map((attachmentType) =>
            attachments.filter(
              (attachment) => attachment.type === attachmentType,
            ).length > 0 ? (
              <Grid container spacing={1} style={{ padding: '15px' }}>
                <Grid item xs={12}>
                  <Typography>
                    {orderAttachmentTypeLookup[attachmentType]}
                  </Typography>
                </Grid>
                {attachments
                  .filter((attachment) => attachment.type === attachmentType)
                  .map((attachment) => (
                    <Grid item xs={12} lg={3} sm={6}>
                      <Card
                        key={attachment.name}
                        elevation={0}
                        style={{ border: '1px solid #ddd' }}
                      >
                        <Grid container>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent="flex-end"
                            wrap="nowrap"
                            style={{ lineHeight: '27px' }}
                          >
                            <Grid
                              item
                              xs
                              zeroMinWidth
                              style={{
                                padding: '0 5px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              <b>{attachment.name}</b>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              style={{
                                textAlign: 'center',
                                backgroundColor: '#ddd',
                              }}
                            >
                              <Tooltip title={t('GENERAL.DOWNLOAD')}>
                                <IconButton
                                  color="default"
                                  size="small"
                                  disabled={attachment.new}
                                  onClick={() => {
                                    orderAttachmentsService
                                      .getOrderAttachment(attachment.id)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        // 2. Create blob link to download
                                        const url = window.URL.createObjectURL(
                                          new Blob([blob]),
                                        )
                                        const link = document.createElement('a')
                                        link.href = url
                                        link.setAttribute(
                                          'download',
                                          attachment.name,
                                        )
                                        // 3. Append to html page
                                        document.body.appendChild(link)
                                        // 4. Force download
                                        link.click()
                                        // 5. Clean up and remove the link
                                        link.parentNode.removeChild(link)
                                      })
                                  }}
                                >
                                  <Save />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            ) : null,
          )}
        </Fragment>
      ) : (
        <div style={{ textAlign: 'center', padding: '10px' }}>
          {t('REQUEST.NO_ATTACHMENTS')}
        </div>
      )}
    </Section>
  )
}

export default connect()(withTranslation()(OrderAttachments))
