import {
  Add,
  ChevronRight,
  Delete,
  Edit,
  ExpandMore,
} from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material'
import { TreeItem, TreeView } from '@mui/x-tree-view'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { _applicationService } from '../../../../_services/_applicationService'
import { productCategoriesService } from '../../../../_services/productCategoriesService'
import { productPropertiesService } from '../../../../_services/productPropertiesService'
import AgreementDialog from '../../../AgreementDialog'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { Loading } from '../../../Loading'

const ProductCategoryEditor = (props) => {
  const { t } = props

  const [expanded, setExpanded] = React.useState([])
  const [selected, setSelected] = React.useState([])

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds)
  }
  const [isLoading, setIsLoading] = useState(true)
  const [productProperties, setProductProperties] = useState([])
  const [productCategories, setProductCategories] = useState([])
  const [productCategoriesFlattened, setProductCategoriesFlattened] = useState(
    [],
  )
  const [selectedItem, setSelectedItem] = useState(null)
  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)

  const valSchema = Yup.object().shape({
    name: Yup.string()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    description: Yup.string().nullable(),
    parentId: Yup.number().nullable(),
    isActive: Yup.bool()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED')),
    renderPosition: Yup.number()
      .typeError(t('GENERAL.REQUIRED'))
      .required(t('GENERAL.REQUIRED'))
      .min(0),
  })

  useEffect(() => {
    setIsLoading(true)
    productPropertiesService.getProductProperties().then((data) => {
      setProductProperties(data)
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    setIsLoading(true)
    _applicationService
      .getProductCategoriesForProductEditor(true)
      .then((data) => {
        setProductCategories(data)
        _applicationService
          .getProductCategoriesForProductEditor(false)
          .then((data) => {
            setProductCategoriesFlattened(data)
            setIsLoading(false)
          })
      })
  }, [forceUpdateCount])

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              size="medium"
              color={'secondary'}
              variant={'contained'}
              startIcon={<Add />}
              onClick={() => {
                setSelectedItem(null)
                setShowCreateDialog(true)
              }}
            >
              {t('PRODUCT_EDITOR.ADD_PRODUCT_CATEGORY')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TreeView
              aria-label="controlled"
              defaultCollapseIcon={<ExpandMore />}
              defaultExpandIcon={<ChevronRight />}
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
            >
              {productCategoryMapping(productCategories)}
            </TreeView>
          </Grid>
        </Grid>

        <AgreementDialog
          open={showDeleteDialog}
          message={t('PRODUCT_EDITOR.DELETE_MESSAGE_PRODUCT_CATEGORY')}
          acceptButtonText={t('GENERAL.CONFIRM')}
          handleClose={() => {
            setShowDeleteDialog(false)
            setSelectedItem(null)
          }}
          agree={() => {
            setIsLoading(true)
            setShowDeleteDialog(false)
            productCategoriesService
              .deleteProductCategory(selectedItem.id)
              .then(() => {
                forceUpdate(forceUpdateCount + 1)
              })
              .catch(function (error) {
                console.log(error)
              })
              .finally(() => {
                setIsLoading(false)
                setSelectedItem(null)
              })
          }}
        />
        <Formik
          enableReinitialize={true}
          initialValues={
            selectedItem
              ? {
                  name: selectedItem.name,
                  description: selectedItem.description,
                  parentId: selectedItem.parentId,
                  isActive: selectedItem.isActive,
                  imageRef: selectedItem.imageRef,
                  renderPosition: selectedItem.renderPosition,
                  productCategoryLocalizations:
                    selectedItem.productCategoryLocalizations,
                  productProperties: productProperties.map(
                    (productProperty) => {
                      if (
                        selectedItem.productProperties.some(
                          (selectedProductProperty) =>
                            selectedProductProperty.id === productProperty.id,
                        )
                      ) {
                        return { ...productProperty, checked: true }
                      } else {
                        return { ...productProperty, checked: false }
                      }
                    },
                  ),
                }
              : {
                  name: '',
                  description: '',
                  parentId: null,
                  isActive: true,
                  renderPosition: 0,
                  productCategoryLocalizations: [
                    { language: 'de', value: '' },
                    { language: 'en', value: '' },
                    { language: 'pl', value: '' },
                    { language: 'cs', value: '' },
                    { language: 'bg', value: '' },
                    { language: 'da', value: '' },
                    { language: 'et', value: '' },
                    { language: 'fi', value: '' },
                    { language: 'fr', value: '' },
                    { language: 'el', value: '' },
                    { language: 'it', value: '' },
                    { language: 'lt', value: '' },
                    { language: 'lv', value: '' },
                    { language: 'nl', value: '' },
                    { language: 'pt-PT', value: '' },
                    { language: 'ro', value: '' },
                    { language: 'sv', value: '' },
                    { language: 'sk', value: '' },
                    { language: 'sl', value: '' },
                    { language: 'es', value: '' },
                    { language: 'uk', value: '' },
                    { language: 'hu', value: '' },
                  ],
                  productProperties: productProperties.map(
                    (productProperty) => {
                      return { ...productProperty, checked: false }
                    },
                  ),
                }
          }
          validationSchema={valSchema}
          //validateOnBlur={false}
          onSubmit={(values, { setSubmitting }) => {
            let parentProductProperties = selectedItem
              ? getParentProductProperties(selectedItem)
              : []

            let payload = {
              ...values,
              productProperties: values.productProperties
                .filter(
                  (productProperty) =>
                    productProperty.checked &&
                    !parentProductProperties.some(
                      (parentProductProperty) =>
                        parentProductProperty.id === productProperty.id,
                    ),
                )
                .map((productProperty) => {
                  return { id: productProperty.id }
                }),
            }

            if (selectedItem) {
              productCategoriesService
                .updateProductCategory(selectedItem.id, payload)
                .then((data) => {
                  forceUpdate(forceUpdateCount + 1)
                  setShowCreateDialog(false)
                })
                .catch(function (error) {
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                })
            } else {
              productCategoriesService
                .createProductCategory(payload)
                .then((data) => {
                  forceUpdate(forceUpdateCount + 1)
                  setShowCreateDialog(false)
                })
                .catch(function (error) {
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                })
            }
          }}
        >
          {({ values, isSubmitting, submitForm, isValid }) => {
            let parentProductProperties = selectedItem
              ? getParentProductProperties(selectedItem)
              : !selectedItem && values.parentId
              ? getParentProductProperties(
                  productCategoriesFlattened.find(
                    (productCategory) => productCategory.id === values.parentId,
                  ),
                  productCategoriesFlattened.find(
                    (productCategory) => productCategory.id === values.parentId,
                  ).productProperties,
                )
              : []

            return (
              <Dialog fullWidth={true} maxWidth="md" open={showCreateDialog}>
                <CustomDialogTitle
                  title={t('PRODUCT_EDITOR.CREATE_PRODUCT_CATEGORY')}
                  onClose={() => {
                    setSelectedItem(null)
                    setShowCreateDialog(false)
                  }}
                />
                <DialogContent>
                  <Form>
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PRODUCT_EDITOR.PRODUCT_PROPERTY_CATEGORY_NAME')}
                      variant="outlined"
                      name="name"
                    />
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PRODUCT_EDITOR.IMAGE_LINK')}
                      variant="outlined"
                      name="imageRef"
                    />
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PRODUCT_EDITOR.DESCRIPTION')}
                      variant="outlined"
                      name="description"
                      multiline
                      rows="4"
                    />
                    <Field
                      component={TextField}
                      select
                      name="parentId"
                      label={t('PRODUCT_EDITOR.PARENT')}
                      variant="outlined"
                      fullWidth={true}
                      margin="dense"
                    >
                      <MenuItem key={null} value={null}>
                        {t('PRODUCT_EDITOR.NO_CATEGORY')}
                      </MenuItem>
                      {productCategoriesFlattened
                        .filter(
                          (productCategory) =>
                            !selectedItem ||
                            selectedItem.id !== productCategory.id,
                        )
                        .map((productCategory) => (
                          <MenuItem
                            key={productCategory.id}
                            value={productCategory.id}
                          >
                            {productCategory.name}
                          </MenuItem>
                        ))}
                    </Field>
                    <Field
                      component={TextField}
                      fullWidth
                      margin="dense"
                      label={t('PRODUCT_EDITOR.RENDER_POSITION')}
                      variant="outlined"
                      name="renderPosition"
                      inputProps={{
                        decimalScale: 0,
                        allowNegative: false,
                      }}
                    />
                    <Field
                      component={CheckboxWithLabel}
                      type={'checkbox'}
                      checked={values.isActive}
                      name={'isActive'}
                      margin="dense"
                      Label={{
                        label: t('PRODUCT_EDITOR.IS_ACTIVE'),
                      }}
                    />
                    <Divider />
                    <Grid container spacing={1}>
                      {productProperties &&
                        productProperties.map((productProperty, index) => (
                          <Grid item xs={4}>
                            <Field
                              disabled={parentProductProperties.some(
                                (parentProductProperty) =>
                                  parentProductProperty.id ===
                                  productProperty.id,
                              )}
                              component={CheckboxWithLabel}
                              type={'checkbox'}
                              checked={
                                values.productProperties[index].checked ||
                                parentProductProperties.some(
                                  (parentProductProperty) =>
                                    parentProductProperty.id ===
                                    productProperty.id,
                                )
                              }
                              name={`productProperties[${index}].checked`}
                              margin="dense"
                              Label={{
                                label: productProperty.name,
                              }}
                            />
                          </Grid>
                        ))}
                      <Divider />
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography>
                            {t('PRODUCT_EDITOR.TRANSLATIONS')}
                          </Typography>
                        </Grid>
                        {values.productCategoryLocalizations.map(
                          (localizations, index) => (
                            <Grid item xs={3}>
                              <Field
                                component={TextField}
                                fullWidth
                                margin="dense"
                                label={localizations.language}
                                variant="outlined"
                                name={`productCategoryLocalizations[${index}].value`}
                              />
                            </Grid>
                          ),
                        )}
                      </Grid>
                    </Grid>
                  </Form>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setShowCreateDialog(false)}
                    variant="outlined"
                    color={'secondary'}
                  >
                    {t('GENERAL.CANCEL')}
                  </Button>
                  <Button
                    onClick={() => submitForm()}
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    variant="contained"
                    color="secondary"
                  >
                    {t('GENERAL.CONFIRM')}
                  </Button>
                </DialogActions>
              </Dialog>
            )
          }}
        </Formik>
      </Fragment>
    )
  )

  function productCategoryMapping(productCategories) {
    return productCategories.map((productCategory) => (
      <TreeItem
        nodeId={productCategory.id}
        label={
          <Fragment>
            {productCategory.name}
            <IconButton
              onClick={() => {
                setSelectedItem(productCategory)
                setShowCreateDialog(true)
              }}
            >
              <Edit />
            </IconButton>
            {productCategory.children.length === 0 && (
              <IconButton
                onClick={() => {
                  setSelectedItem(productCategory)
                  setShowDeleteDialog(true)
                }}
              >
                <Delete />
              </IconButton>
            )}
          </Fragment>
        }
      >
        {productCategory.children
          ? productCategoryMapping(productCategory.children)
          : null}
      </TreeItem>
    ))
  }

  function getParentProductProperties(productCategory, productProperties = []) {
    if (productCategory.parentId) {
      let parentCategory = productCategoriesFlattened.find(
        (pCategory) => pCategory.id === productCategory.parentId,
      )
      productProperties = productProperties.concat(
        parentCategory.productProperties,
      )

      return getParentProductProperties(parentCategory, productProperties)
    } else {
      return productProperties
    }
  }
}

export default withTranslation()(ProductCategoryEditor)
