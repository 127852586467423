import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const partnerInvoiceLocationsService = {
  createPartnerInvoiceLocation,
  createDebtorOfPartnerInvoiceLocation,
  updatePartnerInvoiceLocation,
  getPartnerInvoiceLocationsOfPartner,
  getPartnerInvoiceLocationsOfUser,
}

function getPartnerInvoiceLocationsOfPartner(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/partner-invoice-locations`,
    requestOptions,
  ).then(handleResponse)
}

function createPartnerInvoiceLocation(requestBody, jobOfferToken) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }
  return fetch(
    jobOfferToken
      ? `${config.apiUrlBase}/partner-invoice-locations?token=${jobOfferToken}`
      : `${config.apiUrlBase}/partner-invoice-locations`,
    requestOptions,
  ).then(handleResponse)
}

function updatePartnerInvoiceLocation(partnerInvoiceLocationId, requestBody) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }
  return fetch(
    `${config.apiUrlBase}/partner-invoice-locations/${partnerInvoiceLocationId}`,
    requestOptions,
  ).then(handleResponse)
}

function createDebtorOfPartnerInvoiceLocation(id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/partner-invoice-locations/${id}/debtor`,
    requestOptions,
  ).then(handleResponse)
}

function getPartnerInvoiceLocationsOfUser(userId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/users/${userId}/partner-invoice-locations`,
    requestOptions,
  ).then(handleResponse)
}

export * from './partnerInvoiceLocationsService'
