import _ from 'lodash'
import moment from 'moment'
import ReactDOMServer from 'react-dom/server'
import { countrycodeLookup, indexLookup } from '../_constants/lookupConstants'
import { _googleService } from '../_services/_googleService'
import { validAddressService } from '../_services/validAddressService'
import i18n from '../i18n'

export function calculatePriceByKm(km, stackHeight, priceEachKm, data) {
  if (!data.stackHeightLookup[stackHeight]) {
    return 0
  }

  return (
    parseFloat(data.price) +
    (parseFloat(priceEachKm) * km +
      (data.basePriceEachTruck ? parseFloat(data.basePriceEachTruck) : 0)) /
      data.stackHeightLookup[stackHeight]
  )
}

export function calculateDistance(
  sourceLatitude,
  sorceLongitude,
  targetLatitude,
  targetLongitude,
) {
  const earthRadiusKm = 6371 // Erdradius in Kilometer
  const deltaLatitude = deg2rad(targetLatitude - sourceLatitude)
  const deltaLongitude = deg2rad(targetLongitude - sorceLongitude)
  const a =
    Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
    Math.cos(deg2rad(sourceLatitude)) *
      Math.cos(deg2rad(targetLatitude)) *
      Math.sin(deltaLongitude / 2) *
      Math.sin(deltaLongitude / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = earthRadiusKm * c // Distanz in Kilometer
  return distance
}

// Hilfsfunktion zur Umwandlung von Grad in Radian
const deg2rad = (deg) => {
  return deg * (Math.PI / 180)
}

export function addUniqueElementToArray(list, item, key) {
  if (item[key] && item[key].id) {
    if (!list.some((element) => element.id === item[key].id)) {
      list.push({
        id: item[key].id,
        name: item[key].name + ' (#' + item[key].id + ')',
      })
    }
  }
}

export function getIndexesOfPosition(pos) {
  const identifiers = Object.keys(indexLookup)

  switch (pos.productId) {
    case 20:
      const state = pos.positionProperties.find(
        (positionProperty) => positionProperty.productProperty.id === 44,
      ).value

      if (state === 'new') {
        return identifiers.filter((identifier) => identifier.includes('NEW'))
      } else if (state === 'first') {
        return identifiers.filter((identifier) => identifier.includes('FIRST'))
      } else if (state === 'second') {
        return identifiers.filter((identifier) => identifier.includes('SECOND'))
      } else if (state === 'third') {
        return identifiers.filter((identifier) => identifier.includes('THIRD'))
      } else {
        return identifiers
      }

    default:
      return identifiers
  }
}

export function getIconPath(icon) {
  const iconString = ReactDOMServer.renderToString(icon)
  const parser = new DOMParser()
  const svgDoc = parser.parseFromString(iconString, 'image/svg+xml')
  const iconPath = svgDoc.querySelector('path')?.getAttribute('d')

  return iconPath
}

export async function validateLocation(
  location,
  context,
  formikName,
  setExternalAdressValidationResult,
  setShowHasReplacedComponentsDialog,
  setGeoData,
  yup,
  addressComponentsToIgnore = [],
) {
  try {
    let result = true
    if (
      //Only do address validation in following countries (Atm some European Countries with support of address validation) -> Otherwise the backend takes care of getting the geo-location
      //Importand when adding more countries -> Add country to countrycodeLookup. The RegionCode is required, when validating an address
      [
        // 14, // Austria
        // 21, // Belgium
        // 56, // Denmark
        // 71, // Finland
        // 72, // France
        80, // Germany
        // 97, // Hungary
        // 106, //Italy
        // 155, // Netherlands
        // 176, // Poland
        // 197, // Slovenia
        // 203, // Spain
        // 211, // Sweden
        // 212, // Switzerland
      ].includes(location.countryId) &&
      //To save resources -> only call API when address, zipcode and place are not null
      location.address &&
      location.zipcode &&
      location.place
    ) {
      if (location.address && location.zipcode && location.place) {
        const requestBody = {
          locality: location.place,
          postalCode: location.zipcode,
          regionCode: countrycodeLookup[location.countryId],
        }

        const validationResponse = await validAddressService.validateAddress(
          requestBody,
        )

        if (validationResponse && validationResponse.isValid) {
          return true
        }
      }
      let validationResult = await _googleService.validateAddressOfLocation(
        location,
      )

      //Even when the API replaces the place, hasReplacedComponents is false. Therefore adding that information manually
      let placeAddressComponent =
        validationResult.address.addressComponents.find(
          (addressComponent) => addressComponent.componentType === 'locality',
        )

      if (
        placeAddressComponent &&
        placeAddressComponent.componentName.text !== location.place
      ) {
        validationResult.verdict.hasReplacedComponents = true
        placeAddressComponent.replaced = true
      }

      if (validationResult.address.missingComponentTypes) {
        for (
          let index = 0;
          index < validationResult.address.missingComponentTypes.length;
          index++
        ) {
          const missingComponentType =
            validationResult.address.missingComponentTypes[index]

          if (!addressComponentsToIgnore.includes(missingComponentType)) {
            switch (missingComponentType) {
              case 'route':
                return yup.createError({
                  message: i18n.t('LITTLE.ADDRESS_MISSING'),
                  path: `${formikName}.address`,
                })
              case 'street_number':
                return yup.createError({
                  message: i18n.t('LITTLE.STREET_NUMBER_MISSING'),
                  path: `${formikName}.address`,
                })
              case 'postal_code':
                return yup.createError({
                  message: i18n.t('GENERAL.REQUIRED'),
                  path: `${formikName}.zipcode`,
                })
              case 'locality':
                return yup.createError({
                  message: i18n.t('GENERAL.REQUIRED'),
                  path: `${formikName}.place`,
                })
              default:
                break
            }
          }
        }
      }

      if (validationResult.verdict.hasReplacedComponents) {
        setExternalAdressValidationResult({
          ...validationResult,
          locationId: location.id,
        })
        setShowHasReplacedComponentsDialog(true)
        return false
      }

      if (validationResult.address.unconfirmedComponentTypes) {
        for (
          let index = 0;
          index < validationResult.address.unconfirmedComponentTypes.length;
          index++
        ) {
          const unconfirmedComponentType =
            validationResult.address.unconfirmedComponentTypes[index]

          let addressComponent =
            validationResult.address.addressComponents.find(
              (addressComponent) =>
                addressComponent.componentType === unconfirmedComponentType,
            )
          if (
            unconfirmedComponentType === 'postal_code' &&
            addressComponent.confirmationLevel !== 'CONFIRMED'
          ) {
            return yup.createError({
              message: i18n.t('LITTLE.CHECK_ZIPCODE'),
              path: `generalInformation.deliveryDetails.locations[${context.parent.findIndex(
                (locationInValues) => locationInValues.id === location.id,
              )}].zipcode`,
            })
          }

          if (
            addressComponent.confirmationLevel === 'UNCONFIRMED_AND_SUSPICIOUS'
          ) {
            switch (unconfirmedComponentType) {
              case 'postal_code':
              case 'locality':
                return yup.createError({
                  message: i18n.t('LITTLE.CHECK_ZIPCODE_AND_PLACE'),
                  path: `generalInformation.deliveryDetails.locations[${context.parent.findIndex(
                    (locationInValues) => locationInValues.id === location.id,
                  )}].zipcode`,
                })
              default:
                break
            }
          }
        }
      }

      setGeoData(validationResult)
    }

    return result
  } catch (e) {
    console.error(e)
    console.error('Address validation failed. Accepting address anyways')
    return true
  }
}

export function generateFilterValuesQueryString(filterValues, pagination) {
  let filterString = ``

  let paginationString = ''

  if (pagination) {
    if (pagination.limit) {
      paginationString += `&limit=${pagination.limit}`
    }

    if (pagination.offset !== null) {
      paginationString += `&offset=${pagination.offset}`
    }

    if (pagination.sortBy) {
      paginationString += `&sortBy=${pagination.sortBy}`
    }
  }

  if (filterValues) {
    filterString = `?${Object.keys(filterValues)
      .filter((key) => key !== 'geo')
      .map((key) => key + '=' + filterValues[key])
      .join('&')}`

    if (pagination) {
      filterString = filterString += `${paginationString}`
    }
    return filterString
  } else {
    if (pagination) {
      return paginationString
    } else {
      return '?'
    }
  }
}
export function groupOpenHours(openHours) {
  const valueLabelFormat = (value) => {
    const hours = Math.floor(value)
    const minutes = Math.floor((value % 1) * 60)
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`
  }
  const weekDayLabels = [
    i18n.t('DELIVERY_TIMES.MONDAY'),
    i18n.t('DELIVERY_TIMES.TUESDAY'),
    i18n.t('DELIVERY_TIMES.WEDNESDAY'),
    i18n.t('DELIVERY_TIMES.THURSDAY'),
    i18n.t('DELIVERY_TIMES.FRIDAY'),
    i18n.t('DELIVERY_TIMES.SATURDAY'),
    i18n.t('DELIVERY_TIMES.SUNDAY'),
  ]
  let openTimesNew = ''
  // Map each open hour object to include the weekday label
  let mappedHours = openHours.map((openHour, index) => {
    return {
      ...openHour,
      weekDayLabel: weekDayLabels[index],
    }
  })
  // Filter for only the active days
  const activeHours = mappedHours.filter((oh) => oh.isActive)
  // Group the active hours by their start and end times
  const groupedHours = _.groupBy(activeHours, (oh) => `${oh.start}-${oh.end}`)
  // Create an array of strings that represent each group of active hours
  const openTimes = []

  Object.entries(groupedHours).forEach(([key, groupedHour]) => {
    let isConsecutive = true
    let lastIndex = null
    if (groupedHour.length < 3) {
      isConsecutive = false
    } else {
      groupedHour.forEach((element) => {
        let weekDayIndex = weekDayLabels.findIndex(
          (weekDayLabel) => weekDayLabel === element.weekDayLabel,
        )
        if (lastIndex && lastIndex + 1 !== weekDayIndex) {
          isConsecutive = false

          return isConsecutive
        }
        lastIndex = weekDayIndex
      })
    }

    let output1 = ''

    if (isConsecutive) {
      output1 = `${groupedHour[0].weekDayLabel}-${
        groupedHour[groupedHour.length - 1].weekDayLabel
      }`
    } else {
      output1 = `${groupedHour.map((val) => val.weekDayLabel).join(',')}`
    }

    let output = `${output1}: ${valueLabelFormat(
      groupedHour[0].start,
    )}-${valueLabelFormat(groupedHour[0].end)}`
    openTimes.push(output)
  })
  openTimesNew = openTimes.join(' | ')

  return openTimesNew
}
export function getTouchedObj(errors) {
  if (Array.isArray(errors)) {
    const touched = []
    errors.forEach((val) => {
      touched.push(getTouchedObj(val))
    })
    return touched
  }
  const touched = {}
  if (errors) {
    Object.keys(errors).forEach((key) => {
      if (Array.isArray(errors[key])) {
        errors[key].map((test) => {
          return test
        })

        for (let index = 0; index < errors[key].length; index++) {
          const val = errors[key][index]
          if (index === 0) touched[key] = []
          const ret = getTouchedObj(val)
          touched[key].push(ret)
        }
      } else if (typeof errors[key] !== 'string') {
        touched[key] = getTouchedObj(errors[key])
      } else {
        touched[key] = true
      }
    })
    return touched
  }
}

export function getPartnerOfLocalUser() {
  let userObject = JSON.parse(localStorage.getItem('user'))
  return userObject.partner
}

export function isOfferDeliveryInvalid(offer, requestType) {
  return (
    requestType !== 'TENDER' &&
    offer.positions.some((position) => {
      var totalAmount = 0

      offer.deliveries.forEach((delivery) => {
        var deliveryPositions = delivery.positions.filter((dpos) => {
          return dpos.positionId === position.id
        })

        totalAmount += parseFloat(
          Object.values(deliveryPositions).reduce(
            (t, { amount }) => t + amount,
            0,
          ),
        )
      })
      if (totalAmount !== position.amount) {
        //Disable Button since delivery position amount does not fit to position amount
        return true
      }
      return false
    })
  )
}

export const diffOfferWithOfferOrRequest = (lhsOffer, rhsObj, rhsObjType) => {
  // if rhsObj is a request create array of all positions across locations
  if (rhsObjType === 'REQUEST') {
    var rhsRequestPositions = []
    rhsObj.generalInformation.deliveryDetails.locations.forEach((location) => {
      location.positions.forEach((pos) => {
        rhsRequestPositions.push(pos)
      })
    })
  }

  const userObject = JSON.parse(localStorage.getItem('user'))
  const result = {
    generalInformation: {
      deliveryDetails: {
        incoTerm:
          lhsOffer.generalInformation.deliveryDetails.incoTerm.toString() !==
          rhsObj.generalInformation.deliveryDetails.incoTerm.toString()
            ? rhsObj.generalInformation.deliveryDetails.incoTerm.toString()
            : false,
        locations: lhsOffer.generalInformation.deliveryDetails.locations.map(
          (location, index) => {
            let rhsLocation =
              rhsObj.generalInformation.deliveryDetails.locations[index]
            return {
              address:
                location.address &&
                rhsLocation.address &&
                location.address.toString() !== rhsLocation.address.toString()
                  ? rhsLocation.address.toString()
                  : false,
              company:
                location.company &&
                rhsLocation.company &&
                location.company.toString() !== rhsLocation.company.toString()
                  ? rhsLocation.company.toString()
                  : false,
              countryId:
                location.countryId.toString() !==
                rhsLocation.countryId.toString()
                  ? rhsLocation.countryId.toString()
                  : false,
              zipcode:
                location.zipcode.toString() !== rhsLocation.zipcode.toString()
                  ? rhsLocation.zipcode.toString()
                  : false,
              place:
                location.place.toString() !== rhsLocation.place.toString()
                  ? rhsLocation.place.toString()
                  : false,
              deliveryInformations:
                location.deliveryInformations &&
                rhsLocation.deliveryInformations &&
                location.deliveryInformations.toString() !==
                  rhsLocation.deliveryInformations.toString()
                  ? rhsLocation.deliveryInformations.toString()
                  : false,
              unload:
                location.unload.toString() !== rhsLocation.unload.toString()
                  ? rhsLocation.unload.toString()
                  : false,
              stackHeight:
                Number(location.stackHeight) !== Number(rhsLocation.stackHeight)
                  ? rhsLocation.stackHeight
                    ? rhsLocation.stackHeight
                    : '0'
                  : false,
              stackHeightUnit:
                location.stackHeightUnit.toString() !==
                rhsLocation.stackHeightUnit.toString()
                  ? rhsLocation.stackHeightUnit.toString()
                  : false,
              isDespatchAdviceRequired:
                (location.isDespatchAdviceRequired &&
                  !rhsLocation.isDespatchAdviceRequired) ||
                (!location.isDespatchAdviceRequired &&
                  rhsLocation.isDespatchAdviceRequired)
                  ? rhsLocation.isDespatchAdviceRequired
                    ? rhsLocation.isDespatchAdviceRequired
                    : i18n.t('LITTLE.EMPTY_FIELD')
                  : location.isDespatchAdviceRequired &&
                    rhsLocation.isDespatchAdviceRequired &&
                    location.isDespatchAdviceRequired.toString() !==
                      rhsLocation.isDespatchAdviceRequired.toString()
                  ? rhsLocation.isDespatchAdviceRequired.toString()
                  : false,
              despatchAdviceInstructions:
                (location.despatchAdviceInstructions &&
                  !rhsLocation.despatchAdviceInstructions) ||
                (!location.despatchAdviceInstructions &&
                  rhsLocation.despatchAdviceInstructions)
                  ? rhsLocation.despatchAdviceInstructions
                    ? rhsLocation.despatchAdviceInstructions
                    : i18n.t('LITTLE.EMPTY_FIELD')
                  : location.despatchAdviceInstructions &&
                    rhsLocation.despatchAdviceInstructions &&
                    location.despatchAdviceInstructions.toString() !==
                      rhsLocation.despatchAdviceInstructions.toString()
                  ? rhsLocation.despatchAdviceInstructions.toString()
                  : false,
            }
          },
        ),
      },
      paymentDetails: {
        paymentTerm:
          lhsOffer.generalInformation.paymentDetails.paymentTerm.toString() !==
          rhsObj.generalInformation.paymentDetails.paymentTerm.toString()
            ? rhsObj.generalInformation.paymentDetails.paymentTerm.toString()
            : false,
        skonto:
          (lhsOffer.request.type !== 'ADVERT' &&
            lhsOffer.request.partnerId === userObject.partner.id) ||
          (lhsOffer.request.type === 'ADVERT' &&
            lhsOffer.request.partnerId !== userObject.partner.id)
            ? lhsOffer.generalInformation.paymentDetails.skonto.toString() !==
              rhsObj.generalInformation.paymentDetails.skonto.toString()
              ? rhsObj.generalInformation.paymentDetails.skonto.toString()
              : false
            : false,
        skontoPeriod:
          (lhsOffer.request.type !== 'ADVERT' &&
            lhsOffer.request.partnerId === userObject.partner.id) ||
          (lhsOffer.request.type === 'ADVERT' &&
            lhsOffer.request.partnerId !== userObject.partner.id)
            ? lhsOffer.generalInformation.paymentDetails.skontoPeriod &&
              rhsObj.generalInformation.paymentDetails.skontoPeriod &&
              lhsOffer.generalInformation.paymentDetails.skontoPeriod.toString() !==
                rhsObj.generalInformation.paymentDetails.skontoPeriod.toString()
              ? rhsObj.generalInformation.paymentDetails.skontoPeriod.toString()
              : false
            : false,
      },
      otherDetails: {
        validUntil:
          rhsObjType === 'OFFER' &&
          moment(lhsOffer.generalInformation.otherDetails.validUntil).format(
            'DD.MM.YYYY',
          ) !==
            moment(rhsObj.generalInformation.otherDetails.validUntil).format(
              'DD.MM.YYYY',
            )
            ? moment(rhsObj.generalInformation.otherDetails.validUntil).format(
                'DD.MM.YYYY',
              )
            : false,
      },
    },
    deliveries: diffDeliveries(lhsOffer.deliveries, rhsObj.deliveries),
    positions:
      rhsObjType === 'REQUEST'
        ? diffPositions(lhsOffer.positions, rhsRequestPositions, rhsObjType)
        : diffPositions(lhsOffer.positions, rhsObj.positions, rhsObjType),
    attachments: lhsOffer.attachments.some(
      (attachment) => attachment.isNew || attachment.isDeleted,
    ),
  }
  return result
}

const diffDeliveries = (lhsDeliveries, rhsDeliveries) => {
  if (lhsDeliveries.length !== rhsDeliveries.length) {
    return true
  }
  for (let index = 0; index < lhsDeliveries.length; index++) {
    const lhsDelivery = lhsDeliveries[index]
    if (
      !rhsDeliveries.some(
        (rhsDelivery) =>
          lhsDelivery.type === rhsDelivery.type &&
          moment(lhsDelivery.value).format('DD.MM.YYYY') ===
            moment(rhsDelivery.value).format('DD.MM.YYYY') &&
          !diffDeliveryPositions(lhsDelivery.positions, rhsDelivery.positions),
      )
    ) {
      return true
    }
  }
  return false
}

const diffDeliveryPositions = (lhsDeliveryPositions, rhsDeliveryPositions) => {
  if (lhsDeliveryPositions.length !== rhsDeliveryPositions.length) {
    return true
  }
  for (let index = 0; index < lhsDeliveryPositions.length; index++) {
    const lhsPos = lhsDeliveryPositions[index]
    if (
      !rhsDeliveryPositions.some(
        (rhsPos) =>
          parseInt(lhsPos.amount) === parseInt(rhsPos.amount) &&
          lhsPos.positionId === rhsPos.positionId,
      )
    ) {
      return true
    }
  }
  return false
}

const diffPositions = (lhsPositions, rhsPositions, rhsObjType) => {
  return lhsPositions.map((lhsPos) => {
    var compPos = rhsPositions.find(
      (rhsPos) =>
        parseInt(rhsPos.id) === parseInt(lhsPos.id) &&
        parseInt(rhsPos.locationId) === parseInt(lhsPos.locationId),
    )
    if (!compPos) {
      return null
    }
    return {
      id: lhsPos.id,
      locationId: lhsPos.locationId,
      checked:
        lhsPos.checked !== compPos.checked
          ? i18n.t('LITTLE.POSITION_NOT_OFFERED')
          : false,
      amount:
        parseFloat(lhsPos.amount) !== parseFloat(compPos.amount)
          ? compPos.amount.toString()
          : false,
      productUnitId:
        lhsPos.productUnitId !== compPos.productUnitId
          ? compPos.productUnitId
          : false,
      price: compPos.price
        ? parseFloat(lhsPos.price) !== parseFloat(compPos.price)
          ? compPos.price
          : false
        : false,
      index:
        lhsPos.index !== compPos.index ? indexLookup[compPos.index] : false,
      positionProperties: diffPositionProperties(
        lhsPos.product.productProperties.map((productProperty) => {
          let targetPositionProperty = lhsPos.positionProperties.find(
            (positionProperty) =>
              positionProperty.productProperty.id === productProperty.id,
          )
          return targetPositionProperty
            ? targetPositionProperty
            : {
                productProperty: productProperty,
                value: undefined,
              }
        }),
        lhsPos.product.productProperties.map((productProperty) => {
          let targetPositionProperty = compPos.positionProperties.find(
            (positionProperty) =>
              positionProperty.productProperty.id === productProperty.id,
          )
          return targetPositionProperty
            ? targetPositionProperty
            : {
                productProperty: productProperty,
                value: undefined,
              }
        }),
      ),
    }
  })
}

export const diffPositionProperties = (
  lhsPositionProperties,
  rhsPositionProperties,
) => {
  return lhsPositionProperties.map((lhsPositionProperty) => {
    var matchingPositionProperty = rhsPositionProperties.find(
      (compPositionProperty) => {
        return (
          lhsPositionProperty &&
          compPositionProperty &&
          parseInt(lhsPositionProperty.productProperty.id) ===
            parseInt(compPositionProperty.productProperty.id)
        )
      },
    )
    if (lhsPositionProperty.value && !matchingPositionProperty.value) {
      return {
        ...lhsPositionProperty,
        value:
          lhsPositionProperty.productProperty.type === 'ARRAY' ||
          lhsPositionProperty.productProperty.type === '2D_ARRAY'
            ? lhsPositionProperty.value
            : i18n.t('LITTLE.EMPTY_FIELD'),
      }
    } else if (
      lhsPositionProperty.value === undefined ||
      lhsPositionProperty.value === null
    ) {
      return {
        ...lhsPositionProperty,
        value: false,
      }
    } else if (
      matchingPositionProperty.value === undefined ||
      matchingPositionProperty.value === null
    ) {
      return {
        ...lhsPositionProperty,
        value: false,
      }
    }

    let value = false
    if (lhsPositionProperty.productProperty.type === 'ARRAY') {
      let value = []
      lhsPositionProperty.value.forEach((subPositionProperty, index) => {
        value[index].push({
          productPropertyId: subPositionProperty.productPropertyId,
          value:
            subPositionProperty.value.toString() !==
            matchingPositionProperty.value[index].value.toString()
              ? matchingPositionProperty.value[index].value.toString()
              : false,
        })
      })
    } else if (lhsPositionProperty.productProperty.type === '2D_ARRAY') {
      value = []

      if (
        lhsPositionProperty.value.length !==
        matchingPositionProperty.value.length
      ) {
        return {
          ...lhsPositionProperty,
          value: i18n.t('manual:LITTLE.2D_ARRAY_LENGTH_DIFFERENCE'),
        }
      }

      lhsPositionProperty.value.forEach((outerArray, outerIndex) => {
        outerArray.forEach((subPositionProperty, innerIndex) => {
          if (!value[outerIndex]) {
            value.push([])
          }

          value[outerIndex].push({
            productPropertyId: subPositionProperty.productPropertyId,
            value:
              subPositionProperty.value.toString() !==
              matchingPositionProperty.value[outerIndex][
                innerIndex
              ].value.toString()
                ? matchingPositionProperty.value[outerIndex][
                    innerIndex
                  ].value.toString()
                : false,
          })
        })
      })
    } else {
      value =
        lhsPositionProperty.value.toString() !==
        matchingPositionProperty.value.toString()
          ? matchingPositionProperty.value.toString()
          : false
    }

    return {
      ...lhsPositionProperty,
      value: value,
    }
  })
}

export function isOfferEqualToOfferOrRequest(lhsOffer, rhsObj, rhsObjType) {
  const differences = diffOfferWithOfferOrRequest(lhsOffer, rhsObj, rhsObjType)

  var result = true
  if (
    differences.generalInformation.paymentDetails.paymentTerm ||
    differences.generalInformation.paymentDetails.skonto ||
    differences.generalInformation.paymentDetails.skontoPeriod ||
    differences.generalInformation.otherDetails.validUntil ||
    differences.generalInformation.deliveryDetails.locations.some(
      (location) =>
        location.address ||
        location.company ||
        location.countryId ||
        location.zipcode ||
        location.place ||
        location.deliveryInformations ||
        location.unload ||
        location.stackHeight ||
        location.stachHeightUnit ||
        location.isDespatchAdviceRequired ||
        location.despatchAdviceInstructions,
    ) ||
    differences.deliveries ||
    differences.attachments
  ) {
    result = false
  }
  differences.positions.forEach((pos) => {
    pos.positionProperties.forEach((positionProperty) => {
      switch (positionProperty.productProperty.type) {
        case 'ARRAY':
          if (positionProperty.value.some((value) => value)) {
            result = false
          }
          break
        case '2D_ARRAY':
          if (
            positionProperty.value.some((innerArray) => {
              return innerArray.some((positionValue) => positionValue.value)
            })
          ) {
            result = false
          }
          break
        default:
          if (positionProperty.value) {
            result = false
          }
          break
      }
    })
    delete pos.id
    delete pos.positionProperties
    delete pos.locationId
    if (Object.values(pos).filter((entry) => entry).length > 0) {
      result = false
    }
  })
  return result
}

export function areTenderOrderRequestsEqual(a, b, tenderOrderRequest) {
  if (tenderOrderRequest.state === 'ARCHIVED') {
    return false
  }

  return !a.deliveries.some((aDelivery) => {
    //Check if delivery changed
    if (
      !b.deliveries.some(
        (bDelivery) =>
          aDelivery.type === bDelivery.type &&
          aDelivery.value === bDelivery.value,
      )
    ) {
      return true
    }
    //Check if delivery positions are different
    return b.deliveries.some((bDelivery) => {
      return (
        aDelivery.type === bDelivery.type &&
        aDelivery.value === bDelivery.value &&
        aDelivery.positions.some((aDpos) => {
          if (
            !bDelivery.positions.some(
              (bDpos) => aDpos.positionId === bDpos.positionId,
            )
          ) {
            return true
          }

          let isDifferenceInPosition = bDelivery.positions.some((bDpos) => {
            if (
              !aDelivery.positions.some(
                (aDpos) => aDpos.positionId === bDpos.positionId,
              )
            ) {
              return true
            }
            return (
              aDpos.positionId === bDpos.positionId &&
              parseFloat(aDpos.amount) !== parseFloat(bDpos.amount)
            )
          })
          return isDifferenceInPosition
        })
      )
    })
  })
}

export function generatePositionPropertyValue(positionProperty) {
  let value = ''
  if (positionProperty.productProperty.type === 'BOOLEAN') {
    value =
      positionProperty.productProperty.primaryRenderVariant === 'KEY/VALUE'
        ? `${translateProductProperty(positionProperty.productProperty)}: ${
            positionProperty.productProperty.type === 'ENUM'
              ? translatePositionPropertyValueThroughEnumValue(positionProperty)
              : Boolean(positionProperty.value)
              ? i18n.t('GENERAL.YES')
              : i18n.t('GENERAL.NO')
          }`
        : translateProductProperty(positionProperty.productProperty)
  } else {
    value =
      positionProperty.productProperty.primaryRenderVariant === 'KEY/VALUE'
        ? `${translateProductProperty(
            positionProperty.productProperty,
          )}: ${translatePositionPropertyValueThroughEnumValue(
            positionProperty,
          )}`
        : translatePositionPropertyValueThroughEnumValue(positionProperty)
  }

  return positionProperty.productProperty.unit
    ? `${value} ${positionProperty.productProperty.unit}`
    : value
}

export function translateProduct(product) {
  let lng = i18n.language.substring(0, 2)

  if (lng === 'pt') {
    lng = 'pt-PT'
  }
  return product.productLocalizations &&
    product.productLocalizations.some(
      (localization) => localization.language === lng,
    )
    ? product.productLocalizations.find(
        (localization) => localization.language === lng,
      ).value
    : product.name
}

export function translatePositionPropertyValueThroughEnumValue(
  positionProperty,
) {
  let lng = i18n.language.substring(0, 2)

  if (lng === 'pt') {
    lng = 'pt-PT'
  }

  let untranslatedEnumValue =
    positionProperty.productProperty.productPropertyEnumValues.find(
      (enumValue) => enumValue.value === positionProperty.value,
    )

  let enumLocalization =
    untranslatedEnumValue &&
    untranslatedEnumValue.productPropertyEnumValueLocalizations.some(
      (enumValueLocalization) => enumValueLocalization.language === lng,
    )
      ? untranslatedEnumValue.productPropertyEnumValueLocalizations.find(
          (enumValueLocalization) => enumValueLocalization.language === lng,
        )
      : positionProperty

  return enumLocalization.value
}

export function translateProductUnit(productUnit) {
  let lng = i18n.language.substring(0, 2)

  if (lng === 'pt') {
    lng = 'pt-PT'
  }

  return productUnit.productUnitLocalizations &&
    productUnit.productUnitLocalizations.some(
      (localization) => localization.language === lng,
    )
    ? productUnit.productUnitLocalizations.find(
        (localization) => localization.language === lng,
      ).value
    : productUnit.name
}

export function translateProductCategory(productCategory) {
  let lng = i18n.language.substring(0, 2)

  if (lng === 'pt') {
    lng = 'pt-PT'
  }

  return productCategory.productCategoryLocalizations &&
    productCategory.productCategoryLocalizations.some(
      (localization) => localization.language === lng,
    )
    ? productCategory.productCategoryLocalizations.find(
        (localization) => localization.language === lng,
      ).value
    : productCategory.name
}

export function translateProductProperty(productProperty) {
  let lng = i18n.language.substring(0, 2)

  if (lng === 'pt') {
    lng = 'pt-PT'
  }

  return productProperty.productPropertyLocalizations &&
    productProperty.productPropertyLocalizations.some(
      (localization) => localization.language === lng,
    )
    ? productProperty.productPropertyLocalizations.find(
        (localization) => localization.language === lng,
      ).value
    : productProperty.name
}

export function translateProductPropertyCategory(productPropertyCategory) {
  let lng = i18n.language.substring(0, 2)

  if (lng === 'pt') {
    lng = 'pt-PT'
  }

  return productPropertyCategory.productPropertyCategoryLocalizations &&
    productPropertyCategory.productPropertyCategoryLocalizations.some(
      (localization) => localization.language === lng,
    )
    ? productPropertyCategory.productPropertyCategoryLocalizations.find(
        (localization) => localization.language === lng,
      ).value
    : productPropertyCategory.name
}

export function translateProductPropertyEnumValue(productPropertyEnumValue) {
  let lng = i18n.language.substring(0, 2)

  if (lng === 'pt') {
    lng = 'pt-PT'
  }

  return productPropertyEnumValue.productPropertyEnumValueLocalizations &&
    productPropertyEnumValue.productPropertyEnumValueLocalizations.some(
      (localization) => localization.language === lng,
    )
    ? productPropertyEnumValue.productPropertyEnumValueLocalizations.find(
        (localization) => localization.language === lng,
      ).value
    : productPropertyEnumValue.name
}

export function reorgArray(array, column, offset) {
  array = array.map((element, index) => {
    element[column] = index + offset
    return element
  })
  return array
}

export function generateColorMap(data, alpha = 1) {
  const colorMap = {}
  const hueStep = 45 // Increased hue step
  const saturationMax = 70 // Increased saturation for more vibrant colors
  const lightnessMax = 70 // Increased lightness for brighter colors

  let hue = 120 // Starting hue
  let saturation = 34
  let lightness = 34

  const adjustWithinBounds = (value, step, min, max) => {
    let newValue = value + step
    if (newValue > max) newValue = max
    if (newValue < min) newValue = min
    return newValue
  }

  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key !== 'month' && !colorMap[key]) {
        colorMap[key] = `hsl(${hue}, ${saturation}%, ${lightness}%, ${alpha})`

        hue = (hue + hueStep) % 360

        if (hue === 120) {
          // Adjust other values only when we circle back to original hue
          saturation = adjustWithinBounds(saturation, 10, 25, saturationMax)
          lightness = adjustWithinBounds(lightness, 10, 40, lightnessMax)
        }
      }
    })
  })

  return colorMap
}
