import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useLocation } from 'react-router'
import { jobOffersService } from '../../../../_services/jobOffersService'
import AcceptJobOfferDialog from '../../../AcceptJobOfferDialog'
import AcceptJobOfferNewUserDialog from '../../../AcceptJobOfferNewUserDialog'
import AgreementDialog from '../../../AgreementDialog'
import JobOfferView from '../../../JobOfferView'
import { Loading } from '../../../Loading'

const JobOffersOfPartner = (props) => {
  const { t, dispatch } = props

  const [isLoading, setIsLoading] = useState(false)
  const [jobOffer, setJobOffer] = useState({})
  const [showAcceptOfferDialog, setShowAcceptOfferDialog] = useState(false)
  const [showDeclineOfferDialog, setShowDeclineOfferDialog] = useState(false)
  const [showOfferDeclinedDialog, setShowOfferDeclinedDialog] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)

  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)
  let jobOfferId = params.get('id')
  let token = params.get('token')

  useEffect(() => {
    if (!jobOfferId) {
      return
    }
    setIsLoading(true)
    jobOffersService
      .getJobOffer(jobOfferId, token)
      .then((jobOffer) => {
        setJobOffer(jobOffer)
      })
      .finally(() => setIsLoading(false))
  }, [jobOfferId])

  return isLoading ? (
    <Loading variant={'center'} />
  ) : (
    <Fragment>
      <Fragment>
        <Dialog
          open={
            !showOfferDeclinedDialog &&
            !showDeclineOfferDialog &&
            !showAcceptOfferDialog
          }
          fullWidth={true}
          maxWidth={'lg'}
        >
          <DialogTitle>{t('JOB_OFFERS.OFFER_OVERVIEW')}</DialogTitle>
          {jobOffer.state !== 'ACTIVE' ? (
            <Alert severity="error" sx={{ margin: 5 }}>
              <AlertTitle>{t('JOB_OFFER.OFFER_NO_LONGER_ACTIVE')}</AlertTitle>
              {t('JOB_OFFER.OFFER_NO_LONGER_ACTIVE_DESCRIPTION')}
            </Alert>
          ) : (
            <Fragment>
              <DialogContent>
                <JobOfferView data={jobOffer} />
              </DialogContent>
              <DialogActions>
                <Button
                  variant={'outlined'}
                  color="secondary"
                  style={{ margin: '0 10px 0px 10px' }}
                  onClick={() => {
                    setShowDeclineOfferDialog(true)
                  }}
                >
                  {t('JOB_OFFERS.DECLINE_OFFER')}
                </Button>
                <Button
                  variant={'contained'}
                  color="secondary"
                  style={{ margin: '0 10px 0px 10px' }}
                  onClick={() => {
                    setShowAcceptOfferDialog(true)
                  }}
                >
                  {t('JOB_OFFERS.ACCEPT_OFFER')}
                </Button>
              </DialogActions>
            </Fragment>
          )}
        </Dialog>
      </Fragment>

      {showAcceptOfferDialog &&
        (jobOffer.customerId ? (
          <AcceptJobOfferDialog
            jobOffer={jobOffer}
            hide={() => setShowAcceptOfferDialog(false)}
            forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
            token={token}
          />
        ) : (
          <AcceptJobOfferNewUserDialog
            jobOffer={jobOffer}
            hide={() => setShowAcceptOfferDialog(false)}
            forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
            token={token}
          />
        ))}

      <AgreementDialog
        open={showDeclineOfferDialog}
        message={t('JOB_OFFERS.ARE_YOU_SURE_TO_DECLINE_OFFER')}
        acceptButtonText={t('GENERAL.YES')}
        handleClose={() => {
          setShowDeclineOfferDialog(false)
        }}
        agree={() => {
          setIsLoading(true)
          setShowDeclineOfferDialog(false)
          jobOffersService
            .declineJobOffer(jobOffer.id, token)
            .then(() => {
              setShowDeclineOfferDialog(false)
              setShowOfferDeclinedDialog(true)
            })
            .finally(() => setIsLoading(false))
        }}
      />

      <Dialog open={showOfferDeclinedDialog} fullWidth={true} maxWidth={'md'}>
        <DialogTitle>{t('JOB_OFFERS.OFFER_DECLINED_TITLE')}</DialogTitle>
        <DialogContent>{t('JOB_OFFERS.OFFER_DECLINED_MESSAGE')}</DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default withTranslation()(connect()(JobOffersOfPartner))
