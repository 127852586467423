import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { jobOffersService } from '../../../../../../_services/jobOffersService'

import { Box, Chip, useMediaQuery, useTheme } from '@mui/material'
import {
  categorizedProductLookup,
  countryLookup,
  deliveryTypeLookup,
  jobOfferStateLookup,
  jobOfferTypeLookup,
  productLookup,
  productStateLookup,
} from '../../../../../../_constants/lookupConstants'
import { generalService } from '../../../../../../_services/generalService'
import { partnersService } from '../../../../../../_services/partnersService'
import JobOfferView from '../../../../../JobOfferView'
import { Loading } from '../../../../../Loading'
import { PositionsList } from '../../../../../PositionsList'
import ResourceList from '../../../../../ResourceList/_components/ResourceList'
import ViewDescription from '../../../../../ViewDescription'

const JobOffersOverview = (props) => {
  const { t } = props

  const [isLoading, setIsLoading] = useState(false)

  const [partners, setPartners] = useState([])

  const [forceUpdateCount, forceUpdate] = useState(0)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    setIsLoading(true)
    partnersService
      .getPartners()
      .then((data) => {
        setPartners(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return (
    <Fragment>
      <ViewDescription description={t('JOB_OFFERS.VIEW_DESCRPTION')} />
      {(isLoading && <Loading variant={'centered'} />) || (
        <Fragment>
          <ResourceList
            fetchMethod={(filterValues, pagination) =>
              jobOffersService.getJobOffers(filterValues, pagination)
            }
            forceUpdateCount={forceUpdateCount}
            filterId={'JOB_OFFERS_OVERVIEW'}
            filters={[
              {
                key: 'id.startsWith',
                label: t('JOB_OFFERS.JOB_OFFER_ID'),
                variant: 'textfield',
              },
              {
                key: 'jobId.startsWith',
                label: t('JOB_OFFERS.JOB_ID'),
                variant: 'textfield',
              },
              {
                key: 'customerId.startsWith',
                label: t('ORDERS.CUSTOMER'),
                variant: 'autocomplete',
                options: partners.map((partner) => partner.id),
                lookup: Object.fromEntries(
                  partners.map((partner) => [
                    partner.id,
                    `${partner.name} (${partner.id}) - ${partner.place}`,
                  ]),
                ),
              },
              {
                key: 'type',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(jobOfferTypeLookup),
                lookup: jobOfferTypeLookup,
                label: t('JOB_OFFERS.TYPE'),
              },
              {
                key: 'state',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(jobOfferStateLookup),
                lookup: jobOfferStateLookup,
                label: t('JOB_OFFERS.STATE'),
              },
              {
                key: 'positions.productId.eq',
                variant: 'categorizedCheckboxList',
                values: categorizedProductLookup.map((category) => {
                  return {
                    label: category.name,
                    items: category.products.map((product) => product.id),
                  }
                }),
                lookup: productLookup,
                label: t('ORDERS.PRODUCT'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(productStateLookup),
                lookup: productStateLookup,
                label: t('ORDERS.PRODUCT_STATE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(deliveryTypeLookup),
                lookup: deliveryTypeLookup,
                label: t('ORDERS.DELIVERY_TYPE'),
              },
              {
                key: 'countryId.eq',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(countryLookup),
                lookup: countryLookup,
                label: t('ORDERS.COUNTRY'),
              },
              {
                key: 'zipcode.startsWith',
                label: t('ORDERS.ZIPCODE'),
                variant: 'textfield',
              },
              {
                key: 'place.startsWith',
                label: t('ORDERS.PLACE'),
                variant: 'textfield',
              },
            ]}
            sorting={[
              {
                key: 'id',
                default: true,
                label: t('JOB_OFFERS.JOB_OFFER_ID'),
              },
            ]}
            dataIdentifier={'id'}
            resourceHeaderRenderFunction={(jobOffer) =>
              `${t('JOB_OFFERS.JOB_OFFER')}: ${jobOffer.id}`
            }
            listHeaderRenderFunction={(jobOffer) => (
              <Fragment>
                <div>{`${t('JOB_OFFERS.JOB_OFFER')}: ${jobOffer.id}`} </div>
                <div>
                  {`${jobOffer.zipcode} | ${jobOffer.place} | `}
                  {`${
                    countryLookup[jobOffer.countryId]
                  } |  ${jobOffer.deliveries
                    .map((delivery) =>
                      delivery.type === 'date'
                        ? moment(delivery.value).format('DD.MM.YYYY')
                        : `${t(
                            'ORDERS.CW',
                          )} ${generalService.convertDateToCalendarWeek(
                            delivery.value,
                          )}`,
                    )
                    .join(', ')}`}
                </div>
                <div>{jobOffer.userList}</div>
              </Fragment>
            )}
            headerActionRenderFunction={(jobOffer) => {
              return (
                <Fragment>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'center',
                      margin: 1,
                    }}
                  >
                    <Chip
                      size={'small'}
                      color={
                        jobOffer.state === 'ACCEPTED'
                          ? 'success'
                          : jobOffer.state === 'ACTIVE' ||
                            jobOffer.state === 'READY'
                          ? 'info'
                          : 'default'
                      }
                      label={jobOfferStateLookup[jobOffer.state]}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'center',
                      margin: 1,
                    }}
                  >
                    <Chip
                      size={'small'}
                      color={'default'}
                      label={`${t('JOB_OFFERS.SOURCE')}: ${
                        jobOfferTypeLookup[jobOffer.type]
                      }`}
                    />
                  </Box>
                </Fragment>
              )
            }}
            contentRenderFunction={(jobOffer) => (
              <PositionsList positions={jobOffer.positions} />
            )}
            resourceRenderFunction={(jobOffer) => {
              return (
                <JobOfferView
                  data={jobOffer}
                  forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
                />
              )
            }}
            actionsRenderFunction={(jobOffer) => {
              return []
            }}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default withTranslation()(connect()(JobOffersOverview))
