import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import Alert from '@mui/material/Alert'
import { ErrorMessage, Field, Form, Formik, yupToFormErrors } from 'formik'
import { TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  getTouchedObj,
  translateProductUnit,
  validateLocation,
} from '../../../../../../_helpers/little'
import NumberFormatCustom from '../../../../../../_helpers/numberFormatCustom'
import { ordersService } from '../../../../../../_services/ordersService'
import CalendarWeekPicker from '../../../../../CalendarWeekPicker'
import CustomDialogTitle from '../../../../../CustomDialogTitle'
import DeliveryDetailsReplacedComponentsDialog from '../../../../../DeliveryDetailsReplacedComponentsDialog'
import { FieldErrorWrapper } from '../../../../../FieldErrorWrapper'
import PositionCard from '../../../../../PositionCard'

const CopyOrderDialog = (props) => {
  const { order, hide, show, t } = props
  const [activeStep, setActiveStep] = useState(0)
  const [geoData, setGeoData] = useState(null)
  const userObject = JSON.parse(localStorage.getItem('user'))
  const [externalAdressValidationResult, setExternalAdressValidationResult] =
    useState(false)

  const [showHasReplacedComponentsDialog, setShowHasReplacedComponentsDialog] =
    useState(false)

  const deliveryDetailsSchema = Yup.object().shape({
    locations: Yup.array()
      .of(
        Yup.object()
          .shape({
            address: Yup.string().required(t('GENERAL.REQUIRED')),
            zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
            place: Yup.string().required(t('GENERAL.REQUIRED')),
            countryId: Yup.number().required(t('GENERAL.REQUIRED')),
            latitude: Yup.number().nullable(),
            longitude: Yup.number().nullable(),
          })
          .test(async function (location, context) {
            if (!location.latitude || !location.longitude) {
              const validationPath = `deliveryDetails.locations[${context.parent.findIndex(
                (locationInValues) => locationInValues.id === location.id,
              )}]`

              return await validateLocation(
                location,
                context,
                validationPath,
                setExternalAdressValidationResult,
                setShowHasReplacedComponentsDialog,
                (validationResult) => {
                  setGeoData(validationResult.geocode.location)
                },
                this,
                ['route', 'street_number'],
              )
            } else {
              return true
            }
          }),
      )
      .min(1, t('GENERAL.MIN_1')),
  })

  const valSchemas = [
    Yup.object().shape({
      deliveryValue: Yup.mixed().when(
        'deliveryType',
        ([deliveryType], schema) => {
          return deliveryType === 'date'
            ? Yup.date().typeError(t('GENERAL.INVALID_DATE'))
            : Yup.mixed().nullable()
        },
      ),
      selectedDays: Yup.mixed().when(
        'deliveryType',
        ([deliveryType], schema) => {
          return deliveryType === 'calendarWeek'
            ? Yup.array().min(1, t('GENERAL.REQUIRED'))
            : Yup.mixed().nullable()
        },
      ),
      deliveryDetails: deliveryDetailsSchema, // Integrieren der deliveryDetails-Schema
    }),
    Yup.object().shape({
      positions: Yup.array()
        .of(
          Yup.object().shape({
            amount: Yup.number()
              .required(t('GENERAL.REQUIRED'))
              .typeError(t('GENERAL.REQUIRED')),
            productUnitId: Yup.number().required(t('GENERAL.REQUIRED')),
          }),
        )
        .min(1, t('GENERAL.MIN_1'))
        .test({
          message: t('GENERAL.MIN_1'),
          test: function (positions) {
            if (activeStep === 0) {
              return true
            }

            let totalAmount = 0

            positions.forEach((pos) => {
              if (pos.amount) {
                totalAmount = totalAmount + parseFloat(pos.amount)
              }
            })
            return totalAmount > 0
          },
        }),
    }),
  ]

  return order ? (
    <Formik
      enableReinitialize={true}
      initialValues={{
        deliveryType: 'calendarWeek',
        userId: '',
        deliveryValue: null,
        selectedDays: [],
        positions: order.positions.map((pos) => {
          return {
            ...pos,
            amount: 0,
            productUnitId: pos.productUnitId,
          }
        }),
        customInvoiceAddress: false,
        invoiceAddress: order.invoiceAddress,
        communityId: order.communityId,
        deliveryDetails: {
          locations: [
            {
              address: order.address,
              zipcode: order.zipcode,
              place: order.place,
              countryId: order.countryId,
              latitude: order.latitude,
              longitude: order.longitude,
            },
          ],
        },
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting = true

        var payload = values

        payload.userId = userObject.user.id

        if (payload.deliveryType === 'calendarWeek') {
          payload.deliveryValue = moment(values.selectedDays[0])
        } else {
          payload.deliveryValue = moment(payload.deliveryValue)
        }

        payload.deliveryValue = payload.deliveryValue.set({
          hour: 12,
          minute: 0,
          second: 0,
          millisecond: 0,
        })

        payload.positions = payload.positions
          .filter((pos) => pos.amount && pos.amount > 0)
          .map((pos) => {
            return {
              id: pos.id,
              amount: pos.amount,
              productUnitId: pos.productUnitId,
            }
          })

        if (geoData) {
          payload.geoData = geoData
        }

        ordersService
          .createRequestOfOrder(payload, order.id)
          .then(() => {
            setSubmitting = false
            window.location.reload()
          })
          .catch(function (error) {
            setSubmitting = false
            console.log(error)
          })
      }}
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        setErrors,
        setTouched,
        resetForm,
        submitForm,
        isValid,
      }) => (
        <Form>
          <Dialog fullWidth={true} maxWidth="lg" open={show}>
            <CustomDialogTitle onClose={() => hide()}>
              {t('ORDERS.ORDER_AGAIN')}
            </CustomDialogTitle>
            <DialogContent>
              <Alert severity="info">{t('ORDERS.COPY_ORDER_INFO')}</Alert>
              <Stepper activeStep={activeStep} sx={{ margin: 2 }}>
                <Step>
                  <StepLabel>{t('ORDERS.DELIVERY_DATE')}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{t('ORDERS.POSITIONS')}</StepLabel>
                </Step>
              </Stepper>
              {activeStep === 0 && (
                <Grid container spacing={1} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="deliveryType"
                      select
                      fullWidth
                      label={t('REQUESTS.TENDER_ORDER.TYPE')}
                      variant="outlined"
                      margin="dense"
                    >
                      <MenuItem value={'calendarWeek'} key={'calendarWeek'}>
                        {t('REQUESTS.TENDER_ORDER.CALENDARWEEK')}
                      </MenuItem>
                      <MenuItem value={'date'} key={'date'}>
                        {t('REQUESTS.TENDER_ORDER.DATE')}
                      </MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    {values.deliveryType === 'date' ? (
                      <FieldErrorWrapper
                        slotProps={{
                          textField: {
                            fullWidth: true,
                            label: t('REQUESTS.TENDER_ORDER.DELIVERY_DATE'),
                            margin: 'dense',
                          },
                        }}
                        name="deliveryValue"
                        fullWidth
                        component={DatePicker}
                        label={t('REQUESTS.TENDER_ORDER.DELIVERY_DATE')}
                        inputFormat="DD/MM/YYYY"
                        minDate={moment()}
                      />
                    ) : values.deliveryType === 'calendarWeek' ? (
                      <Grid container xs justifyContent="center">
                        <CalendarWeekPicker
                          selectedDays={values.selectedDays}
                          setSelectedDays={(days) => {
                            setFieldValue('selectedDays', days)
                          }}
                        />
                        <ErrorMessage
                          name="selectedDays"
                          render={(msg) => (
                            <div className="help-block">{msg}</div>
                          )}
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Grid>
              )}

              {activeStep === 1 && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ErrorMessage
                      name={`positions`}
                      render={(msg) => <div className="help-block">{msg}</div>}
                    />
                  </Grid>
                  {values
                    ? values.positions.map((orderPosition, index) => {
                        return (
                          <Grid item xs={12} sm={6}>
                            <PositionCard
                              data={orderPosition}
                              readOnly={true}
                              showProductDetails={true}
                              showImage={true}
                              imageSize={'small'}
                            >
                              <Grid container spacing={1} alignItems={'center'}>
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    fullWidth={true}
                                    component={TextField}
                                    margin="dense"
                                    InputProps={{
                                      inputComponent: NumberFormatCustom,
                                    }}
                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                    inputProps={{
                                      decimalScale:
                                        values.positions[
                                          index
                                        ].product.productUnits.find(
                                          (productUnit) => {
                                            return (
                                              productUnit.id ===
                                              values.positions[index]
                                                .productUnitId
                                            )
                                          },
                                        ).type === 'FLOAT'
                                          ? 2
                                          : 0,
                                      allowNegative: false,
                                    }}
                                    label={t('REQUEST.AMOUNT')}
                                    variant="outlined"
                                    name={`positions[${index}].amount`}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <ErrorMessage
                                    name={`positions[${index}].productUnitId`}
                                    render={(msg) => (
                                      <div className="help-block">{msg}</div>
                                    )}
                                  />
                                  <ToggleButtonGroup
                                    fullWidth
                                    value={
                                      values.positions[index].productUnitId
                                    }
                                    sx={{ alignSelf: 'center' }}
                                    size={'large'}
                                  >
                                    {values.positions[
                                      index
                                    ].product.productUnits.map(
                                      (productUnit) => {
                                        return (
                                          <ToggleButton
                                            value={productUnit.id}
                                            onClick={() =>
                                              setFieldValue(
                                                `positions[${index}].productUnitId`,
                                                productUnit.id,
                                              )
                                            }
                                          >
                                            {translateProductUnit(productUnit)}
                                          </ToggleButton>
                                        )
                                      },
                                    )}
                                  </ToggleButtonGroup>
                                </Grid>
                              </Grid>
                            </PositionCard>
                          </Grid>
                        )
                      })
                    : null}
                </Grid>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="secondary"
                style={{ margin: '0 10px 0px 10px' }}
                onClick={() => {
                  if (activeStep === 0) {
                    hide()
                  } else {
                    setActiveStep((prevActiveStep) => prevActiveStep - 1)
                  }
                }}
              >
                {t('GENERAL.BACK')}
              </Button>
              {activeStep === 1 ? (
                <Button
                  onClick={() => {
                    if (valSchemas[activeStep]) {
                      valSchemas[activeStep]
                        .validate(values, {
                          abortEarly: false,
                          context: values,
                        })
                        .then(function () {
                          submitForm()
                        })
                        .catch((err) => {
                          let yupErrors = yupToFormErrors(err)
                          setErrors(yupErrors)
                          setTouched(getTouchedObj(yupErrors), false) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                        })
                    }
                  }}
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  {t('ORDERS.ORDER_AGAIN')}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    if (valSchemas[activeStep]) {
                      valSchemas[activeStep]
                        .validate(values, {
                          abortEarly: false,
                          context: values,
                        })
                        .then(function (value) {
                          setActiveStep((prevActiveStep) => prevActiveStep + 1)
                        })
                        .catch((err) => {
                          let yupErrors = yupToFormErrors(err)
                          setErrors(yupErrors)
                          setTouched(getTouchedObj(yupErrors), false) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                        })
                    } else {
                      setActiveStep((prevActiveStep) => prevActiveStep + 1)
                    }
                  }}
                >
                  {t('REQUEST.CONTINUE')}
                </Button>
              )}
            </DialogActions>
          </Dialog>
          {showHasReplacedComponentsDialog && (
            <DeliveryDetailsReplacedComponentsDialog
              setAddress={() => {
                let replacedAddressComponents =
                  externalAdressValidationResult.address.addressComponents.filter(
                    (addressComponent) => addressComponent.replaced,
                  )

                replacedAddressComponents.forEach((addressComponent) => {
                  switch (addressComponent.componentType) {
                    case 'route':
                    case 'street_number':
                      let adress =
                        externalAdressValidationResult.address.addressComponents.find(
                          (addressComponent) =>
                            addressComponent.componentType === 'route',
                        )
                      let streetNumber =
                        externalAdressValidationResult.address.addressComponents.find(
                          (addressComponent) =>
                            addressComponent.componentType === 'street_number',
                        )
                      setFieldValue(
                        `deliveryDetails.locations[${values.deliveryDetails.locations.findIndex(
                          (location) =>
                            location.id ===
                            externalAdressValidationResult.locationId,
                        )}].address`,
                        `${adress.componentName.text} ${streetNumber.componentName.text}`,
                      )
                      break
                    case 'postal_code':
                      setFieldValue(
                        `deliveryDetails.locations[${values.deliveryDetails.locations.findIndex(
                          (location) =>
                            location.id ===
                            externalAdressValidationResult.locationId,
                        )}].zipcode`,
                        addressComponent.componentName.text,
                      )
                      break
                    case 'locality':
                      setFieldValue(
                        `deliveryDetails.locations[${values.deliveryDetails.locations.findIndex(
                          (location) =>
                            location.id ===
                            externalAdressValidationResult.locationId,
                        )}].place`,
                        addressComponent.componentName.text,
                      )
                      break
                    // case 'country':
                    //   break
                    default:
                      break
                  }
                  setFieldValue()
                })
              }}
              addressValidationResult={externalAdressValidationResult}
              hide={() => setShowHasReplacedComponentsDialog(false)}
            />
          )}
        </Form>
      )}
    </Formik>
  ) : null
}

export default withTranslation()(CopyOrderDialog)
