import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import React from 'react'

const FilterCheckBoxList = (props) => {
  const { filterHandler, filter, lookup, setFilterHandler } = props
  let index = filterHandler.findIndex((handler) => handler.key === filter.key)
  return (
    <List>
      {filter.values.map((value) => {
        return (
          <ListItem
            key={value}
            value={value}
            dense
            button
            onClick={() => {
              const currentIndex = filterHandler[index].value.indexOf(value)

              let newFilterHandler = [...filterHandler]

              if (currentIndex === -1) {
                newFilterHandler[index].value.push(value)
              } else {
                newFilterHandler[index].value.splice(currentIndex, 1)
              }

              setFilterHandler(newFilterHandler)
            }}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={filterHandler[index].value.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={lookup ? lookup[value] : value} />
          </ListItem>
        )
      })}
    </List>
  )
}

export default FilterCheckBoxList
