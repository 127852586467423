import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material'
import { Field, Formik } from 'formik'
import { DateTimePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { deliveryNotesService } from '../../../../../../../../_services/deliveryNotesService'

const DespatchAdvice = (props) => {
  const { t, hide, deliveryNoteId, forceUpdate } = props

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        despatchAdviceDate: moment(new Date().setHours(12, 0, 0)),
      }}
      validationSchema={Yup.object().shape({
        despatchAdviceDate: Yup.date().required(t('GENERAL.REQUIRED')),
      })}
      validateOnBlur={true}
      validateOnChange={true}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting = true
        deliveryNotesService
          .updateDeliveryNote(deliveryNoteId, {
            hasBeenAdviced: true,
            despatchAdviceDate: values.despatchAdviceDate,
          })
          .then(() => {
            forceUpdate()
            hide()
          })
          .finally(() => {
            setSubmitting = false
          })
      }}
    >
      {({ submitForm, isSubmitting }) => {
        return (
          <Fragment>
            <Dialog open={true} fullWidth={true} maxWidth={'md'}>
              <DialogTitle>
                {t('DESPATCH_ADVICE.SET_DESPATCH_ADVICE')}
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={1} padding={1}>
                  <Grid item xs={12}>
                    <Field
                      name="despatchAdviceDate"
                      label={t('DESPATCH_ADVICE.DESPATCH_DATE')}
                      component={DateTimePicker}
                      type="text"
                      fullWidth={true}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={() => {
                    hide()
                  }}
                >
                  {t('GENERAL.CANCEL')}
                </Button>
                <Button
                  variant={'contained'}
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={() => {
                    submitForm()
                  }}
                >
                  {t('DESPATCH_ADVICE.SET_DESPATCH_ADVICE')}
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        )
      }}
    </Formik>
  )
}

export default withTranslation()(DespatchAdvice)
