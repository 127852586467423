import { Grid, Typography } from '@mui/material'
import _ from 'lodash'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { countryLookup, indexLookup } from '../../_constants/lookupConstants'
import { formatToNumber, formatToPrice } from '../../_helpers/number-formatting'
import { _proxiesService } from '../../_services/_proxiesService'
import FormattedPrice from '../FormattedPrice'
import PositionCard from '../PositionCard'
import { Section } from '../Section'

const TenderOrderPositions = (props) => {
  const { t, data } = props

  const [indexData, setIndexData] = useState({})

  useEffect(() => {
    ;(async () => {
      // Extrahiere alle nicht-null index Werte
      const indexIdentifiers = _.compact(
        _.flatMap(
          data.generalInformation.deliveryDetails.locations,
          (location) => _.map(location.positions, 'index'),
        ),
      )

      let medianLookup = {}
      for (let index = 0; index < indexIdentifiers.length; index++) {
        const identifier = indexIdentifiers[index]

        const indexData = await _proxiesService.getLatestOfIndex(identifier)

        medianLookup[identifier] = indexData.value.indexValue
      }

      setIndexData(medianLookup)
    })()
  }, [data.positions])

  return (
    <Fragment>
      <Section header={t('REQUEST.REQUEST_POSITIONS')}>
        <Grid container spacing={3} direction="row" alignItems="stretch">
          {data.positions.map((pos) => (
            <Grid item xs={12} sm={6}>
              <PositionCard
                data={pos}
                showProductDetails={true}
                readOnly={true}
                showImage={true}
                imageSize={'small'}
              >
                <Typography>
                  {`${t('TENDER_ORDERS.ORDERED_AMOUNT')}: ${formatToNumber(
                    pos.orderedAmount,
                  )}`}
                </Typography>
                <Typography>
                  {`${t('TENDER_ORDERS.REQUESTED_AMOUNT')}: ${formatToNumber(
                    pos.requestedAmount,
                  )}`}
                </Typography>
                <Typography>
                  {`${t('REQUEST.TOTAL_AMOUNT')}: ${formatToNumber(
                    pos.amount,
                  )}`}
                </Typography>
                <Typography>
                  {`${t('TENDER_ORDERS.TOTAL_POSITION_PRICE')}: ${formatToPrice(
                    pos.totalPrice,
                  )}`}
                </Typography>
              </PositionCard>
            </Grid>
          ))}
        </Grid>
      </Section>
      {data.generalInformation.deliveryDetails.locations.map(
        (location, index) => (
          <Section
            header={
              <Fragment>
                {t('REQUEST.LOCATION')}
                {' ' + (index + 1)}
                {location.company ? ` | ${location.company}` : null}
                {location.countryId
                  ? ` | ${countryLookup[location.countryId]}`
                  : null}
                {location.address ? ` | ${location.address}` : null}
                {location.zipcode ? ` | ${location.zipcode}` : null}
                {location.place ? ` | ${location.place}` : null}
              </Fragment>
            }
          >
            <Grid container spacing={3} direction="row" alignItems="stretch">
              {location.positions.map((pos) => (
                <Grid item xs={12} sm={6}>
                  <PositionCard
                    data={pos}
                    showProductDetails={true}
                    readOnly={true}
                    showImage={true}
                    imageSize={'small'}
                  >
                    <Typography>
                      {`${t('TENDER_ORDERS.ORDERED_AMOUNT')}: ${formatToNumber(
                        pos.orderedAmount,
                      )}`}
                    </Typography>
                    <Typography>
                      {`${t(
                        'TENDER_ORDERS.REQUESTED_AMOUNT',
                      )}: ${formatToNumber(pos.requestedAmount)}`}
                    </Typography>
                    <Typography>
                      {`${t('REQUEST.POSITION_AMOUNT')}: ${formatToNumber(
                        pos.amount,
                      )}`}
                    </Typography>
                    <Typography>
                      <FormattedPrice pos={pos} />
                    </Typography>
                    {pos.index && (
                      <Fragment>
                        <Typography>
                          {`${t('REQUEST.REFERENCE_INDEX')}: ${
                            indexLookup[pos.index]
                          } `}
                        </Typography>
                        <Typography>
                          {`${t(
                            'TENDER_ORDERS.INDEX_START_VALUE',
                          )}: ${formatToNumber(pos.indexStartValue)} ${t(
                            'TENDER_ORDERS.INDEX_CURRENT_VALUE',
                          )}: ${formatToNumber(indexData[pos.index])} (${
                            (indexData[pos.index] - pos.indexStartValue) /
                              pos.indexStartValue >=
                            0
                              ? '+'
                              : '-'
                          }${formatToNumber(
                            Math.abs(
                              (indexData[pos.index] - pos.indexStartValue) /
                                pos.indexStartValue,
                            ) * 100,
                          )}%)`}
                        </Typography>
                      </Fragment>
                    )}
                    <Typography>
                      {`${t(
                        'TENDER_ORDERS.TOTAL_POSITION_PRICE',
                      )}: ${formatToPrice(pos.price * pos.amount)}`}
                    </Typography>
                  </PositionCard>
                </Grid>
              ))}
            </Grid>
          </Section>
        ),
      )}
    </Fragment>
  )
}

export default withTranslation()(connect()(TenderOrderPositions))
