import {
  AccountCircle,
  Chat,
  Help,
  Person,
  PowerSettingsNew,
} from '@mui/icons-material'
import {
  AppBar,
  Autocomplete,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import Numeral from 'numeral'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { authorize } from '../../../../_helpers/authorization'
import { partnersService } from '../../../../_services/partnersService'
import { surveysService } from '../../../../_services/surveysService'
import { usersService } from '../../../../_services/usersService'
import keycloak from '../../../../keycloak'
import { CustomRating } from '../../../CustomRating'
import { InfoTooltip } from '../../../InfoTooltip'
import SurveyParticipantDialog from '../PartnerSettings/_components/PartnerSurveys/_components/SurveyParticipantDialog'

const PREFIX = 'index'

const classes = {
  navbarWrapper: `${PREFIX}-navbarWrapper`,
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  logo: `${PREFIX}-logo`,
}

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  [`&.${classes.navbarWrapper}`]: {
    backgroundColor: '#f8f9fa',
    paddingLeft: '4px',
    paddingBottom: '0px',
    paddingTop: '0px',
    borderTop: `5px solid ${theme.palette.primary.main}`,
    borderBottom: '1px solid #ddd',
    zIndex: 1201,
  },

  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },

  [`& .${classes.logo}`]: {
    flex: 1,
  },
}))

const NavigationBar = (props) => {
  const { t, i18n, setIsChatOpen } = props

  const userObject = JSON.parse(localStorage.getItem('user'))

  const [userMenuOpen, setUserMenuOpen] = React.useState(false)

  //Used substring because some browsers return de-DE and not only de
  const [selectedLanguage, setSelectedLanguage] = React.useState(
    i18n.language.substring(0, 2),
  )
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [partners, setPartners] = useState([])
  const [partnerUsers, setPartnerUsers] = useState([])
  const [selectedPartnerId, setSelectedPartnerId] = useState(
    userObject ? userObject.partner.id : null,
  )
  const [selectedSurvey, setSelectedSurvey] = useState(null)
  const [showSurveyDialog, setShowSurveyDialog] = useState(false)

  const [selectedPartnerInputValue, setSelectedPartnerInputValue] =
    React.useState('')
  const [selectedUserId, setSelectedUserId] = useState(
    userObject ? userObject.user.id : null,
  )
  const previousLanguageRef = useRef(selectedLanguage)

  const handleUserMenu = (event) => {
    var anc = null
    if (!userMenuOpen) {
      anc = event.currentTarget
    }
    setUserMenuOpen(!userMenuOpen)
    setAnchorEl(anc)
  }

  const impersonate = async () => {
    let selectedPartner = partners.find(
      (partner) => partner.id === selectedPartnerId,
    )
    let selectedUser = partnerUsers.find((user) => user.id === selectedUserId)

    const partner = await partnersService.getPartner(selectedPartnerId)
    let customerUserobject = {
      partner: {
        allPaymentTermsAllowed: selectedPartner.allPaymentTermsAllowed,
        id: selectedPartner.id,
        name: selectedPartner.name,
        quality: selectedPartner.averageQuality,
        ratingAmount: selectedPartner.ratingAmount,
        reliability: selectedPartner.averageReliability,
        type: selectedPartner.type,
        hasAccessToSpotMarketplace: selectedPartner.hasAccessToSpotMarketplace,
        hasAccessToIndex: partner.hasAccessToIndex,
        activeTenderIndexes: partner.activeTenderIndexes,
      },
      user: {
        email: selectedUser.email,
        firstname: selectedUser.firstname,
        id: selectedUser.id,
        lastname: selectedUser.lastname,
        partnerLocationIds: selectedUser.partnerLocationIds,
        partnerInvoiceLocationIds: selectedUser.partnerInvoiceLocationIds,
      },
      permissions: selectedUser.permissions.concat(['administrator']),
    }
    localStorage.setItem('user', JSON.stringify(customerUserobject))
  }
  useEffect(() => {
    if (
      !keycloak.authenticated ||
      authorize('administrator') ||
      !selectedPartnerId
    ) {
      return
    }
    surveysService.getSurveysOfPartner(selectedPartnerId).then((surveys) => {
      surveys.forEach((survey) => {
        if (survey.isRequired && survey.state === 'ACTIVE') {
          const surveyQuestions = survey.surveyQuestions

          if (
            surveyQuestions.length > 0 &&
            surveyQuestions[0]?.surveyQuestionPartnerAnswers?.length === 0
          ) {
            setSelectedSurvey(survey)
            setShowSurveyDialog(true)
          } else {
            const answer =
              surveyQuestions[0]?.surveyQuestionPartnerAnswers?.length > 0
                ? surveyQuestions[0].surveyQuestionPartnerAnswers[0]
                : null

            if (answer) {
              const createdAt = moment(answer.createdAt)
              const expirationDate = createdAt.add(
                survey.upToDateTimeInDays,
                'days',
              )
              const today = moment()
              if (today.isAfter(expirationDate)) {
                setSelectedSurvey(survey)
                setShowSurveyDialog(true)
              }
            }
          }
        }
      })
    })
  }, [selectedPartnerId])

  useEffect(() => {
    // Wenn die ausgewählte Sprache dieselbe ist wie beim vorherigen Render, nichts tun.
    if (previousLanguageRef.current === selectedLanguage) {
      return
    }

    // Sprache ändern und Seite neu laden, wenn die Sprache tatsächlich geändert wurde
    i18n
      .changeLanguage(selectedLanguage)
      .then(() => {
        // Speichert die aktuelle Sprache für den nächsten Vergleich
        previousLanguageRef.current = selectedLanguage

        window.location.reload()
      })
      .catch((e) => {
        console.log(e)
      })

    // Clean-up-Funktion, um den vorherigen Wert beim Unmounting zu entfernen.
    return () => {
      previousLanguageRef.current = null
    }
  }, [i18n, selectedLanguage]) // Abhängigkeiten des useEffect

  useEffect(() => {
    if (!authorize('administrator')) {
      return
    }
    partnersService.getPartners().then((partners) => {
      setPartners(partners.sort((a, b) => (a.name > b.name && 1) || -1))
    })
  }, [])

  useEffect(() => {
    if (!authorize('administrator') || !selectedPartnerId) {
      return
    }

    usersService.getUsersOfPartner(selectedPartnerId).then((users) => {
      setPartnerUsers(users)
      if (userObject.partner.id !== selectedPartnerId) {
        let masterUserId = users.find((user) => user.isMaster).id
        setSelectedUserId(masterUserId)
      }
    })
  }, [selectedPartnerId])

  useEffect(() => {
    // Define the async function inside the useEffect
    const impersonateAsync = async () => {
      if (!authorize('administrator')) {
        return
      }

      if (
        selectedUserId !== '' &&
        selectedPartnerId !== '' &&
        partners.length > 0 &&
        partnerUsers.length > 0
      ) {
        await impersonate()
        window.location.reload()
      }
    }

    impersonateAsync()
  }, [selectedUserId])

  const handleCloseDialog = () => {
    setShowSurveyDialog(false)
  }
  return (
    <StyledAppBar className={classes.navbarWrapper}>
      <Toolbar>
        <Link to="/" className={classes.logo}>
          <img
            src={
              window.location.host === 'logcoop.packlogx.com' ||
              window.location.host === 'logcoop-test.packlogx.com' ||
              window.location.host === 'logcoop-demo.packlogx.com'
                ? '../../images/LogCoop_Logo.png'
                : window.location.host === 'portal.packlogx.com' ||
                  window.location.host === 'portal-test.packlogx.com' ||
                  window.location.host === 'portal-demo.packlogx.com'
                ? '../../images/logo-packlogx.png'
                : '../../images/logo.png'
            }
            height="60"
            alt="Pacurion Logo"
          />
        </Link>
        <div>
          {keycloak.authenticated && (
            <Fragment>
              {authorize('administrator') && partners.length > 0 ? (
                <Fragment>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="selected-user-label">
                      {t('GENERAL.SELECTED_USER')}
                    </InputLabel>
                    <Select
                      labelId={'selected-user-label'}
                      label={t('GENERAL.SELECTED_USER')}
                      size={'small'}
                      disabled={!selectedPartnerId}
                      defaultValue={userObject.user.id}
                      value={selectedUserId}
                      onChange={async (e) => {
                        let userId = parseInt(e.target.value)
                        setSelectedUserId(userId)
                        await impersonate(userId)
                      }}
                    >
                      {partnerUsers.map((user) => (
                        <MenuItem value={user.id}>{user.email}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Autocomplete
                      value={selectedPartnerId}
                      onChange={(event, newValue) => {
                        setSelectedPartnerId(newValue)
                      }}
                      inputValue={selectedPartnerInputValue}
                      onInputChange={(event, newInputValue) => {
                        setSelectedPartnerInputValue(newInputValue)
                      }}
                      size={'small'}
                      disablePortal
                      options={partners.map((customer) => customer.id)}
                      getOptionLabel={(option) => {
                        let partner = partners.find(
                          (customer) => customer.id === option,
                        )

                        return `${partner.name} (${partner.id}) - ${partner.place}`
                      }}
                      // filterOptions={(options, { inputValue }) => {
                      //   let inputLength = inputValue.length

                      //   return options.filter((option) => {
                      //     const partner = partners.find(
                      //       (customer) => customer.id === option,
                      //     )
                      //     const partnerIdAsString = partner.id.toString()
                      //     const matchesId = partnerIdAsString === inputValue
                      //     const matchesName =
                      //       inputLength >= 3 &&
                      //       partner.name
                      //         .toLowerCase()
                      //         .startsWith(inputValue.toLowerCase())
                      //     return true || matchesId || matchesName
                      //   })
                      // }}
                      sx={{ width: 600 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('GENERAL.SELECTED_PARTNER')}
                        />
                      )}
                    />
                  </FormControl>
                </Fragment>
              ) : null}
              <InfoTooltip title={t('NAVIGATION_BAR.HELP')}>
                <IconButton
                  color={'primary'}
                  size={'medium'}
                  onClick={() => {
                    window.open('https://docs.pacurion.com/', '_blank').focus()
                  }}
                >
                  <Badge>
                    <Help />
                  </Badge>
                </IconButton>
              </InfoTooltip>
              <InfoTooltip id="abcdef" title={t('NAVIGATION_BAR.CHAT')}>
                <IconButton
                  color={'primary'}
                  size={'medium'}
                  onClick={() => {
                    setIsChatOpen(true)
                  }}
                >
                  <Badge>
                    <Chat />
                  </Badge>
                </IconButton>
              </InfoTooltip>
              <IconButton size={'medium'} onClick={handleUserMenu}>
                <Badge>
                  <AccountCircle />
                </Badge>
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                id="user-menu"
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={userMenuOpen}
                onClose={handleUserMenu}
              >
                <ListItem>
                  <Typography>
                    {t('NAVIGATION_BAR.CUSTOMER_ID') +
                      ': ' +
                      userObject.partner.id}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    {userObject.user.firstname + ' ' + userObject.user.lastname}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>{userObject.partner.name}</Typography>
                </ListItem>
                <ListItem>
                  <InfoTooltip
                    title={
                      <table>
                        <thead>
                          <th style={{ borderRight: 'solid 15px transparent' }}>
                            {t('ORDERS.RATING_TOTAL')}
                          </th>
                          <th>
                            {Numeral(
                              (parseFloat(userObject.partner.quality) +
                                parseFloat(userObject.partner.reliability)) /
                                2,
                            ).format('0,0.[00]')}
                          </th>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                borderRight: 'solid 15px transparent',
                              }}
                            >
                              {t('ORDERS.RATING_QUALITY')}
                            </td>
                            <td>
                              {Numeral(
                                parseFloat(userObject.partner.quality),
                              ).format('0,0.[00]')}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                borderRight: 'solid 15px transparent',
                              }}
                            >
                              {t('ORDERS.RATING_RELIABILITY')}
                            </td>
                            <td>
                              {Numeral(
                                parseFloat(userObject.partner.reliability),
                              ).format('0,0.[00]')}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                borderRight: 'solid 15px transparent',
                              }}
                            >
                              {t('OFFERS.TOTAL_RECEIVED_RATINGS')}
                            </td>
                            <td
                              style={{
                                borderRight: 'solid 15px transparent',
                              }}
                            >
                              {userObject.partner.ratingAmount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',
                      }}
                    >
                      <CustomRating
                        name="read-only"
                        value={
                          (parseFloat(userObject.partner.quality) +
                            parseFloat(userObject.partner.reliability)) /
                          2
                        }
                        readOnly
                        precision={0.5}
                      ></CustomRating>
                      <Typography>
                        ({userObject.partner.ratingAmount})
                      </Typography>
                    </div>
                  </InfoTooltip>
                </ListItem>
                <Divider />
                <Link
                  to="/user-settings"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <MenuItem onClick={handleUserMenu}>
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    <ListItemText>
                      {t('NAVIGATION_BAR.USER_PROFILE')}
                    </ListItemText>
                  </MenuItem>
                </Link>
                <Divider />
                <Link
                  to={'#'}
                  onClick={() => {
                    localStorage.removeItem('user')
                    keycloak.logout()
                  }}
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  <MenuItem onClick={handleUserMenu}>
                    <ListItemIcon>
                      <PowerSettingsNew />
                    </ListItemIcon>
                    <ListItemText>{t('SIDE_BAR.LOGOUT')}</ListItemText>
                  </MenuItem>
                </Link>
              </Menu>
            </Fragment>
          )}
          <Select
            variant={'standard'}
            size={'small'}
            value={selectedLanguage}
            onChange={(e) => {
              if (selectedLanguage !== e.target.value.substring(0, 2)) {
                setSelectedLanguage(e.target.value.substring(0, 2))
              }
            }}
          >
            {[
              'EN',
              'DE',
              'PL',
              'CS',
              'FR',
              'BG',
              'DA',
              'ET',
              'FI',
              'EL',
              'IT',
              'LT',
              'LV',
              'NL',
              'PT',
              'RO',
              'SV',
              'SK',
              'SL',
              'ES',
              'UK',
              'HU',
            ]
              .sort()
              .map((value) => (
                <MenuItem value={value.toLowerCase()}>{value}</MenuItem>
              ))}
          </Select>
        </div>
      </Toolbar>
      {showSurveyDialog && selectedSurvey && (
        <SurveyParticipantDialog
          surveyId={selectedSurvey.id}
          partnerId={selectedPartnerId}
          show={showSurveyDialog}
          hide={handleCloseDialog}
          t={t}
          mode={
            selectedSurvey.surveyQuestions[0]?.surveyQuestionPartnerAnswers
              ?.length > 0
              ? 'EDIT'
              : 'NEW'
          }
        />
      )}
    </StyledAppBar>
  )
}

export default withTranslation()(NavigationBar)
