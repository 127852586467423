import {
  Close,
  Edit,
  EventAvailable as GotoOrdersIcon,
} from '@mui/icons-material'
import { Chip } from '@mui/material'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import {
  categorizedProductLookup,
  claimStateLookup,
  claimTypeLookup,
  countryLookup,
  orderStateLookup,
  orderTypeLookup,
  productLookup,
  productStateLookup,
} from '../../../../../../_constants/lookupConstants'
import { claimsService } from '../../../../../../_services/claimsService'
import { partnersService } from '../../../../../../_services/partnersService'
import AgreementDialog from '../../../../../AgreementDialog'
import CreateClaimOfDeliveryNoteDialog from '../../../../../ClaimsOfDeliveryNoteDialog/_components/CreateClaimOfDeliveryNoteDialog'
import { Loading } from '../../../../../Loading'
import { PositionsList } from '../../../../../PositionsList'
import ResourceList from '../../../../../ResourceList/_components/ResourceList'
import ClaimsView from './_components/ClaimsView'

const ClaimsOverview = (props) => {
  const { t, dispatch } = props

  const [isLoading, setIsLoading] = useState(true)

  const [partners, setPartners] = useState([])
  const [showCloseClaimDialog, setShowCloseClaimDialog] = useState(false)
  const [selectedClaim, setSelectedClaim] = useState(null)
  const [showEditClaimsDialog, setShowEditClaimsDialog] = useState(false)
  const [forceUpdateCount, forceUpdate] = useState(0)

  useEffect(
    () => {
      setIsLoading(true)
      partnersService
        .getPartners()
        .then((data) => {
          setPartners(data)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
  const openEditClaimDialog = () => {
    setShowEditClaimsDialog(true)
  }
  const closeEditClaimDialog = () => {
    setShowEditClaimsDialog(false)
    setSelectedClaim(null)
    forceUpdate()
  }
  const handleClaimCloseConfirm = () => {
    setIsLoading(true)

    let requestbody = {
      state: 'CLOSED',
      attachments: [],
    }
    claimsService
      .updateClaim(requestbody, selectedClaim.id)
      .then(() => {
        setSelectedClaim(false)
        setShowCloseClaimDialog(false)
        forceUpdate(forceUpdateCount + 1)
      })
      .catch((error) => {
        console.log('Error closing claim:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const location = useLocation()

  const search = location.search
  const params = new URLSearchParams(search)
  let defaultValueId = params.get('id')
  let navigate = useNavigate()

  return (
    <Fragment>
      {isLoading && <Loading variant={'centered'} />}
      <ResourceList
        fetchMethod={(filterValues, pagination) =>
          claimsService.getClaims(filterValues, pagination)
        }
        forceUpdateCount={forceUpdateCount}
        filterId={'ADMIN_CLAIMS_OVERVIEW'}
        filters={[
          {
            key: 'id.startsWith',
            label: t('CLAIMS.CLAIM_ID'),
            variant: 'textfield',
            defaultValue: defaultValueId,
          },
          {
            key: 'state',
            variant: 'checkboxList',
            values: Object.getOwnPropertyNames(claimStateLookup),
            lookup: claimStateLookup,
            label: t('CLAIMS.STATE'),
          },
          {
            key: 'deliveryNote.order.id.startsWith',
            label: t('ORDERS.ORDER_ID'),
            variant: 'textfield',
          },
          {
            key: 'deliveryNotes.id.startsWith',
            label: t('ORDERS.DELIVERY_NOTE_ID'),
            variant: 'textfield',
          },
          {
            key: 'deliveryNote.order.customerId.startsWith',
            label: t('ORDERS.CUSTOMER'),
            variant: 'autocomplete',
            options: partners.map((partner) => partner.id),
            lookup: Object.fromEntries(
              partners.map((partner) => [
                partner.id,
                `${partner.name} (${partner.id}) - ${partner.place}`,
              ]),
            ),
          },
          {
            key: 'deliveryNote.order.manufacturerId.startsWith',
            label: t('ORDERS.MANUFACTURER'),
            variant: 'autocomplete',
            options: partners.map((partner) => partner.id),
            lookup: Object.fromEntries(
              partners.map((partner) => [
                partner.id,
                `${partner.name} (${partner.id}) - ${partner.place}`,
              ]),
            ),
          },
          {
            key: 'deliveryNote.order.state',
            variant: 'checkboxList',
            values: Object.getOwnPropertyNames(orderStateLookup),
            lookup: orderStateLookup,
            label: t('ORDERS.STATE'),
          },
          {
            key: 'deliveryNote.order.type',
            variant: 'checkboxList',
            values: Object.getOwnPropertyNames(orderTypeLookup),
            lookup: orderTypeLookup,
            label: t('ORDERS.TYPE'),
          },

          {
            key: 'deliveryNote.order.positions.productId.eq',
            variant: 'categorizedCheckboxList',
            values: categorizedProductLookup.map((category) => {
              return {
                label: category.name,
                items: category.products.map((product) => product.id),
              }
            }),
            lookup: productLookup,
            label: t('ORDERS.PRODUCT'),
          },
          {
            key: 'deliveryNote.order.positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
            variant: 'checkboxList',
            values: Object.getOwnPropertyNames(productStateLookup),
            lookup: productStateLookup,
            label: t('ORDERS.PRODUCT_STATE'),
          },
          {
            key: 'deliveryNote.order.countryId.eq',
            variant: 'checkboxList',
            values: Object.getOwnPropertyNames(countryLookup),
            lookup: countryLookup,
            label: t('ORDERS.COUNTRY'),
          },
          {
            key: 'deliveryNote.order.zipcode.startsWith',
            label: t('ORDERS.ZIPCODE'),
            variant: 'textfield',
          },
          {
            key: 'deliveryNote.order.place.startsWith',
            label: t('ORDERS.PLACE'),
            variant: 'textfield',
          },
          {
            key: 'deliveryNote.order.address.startsWith',
            label: t('ORDERS.ADDRESS'),
            variant: 'textfield',
          },
          {
            key: 'deliveryNote.order.company.startsWith',
            label: t('ORDERS.COMPANY'),
            variant: 'textfield',
          },
        ]}
        sorting={[
          {
            key: 'id',
            default: true,
            label: t('CLAIMS.CLAIM_ID'),
          },
          {
            key: 'updatedAt',
            default: false,
            label: t('CLAIMS.UPDATED_AT'),
          },
        ]}
        listHeaderRenderFunction={(claim) => (
          <Fragment>
            <div>
              {`${t('CLAIMS.CLAIM')}: ${claim.id}`}
              {` | ${moment(claim.createdAt).format('DD.MM.YYYY HH:mm:ss')}`}
            </div>
            <div>{claimTypeLookup[claim.type]}</div>

            <Fragment>
              <div>
                {claim.order?.customer
                  ? `${t('ORDER.CUSTOMER')}: ${
                      claim.order.customerUser?.firstname || 'N/A'
                    } ${claim.order.customerUser?.lastname || 'N/A'} | ${
                      claim.order.customer.name || 'N/A'
                    } (${claim.order.customer.id || 'N/A'})`
                  : `${t('ORDER.CUSTOMER')}: N/A`}
              </div>
              {claim.order?.manufacturer && (
                <div>
                  {`${t('ORDER.MANUFACTURER')}: ${
                    claim.order.manufacturerUser?.firstname || 'N/A'
                  } ${claim.order.manufacturerUser?.lastname || 'N/A'} | ${
                    claim.order.manufacturer.name || 'N/A'
                  } (${claim.order.manufacturer.id || 'N/A'})`}
                </div>
              )}
            </Fragment>
            <div>{`${t('CLAIMS.ORDER_ID')}: ${claim.order?.id}`}</div>
          </Fragment>
        )}
        resourceHeaderRenderFunction={(claim) =>
          `${t('CLAIMS.CLAIM')}: ${claim.id}`
        }
        headerActionRenderFunction={(claim) => {
          return (
            <Fragment>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'center',
                  }}
                >
                  <Chip
                    size={'small'}
                    sx={{ padding: 1 }}
                    color={claim.state === 'CLOSED' ? 'success' : 'info'}
                    label={claimStateLookup[claim.state]}
                  />
                </div>
              </div>
            </Fragment>
          )
        }}
        contentRenderFunction={(claim) => (
          <PositionsList positions={claim.order?.positions} />
        )}
        resourceRenderFunction={(claim) => {
          return <ClaimsView claim={claim} />
        }}
        actionsRenderFunction={(claim) => {
          return [
            {
              icon: <Close />,
              name: t('CLAIM.CLOSE'),
              hidden: claim.state !== 'ACTIVE',
              primary: false,
              onClick: async () => {
                setSelectedClaim(claim)
                setShowCloseClaimDialog(true)
              },
            },
            {
              icon: <Edit />,
              name: t('CLAIM.EDIT_CLAIM'),
              hidden: !claim.order?.id,
              primary: true,
              onClick: async () => {
                setSelectedClaim(claim)
                openEditClaimDialog()
              },
            },
            {
              icon: <GotoOrdersIcon />,
              name: t('CLAIM.GO_TO_ORDER'),
              hidden: !claim.order?.id,
              primary: false,
              onClick: () =>
                navigate({
                  pathname: '/deliveries-customer',
                  search: '?id=' + claim.order?.id,
                }),
            },
          ]
        }}
        dataIdentifier={'id'}
      />
      <Fragment>
        {selectedClaim && showEditClaimsDialog && (
          <CreateClaimOfDeliveryNoteDialog
            deliveryNote={selectedClaim.deliveryNote}
            order={selectedClaim.order}
            claim={selectedClaim}
            show={showEditClaimsDialog}
            hide={closeEditClaimDialog}
            forceUpdate={forceUpdate}
          />
        )}
        <AgreementDialog
          open={showCloseClaimDialog}
          message={t('ADMIN.ARE_YOU_SURE')}
          acceptButtonText={t('GENERAL.YES')}
          handleClose={() => {
            setShowCloseClaimDialog(false)
          }}
          agree={handleClaimCloseConfirm}
        />
      </Fragment>
    </Fragment>
  )
}

export default withTranslation()(connect()(ClaimsOverview))
