import {
  Store as AdvertsExchangeIcon,
  MenuBook as AdvertsIcon,
  MenuBookOutlined as AdvertsIconOutlined,
  BarChart,
  LibraryBooksOutlined as BayIcon,
  Build,
  ChevronLeft,
  ReceiptOutlined as ContractsIcon,
  Home as DashboardIcon,
  DescriptionOutlined,
  Domain,
  ExpandLess,
  ExpandMore,
  LocalOffer as LocalOfferIcon,
  LocalShippingOutlined,
  ShoppingCart as MarketplaceIcon,
  Menu as MenuIcon,
  EventAvailable as OrdersIcon,
  PeopleOutlined,
  Person as ProfileIcon,
  Assignment as RequestsIcon,
  AssignmentOutlined as RequestsIconOutlined,
  SmartToy,
  Flag as SpotMarketplaceIcon,
  Gavel as TendersIcon,
  GavelOutlined as TendersIconOutlined,
  WorkOutlineOutlined,
} from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import MailIcon from '@mui/icons-material/Mail'
import {
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Drawer as MuiDrawer,
  useMediaQuery,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { authorize } from '../../../../_helpers/authorization'
const drawerWidth = 240

const openedMixin = (theme, isAdmin, isMobile) => ({
  top: isAdmin && isMobile ? 110 : 72,
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme, isAdmin, isMobile) => ({
  top: isAdmin && isMobile ? 110 : 72,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => {
  const isAdmin = authorize('administrator')
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  return {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme, isAdmin, isMobile),
      '& .MuiDrawer-paper': openedMixin(theme, isAdmin, isMobile),
    }),
    ...(!open && {
      ...closedMixin(theme, isAdmin, isMobile),
      '& .MuiDrawer-paper': closedMixin(theme, isAdmin, isMobile),
    }),
  }
})

const Sidebar = (props) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [anchorEl, setAnchorEl] = React.useState(null)

  const actStyle = { backgroundColor: 'rgba(0, 0, 0, 0.08)' }

  const [adminOpen, setAdminOpen] = React.useState(false)
  const [companyOpen, setCompanyOpen] = React.useState(false)

  const [ordersOpen, setOrdersOpen] = React.useState(false)

  const [offersOpen, setOffersOpen] = React.useState(false)

  const isLessThanLg = useMediaQuery(theme.breakpoints.down('lg'))

  function handleAdmin(e) {
    setAdminOpen(!adminOpen)
    setCompanyOpen(false)
    setOrdersOpen(false)
    setOffersOpen(false)
    if (!adminOpen) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  function handleCompany(e) {
    setCompanyOpen(!companyOpen)
    setAdminOpen(false)
    setOrdersOpen(false)
    setOffersOpen(false)
    if (!companyOpen) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  function handleOrders(e) {
    setOrdersOpen(!ordersOpen)
    setCompanyOpen(false)
    setAdminOpen(false)
    setOffersOpen(false)
    if (!ordersOpen) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  function handleOffers(e) {
    setOffersOpen(!offersOpen)
    setOrdersOpen(false)
    setAdminOpen(false)
    setCompanyOpen(false)
    if (!offersOpen) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleDrawerWrapper = (event) => {
    props.handleDrawer()
    if (props.drawerOpen) {
      setAdminOpen(false)
      setOrdersOpen(false)
      setCompanyOpen(false)
      setOffersOpen(false)
      setAnchorEl(null)
    }
  }
  const closeAllSubMenus = () => {
    setAdminOpen(false)
    setCompanyOpen(false)
    setOrdersOpen(false)
    setOffersOpen(false)
  }
  const handleMenuClickOnMobile = (event) => {
    if (isMobile) {
      closeAllSubMenus()
      if (props.drawerOpen) {
        props.handleDrawer()
      }
    }
  }

  var userObject = JSON.parse(localStorage.getItem('user'))

  const location = useLocation()
  const pathname = location.pathname

  const renderAdminCollapse = (
    <Collapse in={adminOpen} timeout="auto" unmountOnExit>
      <List dense={isLessThanLg ? true : false} component="div" disablePadding>
        {isMobile && <Divider />}
        <ListItem
          button
          key="visitors"
          component={NavLink}
          to="/visitors"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <ProfileIcon color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.VISITORS')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="partner-overview"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          component={NavLink}
          to="/partner-overview"
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <BayIcon color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.PARTNER_OVERVIEW')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="overviews"
          component={NavLink}
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          to="/overviews"
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <BayIcon color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.OVERVIEWS')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="registrations"
          component={NavLink}
          to="/registrations"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <BayIcon color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.REGISTRATIONS')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="price-checker"
          component={NavLink}
          to="/price-checker"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <BayIcon color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.PRICE_CHECKER')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="partner-checker"
          component={NavLink}
          to="/partner-checker"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <BayIcon color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.PARTNER_CHECKER')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="partner-overview-map"
          component={NavLink}
          to="/partner-overview-map"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <BayIcon color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.PARTNER_MAP')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="automatic-offer-positions-map"
          component={NavLink}
          to="/automatic-offer-positions-map"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <BayIcon color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.OFFER_SERVICES_MAP')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="global-settings"
          component={NavLink}
          to="/global-settings"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <BayIcon color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.GLOBAL_SETTINGS')} />
        </ListItem>
        <Divider />
      </List>
    </Collapse>
  )

  const renderOrdersCollapse = (
    <Collapse in={ordersOpen} timeout="auto" unmountOnExit>
      <List dense={isLessThanLg ? true : false} component="div" disablePadding>
        {
          <Fragment>
            {isMobile && <Divider />}
            <ListItem
              button
              key="deliveries-manufacturer"
              component={NavLink}
              to="/deliveries-manufacturer"
              onClick={(e) => {
                if (props.drawerOpen) {
                  handleMenuClickOnMobile(e)
                }
              }}
              style={({ isActive }) =>
                isActive ? actStyle : { textDecoration: 'none', color: 'black' }
              }
            >
              <ListItemIcon>
                <LocalShippingOutlined color="default" />
              </ListItemIcon>
              <ListItemText primary={t('SIDE_BAR.ORDERS_MANUFACTURER')} />
            </ListItem>
            {isMobile && <Divider />}
            <ListItem
              button
              key="/deliveries-customer"
              component={NavLink}
              to="/deliveries-customer"
              onClick={(e) => {
                if (props.drawerOpen) {
                  handleMenuClickOnMobile(e)
                }
              }}
              style={({ isActive }) =>
                isActive ? actStyle : { textDecoration: 'none', color: 'black' }
              }
            >
              <ListItemIcon>
                <LocalShippingOutlined
                  color="default"
                  style={{ transform: 'scaleX(-1)' }}
                />
              </ListItemIcon>
              <ListItemText
                primary={t('SIDE_BAR.ORDERS_CUSTOMER_AS_MANUFACTURER')}
              />
            </ListItem>
            {isMobile && <Divider />}
          </Fragment>
        }
        <ListItem
          button
          key="contracts"
          component={NavLink}
          to="/contracts"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <ContractsIcon color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.CONTRACTS')} />
        </ListItem>
        <Divider />
      </List>
    </Collapse>
  )

  const renderCompanyCollapse = (
    <Collapse in={companyOpen} timeout="auto" unmountOnExit>
      {isMobile && <Divider />}
      <List dense={isLessThanLg ? true : false} component="div" disablePadding>
        <ListItem
          button
          key="company-data"
          component={NavLink}
          to="/company-data"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <WorkOutlineOutlined color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.COMPANY_DATA')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="company-users"
          component={NavLink}
          to="/company-users"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <PeopleOutlined color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.COMPANY_USERS')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="charts"
          component={NavLink}
          to="/charts"
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <BarChart color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.REPORTING')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="mail-settings"
          component={NavLink}
          to="/mail-settings"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <MailIcon color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.EMAIL_SETTINGS')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="communities"
          component={NavLink}
          to="/communities"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <GroupsIcon color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.SIDE_BAR.COMMUNITIES')} />
        </ListItem>
        {isMobile && <Divider />}
        {userObject.partner.type === 'manufacturer' && (
          <>
            <ListItem
              button
              key="company-documents"
              component={NavLink}
              to="/company-documents"
              onClick={(e) => {
                handleMenuClickOnMobile(e)
              }}
              style={({ isActive }) =>
                isActive ? actStyle : { textDecoration: 'none', color: 'black' }
              }
            >
              <ListItemIcon>
                <DescriptionOutlined color="default" />
              </ListItemIcon>
              <ListItemText primary={t('SIDE_BAR.COMPANY_DOCUMENTS')} />
            </ListItem>
            {isMobile && <Divider />}
          </>
        )}
        {userObject.partner.type === 'manufacturer' && (
          <>
            <ListItem
              button
              key="automatic-offers"
              component={NavLink}
              to="/automatic-offers"
              onClick={(e) => {
                handleMenuClickOnMobile(e)
              }}
              style={({ isActive }) =>
                isActive ? actStyle : { textDecoration: 'none', color: 'black' }
              }
            >
              <ListItemIcon>
                <SmartToy color="default" />
              </ListItemIcon>
              <ListItemText primary={t('SIDE_BAR.OFFER_SERVICES')} />
            </ListItem>
            {isMobile && <Divider />}
          </>
        )}
        <Divider />
      </List>
    </Collapse>
  )

  const renderOfferCollapse = (
    <Collapse in={offersOpen} timeout="auto" unmountOnExit>
      {isMobile && <Divider />}
      <List dense={isLessThanLg ? true : false} component="div" disablePadding>
        <ListItem
          button
          key="offers-requests"
          component={NavLink}
          to="/offers-requests"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <RequestsIconOutlined color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.REQUESTS')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="offers-tenders"
          component={NavLink}
          to="/offers-tenders"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <TendersIconOutlined color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.TENDERS')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="offers-adverts"
          component={NavLink}
          to="/offers-adverts"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon>
            <AdvertsIconOutlined color="default" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.ADVERTS')} />
        </ListItem>
        <Divider />
      </List>
    </Collapse>
  )

  const renderAdminMenu = (
    <Menu
      open={adminOpen}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleAdmin}
    >
      <Link
        to="/visitors"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleAdmin}>
          <ProfileIcon color="primary" />
          {t('SIDE_BAR.VISITORS')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/partner-overview"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleAdmin}>
          <BayIcon color="primary" />
          {t('SIDE_BAR.PARTNER_OVERVIEW')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/price-checker"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleAdmin}>
          <BayIcon color="primary" />
          {t('SIDE_BAR.PRICE_CHECKER')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/partner-checker"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleAdmin}>
          <BayIcon color="primary" />
          {t('SIDE_BAR.PARTNER_CHECKER')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/automatic-offer-positions-map"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleAdmin}>
          <BayIcon color="primary" />
          {t('SIDE_BAR.OFFER_SERVICES_MAP')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/partner-overview-map"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleAdmin}>
          <BayIcon color="primary" />
          {t('SIDE_BAR.PARTNER_MAP')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/overviews"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleAdmin}>
          <BayIcon color="primary" />
          {t('SIDE_BAR.OVERVIEWS')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/registrations"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleAdmin}>
          <BayIcon color="primary" />
          {t('SIDE_BAR.REGISTRATIONS')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/global-settings"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleAdmin}>
          <BayIcon color="primary" />
          {t('SIDE_BAR.GLOBAL_SETTINGS')}
        </MenuItem>
      </Link>
    </Menu>
  )

  const renderOrdersMenu = (
    <Menu
      open={ordersOpen}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleOrders}
    >
      <Link
        to="/deliveries-manufacturer"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleOrders}>
          <LocalShippingOutlined color="primary" />
          {t('SIDE_BAR.ORDERS_MANUFACTURER')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/deliveries-customer"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleOrders}>
          <LocalShippingOutlined color="primary" />
          {t('SIDE_BAR.ORDERS_CUSTOMER_AS_MANUFACTURER')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/contracts"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleOrders}>
          <ContractsIcon color="primary" />
          {t('SIDE_BAR.CONTRACTS')}
        </MenuItem>
      </Link>
    </Menu>
  )

  const renderOffersMenu = (
    <Menu
      open={offersOpen}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleOffers}
    >
      <Link
        to="/offers-requests"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleOffers}>
          <RequestsIcon color="primary" />
          {t('SIDE_BAR.REQUESTS')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/offers-tenders"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleOffers}>
          <TendersIcon color="primary" />
          {t('SIDE_BAR.TENDERS')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/offers-adverts"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleOffers}>
          <AdvertsIcon color="primary" />
          {t('SIDE_BAR.ADVERTS')}
        </MenuItem>
      </Link>
    </Menu>
  )

  const renderCompanyMenu = (
    <Menu
      open={companyOpen}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleCompany}
    >
      <Link
        to="/company-data"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleCompany}>
          <Domain color="primary" />
          {t('SIDE_BAR.COMPANY_DATA')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/company-users"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleCompany}>
          <PeopleOutlined color="primary" />
          {t('SIDE_BAR.COMPANY_USERS')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/mail-settings"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleCompany}>
          <DescriptionOutlined color="primary" />
          {t('SIDE_BAR.EMAIL_SETTINGS')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/communities"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleCompany}>
          <GroupsIcon color="primary" />
          {t('SIDE_BAR.SIDE_BAR.COMMUNITIES')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      <Link
        to="/charts"
        onClick={(e) => {
          if (props.drawerOpen) {
            handleMenuClickOnMobile(e)
          }
        }}
        style={{ textDecoration: 'none', color: 'black' }}
      >
        <MenuItem onClick={handleCompany}>
          <BarChart color="primary" />
          {t('SIDE_BAR.REPORTING')}
        </MenuItem>
      </Link>
      {isMobile && <Divider />}
      {userObject.partner.type === 'manufacturer' && (
        <>
          <Link
            to="/automatic-offers"
            onClick={(e) => {
              if (props.drawerOpen) {
                handleMenuClickOnMobile(e)
              }
            }}
            style={{ textDecoration: 'none', color: 'black' }}
          >
            <MenuItem onClick={handleCompany}>
              <SmartToy color="primary" />
              {t('SIDE_BAR.OFFER_SERVICES')}
            </MenuItem>
          </Link>
          {isMobile && <Divider />}
        </>
      )}

      {userObject.partner.type === 'manufacturer' && (
        <Link
          to="/company-documents"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <MenuItem onClick={handleCompany}>
            <DescriptionOutlined color="primary" />
            {t('SIDE_BAR.COMPANY_DOCUMENTS')}
          </MenuItem>
        </Link>
      )}
    </Menu>
  )
  return (
    <Drawer variant="permanent" open={props.drawerOpen}>
      {props.drawerOpen && (
        <DrawerHeader>
          <IconButton onClick={handleDrawerWrapper} size="large">
            <ChevronLeft />
          </IconButton>
        </DrawerHeader>
      )}
      {!props.drawerOpen && (
        <List dense={isLessThanLg ? true : false}>
          <ListItem button key="open drawer" onClick={handleDrawerWrapper}>
            <ListItemIcon>
              <MenuIcon />
            </ListItemIcon>
          </ListItem>
        </List>
      )}
      <Divider />
      <List dense={isLessThanLg ? true : false}>
        <ListItem
          button
          key="dashboard"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          component={NavLink}
          exact
          to="/"
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
            <DashboardIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.DASHBOARD')} />
        </ListItem>
        {isMobile && <Divider />}
        {userObject.partner.type === 'manufacturer' ? (
          <>
            <ListItem
              button
              key="marketplace"
              onClick={(e) => {
                handleMenuClickOnMobile(e)
              }}
              component={NavLink}
              exact
              to="/marketplace"
              style={({ isActive }) =>
                isActive ? actStyle : { textDecoration: 'none', color: 'black' }
              }
            >
              <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
                <MarketplaceIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('SIDE_BAR.MARKETPLACE')} />
            </ListItem>
            {isMobile && <Divider />}
          </>
        ) : null}
        {userObject.partner.hasAccessToSpotMarketplace ? (
          <>
            <ListItem
              button
              key="spot-marketplace"
              component={NavLink}
              exact
              to="/spot-marketplace"
              onClick={(e) => {
                if (props.drawerOpen) {
                  handleMenuClickOnMobile(e)
                }
              }}
              style={({ isActive }) =>
                isActive ? actStyle : { textDecoration: 'none', color: 'black' }
              }
            >
              <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
                <SpotMarketplaceIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('SIDE_BAR.SPOT_MARKETPLACE')} />
            </ListItem>
            {isMobile && <Divider />}
          </>
        ) : null}
        <ListItem
          button
          key="adverts-exchange"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          component={NavLink}
          exact
          to="/adverts-exchange"
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
            <AdvertsExchangeIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.ADVERTS_EXCHANGE')} />
        </ListItem>
        <Divider />
        <ListItem
          button
          key="requests"
          component={NavLink}
          exact
          to="/requests"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
            <RequestsIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.MY_REQUESTS')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="tenders"
          component={NavLink}
          exact
          to="/tenders"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
            <TendersIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.MY_TENDERS')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          button
          key="adverts"
          component={NavLink}
          exact
          to="/adverts"
          onClick={(e) => {
            handleMenuClickOnMobile(e)
          }}
          style={({ isActive }) =>
            isActive ? actStyle : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
            <AdvertsIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.MY_ADVERTS')} />
        </ListItem>
        {isMobile && <Divider />}
        <ListItem
          component={NavLink}
          to={'#'}
          button
          key="offers"
          id="offers"
          onClick={handleOffers}
          style={() =>
            (!props.drawerOpen || (props.drawerOpen && !offersOpen)) &&
            (pathname.match(/\/offers-requests/) ||
              pathname.match(/\/offers-tenders/) ||
              pathname.match(/\/offers-adverts/))
              ? actStyle
              : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
            <LocalOfferIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.MY_OFFERS')} />
          {offersOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {props.drawerOpen ? renderOfferCollapse : renderOffersMenu}
        {isMobile && <Divider />}
        <ListItem
          component={NavLink}
          to={'#'}
          button
          key="orders"
          id="orders"
          onClick={handleOrders}
          style={() =>
            (!props.drawerOpen || (props.drawerOpen && !ordersOpen)) &&
            (pathname.match(/\/deliveries-/) || pathname.match(/\/contracts/))
              ? actStyle
              : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
            <OrdersIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.MY_ORDERS')} />
          {ordersOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {props.drawerOpen ? renderOrdersCollapse : renderOrdersMenu}
        <Divider />
        <ListItem
          component={NavLink}
          to={'#'}
          button
          key="company"
          id="company"
          onClick={handleCompany}
          style={() =>
            (!props.drawerOpen || (props.drawerOpen && !companyOpen)) &&
            pathname.match(/\/company-/)
              ? actStyle
              : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
            <Domain color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.COMPANY')} />
          {companyOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        {isMobile && authorize('administrator') && <Divider />}
        {props.drawerOpen ? renderCompanyCollapse : renderCompanyMenu}
      </List>

      {authorize('administrator') ? (
        <ListItem
          component={NavLink}
          to={'#'}
          button
          key="admin"
          id="admin"
          onClick={handleAdmin}
          style={() =>
            (!props.drawerOpen || (props.drawerOpen && !adminOpen)) &&
            (pathname.match(/\/visitors/) ||
              pathname.match(/\/partner-overview/) ||
              pathname.match(/\/partner-overview-map/) ||
              pathname.match(/\/global-settings/) ||
              pathname.match(/\/registrations/) ||
              pathname.match(/\/overviews/) ||
              pathname.match(/\/automatic-offer-positions-map/))
              ? actStyle
              : { textDecoration: 'none', color: 'black' }
          }
        >
          <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
            <Build color="primary" />
          </ListItemIcon>
          <ListItemText primary={t('SIDE_BAR.ADMIN')} />
          {adminOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      ) : null}
      {isMobile && authorize('administrator') && <Divider />}
      {props.drawerOpen && authorize('administrator')
        ? renderAdminCollapse
        : authorize('administrator')
        ? renderAdminMenu
        : null}
      {(props.drawerOpen && window.location.host === 'logcoop.packlogx.com') ||
      window.location.host === 'logcoop-test.packlogx.com' ? (
        <div>
          <div>
            <span>Powered by</span>
          </div>
          <div>
            <a
              href="https://pacurion.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="../../images/logo.png"
                height="60"
                alt="Pacurion Logo"
              />
            </a>
          </div>
        </div>
      ) : null}
    </Drawer>
  )
}
export default Sidebar
