import { HelpOutline, Save } from '@mui/icons-material'
import { Card, Grid, IconButton, Tooltip } from '@mui/material'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { claimAttachmentsService } from '../../../../../../../../../../_services/claimAttachmentsService'
import { InfoTooltip } from '../../../../../../../../../InfoTooltip'
import { Section } from '../../../../../../../../../Section'

const ClaimAttachmentsView = (props) => {
  const { t, data } = props

  return (
    <Section
      header={
        <Fragment>
          {t('CLAIM.CLAIM_ATTACHMENTS.ATTACHMENT')}
          <InfoTooltip
            disableTouchListener
            disableFocusListener
            title={t('CLAIM.CLAIM_ATTACHMENTS_TOOLTIP')}
          >
            <IconButton
              color="default"
              style={{
                padding: 0,
                margin: '0 8px',
              }}
              size="large"
            >
              <HelpOutline />
            </IconButton>
          </InfoTooltip>
        </Fragment>
      }
    >
      {data.attachments && data.attachments.length > 0 ? (
        <Grid container spacing={1} style={{ padding: '15px' }}>
          {data.attachments.map((attachment, index) => (
            <Grid item xs={12} lg={3} sm={6} key={attachment.name + index}>
              <Card elevation={0} style={{ border: '1px solid #ddd' }}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent="flex-end"
                  wrap="nowrap"
                  style={{ lineHeight: '27px' }}
                >
                  <div
                    style={{
                      marginRight: '10px',
                    }}
                  >
                    <b>{attachment.name}</b>
                  </div>

                  <Tooltip title={t('GENERAL.DOWNLOAD')}>
                    <IconButton
                      color="default"
                      size="small"
                      disabled={attachment.isNew}
                      onClick={() => {
                        claimAttachmentsService
                          .getClaimAttachment(attachment.id)
                          .then((response) => response.blob())
                          .then((blob) => {
                            const url = window.URL.createObjectURL(blob)
                            const link = document.createElement('a')
                            link.href = url
                            link.setAttribute('download', attachment.name)
                            document.body.appendChild(link)
                            link.click()
                            link.parentNode.removeChild(link)
                          })
                      }}
                    >
                      <Save />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div style={{ textAlign: 'center', padding: '10px' }}>
          {t('CLAIM.NO_ATTACHMENTS')}
        </div>
      )}
    </Section>
  )
}

export default withTranslation()(ClaimAttachmentsView)
