import AddBox from '@mui/icons-material/AddBox'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import Check from '@mui/icons-material/Check'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Clear from '@mui/icons-material/Clear'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import Edit from '@mui/icons-material/Edit'
import FilterList from '@mui/icons-material/FilterList'
import FirstPage from '@mui/icons-material/FirstPage'
import LastPage from '@mui/icons-material/LastPage'
import Remove from '@mui/icons-material/Remove'
import SaveAlt from '@mui/icons-material/SaveAlt'
import Search from '@mui/icons-material/Search'
import ViewColumn from '@mui/icons-material/ViewColumn'
import React, { forwardRef } from 'react'
import i18n from '../i18n'

export const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

export const tableLocalization = () => {
  return {
    grouping: {
      placeholder: i18n.t('TABLE.GROUPING_PLACEHOLDER'),
      groupedBy: i18n.t('TABLE.GROUPING_BY'),
    },
    header: {
      actions: i18n.t('TABLE.ACTIONS'),
    },
    toolbar: {
      addRemoveColumns: i18n.t('TABLE.ADD_REMOVE_COLUMNS'),
      nRowsSelected: i18n.t('TABLE.N_ROWS_SELECTED'),
      showColumnsTitle: i18n.t('TABLE.SHOW_COLUMNS_TITLE'),
      showColumnsAriaLabel: i18n.t('TABLE.SHOW_COLUMNS_TITLE'),
      exportTitle: i18n.t('TABLE.EXPORT_TITLE'),
      exportAriaLabel: i18n.t('TABLE.EXPORT_TITLE'),
      exportName: i18n.t('TABLE.EXPORT_NAME'),
      searchTooltip: i18n.t('TABLE.SEARCH'),
      searchPlaceholder: i18n.t('TABLE.SEARCH'),
    },
    pagination: {
      labelDisplayedRows: i18n.t('TABLE.OF'),
      labelRowsSelect: i18n.t('TABLE.ROWS'),
      labelRowsPerPage: i18n.t('TABLE.ROWS_PER_PAGE'),
      firstAriaLabel: i18n.t('TABLE.FIRST_PAGE'),
      firstTooltip: i18n.t('TABLE.FIRST_PAGE'),
      previousAriaLabel: i18n.t('TABLE.PREVIOUS_PAGE'),
      previousTooltip: i18n.t('TABLE.PREVIOUS_PAGE'),
      nextAriaLabel: i18n.t('TABLE.NEXT_PAGE'),
      nextTooltip: i18n.t('TABLE.NEXT_PAGE'),
      lastAriaLabel: i18n.t('TABLE.LAST_PAGE'),
      lastTooltip: i18n.t('TABLE.LAST_PAGE'),
    },
    body: {
      emptyDataSourceMessage: i18n.t('TABLE.EMPTY_DATA_SOURCE'),
      addTooltip: i18n.t('TABLE.ADD'),
      deleteTooltip: i18n.t('TABLE.DELETE'),
      editTooltip: i18n.t('TABLE.EDIT'),
      filterRow: {
        filterTooltip: i18n.t('TABLE.FILTER'),
      },
      editRow: {
        deleteText: i18n.t('TABLE.DELETE_TEXT'),
        cancelTooltip: i18n.t('TABLE.CANCEL'),
        saveTooltip: i18n.t('TABLE.SAVE'),
      },
    },
  }
}
