import { Grid, Typography } from '@mui/material'
import React from 'react'
import { withTranslation } from 'react-i18next'
import DifferenceWrapper from '../DifferenceWrapper'
import { Section } from '../Section'

const OfferPaymentDetailsView = (props) => {
  const { t, data, differences, diffObjectType } = props

  const userObject = JSON.parse(localStorage.getItem('user'))

  return (
    <Section header={t('REQUEST.PAYMENT_DETAILS.HEADING')} hideBorder={true}>
      <Grid container spacing={1}>
        <Grid item container xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant={'subtitle1'}>
              {t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM') + ':'}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <DifferenceWrapper
              showDifference={
                (data.request.type !== 'ADVERT' &&
                  data.request.partnerId === userObject.partner.id) ||
                (data.request.type === 'ADVERT' &&
                  data.request.partnerId !== userObject.partner.id)
                  ? differences.paymentTerm ||
                    differences.skonto ||
                    differences.skontoPeriod
                  : differences.paymentTerm
              }
              difference={
                (data.request.type !== 'ADVERT' &&
                  data.request.partnerId === userObject.partner.id) ||
                (data.request.type === 'ADVERT' &&
                  data.request.partnerId !== userObject.partner.id)
                  ? differences.paymentTerm ||
                    differences.skonto ||
                    differences.skontoPeriod
                  : differences.paymentTerm
              }
              differenceDisplay={
                differences.paymentTerm === 0
                  ? t('GENERAL.PREPAYMENT')
                  : (data.request.type !== 'ADVERT' &&
                      data.request.partnerId === userObject.partner.id) ||
                    (data.request.type === 'ADVERT' &&
                      data.request.partnerId !== userObject.partner.id)
                  ? differences.skontoPeriod
                    ? `${
                        differences.skontoPeriod
                          ? differences.skontoPeriod
                          : data.generalInformation.paymentDetails.skontoPeriod
                      } ${t('OFFER.DAYS')} ${
                        differences.skonto
                          ? differences.skonto
                          : data.generalInformation.paymentDetails.skonto
                      } % ${t('REQUEST.PAYMENT_DETAILS.SKONTO')}, ${
                        differences.paymentTerm
                          ? differences.paymentTerm
                          : data.generalInformation.paymentDetails.paymentTerm
                      } ${t('GENERAL.NET')}`
                    : `${
                        differences.paymentTerm
                          ? differences.paymentTerm
                          : data.generalInformation.paymentDetails.paymentTerm
                      } ${t('OFFER.DAYS')} ${t('GENERAL.NET')}`
                  : `${
                      differences.paymentTerm
                        ? differences.paymentTerm
                        : data.generalInformation.paymentDetails.paymentTerm
                    } ${t('OFFER.DAYS')} ${t('GENERAL.NET')}`
              }
              diffObjectType={diffObjectType}
            >
              {data.generalInformation.paymentDetails.paymentTerm === 0 ? (
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  {t('GENERAL.PREPAYMENT')}
                </Typography>
              ) : ((data.request.type !== 'ADVERT' &&
                  data.request.partnerId === userObject.partner.id) ||
                  (data.request.type === 'ADVERT' &&
                    data.request.partnerId !== userObject.partner.id)) &&
                data.generalInformation.paymentDetails.skonto !== 0 ? (
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  {`${data.generalInformation.paymentDetails.skontoPeriod} ${t(
                    'OFFER.DAYS',
                  )} ${data.generalInformation.paymentDetails.skonto} % ${t(
                    'REQUEST.PAYMENT_DETAILS.SKONTO',
                  )}, ${data.generalInformation.paymentDetails.paymentTerm} ${t(
                    'OFFER.DAYS',
                  )} ${t('GENERAL.NET')}`}
                </Typography>
              ) : (
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  {`${data.generalInformation.paymentDetails.paymentTerm} ${t(
                    'OFFER.DAYS',
                  )} ${t('GENERAL.NET')}`}
                </Typography>
              )}
            </DifferenceWrapper>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  )
}

export default withTranslation()(OfferPaymentDetailsView)
