import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import numeral from 'numeral'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { translateProductUnit } from '../../../../_helpers/little'
import { generalService } from '../../../../_services/generalService'
import { productUnitsService } from '../../../../_services/productUnitsService'
import PositionCard from '../../../PositionCard'
import { Section } from '../../../Section'
const RequestPositionsView = (props) => {
  const { t, data } = props

  const [productUnits, setProductUnits] = useState([])

  useEffect(() => {
    productUnitsService.getProductUnits().then((data) => setProductUnits(data))
  }, [])

  return (
    <Section header={t('REQUEST.REQUEST_POSITIONS')}>
      <Grid container spacing={3} direction="row" alignItems="stretch">
        {productUnits.length > 0 &&
          data.generalInformation.deliveryDetails.locations[0].positions.map(
            (pos) => {
              let deliveriesWithPosition = data.deliveries.filter((delivery) =>
                delivery.positions.some(
                  (dPos) => pos.id === dPos.positionId && dPos.amount > 0,
                ),
              )
              return (
                <Grid item xs={12} sm={6}>
                  <PositionCard
                    data={pos}
                    showProductDetails={true}
                    readOnly={true}
                    showImage={true}
                    imageSize={'small'}
                  >
                    <div>
                      <Typography>{`${t('REQUEST.AMOUNT')}: ${numeral(
                        parseFloat(pos.amount),
                      ).format('0,0.[00]')} ${translateProductUnit(
                        productUnits.find(
                          (productUnit) => productUnit.id === pos.productUnitId,
                        ),
                      )}`}</Typography>
                    </div>
                    {deliveriesWithPosition.map((delivery) => {
                      let dPos = delivery.positions.find(
                        (dPos) => pos.id === dPos.positionId,
                      )
                      return (
                        <div>
                          <Typography>{`${t('REQUEST.DELIVERY')} ${
                            delivery.type === 'date'
                              ? moment(delivery.value).format('DD.MM.YYYY')
                              : `${t(
                                  'ORDERS.CW',
                                )} ${generalService.convertDateToCalendarWeek(
                                  delivery.value,
                                )}`
                          }: ${numeral(parseFloat(dPos.amount)).format(
                            '0,0.[00]',
                          )} ${translateProductUnit(
                            productUnits.find(
                              (productUnit) =>
                                productUnit.id === pos.productUnitId,
                            ),
                          )}`}</Typography>
                        </div>
                      )
                    })}
                  </PositionCard>
                </Grid>
              )
            },
          )}
      </Grid>
    </Section>
  )
}

export default withTranslation()(connect()(RequestPositionsView))
