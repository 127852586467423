import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material'
import { ErrorMessage, Field, Formik, yupToFormErrors } from 'formik'
import { TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { getTouchedObj, translateProductUnit } from '../../_helpers/little'
import NumberFormatCustom from '../../_helpers/numberFormatCustom'
import { generalService } from '../../_services/generalService'
import { productUnitsService } from '../../_services/productUnitsService'
import CalendarWeekPicker from '../CalendarWeekPicker'
import CustomDialogTitle from '../CustomDialogTitle'
import { FieldErrorWrapper } from '../FieldErrorWrapper'
import PositionCard from '../PositionCard'

const DeliveryWizard = (props) => {
  const {
    t,
    open,
    close,
    readOnly,
    data,
    submit,
    positions,
    deliveries,
    selectedDelivery,
    type,
  } = props

  const [initialValues, setInitialValues] = useState({
    selectedDays: [],
    type: selectedDelivery ? selectedDelivery.type : 'calendarWeek',
    value: selectedDelivery ? selectedDelivery.value : null,
    positions: [],
  })
  const [activeStep, setActiveStep] = React.useState(0)

  const [productUnits, setProductUnits] = React.useState([])

  useEffect(() => {
    var selectedDays = []

    if (
      selectedDelivery &&
      selectedDelivery.type === 'calendarWeek' &&
      selectedDelivery.value !== null
    ) {
      for (var i = 0; i < 7; i++) {
        var date = generalService.addDaysToDate(
          moment(selectedDelivery.value),
          i,
        )
        selectedDays.push(date)
      }
    }

    setInitialValues({
      selectedDays: selectedDays,
      type: selectedDelivery ? selectedDelivery.type : 'calendarWeek',
      value: selectedDelivery ? selectedDelivery.value : null,
      positions:
        type !== 'OFFER'
          ? positions.map((pos) => {
              return {
                amount: selectedDelivery
                  ? selectedDelivery.positions.find(
                      (selectedPos) => pos.id === selectedPos.positionId,
                    ).amount
                  : 0,
                positionId: pos.id,
              }
            })
          : positions.map((pos) => {
              return {
                amount:
                  selectedDelivery &&
                  selectedDelivery.positions.find((selectedPos) => {
                    return pos.id === selectedPos.positionId
                  })
                    ? selectedDelivery.positions.find((selectedPos) => {
                        return pos.id === selectedPos.positionId
                      }).amount
                    : 0,
                positionId: pos.id,
              }
            }),
    })
  }, [selectedDelivery, open])

  useEffect(() => {
    productUnitsService.getProductUnits().then((data) => setProductUnits(data))
  }, [])

  useEffect(() => {
    if (open) {
      setActiveStep(0)
    }
  }, [open])

  const valSchema = Yup.object().shape({
    type: Yup.string().required(t('GENERAL.REQUIRED')),
    value: Yup.mixed().when('type', ([type], schema) => {
      return type !== 'calendarWeek'
        ? Yup.date()
            .typeError(t('GENERAL.INVALID_DATE'))
            .test('start', t('GENERAL.INVALID_DATE_PAST'), (start) => {
              if (start) {
                return moment().diff(moment(start), 'days') <= 0
              } else {
                return true
              }
            })
        : Yup.mixed().nullable()
    }),
    selectedDays: Yup.array().when('type', ([type], schema) => {
      return type === 'calendarWeek'
        ? schema.min(1, t('GENERAL.REQUIRED'))
        : Yup.mixed().nullable()
    }),
    positions: Yup.array()
      .of(
        Yup.object().shape({
          amount: Yup.number()
            .transform((cv, ov) => {
              return ov === '' ? null : cv
            })
            .nullable()
            .test({
              message: t('REQUEST.ADD_DELIVERY_WIZARD.POS_AMOUNT_EXCEEDED'),
              test: function (amount) {
                const dPos = this.parent
                var positionsAmount = parseFloat(
                  positions.filter((pos) => {
                    return pos.id === dPos.positionId
                  })[0].amount,
                )

                let otherDeliveries = deliveries.filter((delivery) =>
                  selectedDelivery ? delivery.id !== selectedDelivery.id : true,
                )

                var otherDeliveriesAmount = 0
                otherDeliveries.forEach((delivery) => {
                  delivery.positions.forEach((dPos2) => {
                    if (dPos2.positionId === dPos.positionId) {
                      otherDeliveriesAmount += parseFloat(dPos2.amount)
                    }
                  })
                })
                return activeStep === 0
                  ? true
                  : (amount ? amount : 0) <=
                      positionsAmount - otherDeliveriesAmount
              },
            }),
        }),
      )
      .test({
        message: t('DELIVERY_WIZARD.DELIVERY_IS_EMPTY'),
        test: function (positions) {
          if (activeStep === 0) {
            return true
          }

          let totalAmount = 0

          positions.forEach((pos) => {
            if (pos.amount) {
              totalAmount = totalAmount + parseFloat(pos.amount)
            }
          })
          return totalAmount > 0
        },
      }),
  })

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={valSchema}
      initialValues={initialValues}
      //validateOnBlur={false}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        isValid,
        isSubmitting,
        resetForm,
        setErrors,
        setTouched,
      }) => (
        <Dialog open={open} maxWidth="lg" fullWidth>
          <CustomDialogTitle
            title={
              readOnly
                ? t('REQUEST.ADD_DELIVERY_WIZARD.ADD_DELIVERY')
                : t('REQUEST.ADD_DELIVERY_WIZARD.ADD_DELIVERY')
            }
            onClose={() => close()}
          />
          <DialogContent dividers>
            <Stepper activeStep={activeStep} sx={{ margin: 2 }}>
              <Step>
                <StepLabel>{t('DELIVERY_WIZARD.DELIVERY_DATE')}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t('DELIVERY_WIZARD.POSITIONS')}</StepLabel>
              </Step>
            </Stepper>
            {activeStep === 0 ? (
              <Fragment>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Field
                      margin={'dense'}
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      select
                      name={'type'}
                      label={t(
                        'REQUEST.ADD_DELIVERY_WIZARD.DELIVERY_DATE_TYPE',
                      )}
                      style={{ marginBottom: '10px' }}
                      onChange={(e) => {
                        setFieldValue(
                          'value',
                          e.target.value === 'calendarWeek' ? [] : moment(),
                        )
                        setFieldValue('type', e.target.value)
                      }}
                    >
                      <MenuItem key="calendarWeek" value="calendarWeek">
                        {t('REQUEST.CALENDARWEEK')}
                      </MenuItem>
                      <MenuItem key="date" value="date">
                        {t('REQUEST.DATE')}
                      </MenuItem>
                    </Field>
                  </Grid>
                </Grid>
                {values.type === 'date' ? (
                  <FieldErrorWrapper
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        label: t('REQUEST.ADD_DELIVERY_WIZARD.DELIVERY_DATE'),
                        margin: 'dense',
                      },
                    }}
                    name="value"
                    fullWidth
                    component={DatePicker}
                    inputFormat="DD/MM/YYYY"
                    minDate={moment()}
                  />
                ) : (
                  <Grid container xs justifyContent="center">
                    <div className="form-group">
                      <CalendarWeekPicker
                        margin={'dense'}
                        selectedDays={values.selectedDays}
                        setSelectedDays={(days) =>
                          setFieldValue('selectedDays', days)
                        }
                      />
                      <ErrorMessage
                        name="selectedDays"
                        render={(msg) => (
                          <div className="help-block">{msg}</div>
                        )}
                      />
                    </div>
                  </Grid>
                )}
              </Fragment>
            ) : (
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="stretch"
                justifyContent={'flex-start'}
              >
                {(type === 'OFFER'
                  ? positions.filter((pos) => pos.checked)
                  : positions
                ).map((pos) => {
                  let otherDeliveriesAmount = 0

                  let otherDeliveries = deliveries.filter((delivery) =>
                    selectedDelivery
                      ? delivery.id !== selectedDelivery.id
                      : true,
                  )

                  otherDeliveries.forEach((delivery) => {
                    delivery.positions.forEach((dPos) => {
                      if (dPos.positionId === pos.id) {
                        otherDeliveriesAmount += parseFloat(dPos.amount)
                      }
                    })
                  })

                  let deliveryWizardPosAmount =
                    values.positions.find((dPos) => dPos.positionId === pos.id)
                      .amount === ''
                      ? 0
                      : parseFloat(
                          values.positions.find(
                            (dPos) => dPos.positionId === pos.id,
                          ).amount,
                        )
                  return (
                    <Grid item xs={12} sm={4}>
                      <PositionCard
                        data={pos}
                        showProductDetails={true}
                        readOnly={true}
                        showImage={true}
                        imageSize={'small'}
                      >
                        <Typography>{`${t(
                          'DELIVERY_WIZARD.AVAILABLE_AMOUNT',
                        )}: ${
                          pos.amount -
                          (otherDeliveriesAmount + deliveryWizardPosAmount)
                        } ${translateProductUnit(
                          productUnits.find(
                            (productUnit) =>
                              productUnit.id === pos.productUnitId,
                          ),
                        )}`}</Typography>
                        <Field
                          fullWidth={true}
                          component={TextField}
                          margin="dense"
                          size={positions.length > 3 ? 'small' : 'medium'}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            endAdornment: (
                              <InputAdornment position="end">
                                {translateProductUnit(
                                  productUnits.find(
                                    (productUnit) =>
                                      productUnit.id === pos.productUnitId,
                                  ),
                                )}
                              </InputAdornment>
                            ),
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          inputProps={{
                            decimalScale:
                              productUnits.find(
                                (productUnit) =>
                                  productUnit.id === pos.productUnitId,
                              ).type === 'FLOAT'
                                ? 2
                                : 0,
                            allowNegative: false,
                          }}
                          label={t('REQUEST.AMOUNT')}
                          variant="outlined"
                          name={`positions[${positions.findIndex(
                            (pos2) => pos.id === pos2.id,
                          )}].amount`}
                        />
                      </PositionCard>
                    </Grid>
                  )
                })}
                <Grid item xs={12}>
                  {errors.positions && typeof errors.positions === 'string' ? (
                    <ErrorMessage
                      name={'positions'}
                      render={(msg) => <div className="help-block">{msg}</div>}
                    />
                  ) : null}
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            {activeStep === 0 ? (
              <Fragment>
                <Button
                  variant="outlined"
                  color={'secondary'}
                  onClick={(e) => {
                    close()
                    resetForm()
                  }}
                >
                  {t('GENERAL.CANCEL')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(e) => {
                    valSchema
                      .validate(values, {
                        abortEarly: false,
                        context: values,
                      })
                      .then(function (value) {
                        setActiveStep(1)
                      })
                      .catch((err) => {
                        let yupErrors = yupToFormErrors(err)
                        setErrors(yupErrors)
                        setTouched(getTouchedObj(yupErrors), false) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                      })
                  }}
                >
                  {t('DELIVERY_WIZARD.CONTINUE')}
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <Button
                  variant="outlined"
                  color={'secondary'}
                  onClick={(e) => {
                    setActiveStep(0)
                  }}
                >
                  {t('GENERAL.BACK')}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={!isValid || isSubmitting}
                  onClick={(e) => {
                    valSchema
                      .validate(values, {
                        abortEarly: false,
                        context: values,
                      })
                      .then(function (value) {
                        close()

                        let deliveryValue = ''
                        if (values.type === 'calendarWeek') {
                          deliveryValue = moment(values.selectedDays[0])
                        } else {
                          deliveryValue = moment(values.value)
                        }

                        deliveryValue = deliveryValue.set({
                          hour: 12,
                          minute: 0,
                          second: 0,
                          millisecond: 0,
                        })

                        var delivery = {
                          id: data.deliveries.length + 1,
                          type: values.type,
                          value: deliveryValue,
                          positions: values.positions.map((pos) => {
                            return {
                              amount: pos.amount ? pos.amount : 0,
                              positionId: pos.positionId,
                            }
                          }),
                        }
                        submit(delivery)
                        resetForm()
                        setActiveStep(0)
                      })
                      .catch((err) => {
                        let yupErrors = yupToFormErrors(err)
                        setErrors(yupErrors)
                        setTouched(getTouchedObj(yupErrors), false) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                      })
                  }}
                >
                  {t('GENERAL.SUBMIT')}
                </Button>
              </Fragment>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}
export default withTranslation()(DeliveryWizard)
