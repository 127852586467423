import { Grid, Typography } from '@mui/material'
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { countryLookup } from '../../_constants/lookupConstants'
import { authorize } from '../../_helpers/authorization'
import { Section } from '../Section'

const OrderPaymentDetailsView = (props) => {
  const { t, data } = props

  const userObject = JSON.parse(localStorage.getItem('user'))

  return (
    <Section header={t('REQUEST.PAYMENT_DETAILS.HEADING')} hideBorder={true}>
      <Grid container spacing={1}>
        <Grid item container xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant={'subtitle1'}>
              {t('REQUEST.PAYMENT_DETAILS.PAYMENT_TERM') + ':'}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            {data.paymentTerm === 0 ? (
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {t('GENERAL.PREPAYMENT')}
              </Typography>
            ) : data.customerId === userObject.partner.id &&
              data.skonto !== 0 ? (
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {`${data.skontoPeriod} ${t('OFFER.DAYS')} ${data.skonto} % ${t(
                  'REQUEST.PAYMENT_DETAILS.SKONTO',
                )}, ${data.paymentTerm} ${t('OFFER.DAYS')} ${t('GENERAL.NET')}`}
              </Typography>
            ) : (
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {`${data.paymentTerm} ${t('OFFER.DAYS')} ${t('GENERAL.NET')}`}
              </Typography>
            )}
          </Grid>
          {(data.customerId === userObject.partner.id ||
            authorize('administrator')) && (
            <Fragment>
              <Grid item xs={12} style={{ alignSelf: 'center' }}>
                <Typography variant={'subtitle1'}>
                  {t('ORDERS.INVOICE_ADDRESS') + ':'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  <Fragment>{`${data.invoiceCompany},${
                    data.invoiceContactPerson
                      ? ' ' + data.invoiceContactPerson + ', '
                      : ''
                  } ${data.invoiceAddress},${
                    data.invoiceAdditionalAddressLine
                      ? ' ' + data.invoiceAdditionalAddressLine + ', '
                      : ''
                  } `}</Fragment>
                  <Fragment>{`${data.invoiceZipcode} ${data.invoicePlace}, ${
                    countryLookup[data.invoiceCountryId]
                  }, ${data.invoiceTaxId}`}</Fragment>
                </Typography>
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Grid>
    </Section>
  )
}

export default withTranslation()(OrderPaymentDetailsView)
