import { Carpenter, ShoppingCart } from '@mui/icons-material'
import { Chip, Stack, Typography } from '@mui/material'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { countryLookup } from '../../../../_constants/lookupConstants'
import { getIconPath } from '../../../../_helpers/little'
import { _applicationService } from '../../../../_services/_applicationService'
import { partnerCommissionGroupsService } from '../../../../_services/partnerCommissionGroupsService'
import { partnersService } from '../../../../_services/partnersService'
import ResourceMap from '../../../ResourceMap'

const ParnerOverviewMap = (props) => {
  const { t } = props

  const [isLoading, setIsLoading] = useState(false)

  const [partnerList, setPartnerList] = useState([])
  const [partnerCommissionGroups, setPartnerCommissionGroups] = useState([])

  useEffect(
    () => {
      setIsLoading(true)
      partnersService
        .getPartners()
        .then((data) => {
          setPartnerList(data)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    partnerCommissionGroupsService
      .getPartnerCommissionGroups()
      .then((partnerCommissioNGroups) => {
        setPartnerCommissionGroups(partnerCommissioNGroups)
      })
  }, [])

  const partnerOrderStateLookup = {
    INACTIVE: {
      text: t('PARTNER_OVERVIEW.PARTNER_ORDER_STATE.INACTIVE'),
      color: '#D1D1D1',
    },
    NONE: {
      text: t('PARTNER_OVERVIEW.PARTNER_ORDER_STATE.NONE'),
      color: '#FF0000',
    },
    NULL: {
      text: t('PARTNER_OVERVIEW.PARTNER_ORDER_STATE.NULL'),
      color: '#F0C807',
    },
    ACTIVE: {
      text: t('PARTNER_OVERVIEW.PARTNER_ORDER_STATE.ACTIVE'),
      color: '#008000',
    },
  }

  const getIcon = (partnerLocation) => {
    let partner = partnerLocation.partner

    return {
      path: getIconPath(
        partner.type === 'manufacturer' ? <Carpenter /> : <ShoppingCart />,
      ),
      fillColor: partnerOrderStateLookup[partner.orderState].color,
      fillOpacity: 1,
      strokeWeight: 2.25,
      scale: 2,
      anchor: new window.google.maps.Point(12, 12),
    }
  }

  return (
    <Fragment>
      <Fragment>
        <ResourceMap
          isPaginationEnabled={true}
          geoCoordinatePath={''}
          fetchMethod={(filterValues, pagination) => {
            return _applicationService.getPartnerLocationsMapData(filterValues)
          }}
          iconFunction={(partnerLocation) => {
            return getIcon(partnerLocation)
          }}
          mouseOverFunction={(partnerLocation) => (
            <Stack spacing={1}>
              <Typography variant="h6">{partnerLocation.company}</Typography>
              <Typography>
                {`${t('PARTNER_OVERVIEW.PARTNER_ID')} ${
                  partnerLocation.partner.id
                }`}
              </Typography>
              <Typography>{partnerLocation.type}</Typography>
              <Typography>
                {`${t('PARTNER_OVERVIEW.LOCATION_COMPANY')} ${
                  partnerLocation.company
                }`}
              </Typography>

              <Fragment>
                <Typography>
                  {`Anzahl Kundenaufträge: ${partnerLocation.customerOrderAmount}`}
                </Typography>
                {partnerLocation.latestCustomerOrder ? (
                  <Typography>
                    {`Letzter Kundenauftrag: ${moment(
                      partnerLocation.latestCustomerOrder,
                    ).format('DD.MM.YYYY')}`}
                  </Typography>
                ) : (
                  <Typography>
                    {`${t('PARTNER_OVERVIEW.NO_CUSTOMER_ORDERS_SO_FAR')}`}
                  </Typography>
                )}
              </Fragment>
              {partnerLocation.partner.type === 'manufacturer' && (
                <Fragment>
                  <Typography>
                    {`Anzahl Herstelleraufträge: ${partnerLocation.manufacturerOrderAmount}`}
                  </Typography>
                  {partnerLocation.latestManufacturerOrder ? (
                    <Typography>
                      {`Letzter Herstellerauftrag: ${moment(
                        partnerLocation.latestManufacturerOrder,
                      ).format('DD.MM.YYYY')}`}
                    </Typography>
                  ) : (
                    <Typography>{`${t(
                      'PARTNER_OVERVIEW.NO_MANUFACTURER_ORDERS_SO_FAR',
                    )}`}</Typography>
                  )}
                </Fragment>
              )}
            </Stack>
          )}
          filterId={'PARTNER_OVERVIEW_MAP'}
          filters={[
            {
              key: '~IGNORE~partnerOrderStates',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(partnerOrderStateLookup),
              lookup: {
                INACTIVE: t('PARTNER_OVERVIEW.PARTNER_ORDER_STATE.INACTIVE'),
                NONE: t('PARTNER_OVERVIEW.PARTNER_ORDER_STATE.NONE'),
                NULL: t('PARTNER_OVERVIEW.PARTNER_ORDER_STATE.NULL'),
                ACTIVE: t('PARTNER_OVERVIEW.PARTNER_ORDER_STATE.ACTIVE'),
              },
              label: t('PARTNER_OVERVIEW.PARTNER_ORDER_STATE.LABEL'),
            },
            // {
            //   key: 'partner.products.PartnerProduct.productId.eq',
            //   variant: 'categorizedCheckboxList',
            //   values: categorizedProductLookup.map((category) => {
            //     return {
            //       label: category.name,
            //       items: category.products.map((product) => product.id),
            //     }
            //   }),
            //   lookup: productLookup,
            //   label: t('MARKETPLACE.PRODUCT'),
            // },
            // {
            //   key: 'partnerProductStates',
            //   variant: 'checkboxList',
            //   values: Object.getOwnPropertyNames(productStateLookup),
            //   lookup: productStateLookup,
            //   label: t('MARKETPLACE.PRODUCT_STATE'),
            // },
            {
              key: 'partner.type',
              variant: 'checkboxList',
              values: ['manufacturer', 'customer'],
              lookup: {
                manufacturer: 'Hersteller',
                customer: 'Kunde',
              },
              label: t('MARKETPLACE.PARTNER_TYPE'),
            },
            {
              key: 'partner.active.eq',
              variant: 'checkboxList',
              values: ['true', 'false'],
              lookup: {
                true: 'Ja',
                false: 'Nein',
              },
              label: t('MARKETPLACE.IS_ACTIVE'),
            },
            {
              key: 'countryId.eq',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(countryLookup),
              lookup: countryLookup,
              label: t('MARKETPLACE.COUNTRY'),
            },
            {
              key: 'zipcode.startsWith',
              label: t('MARKETPLACE.ZIPCODE'),
              variant: 'textfield',
            },
            {
              key: 'place.startsWith',
              label: t('MARKETPLACE.PLACE'),
              variant: 'textfield',
            },
            {
              key: 'partnerId',
              label: t('ORDERS.CUSTOMER'),
              variant: 'autocomplete',
              options: partnerList.map((partner) => partner.id),
              lookup: Object.fromEntries(
                partnerList.map((partner) => [
                  partner.id,
                  `${partner.name} (${partner.id}) - ${partner.place}`,
                ]),
              ),
            },
            {
              key: 'partner.partnerCommissionGroups.id.eq',
              label: t('PARTNER_OVERVIEW.ACCOUNT_MANAGER'),
              variant: 'autocomplete',
              options: partnerCommissionGroups.map(
                (partnerCommissionGroup) => partnerCommissionGroup.id,
              ),
              lookup: Object.fromEntries(
                partnerCommissionGroups.map((partnerCommissionGroup) => [
                  partnerCommissionGroup.id,
                  `${partnerCommissionGroup.name}`,
                ]),
              ),
            },
          ]}
          sorting={[
            {
              key: 'id',
              default: true,
              label: t('MARKETPLACE.NEWEST_FIRST'),
            },
          ]}
          resourceHeaderRenderFunction={(partnerLocation) =>
            `${partnerLocation.company} (${partnerLocation.partner.id})`
          }
          listHeaderRenderFunction={(partnerLocation) => (
            <Fragment>
              <Typography variant="h6">{partnerLocation.company}</Typography>
              <Typography variant="subtitle1">
                {`${t('PARTNER_OVERVIEW.LOCATION_NAME')}: ${
                  partnerLocation.company
                }`}
              </Typography>
            </Fragment>
          )}
          headerActionRenderFunction={(partnerLocation) => {
            return (
              <Chip
                size={'medium'}
                style={{
                  color: '#fff',
                  backgroundColor:
                    partnerOrderStateLookup[partnerLocation.partner.orderState]
                      .color,
                }}
                label={
                  partnerOrderStateLookup[partnerLocation.partner.orderState]
                    .text
                }
              />
            )
          }}
          contentRenderFunction={(partnerLocation) => (
            <Fragment>
              <Fragment>
                <Typography>
                  {`Anzahl Kundenaufträge: ${partnerLocation.customerOrderAmount}`}
                </Typography>
                {partnerLocation.latestCustomerOrder ? (
                  <Typography>
                    {`Letzter Kundenauftrag: ${moment(
                      partnerLocation.latestCustomerOrder,
                    ).format('DD.MM.YYYY')}`}
                  </Typography>
                ) : (
                  <Typography>
                    {' '}
                    {`${t('PARTNER_OVERVIEW.NO_CUSTOMER_ORDERS_SO_FAR')}`}
                  </Typography>
                )}
              </Fragment>

              {partnerLocation.partner.type === 'manufacturer' && (
                <Fragment>
                  <br />
                  <Typography>
                    {`Anzahl Herstelleraufträge: ${partnerLocation.manufacturerOrderAmount}`}
                  </Typography>
                  {partnerLocation.latestManufacturerOrder ? (
                    <Typography>
                      {`Letzter Herstellerauftrag: ${moment(
                        partnerLocation.latestManufacturerOrder,
                      ).format('DD.MM.YYYY')}`}
                    </Typography>
                  ) : (
                    <Typography>{`${t(
                      'PARTNER_OVERVIEW.NO_MANUFACTURER_ORDERS_SO_FAR',
                    )}`}</Typography>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
          resourceRenderFunction={(partnerLocation) => {
            return (
              <Stack spacing={1}>
                <Typography></Typography>
                <Typography>{partnerLocation.type}</Typography>
                <Typography>
                  {`${t('PARTNER_OVERVIEW.LOCATION_COMPANY')} ${
                    partnerLocation.company
                  }`}
                </Typography>

                <Fragment>
                  <Typography>
                    {`Anzahl Kundenaufträge: ${partnerLocation.customerOrderAmount}`}
                  </Typography>
                  {partnerLocation.latestCustomerOrder ? (
                    <Typography>
                      {`Letzter Kundenauftrag: ${moment(
                        partnerLocation.latestCustomerOrder,
                      ).format('DD.MM.YYYY')}`}
                    </Typography>
                  ) : (
                    <Typography>
                      {' '}
                      {`${t('PARTNER_OVERVIEW.NO_CUSTOMER_ORDERS_SO_FAR')}`}
                    </Typography>
                  )}
                </Fragment>
                {partnerLocation.partner.type === 'manufacturer' && (
                  <Fragment>
                    <Typography>
                      {`Anzahl Herstelleraufträge: ${partnerLocation.manufacturerOrderAmount}`}
                    </Typography>
                    {partnerLocation.latestManufacturerOrder ? (
                      <Typography>
                        {`Letzter Herstellerauftrag: ${moment(
                          partnerLocation.latestManufacturerOrder,
                        ).format('DD.MM.YYYY')}`}
                      </Typography>
                    ) : (
                      <Typography>{`${t(
                        'PARTNER_OVERVIEW.NO_MANUFACTURER_ORDERS_SO_FAR',
                      )}`}</Typography>
                    )}
                  </Fragment>
                )}
              </Stack>
            )
          }}
          actionsRenderFunction={(partnerLocation) => {
            return []
          }}
          dataIdentifier={'id'}
        />
      </Fragment>
    </Fragment>
  )
}

export default withTranslation()(ParnerOverviewMap)
