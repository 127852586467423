export default function validate(password) {
  var minMaxLength = /^[\s\S]{8,32}$/,
    upper = /[A-Z]/,
    lower = /[a-z]/,
    number = /[0-9]/,
    count = 0

  if (minMaxLength.test(password)) {
    // Only need 3 out of 4 of these to match
    if (upper.test(password)) count++
    if (lower.test(password)) count++
    if (number.test(password)) count++
  }

  return count === 3
}
