import { styled } from '@mui/material/styles';
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import i18n from '../../../../../../../../i18n'
import TenderOrderNegotiationForm from './_components/TenderOrderNegotiationForm'

import { Grid, Step, StepConnector, Stepper } from '@mui/material'

import {
  ArchiveOutlined as ArchivedIcon,
  PeopleOutlined as CustomerIcon,
  BusinessOutlined as ManufacturerIcon,
  Archive as SelectedArchivedIcon,
  People as SelectedCustomerIcon,
  Business as SelectedManufacturerIcon,
} from '@mui/icons-material'

const PREFIX = 'index';

const classes = {
  alternativeLabel: `${PREFIX}-alternativeLabel`,
  active: `${PREFIX}-active`,
  completed: `${PREFIX}-completed`,
  disabled: `${PREFIX}-disabled`,
  line: `${PREFIX}-line`
};

const Root = styled('div')({
  [`& .${classes.alternativeLabel}`]: {
    top: 16,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
  },
  [`& .${classes.active}`]: {
    '& $line': {
      borderColor: '#94c11f',
    },
  },
  [`& .${classes.completed}`]: {
    '& $line': {
      borderColor: '#94c11f',
    },
  },
  [`& .${classes.disabled}`]: {
    '& $line': {
      borderColor: '#94c11f',
    },
  },
  [`& .${classes.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
});

const QontoConnector = StepConnector

const TenderOrderRequestHistory = (props) => {
  const {
    tenderOrder,
    tenderOrderRequest,
    t,
    removeTenderOrderRequest,
    addTenderOrderRequest,
  } = props

  const [activeStep, setActiveStep] = React.useState(0)
  const [isEditing, setIsEditing] = React.useState(false)
  const [counterTenderOrderRequest, setCounterTenderOrderRequest] =
    React.useState(null)

  return (
    <Root>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<QontoConnector
          classes={{
            alternativeLabel: classes.alternativeLabel,
            active: classes.active,
            completed: classes.completed,
            disabled: classes.disabled,
            line: classes.line
          }} />}
      >
        {tenderOrderRequest.descendents.map(
          (tenderOrderRequestChild, index) => (
            <Step
              key={tenderOrderRequestChild.id}
              onClick={(e) => {
                setIsEditing(false)
                setActiveStep(index)
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  style={{
                    textAlign: 'center',
                    height: '35px',
                    lineHeight: '35px',
                  }}
                >
                  {tenderOrderRequestChild.state ===
                  'WAITING_FOR_MANUFACTURER' ? (
                    index === activeStep ? (
                      <SelectedManufacturerIcon
                        fontSize="large"
                        color="default"
                      />
                    ) : (
                      <ManufacturerIcon color="default" />
                    )
                  ) : tenderOrderRequestChild.state ===
                    'WAITING_FOR_CUSTOMER' ? (
                    index === activeStep ? (
                      <SelectedCustomerIcon fontSize="large" color="default" />
                    ) : (
                      <CustomerIcon color="default" />
                    )
                  ) : tenderOrderRequestChild.state === 'ARCHIVED' ? (
                    index === activeStep ? (
                      <SelectedArchivedIcon fontSize="large" color="default" />
                    ) : (
                      <ArchivedIcon color="default" />
                    )
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={
                    index === activeStep
                      ? { textAlign: 'center' }
                      : { textAlign: 'center', color: '#BDBDBD' }
                  }
                >
                  <span>{t('OFFERS.OFFER')}</span> (#
                  {tenderOrderRequestChild.hierarchyLevel}{' '}
                  {i18n.t(
                    'manual:CONTRACT_NEGOTATION_STATES.' +
                      tenderOrderRequestChild.state,
                  )}
                  )
                </Grid>
              </Grid>
            </Step>
          ),
        )}
      </Stepper>
      {
        <Fragment>
          <TenderOrderNegotiationForm
            isEditing={isEditing}
            isNewestTenderOrderRequest={
              tenderOrderRequest.descendents[activeStep].hierarchyLevel ===
              tenderOrderRequest.descendents.length
            }
            addTenderOrderRequest={(tenderOrderRequest) => {
              setIsEditing(false)
              addTenderOrderRequest(tenderOrderRequest)
            }}
            removeTenderOrderRequest={removeTenderOrderRequest}
            startCreateCounterTenderOrderRequest={() => {
              setCounterTenderOrderRequest(
                tenderOrderRequest.descendents[activeStep],
              )
              setIsEditing(true)
            }}
            cancelCreateCounterTenderOrderRequest={() => {
              setCounterTenderOrderRequest(null)
              setIsEditing(false)
            }}
            counterTenderOrderRequest={counterTenderOrderRequest}
            compareTenderOrderRequest={
              tenderOrderRequest.descendents[activeStep + 1]
                ? tenderOrderRequest.descendents[activeStep + 1]
                : null
            }
            tenderOrder={tenderOrder}
            tenderOrderRequest={tenderOrderRequest.descendents[activeStep]}
            key={tenderOrderRequest.descendents[activeStep].id}
          />
        </Fragment>
      }
    </Root>
  );
}

export default withTranslation()(TenderOrderRequestHistory)
