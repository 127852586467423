import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { rolesLookup } from '../../../../../../../../_constants/lookupConstants'
import { rolesService } from '../../../../../../../../_services/rolesService'
import { userRolesService } from '../../../../../../../../_services/userRolesService'
import { Loading } from '../../../../../../../Loading'

function not(a, b) {
  return a.filter((role) => b.indexOf(role) === -1)
}

function intersection(a, b) {
  return a.filter((role) => b.indexOf(role) !== -1)
}

const UserRoles = (props) => {
  const { t, user, forceUpdate } = props

  const [isLoading, setIsLoading] = useState(false)

  const [checked, setChecked] = useState([])
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (role) => () => {
    const currentIndex = checked.indexOf(role)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(role)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = async () => {
    const selectedRoles = right.concat(left)

    setRight(selectedRoles)
    setLeft([])

    updateUserRoles(selectedRoles)
  }

  const handleCheckedRight = () => {
    const selectedRoles = right.concat(leftChecked)

    setRight(selectedRoles)
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))

    updateUserRoles(selectedRoles)
  }

  const handleCheckedLeft = () => {
    const selectedRoles = not(right, rightChecked)

    setLeft(left.concat(rightChecked))
    setRight(selectedRoles)
    setChecked(not(checked, rightChecked))

    updateUserRoles(selectedRoles)
  }

  const handleAllLeft = async () => {
    const selectedRoles = []
    setLeft(left.concat(right))
    setRight(selectedRoles)

    updateUserRoles(selectedRoles)
  }

  const updateUserRoles = async (roles) => {
    setIsLoading(true)
    try {
      await userRolesService.updateUserRoles(
        user.id,
        roles.map((role) => role.id),
      )
      forceUpdate()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!user) {
      return
    }

    let unassignedRoles = []
    let assignedRoles = []

    const fetchUserRoles = async () => {
      try {
        setIsLoading(true)
        const roles = await rolesService.getRoles()

        roles.forEach((role) => {
          const matchingRole = user.roles.find(
            (roleOfUser) => roleOfUser.id === role.id,
          )

          if (matchingRole) {
            assignedRoles.push(role)
          } else {
            unassignedRoles.push(role)
          }
        })

        setLeft(unassignedRoles)
        setRight(assignedRoles)
      } catch (e) {
        console.log(e)
      } finally {
        setIsLoading(false)
      }
    }

    fetchUserRoles()
  }, [user])

  const customList = (items, header) => (
    <Paper sx={{ minHeight: 250, overflow: 'auto' }}>
      <List
        dense
        component="div"
        role="list"
        subheader={<ListSubheader>{header}</ListSubheader>}
      >
        {items.map((role) => {
          const labelId = `transfer-list-item-${role}-label`
          return (
            <ListItem
              key={role}
              role="listitem"
              button
              onClick={handleToggle(role)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(role) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={rolesLookup[role.id]} />
            </ListItem>
          )
        })}
      </List>
    </Paper>
  )

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Grid container spacing={2} justifyContent="center" alignItems="stretch">
        <Grid item xs={12} md={5}>
          {customList(left, t('USERS.UNASSIGNED_ROLES'))}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          {customList(right, t('USERS.ASSIGNED_ROLES'))}
        </Grid>
      </Grid>
    )
  )
}

export default withTranslation()(UserRoles)
