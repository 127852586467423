import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const documentsService = {
  getDocumentsOfPartner,
  getDocument,
  deleteDocument,
  createDocumentOfPartner,
}

function getDocumentsOfPartner(partnerId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/documents`,
    requestOptions,
  ).then(handleResponse)
}

function getDocument(document) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }
  return fetch(`${config.apiUrlBase}/documents/` + document.id, requestOptions)
}

function deleteDocument(document) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }
  return fetch(
    `${config.apiUrlBase}/documents/` + document.id,
    requestOptions,
  ).then(function (response) {
    if (response.ok) {
      return true
    } else {
      return false
    }
  })
}

function createDocumentOfPartner(partnerId, requestBody) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: requestBody,
  }
  return fetch(
    `${config.apiUrlBase}/partners/${partnerId}/documents`,
    requestOptions,
  ).then(handleResponse)
}
export * from './documentsService'
