import { Add, Archive, CopyAll, Edit } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  automaticOfferPositionStateLookup,
  countryLookup,
} from '../../../../_constants/lookupConstants'
import { automaticOfferPositionsService } from '../../../../_services/automaticOfferPositionsService'
import { offerPositionsService } from '../../../../_services/offerPositionsService'
import { offersService } from '../../../../_services/offersService'
import { partnerLocationsService } from '../../../../_services/partnerLocationsService'
import AgreementDialog from '../../../AgreementDialog'
import { Loading } from '../../../Loading'
import { PositionsList } from '../../../PositionsList'
import ResourceListLegacy from '../../../ResourceListLegacy/_components/ResourceListLegacy'
import AutomaticOfferPosition from './_components/AutomaticOfferPosition'
import ComposeAutomaticOfferPositionDialog from './_components/ComposeAutomaticOfferPositionDialog'

const AutomaticOffers = (props) => {
  const { t } = props

  const [isLoading, setIsLoading] = useState(false)
  const [
    showComposeAutomaticOfferPositionDialog,
    setShowComposeAutomaticOfferPositionDialog,
  ] = useState(false)
  const [partnerLocations, setPartnerLocations] = useState([])
  const [selectedPartnerLocation, setSelectedPartnerLocation] = useState(null)
  const [selectedAutomaticOfferPosition, setSelectedAutomaticOfferPosition] =
    useState(null)
  const [automaticOfferPositions, setAutomaticOfferPositions] = useState([])
  const [forceUpdateCount, forceUpdate] = useState(0)
  const [mode, setMode] = useState(null)
  const [showAgreementDialog, setShowAgreementDialog] = useState(false)

  const handleDeclineAllOffersClick = (automaticOfferPosition) => {
    setSelectedAutomaticOfferPosition(automaticOfferPosition)
    setShowAgreementDialog(true)
  }
  const handleAgree = async () => {
    setIsLoading(true)
    try {
      const offerPositions =
        await offerPositionsService.getOfferPositionsOfAutomaticOfferPosition(
          selectedAutomaticOfferPosition.id,
          mappedFilterValues,
          offset,
          limit,
        )

      for (const offerPosition of offerPositions.offerPositions) {
        await offersService.declineOffer(
          offerPosition.offerId,
          userObject.partner.id,
        )
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
      forceUpdate(forceUpdateCount + 1)
    }
  }
  var userObject = JSON.parse(localStorage.getItem('user'))
  const mappedFilterValues = {
    'offer.state': ['ACTIVE'],
  }
  const offset = 0
  const limit = Number.MAX_SAFE_INTEGER

  useEffect(() => {
    setIsLoading(true)
    partnerLocationsService
      .getPartnerLocationsOfUser(userObject.user.id)
      .then((data) => {
        setPartnerLocations(data)
        setIsLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!selectedPartnerLocation) {
      return
    }

    setIsLoading(true)

    automaticOfferPositionsService
      .getAutomaticOfferPositionsOfPartnerLocation(selectedPartnerLocation.id)
      .then((data) => {
        setAutomaticOfferPositions(data)
        setIsLoading(false)
      })
  }, [selectedPartnerLocation, forceUpdateCount])

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Fragment>
        <ResourceListLegacy
          selectionText={
            selectedPartnerLocation
              ? t('AUTOMATIC_OFFERS.SELECT_A_LOCATION')
              : t('AUTOMATIC_OFFERS.SELECT_AN_AUTOMATIC_OFFER_POSITION')
          }
          toolbarSecondary={
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="selected-user-label">
                {t('AUTOMATIC_OFFERS.SELECTED_PARTNER_LOCATION')}
              </InputLabel>
              <Select
                labelId={'selected-user-label'}
                label={t('AUTOMATIC_OFFERS.SELECTED_PARTNER_LOCATION')}
                value={selectedPartnerLocation}
                onChange={(e) => {
                  setSelectedPartnerLocation(e.target.value)
                }}
              >
                {partnerLocations.map((partnerLocation) => (
                  <MenuItem value={partnerLocation}>{`${
                    partnerLocation.company
                  } ${partnerLocation.zipcode} ${partnerLocation.place} ${
                    partnerLocation.address
                  } ${countryLookup[partnerLocation.countryId]}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
          toolbar={
            selectedPartnerLocation ? (
              <Button
                size="medium"
                color={'secondary'}
                variant={'contained'}
                startIcon={<Add />}
                onClick={() => {
                  setSelectedAutomaticOfferPosition(null)
                  setMode(null)
                  setShowComposeAutomaticOfferPositionDialog(true)
                }}
              >
                {t('AUTOMATIC_OFFERS.CREATE_AUTOMATIC_OFFERS_POSITION')}
              </Button>
            ) : null
          }
          sort={(a, b) => {
            return a.id < b.id ? 1 : -1
          }}
          listHeaderRenderFunction={(automaticOfferPosition) =>
            `${t('AUTOMATIC_OFFERS.OFFER_SERVICE_ID')} ${
              automaticOfferPosition.id
            } | ${t('AUTOMATIC_OFFERS.VALID_UNTIL')} ${moment(
              automaticOfferPosition.validUntil,
            ).format('DD.MM.YYYY')}`
          }
          headerActionRenderFunction={(automaticOfferPosition) => (
            <Chip
              size={'small'}
              color={
                automaticOfferPosition.state === 'ARCHIVED' ? 'default' : 'info'
              }
              label={
                automaticOfferPositionStateLookup[automaticOfferPosition.state]
              }
            />
          )}
          contentRenderFunction={(automaticOfferPosition) => (
            <>
              {
                <Typography>
                  {automaticOfferPosition.type === 'DISTANCE_BASED'
                    ? t('AUTOMATIC_OFFERS.DISTANCE_BASED')
                    : t('AUTOMATIC_OFFERS.PRICE_BASED')}
                </Typography>
              }
              <PositionsList
                positions={[
                  {
                    ...automaticOfferPosition,
                    amount: automaticOfferPosition.maxAmountEachCalendarWeek,
                  },
                ]}
              />
            </>
          )}
          resourceRenderFunction={(automaticOfferPosition) => (
            <AutomaticOfferPosition
              automaticOfferPositionId={automaticOfferPosition.id}
            />
          )}
          actionsRenderFunction={(automaticOfferPosition) => {
            return [
              {
                icon: <CloseIcon />,
                name: t('AUTOMATIC_OFFERS.DECLINE_ALL_OFFERS'),
                onClick: () =>
                  handleDeclineAllOffersClick(automaticOfferPosition),
              },
              {
                icon: <Edit />,
                name: t('AUTOMATIC_OFFERS.EDIT'),
                primary: true,
                onClick: () => {
                  setSelectedAutomaticOfferPosition(automaticOfferPosition)
                  setMode('EDIT')
                  setShowComposeAutomaticOfferPositionDialog(true)
                },
              },
              {
                icon: <CopyAll />,
                name: t('AUTOMATIC_OFFERS.COPY'),
                onClick: () => {
                  setSelectedAutomaticOfferPosition(automaticOfferPosition)
                  setMode('COPY')
                  setShowComposeAutomaticOfferPositionDialog(true)
                },
              },
              automaticOfferPosition.state === 'ACTIVE'
                ? {
                    icon: <Archive />,
                    name: t('AUTOMATIC_OFFERS.ARCHIVE'),
                    primary: false,
                    onClick: async () => {
                      await automaticOfferPositionsService.updateAutomaticOfferPosition(
                        { state: 'ARCHIVED' },
                        automaticOfferPosition.id,
                      )
                      forceUpdate(forceUpdateCount + 1)
                    },
                  }
                : {
                    icon: <Archive />,
                    name: t('AUTOMATIC_OFFERS.ACTIVATE'),
                    primary: false,
                    disabled: moment(
                      automaticOfferPosition.validUntil,
                    ).isBefore(moment()),
                    tooltip: moment(automaticOfferPosition.validUntil).isBefore(
                      moment(),
                    )
                      ? t('AUTOMATIC_OFFERS.VALID_UNTIL_DATE_IS_IN_PAST')
                      : '',
                    onClick: async () => {
                      await automaticOfferPositionsService.updateAutomaticOfferPosition(
                        { state: 'ACTIVE' },
                        automaticOfferPosition.id,
                      )
                      forceUpdate(forceUpdateCount + 1)
                    },
                  },
            ]
          }}
          data={selectedPartnerLocation ? automaticOfferPositions : []}
          dataIdentifier={'id'}
        />
        {showComposeAutomaticOfferPositionDialog && (
          <ComposeAutomaticOfferPositionDialog
            automaticOfferPositionId={
              selectedAutomaticOfferPosition
                ? selectedAutomaticOfferPosition.id
                : null
            }
            partnerLocation={selectedPartnerLocation}
            forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
            hide={() => setShowComposeAutomaticOfferPositionDialog(false)}
            mode={mode}
          />
        )}
        <AgreementDialog
          open={showAgreementDialog}
          message={t('ADMIN.ARE_YOU_SURE')}
          acceptButtonText={t('GENERAL.YES')}
          handleClose={() => {
            setShowAgreementDialog(false)
          }}
          agree={() => {
            setShowAgreementDialog(false)
            handleAgree()
          }}
        />
      </Fragment>
    )
  )
}

export default withTranslation()(AutomaticOffers)
