import { Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { tenderOrdersService } from '../../../../_services/tenderOrdersService'
import DeliveryDetailsMultipleLocationsView from '../../../DeliveryDetailsMultipleLocationsView'
import { Loading } from '../../../Loading'
import OtherDetailsView from '../../../OtherDetailsView'
import { Section } from '../../../Section'
import TenderOrderPositions from '../../../TenderOrderPositions'
import TenderOrderAttachments from './_components/TenderOrderAttachments'
import TenderOrderPaymentDetailsView from './_components/TenderOrderPaymentDetailsView'

const TenderOrderDetails = (props) => {
  const { tenderOrderId } = props
  const [tenderOrder, setTenderOrder] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const userObject = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (tenderOrderId) {
      setIsLoading(true)
      tenderOrdersService
        .getTenderOrder(
          tenderOrderId,
          userObject.partner.id,
          userObject.user.partnerLocationIds,
        )
        .then((data) => {
          setTenderOrder(data)
          setIsLoading(false)
        })
    }
  }, [tenderOrderId])

  if (!tenderOrder) {
    return null
  }

  return (
    (isLoading && <Loading />) || (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Section>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <DeliveryDetailsMultipleLocationsView data={tenderOrder} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TenderOrderPaymentDetailsView
                  data={tenderOrder}
                  parentType={'TENDERORDER'}
                />
                <OtherDetailsView
                  data={tenderOrder.generalInformation.otherDetails}
                  requestType={'TENDERORDER'}
                />
              </Grid>
            </Grid>
          </Section>
        </Grid>
        <Grid item xs={12}>
          <TenderOrderPositions data={tenderOrder} />
        </Grid>
        <Grid item xs={12}>
          <TenderOrderAttachments data={tenderOrder} readOnly={true} />
        </Grid>
      </Grid>
    )
  )
}
export default withTranslation()(TenderOrderDetails)
