import {
  ArchiveOutlined,
  Cancel,
  HourglassEmptyOutlined,
  LocalOfferOutlined,
} from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  Grid,
  Step,
  StepConnector,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { offersService } from '../../_services/offersService'
import i18n from '../../i18n'
import CustomDialogTitle from '../CustomDialogTitle'
import { Loading } from '../Loading'
import Offer from '../Offer'
import OfferView from './_components/OfferView'

const PREFIX = 'index'

const classes = {
  alternativeLabel: `${PREFIX}-alternativeLabel`,
  active: `${PREFIX}-active`,
  completed: `${PREFIX}-completed`,
  disabled: `${PREFIX}-disabled`,
  line: `${PREFIX}-line`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')({
  [`& .${classes.alternativeLabel}`]: {
    top: 16,
    left: 'calc(-50% + 20px)',
    right: 'calc(50% + 20px)',
  },
  [`& .${classes.active}`]: {
    '& $line': {
      borderColor: '#94c11f',
    },
  },
  [`& .${classes.completed}`]: {
    '& $line': {
      borderColor: '#94c11f',
    },
  },
  [`& .${classes.disabled}`]: {
    '& $line': {
      borderColor: '#94c11f',
    },
  },
  [`& .${classes.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})

const QontoConnector = StepConnector

const OfferStepper = (props) => {
  const {
    forceUpdate,
    t,
    cancelOffering,
    mode,
    rootOfferId,
    onLatestOfferChange,
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const [offers, setOffers] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const userObject = JSON.parse(localStorage.getItem('user'))

  const theme = useTheme()
  const isLessThanLg = useMediaQuery(theme.breakpoints.down('lg'))

  useEffect(() => {
    setIsLoading(true)
    offersService
      .getOffersOfOffer(rootOfferId)
      .then((offers) => {
        setOffers(offers)
        setActiveStep(0)
        if (onLatestOfferChange) {
          onLatestOfferChange(
            offers[activeStep].hierarchyLevel === offers.length
              ? offers[activeStep]
              : null,
          )
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [rootOfferId])
  return (
    <Root>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <Stepper
            sx={{ marginTop: 2, marginBottom: 2 }}
            activeStep={activeStep}
            alternativeLabel
            connector={
              <QontoConnector
                classes={{
                  alternativeLabel: classes.alternativeLabel,
                  active: classes.active,
                  completed: classes.completed,
                  disabled: classes.disabled,
                  line: classes.line,
                }}
              />
            }
          >
            {offers.map((offer, index) => (
              <Step
                style={{ alignSelf: 'center', height: '71px' }}
                key={offer.id}
                onClick={(e) => {
                  setActiveStep(index)
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      textAlign: 'center',
                      height: '35px',
                      lineHeight: '35px',
                    }}
                  >
                    {offer.state === 'CANCLED' ? (
                      <Cancel
                        fontSize="large"
                        color={index === activeStep ? 'error' : 'disabled'}
                      />
                    ) : null}
                    {offer.state === 'EXPIRED' ? (
                      <HourglassEmptyOutlined
                        fontSize="large"
                        color={index === activeStep ? 'secondary' : 'disabled'}
                      />
                    ) : null}
                    {offer.state === 'ARCHIVED' ? (
                      <ArchiveOutlined
                        fontSize="large"
                        color={index === activeStep ? 'secondary' : 'disabled'}
                      />
                    ) : null}
                    {offer.state === 'ACTIVE' ? (
                      <LocalOfferOutlined
                        fontSize="large"
                        color={index === activeStep ? 'primary' : 'disabled'}
                      />
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={
                      index === activeStep
                        ? { textAlign: 'center' }
                        : { textAlign: 'center', color: '#BDBDBD' }
                    }
                  >
                    {offer.partnerId === userObject.partner.id ? (
                      <span>{t('OFFERS.MY_OFFER')}</span>
                    ) : (
                      <span>{t('OFFERS.OFFER')}</span>
                    )}{' '}
                    (#{offer.id} {i18n.t('OFFER_STATES.' + offer.state)})
                  </Grid>
                </Grid>
              </Step>
            ))}
          </Stepper>
          {offers.length > 0 && (
            <OfferView
              offerId={offers[activeStep].id}
              key={offers[activeStep].id}
              isNewestOffer={
                offers[activeStep].hierarchyLevel === offers.length
              }
              forceUpdate={forceUpdate}
            />
          )}
          {offers.length > 0 && (
            <Dialog
              fullWidth={true}
              maxWidth="xl"
              open={['EDIT', 'COUNTER'].includes(mode)}
              fullScreen={isLessThanLg}
            >
              <CustomDialogTitle
                title={
                  mode === 'COUNTER'
                    ? t('MARKETPLACE.CREATE_OFFER')
                    : t('OFFERS.EDIT_OFFER')
                }
                onClose={() => cancelOffering()}
              />
              <DialogContent>
                <Offer
                  offerId={offers[activeStep].id}
                  key={offers[activeStep].id}
                  forceUpdate={forceUpdate}
                  mode={mode}
                  cancelOffering={() => {
                    cancelOffering()
                  }}
                />
              </DialogContent>
            </Dialog>
          )}
        </Fragment>
      )}
    </Root>
  )
}

export default withTranslation()(OfferStepper)
