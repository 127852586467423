import { AddBox, Search } from '@mui/icons-material'
import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField as MuiTextField,
} from '@mui/material'
import { Field, Form, Formik, yupToFormErrors } from 'formik'
import { Autocomplete, TextField } from 'formik-mui'
import _ from 'lodash'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { alertActions } from '../../../../../../_actions/alertActions'
import { addUniqueElementToArray } from '../../../../../../_helpers/little'
import { ordersService } from '../../../../../../_services/ordersService'
import { partnersService } from '../../../../../../_services/partnersService'
import { tenderOrdersService } from '../../../../../../_services/tenderOrdersService'
import { usersService } from '../../../../../../_services/usersService'
import Deliveries from '../../../../../Deliveries'
import DeliveryDetails from '../../../../../DeliveryDetails'
import DeliveryDetailsReplacedComponentsDialog from '../../../../../DeliveryDetailsReplacedComponentsDialog'
import DeliveryDetailsView from '../../../../../DeliveryDetailsView'
import InvoiceAddress from '../../../../../InvoiceAddress'
import OrderRegistrationPositions from '../../../../../OrderRegistrationPositions'
import PaymentDetails from '../../../../../PaymentDetails'
import PaymentDetailsView from '../../../../../PaymentDetailsView'
import RegisterPartnerDialog from '../../../../../RegisterPartnerDialog'
import { Section } from '../../../../../Section'
import OrderRegistrationPositionsTender from './_components/OrderRegistrationPositionsTender'
import SelectOrderDialog from './_components/SelectOrderDialog'

const OrderRegistration = (props) => {
  const { t, dispatch } = props

  const userObject = JSON.parse(localStorage.getItem('user'))

  const [customers, setCustomers] = useState([])
  const [customerUsers, setCustomerUsers] = useState([])
  const [selectedCustomerId, setSelectedCustomerId] = useState(
    userObject.partner.id,
  )
  const [manufacturers, setManufacturers] = useState([])
  const [manufacturerUsers, setManufacturerUsers] = useState([])
  const [selectedManufacturerId, setSelectedManufacturerId] = useState()
  const [tenderOrders, setTenderOrders] = useState([])
  const [selectedTenderOrder, setSelectedTenderOrder] = useState()
  const [selectedTenderOrderLocationId, setSelectedTenderOrderLocationId] =
    useState()
  const [registerPartnerOpen, setRegisterPartnerOpen] = React.useState(false)
  const [selectOrderOpen, setSelectOrderOpen] = useState(false)

  const [showHasReplacedComponentsDialog, setShowHasReplacedComponentsDialog] =
    useState(false)
  const [externalAdressValidationResult, setExternalAdressValidationResult] =
    useState(false)

  const [geoData, setGeoData] = useState(null)

  useEffect(() => {
    partnersService.getPartners().then((data) => {
      setCustomers(
        data.map((partner) => ({
          id: partner.id,
          name: partner.name,
          place: partner.place,
        })),
      )
      setManufacturers(
        data.map((partner) => ({
          id: partner.id,
          name: partner.name,
          place: partner.place,
        })),
      )
    })
  }, [registerPartnerOpen])

  useEffect(() => {
    if (selectedCustomerId) {
      usersService.getUsersOfPartner(selectedCustomerId).then((data) => {
        setCustomerUsers(
          data.map((user) => ({
            id: user.id,
            name: user.email,
          })),
        )
      })
    }
  }, [selectedCustomerId])

  useEffect(() => {
    if (selectedManufacturerId) {
      usersService.getUsersOfPartner(selectedManufacturerId).then((data) => {
        setManufacturerUsers(
          data.map((user) => ({
            id: user.id,
            name: user.email,
          })),
        )
      })
    }
  }, [selectedManufacturerId])

  useEffect(() => {
    if (selectedCustomerId && selectedManufacturerId) {
      tenderOrdersService
        .getTenderOrdersOfPartner(
          selectedCustomerId,
          {},
          {
            limit: Number.MAX_SAFE_INTEGER,
            offset: 0,
            sortBy: 'id',
          },
          userObject.user.partnerLocationIds,
        )
        .then((tenderOrders) => {
          setTenderOrders(
            tenderOrders.data
              .filter(
                (to) =>
                  parseInt(to.customerId) === parseInt(selectedCustomerId) &&
                  parseInt(to.manufacturerId) ===
                    parseInt(selectedManufacturerId),
              )
              .map((to) => to.id),
          )
        })
    }
  }, [selectedCustomerId, selectedManufacturerId])

  const initialValues = {
    type: 'SERVICE',
    state: 'CONFIRMED',
    customerId: selectedCustomerId,
    customerUserId: userObject.user.id,
    manufacturerId: '',
    manufacturerUserId: '',
    tenderOrderId: '',
    orderNumber: '',
    generalInformation: {
      deliveryDetails: {
        incoTerm: 'DDP',
        locations: [
          {
            id: 1,
            company: '',
            address: '',
            zipcode: '',
            place: '',
            countryId: 80,
            deliveryInformations: '',
            positions: [],
            unload: 'ANY',
            stackHeight: null,
            stackHeightUnit: 'QUANTITY',
            deliveryTimes: [
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: true,
                start: 8,
                end: 16,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
              {
                isActive: false,
                start: null,
                end: null,
              },
            ],
          },
        ],
      },
      paymentDetails: {
        paymentTerm: 14,
        skonto: 0,
        skontoPeriod: null,
      },
    },
    positions: [],
    deliveries: [],
  }

  var valSchema = Yup.object().shape({
    customerId: Yup.string().required(t('GENERAL.REQUIRED')),
    customerUserId: Yup.string().required(t('GENERAL.REQUIRED')),
    manufacturerId: Yup.string().nullable(),
    manufacturerUserId: Yup.string().when(
      'manufacturerId',
      ([manufacturerId], schema) => {
        return manufacturerId
          ? Yup.string().required(t('GENERAL.REQUIRED'))
          : Yup.string().nullable()
      },
    ),
    partnerInvoiceLocationId: Yup.number().required(t('GENERAL.REQUIRED')),
    deliveries: Yup.array()
      .of(
        Yup.object()
          .shape({
            type: Yup.string().required(t('GENERAL.REQUIRED')),
            value: Yup.string().required(t('GENERAL.REQUIRED')),
            obligatory: Yup.boolean(),
            positions: Yup.array()
              .of(
                Yup.object().shape({
                  amount: Yup.number(),
                }),
              )
              .compact(function (v) {
                return v.amount === ''
              })
              .min(1, t('GENERAL.MIN_1')),
          })
          .test({
            message: t('REQUEST.ADD_DELIVERY_WIZARD.POS_AMOUNT_EXCEEDED'),
            test: function (delivery) {
              let result = true
              delivery.positions.forEach((dPos) => {
                const { context } = this.options

                // if order is placed against tender order skip this validation
                if (context.tenderOrderId) {
                  return true
                }

                var positionsAmount = parseFloat(
                  context.generalInformation.deliveryDetails.locations[0].positions.find(
                    (pos) => {
                      return pos.id === dPos.positionId
                    },
                  ).amount,
                )
                var otherDeliveriesAmount = 0
                context.deliveries.forEach((otherDelivery) => {
                  if (delivery.id === otherDelivery.id) {
                    return
                  }
                  otherDelivery.positions.forEach((dPos2) => {
                    if (dPos2.positionId === dPos.positionId) {
                      otherDeliveriesAmount += parseFloat(dPos2.amount)
                    }
                  })
                })
                result =
                  result &&
                  dPos.amount <=
                    positionsAmount - parseFloat(otherDeliveriesAmount)
              })

              return result
            },
          }),
      )
      .min(1, t('GENERAL.MIN_1')),
  })

  const valSchemaOrder = {
    generalInformation: Yup.object().shape({
      deliveryDetails: Yup.object().shape({
        incoTerm: Yup.string().required(t('GENERAL.REQUIRED')),
        locations: Yup.array()
          .of(
            Yup.object().shape({
              company: Yup.string().required(t('GENERAL.REQUIRED')),
              address: Yup.string().required(t('GENERAL.REQUIRED')),
              zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
              place: Yup.string().required(t('GENERAL.REQUIRED')),
              countryId: Yup.number().required(t('GENERAL.REQUIRED')),
              deliveryInformations: Yup.string().nullable(),
              positions: Yup.array()
                .of(
                  Yup.object().shape({
                    id: Yup.number().required(t('GENERAL.REQUIRED')),
                    productId: Yup.string().required(t('GENERAL.REQUIRED')),
                    productUnitId: Yup.number().required(t('GENERAL.REQUIRED')),
                    salesPrice: Yup.number()
                      .min(0.01, t('GENERAL.REQUIRED'))
                      .required(t('GENERAL.REQUIRED')),
                    purchasePrice: Yup.number()
                      .min(0.01, t('GENERAL.REQUIRED'))
                      .required(t('GENERAL.REQUIRED')),
                    amount: Yup.number()
                      .required(t('GENERAL.REQUIRED'))
                      .typeError(t('GENERAL.REQUIRED'))
                      .min(0.01, t('GENERAL.GREATER_THAN_0'))
                      .test({
                        message: t('GENERAL.TOO_MUCH'),
                        test: function (v) {
                          if (
                            this.parent.productUnitId === 3 &&
                            parseFloat(v) > 200
                          ) {
                            return false
                          } else {
                            return true
                          }
                        },
                      }),
                  }),
                )
                .min(1, t('GENERAL.MIN_1')),
            }),
            //Temporarily disabled address validation in OrderRegistration

            // .test({
            //   test: async function (location, context) {
            //     return await validateLocation(
            //       location,
            //       context,
            //       `generalInformation.deliveryDetails.locations[${context.parent.findIndex(
            //         (locationInValues) => locationInValues.id === location.id,
            //       )}]`,
            //       setExternalAdressValidationResult,
            //       setShowHasReplacedComponentsDialog,
            //       (validationResult) => {
            //         setGeoData(validationResult.geocode.location)
            //       },
            //       this,
            //       ['route', 'street_number'],
            //     )
            //   },
            // }),
          )
          .min(1, t('GENERAL.MIN_1')),
      }),
      paymentDetails: Yup.object().shape({
        paymentTerm: Yup.number()
          .min(0, t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        skonto: Yup.number()
          .min(0, t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
      }),
    }),
  }

  return (
    customers.length > 0 &&
    manufacturers.length > 0 && (
      <Fragment>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={false}
          validate={(values) => {
            var schema = valSchema
            if (!values.tenderOrderId) {
              schema = schema.shape(valSchemaOrder)
            }
            return schema
              .validate(values, { abortEarly: false, context: values })
              .then(function (value) {})
              .catch((err) => {
                console.log(err.stack)
                return yupToFormErrors(err)
              })
          }}
          onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
            let requestBody = {
              ...values,
              latitude: geoData ? geoData.latitude : undefined,
              longitude: geoData ? geoData.longitude : undefined,
            }

            ordersService
              .createOrders(requestBody)
              .then((data) => {
                setSubmitting(false)
                resetForm(initialValues)
                setSelectedCustomerId()
                setSelectedManufacturerId()
                setSelectedTenderOrder()
                setSelectedTenderOrderLocationId()
                dispatch(
                  alertActions.info(t('ORDER_REGISTRATION.PUBLISH_SUCCESSFUL')),
                )
                setTimeout(() => {
                  dispatch(alertActions.clear())
                }, alertActions.alertTimeout)
              })
              .catch(function (error) {
                setSubmitting(false)
                console.log(error)
                dispatch(alertActions.error(error))
                setTimeout(() => {
                  dispatch(alertActions.clear())
                }, alertActions.alertTimeout)
              })
          }}
        >
          {({
            isSubmitting,
            values,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            errors,
            handleBlur,
            touched,
            setValues,
          }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Section header={t('REQUEST.GENERAL_INFORMATION')}>
                    <Grid container spacing={1}>
                      <Grid container spacing={1} item xs={12}>
                        <Grid
                          container
                          item
                          xs={12}
                          sm={3}
                          justifyContent={'flex-end'}
                        >
                          <Grid item xs>
                            <Field
                              name="customerId"
                              component={Autocomplete}
                              options={customers.map((customer) => customer.id)}
                              getOptionLabel={(option) => {
                                if (!option) {
                                  return ''
                                }

                                let partner = customers.find(
                                  (customer) => customer.id === option,
                                )

                                return `${partner.name} (${partner.id}) - ${partner.place}`
                              }}
                              onChange={(event, value) => {
                                setFieldValue('customerId', value)
                                setSelectedCustomerId(value)
                              }}
                              disabled={true}
                              renderInput={(params) => (
                                <MuiTextField
                                  {...params}
                                  // We have to manually set the corresponding fields on the input component
                                  name="customerId"
                                  error={
                                    touched['customerId'] &&
                                    !!errors['customerId']
                                  }
                                  helperText={errors['customerId']}
                                  label={t('ORDER_REGISTRATION.CUSTOMER')}
                                  variant="outlined"
                                  margin={'dense'}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ alignSelf: 'center', paddingLeft: '5px' }}
                          >
                            <IconButton
                              size={'small'}
                              onClick={(e) => {
                                setRegisterPartnerOpen(true)
                              }}
                            >
                              <AddBox />
                            </IconButton>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{ alignSelf: 'center', paddingLeft: '5px' }}
                          >
                            <IconButton
                              size={'small'}
                              disabled={!selectedCustomerId}
                              onClick={(e) => {
                                setSelectOrderOpen(true)
                              }}
                            >
                              <Search />
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Field
                            component={TextField}
                            label={t('ORDER_REGISTRATION.CUSTOMER_USER')}
                            disabled={true}
                            name={'customerUserId'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            select
                          >
                            {customerUsers.map((customerUser) => (
                              <MenuItem
                                value={customerUser.id}
                                key={customerUser.id}
                              >
                                {customerUser.name}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Field
                            name="manufacturerId"
                            component={Autocomplete}
                            options={manufacturers.map(
                              (manufacturer) => manufacturer.id,
                            )}
                            getOptionLabel={(option) => {
                              if (!option) {
                                return ''
                              }

                              let partner = manufacturers.find(
                                (manufacturer) => manufacturer.id === option,
                              )

                              return `${partner.name} (${partner.id}) - ${partner.place}`
                            }}
                            onChange={(event, value) => {
                              setFieldValue('manufacturerId', value)
                              setSelectedManufacturerId(value)
                            }}
                            disabled={values.tenderOrderId}
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                // We have to manually set the corresponding fields on the input component
                                name="manufacturerId"
                                error={
                                  touched['manufacturerId'] &&
                                  !!errors['manufacturerId']
                                }
                                helperText={errors['manufacturerId']}
                                label={t('ORDER_REGISTRATION.MANUFACTURER')}
                                variant="outlined"
                                margin={'dense'}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Field
                            component={TextField}
                            label={t('ORDER_REGISTRATION.MANUFACTURER_USER')}
                            disabled={values.tenderOrderId}
                            name={'manufacturerUserId'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            select
                          >
                            {manufacturerUsers.map((manufacturerUser) => (
                              <MenuItem
                                value={manufacturerUser.id}
                                key={manufacturerUser.id}
                              >
                                {manufacturerUser.name}
                              </MenuItem>
                            ))}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <MuiTextField
                            error={
                              !_.isEmpty(errors.tenderOrderId) &&
                              touched.tenderOrderId === true
                            }
                            select
                            disabled={
                              !values.manufacturerId ||
                              !values.customerId ||
                              !values.manufacturerUserId ||
                              !values.customerUserId
                            }
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            label={t('ORDER_REGISTRATION.TENDER_ORDER')}
                            value={
                              values.tenderOrderId ? values.tenderOrderId : ''
                            }
                            onChange={(e) => {
                              // reset error
                              setFieldError('tenderOrderId')
                              setFieldTouched('tenderOrderId')
                              setFieldValue('tenderOrderId', e.target.value)
                              if (e.target.value === null) {
                                setSelectedTenderOrder(null)
                                setSelectedTenderOrderLocationId(null)
                                setValues({
                                  ...initialValues,
                                  customerId: values.customerId,
                                  customerUserId: values.customerUserId,
                                  manufacturerId: values.manufacturerId,
                                  manufacturerUserId: values.manufacturerUserId,
                                })
                              } else {
                                tenderOrdersService
                                  .getTenderOrder(
                                    e.target.value,
                                    userObject.partner.id,
                                    userObject.user.partnerLocationIds,
                                  )
                                  .then((data) => setSelectedTenderOrder(data))
                              }
                            }}
                            helperText={
                              !_.isEmpty(errors.tenderOrderId) &&
                              touched.tenderOrderId === true
                                ? errors.tenderOrderId
                                : null
                            }
                          >
                            <MenuItem value={null} key={''}>
                              <em>N/A</em>
                            </MenuItem>
                            {tenderOrders.map((to) => (
                              <MenuItem value={to} key={to}>
                                {to}
                              </MenuItem>
                            ))}
                          </MuiTextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <MuiTextField
                            select
                            disabled={
                              !values.manufacturerId ||
                              !values.customerId ||
                              !values.manufacturerUserId ||
                              !values.customerUserId ||
                              !values.tenderOrderId
                            }
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            label={t('ORDER_REGISTRATION.LOCATION')}
                            value={
                              selectedTenderOrderLocationId
                                ? selectedTenderOrderLocationId
                                : ''
                            }
                            onChange={(e) => {
                              setSelectedTenderOrderLocationId(e.target.value)
                              setValues({
                                ...values,
                                generalInformation: {
                                  deliveryDetails: {
                                    locations: [
                                      {
                                        ...selectedTenderOrder.generalInformation.deliveryDetails.locations.find(
                                          (loc) =>
                                            parseInt(loc.id) ===
                                            parseInt(e.target.value),
                                        ),
                                      },
                                    ],
                                  },
                                  paymentDetails: {
                                    paymentTerm:
                                      selectedTenderOrder.generalInformation
                                        .paymentDetails.paymentTerm,
                                    skonto:
                                      selectedTenderOrder.generalInformation
                                        .paymentDetails.skonto,
                                  },
                                },
                                positions: selectedTenderOrder.positions,
                                tenderOrderRequests:
                                  selectedTenderOrder.tenderOrderRequests,
                                orders: selectedTenderOrder.orders,
                              })
                            }}
                          >
                            {selectedTenderOrder ? (
                              selectedTenderOrder.generalInformation.deliveryDetails.locations.map(
                                (loc) => (
                                  <MenuItem value={loc.id} key={loc.id}>
                                    {loc.id}
                                  </MenuItem>
                                ),
                              )
                            ) : (
                              <MenuItem value={null} key={''}>
                                <em>N/A</em>
                              </MenuItem>
                            )}
                          </MuiTextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Field
                            component={TextField}
                            label={t('ORDER_REGISTRATION.ORDER_NUMBER')}
                            name={'orderNumber'}
                            variant="outlined"
                            margin="dense"
                            fullWidth
                          ></Field>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {values.tenderOrderId ? (
                          <DeliveryDetailsView data={values} />
                        ) : (
                          <DeliveryDetails
                            data={values}
                            readOnly={
                              values.state === 'NEGOTIATION' ||
                              values.state === 'ARCHIVED'
                            }
                            isOrderRegistration={true}
                            setFieldValue={setFieldValue}
                            selectedPartnerId={
                              values.generalInformation.deliveryDetails
                                .incoTerm === 'DDP'
                                ? selectedCustomerId
                                : selectedManufacturerId
                            }
                            mode={'create'}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {values.tenderOrderId ? (
                          <PaymentDetailsView data={values} differences={{}} />
                        ) : (
                          <PaymentDetails
                            data={values}
                            setFieldValue={setFieldValue}
                            readOnly={
                              values.state === 'NEGOTIATION' ||
                              values.state === 'ARCHIVED'
                            }
                          />
                        )}
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <InvoiceAddress
                              partnerId={selectedCustomerId}
                              data={values}
                              setFieldValue={setFieldValue}
                              key={selectedCustomerId}
                              userId={values.customerUserId}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Section>
                </Grid>
                <Grid item xs={12}>
                  {values.tenderOrderId ? (
                    <OrderRegistrationPositionsTender
                      data={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  ) : (
                    <OrderRegistrationPositions
                      data={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Deliveries
                    //TODO data.positions so ändern, dass nur die positionen des standorts enthalten sind, aber mit den aggregierten mengen
                    data={{
                      ...values,
                      positions:
                        values.generalInformation.deliveryDetails.locations[0]
                          .positions,
                      // .map(pos => {
                      //     const aPos = values.positions.find(aPos => parseInt(aPos.id) === parseInt(pos.id))
                      //     pos.amount = aPos.amount
                      //     return pos
                      // })
                    }}
                    setFieldValue={setFieldValue}
                    positions={
                      values.generalInformation.deliveryDetails.locations[0]
                        .positions
                    }
                    errors={errors}
                    type={'ORDER_REGISTRATION'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={isSubmitting}
                    style={{ margin: '0 0 15px 15px' }}
                    type={'submit'}
                  >
                    {t('REQUEST.PUBLISH')}
                  </Button>

                  <Button
                    variant="outlined"
                    color="secondary"
                    component={Link}
                    to="/order-registration"
                    style={{ margin: '0 0 15px 15px' }}
                    disabled={isSubmitting}
                  >
                    {t('GENERAL.CANCEL')}
                  </Button>
                </Grid>
              </Grid>
              {selectedCustomerId && (
                <SelectOrderDialog
                  partnerId={selectedCustomerId}
                  open={selectOrderOpen}
                  close={() => setSelectOrderOpen(false)}
                  submit={(order) => {
                    let mappedCustomers = [...customers]
                    let mappedManufacturers = [...manufacturers]

                    addUniqueElementToArray(mappedCustomers, order, 'customer')
                    addUniqueElementToArray(
                      mappedCustomers,
                      order,
                      'manufacturer',
                    )
                    addUniqueElementToArray(
                      mappedManufacturers,
                      order,
                      'customer',
                    )
                    addUniqueElementToArray(
                      mappedManufacturers,
                      order,
                      'manufacturer',
                    )

                    setCustomers(mappedCustomers)
                    setManufacturers(mappedManufacturers)

                    setSelectedManufacturerId(order.manufacturerId)

                    if (order.tenderOrderId) {
                      tenderOrdersService
                        .getTenderOrder(
                          order.tenderOrderId,
                          userObject.partner.id,
                          userObject.user.partnerLocationIds,
                        )
                        .then((data) => {
                          setSelectedTenderOrder(data)
                          setSelectedTenderOrderLocationId(
                            order.positions[0].locationId,
                          )
                          let location =
                            data.generalInformation.deliveryDetails.locations.find(
                              (loc) =>
                                parseInt(loc.id) ===
                                parseInt(order.positions[0].locationId),
                            )

                          setValues({
                            ...order,
                            deliveries: [
                              {
                                id: 1,
                                type: order.deliveryType,
                                value:
                                  order.deliveryType === 'date'
                                    ? moment(order.deliveryValue)
                                    : order.deliveryValue,
                                positions: order.positions.map((pos) => {
                                  return {
                                    ...pos,
                                    positionId: pos.tenderOrderPositionId,
                                  }
                                }),
                              },
                            ],
                            generalInformation: {
                              deliveryDetails: {
                                incoTerm: 'DDP',
                                locations: [
                                  {
                                    ...location,
                                    positions: location.positions.map((pos) => {
                                      return {
                                        ...pos,
                                        id: pos.id,
                                      }
                                    }),
                                  },
                                ],
                              },
                              paymentDetails: {
                                paymentTerm:
                                  data.generalInformation.paymentDetails
                                    .paymentTerm,
                                skonto:
                                  data.generalInformation.paymentDetails.skonto,
                              },
                            },
                            positions: data.positions,
                            tenderOrderRequests: data.tenderOrderRequests,
                            orders: data.orders,
                          })
                        })
                    } else {
                      setValues({
                        ...order,
                        deliveries: [
                          {
                            id: 1,
                            type: order.deliveryType,
                            value:
                              order.deliveryType === 'date'
                                ? moment(order.deliveryValue)
                                : order.deliveryValue,
                            positions: order.positions.map((pos, index) => {
                              return {
                                amount: pos.amount,
                                positionId: index + 1,
                              }
                            }),
                          },
                        ],
                        generalInformation: {
                          paymentDetails: {
                            paymentTerm: order.paymentTerm,
                            skonto: order.skonto,
                          },
                          deliveryDetails: {
                            incoTerm: order.incoTerm,
                            locations: [
                              {
                                company: order.company,
                                address: order.address,
                                zipcode: order.zipcode,
                                countryId: order.countryId,
                                place: order.place,
                                deliveryInformations:
                                  order.deliveryInformations,
                                stackHeight: order.stackHeight,
                                stackHeightUnit: order.stackHeightUnit,
                                deliveryTimes: order.deliveryTimes,
                                unload: order.unload,
                                isDespatchAdviceRequired:
                                  order.isDespatchAdviceRequired,
                                despatchAdviceInstructions:
                                  order.despatchAdviceInstructions,
                                positions: order.positions.map((pos, index) => {
                                  return {
                                    ...pos,
                                    id: index + 1,
                                  }
                                }),
                              },
                            ],
                          },
                        },
                      })
                    }

                    setSelectOrderOpen(false)
                  }}
                />
              )}
              {showHasReplacedComponentsDialog && (
                <DeliveryDetailsReplacedComponentsDialog
                  setAddress={() => {
                    let replacedAddressComponents =
                      externalAdressValidationResult.address.addressComponents.filter(
                        (addressComponent) => addressComponent.replaced,
                      )

                    replacedAddressComponents.forEach((addressComponent) => {
                      switch (addressComponent.componentType) {
                        case 'route':
                        case 'street_number':
                          let adress =
                            externalAdressValidationResult.address.addressComponents.find(
                              (addressComponent) =>
                                addressComponent.componentType === 'route',
                            )
                          let streetNumber =
                            externalAdressValidationResult.address.addressComponents.find(
                              (addressComponent) =>
                                addressComponent.componentType ===
                                'streetNumber',
                            )
                          setFieldValue(
                            `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                              (location) =>
                                location.id ===
                                externalAdressValidationResult.locationId,
                            )}].address`,
                            `${adress} ${streetNumber}`,
                          )
                          break
                        case 'postal_code':
                          setFieldValue(
                            `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                              (location) =>
                                location.id ===
                                externalAdressValidationResult.locationId,
                            )}].zipcode`,
                            addressComponent.componentName.text,
                          )
                          break
                        case 'locality':
                          setFieldValue(
                            `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                              (location) =>
                                location.id ===
                                externalAdressValidationResult.locationId,
                            )}].place`,
                            addressComponent.componentName.text,
                          )
                          break
                        // case 'country':
                        //   break
                        default:
                          break
                      }
                      setFieldValue()
                    })
                  }}
                  addressValidationResult={externalAdressValidationResult}
                  hide={() => setShowHasReplacedComponentsDialog(false)}
                />
              )}
            </Form>
          )}
        </Formik>
        <RegisterPartnerDialog
          open={registerPartnerOpen}
          close={() => setRegisterPartnerOpen(false)}
        />
      </Fragment>
    )
  )
}

export default withTranslation()(connect()(OrderRegistration))
