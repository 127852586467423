import { Add, Cancel } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import React, { Fragment, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { rolesLookup } from '../../../../_constants/lookupConstants'
import { usersService } from '../../../../_services/usersService'
import AgreementDialog from '../../../AgreementDialog'
import { Loading } from '../../../Loading'
import ResourceListLegacy from '../../../ResourceListLegacy/_components/ResourceListLegacy'
import CreateUser from './_components/CreateUser'
import UserDetails from './_components/UserDetails'

const Users = (props) => {
  const { t } = props

  const [selectedUser, setSelectedUser] = React.useState(null)
  const [showDeleteUserDialog, setShowDeleteUserDialog] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [showCreateUserModal, setShowCreateUserModal] = React.useState(false)
  const [users, setUsers] = React.useState([])
  const [forceUpdateCount, forceUpdate] = React.useState(false)

  const userObject = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    setIsLoading(true)
    usersService.getUsersOfPartner(userObject.partner.id).then((users) => {
      setUsers(users)
      setIsLoading(false)
    })
  }, [t, showDeleteUserDialog, forceUpdateCount])

  return (
    <Fragment>
      {isLoading && <Loading variant={'centered'} />}
      <Fragment>
        <ResourceListLegacy
          selectionText={t('USERS.SELECT_AN_USER')}
          toolbar={
            <Button
              size="medium"
              color={'secondary'}
              variant={'contained'}
              startIcon={<Add />}
              onClick={() => {
                setShowCreateUserModal(true)
              }}
            >
              {t('USERS.CREATE_USER')}
            </Button>
          }
          sort={(a, b) => {
            return a.id < b.id ? 1 : -1
          }}
          listHeaderRenderFunction={(user) =>
            `${user.firstname} ${user.lastname}`
          }
          resourceHeaderRenderFunction={(user) =>
            `${user.firstname} ${user.lastname}`
          }
          headerActionRenderFunction={(user) =>
            user.roles.map((role) => (
              <Typography>{rolesLookup[role.id]}</Typography>
            ))
          }
          contentRenderFunction={(user) => `${user.email}`}
          resourceRenderFunction={(user) => (
            <UserDetails
              user={user}
              forceUpdate={() => {
                forceUpdate(forceUpdateCount + 1)
              }}
            />
          )}
          actionsRenderFunction={(user) => {
            return [
              {
                icon: <Cancel />,
                hidden: users.length === 1 || user.id === userObject.user.id,
                name: t('USERS.DELETE_USER'),
                primary: true,
                onClick: () => {
                  setSelectedUser(user)
                  setShowDeleteUserDialog(true)
                },
              },
            ]
          }}
          data={users}
          dataIdentifier={'id'}
        />
        <AgreementDialog
          open={showDeleteUserDialog}
          handleClose={() => {
            setShowDeleteUserDialog(false)
          }}
          acceptButtonText={t('USERS.DELETE_USER')}
          message={t('USERS.DELETE_USER_MESSAGE')}
          agree={() => {
            usersService.deleteUser(selectedUser.id).then(() => {
              setShowDeleteUserDialog(false)
            })
          }}
        />
        <CreateUser
          show={showCreateUserModal}
          hide={() => setShowCreateUserModal(false)}
        />
      </Fragment>
    </Fragment>
  )
}

export default withTranslation()(Users)
