import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material'
import React from 'react'

const CategorizedFilterCheckBoxList = (props) => {
  const { filterHandler, filter, lookup, setFilterHandler } = props
  let index = filterHandler.findIndex((handler) => handler.key === filter.key)
  return (
    <List>
      {filter.values.map((category) => {
        return [
          <ListSubheader disableSticky={true}>{category.label}</ListSubheader>,
          category.items.map((item) => {
            return (
              <ListItem
                key={item}
                value={item}
                dense
                button
                onClick={() => {
                  const currentIndex = filterHandler[index].value.indexOf(item)

                  let newFilterHandler = [...filterHandler]

                  if (currentIndex === -1) {
                    newFilterHandler[index].value.push(item)
                  } else {
                    newFilterHandler[index].value.splice(currentIndex, 1)
                  }

                  setFilterHandler(newFilterHandler)
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={filterHandler[index].value.indexOf(item) !== -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={lookup ? lookup[item] : item} />
              </ListItem>
            )
          }),
        ]
      })}
    </List>
  )
}

export default CategorizedFilterCheckBoxList
