import TranslateIcon from '@mui/icons-material/Translate'
import { Grid, IconButton, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  countryLookup,
  incotermLookup,
  stackHeightUnitLookup,
  unloadLookup,
} from '../../_constants/lookupConstants'
import { _applicationService } from '../../_services/_applicationService'
import { generalService } from '../../_services/generalService'
import DeliveryTimesView from '../DeliveryTimesView'
import DifferenceWrapper from '../DifferenceWrapper'
import { Section } from '../Section'

const DeliveryDetailsView = (props) => {
  const { data, t, differences, diffObjectType, parentType } = props
  const [translatedDeliveryInformations, setTranslatedDeliveryInformations] =
    useState('')
  const [showTranslated, setShowTranslated] = useState(false)
  const toggleTranslation = () => {
    if (!showTranslated) {
      fetchTranslation()
    }
    setShowTranslated(!showTranslated)
  }

  const incoTerm =
    parentType === 'ORDER'
      ? data.incoTerm
      : data.generalInformation.deliveryDetails.incoTerm

  const address =
    parentType === 'ORDER'
      ? data.address
      : data.generalInformation.deliveryDetails.locations[0].address

  const company =
    parentType === 'ORDER'
      ? data.company
      : data.generalInformation.deliveryDetails.locations[0].company

  const countryId =
    parentType === 'ORDER'
      ? data.countryId
      : data.generalInformation.deliveryDetails.locations[0].countryId

  const zipcode =
    parentType === 'ORDER'
      ? data.zipcode
      : data.generalInformation.deliveryDetails.locations[0].zipcode

  const place =
    parentType === 'ORDER'
      ? data.place
      : data.generalInformation.deliveryDetails.locations[0].place

  const unload =
    parentType === 'ORDER'
      ? data.unload
      : data.generalInformation.deliveryDetails.locations[0].unload

  const stackHeight =
    parentType === 'ORDER'
      ? data.stackHeight
      : data.generalInformation.deliveryDetails.locations[0].stackHeight

  const stackHeightUnit =
    parentType === 'ORDER'
      ? data.stackHeightUnit
      : data.generalInformation.deliveryDetails.locations[0].stackHeightUnit

  const deliveryInformations =
    parentType === 'ORDER'
      ? data.deliveryInformations
      : data.generalInformation.deliveryDetails.locations[0]
          .deliveryInformations

  const isDespatchAdviceRequired =
    parentType === 'ORDER'
      ? data.isDespatchAdviceRequired
      : data.generalInformation.deliveryDetails.locations[0]
          .isDespatchAdviceRequired

  const despatchAdviceInstructions =
    parentType === 'ORDER'
      ? data.despatchAdviceInstructions
      : data.generalInformation.deliveryDetails.locations[0]
          .despatchAdviceInstructions

  const deliveryTimes =
    parentType === 'ORDER'
      ? data.deliveryTimes
      : data.generalInformation.deliveryDetails.locations[0].deliveryTimes

  const fetchTranslation = () => {
    if (deliveryInformations) {
      _applicationService
        .translateText(deliveryInformations)
        .then((translation) => {
          setTranslatedDeliveryInformations(translation)
        })
        .catch((error) => {
          console.error('Translation error:', error)
        })
    }
  }

  return (
    <Section header={t('REQUEST.DELIVERY_DETAILS.HEADING')} hideBorder={true}>
      <Grid container spacing={1}>
        {parentType === 'ORDER' ? (
          <Grid container item xs={12}>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle1'}>
                {t('DELIVERY_DETAILS_VIEW.DELIVERY_DATE') + ':'}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {data.deliveryType === 'date'
                  ? moment(data.deliveryValue).format('DD.MM.YYYY')
                  : `${t(
                      'ORDERS.CW',
                    )} ${generalService.convertDateToCalendarWeek(
                      data.deliveryValue,
                    )}`}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        <Grid container item xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant={'subtitle1'}>
              {t('DELIVERY_DETAILS_VIEW.INCOTERM') + ':'}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            {parentType === 'OFFER' ? (
              <DifferenceWrapper
                showDifference={differences.incoTerm}
                difference={differences.incoTerm}
                differenceDisplay={differences.incoTerm}
                diffObjectType={diffObjectType}
              >
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  {incotermLookup[incoTerm]}
                </Typography>
              </DifferenceWrapper>
            ) : (
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {incotermLookup[incoTerm]}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant={'subtitle1'}>
              {t('OFFER.DELIVERY_ADDRESS') + ':'}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            {parentType === 'OFFER' ? (
              <DifferenceWrapper
                showDifference={
                  differences.locations[0].company |
                  differences.locations[0].address |
                  differences.locations[0].zipcode |
                  differences.locations[0].place |
                  differences.locations[0].countryId
                }
                difference={
                  differences.locations[0].company |
                  differences.locations[0].address |
                  differences.locations[0].zipcode |
                  differences.locations[0].place |
                  differences.locations[0].countryId
                }
                differenceDisplay={
                  differences.locations[0].company
                    ? `${
                        differences.locations[0].company
                          ? differences.locations[0].company
                          : company
                      }, ${
                        differences.locations[0].address
                          ? differences.locations[0].address
                          : address
                      }, ${
                        differences.locations[0].zipcode
                          ? differences.locations[0].zipcode
                          : zipcode
                      } ${
                        differences.locations[0].place
                          ? differences.locations[0].place
                          : place
                      }, ${
                        differences.locations[0].countryId
                          ? countryLookup[differences.locations[0].countryId]
                          : countryLookup[countryId]
                      }`
                    : `${
                        differences.locations[0].zipcode
                          ? differences.locations[0].zipcode
                          : zipcode
                      } ${
                        differences.locations[0].place
                          ? differences.locations[0].place
                          : place
                      }, ${
                        differences.locations[0].countryId
                          ? countryLookup[differences.locations[0].countryId]
                          : countryLookup[countryId]
                      }`
                }
                diffObjectType={diffObjectType}
              >
                <Typography
                  variant={'subtitle2'}
                  color={'text.secondary'}
                  whiteSpace={'pre-wrap'}
                >
                  {company && <Fragment>{`${company}, ${address}, `}</Fragment>}
                  <Fragment>{`${zipcode} ${place}, ${countryLookup[countryId]}`}</Fragment>
                </Typography>
              </DifferenceWrapper>
            ) : (
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {company && <Fragment>{`${company}, ${address}, `}</Fragment>}
                <Fragment>{`${zipcode} ${place}, ${countryLookup[countryId]}`}</Fragment>
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <Typography variant={'subtitle1'}>
              {t('DELIVERY_DETAILS.POSSIBLE_LOADING') + ':'}
            </Typography>
          </Grid>
          {unload && (
            <Grid container item xs={12}>
              {parentType === 'OFFER' ? (
                <DifferenceWrapper
                  showDifference={differences.locations[0].unload}
                  difference={differences.locations[0].unload}
                  differenceDisplay={
                    unloadLookup[differences.locations[0].unload]
                  }
                  diffObjectType={diffObjectType}
                >
                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                    {unloadLookup[unload]}
                  </Typography>
                </DifferenceWrapper>
              ) : (
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  {unloadLookup[unload]}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
        {stackHeight !== null &&
          stackHeight !== 0 &&
          stackHeight !== '' &&
          stackHeightUnit && (
            <Grid container item xs={12}>
              <Grid item xs={12} style={{ alignSelf: 'center' }}>
                <Typography variant={'subtitle1'}>
                  {t('DELIVERY_DETAILS.STACK_HEIGHT') + ':'}
                </Typography>
              </Grid>
              {parentType === 'OFFER' ? (
                <DifferenceWrapper
                  showDifference={
                    differences.locations[0].stackHeight ||
                    differences.locations[0].stackHeightUnit
                  }
                  difference={
                    differences.locations[0].stackHeight ||
                    differences.locations[0].stackHeightUnit
                  }
                  differenceDisplay={
                    differences.locations[0].stackHeight &&
                    differences.locations[0].stackHeight !== '0'
                      ? `${differences.locations[0].stackHeight} ${
                          stackHeightUnitLookup[
                            differences.locations[0].stackHeightUnit
                              ? differences.locations[0].stackHeightUnit
                              : stackHeightUnit
                          ]
                        }`
                      : t('DELIVERY_DETAILS.ANY_STACK_HEIGHT')
                  }
                  diffObjectType={diffObjectType}
                >
                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                    {`${stackHeight} ${stackHeightUnitLookup[stackHeightUnit]}`}
                  </Typography>
                </DifferenceWrapper>
              ) : (
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  {`${stackHeight} ${stackHeightUnitLookup[stackHeightUnit]}`}
                </Typography>
              )}
            </Grid>
          )}
        <Grid container item xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'center' }}>
            <DeliveryTimesView data={deliveryTimes} />
          </Grid>
        </Grid>
        {isDespatchAdviceRequired && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} style={{ alignSelf: 'center' }}>
                <Typography variant={'subtitle1'}>
                  {t('OFFER.DESPATCH_ADVICE_INSTRUCTIONS') + ':'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                  {despatchAdviceInstructions}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid container item xs={12}>
          <Grid item xs={12} style={{ alignSelf: 'flex-start' }}>
            <Typography variant={'subtitle1'}>
              {t('OFFER.DELIVERY_INFORMATION') + ':'}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sx={{
              alignSelf: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid
              item
              xs
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {parentType === 'OFFER' ? (
                <DifferenceWrapper
                  showDifference={differences.locations[0].deliveryInformations}
                  difference={differences.locations[0].deliveryInformations}
                  differenceDisplay={
                    showTranslated && translatedDeliveryInformations
                      ? translatedDeliveryInformations
                      : deliveryInformations
                  }
                  diffObjectType={diffObjectType}
                >
                  <Typography
                    variant={'subtitle2'}
                    color={'text.secondary'}
                    sx={{
                      marginRight: 8,
                      whiteSpace: 'normal',
                    }}
                  >
                    {showTranslated && deliveryInformations !== '' ? (
                      translatedDeliveryInformations
                    ) : deliveryInformations ? (
                      deliveryInformations
                    ) : (
                      <em>{t('OFFER.NO_DELIVERY_INFORMATION')}</em>
                    )}
                  </Typography>
                </DifferenceWrapper>
              ) : (
                <Typography
                  variant={'subtitle2'}
                  color={'text.secondary'}
                  sx={{
                    marginRight: 8,
                    whiteSpace: 'normal',
                  }}
                >
                  {showTranslated && deliveryInformations !== '' ? (
                    translatedDeliveryInformations
                  ) : deliveryInformations ? (
                    deliveryInformations
                  ) : (
                    <em>{t('OFFER.NO_DELIVERY_INFORMATION')}</em>
                  )}
                </Typography>
              )}
              {deliveryInformations && (
                <Tooltip title={t('OFFER.TRANSLATION_TOOLTIP')}>
                  <IconButton onClick={toggleTranslation}>
                    <TranslateIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Section>
  )
}

export default withTranslation()(DeliveryDetailsView)
