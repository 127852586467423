import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { partnersService } from '../../../../_services/partnersService'
import { Loading } from '../../../Loading'
import CalendarCustom from './_components/CalendarCustom'
import DashboardIndex from './_components/DashboardIndex'
import OrderPieChart from './_components/OrderPieChart'
import TaskBoard from './_components/TaskBoard'
const Dashboard = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [partner, setPartner] = useState({})
  var userObject = JSON.parse(localStorage.getItem('user'))
  const theme = useTheme()

  useEffect(() => {
    setIsLoading(true)
    partnersService
      .getPartner(userObject.partner.id)
      .then((data) => {
        setPartner(data)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [userObject.partner.id])

  const hasIndexAccess =
    userObject.partner.hasAccessToIndex ||
    userObject.partner.activeTenderIndexes?.length > 0

  return isLoading ? (
    <Loading variant="centered" />
  ) : (
    <Fragment>
      <Grid
        container
        spacing={3}
        alignItems="stretch"
        style={{ padding: theme.spacing(1) }}
      >
        <Grid item xs={12} md={hasIndexAccess ? 4 : 6}>
          <TaskBoard partner={partner}></TaskBoard>
        </Grid>
        <Grid item xs={12} md={hasIndexAccess ? 4 : 6}>
          <OrderPieChart partner={partner}></OrderPieChart>
        </Grid>
        {hasIndexAccess && (
          <Grid item xs={12} md={4}>
            <DashboardIndex partner={partner} />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          style={{
            height: 'auto',
          }}
        >
          <CalendarCustom partner={partner}></CalendarCustom>
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default withTranslation()(Dashboard)
