import {
  Attachment,
  Cancel,
  Delete,
  LocalShipping as DeliveryNoteIcon,
  Drafts,
  Edit,
  Euro,
  FileCopy,
  ShoppingCartCheckout,
  Stars,
} from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits'
import {
  Box,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import moment from 'moment'
import Numeral from 'numeral'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'
import { alertActions } from '../../../../_actions/alertActions'
import {
  categorizedProductLookup,
  countryLookup,
  deliveryTypeLookup,
  orderStateLookup,
  orderTypeLookup,
  productLookup,
  productStateLookup,
} from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import { generalService } from '../../../../_services/generalService'
import { orderConfirmationsService } from '../../../../_services/orderConfirmationsService'
import { orderPurchaseConfirmationsService } from '../../../../_services/orderPurchaseConfirmationsService'
import { ordersService } from '../../../../_services/ordersService'
import { usersService } from '../../../../_services/usersService'
import AgreementDialog from '../../../AgreementDialog'
import ClaimsOfDeliveryNoteDialog from '../../../ClaimsOfDeliveryNoteDialog'
import { CustomRating } from '../../../CustomRating'
import DeliveryNotes from '../../../DeliveryNotes'
import EditOrderDialog from '../../../EditOrderDialog'
import { InfoTooltip } from '../../../InfoTooltip'
import InvoicingDialog from '../../../InvoicingDialog'
import { Loading } from '../../../Loading'
import OrderAttachmentDialog from '../../../OrderAttachmentDialog'
import { PositionsList } from '../../../PositionsList'
import RateOrderDialog from '../../../RateOrderDialog'
import ResourceList from '../../../ResourceList/_components/ResourceList'
import ViewDescription from '../../../ViewDescription'
import Order from '../Order'
import OrderAgainDialog from './_components/OrderAgainDialog'
import RetroactivePunchoutDialog from './_components/RetroactivePunchoutDialog'

const Orders = (props) => {
  const { t, dispatch } = props

  const [isLoading, setIsLoading] = useState(false)

  const [selectedOrder, setSelectedOrder] = useState(null)
  const [selectedClaimOrder, setSelectedClaimOrder] = useState(null)
  const [showDeliveryNoteDialog, setShowDeliveryNoteDialog] = useState(false)

  const [showRateOrderDialog, setShowRateOrderDialog] = useState(false)
  const [showClaimsDialog, setShowClaimsDialog] = useState(false)
  const [showEditOrderDialog, setShowEditOrderDialog] = useState(false)

  const [showOrderAgainDialog, setShowOrderAgainDialog] = useState(false)

  const [showDeleteOrderDialog, setShowDeleteOrderDialog] = useState(false)

  const [showCancelOrderDialog, setShowCancelOrderDialog] = useState(false)

  const [showInvoicingDialog, setShowInvoicingDialog] = useState(false)

  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false)

  const [showRetroactivePunchoutDialog, setShowRetroactivePunchoutDialog] =
    useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [forceUpdateCount, forceUpdate] = useState(0)

  const [usersOfPartner, setUsersOfPartner] = useState([])

  var userObject = JSON.parse(localStorage.getItem('user'))

  const location = useLocation()

  let mode =
    location.pathname === '/deliveries-customer' ? 'CUSTOMER' : 'MANUFACTURER'

  const search = location.search
  const params = new URLSearchParams(search)
  let defaultValueId = params.get('id')
  let defaultValueRequestId = params.get('requestId')
  let defaultValueJobOfferId = params.get('jobOfferId')

  useEffect(() => {
    usersService.getUsersOfPartner(userObject.partner.id).then((users) => {
      setUsersOfPartner(users)
    })
  }, [userObject.partner.id])

  let hookURL = sessionStorage.getItem('HOOK_URL')

  return (
    <Fragment>
      <ViewDescription
        description={
          mode === 'CUSTOMER'
            ? t('DELIVERIES_CUSTOMER.VIEW_DESCRPTION')
            : t('DELIVERIES_MANUFACTURER.VIEW_DESCRPTION')
        }
      />
      {(isLoading && <Loading variant={'centered'} />) || (
        <Fragment>
          <ResourceList
            fetchMethod={(filterValues, pagination) =>
              ordersService.getOrdersOfPartner(
                parseInt(userObject.partner.id),
                mode,
                filterValues,
                pagination,
                userObject.user.partnerLocationIds,
              )
            }
            forceUpdateCount={forceUpdateCount}
            filterId={'ORDERS_V2'}
            filters={[
              {
                key: 'id.startsWith',
                label: t('ORDERS.ORDER_ID'),
                variant: 'textfield',
                defaultValue: defaultValueId,
              },
              {
                key: 'deliveryNotes.id.startsWith',
                label: t('ORDERS.DELIVERY_NOTE_ID'),
                variant: 'textfield',
              },
              {
                key: 'orderNumber.like',
                label: t('ORDER.CUSTOMER_ORDER_NUMBER'),
                variant: 'textfield',
              },
              {
                key: 'invoices.invoiceNumber.like',
                label: t('ORDERS.INVOICE_NUMBER'),
                variant: 'textfield',
              },
              {
                key: 'calendarWeek',
                label: t('ORDERS.CALENDAR_WEEK'),
                variant: 'calendarWeekPicker',
              },
              {
                key: 'requestId.startsWith',
                label: t('ORDERS.REQUEST_ID'),
                variant: 'textfield',
                defaultValue: defaultValueRequestId,
              },
              {
                key: 'jobOfferId.startsWith',
                label: t('ORDERS.JOB_OFFER_ID'),
                variant: 'textfield',
                defaultValue: defaultValueJobOfferId,
              },
              {
                key: 'state',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(orderStateLookup),
                lookup: orderStateLookup,
                label: t('ORDERS.STATE'),
              },
              {
                key: 'positions.productId.eq',
                variant: 'categorizedCheckboxList',
                values: categorizedProductLookup.map((category) => {
                  return {
                    label: category.name,
                    items: category.products.map((product) => product.id),
                  }
                }),
                lookup: productLookup,
                label: t('ORDERS.PRODUCT'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(productStateLookup),
                lookup: productStateLookup,
                label: t('ORDERS.PRODUCT_STATE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(deliveryTypeLookup),
                lookup: deliveryTypeLookup,
                label: t('ORDERS.DELIVERY_TYPE'),
              },
              {
                key: 'positions.positionProperties.productPropertyId=1&positions.positionProperties.value',
                label: t('ORDERS.PRODUCT_LENGTH'),
                variant: 'textfield',
              },
              {
                key: 'positions.positionProperties.productPropertyId=2&positions.positionProperties.value',
                label: t('ORDERS.PRODUCT_WIDTH'),
                variant: 'textfield',
              },
              {
                key: 'countryId.eq',
                variant: 'checkboxList',
                values: Object.getOwnPropertyNames(countryLookup),
                lookup: countryLookup,
                label: t('ORDERS.COUNTRY'),
              },
              {
                key: 'zipcode.startsWith',
                label: t('ORDERS.ZIPCODE'),
                variant: 'textfield',
              },
              {
                key: 'place.startsWith',
                label: t('ORDERS.PLACE'),
                variant: 'textfield',
              },
              {
                key: 'address.startsWith',
                label: t('ORDERS.ADDRESS'),
                variant: 'textfield',
              },
              {
                key: 'company.startsWith',
                label: t('ORDERS.COMPANY'),
                variant: 'textfield',
              },
              {
                key:
                  mode === 'CUSTOMER'
                    ? 'customerUserId.eq'
                    : 'manufacturerUserId.eq',
                variant: 'checkboxList',
                values: usersOfPartner.map((user) => user.id),
                lookup: Object.fromEntries(
                  usersOfPartner.map((user) => [
                    user.id,
                    `${user.firstname} ${user.lastname}`,
                  ]),
                ),
                label: t('GENERAL.USER'),
              },
            ]}
            sorting={[
              {
                key: 'id',
                default: true,
                label: t('ORDERS.ORDER_ID'),
              },
              {
                key: 'deliveryValue',
                default: false,
                label: t('ORDERS.DELIVERY_DATE'),
              },
            ]}
            dataIdentifier={'id'}
            resourceHeaderRenderFunction={(order) =>
              `${t('ORDERS.ORDER')}: ${order.id}`
            }
            listHeaderRenderFunction={(order) => (
              <Fragment>
                <div>{`${t('ORDERS.ORDER')}: ${order.id}`}</div>
                <div>
                  {orderTypeLookup[order.type]}
                  {order.jobOfferId &&
                    ` ${t('ORDERS.NUMBER_SHORT')}: ${order.jobOfferId}`}
                  {order.request &&
                    ` ${t('ORDERS.NUMBER_SHORT')}: ${order.request.id}`}
                </div>
                <Fragment>
                  <div>
                    {authorize('administrator') ? (
                      <Fragment>
                        <div>{`${t('ORDERS.CUSTOMER')}: ${
                          order.customerUser.firstname
                        } ${order.customerUser.lastname} | ${
                          order.customer.name
                        } (${order.customerId})`}</div>
                        {order.manufacturerUser && order.manufacturer && (
                          <div>{`${t('ORDERS.MANUFACTURER')}: ${
                            order.manufacturerUser.firstname
                          } ${order.manufacturerUser.lastname} | ${
                            order.manufacturer.name
                          } (${order.manufacturerId})`}</div>
                        )}
                      </Fragment>
                    ) : order.manufacturer ? (
                      <Fragment>
                        <div>{`${t('ORDERS.MANUFACTURER')}: ${
                          order.manufacturer.name
                        }`}</div>
                      </Fragment>
                    ) : order.customer ? (
                      <Fragment>
                        <div>{`${t('ORDERS.CUSTOMER')}: ${
                          order.customer.name
                        }`}</div>
                      </Fragment>
                    ) : null}
                  </div>
                </Fragment>
                <div>
                  {`${order.zipcode} | ${order.place} | `}
                  {`${countryLookup[order.countryId]} |  ${
                    order.deliveryType === 'date'
                      ? moment(order.deliveryValue).format('DD.MM.YYYY')
                      : `${t(
                          'ORDERS.CW',
                        )} ${generalService.convertDateToCalendarWeek(
                          order.deliveryValue,
                        )}`
                  }`}
                </div>
              </Fragment>
            )}
            headerActionRenderFunction={(order) => {
              return (
                <Fragment>
                  <Stack direction={'column'}>
                    <Stack
                      direction={isMobile ? 'row-reverse' : 'row'}
                      spacing={1}
                    >
                      {order.deliveryNotes.some(
                        (deliveryNote) =>
                          deliveryNote.claims && deliveryNote.claims.length > 0,
                      ) && (
                        <ProductionQuantityLimitsIcon
                          color={'secondary'}
                          onClick={() => {
                            if (order.customerId === userObject.partner.id) {
                              setShowClaimsDialog(true)
                              setSelectedClaimOrder(order)
                            }
                          }}
                        />
                      )}

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          marginRight: '10px',
                        }}
                      >
                        {order.community && (
                          <InfoTooltip
                            title={
                              order.manufacturerId !== userObject.partner.id
                                ? order.community.name
                                : order.customer.name
                            }
                          >
                            <GroupsIcon color={'secondary'} />
                          </InfoTooltip>
                        )}
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'right',
                          alignItems: 'center',
                        }}
                      >
                        <Chip
                          size={'small'}
                          sx={{ padding: 1 }}
                          color={
                            order.state === 'RECEIVED' ||
                            order.state === 'INVOICED'
                              ? 'success'
                              : order.state === 'CANCELED'
                              ? 'default'
                              : 'info'
                          }
                          label={orderStateLookup[order.state]}
                        />
                      </div>
                    </Stack>
                    <Box padding={1}>
                      {order.rating ? (
                        <InfoTooltip
                          title={
                            <table>
                              <thead>
                                <th
                                  style={{
                                    borderRight: 'solid 15px transparent',
                                  }}
                                >
                                  {t('ORDERS.RATING_TOTAL')}
                                </th>
                                <th>
                                  {Numeral(
                                    (order.rating.quality +
                                      order.rating.reliability) /
                                      2,
                                  ).format('0,0.[00]')}
                                </th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {t('ORDERS.RATING_QUALITY')}
                                  </td>
                                  <td>
                                    {Numeral(
                                      parseFloat(order.rating.quality),
                                    ).format('0,0.[00]')}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      borderRight: 'solid 15px transparent',
                                    }}
                                  >
                                    {t('ORDERS.RATING_RELIABILITY')}
                                  </td>
                                  <td>
                                    {Numeral(
                                      parseFloat(order.rating.reliability),
                                    ).format('0,0.[00]')}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          }
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'right',
                              alignItems: 'center',
                            }}
                          >
                            <CustomRating
                              size={'small'}
                              name="read-only"
                              value={
                                (order.rating.quality +
                                  order.rating.reliability) /
                                2
                              }
                              readOnly
                              precision={0.5}
                            />
                          </div>
                        </InfoTooltip>
                      ) : (
                        <div
                          style={{
                            textAlign: isMobile ? 'left' : 'right',
                          }}
                        >
                          <Typography variant={'caption'}>
                            {t('ORDERS.NOT_RATED_YET')}
                          </Typography>
                        </div>
                      )}
                    </Box>
                  </Stack>
                </Fragment>
              )
            }}
            contentRenderFunction={(order) => (
              <PositionsList positions={order.positions} />
            )}
            resourceRenderFunction={(order) => {
              return <Order orderId={order.id} />
            }}
            actionsRenderFunction={(order) => {
              return [
                {
                  icon: <ShoppingCartCheckout />,
                  name: t('ORDERS.RETRO_ACTIVE_PUNCHOUT'),
                  hidden: !hookURL || authorize('administrator'),
                  primary: false,
                  onClick: async () => {
                    var data = await ordersService.getOrder(order.id)
                    setSelectedOrder(data)
                    setShowRetroactivePunchoutDialog(true)
                  },
                },
                {
                  icon: <Cancel />,
                  hidden:
                    !authorize('administrator') || order.state === 'CANCELED',
                  name: t('ORDERS.CANCEL'),
                  primary: false,
                  onClick: async () => {
                    var data = await ordersService.getOrder(order.id)
                    setSelectedOrder(data)
                    setShowCancelOrderDialog(true)
                  },
                },
                {
                  icon: <Delete />,
                  hidden: !authorize('administrator'),
                  disabled:
                    order.state === 'RECEIVED' || order.state === 'INVOICED',
                  name: t('ORDERS.DELETE_ORDER'),
                  primary: false,
                  onClick: async () => {
                    var data = await ordersService.getOrder(order.id)
                    setSelectedOrder(data)
                    setShowDeleteOrderDialog(true)
                  },
                },
                {
                  icon: <FileCopy />,
                  hidden: order.customerId !== userObject.partner.id,
                  name: t('ORDERS.ORDER_AGAIN'),
                  primary: false,
                  onClick: async () => {
                    var data = await ordersService.getOrder(order.id)
                    setSelectedOrder(data)
                    setShowOrderAgainDialog(true)
                  },
                },
                {
                  icon: <Edit />,
                  hidden: !authorize('administrator'),
                  name: t('ORDERS.EDIT_ORDER'),
                  primary: false,
                  onClick: async () => {
                    var data = await ordersService.getOrder(order.id)
                    setSelectedOrder(data)
                    setShowEditOrderDialog(true)
                  },
                },
                {
                  icon: <Drafts />,
                  hidden: mode === 'MANUFACTURER',
                  name: t('ORDERS.DOWNLOAD_ORDER_CONFIRMATION'),
                  primary: false,
                  onClick: () =>
                    orderConfirmationsService
                      .getOrderConfirmation(order.id)
                      .then((response) => response.blob())
                      .then((blob) => {
                        // 2. Create blob link to download
                        const url = window.URL.createObjectURL(new Blob([blob]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute(
                          'download',
                          'order-confirmation-' + order.id + '.pdf',
                        )
                        // 3. Append to html page
                        document.body.appendChild(link)
                        // 4. Force download
                        link.click()
                        // 5. Clean up and remove the link
                        link.parentNode.removeChild(link)
                      }),
                },
                {
                  icon: <Drafts />,
                  hidden: mode === 'CUSTOMER',
                  name: t('ORDERS.DOWNLOAD_ORDER_PURCHASE_CONFIRMATION'),
                  primary: false,
                  onClick: () =>
                    orderPurchaseConfirmationsService
                      .getOrderPurchaseConfirmation(order.id)
                      .then((response) => response.blob())
                      .then((blob) => {
                        // 2. Create blob link to download
                        const url = window.URL.createObjectURL(new Blob([blob]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute(
                          'download',
                          'order-purchase-confirmation-' + order.id + '.pdf',
                        )
                        // 3. Append to html page
                        document.body.appendChild(link)
                        // 4. Force download
                        link.click()
                        // 5. Clean up and remove the link
                        link.parentNode.removeChild(link)
                      }),
                },
                {
                  icon: <Stars />,
                  hidden:
                    order.customerId !== userObject.partner.id ||
                    order.rating ||
                    (order.state !== 'RECEIVED' &&
                      order.state !== 'SHIPPED' &&
                      order.state !== 'INVOICED'),
                  name: order.rating
                    ? t('ORDERS.ALREADY_RATED')
                    : order.state !== 'RECEIVED' &&
                      order.state !== 'SHIPPED' &&
                      order.state !== 'INVOICED'
                    ? t('ORDERS.ORDER_NOT_SEND_YET')
                    : t('ORDERS.RATE_ORDER_NOW'),
                  primary: false,
                  onClick: async () => {
                    var data = await ordersService.getOrder(order.id)
                    setSelectedOrder(data)
                    setShowRateOrderDialog(true)
                  },
                },
                {
                  icon: <DeliveryNoteIcon />,
                  name:
                    mode === 'CUSTOMER'
                      ? t('ORDERS.SHOW_DELIVERY_NOTES_CUSTOMER')
                      : t('ORDERS.SHOW_DELIVERY_NOTES_MANUFACTURER'),
                  primary: true,
                  onClick: async () => {
                    var data = await ordersService.getOrder(order.id)
                    setSelectedOrder(data)
                    setShowDeliveryNoteDialog(true)
                  },
                },
                {
                  icon: <Euro />,
                  name: authorize('administrator')
                    ? t('ORDERS.INVOICING')
                    : t('ORDERS.INVOICES'),
                  hidden:
                    !authorize('administrator') &&
                    userObject.partner.id !== order.customerId,
                  primary: false,
                  onClick: async () => {
                    var data = await ordersService.getOrder(order.id)
                    setSelectedOrder(data)
                    setShowInvoicingDialog(true)
                  },
                },
                {
                  icon: <Attachment />,
                  name: t('ORDERS.ATTACHMENTS'),
                  hidden: !authorize('administrator'),
                  primary: false,
                  onClick: async () => {
                    var data = await ordersService.getOrder(order.id)
                    setSelectedOrder(data)
                    setShowAttachmentDialog(true)
                  },
                },
              ]
            }}
          />

          {selectedOrder && (
            <AgreementDialog
              open={showDeleteOrderDialog}
              message={t('ORDERS.DELETE_ORDER_MESSAGE')}
              acceptButtonText={t('ORDERS.DELETE_ORDER')}
              handleClose={() => {
                setShowDeleteOrderDialog(false)
              }}
              agree={() => {
                setIsLoading(true)
                setShowDeleteOrderDialog(false)
                ordersService
                  .deleteOrder(selectedOrder.id)
                  .then((data) => {
                    setSelectedOrder(null)
                    forceUpdate()
                  })
                  .catch(function (error) {
                    console.log(error)
                  })
                  .finally(() => {
                    setIsLoading(false)
                  })
              }}
            />
          )}
          {selectedOrder && (
            <AgreementDialog
              open={showCancelOrderDialog}
              message={t('ORDERS.CANCEL_ORDER_MESSAGE')}
              acceptButtonText={t('ORDERS.CANCEL_ORDER')}
              handleClose={() => setShowCancelOrderDialog(false)}
              agree={() => {
                setIsLoading(true)
                setShowCancelOrderDialog(false)
                ordersService
                  .updateOrder({ state: 'CANCELED' }, selectedOrder.id)
                  .then((data) => {
                    console.log('update Order Canceled')
                    forceUpdate(forceUpdateCount + 1)
                  })
                  .catch(function (error) {
                    console.log(error)
                  })
                  .finally(() => {
                    setIsLoading(false)
                  })
              }}
            />
          )}
          <DeliveryNotes
            order={selectedOrder}
            productLookup={productLookup}
            show={showDeliveryNoteDialog}
            hide={() => {
              setSelectedOrder(null)
              setShowDeliveryNoteDialog(false)
            }}
            key={
              selectedOrder
                ? selectedOrder.id + 'deliveryNotes'
                : 'deliveryNotes'
            }
          />

          <RateOrderDialog
            order={selectedOrder}
            show={showRateOrderDialog}
            hide={() => {
              setSelectedOrder(null)
              setShowRateOrderDialog(false)
            }}
            key={selectedOrder ? selectedOrder.id + 'rateOrder' : 'rateOrder'}
            forceUpdate={() => {
              forceUpdate(forceUpdateCount + 1)
              dispatch(alertActions.info(t('ORDER.ORDER_SUCCESSFULLY_RATED')))
              setTimeout(() => {
                dispatch(alertActions.clear())
              }, alertActions.alertTimeout)
            }}
          />

          {authorize('administrator') && showEditOrderDialog && (
            <EditOrderDialog
              orderId={selectedOrder ? selectedOrder.id : null}
              show={showEditOrderDialog}
              hide={() => {
                setSelectedOrder(null)
                setShowEditOrderDialog(false)
              }}
              forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
              key={selectedOrder ? selectedOrder.id + 'editOrder' : 'editOrder'}
            />
          )}

          <OrderAgainDialog
            order={selectedOrder}
            show={showOrderAgainDialog}
            hide={() => {
              setSelectedOrder(null)
              setShowOrderAgainDialog(false)
            }}
            key={selectedOrder ? selectedOrder.id + 'orderAgain' : 'orderAgain'}
          />

          {showInvoicingDialog && (
            <InvoicingDialog
              order={selectedOrder}
              show={showInvoicingDialog}
              hide={() => setShowInvoicingDialog(false)}
            />
          )}
          {showClaimsDialog && (
            <ClaimsOfDeliveryNoteDialog
              forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
              deliveryNote={null}
              order={selectedClaimOrder}
              show={showClaimsDialog}
              hide={() => setShowClaimsDialog(false)}
            />
          )}
          {showAttachmentDialog && (
            <OrderAttachmentDialog
              orderId={selectedOrder.id}
              show={showAttachmentDialog}
              hide={() => setShowAttachmentDialog(false)}
              forceUpdateCount={forceUpdateCount}
              forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
            />
          )}

          <RetroactivePunchoutDialog
            order={selectedOrder}
            show={showRetroactivePunchoutDialog}
            hide={() => setShowRetroactivePunchoutDialog(false)}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

export default withTranslation()(connect()(Orders))
