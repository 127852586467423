import { Grid, Typography } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Loading } from '../../../../../../../Loading'
import { Section } from '../../../../../../../Section'
import ClaimAttachmentView from './_components/ClaimAttachmentView'

const ClaimView = ({ t, claim }) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Fragment>
      <Section header={t('CLAIMS.TITLE')} hideBorder={true}>
        <Fragment>
          {isLoading ? (
            <Loading variant="centered" />
          ) : (
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <Typography variant="subtitle1">{t('CLAIM.TYPE')}</Typography>
                <Typography variant="body1" gutterBottom>
                  {claim?.type || t('CLAIM.QUALITY')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t('CLAIM.DESCRIPTION')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  {claim?.description || ''}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{t('CLAIM.STATE')}</Typography>
                <Typography variant="body1" gutterBottom>
                  {claim?.state || t('CLAIM.STATE_ACTIVE')}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Fragment>
      </Section>
      <Section hideBorder={true}>
        <ClaimAttachmentView data={claim}></ClaimAttachmentView>
      </Section>
    </Fragment>
  )
}

export default withTranslation()(ClaimView)
