import { Grid, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { authorize } from '../../_helpers/authorization'
import { communitiesService } from '../../_services/communitiesService'
import { Section } from '../Section'
const OtherDetailsView = (props) => {
  const { t, data, requestType } = props
  const [community, setCommunity] = useState(data)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (data.communityId) {
      setIsLoading(true)
      communitiesService.getCommunity(data.communityId).then((data) => {
        setCommunity(data)
        setIsLoading(false)
      })
    }
  }, [data.communityId])

  return (
    <Section header={t('REQUEST.OTHER_DETAILS')} hideBorder={true}>
      <Grid container spacing={1}>
        {data.title && (
          <Grid item container xs={12}>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle1'}>
                {t('REQUEST.TITLE') + ':'}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {data.title}
              </Typography>
            </Grid>
          </Grid>
        )}
        {requestType !== 'TENDERORDER' && (
          <Grid item container xs={12}>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle1'}>
                {t('REQUEST.VALID_UNTIL') + ':'}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {data.validUntil === '' ||
                data.validUntil === null ||
                data.validUntil === undefined
                  ? t('REQUEST.INDEFINED')
                  : moment(data.validUntil).local().format('DD.MM.YYYY')}
              </Typography>
            </Grid>
          </Grid>
        )}

        {data.communityId && (
          <Grid item container xs={12}>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle1'}>
                {t('REQUEST.COMMUNITY_CHOSEN') + ':'}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {community.name}
              </Typography>
            </Grid>
          </Grid>
        )}
        {/* negative check because of also used for TENDERORDER besides TENDER */}
        {requestType !== 'REQUEST' && requestType !== 'ADVERT' && (
          <Grid item container xs={12}>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle1'}>
                {t('REQUEST.COLLABORATION_PERIOD') + ':'}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {`${moment(data.start).local().format('DD.MM.YYYY')} - ${moment(
                  data.end,
                )
                  .local()
                  .format('DD.MM.YYYY')}`}
              </Typography>
            </Grid>
          </Grid>
        )}
        {authorize('administrator') && data.internalNote && (
          <Grid item container xs={12}>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle1'}>
                {t('OFFER.INTERNAL_NOTE') + ':'}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ alignSelf: 'center' }}>
              <Typography variant={'subtitle2'} color={'text.secondary'}>
                {data.internalNote}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Section>
  )
}

export default withTranslation()(OtherDetailsView)
