import {
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import moment from 'moment'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { indexLookup } from '../../../../../../_constants/lookupConstants'
import { formatToNumber } from '../../../../../../_helpers/number-formatting'

const IndexesRendererMobile = (props) => {
  const { t, identifiers, data, selectedIdentifiers, setSelectedIdentifiers } =
    props

  const handleChange = (event) => {
    setSelectedIdentifiers([event.target.value])
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Select
          labelId="select-index-label"
          id="select-index"
          value={selectedIdentifiers[0]}
          label={t('INDEXES.SELECT_INDEX')}
          onChange={handleChange}
        >
          {identifiers.map((identifier, index) => (
            <MenuItem key={index} value={identifier}>
              {indexLookup[identifier]}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('INDEXES.DATE')}</TableCell>
                <TableCell>{t('INDEXES.INDEX_VALUE')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .sort((a, b) => moment(b.timestamp) - moment(a.timestamp))
                .map((entry) => (
                  <TableRow
                    key={entry.timestamp}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      {moment(entry.timestamp).format('DD.MM.YYYY')}
                    </TableCell>
                    <TableCell>
                      {`${formatToNumber(
                        entry.value.indexValue,
                      )} (${formatToNumber(entry.value.difference * 100)}%)`}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default withTranslation()(connect()(IndexesRendererMobile))
