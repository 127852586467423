import Keycloak from 'keycloak-js'
import config from './_config/config'

const keycloakConfig = {
  url: 'https://auth.pacurion.com',
  realm: config.keycloakRealm,
  clientId: 'portal-frontend',
}

const keycloak = new Keycloak(keycloakConfig)

export default keycloak
