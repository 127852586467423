import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header.js'

export const badgesService = {
  getBadges,
  createBadge,
  deleteBadge,
  updateBadge,
}

function getBadges() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/badges`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function createBadge(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }

  return fetch(`${config.apiUrlBase}/badges`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function deleteBadge(badgeId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/badges/${badgeId}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function updateBadge(badgeId, requestBody) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(requestBody),
  }

  return fetch(`${config.apiUrlBase}/badges/${badgeId}`, requestOptions).then(
    handleResponse,
  )
}

export * from './badgesService.js'
