import moment from 'moment'
import config from '../_config/config'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'
import { generateFilterValuesQueryString } from '../_helpers/little'
import { claimAttachmentsService } from './claimAttachmentsService'

export const claimsService = {
  updateClaim,
  createClaimAttachment,
  getClaims,
  getClaimsOfDeliveryNote,
  createClaimOfDeliveryNote,
  getClaimsOfOrder,
}

function updateClaim(requestbody, claimId) {
  return new Promise(function (resolve, reject) {
    var payload = requestbody
    let requestOptions = {
      method: 'PATCH',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }

    fetch(`${config.apiUrlBase}/claims/${claimId}`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        let fileUploadPromiseList = []
        requestbody.attachments.forEach(function (attachment) {
          if (attachment.isDeleted) {
            let attachmentPromiseList = new Promise(function (resolve, reject) {
              requestOptions = {
                method: 'DELETE',
                headers: { ...authHeader() },
              }
              claimAttachmentsService
                .deleteClaimAttachment(attachment.id)
                .then(function (response) {
                  if (response.ok) {
                    handleResponse(response).then((data) => {
                      resolve(data)
                    })
                  } else {
                    reject()
                  }
                })
            })
            fileUploadPromiseList.push(attachmentPromiseList)
          } else if (attachment.isNew) {
            var requestBody = new FormData()
            requestBody.append('file', attachment)

            var attachmentPromiseList = new Promise(function (resolve, reject) {
              claimsService
                .createClaimAttachment(claimId, requestBody)
                .then(function (response) {
                  if (response.ok) {
                    handleResponse(response).then((data) => {
                      resolve(data)
                    })
                  } else {
                    reject()
                  }
                })
            })

            fileUploadPromiseList.push(attachmentPromiseList)
          }
        })
        Promise.all(fileUploadPromiseList).then(function (responses) {
          data.attachments = responses.map((response) => response.attachment)
          resolve(data)
        })
      })
  })
}

function createClaimAttachment(claimId, attachmentData) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    body: attachmentData,
  }
  return fetch(
    `${config.apiUrlBase}/claims/${claimId}/claim-attachments`,
    requestOptions,
  )
}

function getClaims(filterValues = undefined, pagination) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  filterValues = filterValues.calendarWeek
    ? {
        ...filterValues,
        calendarWeek: moment(filterValues.calendarWeek[0]).format(
          'YYYY-MM-DD HH:mm:ss',
        ),
      }
    : filterValues

  let queryString = generateFilterValuesQueryString(filterValues, pagination)
  return fetch(
    `${config.apiUrlBase}/claims${queryString}`,
    requestOptions,
  ).then(handleResponse)
}
function getClaimsOfDeliveryNote(deliveryNoteId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/delivery-notes/${deliveryNoteId}/claims`,
    requestOptions,
  ).then(handleResponse)
}
function getClaimsOfOrder(orderId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(
    `${config.apiUrlBase}/orders/${orderId}/claims`,
    requestOptions,
  ).then(handleResponse)
}

function createClaimOfDeliveryNote(claim, deliveryNoteId) {
  return new Promise(function (resolve, reject) {
    var payload = claim
    var requestOptions = {
      method: 'POST',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }

    fetch(
      `${config.apiUrlBase}/delivery-notes/${deliveryNoteId}/claims`,
      requestOptions,
    )
      .then(handleResponse)
      .then((data) => {
        var createdClaimId = data.claimId

        let fileUploadPromiseList = []
        claim.attachments.forEach(function (attachment) {
          if (!attachment.isNew) {
            return
          }
          var requestBody = new FormData()

          requestBody.append('file', attachment)

          var attachmentPromiseList = new Promise(function (resolve, reject) {
            claimsService
              .createClaimAttachment(createdClaimId, requestBody)
              .then(function (response) {
                if (response.ok) {
                  handleResponse(response).then((data) => {
                    resolve(data)
                  })
                } else {
                  reject()
                }
              })
          })
          fileUploadPromiseList.push(attachmentPromiseList)
        })

        Promise.all(fileUploadPromiseList)
          .then(function (responses) {
            data.attachments = responses.map((response) => response.attachment)
            resolve(data)
          })
          .catch(function (e) {
            console.log(e)
          })
      })
  })
}
export * from './claimsService'
