import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header.js'

export const productPriceLimitsService = {
  getProductPriceLimits,
  createProductPriceLimit,
  updateProductPriceLimit,
  deleteProductPriceLimit,
}

function getProductPriceLimits() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/product-price-limits`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function createProductPriceLimit(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(
    `${config.apiUrlBase}/product-price-limits`,
    requestOptions,
  ).then(handleResponse)
}

function updateProductPriceLimit(productPriceLimitId, payload) {
  const requestOptions = {
    method: 'PATCH',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  }
  return fetch(
    `${config.apiUrlBase}/product-price-limits/${productPriceLimitId}`,
    requestOptions,
  ).then(handleResponse)
}

function deleteProductPriceLimit(productPriceLimitId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }
  return fetch(
    `${config.apiUrlBase}/product-price-limits/${productPriceLimitId}`,
    requestOptions,
  ).then(handleResponse)
}

export * from './productPriceLimitsService.js'
