import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  TextField as MuiTextField,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material'
import { Field, Form, Formik, yupToFormErrors } from 'formik'
import { Autocomplete, TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { alertActions } from '../../_actions/alertActions'
import { addUniqueElementToArray, getTouchedObj } from '../../_helpers/little'
import { generalService } from '../../_services/generalService'
import { ordersService } from '../../_services/ordersService'
import { partnersService } from '../../_services/partnersService'
import { usersService } from '../../_services/usersService'
import CalendarWeekPicker from '../CalendarWeekPicker'
import CustomDialogTitle from '../CustomDialogTitle'
import DeliveryDetails from '../DeliveryDetails'
import DeliveryDetailsReplacedComponentsDialog from '../DeliveryDetailsReplacedComponentsDialog'
import { FieldErrorWrapper } from '../FieldErrorWrapper'
import InvoiceAddress from '../InvoiceAddress'
import OrderRegistrationPositions from '../OrderRegistrationPositions'
import PaymentDetails from '../PaymentDetails'

const EditOrderDialog = (props) => {
  const { orderId, hide, show, t, dispatch, forceUpdate } = props

  const [order, setOrder] = useState()
  const [customers, setCustomers] = useState([])
  const [customerUsers, setCustomerUsers] = useState([])
  const [selectedCustomerId, setSelectedCustomerId] = useState()
  const [manufacturers, setManufacturers] = useState([])
  const [manufacturerUsers, setManufacturerUsers] = useState([])
  const [selectedManufacturerId, setSelectedManufacturerId] = useState()
  const [activeStep, setActiveStep] = React.useState(0)
  const [showHasReplacedComponentsDialog, setShowHasReplacedComponentsDialog] =
    useState(false)
  const [externalAdressValidationResult, setExternalAdressValidationResult] =
    useState(false)

  const [geoData, setGeoData] = useState(null)

  useEffect(() => {
    if (orderId) {
      ordersService.getOrder(orderId).then((data) => {
        var order = {
          id: data.id,
          customer: data.customer,
          manufacturer: data.manufacturer,
          customerId: data.customerId,
          customerUserId: data.customerUserId,
          manufacturerId: data.manufacturerId,
          manufacturerUserId: data.manufacturerUserId,
          type: data.type,
          state: data.state,
          tenderOrderId: data.tenderOrderId,
          orderNumber: data.orderNumber,
          customInvoiceAddress: false,
          invoiceCompany: data.invoiceCompany,
          invoiceZipcode: data.invoiceZipcode,
          invoicePlace: data.invoicePlace,
          invoiceAddress: data.invoiceAddress,
          invoiceCountryId: data.invoiceCountryId,
          invoiceContactPerson: data.invoiceContactPerson,
          invoiceAdditionalAddressLine: data.invoiceAdditionalAddressLine,
          deliveryValue:
            data.deliveryType === 'date'
              ? moment(data.deliveryValue)
              : data.deliveryValue,
          deliveryType: data.deliveryType,
          internalNote: data.internalNote,
          generalInformation: {
            deliveryDetails: {
              incoTerm: data.incoTerm,
              locations: [
                {
                  id: 1,
                  company: data.company,
                  address: data.address,
                  zipcode: data.zipcode,
                  place: data.place,
                  countryId: data.countryId,
                  deliveryInformations: data.deliveryInformations,
                  positions: data.positions,
                  stackHeight: data.stackHeight,
                  stackHeightUnit: data.stackHeightUnit,
                  unload: data.unload,
                  deliveryTimes: data.deliveryTimes,
                  isDespatchAdviceRequired: data.isDespatchAdviceRequired,
                  despatchAdviceInstructions: data.despatchAdviceInstructions,
                },
              ],
            },
            paymentDetails: {
              paymentTerm: data.paymentTerm,
              skonto: data.skonto,
              skontoPeriod: data.skontoPeriod,
            },
          },
          positions: data.positions,
          partnerInvoiceLocationId: data.partnerInvoiceLocationId,
          deliveries: [],
        }
        setOrder(order)
        setSelectedCustomerId(data.customerId)
        setSelectedManufacturerId(data.manufacturerId)
      })
    }
  }, [orderId])

  useEffect(() => {
    partnersService.getPartners().then((data) => {
      setCustomers(
        data.map((partner) => ({
          id: partner.id,
          name: partner.name + ' (#' + partner.id + ')',
        })),
      )
      setManufacturers(
        data.map((partner) => ({
          id: partner.id,
          name: partner.name + ' (#' + partner.id + ')',
        })),
      )
    })
  }, [show])

  useEffect(() => {
    if (selectedCustomerId) {
      usersService.getUsersOfPartner(selectedCustomerId).then((data) => {
        setCustomerUsers(
          data.map((user) => ({
            id: user.id,
            name: user.email,
          })),
        )
      })
    }
  }, [selectedCustomerId])

  useEffect(() => {
    if (selectedManufacturerId) {
      usersService.getUsersOfPartner(selectedManufacturerId).then((data) => {
        setManufacturerUsers(
          data.map((user) => ({
            id: user.id,
            name: user.email,
          })),
        )
      })
    }
  }, [selectedManufacturerId])

  const valSchemas = [
    Yup.object().shape({
      manufacturerId: Yup.string().nullable(),
      manufacturerUserId: Yup.string().when(
        'manufacturerId',
        ([manufacturerId], schema) => {
          return manufacturerId
            ? Yup.string().required(t('GENERAL.REQUIRED'))
            : Yup.string().nullable()
        },
      ),
      customerId: Yup.number()
        .typeError(t('GENERAL.REQUIRED'))
        .required(t('GENERAL.REQUIRED')),
      customerUserId: Yup.number()
        .typeError(t('GENERAL.REQUIRED'))
        .required(t('GENERAL.REQUIRED')),
      generalInformation: Yup.object().shape({
        deliveryDetails: Yup.object().shape({
          incoTerm: Yup.string().required(t('GENERAL.REQUIRED')),
          locations: Yup.array()
            .of(
              Yup.object().shape({
                company: Yup.string().required(t('GENERAL.REQUIRED')),
                address: Yup.string().required(t('GENERAL.REQUIRED')),
                zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
                place: Yup.string().required(t('GENERAL.REQUIRED')),
                countryId: Yup.number().required(t('GENERAL.REQUIRED')),
                deliveryInformations: Yup.string().nullable(),
                isDespatchAdviceRequired: Yup.boolean().required(),
                despatchAdviceInstructions: Yup.string().nullable(),
              }),
              //Temporarily disabled address validation in EditOrder
              // .test({
              //   test: async function (location, context) {
              //     console.log(location)
              //     return await validateLocation(
              //       location,
              //       context,
              //       `generalInformation.deliveryDetails.locations[${context.parent.findIndex(
              //         (locationInValues) =>
              //           locationInValues.id === location.id,
              //       )}]`,
              //       setExternalAdressValidationResult,
              //       setShowHasReplacedComponentsDialog,
              //       (validationResult) => {
              //         setGeoData(validationResult.geocode.location)
              //       },
              //       this,
              //       ['route', 'street_number'],
              //     )
              //   },
              // }),
            )
            .min(1, t('GENERAL.MIN_1')),
        }),
        paymentDetails: Yup.object().shape({
          paymentTerm: Yup.number()
            .min(0, t('GENERAL.REQUIRED'))
            .required(t('GENERAL.REQUIRED')),
          skonto: Yup.number()
            .min(0, t('GENERAL.REQUIRED'))
            .required(t('GENERAL.REQUIRED')),
        }),
      }),
      orderNumber: Yup.string().nullable(),
      deliveryType: Yup.string().required(t('GENERAL.REQUIRED')),
      deliveryValue: Yup.mixed().when(
        'deliveryType',
        ([deliveryType], schema) => {
          return deliveryType === 'date'
            ? Yup.date().typeError(t('GENERAL.INVALID_DATE'))
            : Yup.mixed().nullable()
        },
      ),
      selectedDays: Yup.mixed().when(
        'deliveryType',
        ([deliveryType], schema) => {
          return deliveryType === 'calendarWeek'
            ? Yup.array().min(1, t('GENERAL.REQUIRED'))
            : Yup.mixed().nullable()
        },
      ),
    }),
    Yup.object().shape({
      generalInformation: Yup.object().shape({
        deliveryDetails: Yup.object().shape({
          incoTerm: Yup.string().required(t('GENERAL.REQUIRED')),
          locations: Yup.array()
            .of(
              Yup.object().shape({
                company: Yup.string().required(t('GENERAL.REQUIRED')),
                address: Yup.string().required(t('GENERAL.REQUIRED')),
                zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
                place: Yup.string().required(t('GENERAL.REQUIRED')),
                countryId: Yup.number().required(t('GENERAL.REQUIRED')),
                deliveryInformations: Yup.string().nullable(),
                isDespatchAdviceRequired: Yup.boolean().required(),
                despatchAdviceInstructions: Yup.string().nullable(),
                positions: Yup.array()
                  .of(
                    Yup.object().shape({
                      id: Yup.number().required(t('GENERAL.REQUIRED')),
                      productId: Yup.string().required(t('GENERAL.REQUIRED')),
                      productUnitId: Yup.number().required(
                        t('GENERAL.REQUIRED'),
                      ),
                      salesPrice: Yup.number()
                        .min(0.01, t('GENERAL.REQUIRED'))
                        .required(t('GENERAL.REQUIRED')),
                      purchasePrice: Yup.number()
                        .min(0.01, t('GENERAL.REQUIRED'))
                        .required(t('GENERAL.REQUIRED')),
                      amount: Yup.number()
                        .required(t('GENERAL.REQUIRED'))
                        .typeError(t('GENERAL.REQUIRED'))
                        .min(0.01, t('GENERAL.GREATER_THAN_0'))
                        .test({
                          message: t('GENERAL.TOO_MUCH'),
                          test: function (v) {
                            if (
                              this.parent.productUnitId === 3 &&
                              parseFloat(v) > 200
                            ) {
                              return false
                            } else {
                              return true
                            }
                          },
                        }),
                    }),
                  )
                  .min(1, t('GENERAL.MIN_1')),
              }),
            )
            .min(1, t('GENERAL.MIN_1')),
        }),
        paymentDetails: Yup.object().shape({
          paymentTerm: Yup.number()
            .min(0, t('GENERAL.REQUIRED'))
            .required(t('GENERAL.REQUIRED')),
          skonto: Yup.number()
            .min(0, t('GENERAL.REQUIRED'))
            .required(t('GENERAL.REQUIRED')),
        }),
      }),
    }),
  ]

  var selectedOrderDays = []
  if (
    order &&
    order.deliveryType === 'calendarWeek' &&
    order.deliveryValue !== null
  ) {
    for (var i = 0; i < 7; i++) {
      var date = generalService.addDaysToDate(order.deliveryValue, i)
      selectedOrderDays.push(date)
    }
  }

  return order && customers.length > 0 && manufacturers.length > 0 ? (
    <Formik
      enableReinitialize={true}
      initialValues={
        order
          ? {
              ...order,
              selectedDays: selectedOrderDays,
            }
          : null
      }
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting = true

        let payload = {
          ...values,
          latitude: geoData ? geoData.latitude : undefined,
          longitude: geoData ? geoData.longitude : undefined,
        }

        if (payload.deliveryType === 'calendarWeek') {
          payload.deliveryValue = moment(values.selectedDays[0])
        } else {
          payload.deliveryValue = moment(payload.deliveryValue)
        }

        payload.deliveryValue = payload.deliveryValue.set({
          hour: 12,
          minute: 0,
          second: 0,
          millisecond: 0,
        })

        payload.deliveries = [
          {
            type: payload.deliveryType,
            value: payload.deliveryValue,
            positions:
              payload.generalInformation.deliveryDetails.locations[0].positions.map(
                (pos) => ({
                  positionId: pos.id,
                  amount: pos.amount,
                }),
              ),
          },
        ]

        var payloadOrder = ordersService.mapOrders(payload)[0]

        // remove ids < 10 form positions to force new creation of the order positions, as adding new positions leads to pos.id 2 e.g.
        payloadOrder.positions = payloadOrder.positions.map((pos) => {
          if (pos.id < 10) {
            delete pos.id
          }
          return pos
        })
        ordersService
          .updateOrder(payloadOrder, order.id)
          .then(() => {
            setSubmitting = false
            forceUpdate()
            hide()
            dispatch(alertActions.info(t('REQUEST.PUBLISH_SUCCESSFUL')))
            //OrdersOverview does not reload atm, since the loading time is quite high

            setTimeout(() => {
              dispatch(alertActions.clear())
            }, alertActions.alertTimeout)
          })
          .catch(function (error) {
            setSubmitting = false
            console.log(error)
            dispatch(alertActions.error(error))
            setTimeout(() => {
              dispatch(alertActions.clear())
            }, alertActions.alertTimeout)
          })
      }}
    >
      {({
        isSubmitting,
        isValid,
        values,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        errors,
        touched,
        handleBlur,
        submitForm,
        setErrors,
        setTouched,
      }) => {
        let mappedCustomers = [...customers]
        let mappedManufacturers = [...manufacturers]

        addUniqueElementToArray(mappedCustomers, order, 'customer')
        addUniqueElementToArray(mappedCustomers, order, 'manufacturer')
        addUniqueElementToArray(mappedManufacturers, order, 'customer')
        addUniqueElementToArray(mappedManufacturers, order, 'manufacturer')

        return (
          <Form>
            <Dialog fullWidth={true} maxWidth="xl" open={show}>
              <CustomDialogTitle
                title={
                  <Fragment>
                    {t('ORDERS.EDIT_ORDER')}
                    <Stepper activeStep={activeStep} sx={{ margin: 2 }}>
                      <Step>
                        <StepLabel>
                          {t('REQUEST.GENERAL_INFORMATION')}
                        </StepLabel>
                      </Step>
                      <Step>
                        <StepLabel>{t('REQUEST.PRODUCTS')}</StepLabel>
                      </Step>
                    </Stepper>
                  </Fragment>
                }
                onClose={() => hide()}
              />
              <DialogContent>
                {activeStep === 0 && (
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Field
                        name="customerId"
                        component={Autocomplete}
                        options={mappedCustomers.map((customer) => customer.id)}
                        getOptionLabel={(option) =>
                          mappedCustomers.find(
                            (customer) => customer.id === option,
                          ).name
                        }
                        onChange={(event, value) => {
                          setFieldValue('customerId', value)
                          setSelectedCustomerId(value)
                          setFieldValue('customerUserId', null)
                        }}
                        disabled={values.tenderOrderId}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            // We have to manually set the corresponding fields on the input component
                            name="customerId"
                            error={
                              touched['customerId'] && !!errors['customerId']
                            }
                            helperText={errors['customerId']}
                            label={t('ORDER_REGISTRATION.CUSTOMER')}
                            variant="outlined"
                            margin="normal"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        label={t('ORDER_REGISTRATION.CUSTOMER_USER')}
                        disabled={values.tenderOrderId}
                        name={'customerUserId'}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        select
                      >
                        {customerUsers.map((customerUser) => (
                          <MenuItem
                            value={customerUser.id}
                            key={customerUser.id}
                          >
                            {customerUser.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="manufacturerId"
                        component={Autocomplete}
                        options={mappedManufacturers.map(
                          (manufacturer) => manufacturer.id,
                        )}
                        getOptionLabel={(option) => {
                          return mappedManufacturers.find(
                            (manufacturer) => manufacturer.id === option,
                          ).name
                        }}
                        onChange={(event, value) => {
                          setFieldValue('manufacturerId', value)
                          setSelectedManufacturerId(value)
                          setFieldValue('manufacturerUserId', null)
                        }}
                        renderInput={(params) => (
                          <MuiTextField
                            {...params}
                            // We have to manually set the corresponding fields on the input component
                            name="manufacturerId"
                            error={
                              touched['manufacturerId'] &&
                              !!errors['manufacturerId']
                            }
                            helperText={errors['manufacturerId']}
                            label={t('ORDER_REGISTRATION.MANUFACTURER')}
                            variant="outlined"
                            margin="normal"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        component={TextField}
                        label={t('ORDER_REGISTRATION.MANUFACTURER_USER')}
                        name={'manufacturerUserId'}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        select
                      >
                        {manufacturerUsers.map((manufacturerUser) => (
                          <MenuItem
                            value={manufacturerUser.id}
                            key={manufacturerUser.id}
                          >
                            {manufacturerUser.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={6}>
                      <DeliveryDetails
                        data={values}
                        readOnly={false}
                        isOrderRegistration={true}
                        setFieldValue={setFieldValue}
                        userId={values.customerUserId}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <PaymentDetails
                        data={values}
                        setFieldValue={setFieldValue}
                        readOnly={false}
                      />
                      <InvoiceAddress
                        partnerId={values.customerId}
                        data={values}
                        setFieldValue={setFieldValue}
                        userId={values.customerUserId}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="orderNumber"
                        fullWidth
                        label={t('OFFER.INTERNAL_ORDERNUMBER')}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name={'internalNote'}
                        fullWidth
                        margin="dense"
                        label={t('OFFER.INTERNAL_NOTE')}
                        variant="outlined"
                        multiline
                        rows="4"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        name="deliveryType"
                        select
                        fullWidth
                        label={t('REQUESTS.TENDER_ORDER.TYPE')}
                        variant="outlined"
                        margin="normal"
                        onChange={(e) => {
                          setFieldValue('deliveryType', e.target.value)
                          if (e.target.value === 'calendarWeek') {
                            setFieldValue('selectedDays', [])
                          } else {
                            setFieldValue('deliveryValue', null)
                          }
                        }}
                      >
                        <MenuItem value={'calendarWeek'} key={'calendarWeek'}>
                          {t('REQUESTS.TENDER_ORDER.CALENDARWEEK')}
                        </MenuItem>
                        <MenuItem value={'date'} key={'date'}>
                          {t('REQUESTS.TENDER_ORDER.DATE')}
                        </MenuItem>
                      </Field>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center">
                      {values.deliveryType === 'date' ? (
                        <FieldErrorWrapper
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              label: t('REQUESTS.TENDER_ORDER.DELIVERY_DATE'),
                              margin: 'dense',
                            },
                          }}
                          name="deliveryValue"
                          fullWidth
                          component={DatePicker}
                          inputFormat="DD/MM/YYYY"
                        />
                      ) : values.deliveryType === 'calendarWeek' ? (
                        <CalendarWeekPicker
                          selectedDays={values.selectedDays}
                          enablePast={true}
                          setSelectedDays={(days) => {
                            setFieldValue('selectedDays', days)
                          }}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                )}
                {activeStep === 1 && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <OrderRegistrationPositions
                        data={values}
                        errors={errors}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                  </Grid>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    if (activeStep === 0) {
                      hide()
                    } else {
                      setActiveStep((prevActiveStep) => prevActiveStep - 1)
                    }
                  }}
                  variant="outlined"
                  color="secondary"
                >
                  {activeStep === 0 ? t('GENERAL.CANCEL') : t('GENERAL.BACK')}
                </Button>
                <Button
                  onClick={() => {
                    if (valSchemas[activeStep]) {
                      valSchemas[activeStep]
                        .validate(values, {
                          abortEarly: false,
                          context: values,
                        })
                        .then(function (value) {
                          if (activeStep === 1) {
                            submitForm()
                          } else {
                            if (isValid) {
                              setActiveStep(
                                (prevActiveStep) => prevActiveStep + 1,
                              )
                            }
                          }
                        })
                        .catch((err) => {
                          let yupErrors = yupToFormErrors(err)
                          setErrors(yupErrors)
                          setTouched(getTouchedObj(yupErrors), false) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                        })
                    } else {
                      setActiveStep((prevActiveStep) => prevActiveStep + 1)
                    }
                  }}
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  {activeStep === 1
                    ? t('GENERAL.SUBMIT')
                    : t('GENERAL.CONTINUE')}
                </Button>
              </DialogActions>
              {showHasReplacedComponentsDialog && (
                <DeliveryDetailsReplacedComponentsDialog
                  setAddress={() => {
                    let replacedAddressComponents =
                      externalAdressValidationResult.address.addressComponents.filter(
                        (addressComponent) => addressComponent.replaced,
                      )

                    replacedAddressComponents.forEach((addressComponent) => {
                      switch (addressComponent.componentType) {
                        case 'route':
                        case 'street_number':
                          let adress =
                            externalAdressValidationResult.address.addressComponents.find(
                              (addressComponent) =>
                                addressComponent.componentType === 'route',
                            )
                          let streetNumber =
                            externalAdressValidationResult.address.addressComponents.find(
                              (addressComponent) =>
                                addressComponent.componentType ===
                                'streetNumber',
                            )
                          setFieldValue(
                            `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                              (location) =>
                                location.id ===
                                externalAdressValidationResult.locationId,
                            )}].address`,
                            `${adress} ${streetNumber}`,
                          )
                          break
                        case 'postal_code':
                          setFieldValue(
                            `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                              (location) =>
                                location.id ===
                                externalAdressValidationResult.locationId,
                            )}].zipcode`,
                            addressComponent.componentName.text,
                          )
                          break
                        case 'locality':
                          setFieldValue(
                            `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                              (location) =>
                                location.id ===
                                externalAdressValidationResult.locationId,
                            )}].place`,
                            addressComponent.componentName.text,
                          )
                          break
                        // case 'country':
                        //   break
                        default:
                          break
                      }
                      setFieldValue()
                    })
                  }}
                  addressValidationResult={externalAdressValidationResult}
                  hide={() => setShowHasReplacedComponentsDialog(false)}
                />
              )}
            </Dialog>
          </Form>
        )
      }}
    </Formik>
  ) : null
}

export default withTranslation()(connect()(EditOrderDialog))
