import { ButtonGroup, MenuItem, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import React from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { countryList } from '../../_constants/lookupConstants'
import { partnerLocationsService } from '../../_services/partnerLocationsService'
import CustomDialogTitle from '../CustomDialogTitle'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material'
import NumberFormatCustom from '../../_helpers/numberFormatCustom'
import DeliveryTimes from '../DeliveryTimes'
import GoogleAutocomplete from '../GoogleAutocomplete'
import { Loading } from '../Loading'

const PartnerLocationDialog = (props) => {
  const { t, hide, open } = props
  var userObject = JSON.parse(localStorage.getItem('user'))
  let partnerId = parseInt(userObject.partner.id)

  const [isLoading, setIsLoading] = React.useState(false)

  const valSchema = Yup.object().shape({
    company: Yup.string().required(t('GENERAL.REQUIRED')),
    address: Yup.string().required(t('GENERAL.REQUIRED')),
    zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
    place: Yup.string().required(t('GENERAL.REQUIRED')),
    countryId: Yup.number().required(t('GENERAL.REQUIRED')),
    deliveryInformations: Yup.string().nullable(),
    unload: Yup.string().required(),
    stackHeight: Yup.number().nullable(),
    stackHeightUnit: Yup.string().required(),
    isDespatchAdviceRequired: Yup.boolean().required(),
    despatchAdviceInstructions: Yup.string().nullable(),
  })

  let initialValues = {
    company: '',
    countryId: '',
    address: '',
    zipcode: '',
    place: '',
    deliveryInformations: '',
    unload: 'ANY',
    stackHeightUnit: 'QUANTITY',
    isDespatchAdviceRequired: false,
    despatchAdviceInstructions: '',
    deliveryTimes: [
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: true,
        start: 8,
        end: 16,
      },
      {
        isActive: false,
        start: null,
        end: null,
      },
      {
        isActive: false,
        start: null,
        end: null,
      },
    ],
  }

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={valSchema}
        //validateOnBlur={false}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setIsLoading(true)
          partnerLocationsService
            .createPartnerLocation({ ...values, partnerId: partnerId })
            .then(() => {
              resetForm(initialValues)
              hide()
            })
            .catch(function (error) {
              console.log(error)
            })
            .finally(() => {
              setSubmitting = false
              setIsLoading(false)
            })
        }}
      >
        {({ isSubmitting, submitForm, resetForm, setFieldValue, values }) => (
          <Dialog
            onClose={() => {
              resetForm(initialValues)
              hide()
            }}
            fullWidth={true}
            maxWidth="md"
            open={open}
          >
            <CustomDialogTitle
              title={t('PARTNER_LOCATION_DIALOG.CREATE_COMPANY_LOCATION')}
              onClose={() => {
                resetForm(initialValues)
                hide()
              }}
            />
            <DialogContent>
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      label={t('REQUEST.DELIVERY_DETAILS.COMPANY')}
                      name={'company'}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      label={t('REQUEST.DELIVERY_DETAILS.COUNTRY')}
                      name={'countryId'}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      select
                    >
                      {Object.entries(countryList).map((country) => (
                        <MenuItem value={country[1]} key={country[0]}>
                          {country[0]}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <GoogleAutocomplete
                      setFieldValue={setFieldValue}
                      formikName={'address'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      label={t('REQUEST.DELIVERY_DETAILS.ZIP_CODE')}
                      name={'zipcode'}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      component={TextField}
                      label={t('REQUEST.DELIVERY_DETAILS.PLACE')}
                      name={'place'}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {t('DELIVERY_DETAILS.POSSIBLE_LOADING')}
                      </Typography>
                      <ButtonGroup
                        color={'secondary'}
                        sx={{
                          height: '36.7px',
                          marginTop: '8px',
                        }}
                        fullWidth
                      >
                        <Button
                          variant={
                            values.unload === 'ANY' ? 'contained' : 'outlined'
                          }
                          onClick={() => setFieldValue('unload', 'ANY')}
                        >
                          {t('DELIVERY_DETAILS.ANY_UNLOAD')}
                        </Button>
                        <Button
                          variant={
                            values.unload === 'RAMP' ? 'contained' : 'outlined'
                          }
                          onClick={() => setFieldValue('unload', 'RAMP')}
                        >
                          {t('DELIVERY_DETAILS.RAMP')}
                        </Button>
                        <Button
                          variant={
                            values.unload === 'SIDE' ? 'contained' : 'outlined'
                          }
                          onClick={() => setFieldValue('unload', 'SIDE')}
                        >
                          {t('DELIVERY_DETAILS.SIDE')}
                        </Button>
                      </ButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Field
                            fullWidth={true}
                            component={TextField}
                            margin="dense"
                            size={'small'}
                            InputProps={{
                              inputComponent: NumberFormatCustom,
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            inputProps={{
                              decimalScale: 0,
                              allowNegative: false,
                            }}
                            label={`${t('DELIVERY_DETAILS.STACK_HEIGHT')} (${t(
                              'GENERAL.OPTIONAL',
                            )})`}
                            variant="outlined"
                            name={'stackHeight'}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <ButtonGroup
                            color={'secondary'}
                            sx={{
                              height: '36.7px',
                              marginTop: '8px',
                            }}
                            fullWidth
                          >
                            <Button
                              variant={
                                values.stackHeightUnit === 'QUANTITY'
                                  ? 'contained'
                                  : 'outlined'
                              }
                              onClick={() =>
                                setFieldValue('stackHeightUnit', 'QUANTITY')
                              }
                            >
                              {t('DELIVERY_DETAILS.PIECES')}
                            </Button>
                            <Button
                              variant={
                                values.stackHeightUnit === 'CM'
                                  ? 'contained'
                                  : 'outlined'
                              }
                              onClick={() =>
                                setFieldValue('stackHeightUnit', 'CM')
                              }
                            >
                              {t('DELIVERY_DETAILS.CENTIMETER')}
                            </Button>
                          </ButtonGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={12} style={{ alignSelf: 'center' }}>
                      <Typography variant={'subtitle1'}>
                        {t('DELIVERY_TIMES.LOADING_TIMES') + ':'}
                      </Typography>
                      <DeliveryTimes
                        data={values.deliveryTimes}
                        setFieldValue={setFieldValue}
                        formikName={'deliveryTimes'}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
                    </Typography>
                    <Grid container>
                      <Grid item xs={12}>
                        <Field
                          component={CheckboxWithLabel}
                          type={'checkbox'}
                          color="default"
                          name={`isDespatchAdviceRequired`}
                          Label={{
                            label: t(
                              'DELIVERY_DETAILS.IS_DESPATCH_ADVICE_REQUIRED',
                            ),
                          }}
                          style={{ marginLeft: '15px' }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          disabled={!values.isDespatchAdviceRequired}
                          name={`despatchAdviceInstructions`}
                          fullWidth
                          margin="dense"
                          label={t('DELIVERY_DETAILS.DESPATCH_ADVICE')}
                          variant="outlined"
                          multiline
                          rows="4"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {t('DELIVERY_DETAILS.OTHER_INFORMATIONS')}
                    </Typography>
                    <Field
                      component={TextField}
                      name={'deliveryInformations'}
                      fullWidth
                      margin="dense"
                      label={t(
                        'REQUEST.DELIVERY_DETAILS.ADDITIONAL_DELIVERY_INFORMATION',
                      )}
                      variant="outlined"
                      multiline
                      rows="4"
                    />
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  resetForm(initialValues)
                  hide()
                }}
              >
                {t('PARTNER_LOCATION_DIALOG.CANCEL')}
              </Button>
              <Button
                disabled={isSubmitting}
                onClick={() => submitForm()}
                variant="contained"
                color="secondary"
              >
                {t('PARTNER_LOCATION_DIALOG.SAVE')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    )
  )
}

export default withTranslation()(PartnerLocationDialog)
