import { Delete as DeleteIcon, Save as DownloadIcon } from '@mui/icons-material'
import { Grid, IconButton, List, ListItem, ListItemText } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import React, { Fragment } from 'react'
import Dropzone from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import { getPartnerOfLocalUser } from '../../../../../../_helpers/little'
import { documentsService } from '../../../../../../_services/documentsService'
import AgreementDialog from '../../../../../AgreementDialog'
import { Loading } from '../../../../../Loading'

const dropzoneStyle = {
  marginRight: '20px',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderWidth: '2px',
  borderRadius: '2px',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in -out',
}

const Documents = (props) => {
  const { t, documents, type, deleteDocument, forceUpdate } = props

  const [isLoading, setIsLoading] = React.useState(false)
  const [showDeleteDocumentDialog, setShowDeleteDocumentDialog] =
    React.useState(false)
  const [selectedDocument, setSelectedDocument] = React.useState(null)

  const partner = getPartnerOfLocalUser()
  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Fragment>
        <Grid container sapcing={1}>
          <Grid item xs={12} sm={6}>
            <h4>{t('DOCUMENTS.DOCUMENT_UPLOAD')}</h4>
            <Dropzone
              onDrop={(acceptedFiles) => {
                var uploadedDocuments = []
                if (acceptedFiles.length === 0) {
                  return
                }
                let fileUploadPromiseList = []
                acceptedFiles.forEach(function (document) {
                  var requestBody = new FormData()

                  requestBody.append('file', document)
                  requestBody.append('type', type)

                  var attachmentPromiseList = new Promise(function (
                    resolve,
                    reject,
                  ) {
                    setIsLoading(true)
                    documentsService
                      .createDocumentOfPartner(partner.id, requestBody)
                      .then(function (response) {
                        document = {
                          id: response.documentId,
                          name: document.name,
                          type: type,
                        }
                        uploadedDocuments.push(document)
                        resolve('DONE')
                      })
                      .catch(function (error) {
                        reject(error)
                      })
                      .finally(() => {
                        setIsLoading(false)
                      })
                  })

                  fileUploadPromiseList.push(attachmentPromiseList)
                })
                Promise.all(fileUploadPromiseList).then(function (v) {
                  Promise.resolve()
                  forceUpdate()
                })
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div
                  style={dropzoneStyle}
                  {...getRootProps({ className: 'dropzone' })}
                >
                  <input {...getInputProps()} />
                  <p>{t('DOCUMENTS.CLICK_HERE_OR_DRAG_FILES_HERE')}</p>
                </div>
              )}
            </Dropzone>
          </Grid>
          <Grid item xs={12} sm={6}>
            <aside>
              <h4>{t('DOCUMENTS.COMPANY_DOCUMENTS')}</h4>
              {documents.filter((document) => {
                return document.type === type
              }).length === 0
                ? t('DOCUMENTS.NO_UPLOAD_YET')
                : null}
              <List>
                {documents
                  .filter((document) => {
                    return document.type === type
                  })
                  .map((doc) => (
                    <ListItem key={doc.name}>
                      <ListItemText
                        primary={doc.name}
                        secondary={
                          <Fragment>
                            {!doc.new ? (
                              <Tooltip title={t('DOCUMENTS.DOWNLOAD')}>
                                <IconButton
                                  color="secondary"
                                  onClick={() => {
                                    documentsService
                                      .getDocument(doc)
                                      .then((response) => response.blob())
                                      .then((blob) => {
                                        // 2. Create blob link to download
                                        const url = window.URL.createObjectURL(
                                          new Blob([blob]),
                                        )
                                        const link = document.createElement('a')
                                        link.href = url
                                        link.setAttribute('download', doc.name)
                                        // 3. Append to html page
                                        document.body.appendChild(link)
                                        // 4. Force download
                                        link.click()
                                        // 5. Clean up and remove the link
                                        link.parentNode.removeChild(link)
                                      })
                                  }}
                                  size="large"
                                >
                                  <DownloadIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            <Tooltip title={t('DOCUMENTS.DELETE')}>
                              <IconButton
                                color="default"
                                onClick={() => {
                                  setShowDeleteDocumentDialog(true)
                                  setSelectedDocument(doc)
                                }}
                                size="large"
                              >
                                <DeleteIcon color="secondary" />
                              </IconButton>
                            </Tooltip>
                          </Fragment>
                        }
                      />
                    </ListItem>
                  ))}
              </List>
            </aside>
          </Grid>
        </Grid>
        <AgreementDialog
          open={showDeleteDocumentDialog}
          handleClose={() => {
            setShowDeleteDocumentDialog(false)
          }}
          agree={() => {
            setShowDeleteDocumentDialog(false)
            deleteDocument(selectedDocument)
          }}
        />
      </Fragment>
    )
  )
}

export default withTranslation()(Documents)
