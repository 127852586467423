import {
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Form, Formik, yupToFormErrors } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { alertActions } from '../../_actions/alertActions'
import {
  diffOfferWithOfferOrRequest,
  getTouchedObj,
  isOfferEqualToOfferOrRequest,
  validateLocation,
} from '../../_helpers/little'
import { offersService } from '../../_services/offersService'
import { requestsService } from '../../_services/requestsService'
import AgreementDialog from '../AgreementDialog'
import Deliveries from '../Deliveries'
import DeliveryDetailsReplacedComponentsDialog from '../DeliveryDetailsReplacedComponentsDialog'
import { InfoTooltip } from '../InfoTooltip'
import { Loading } from '../Loading'
import OfferAttachments from '../OfferAttachments'
import OfferPaymentDetails from '../OfferPaymentDetails'
import ScrollableFixedHeightContainer from '../ScrollableFixedHeightContainer'
import FormikEffect from './_components/FormikEffect'
import OfferDeliveryDetails from './_components/OfferDeliveryDetails'
import OfferDeliveryDetailsMultipleLocations from './_components/OfferDeliveryDetailsMultipleLocations'
import OfferDetails from './_components/OfferDetails'
import OfferOtherDetails from './_components/OfferOtherDetails'
import OfferRequestPositions from './_components/OfferRequestPositions'
import OfferTenderPositions from './_components/OfferTenderPositions'

const Offer = (props) => {
  const {
    offerId,
    t,
    requestId,
    cancelOffering,
    forceUpdate,
    dispatch,
    closeOfferDialog,
    mode,
    priceList = [],
  } = props

  const [offer, setOffer] = React.useState({})
  const [compOffer, setCompOffer] = React.useState({})
  const [isLoading, setIsLoading] = React.useState(true)
  const [activeStep, setActiveStep] = React.useState(0)
  const [showSendOfferDialog, setShowSendOfferDialog] = React.useState(false)

  const [showHasReplacedComponentsDialog, setShowHasReplacedComponentsDialog] =
    useState(false)
  const [externalAdressValidationResult, setExternalAdressValidationResult] =
    useState(false)

  const [geoData, setGeoData] = useState(null)

  const userObject = JSON.parse(localStorage.getItem('user'))
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    setIsLoading(true)
    if (offerId) {
      offersService
        .getOffer(offerId, userObject.partner.id)
        .then((data) => {
          setOffer({
            ...data,
            generalInformation: {
              ...data.generalInformation,
              otherDetails: {
                ...data.generalInformation.otherDetails,
                validUntil:
                  mode === 'EDIT'
                    ? moment(data.generalInformation.otherDetails.validUntil)
                    : moment().add(2, 'weeks'),
              },
            },
            deliveries: data.deliveries.map((delivery) => {
              return {
                ...delivery,
                value:
                  delivery.type === 'date'
                    ? moment(delivery.value)
                    : delivery.value,
              }
            }),
          })
          // set offer as a copy of data in order to have a hard copy which can be used for difference comparison
          setCompOffer(JSON.parse(JSON.stringify(data)))
        })
        .finally(() => setIsLoading(false))
    } else {
      requestsService
        .getRequest(requestId)
        .then((request) => {
          //generate offer positions by creating an array of all request positions and adding offer data
          var offerPositions = []
          request.generalInformation.deliveryDetails.locations.forEach(
            (location) => {
              location.positions.forEach((pos, index) => {
                offerPositions.push({
                  ...pos,
                  checked: pos.amount > 0 ? true : false,
                  price:
                    request.type === 'ADVERT'
                      ? pos.price
                      : priceList.length > 0
                      ? priceList[index]
                      : undefined,
                  requestPositionId: pos.id,
                  locationId: location.id,
                })
              })
            },
          )

          setOffer({
            generalInformation: {
              deliveryDetails: {
                incoTerm: request.generalInformation.deliveryDetails.incoTerm,
                locations:
                  request.generalInformation.deliveryDetails.locations.map(
                    (location) => {
                      const result = {
                        ...location,
                      }
                      delete result.positions
                      return result
                    },
                  ),
              },
              paymentDetails: request.generalInformation.paymentDetails,
              otherDetails: {
                validUntil: moment().add(2, 'weeks'),
                start: request.generalInformation.otherDetails.start,
                end: request.generalInformation.otherDetails.end,
              },
            },
            request: request,
            positions: _.cloneDeep(offerPositions), //Deep cloning needed. Otherwise the properties would just be references => This is a problem, since its used in formik. SetfieldValue then also changes the positions in the request
            deliveries: request.deliveries.map((delivery) => {
              return {
                ...delivery,
                value:
                  delivery.type === 'date'
                    ? moment(delivery.value)
                    : delivery.value,
              }
            }),
            attachments: [],
          })
        })
        .finally(() => setIsLoading(false))
    }
  }, [offerId, requestId])

  const valSchemas = [
    Yup.object().shape({
      generalInformation: Yup.object().shape({
        deliveryDetails: Yup.object().shape({
          locations: Yup.array()
            .of(
              Yup.object()
                .shape({
                  company: Yup.string()
                    .min(1, t('GENERAL.REQUIRED'))
                    .nullable(true),
                  address: Yup.string()
                    .min(1, t('GENERAL.REQUIRED'))
                    .nullable(true),
                  zipcode: Yup.string().required(t('GENERAL.REQUIRED')),
                  place: Yup.string().required(t('GENERAL.REQUIRED')),
                  countryId: Yup.number().required(t('GENERAL.REQUIRED')),
                  deliveryInformations: Yup.string().nullable(),
                })
                .test({
                  test: async function (location, context) {
                    let incoTerm = context.from[1].incoTerm //context.from[1] returns deliveryDetails
                    const isManufacturer =
                      offer.request.type === 'ADVERT'
                        ? offer.request.partnerId === userObject.partner.id
                        : offer.request.partnerId !== userObject.partner.id

                    if (
                      offer.request.type === 'TENDER' ||
                      (incoTerm === 'DDP' && isManufacturer) ||
                      (incoTerm === 'EXW' && !isManufacturer)
                    ) {
                      return true
                    }

                    return userObject.user.partnerLocationIds.length > 0
                      ? true
                      : await validateLocation(
                          location,
                          context,
                          `generalInformation.deliveryDetails.locations[${context.parent.findIndex(
                            (locationInValues) =>
                              locationInValues.id === location.id,
                          )}]`,
                          setExternalAdressValidationResult,
                          setShowHasReplacedComponentsDialog,
                          (validationResult) => {
                            setGeoData(validationResult.geocode.location)
                          },
                          this,
                          ['route', 'street_number'],
                        )
                  },
                }),
            )
            .min(1, t('GENERAL.MIN_1')),
        }),
        paymentDetails: Yup.object().shape({
          paymentTerm: Yup.number()
            .min(0, t('GENERAL.REQUIRED'))
            .required(t('GENERAL.REQUIRED')),
          skonto: Yup.number(),
          skontoPeriod: Yup.number().nullable(true),
        }),
        otherDetails: Yup.object().shape({
          validUntil: Yup.string()
            .typeError(t('GENERAL.REQUIRED'))
            .required(t('GENERAL.REQUIRED')),
        }),
      }),
    }),
    Yup.object().shape({
      positions: Yup.array()
        .of(
          Yup.object().shape({
            amount: Yup.mixed().when('checked', ([checked], schema) => {
              return checked
                ? Yup.number()
                    .required(t('GENERAL.REQUIRED'))
                    .typeError(t('GENERAL.REQUIRED'))
                    .min(0.1, t('GENERAL.GREATER_THAN_0'))
                    .typeError(t('GENERAL.REQUIRED'))
                : Yup.string().typeError(t('GENERAL.REQUIRED'))
            }),
            price: Yup.mixed().when('checked', ([checked], schema) => {
              return checked
                ? Yup.number()
                    .transform((currentValue, originalValue) =>
                      originalValue === '' ? null : currentValue,
                    )
                    .nullable(true)
                    .typeError(t('GENERAL.REQUIRED'))
                    .min(0.01, t('GENERAL.GREATER_THAN_0'))
                    .test({
                      message: t('GENERAL.REQUIRED'),
                      test: function (price) {
                        return (
                          price ||
                          this.parent.salesPrice ||
                          this.parent.purchasePrice
                        )
                      },
                    })
                : Yup.string().typeError(t('GENERAL.REQUIRED'))
            }),
            purchasePrice: Yup.mixed().when('checked', ([checked], schema) => {
              return checked
                ? Yup.number()
                    .transform((currentValue, originalValue) =>
                      originalValue === '' ? null : currentValue,
                    )
                    .nullable(true)
                    .typeError(t('GENERAL.REQUIRED'))
                    .min(0.01, t('GENERAL.GREATER_THAN_0'))
                    .test({
                      message: t('GENERAL.REQUIRED'),
                      test: function (purchasePrice) {
                        return this.parent.price || purchasePrice
                      },
                    })
                : Yup.string().typeError(t('GENERAL.REQUIRED'))
            }),
            salesPrice: Yup.string().when('checked', ([checked], schema) => {
              return checked
                ? Yup.number()
                    .transform((currentValue, originalValue) =>
                      originalValue === '' ? null : currentValue,
                    )
                    .nullable(true)
                    .typeError(t('GENERAL.REQUIRED'))
                    .min(0.01, t('GENERAL.GREATER_THAN_0'))
                    .test({
                      message: t('GENERAL.REQUIRED'),
                      test: function (salesPrice) {
                        return this.parent.price || salesPrice
                      },
                    })
                : Yup.string().typeError(t('GENERAL.REQUIRED'))
            }),
          }),
        )
        .test({
          message: t('GENERAL.MIN_1'),
          test: (positions) => {
            return positions.filter((pos) => pos.checked).length > 0
          },
        }),
    }),
    Yup.object().shape({
      deliveries: Yup.array().of(
        Yup.object()
          .shape({
            type: Yup.string().required(t('GENERAL.REQUIRED')),
            value: Yup.string(),
            obligatory: Yup.boolean(),
            positions: Yup.array()
              .of(
                Yup.object().shape({
                  positions: Yup.array()
                    .compact(function (v) {
                      return !v.checked
                    })
                    .of(
                      Yup.object().shape({
                        amount: Yup.string()
                          .required(t('GENERAL.REQUIRED'))
                          .min(0.1, t('GENERAL.GREATER_THAN_0')),
                        price: Yup.number()
                          .required(t('GENERAL.REQUIRED'))
                          .typeError(t('GENERAL.REQUIRED'))
                          .min(0.01, t('GENERAL.GREATER_THAN_0')),
                      }),
                    ),
                }),
              )
              .compact(function (v) {
                return v.amount === ''
              })
              .min(1, t('GENERAL.MIN_1')),
          })
          .test({
            message: t('REQUEST.ADD_DELIVERY_WIZARD.POS_AMOUNT_EXCEEDED'),
            test: function (delivery) {
              let result = true
              delivery.positions.forEach((dPos) => {
                const { context } = this.options
                var positionsAmount = parseFloat(
                  context.positions.filter((pos) => {
                    return pos.id === dPos.positionId
                  })[0].amount,
                )
                var otherDeliveriesAmount = 0
                context.deliveries.forEach((otherDelivery) => {
                  if (delivery.id === otherDelivery.id) {
                    return
                  }
                  otherDelivery.positions.forEach((dPos2) => {
                    if (dPos2.positionId === dPos.positionId) {
                      otherDeliveriesAmount += dPos2.amount
                        ? parseFloat(dPos2.amount)
                        : 0
                    }
                  })
                })
                result =
                  result &&
                  dPos.amount <=
                    positionsAmount - parseFloat(otherDeliveriesAmount)
              })
              return result
            },
          }),
      ),
    }),
  ]
  const [differences, setDifferences] = useState()
  const [offerEqual, setOfferEqual] = useState()
  const [diffObjectType, setDiffObjectType] = useState()

  return (
    <Fragment>
      {(isLoading && <Loading variant={'centered'} />) || (
        <Formik
          enableReinitialize={true}
          initialValues={offer}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting = true
            setIsLoading(true)

            let requestBody = {
              ...values,
              latitude: geoData ? geoData.latitude : undefined,
              longitude: geoData ? geoData.longitude : undefined,
            }

            if (mode === 'COUNTER') {
              offersService
                .counterOffer(requestBody, offerId)
                .catch(function (error) {
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                  cancelOffering()
                  forceUpdate()
                })
            } else if (mode === 'EDIT') {
              offersService
                .updateOffer(requestBody, offerId)
                .catch(function (error) {
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                  cancelOffering()
                  forceUpdate()
                })
            } else {
              offersService
                .createOffer(requestBody)
                .then((data) => {
                  let createOfferMessage = ''
                  if (
                    Object.keys(compOffer).length === 0 &&
                    offer.request.type === 'ADVERT'
                  ) {
                    createOfferMessage = t('OFFER.INTEREST_SHOWED')
                  } else {
                    createOfferMessage = t('OFFER.OFFER_CREATED')
                  }
                  closeOfferDialog(values)
                  dispatch(alertActions.info(createOfferMessage))
                  setTimeout(() => {
                    dispatch(alertActions.clear())
                  }, alertActions.alertTimeout)
                })
                .catch(function (error) {
                  console.log(error)
                })
                .finally(() => {
                  setSubmitting = false
                  setIsLoading(false)
                  forceUpdate()
                })
            }
          }}
        >
          {({
            values,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            errors,
            touched,
            submitForm,
            isSubmitting,
            setErrors,
            setTouched,
            isValid,
          }) => (
            <React.Fragment>
              <Form
                onKeyDown={(keyEvent) => {
                  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                    keyEvent.preventDefault()
                  }
                }}
              >
                <Stepper
                  activeStep={activeStep}
                  orientation={'horizontal'}
                  sx={{ margin: 2 }}
                >
                  {[
                    t('OFFER.GENERAL_INFORMATION'),
                    t('OFFER.PRODUCTS'),
                    offer.request.type !== 'TENDER' &&
                      t('OFFER.DATE_OF_DELIVERY'),
                    t('OFFER.ATTACHMENTS'),
                    t('OFFER.SUMMARY'),
                  ]
                    .filter(Boolean)
                    .map((label, index) => {
                      if (isMobile && index !== activeStep) {
                        return null
                      }
                      return (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      )
                    })}
                </Stepper>
                <ScrollableFixedHeightContainer
                  height={'70vh'}
                  toolbar={
                    <Fragment>
                      <Button
                        variant="outlined"
                        color="secondary"
                        style={{ margin: '0 10px 0px 10px' }}
                        onClick={() => {
                          if (activeStep === 0 && offerId) {
                            cancelOffering()
                          } else if (activeStep === 0 && !offerId) {
                            closeOfferDialog(values)
                          } else {
                            setActiveStep(
                              (prevActiveStep) => prevActiveStep - 1,
                            )
                          }
                        }}
                      >
                        {t('GENERAL.BACK')}
                      </Button>
                      {(activeStep === 3 && offer.request.type === 'TENDER') ||
                      (activeStep === 4 && offer.request.type !== 'TENDER') ? (
                        <InfoTooltip
                          title={
                            offerId && offerEqual
                              ? t(
                                  'OFFER.CANT_SEND_OFFER_SINCE_NO_DIFFERENCE_TO_THE_PREVIOUS_OFFER_WAS_DETECTED',
                                )
                              : ''
                          }
                        >
                          <div>
                            <Button
                              type="button"
                              onClick={async () => {
                                await setFieldValue('isInstant', false)
                                if (
                                  (offer.request.type !== 'ADVERT' &&
                                    offer.request.partnerId ===
                                      userObject.partner.id) ||
                                  (offer.request.type === 'ADVERT' &&
                                    offer.request.partnerId !==
                                      userObject.partner.id)
                                ) {
                                  submitForm()
                                } else {
                                  setShowSendOfferDialog(true)
                                }
                              }}
                              variant="contained"
                              color="secondary"
                              style={{ margin: '0 10px 0px 10px' }}
                              disabled={isSubmitting || (offerId && offerEqual)}
                            >
                              {offer.request.type === 'ADVERT' &&
                              Object.keys(compOffer).length === 0
                                ? t('OFFERS.SHOW_INTEREST')
                                : t('OFFERS.SEND_OFFER')}
                            </Button>
                          </div>
                        </InfoTooltip>
                      ) : (
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ margin: '0 10px 0px 10px' }}
                          onClick={() => {
                            if (valSchemas[activeStep]) {
                              valSchemas[activeStep]
                                .validate(values, {
                                  abortEarly: false,
                                  context: values,
                                })
                                .then(function (value) {
                                  setActiveStep(
                                    (prevActiveStep) => prevActiveStep + 1,
                                  )
                                })
                                .catch((err) => {
                                  let yupErrors = yupToFormErrors(err)
                                  setErrors(yupErrors)
                                  setTouched(getTouchedObj(yupErrors), false) //Dynamic Fields cant get touched https://github.com/formium/formik/issues/503
                                })
                            } else {
                              setActiveStep(
                                (prevActiveStep) => prevActiveStep + 1,
                              )
                            }
                          }}
                        >
                          {t('OFFER.CONTINUE')}
                        </Button>
                      )}
                    </Fragment>
                  }
                >
                  <FormikEffect
                    onChange={(currentFormikState, nextFormikState) => {
                      var rhsObj = offer.request
                      var rhsObjType = 'REQUEST'
                      if (offerId) {
                        rhsObj = compOffer
                        rhsObjType = 'OFFER'
                      }
                      var nextDifferences = diffOfferWithOfferOrRequest(
                        currentFormikState.values,
                        rhsObj,
                        rhsObjType,
                      )

                      var nextEqual = isOfferEqualToOfferOrRequest(
                        currentFormikState.values,
                        rhsObj,
                        rhsObjType,
                      )
                      if (
                        !_.isEqual(differences, nextDifferences) ||
                        diffObjectType !== rhsObjType
                      ) {
                        setDiffObjectType(rhsObjType)
                        setDifferences(nextDifferences)
                        setOfferEqual(nextEqual)
                      }
                    }}
                  />
                  {differences && (
                    <Grid container spacing={1}>
                      {activeStep === 0 && (
                        <Grid item xs={12}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} lg={6}>
                              {offer.request.type === 'TENDER' ? (
                                <OfferDeliveryDetailsMultipleLocations
                                  data={values}
                                />
                              ) : (
                                <OfferDeliveryDetails
                                  differences={
                                    differences.generalInformation
                                      .deliveryDetails
                                  }
                                  diffData={
                                    offer.ancestor
                                      ? offer.ancestor
                                      : offer.request
                                  }
                                  diffObjectType={diffObjectType}
                                  offer={offer}
                                  data={values}
                                  setFieldValue={setFieldValue}
                                />
                              )}
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <OfferPaymentDetails
                                data={values}
                                allPaymentTermsAllowed={
                                  offer.request.allPaymentTermsAllowed
                                }
                                differences={
                                  differences.generalInformation.paymentDetails
                                }
                                diffObjectType={diffObjectType}
                                setFieldValue={setFieldValue}
                              />
                              <OfferOtherDetails
                                data={values}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                errors={errors}
                                touched={touched}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}

                      {activeStep === 1 && (
                        <Grid item xs={12}>
                          {offer.request.type !== 'TENDER' ? (
                            <OfferRequestPositions
                              data={values}
                              errors={errors}
                              touched={touched}
                              setFieldError={setFieldError}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              //TODO: errors sauber implementieren in dieser form
                              differences={differences.positions}
                              diffData={
                                offer.ancestor ? offer.ancestor : offer.request
                              }
                              diffObjectType={diffObjectType}
                            />
                          ) : (
                            <OfferTenderPositions
                              data={values}
                              errors={errors}
                              setFieldError={setFieldError}
                              setFieldValue={setFieldValue}
                              differences={differences.positions}
                              diffData={
                                offer.ancestor ? offer.ancestor : offer.request
                              }
                              diffObjectType={diffObjectType}
                            />
                          )}
                        </Grid>
                      )}

                      {activeStep === 2 && offer.request.type !== 'TENDER' && (
                        <Grid item xs={12}>
                          <Deliveries
                            data={values}
                            setFieldValue={setFieldValue}
                            readOnly={false}
                            errors={errors}
                            positions={values.positions}
                            type={'OFFER'}
                          />
                        </Grid>
                      )}

                      {((activeStep === 2 && offer.request.type === 'TENDER') ||
                        (activeStep === 3 &&
                          offer.request.type !== 'TENDER')) && (
                        <Grid item xs={12}>
                          <OfferAttachments
                            data={values}
                            mode={mode}
                            setFieldValue={setFieldValue}
                          />
                        </Grid>
                      )}

                      {((activeStep === 3 && offer.request.type === 'TENDER') ||
                        (activeStep === 4 &&
                          offer.request.type !== 'TENDER')) && (
                        <Grid item xs={12}>
                          <OfferDetails
                            data={values}
                            differences={differences}
                            diffData={
                              offer.ancestor ? offer.ancestor : offer.request
                            }
                            diffObjectType={diffObjectType}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                  <AgreementDialog
                    title={t('OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_0')}
                    acceptButtonText={t('OFFERS.YES')}
                    declineButtonText={t('OFFERS.NO')}
                    message={
                      !values.isInstant ? (
                        <Fragment>
                          <p>
                            {t(
                              'OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_1',
                            )}
                          </p>
                          <p>
                            {t(
                              'OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_2',
                            )}
                          </p>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <p>
                            {t(
                              'OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_INSTANT_1',
                            )}
                          </p>
                          <p>
                            {t(
                              'OFFERS.MANUFACTURER_LEGAL_BINDING_INFORMATION_INSTANT_2',
                            )}
                          </p>
                        </Fragment>
                      )
                    }
                    open={showSendOfferDialog}
                    handleClose={() => {
                      setShowSendOfferDialog(false)
                    }}
                    agree={() => {
                      if (isSubmitting) {
                        return
                      }
                      setShowSendOfferDialog(false)
                      submitForm()
                    }}
                  />
                </ScrollableFixedHeightContainer>
                {showHasReplacedComponentsDialog && (
                  <DeliveryDetailsReplacedComponentsDialog
                    setAddress={() => {
                      let replacedAddressComponents =
                        externalAdressValidationResult.address.addressComponents.filter(
                          (addressComponent) => addressComponent.replaced,
                        )

                      replacedAddressComponents.forEach((addressComponent) => {
                        switch (addressComponent.componentType) {
                          case 'route':
                          case 'street_number':
                            let adress =
                              externalAdressValidationResult.address.addressComponents.find(
                                (addressComponent) =>
                                  addressComponent.componentType === 'route',
                              )
                            let streetNumber =
                              externalAdressValidationResult.address.addressComponents.find(
                                (addressComponent) =>
                                  addressComponent.componentType ===
                                  'streetNumber',
                              )
                            setFieldValue(
                              `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                                (location) =>
                                  location.id ===
                                  externalAdressValidationResult.locationId,
                              )}].address`,
                              `${adress} ${streetNumber}`,
                            )
                            break
                          case 'postal_code':
                            setFieldValue(
                              `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                                (location) =>
                                  location.id ===
                                  externalAdressValidationResult.locationId,
                              )}].zipcode`,
                              addressComponent.componentName.text,
                            )
                            break
                          case 'locality':
                            setFieldValue(
                              `generalInformation.deliveryDetails.locations[${values.generalInformation.deliveryDetails.locations.findIndex(
                                (location) =>
                                  location.id ===
                                  externalAdressValidationResult.locationId,
                              )}].place`,
                              addressComponent.componentName.text,
                            )
                            break
                          // case 'country':
                          //   break
                          default:
                            break
                        }
                        setFieldValue()
                      })
                    }}
                    addressValidationResult={externalAdressValidationResult}
                    hide={() => setShowHasReplacedComponentsDialog(false)}
                  />
                )}
              </Form>
            </React.Fragment>
          )}
        </Formik>
      )}
    </Fragment>
  )
}

export default withTranslation()(connect()(Offer))
