import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api'
import { authHeader } from '../_helpers/auth-header'

export const countriesService = {
  getCountries,
  getCountry,
}

function getCountries() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/countries`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}

function getCountry(countryId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  }

  return fetch(`${config.apiUrlBase}/countries/${countryId}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data
    })
}
export * from './countriesService'
