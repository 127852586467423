import { Autocomplete, TextField } from '@mui/material'
import React from 'react'

const FilterAutocomplete = (props) => {
  const { filterHandler, filter, setFilterHandler, lookup } = props
  let index = filterHandler.findIndex((handler) => handler.key === filter.key)

  const [inputValue, setInputValue] = React.useState('')

  return (
    <Autocomplete
      value={filterHandler[index].value ? filterHandler[index].value : ''}
      onChange={(event, newValue) => {
        let newFilterHandler = [...filterHandler]
        newFilterHandler[index].value = newValue
        setFilterHandler(newFilterHandler)
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      size={'small'}
      disablePortal
      options={filter.options}
      getOptionLabel={(option) => {
        if (option) {
          return lookup ? lookup[option] : option
        } else {
          return ''
        }
      }}
      filterOptions={(options) => {
        return options.filter((option) =>
          lookup
            ? lookup[option].toLowerCase().includes(inputValue.toLowerCase())
            : option.toLowerCase().includes(inputValue.toLowerCase()),
        )
      }}
      sx={{ width: '100%' }}
      renderInput={(params) => <TextField {...params} label={filter.label} />}
    />
  )
}

export default FilterAutocomplete
