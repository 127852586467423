import MaterialTable from '@material-table/core'
import { Delete } from '@mui/icons-material'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { DatePicker } from 'formik-mui-x-date-pickers'
import moment from 'moment'
import numeral from 'numeral'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  countrycodeLookup,
  invoicingTypeLookup,
} from '../../../../_constants/lookupConstants'
import { tableIcons, tableLocalization } from '../../../../_helpers/muiTable'
import { round } from '../../../../_helpers/number-formatting'
import NumberFormatCustom from '../../../../_helpers/numberFormatCustom'
import { invoicesService } from '../../../../_services/invoicesService'
import CustomDialogTitle from '../../../CustomDialogTitle'
import { FieldErrorWrapper } from '../../../FieldErrorWrapper'
import { Loading } from '../../../Loading'

const InvoicingCreateInitialDialog = (props) => {
  const {
    t,
    deliveryNote,
    refInvoiceNumber,
    refInvoiceDate,
    show,
    hide,
    mode,
    forceUpdate,
  } = props

  const [initialValues, setInitialValues] = useState()

  useEffect(() => {
    if (deliveryNote.id && mode) {
      let invoiceMode = mode === 'initial' ? 'invoice' : mode
      invoicesService
        .getInvoiceDraft(
          deliveryNote.id,
          invoiceMode,
          refInvoiceNumber,
          refInvoiceDate,
        )
        .then((data) => {
          data.invoice.date_of_supply = moment(
            data.invoice.date_of_supply,
            'DD.MM.YYYY',
          )
          setInitialValues({
            invoiceNumber: '',
            invoiceDate: moment(),
            bb_data: data,
            invoices: [],
          })
          setIsLoading(false)
        })
    }
  }, [deliveryNote.id, refInvoiceNumber, refInvoiceDate, mode])

  const [isLoading, setIsLoading] = React.useState(true)

  const valSchema = Yup.object().shape({
    inboundInvoiceNumber: Yup.string()
      .nullable()
      .test('required', t('GENERAL.REQUIRED'), function (inboundInvoiceNumber) {
        return (
          (mode === 'initial' && inboundInvoiceNumber?.length > 0) ||
          mode !== 'initial'
        )
      }),
    invoiceDate: Yup.date()
      .typeError(t('GENERAL.INVALID_DATE'))
      .required(t('GENERAL.REQUIRED')),
    bb_data: Yup.object().shape({
      invoice: Yup.object().shape({
        company_name: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        contact_person_name: Yup.string().nullable(),
        street: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        additional_addressline: Yup.string().nullable(),
        zip: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        city: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        country: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        date_of_supply: Yup.date()
          .typeError(t('GENERAL.INVALID_DATE'))
          .required(t('GENERAL.REQUIRED')),
        payment_conditions: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        email: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        correspondence: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        final_provisions: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        item_amount: Yup.array().of(Yup.string()),
        item_single_price: Yup.array().of(Yup.string()),
      }),
    }),
    invoices: Yup.array().of(
      Yup.object().shape({
        invoiceNumber: Yup.string().nullable(),
        description: Yup.mixed().when(
          ['direction', 'type'],
          ([direction, type], schema) => {
            return direction === 'OUTBOUND' && type === 'OTHER'
              ? Yup.string()
                  .typeError(t('GENERAL.REQUIRED'))
                  .required(t('GENERAL.REQUIRED'))
              : Yup.mixed().nullable(true)
          },
        ),
        direction: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        type: Yup.string()
          .typeError(t('GENERAL.REQUIRED'))
          .required(t('GENERAL.REQUIRED')),
        price: Yup.number()
          .required(t('GENERAL.REQUIRED'))
          .typeError(t('GENERAL.REQUIRED'))
          .min(0.1, t('GENERAL.GREATER_THAN_0')),
        comment: Yup.string().nullable(),
      }),
    ),
  })

  return (
    (isLoading && <Loading variant={'centered'} />) || (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={valSchema}
        //validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting = true

          let totalPurchasePrice = 0

          let totalSalesPrice = values.bb_data.invoice.item_single_price
            .map(
              (price, index) =>
                parseFloat(price) *
                parseFloat(values.bb_data.invoice.item_amount[index]),
            )
            .reduce((a, b) => a + b, 0)

          deliveryNote.positions.forEach((dPos) => {
            totalPurchasePrice +=
              deliveryNote.order.positions.find((orderPosition) => {
                return orderPosition.id === dPos.orderPositionId
              }).purchasePrice * dPos.amount
          })

          let invoiceDate = moment(values.invoiceDate)

          invoiceDate = invoiceDate.format('YYYY-MM-DD')
          values.bb_data.invoice.date = invoiceDate
          values.bb_data.invoice.date_of_supply =
            values.bb_data.invoice.date_of_supply.format('YYYY-MM-DD')

          let invoices = []
          if (mode === 'initial') {
            invoices = values.invoices.concat([
              {
                invoiceNumber: values.outboundInvoiceNumber,
                date: invoiceDate,
                price: totalSalesPrice,
                type: 'POSITION',
                direction: 'OUTBOUND',
                data: values.bb_data,
              },
              {
                invoiceNumber: values.inboundInvoiceNumber,
                price: totalPurchasePrice,
                type: 'POSITION',
                direction: 'INBOUND',
              },
            ])
          } else {
            invoices = [
              {
                invoiceNumber: null,
                date: invoiceDate,
                price: totalSalesPrice,
                type: 'POSITION',
                direction: 'OUTBOUND',
                data: values.bb_data,
              },
            ]
          }

          invoicesService
            .createInvoices(
              invoices.map((invoice) => {
                return {
                  ...invoice,
                  deliveryNoteId: deliveryNote.id,
                  orderId: deliveryNote.order.id,
                }
              }),
            )
            .then((data) => {
              hide()
              forceUpdate()
            })
            .catch(function (error) {
              console.log(error)
            })
            .finally(() => {
              setSubmitting = false
              setIsLoading(true)
            })
        }}
      >
        {({
          values,
          setFieldValue,
          isSubmitting,
          submitForm,
          isValid,
          errors,
          touched,
        }) => (
          <Dialog fullWidth={true} maxWidth="xl" open={show}>
            <CustomDialogTitle
              title={t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.TITLE')}
              onClose={() => hide()}
            />
            <DialogContent>
              <Form>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  {values.bb_data &&
                    values.bb_data.invoice.result !== 'ERROR' &&
                    values.bb_data.posting.result !== 'ERROR' &&
                    !values.bb_data.invoice.email && (
                      <Grid item xs={12}>
                        <Alert severity="error">
                          {t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.NO_EMAIL')}
                        </Alert>
                      </Grid>
                    )}
                  {values.bb_data &&
                    values.bb_data.invoice.result !== 'ERROR' &&
                    values.bb_data.posting.result !== 'ERROR' &&
                    !values.bb_data.posting.debtor && (
                      <Grid item xs={12}>
                        <Alert severity="error">
                          {t(
                            'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.NO_DEBTOR',
                          )}
                        </Alert>
                      </Grid>
                    )}
                  {values.bb_data &&
                    values.bb_data.invoice.result !== 'ERROR' &&
                    values.bb_data.posting.result !== 'ERROR' &&
                    deliveryNote.order.invoiceTaxId.trim().substring(0, 2) !==
                      countrycodeLookup[deliveryNote.order.countryId] && (
                      <Grid item xs={12}>
                        <Alert severity="warning">
                          {t(
                            'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.TAX_ID_MISMATCH',
                          )}
                        </Alert>
                      </Grid>
                    )}
                  {values.bb_data &&
                    (values.bb_data.invoice.result === 'ERROR' ||
                      values.bb_data.posting.result === 'ERROR') && (
                      <Grid item xs={12}>
                        <Alert severity="error">
                          {t(
                            'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.BUSINESS_CASE_NOT_HANDLED',
                          )}
                        </Alert>
                      </Grid>
                    )}
                  {values.bb_data &&
                    values.bb_data.invoice.result !== 'ERROR' &&
                    values.bb_data.posting.result !== 'ERROR' && (
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={4}>
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              size="small"
                              label={t('INVOICE_DRAFT.COMPANY_NAME')}
                              variant="outlined"
                              name="bb_data.invoice.company_name"
                            />
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              size="small"
                              label={t('INVOICE_DRAFT.CONTACT_PERSON_NAME')}
                              variant="outlined"
                              name="bb_data.invoice.contact_person_name"
                            />
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              size="small"
                              label={t('INVOICE_DRAFT.STREET')}
                              variant="outlined"
                              name="bb_data.invoice.street"
                            />
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              size="small"
                              label={t('INVOICE_DRAFT.ADDITIONAL_ADDRESSLINE')}
                              variant="outlined"
                              name="bb_data.invoice.additional_addressline"
                            />
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={4}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  margin="dense"
                                  size="small"
                                  label={t('INVOICE_DRAFT.ZIP')}
                                  variant="outlined"
                                  name="bb_data.invoice.zip"
                                />
                              </Grid>
                              <Grid item xs={12} sm={8}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  margin="dense"
                                  size="small"
                                  label={t('INVOICE_DRAFT.CITY')}
                                  variant="outlined"
                                  name="bb_data.invoice.city"
                                />
                              </Grid>
                            </Grid>
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              size="small"
                              label={t('INVOICE_DRAFT.COUNTRY')}
                              variant="outlined"
                              name="bb_data.invoice.country"
                            />
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            sm={8}
                            spacing={1}
                            alignItems={'flex-start'}
                          >
                            <Grid item xs={12} sm={4}>
                              <FieldErrorWrapper
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    label: t('INVOICE_DRAFT.INVOICE_DATE'),
                                    margin: 'dense',
                                    size: 'small',
                                  },
                                }}
                                component={DatePicker}
                                name="invoiceDate"
                                inputFormat="DD.MM.YYYY"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <FieldErrorWrapper
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    label: t('INVOICE_DRAFT.DATE_OF_SUPPLY'),
                                    margin: 'dense',
                                    size: 'small',
                                  },
                                }}
                                name="bb_data.invoice.date_of_supply"
                                component={DatePicker}
                                inputFormat="DD.MM.YYYY"
                              />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Field
                                component={TextField}
                                fullWidth
                                margin="dense"
                                size="small"
                                label={t('INVOICE_DRAFT.PAYMENT_CONDITIONS')}
                                variant="outlined"
                                name="bb_data.invoice.payment_conditions"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Field
                                component={TextField}
                                fullWidth
                                margin="dense"
                                size="small"
                                label={t('INVOICE_DRAFT.EMAIL')}
                                variant="outlined"
                                name="bb_data.invoice.email"
                              />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                              <MaterialTable
                                icons={tableIcons}
                                localization={tableLocalization()}
                                columns={[
                                  {
                                    title: t('INVOICE_DRAFT.DEBTOR'),
                                    field: 'debtor',
                                    cellStyle: { whiteSpace: 'nowrap' },
                                  },
                                  {
                                    title: t('INVOICE_DRAFT.POSTING_ACCOUNT'),
                                    field: 'postingaccounts',
                                  },
                                  {
                                    title: t('INVOICE_DRAFT.COST_LOCATION'),
                                    field: 'cost_locations',
                                  },
                                  {
                                    title: t('INVOICE_DRAFT.VAT'),
                                    field: 'vats',
                                  },
                                ]}
                                data={[
                                  {
                                    debtor: values.bb_data.posting.debtor,
                                    postingaccounts:
                                      values.bb_data.posting.postingaccounts.join(
                                        ', ',
                                      ),
                                    cost_locations:
                                      values.bb_data.posting.cost_locations?.join(
                                        ', ',
                                      ),
                                    vats: values.bb_data.posting.vats.join(
                                      ', ',
                                    ),
                                  },
                                ]}
                                style={{
                                  boxShadow: 'none',
                                  marginLeft: '20px',
                                  marginRight: '20px',
                                }}
                                options={{
                                  toolbar: false,
                                  showTitle: false,
                                  search: false,
                                  paging: false,
                                  filtering: false,
                                  emptyRowsWhenPaging: false,
                                  padding: 'dense',
                                  actionsColumnIndex: -1,
                                  headerStyle: {
                                    fontWeight: 'bold',
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              multiline
                              rows={5}
                              fullWidth
                              margin="dense"
                              size="small"
                              label={t('INVOICE_DRAFT.CORRESPONDENCE')}
                              variant="outlined"
                              name="bb_data.invoice.correspondence"
                            />
                          </Grid>
                          <Grid
                            style={{ marginTop: 20 }}
                            container
                            item
                            xs={12}
                          >
                            <Grid item xs={5}>
                              <Typography>
                                {t('INVOICE_DRAFT.ITEM_NAME')}
                              </Typography>
                            </Grid>
                            <Grid container item xs={7}>
                              <Grid item xs={3}>
                                <Typography>
                                  {t('INVOICE_DRAFT.ITEM_AMOUNT')}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography>
                                  {t('INVOICE_DRAFT.ITEM_UNIT')}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography>
                                  {t('INVOICE_DRAFT.ITEM_VAT')}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography>
                                  {t('INVOICE_DRAFT.ITEM_SINGLE_PRICE')}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          {values.bb_data.invoice.item_name.map(
                            (name, index) => (
                              <Grid
                                container
                                item
                                xs={12}
                                alignItems={'center'}
                              >
                                <Grid item xs={5}>
                                  <Typography>{name}</Typography>
                                  <Typography variant="body2">
                                    {
                                      values.bb_data.invoice.item_description[
                                        index
                                      ]
                                    }
                                  </Typography>
                                </Grid>
                                <Grid
                                  container
                                  item
                                  xs={7}
                                  alignItems={'center'}
                                >
                                  <Grid item xs={3}>
                                    <Field
                                      component={TextField}
                                      fullWidth
                                      margin="dense"
                                      variant="standard"
                                      size="small"
                                      name={`bb_data.invoice.item_amount[${index}]`}
                                      style={{ paddingRight: 60 }}
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                      // eslint-disable-next-line react/jsx-no-duplicate-props
                                      inputProps={{
                                        inputComponent: NumberFormatCustom,
                                        decimalScale: 1,
                                        allowNegative: true,
                                        fixedDecimalScale: false,
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography>
                                      {values.bb_data.invoice.item_unit[index]}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography>
                                      {values.bb_data.invoice.item_vat[index] +
                                        ' %'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Field
                                      component={TextField}
                                      fullWidth
                                      margin="dense"
                                      variant="standard"
                                      size="small"
                                      name={`bb_data.invoice.item_single_price[${index}]`}
                                      style={{ paddingRight: 60 }}
                                      InputProps={{
                                        inputComponent: NumberFormatCustom,
                                      }}
                                      // eslint-disable-next-line react/jsx-no-duplicate-props
                                      inputProps={{
                                        inputComponent: NumberFormatCustom,
                                        decimalScale: 2,
                                        allowNegative: true,
                                        suffix: ' \u20AC',
                                        fixedDecimalScale: true,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            ),
                          )}
                          <Grid item xs={5}></Grid>
                          <Grid container item xs={7}>
                            <Grid item xs={6} />
                            <Grid item xs={3}>
                              <Typography textAlign={'right'}>
                                Nettobetrag:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              textAlign={'right'}
                              paddingRight={10}
                            >
                              {numeral(
                                values.bb_data.invoice.item_single_price
                                  .map(
                                    (price, index) =>
                                      parseFloat(price) *
                                      parseFloat(
                                        values.bb_data.invoice.item_amount[
                                          index
                                        ],
                                      ),
                                  )
                                  .reduce((a, b) => a + b, 0),
                              ).format('0,0.00 $')}
                            </Grid>
                          </Grid>
                          <Grid item xs={5}></Grid>
                          <Grid container item xs={7}>
                            <Grid item xs={6} />
                            <Grid item xs={3}>
                              <Typography textAlign={'right'}>
                                Steuer:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              textAlign={'right'}
                              paddingRight={10}
                            >
                              {numeral(
                                round(
                                  values.bb_data.invoice.item_single_price
                                    .map((price, index) => {
                                      return (
                                        parseFloat(price) *
                                        parseFloat(
                                          values.bb_data.invoice.item_amount[
                                            index
                                          ],
                                        ) *
                                        (parseFloat(
                                          values.bb_data.invoice.item_vat[
                                            index
                                          ],
                                        ) /
                                          100)
                                      )
                                    })
                                    .reduce((a, b) => {
                                      return a + b
                                    }, 0),
                                  2,
                                ),
                              ).format('0,0.00 $')}
                            </Grid>
                          </Grid>
                          <Grid item xs={5}></Grid>
                          <Grid container item xs={7}>
                            <Grid item xs={6} />
                            <Grid item xs={3}>
                              <Typography textAlign={'right'}>
                                Bruttobetrag:
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={3}
                              textAlign={'right'}
                              paddingRight={10}
                            >
                              {numeral(
                                round(
                                  values.bb_data.invoice.item_single_price
                                    .map(
                                      (price, index) =>
                                        parseFloat(price) *
                                        parseFloat(
                                          values.bb_data.invoice.item_amount[
                                            index
                                          ],
                                        ) *
                                        (1 +
                                          parseFloat(
                                            values.bb_data.invoice.item_vat[
                                              index
                                            ],
                                          ) /
                                            100),
                                    )
                                    .reduce((a, b) => a + b, 0),
                                  2,
                                ),
                              ).format('0,0.00 $')}
                            </Grid>
                          </Grid>
                          <Grid container style={{ marginBottom: 20 }} />
                          <Grid item xs={12}>
                            <Typography style={{ whiteSpace: 'pre-wrap' }}>
                              <Field
                                component={TextField}
                                multiline
                                rows={8}
                                fullWidth
                                margin="dense"
                                size="small"
                                label={t('INVOICE_DRAFT.FINAL_PROVISIONS')}
                                variant="outlined"
                                name="bb_data.invoice.final_provisions"
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  {mode === 'initial' && (
                    <Fragment>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t(
                            'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.INBOUND_INVOICE_NUMBER',
                          )}
                          variant="outlined"
                          size="small"
                          name="inboundInvoiceNumber"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          fullWidth
                          margin="dense"
                          label={t(
                            'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.OUTBOUND_INVOICE_NUMBER',
                          )}
                          variant="outlined"
                          size="small"
                          name="outboundInvoiceNumber"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ margin: 1 }} />
                        <Button
                          variant={'contained'}
                          color={'secondary'}
                          onClick={() =>
                            setFieldValue(
                              'invoices',
                              values.invoices.concat([
                                {
                                  invoiceNumber: '',
                                  price: '',
                                  type: 'PROVISION',
                                  direction: 'INBOUND',
                                  comment: '',
                                },
                              ]),
                            )
                          }
                        >
                          {t(
                            'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.ADD_INVOICE',
                          )}
                        </Button>
                      </Grid>

                      {values.invoices.map((invoice, index) => (
                        <Fragment>
                          <Grid item xs={1.5}>
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t(
                                'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.INVOICE_NUMBER',
                              )}
                              variant="outlined"
                              name={`invoices[${index}].invoiceNumber`}
                              disabled={
                                values.invoices[index].direction === 'OUTBOUND'
                              }
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Field
                              fullWidth={true}
                              component={TextField}
                              margin="dense"
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              inputProps={{
                                inputComponent: NumberFormatCustom,
                                decimalScale: 2,
                                allowNegative: false,
                                suffix: ' \u20AC',
                                fixedDecimalScale: true,
                              }}
                              label={t(
                                'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.PRICE',
                              )}
                              variant="outlined"
                              name={`invoices[${index}].price`}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Field
                              component={TextField}
                              select
                              fullWidth
                              margin="dense"
                              label={t(
                                'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.DIRECTION',
                              )}
                              variant="outlined"
                              name={`invoices[${index}].direction`}
                              onChange={(e) => {
                                setFieldValue(
                                  `invoices[${index}].direction`,
                                  e.target.value,
                                )
                                setFieldValue(
                                  `invoices[${index}].invoiceNumber`,
                                  '',
                                )
                              }}
                            >
                              <MenuItem key="INBOUND" value="INBOUND">
                                {t(
                                  'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.INBOUND',
                                )}
                              </MenuItem>
                              <MenuItem key="OUTBOUND" value="OUTBOUND">
                                {t(
                                  'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.OUTBOUND',
                                )}
                              </MenuItem>
                            </Field>
                          </Grid>
                          <Grid item xs={2}>
                            <Field
                              component={TextField}
                              select
                              fullWidth
                              margin="dense"
                              label={t(
                                'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.TYPE',
                              )}
                              variant="outlined"
                              name={`invoices[${index}].type`}
                            >
                              {Object.entries(invoicingTypeLookup)
                                .filter(
                                  (invoiceType) =>
                                    invoiceType[0] !== 'POSITION',
                                )
                                .map((invoiceType) => (
                                  <MenuItem
                                    key={invoiceType[0]}
                                    value={invoiceType[0]}
                                  >
                                    {invoiceType[1]}
                                  </MenuItem>
                                ))}
                            </Field>
                          </Grid>
                          {values.invoices[index].type === 'OTHER' &&
                            values.invoices[index].direction === 'OUTBOUND' && (
                              <Grid item xs={2}>
                                <Field
                                  component={TextField}
                                  fullWidth
                                  margin="dense"
                                  label={t(
                                    'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.DESCRIPTION',
                                  )}
                                  variant="outlined"
                                  name={`invoices[${index}].description`}
                                />
                              </Grid>
                            )}
                          <Grid
                            item
                            xs={
                              values.invoices[index].type === 'OTHER' &&
                              values.invoices[index].direction === 'OUTBOUND'
                                ? 2
                                : 4
                            }
                          >
                            <Field
                              component={TextField}
                              fullWidth
                              margin="dense"
                              label={t(
                                'ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.COMMENT',
                              )}
                              variant="outlined"
                              name={`invoices[${index}].comment`}
                            />
                          </Grid>
                          <Grid item xs={0.5}>
                            <IconButton
                              onClick={() => {
                                let newValues = [...values.invoices]
                                newValues.splice(index, 1)
                                setFieldValue('invoices', newValues)
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Grid>
                        </Fragment>
                      ))}
                    </Fragment>
                  )}
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => hide()}
                variant="outlined"
                color={'secondary'}
              >
                {t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.CANCEL')}
              </Button>
              <Button
                onClick={() => submitForm()}
                disabled={
                  !isValid ||
                  isSubmitting ||
                  (values.bb_data &&
                    values.bb_data.invoice.result !== 'ERROR' &&
                    values.bb_data.posting.result !== 'ERROR' &&
                    (!values.bb_data.invoice.email ||
                      !values.bb_data.posting.debtor))
                }
                type="submit"
                variant="contained"
                color="secondary"
              >
                {t('ORDERS.DELIVERY_NOTES.INVOICING_DIALOG.CONFIRM')}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    )
  )
}

export default withTranslation()(InvoicingCreateInitialDialog)
