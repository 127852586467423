import config from '../_config/config.js'
import { handleResponse } from '../_helpers/api.js'
import { authHeader } from '../_helpers/auth-header.js'

export const mailSettingsService = {
  getMailSettings,
}

function getMailSettings() {
  var requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  }

  return fetch(`${config.apiUrlBase}/mail-settings`, requestOptions).then(
    handleResponse,
  )
}

export * from './mailSettingsService.js'
