import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material'

import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { authorize } from '../../../../_helpers/authorization'
import { claimsService } from '../../../../_services/claimsService'
import AgreementDialog from '../../../AgreementDialog'
import { Loading } from '../../../Loading'
import PositionCard from '../../../PositionCard'
import { Section } from '../../../Section'
import ClaimAttachments from './_components/ClaimAttachments'

const CreateClaimOfDeliveryNoteDialog = ({
  t,
  order,
  dispatch,
  deliveryNote,
  setFieldValue,
  claim,
  show,
  hide,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const userObject = JSON.parse(localStorage.getItem('user'))
  const [showResetDialog, setShowResetDialog] = useState(false)
  const mode = claim ? 'EDIT' : ''
  const [initialValues, setInitialValues] = useState({
    positions: deliveryNote.positions.map((position) => ({
      checked: true,
      quantityAmount: '',
      qualityAmount: '',
      deliveryNotePositionId: position.id,
    })),
    claimType: 'QUALITY',
    claimDescription: '',
    state: '',
    attachments: [],
  })

  const handleClaimCloseConfirm = () => {
    setIsLoading(true)
    setShowResetDialog(false)
    let requestbody = {
      ...claim,
      id: claim.id,
      state: 'CLOSED',
    }
    claimsService
      .updateClaim(requestbody, claim.id)
      .then(() => {
        console.log('Claim closed successfully')
      })
      .catch((error) => {
        console.log('Error closing claim:', error)
      })
      .finally(() => {
        setIsLoading(false)
        hide()
      })
  }

  useEffect(() => {
    if (claim) {
      setInitialValues({
        positions: deliveryNote.positions.map((position) => {
          let claimPosition = claim.positions.find(
            (claimPosition) =>
              claimPosition.deliveryNotePositionId === position.id,
          )
          return {
            checked: true,
            quantityAmount: claimPosition ? claimPosition.quantityAmount : 0,
            qualityAmount: claimPosition ? claimPosition.qualityAmount : 0,
            deliveryNotePositionId: position.id,
          }
        }),
        claimType: claim.type,
        claimDescription: claim.description,
        state: claim.state,
        attachments: claim.attachments.map((attachment) => {
          return {
            id: attachment ? attachment.id : null,
            name: attachment ? attachment.name : null,
          }
        }),
      })
    } else {
      setInitialValues({
        positions: deliveryNote.positions.map((position) => ({
          checked: true,
          quantityAmount: '',
          qualityAmount: '',
          deliveryNotePositionId: position.id,
        })),
        claimType: 'QUALITY',
        claimDescription: '',
        state: 'ACTIVE',
        attachments: [],
      })
    }
  }, [claim, t])

  const handleClaimClose = () => {
    setShowResetDialog(true)
  }
  const handleSave = (values) => {
    setIsLoading(true)
    let partnerId = userObject.partner.id
    const filteredClaimPositions = values.positions.filter(
      (position) => position.checked,
    )

    let requestbody = {
      ...values,
      positions: filteredClaimPositions,
      partnerId,
    }
    if (claim) {
      claimsService
        .updateClaim(requestbody, claim.id)
        .then(() => {
          console.log('Claim updated successfully')
        })
        .catch((error) => {
          console.log('Error updating claim:', error)
        })
        .finally(() => {
          setIsLoading(false)
          hide()
        })
    } else {
      claimsService
        .createClaimOfDeliveryNote(requestbody, deliveryNote.id)
        .then(() => {
          console.log('Claim created successfully')
        })
        .catch((error) => {
          console.log('Error creating claim:', error)
        })
        .finally(() => {
          setIsLoading(false)
          hide()
        })
    }
    setIsLoading(false)
    hide()
  }

  const valSchema = Yup.object().shape({
    positions: Yup.array()
      .of(
        Yup.object().shape({
          quantityAmount: Yup.number().when(
            ['checked', 'claimType'],
            (claimType, checked, schema) => {
              return claimType === 'QUANTITY' && checked
                ? Yup.number()
                    .required(t('GENERAL.REQUIRED'))
                    .typeError(t('GENERAL.REQUIRED'))
                : Yup.mixed().nullable()
            },
          ),
          qualityAmount: Yup.number().when(
            ['claimType', 'checked'],
            (claimType, checked, schema) => {
              return claimType === 'QUALITY' && checked
                ? Yup.number()
                    .required(t('GENERAL.REQUIRED'))
                    .typeError(t('GENERAL.REQUIRED'))
                : Yup.mixed().nullable()
            },
          ),
        }),
      )
      .min(1, t('GENERAL.MIN_1')),
    claimType: Yup.string().required(t('GENERAL.REQUIRED')),
    claimDescription: Yup.string().required(t('GENERAL.REQUIRED')),
  })

  return (
    <Dialog fullWidth={true} maxWidth="xl" open={show}>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          {t('CLAIMS.TITLE')}
        </Typography>
        {isLoading ? (
          <Loading variant={'centered'} />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={valSchema}
            onSubmit={handleSave}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <Section header={t('CLAIM.CLAIM_DETAILS')}>
                      <Field
                        component={TextField}
                        name="claimType"
                        disabled={values.state === 'CLOSED'}
                        label={t('CLAIM.TYPE')}
                        select
                        variant="outlined"
                        margin="normal"
                        fullWidth
                      >
                        <MenuItem value="QUALITY">
                          {t('CLAIM.QUALITY')}
                        </MenuItem>
                        <MenuItem value="QUANTITY">
                          {t('CLAIM.QUANTITY')}
                        </MenuItem>
                        <MenuItem value="BOTH">{t('CLAIM.BOTH')}</MenuItem>
                      </Field>
                      <Field
                        component={TextField}
                        name="claimDescription"
                        label={t('CLAIM.DESCRIPTION')}
                        disabled={values.state === 'CLOSED'}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        multiline
                        rows="7.5"
                      />
                    </Section>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Section header={t('CLAIM.POSITIONS')}>
                      {deliveryNote.positions.map((position, index) => {
                        let orderPosition = order.positions.find(
                          (orderPosition) =>
                            position.orderPositionId === orderPosition.id,
                        )
                        const isChecked = values.positions[index].checked
                        const isReadOnly =
                          !isChecked || values.state === 'CLOSED'

                        const isQuantityVisible =
                          values.claimType === 'QUANTITY' ||
                          values.claimType === 'BOTH'
                        const isQualityVisible =
                          values.claimType === 'QUALITY' ||
                          values.claimType === 'BOTH'

                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            container
                            spacing={1}
                            direction="row"
                            alignItems="stretch"
                          >
                            {
                              <PositionCard
                                data={orderPosition}
                                showProductDetails={true}
                                showImage={true}
                                imageSize={'small'}
                                readOnly={true}
                              >
                                <Grid item xs={12} sm={6}>
                                  <Field
                                    component={CheckboxWithLabel}
                                    type={'checkbox'}
                                    disabled={values.state === 'CLOSED'}
                                    checked={values.positions[index].checked}
                                    name={`positions[${index}].checked`}
                                    margin="dense"
                                    Label={{
                                      label: t('CLAIM.CLAIM_POSITION'),
                                    }}
                                  />
                                  {isQuantityVisible && (
                                    <Field
                                      variant="outlined"
                                      label={t('CLAIM.AMOUNT_QUANTITY')}
                                      component={TextField}
                                      name={`positions[${index}].quantityAmount`}
                                      type="text"
                                      placeholder={t('CLAIM.AMOUNT_QUANTITY')}
                                      disabled={isReadOnly}
                                      margin="dense"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  )}
                                  {isQualityVisible && (
                                    <Field
                                      variant="outlined"
                                      margin="dense"
                                      label={t('CLAIM.AMOUNT_QUALITY')}
                                      component={TextField}
                                      name={`positions[${index}].qualityAmount`}
                                      type="text"
                                      placeholder={t('CLAIM.AMOUNT_QUALITY')}
                                      disabled={isReadOnly}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  )}
                                </Grid>
                              </PositionCard>
                            }
                          </Grid>
                        )
                      })}
                    </Section>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ClaimAttachments
                    data={values}
                    mode={mode}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <DialogActions>
                  <Button onClick={hide} variant="outlined" color="secondary">
                    {t('GENERAL.CLOSE')}
                  </Button>
                  {(!claim || claim.state !== 'CLOSED') && (
                    <Button type="submit" variant="contained" color="secondary">
                      {t('CLAIM.SAVE')}
                    </Button>
                  )}
                  {claim &&
                    claim.state === 'ACTIVE' &&
                    authorize('administrator') &&
                    mode === 'EDIT' && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClaimClose}
                      >
                        {t('CLAIM.CLOSE')}
                      </Button>
                    )}
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
      <AgreementDialog
        open={showResetDialog}
        message={t('ADMIN.ARE_YOU_SURE')}
        acceptButtonText={t('GENERAL.YES')}
        handleClose={() => {
          setShowResetDialog(false)
        }}
        agree={handleClaimCloseConfirm}
      />
    </Dialog>
  )
}

export default withTranslation()(CreateClaimOfDeliveryNoteDialog)
