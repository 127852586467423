import {
  Add,
  Archive,
  Edit,
  EventAvailable,
  FileCopy,
  LocalOffer,
  Publish,
  Receipt,
} from '@mui/icons-material'
import GroupsIcon from '@mui/icons-material/Groups'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import {
  Button,
  Chip,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { alertActions } from '../../../../_actions/alertActions'
import {
  categorizedProductLookup,
  countryLookup,
  deliveryTypeLookup,
  productLookup,
  productStateLookup,
  requestStateLookup,
} from '../../../../_constants/lookupConstants'
import { authorize } from '../../../../_helpers/authorization'
import { requestsService } from '../../../../_services/requestsService'
import { usersService } from '../../../../_services/usersService'
import AgreementDialog from '../../../AgreementDialog'
import CounterOfferTargetPriceDialog from '../../../CounterOfferTargetPriceDialog'
import { InfoTooltip } from '../../../InfoTooltip'
import { PositionsList } from '../../../PositionsList'
import RequestDetails from '../../../RequestDetails'
import RequestPositionsOfferComparison from '../../../RequestPositionsOfferComparison'
import ResourceList from '../../../ResourceList/_components/ResourceList'
import ViewDescription from '../../../ViewDescription'
import RequestPartnerSwitchDialog from '../Request/_components/RequestPartnerSwitchDialog'
import AdvertRecommendations from './_components/AdvertRecommendations'

const Requests = (props) => {
  const { t, dispatch } = props
  const [selectedRequest, setSelectedRequest] = useState(null)
  const [
    showAgreementDialogDeleteRequest,
    setShowShowAgreementDialogDeleteRequest,
  ] = useState(false)
  const [showPartnerSwitchDialog, setShowPartnerSwitchDialog] = useState(false)
  const [showRequestPositionComparison, setShowRequestPositionComparison] =
    useState(false)
  const [usersOfPartner, setUsersOfPartner] = useState([])
  const [forceUpdateCount, forceUpdate] = useState(0)
  const [
    showCounterOfferTargetPriceDialog,
    setShowCounterOfferTargetPriceDialog,
  ] = useState(false)
  const [advertRecommendations, setAdvertRecommendations] = useState({})

  const userObject = JSON.parse(localStorage.getItem('user'))

  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const search = location.search
  const params = new URLSearchParams(search)
  let defaultValueRequestId = params.get('id')
  let requestType = location.pathname === '/tenders' ? 'TENDER' : 'REQUEST'

  let advertsCheckRequestId = params.get('advertsCheck')

  let offerComparisonRequestId = params.get('offerComparisonRequestId')

  let offerComparisonTargetPriceRequestId = params.get(
    'offerComparisonTargetPriceRequestId',
  )
  window.history.pushState({}, document.title, window.location.pathname)

  useEffect(() => {
    usersService.getUsersOfPartner(userObject.partner.id).then((users) => {
      setUsersOfPartner(users)
    })
  }, [userObject.partner.id])

  useEffect(() => {
    if (offerComparisonRequestId) {
      requestsService.getRequest(offerComparisonRequestId).then((request) => {
        setSelectedRequest(request)
        setShowRequestPositionComparison(true)
      })
    }
  }, [])
  useEffect(() => {
    if (offerComparisonTargetPriceRequestId) {
      requestsService
        .getRequest(offerComparisonTargetPriceRequestId)
        .then((request) => {
          setSelectedRequest(request)
          setShowCounterOfferTargetPriceDialog(true)
        })
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (advertsCheckRequestId) {
        try {
          const request = await requestsService.getRequest(
            advertsCheckRequestId,
          )
          if (request.type !== 'REQUEST') {
            return
          }

          const filterValues = {
            'positions.productId': [],
          }

          if (request.positions.length > 1) {
            return
          }
          request.positions.forEach((rPos) => {
            filterValues['positions.productId'].push(rPos.productId)

            rPos.positionProperties.forEach((positionProperty) => {
              switch (positionProperty.productPropertyId) {
                case 1:
                  filterValues[
                    'positions.positionProperties.productPropertyId=1&positions.positionProperties.value'
                  ] = positionProperty.value
                  break
                case 2:
                  filterValues[
                    'positions.positionProperties.productPropertyId=2&positions.positionProperties.value'
                  ] = positionProperty.value
                  break
                case 21:
                case 44:
                case 45:
                  if (
                    !filterValues[
                      'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value'
                    ]
                  ) {
                    filterValues[
                      'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value'
                    ] = []
                  }
                  filterValues[
                    'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value'
                  ].push(positionProperty.value)
                  break
                case 22:
                case 47:
                case 69:
                  if (
                    !filterValues[
                      'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value'
                    ]
                  ) {
                    filterValues[
                      'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value'
                    ] = []
                  }
                  filterValues[
                    'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value'
                  ].push(positionProperty.value)
                  break
                default:
                  break
              }
            })
          })

          filterValues['~IGNORE~advertRecommendationRequestId'] =
            advertsCheckRequestId

          const recommendation = await requestsService.getPublishedRequests(
            ['ADVERT'],
            userObject.partner.id,
            filterValues,
          )

          setAdvertRecommendations(recommendation)
        } catch (error) {
          console.error('Fehler beim Abrufen der Anfragen:', error)
        }
      }
    }

    fetchData()
  }, [])

  let navigate = useNavigate()

  return (
    <Fragment>
      {
        <ViewDescription
          description={
            requestType === 'TENDER'
              ? t('TENDERS.VIEW_DESCRPTION')
              : t('REQUESTS.VIEW_DESCRPTION')
          }
        />
      }
      <Fragment>
        <ResourceList
          fetchMethod={(filterValues, pagination) =>
            requestsService.getRequestsOfPartner(
              userObject.partner.id,
              requestType,
              filterValues,
              pagination,
              userObject.user.partnerLocationIds,
            )
          }
          forceUpdateCount={forceUpdateCount}
          filterId={requestType === 'REQUEST' ? 'REQUESTS_V2' : 'TENDERS_V2'}
          hint={
            requestType === 'TENDER'
              ? t('TENDERS.VIEW_DESCRPTION')
              : t('REQUESTS.VIEW_DESCRPTION')
          }
          selectionText={
            requestType === 'TENDER'
              ? t('TENDERS.SELECT_A_TENDER')
              : t('REQUESTS.SELECT_A_REQUEST')
          }
          sorting={[
            {
              key: 'id',
              default: true,
              label:
                requestType === 'REQUEST'
                  ? t('REQUESTS.REQUEST_ID')
                  : t('REQUESTS.TENDER_ID'),
            },
          ]}
          filters={[
            {
              key: 'id.startsWith',
              label: t('MARKETPLACE.REQUEST_ID'),
              variant: 'textfield',
              defaultValue: defaultValueRequestId,
            },
            {
              key: 'state',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(requestStateLookup),
              lookup: requestStateLookup,
              label: t('REQUEST.STATE'),
            },
            {
              key: 'positions.productId.eq',
              variant: 'categorizedCheckboxList',
              values: categorizedProductLookup.map((category) => {
                return {
                  label: category.name,
                  items: category.products.map((product) => product.id),
                }
              }),
              lookup: productLookup,
              label: t('MARKETPLACE.PRODUCT'),
              shortcut: true,
            },
            {
              key: 'positions.positionProperties.productPropertyId=21,44,45&positions.positionProperties.value',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(productStateLookup),
              lookup: productStateLookup,
              label: t('MARKETPLACE.PRODUCT_STATE'),
            },
            {
              key: 'positions.positionProperties.productPropertyId=22,47,69&positions.positionProperties.value',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(deliveryTypeLookup),
              lookup: deliveryTypeLookup,
              label: t('MARKETPLACE.DELIVERY_TYPE'),
            },
            {
              key: 'positions.positionProperties.productPropertyId=1&positions.positionProperties.value',
              label: t('MARKETPLACE.PRODUCT_LENGTH'),
              variant: 'textfield',
            },
            {
              key: 'positions.positionProperties.productPropertyId=2&positions.positionProperties.value',
              label: t('MARKETPLACE.PRODUCT_WIDTH'),
              variant: 'textfield',
            },
            {
              key: 'positions.location.countryId.eq',
              variant: 'checkboxList',
              values: Object.getOwnPropertyNames(countryLookup),
              lookup: countryLookup,
              label: t('MARKETPLACE.COUNTRY'),
            },
            {
              key: 'positions.location.zipcode.startsWith',
              label: t('MARKETPLACE.ZIPCODE'),
              variant: 'textfield',
            },
            {
              key: 'positions.location.place.startsWith',
              label: t('MARKETPLACE.PLACE'),
              variant: 'textfield',
            },
            {
              key: 'userId.eq',
              variant: 'checkboxList',
              values: usersOfPartner.map((user) => user.id),
              lookup: Object.fromEntries(
                usersOfPartner.map((user) => [
                  user.id,
                  `${user.firstname} ${user.lastname}`,
                ]),
              ),
              label: t('GENERAL.USER'),
            },
            {
              key: 'title.like',
              label: t('REQUESTS.TITLE'),
              variant: 'textfield',
            },
          ]}
          toolbarSecondary={
            <Button
              size="medium"
              color={'secondary'}
              variant={'contained'}
              startIcon={<Add />}
              onClick={() => {
                navigate({
                  pathname: requestType === 'TENDER' ? '/tender' : '/request',
                })
              }}
            >
              {requestType === 'TENDER'
                ? t('REQUESTS.NEW_TENDER')
                : t('REQUESTS.NEW_REQUEST')}
            </Button>
          }
          resourceHeaderRenderFunction={(request) =>
            `${
              request.type === 'REQUEST'
                ? t('MARKETPLACE.REQUEST')
                : t('MARKETPLACE.TENDER')
            }: ${request.id}`
          }
          listHeaderRenderFunction={(request) => (
            <Fragment>
              <div>
                {`${
                  request.type === 'REQUEST'
                    ? t('MARKETPLACE.REQUEST')
                    : t('MARKETPLACE.TENDER')
                }
                  : ${request.id}${
                  request.publishedAt
                    ? ` | ${moment(request.publishedAt)
                        .local()
                        .format('DD.MM.YYYY')}`
                    : ''
                }`}
                {request.generalInformation.otherDetails.title
                  ? ` | ${request.generalInformation.otherDetails.title}`
                  : ''}
              </div>
              <div>{`${request.user.firstname} ${request.user.lastname}`}</div>
              {`${
                request.generalInformation.deliveryDetails.locations[0].company
              } | ${
                request.generalInformation.deliveryDetails.locations[0].zipcode
              } | ${
                request.generalInformation.deliveryDetails.locations[0].place
              } | ${
                countryLookup[
                  request.generalInformation.deliveryDetails.locations[0]
                    .countryId
                ]
              }`}
            </Fragment>
          )}
          headerActionRenderFunction={(request) => (
            <Fragment>
              <Stack direction={'column'}>
                <Stack direction={isMobile ? 'row-reverse' : 'row'} spacing={1}>
                  {request.community && (
                    <InfoTooltip
                      title={
                        request.generalInformation.otherDetails.communityName
                      }
                    >
                      <GroupsIcon color={'secondary'} />
                    </InfoTooltip>
                  )}
                  <Chip
                    size={'small'}
                    color={
                      request.state === 'DRAFT' ||
                      request.state === 'ARCHIVED' ||
                      request.state === 'EXPIRED'
                        ? 'default'
                        : 'info'
                    }
                    label={requestStateLookup[request.state]}
                  />
                </Stack>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: isMobile ? 'left' : 'right',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant={'caption'}>
                    {`${t('REQUESTS.OFFERS')}: ${request.offerAmount}`}
                  </Typography>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: isMobile ? 'left' : 'right',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant={'caption'}>
                    {`${t('REQUESTS.ORDERS')}: ${request.orderAmount}`}
                  </Typography>
                </div>
              </Stack>
            </Fragment>
          )}
          contentRenderFunction={(request) => (
            <PositionsList positions={request.positions} />
          )}
          resourceRenderFunction={(request) => (
            <RequestDetails requestId={request.id} />
          )}
          actionsRenderFunction={(request) => {
            return [
              {
                icon: <SwapHorizIcon />,
                hidden: !authorize('administrator'),
                name: t('REQUESTS.SWITCH_PARTNERS'),
                primary: false,
                onClick: () => {
                  setSelectedRequest(request)
                  setShowPartnerSwitchDialog(true)
                },
              },
              {
                icon: <Receipt />,
                hidden:
                  request.type !== 'TENDER' ||
                  request.offerState !== 'SUCCESSFUL',
                name:
                  request.offerState !== 'SUCCESSFUL'
                    ? t('REQUESTS.NO_CONTRACTS')
                    : t('REQUESTS.GOTO_CONTRACTS'),
                primary: false,
                onClick: () =>
                  navigate({
                    pathname: '/contracts',
                    search: 'requestId=' + request.id,
                  }),
              },
              {
                icon: <EventAvailable />,
                hidden: request.offerState !== 'SUCCESSFUL',
                name:
                  request.offerState !== 'SUCCESSFUL'
                    ? t('REQUESTS.NO_ORDERS')
                    : t('REQUESTS.GOTO_ORDERS'),
                primary: false,
                onClick: () =>
                  navigate({
                    pathname: '/deliveries-customer',
                    search: 'requestId=' + request.id,
                  }),
              },
              {
                icon: <Publish />,
                hidden: request.state !== 'DRAFT',
                name:
                  request.state !== 'DRAFT'
                    ? t('REQUESTS.ALREADY_PUBLISHED')
                    : t('GENERAL.PUBLISH'),
                primary: false,
                onClick: () => {
                  requestsService
                    .updateRequest(
                      { state: 'PUBLISHED' },
                      request.id,
                      false,
                      request.generalInformation.deliveryDetails.locations.map(
                        (location) => location.partnerLocationId,
                      ),
                    )
                    .then(() => {
                      forceUpdate(forceUpdateCount + 1)
                      dispatch(alertActions.info(t('REQUESTS.PUBLISHED')))
                      setTimeout(() => {
                        dispatch(alertActions.clear())
                      }, alertActions.alertTimeout)
                    })
                    .catch((error) => {
                      dispatch(alertActions.error(error))
                      setTimeout(() => {
                        dispatch(alertActions.clear())
                      }, alertActions.alertTimeout)
                    })
                },
              },
              {
                icon: <FileCopy />,
                name: t('REQUESTS.COPY'),
                primary: false,
                onClick: () =>
                  navigate({
                    pathname: requestType === 'TENDER' ? '/tender' : '/request',
                    search: '?mode=copy&id=' + request.id,
                  }),
              },
              {
                icon: <Archive />,
                name:
                  request.state === 'ARCHIVED'
                    ? t('REQUESTS.ALREADY_ARCHIVED')
                    : t('GENERAL.ARCHIVE'),
                hidden: request.state === 'ARCHIVED',
                primary: false,
                onClick: () => {
                  setSelectedRequest(request)
                  setShowShowAgreementDialogDeleteRequest(true)
                },
              },
              {
                icon: <Edit />,
                name: t('REQUESTS.EDIT'),
                hidden: request.state === 'ARCHIVED',
                primary: false,
                onClick: () => {
                  if (
                    request.state === 'NEGOTIATION' ||
                    request.state === 'ARCHIVED'
                  ) {
                    dispatch(
                      alertActions.info(
                        t('REQUESTS.EDIT_NO_LONGER_POSSIBLE_CONTENT'),
                      ),
                    )
                    setTimeout(() => {
                      dispatch(alertActions.clear())
                    }, alertActions.alertTimeout)
                  }
                  navigate({
                    pathname: requestType === 'TENDER' ? '/tender' : '/request',
                    search: '?mode=edit&id=' + request.id,
                  })
                },
              },
              {
                icon: <LocalOffer />,
                name: t('REQUESTS.COMPARE_OFFERS'),
                primary: true,
                onClick: () => {
                  setSelectedRequest(request)
                  setShowRequestPositionComparison(true)
                },
              },
            ]
          }}
          dataIdentifier={'id'}
        />
        <AgreementDialog
          open={showAgreementDialogDeleteRequest}
          handleClose={() => {
            setShowShowAgreementDialogDeleteRequest(false)
          }}
          agree={async () => {
            await requestsService.deleteRequest(selectedRequest.id)
            dispatch(alertActions.info(t('REQUESTS.DELETE_SUCCESSFUL')))
            setShowShowAgreementDialogDeleteRequest(false)
            forceUpdate(forceUpdateCount + 1)

            setTimeout(() => {
              dispatch(alertActions.clear())
            }, alertActions.alertTimeout)
          }}
        />
        {showPartnerSwitchDialog && (
          <RequestPartnerSwitchDialog
            show={showPartnerSwitchDialog}
            hide={() => setShowPartnerSwitchDialog(false)}
            request={selectedRequest}
            forceUpdateCount={forceUpdateCount}
            forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
          />
        )}
        {showRequestPositionComparison && (
          <RequestPositionsOfferComparison
            hide={() => setShowRequestPositionComparison(false)}
            requestId={selectedRequest.id}
          />
        )}
        {showCounterOfferTargetPriceDialog && (
          <CounterOfferTargetPriceDialog
            requestId={selectedRequest.id}
            show={showCounterOfferTargetPriceDialog}
            hide={() => setShowCounterOfferTargetPriceDialog(false)}
            forceUpdateCount={forceUpdateCount}
            forceUpdate={() => forceUpdate(forceUpdateCount + 1)}
          />
        )}
        {advertRecommendations.closest && (
          <AdvertRecommendations
            advertRecommendations={advertRecommendations}
            show={true}
            hide={() => setAdvertRecommendations({})}
          />
        )}
      </Fragment>
    </Fragment>
  )
}

export default withTranslation()(connect()(Requests))
